export class WizardWIP {

  public id!: string;
  public createClickCampaign!: boolean;
  public createDisplayCampaign!: boolean;
  public displayClickPercent!: number;
  public selectedTarget!: number;
  public step!: number;
  public status!: string;
  public rejectedReason!: string;

  public campaignName!: string;
  public displayName!: string;
  public name!: string;
  public keywords: string[] = [];
  public targetGender: string[] = [];
  public targetAgeFrom!: number;
  public targetAgeTo!: number;
  public intensityFocus!: string;
  public streamerFocus!: string;
  public gameKeywords: string[] = [];
  public ignoredGameKeywords: string[] = [];
  public activateStreamerAge: boolean = false;
  public streamerAgeFrom!: number;
  public streamerAgeTo!: number;
  public language!: string;

  public description!: string;
  public affiliatePics: File[] = [];
  public campaignPics: File[] = [];
  public containsAnimatedPicture: boolean = false;
  public link!: string;
  public chatMessage!: string;
  public displayAgencyPictureIds: string[] = [];
  public clickAgencyPictureIds: string[] = [];

  public totalBudget!: string;
  public dailyBudget!: string;
  public clicks!: number;
  public dailyClicks!: number;
  public views!: number;
  public dailyViews!: number;
  public startDate!: Date;
  public endDate!: Date;
  public tkp!: number;

  constructor() {
  }

}
