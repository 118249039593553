import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { StreamerService } from '../../services/streamer.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'streamer-delete-dialog',
  templateUrl: 'streamer-delete-dialog.component.html',
  styleUrls: ['streamer-delete-dialog.component.css']
})
export class StreamerDeleteDialogComponent {

  constructor(public i18nService: I18nService, private streamerService: StreamerService,
              public globalService: GlobalService) {
  }

  @Input()
  public show: boolean = false;

  @Output()
  public showChange = new EventEmitter<boolean>();

  public updateShow() {
    this.showChange.emit(this.show);
  }

  deleteStreamer() {
    this.streamerService.deleteStreamer().then(() => {
      this.streamerService.logout().then(() =>
        setTimeout(() => {
          this.show = false;
          window.location.href = this.globalService.links.siteURL;
        }, 100)
      );
    });
  }

  close() {
    this.show = false;
    this.updateShow();
  }

}
