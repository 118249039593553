import {SimpleDemographicCountry} from './simple-demo-country.model';

export class CampaignDemographic {

  public male!: number;
  public female!: number;
  public diverse!: number;

  public under18!: number;
  public from18to24!: number;
  public from25to34!: number;
  public from35to44!: number;
  public over44!: number;

  public countries: SimpleDemographicCountry[] = [];

  constructor() {}

  public hasGenderInfos(): boolean {
    return this.male != undefined && this.female != undefined && this.diverse != undefined;
  }

  public hasAgeInfos(): boolean {
    return this.under18 != undefined && this.from18to24 != undefined && this.from25to34 != undefined
      && this.from35to44 != undefined && this.over44 != undefined;
  }

  public hasCountries(): boolean{
    return this.countries.length > 0;
  }

  public getCountryNames() : string[] {
    var names : string[] = [];
    var i: number = 0;

    for (i = 0; i < this.countries.length; i++) {
      names.push(this.countries[i]['name']);
    }

    return names;
  }

  public getCountryValues() : number[] {
    var values : number[] = [];
    var i: number = 0;

    for (i = 0; i < this.countries.length; i++) {
      values.push(this.countries[i]['share']);
    }

    return values;
  }

}
