import { filter } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterEvent } from '@angular/router';

@Injectable()
export class RoutingService {

  pathEmitter: EventEmitter<string> = new EventEmitter();

  pathChangeEmitter: EventEmitter<string> = new EventEmitter();

  constructor(private location: Location,
    private router: Router) {
    this.router.events.pipe(filter(e => e instanceof RouterEvent)).subscribe((event) => {
      // @ts-ignore
      this.pathChangeEmitter.emit(event['url']);
    })
  }

  getRoute(): string {
    return this.router.url;
  }

  /**
   * Wechselt zur angegebenen Seite
   * @param path
   * @param params
   */
  switchToPath(path: string, params?:{}) {
    if (!params) {
      params = {};
    }

    this.pathEmitter.emit(path);
    this.router.navigate([path, params]).then();
  }

  /**
   * Wechselt zur angegebenen Seite und macht danach einen kompletten Reload der Seite
   * @param path
   * @param params
   */
  switchToPathWithReload(path: string, params?:{}) {
    if (!params) {
      params = {};
    }

    this.router.navigate([path, params]).then(() => {
      window.location.reload();
    });
  }

  goBack() {
    this.location.back();
  }

}
