import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StreamerLoginComponent} from './streamer/login/streamer-login.component';
import {OauthCallbackComponent} from './streamer/oauth-callback/oauth-callback.component';
import {StreamerHomeComponent} from './streamer/home/streamer-home.component';
import {StreamerNewsComponent} from './streamer/news/streamer-news.component';
import {StreamerContestComponent} from './streamer/contest/streamer-contest.component';
import {AdvertiserLoginComponent} from './advertiser/login/advertiser-login.component';
import {AdvertiserDashboardComponent} from './advertiser/dashboard/advertiser-dashboard.component';
import {StreamerCampaignsComponent} from './streamer/campaigns/streamer-campaigns.component';
import {StreamerAffiliatesComponent} from './streamer/affiliates/streamer-affiliates.component';
import {AdvertiserBillsComponent} from './advertiser/bills/advertiser-bills.component';
import {StreamerIncomeComponent} from './streamer/income/streamer-income.component';
import {AdvertiserProfileComponent} from './advertiser/profile/profile-advertiser.component';
import {AdvertiserCampaignsComponent} from './advertiser/campaigns/advertiser-campaigns.component';
import {StreamerRegisterComponent} from './streamer/register/streamer-register.component';
import {AdvertiserRegisterComponent} from './advertiser/register/advertiser-register.component';
import {StreamerEmailActivationComponent} from './streamer/email-activation/email-activation.component';
import {AdvertiserActivationComponent} from './advertiser/activation/advertiser-activation.component';
import {AdvertiserCancelMailsComponent} from './advertiser/cancel-mails/advertiser-cancel-mails.component';
import {AgencyLoginComponent} from './agency/login/agency-login.component';
import {AgencyDashboardComponent} from './agency/dashboard/agency-dashboard.component';
import {AgencyCreateAdvertiserComponent} from './agency/create-advertiser/agency-create-advertiser.component';
import {AgencyProfileComponent} from './agency/profile/profile-agency.component';
import {AgencyAdvertisersComponent} from './agency/advertisers/agency-advertisers.component';
import {AgencyUsersComponent} from './agency/users/agency-users.component';
import {AdvertiserAdminTakeoverComponent} from './advertiser/admin-takeover/admin-takeover.component';
import {AgencyAdminTakeoverComponent} from './agency/admin-takeover/admin-takeover.component';
import {AgencyPicturesComponent} from './agency/pictures/pictures.component';
import {AgencyActivationComponent} from './agency/activation/agency-activation.component';
import {AdvertiserTagsComponent} from './advertiser/tags/advertiser-tags.component';
import {CampaignWizardComponent} from './campaign-wizard/campaign-wizard.component';
import {AgencyAdvertiserRegisterComponent} from './register/agency-advertiser-register.component';
import {AdvertiserAgencyLoginComponent} from './login/advertiser-agency-login.component';

const routes: Routes = [
  { path: '', redirectTo: 'streamer/login', pathMatch: 'full' },
  { path: 'streamer/login', component: StreamerLoginComponent },
  { path: 'streamer/home', component: StreamerHomeComponent },
  { path: 'streamer/campaigns', component: StreamerCampaignsComponent },
  {path: 'streamer/benefits', component: StreamerNewsComponent},
  { path: 'streamer/contest', component: StreamerContestComponent },
  { path: 'streamer/income', component: StreamerIncomeComponent },
  { path: 'streamer/affiliates', component: StreamerAffiliatesComponent },
  { path: 'streamer/register', component: StreamerRegisterComponent },
  { path: 'streamer/activate/email/:id', component: StreamerEmailActivationComponent},
  { path: 'advertiser/login', component: AdvertiserLoginComponent },
  { path: 'advertiser/dashboard', component: AdvertiserDashboardComponent },
  { path: 'advertiser/bills', component: AdvertiserBillsComponent },
  { path: 'advertiser/profile', component: AdvertiserProfileComponent },
  { path: 'advertiser/campaigns', component: AdvertiserCampaignsComponent },
  { path: 'advertiser/tags', component: AdvertiserTagsComponent },
  { path: 'advertiser/register/regular', component: AdvertiserRegisterComponent },
  { path: 'advertiser/activate/:id', component: AdvertiserActivationComponent },
  { path: 'advertiser/cancel/mails/:id', component: AdvertiserCancelMailsComponent },
  { path: 'advertiser/takeover/:token', component: AdvertiserAdminTakeoverComponent },
  { path: 'advertiser/campaign/create', component: CampaignWizardComponent },
  { path: 'agency/login', component: AgencyLoginComponent },
  { path: 'agency/dashboard', component: AgencyDashboardComponent },
  { path: 'agency/advertiser/add', component: AgencyCreateAdvertiserComponent },
  { path: 'agency/profile', component: AgencyProfileComponent },
  { path: 'agency/advertisers', component: AgencyAdvertisersComponent },
  { path: 'agency/users', component: AgencyUsersComponent },
  { path: 'agency/pictures', component: AgencyPicturesComponent },
  { path: 'agency/takeover/:token', component: AgencyAdminTakeoverComponent },
  { path: 'agency/activate/:id', component: AgencyActivationComponent },
  { path: 'agency/campaign/create', component: CampaignWizardComponent },
  { path: 'register', component: AgencyAdvertiserRegisterComponent },
  { path: 'callback', component: OauthCallbackComponent },
  { path: 'login', component: AdvertiserAgencyLoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
