export class CampaignAffiliate {

  public link!: string;
  public code!: string;

  public files: string[] = [];

  constructor() {}

}
