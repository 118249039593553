import {SuperService} from "./super.service";
import {Injectable, Input} from '@angular/core';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {CookieService} from 'ngx-cookie-service';
import {loadStripe, Stripe, StripeEmbeddedCheckout} from "@stripe/stripe-js";
import {GlobalService} from "./global.service";
import {Advertiser} from "../model/advertiser/advertiser.model";
import {Agency} from "../model/agency/agency.model";
import {environment} from "../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class PaymentService extends SuperService {
  @Input() isAdvertiser: boolean = true;

  checkout!: StripeEmbeddedCheckout;

  constructor(public streamBoostEventBus: StreamBoostEventBus,
              private cookieService: CookieService,
              public globalService: GlobalService,
              public router: Router) {
    super(streamBoostEventBus);
  }

  public createCheckoutSession(productName: string, price: number, returnUrl: string = ""): Promise<string> {
    if (this.checkout !== undefined) {
      this.checkout.destroy();
      console.log('destroyed');
    }
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const person: Advertiser | Agency = this.globalService.advertiser ? this.globalService.advertiser! : this.globalService.agency!;
        this.streamBoostEventBus.send('stream.web.facade.create.session',
          {
            message: {
              firstname: person.firstname,
              lastname: person.lastname,
              email: person.email,

              product_name: productName,
              price: price,
              return_url: environment.streamWebUrl + returnUrl,

              customer_id: person.customerId,
            },
          }).then(
          async (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
              localStorage.clear();
              return;
            }
            var ans = response.body.response;
            resolve(ans);
          }
        );
      }
    });
  }

  public async fulfillPayment(sessionId: string, successFunction: Function, errorFunction: Function = () => {
  }) {
    if (this.cookieService.get('streamboost-session')) {
      console.log('fulfilling session')
      this.streamBoostEventBus.send('stream.web.facade.fulfill.session',
        {
          message: {
            session_id: sessionId,
          },
        }).then(
        async (response: any) => {
          if (response.body.code) {
            errorFunction();
            return;
          }
          successFunction();
        }
      );
    }

  }

}
