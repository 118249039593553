<div *ngIf="this.globalService.advertiser||this.globalService.agency">
    <div class="tab-menu">
        <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="this.activeTab=1">
            <span class="p-menuitem-icon fas fa-info-circle" style="margin-right: 0.5rem;"></span>
            <span class="p-menuitem-text">{{i18nService.localize().AdvertiserCampaignDetailsComponent.InformationTab}}</span>
        </div>
        <div [class]="this.activeTab===2 ? 'tab tab-selected' : 'tab'" (click)="this.activeTab=2">
            <span class="p-menuitem-icon fas fa-chart-bar" style="margin-right: 0.5rem;"></span>
            <span class="p-menuitem-text">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StatisticTab}}</span>
        </div>
        <div [class]="this.activeTab===3 ? 'tab tab-selected' : 'tab'" (click)="goToLiveStreams()">
            <span class="p-menuitem-icon fas fa-rss" style="margin-right: 0.5rem;"></span>
            <span class="p-menuitem-text">live</span>
        </div>
        <div [class]="this.activeTab===4 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="this.activeTab=4">
            <span class="p-menuitem-icon fas fa-comments" style="margin-right: 0.5rem;"></span>
            <span class="p-menuitem-text">{{i18nService.localize().AdvertiserCampaignDetailsComponent.FeedbackTab}}</span>
        </div>
    </div>
    <div class="tab-card" *ngIf="campaign">

    <!-- Informationen Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 1">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
      <div class="p-col-12" style="margin-bottom: 2em;">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.CurrentStatusHeader}}</p>
        <p-progressBar [value]="campaign.progress"></p-progressBar>
      </div>
      <div class="p-col-12 p-lg-6 p-xl-6 border-cross-left" style="padding: 1em;">
        <div class="p-grid">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{campaign.name}}</p>
          </div>
          <div class="p-col-12" *ngIf="campaign.pictures&&campaign.pictures.length>0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Pictures}}:</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.pictures&&campaign.pictures.length>0">
            <p-carousel [value]="campaign.pictures" orientation="horizontal" [autoplayInterval]="6000" [circular]="true"
                        [numVisible]="1" [numScroll]="1" [showIndicators]="false">
              <ng-template let-pic pTemplate="item">
                <div>
                  <img src="{{pic.link}}" class="ad-picture" alt="Ad-Picture" style="width: 100%;"/>
                  <p *ngIf="pic.calls > 0"><b>{{i18nService.localize().AdvertiserCampaignDetailsComponent.Calls}}:</b>
                    {{pic.calls}}</p>
                  <p *ngIf="pic.calls > 0">
                    <b>{{i18nService.localize().AdvertiserCampaignDetailsComponent.Duration}}:</b> {{pic.duration}}
                  </p>
                  <div class="p-col"
                       *ngIf="campaign.status==='PAUSED' || campaign.status==='DEACTIVATED' || campaign.status==='ACTIVE'">
                    <div style="text-align: center">
                      <button pButton pRipple type="button" icon="fas fa-trash"
                              [disabled]="campaign.pictures.length < 2 || campaign.status==='ACTIVE'"
                              (click)="deletePicture(pic)" class="p-button-rounded">
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
            <div class="box" *ngIf="campaign.status==='PAUSED' || campaign.status==='DEACTIVATED'">
              <button pButton pRipple type="button" icon="fas fa-plus" [disabled]="campaign.pictures.length > 2"
                      class="p-button-rounded" (click)="this.showPicUpload=true">
              </button>
            </div>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.pictures&&campaign.pictures.length>0">
          </div>
          <div class="p-col-12 p-lg-6 p-xl-4" *ngIf="campaign.description">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Description}}:</p>
            {{campaign.getDescriptionExcerpt()}}<br>
            <a (click)="this.showDescriptionModal=true" *ngIf="campaign.hasLongDescription()"
               style="cursor: pointer; text-decoration: underline">
              {{i18nService.localize().AdvertiserCampaignDetailsComponent.MoreDescriptionLink}}
            </a>
          </div>
          <div class="p-col-4">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Status}}:</p>
            {{campaign.status}} <i class="far fa-question-circle" (click)="statusOP.show($event)"></i>
            <p-overlayPanel #statusOP [showCloseIcon]="true" [dismissable]="true">
              <ng-template pTemplate>
                <p>
                  <b>IN_REVIEW</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.InReviewPanelText}}<br>
                  <b>REVIEWED</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.ReviewedPanelText}}<br>
                  <b>ACTIVE</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.ActivePanelText}}<br>
                  <b>WAITING</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.WaitingPanelText}}<br>
                  <b>PAUSED</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.PausedPanelText}}<br>
                  <b>INACTIVE</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.InactivePanelText}}<br>
                  <b>DELETED</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.DeletedPanelText}}<br>
                  <b>ENDED</b>: {{i18nService.localize().AdvertiserCampaignDetailsComponent.EndedPanelText}}<br>
                </p>
              </ng-template>
            </p-overlayPanel>
          </div>
          <div class="p-col-4">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.DisplayName}}:</p>
            {{campaign.displayName}}
          </div>
          <div class="p-col-12">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Branch}}:</p>
            <div *ngIf="campaign.keywords.length > 0">
              <p-tag *ngFor="let keyword of campaign.keywords" value="{{keyword}}" [rounded]="true"></p-tag>
            </div>
          </div>
          <div class="p-col-4" *ngIf="campaign.targetAgeTo">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetGroupAge}}:</p>
            {{campaign.targetAgeFrom}} - {{campaign.targetAgeTo}}
          </div>
          <div class="p-col-4" *ngIf="campaign.targetGender && campaign.targetGender.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetGender}}:</p>
            <span *ngFor="let gender of campaign.targetGender, let i = index">
              {{gender == "male" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Male : gender == "female" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Female : i18nService.localize().AdvertiserCampaignDetailsComponent.Divers}}{{i < campaign.targetGender.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="campaign.targetCountries && campaign.targetCountries.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetCountry}}:</p>
            <span *ngFor="let country of campaign.targetCountries, let i = index">
              {{country}}{{i < campaign.targetCountries.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="campaign.intensityFocus">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.AdFocus}}:</p>
            {{campaign.intensityFocus == "intensive" ? "Hohe Intensität" : campaign.intensityFocus == "range" ? "Hohe Reichweite" : "Balanced"}}
          </div>
          <div class="p-col-4" *ngIf="campaign.streamerFocus">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerFocus}}:</p>
            {{campaign.streamerFocus == "micro" ? "auf kleine Streamer" : campaign.streamerFocus == "macro" ? "auf große Streamer" : "Balanced"}}
          </div>
          <div class="p-col-4" *ngIf="campaign.streamerAgeTo">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerGroupAge}}:</p>
            {{campaign.streamerAgeFrom}} - {{campaign.streamerAgeTo}}
          </div>
          <div class="p-col-4" *ngIf="campaign.streamerGender && campaign.streamerGender.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerGender}}:</p>
            <span *ngFor="let gender of campaign.streamerGender, let i = index">
              {{gender == "male" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Male : gender == "female" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Female : i18nService.localize().AdvertiserCampaignDetailsComponent.Divers}}{{i < campaign.targetGender.length - 1 ? ', ' : ''}}
            </span>
          </div>

          <div class="p-col-4" *ngIf="campaign.gameKeywords">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.GameKeywords}}:</p>
            <span *ngFor="let gameKeyword of campaign.gameKeywords, let i = index">
              {{gameKeyword}}{{i < campaign.gameKeywords.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="campaign.ignoredGameKeywords">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.IgnoredGameKeywords}}:</p>
            <span *ngFor="let ignoredGameKeyword of campaign.ignoredGameKeywords, let i = index">
              {{ignoredGameKeyword}}{{i < campaign.ignoredGameKeywords.length - 1 ? ', ' : ''}}
            </span>
          </div>
        </div>
      </div>

      <p-dialog [(visible)]="showPicUpload" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.DescriptionHeader}}</div>
        </ng-template>

        <p class="error" *ngIf="!picturesOk">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.PicUploadError}}</p>
        <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png" [maxFileSize]=1500000
                      id="banner" [showUploadButton]="false" [files]="getCurrentPictures()" [fileLimit]="1"
                      (onSelect)="setPictures($event)"
                      chooseLabel="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ChooseButton}}"
                      cancelLabel="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.DeleteButton}}">
          <ng-template pTemplate="content">
            <ul *ngIf="uploadedPictures.length">
              <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
        <p class="text-muted">{{i18nService.localize().AdvertiserCampaignDetailsComponent.BannerHint}}</p>
        <div style="text-align: right">
          <div *ngIf="!working">
            <button pButton pRipple type="button" icon="fas fa-check" [disabled]="this.uploadedPicturesBackup.length<=0"
                    class="p-button-rounded" (click)="uploadPic()"></button>
            <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                    class="p-button-rounded p-button-secondary" (click)="this.showPicUpload=false"></button>
          </div>
          <div *ngIf="working">
            <p-progressSpinner [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
          </div>
        </div>
      </p-dialog>


      <p-dialog [(visible)]="showDescriptionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.DescriptionHeader}}</div>
        </ng-template>
        <p [innerHTML]="campaign.description"></p>
      </p-dialog>

      <div class="p-col-12 p-lg-6 p-xl-6 border-cross-right" style="padding: 1em 1em 1em 3em;">
        <div class="p-grid" *ngIf="campaign.scheduling">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">
              {{i18nService.localize().AdvertiserCampaignDetailsComponent.SchedulingHeader}}</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.scheduling.startDate">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StartDate}}:</p>
            {{campaign.scheduling.startDate}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.scheduling.endDate">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.EndDate}}:</p>
            {{campaign.scheduling.endDate}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.scheduling.startTime">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StartTime}}:</p>
            {{campaign.scheduling.startTime}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.scheduling.endTime">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.EndTime}}:</p>
            {{campaign.scheduling.endTime}}
          </div>
          <div class="p-col-12" *ngIf="campaign.scheduling.days.length > 0">
            <span class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Days}}:</span><br>
            <p-tag *ngFor="let day of campaign.scheduling.days" value="{{day}}" [rounded]="true"></p-tag>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6"
               *ngIf="campaign.scheduling.dailyViews && campaign.scheduling.dailyViews > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.PlannedDailyViews}}:
            </p>
            {{campaign.scheduling.dailyViews}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6"
               *ngIf="campaign.scheduling.dailyViews && campaign.scheduling.dailyViews > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Views}}:</p>
            {{campaign.scheduling.countedDailyViews}}
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8"
               *ngIf="campaign.scheduling.dailyViews && campaign.scheduling.dailyViews > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.DailyViews}}:</p>
            <div style="width: 50%;margin: 0 auto;">
              <p-chart type="doughnut" [data]="dailyViewData"></p-chart>
            </div>
          </div>
        </div>
        <div class="p-grid" *ngIf="!campaign.scheduling">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">
              {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoSchedulingHeader}}</p>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" style="border-right: 1px solid black; padding: 1em;">
        <div class="p-grid">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">
              {{i18nService.localize().AdvertiserCampaignDetailsComponent.ViewsHeader}}</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.PlannedViews}}:</p>
            {{campaign.viewsPlanned}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Views}}:</p>
            {{campaign.viewsAccounted}}
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8">
            <div style="width: 50%;margin: 0 auto;">
              <p-chart type="doughnut" [data]="viewData"></p-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="campaign.affiliate" style="padding: 1em 1em 1em 3em;">
        <div class="p-grid">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">
              {{i18nService.localize().AdvertiserCampaignDetailsComponent.ClickCampaignHeader}}</p>
          </div>
          <div class="p-col-12" *ngIf="campaign.affiliate.link">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Link}}:</p>
            {{campaign.affiliate.link}}
          </div>
          <div class="p-col-12" *ngIf="campaign.affiliate.code">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Code}}:</p>
            {{campaign.affiliate.code}}
          </div>
          <div class="p-col-12" *ngIf="campaign.affiliate.files">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Files}}:</p>
            {{campaign.affiliate.files.length}}
          </div>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

    </div>

    <!-- Statistik Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 2">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
      <div class="p-col-9" style="margin-bottom: 2em;">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.CurrentStatusHeader}}</p>
        <p-progressBar [value]="campaign.progress"></p-progressBar>
      </div>
      <div class="p-col"></div>
      <div class="p-col-2">
        <div class="streamer-block"
             pTooltip="{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerInCampaignHeader}}"
             tooltipPosition="left">
          <img src="/assets/images/streamer.svg" alt="streamerLogo">
          <p class="streamer-block-text">{{numberOfStreamer}}</p>
        </div>
      </div>

      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-bottom: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamedGamesHeader}}</p>
        <p-dataView [value]="this.gameStreamStatistics" [paginator]="true" [rows]="3">
          <ng-template let-gameStream pTemplate="listItem">
            <div class="p-col-12 list-object">
              <div class="p-grid data-grid">
                <div class="p-col-12 p-md-3 p-lg-3 p-xl-3">
                  <img [src]="gameStream.box_art" [alt]="gameStream.name" [title]="gameStream.name"/>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 p-xl-7">
                  <p style="font-weight: bold; font-size: 14pt;">{{gameStream.name}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3 p-xl-2" style="text-align: right;">
                  <p style="font-size: 14pt;">{{gameStream.number_of_streams}} Streams</p>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </div>
      <div class="p-col"></div>
      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-bottom: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamKeywordCountHeader}}
        </p>
        <div *ngIf="gameTimesData.labels">
          <p-chart type="bar" [data]="gameTimesData"></p-chart>
        </div>
        <div *ngIf="!gameTimesData.labels">
          <p>
            {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoDataAvailable}}
          </p>
        </div>

      </div><div class="p-col"></div>
      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-bottom: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamGameViewsCountHeader}}
        </p>
        <div *ngIf="gameViewData.labels">
          <p-chart type="bar" [data]="gameViewData"></p-chart>
        </div>
        <div *ngIf="!gameViewData.labels">
          <p>
            {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoDataAvailable}}
          </p>
        </div>
      </div>


        <div class="p-col"></div>
        <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">
                {{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamGameCategoryHeader}}
            </p>
            <div *ngIf="streamedCategoryViewData.labels">
                <p-chart type="bar" [data]="streamedCategoryViewData"></p-chart>
            </div>
            <div *ngIf="!streamedCategoryViewData.labels">
                <p>
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoDataAvailable}}
                </p>
            </div>
        </div>

      <div class="p-col-12" *ngIf="burndownData">
        <div style="margin-top: 2em; margin-bottom: 2em;">
          <span (click)="setBurnup()" [class]="!this.burndown ? 'tab-card-headline' : 'tab-card-line'"
                style="border-right: 1px solid black; cursor: pointer;">BURNUP </span>
          <span (click)="setBurndown()" [class]="this.burndown ? 'tab-card-headline' : 'tab-card-line'"
                style="cursor: pointer;">&nbsp;BURNDOWN</span>
        </div>
        <p-tabMenu [model]="burndownItems" *ngIf="burndownData" [activeItem]="burndownItems[0]"
                   [style]="{marginTop: '1em'}"></p-tabMenu>
        <div style="padding: 1em 2em 1em 0em;">
          <p-chart *ngIf="burndownData" type="line" [data]="burndownData" [options]="burndownOptions"></p-chart>
        </div>
      </div>

      <div class="p-col-12" *ngIf="campaign.hasDemographic()">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.AudienceHeader}}</p>
        <div class="p-grid">
          <div class="p-col-12 p-md-6" *ngIf="campaign.demographic.hasGenderInfos()">
            <div style="width: 40%; margin: 0 auto">
              <p-chart type="pie" [data]="genderData"></p-chart>
            </div>
          </div>
          <div class="p-col-12 p-md-6" *ngIf="campaign.demographic.hasAgeInfos()" style="padding-right: 5em;">
            <p-chart type="bar" [data]="ageData" [options]="chartOptions"></p-chart>
          </div>
          <!--              <div class="p-col-4" *ngIf="selectedCampaign.demographic.hasCountries()">-->
          <!--                <p-chart type="horizontalBar" [data]="countryData" [options]="chartOptions"></p-chart>-->
          <!--              </div>-->
        </div>
      </div>

      <div class="p-col-12" *ngIf="heatOptions">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.ViewMapHeader}}
        </p>
        <div class="p-grid">
          <div class="p-col-12">
            <p-tabMenu [model]="cwSelectItems" [activeItem]="cwSelectItems[0]"
                       [style]="{marginTop: '1em'}"></p-tabMenu>
          </div>
          <div class="p-col-12">
            <div style="width: 95%; margin: 0 auto">
              <apx-chart [series]="heatOptions.series" [chart]="heatOptions.chart" [dataLabels]="heatOptions.dataLabels"
                         [colors]="heatOptions.colors" [plotOptions]="heatOptions.plotOptions">
              </apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

    </div>

    <!-- Live Stream Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 3">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.LiveStreamsHeader}}</p>
      </div>

      <div class="p-col-12" *ngIf="streams.length==0">
        <p class="no-streams-text">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoLiveStreamsText}}
        </p>
      </div>

      <div class="p-col-12" *ngIf="streams.length>0">
        <p-dataView [value]="this.streams" [paginator]="true" [rows]="5" [loading]="loadingStreams">
          <ng-template let-stream pTemplate="listItem">
            <div class="p-col-12">
              <div class="p-grid"
                   [style]="stream.darkStyledEntry ? 'background-color: #ededed;' : 'background-color: #ffffff;'">
                <div class="p-col-12 p-lg-3 p-xl-3">
                  <div class="streams-border-right">
                    <div class="online-container">
                      <i class="fas fa-rss" style="margin-right: 0.5em;"></i>
                      <span>online</span>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-lg-3 p-xl-3" style="text-align: center;">
                  <div class="p-grid" style="padding-top: 1em;">
                    <div *ngFor="let game of stream.games" class="p-col">
                      {{game}}
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-lg-3 p-xl-3" style="text-align: center;">
                  <div class="streams-border">
                    {{stream.streamerName}}
                  </div>
                </div>
                <div class="p-col-12 p-lg-3 p-xl-3" style="text-align: center;">
                  <div class="streams-border">
                    <a href="{{stream.profileLink}}" target="_blank">
                      {{i18nService.localize().AdvertiserCampaignDetailsComponent.ToStreamLink}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </div>

      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.FinishedStreamsHeader}}</p>
      </div>

      <div class="p-col-12" *ngIf="finishedStreams.length==0">
        <p class="no-streams-text">
          {{i18nService.localize().AdvertiserCampaignDetailsComponent.NoFinishedStreamsText}}
        </p>
      </div>

      <div class="p-col-12">
        <div *ngIf="finishedStreams.length > 0">
          <div class="table-scrollable">
            <p-table [value]="finishedStreams" [rows]="15" [lazy]="true" (onLazyLoad)="updateFinishedStreams($event)"
                     [paginator]="true" [totalRecords]="totalFinishedStreams" [loading]="loadingFinishedStreams"
                     sortMode="single" sortField="start_date" [defaultSortOrder]="getDefaultSortOrder()"
                     [sortOrder]="getDefaultSortOrder()" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="start_date" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.StartDate}}
                    <p-sortIcon field="start_date"></p-sortIcon>
                  </th>
                  <th pSortableColumn="end_date" style="width:200px">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.EndDate}}
                    <p-sortIcon field="end_date"></p-sortIcon>
                  </th>
                  <th style="width:200px">
                    Streamer
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.Games}}
                  </th>
                  <th pSortableColumn="views" style="width:70px">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.Views}}
                    <p-sortIcon field="views"></p-sortIcon>
                  </th>
                  <th style="width:150px">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamDuration}}
                  </th>
                  <th class="tableTopRight" style="width:150px">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.AdDuration}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-stream>
                <tr>
                  <td>{{stream.startDate}}</td>
                  <td>{{stream.endDate}}</td>
                  <td>
                    <a href="{{stream.profileLink}}" target="_blank">
                      {{stream.streamerName}}
                    </a>
                  </td>
                  <td>
                    <div class="p-grid" style="padding: 0;">
                      <div *ngFor="let game of stream.games" class="p-col">
                        {{game}}
                      </div>
                    </div>
                  </td>
                  <td>{{stream.views}}</td>
                  <td>{{stream.streamDuration}}</td>
                  <td>{{stream.adDuration}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserCampaignDetailsComponent.BackButton}}"
                class="p-button-rounded"
                icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

    </div>

    <!-- Feedback | Fragen Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 4">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

      <div class="p-col-12">
        <h2
          class="card-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerFeedbackHeader}}</h2>
        <div *ngIf="feedbacks.length > 0">
          <div class="table-scrollable">
            <p-table [value]="feedbacks" [rows]="20" [lazy]="true" (onLazyLoad)="updateFeedbacks($event)"
                     [paginator]="true" [totalRecords]="totalFeedbacks" [loading]="loadingFeedbacks" sortMode="single"
                     sortField="question_date" [defaultSortOrder]="getDefaultSortOrder()"
                     [sortOrder]="getDefaultSortOrder()"
                     responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="question_date" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.FeedbackDate}}
                    <p-sortIcon field="question_date"></p-sortIcon>
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.TextExcerpt}}
                  </th>
                  <th class="tableTopRight">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.Action}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-feedback>
                <tr>
                  <td>{{feedback.feedbackDate}}</td>
                  <td>{{feedback.getMessageExcerpt()}}</td>
                  <td>
                    <button pButton pRipple type="button" class="p-button-rounded" icon="fas fa-comment-dots"
                            (click)="showText(feedback, 'FEEDBACK')"
                            pTooltip="{{i18nService.localize().AdvertiserCampaignDetailsComponent.ShowFeedbackTooltip}}"
                            tooltipPosition="left" *ngIf="feedback.isMessageOverlyLong()">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!(feedbacks.length>0)">
          <p>{{i18nService.localize().AdvertiserCampaignDetailsComponent.NoFeedbackInfo}}</p>
        </div>
      </div>

      <div class="p-col-12">
        <h2
          class="card-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerQuestionsHeader}}</h2>
        <div *ngIf="questions.length > 0">
          <div class="table-scrollable">
            <p-table [value]="questions" [rows]="20" [lazy]="true" (onLazyLoad)="updateQuestions($event)"
                     [paginator]="true" [totalRecords]="totalQuestions" [loading]="loadingQuestions"
                     sortMode="single" sortField="question_date" [defaultSortOrder]="getDefaultSortOrder()"
                     [sortOrder]="getDefaultSortOrder()" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="question_date" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.QuestionDate}}
                    <p-sortIcon field="question_date"></p-sortIcon>
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.TextExcerpt}}
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.AnswerDate}}
                  </th>
                  <th class="tableTopRight">
                    {{i18nService.localize().AdvertiserCampaignDetailsComponent.Action}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-question>
                <tr>
                  <td>{{question.questionDate}}</td>
                  <td>{{question.getMessageExcerpt()}}</td>
                  <td>{{question.answerDate}}</td>
                  <td>
                    <button pButton pRipple type="button" class="p-button-rounded" icon="fas fa-comment-dots"
                            (click)="showText(question, 'QUESTION')"
                            pTooltip="{{i18nService.localize().AdvertiserCampaignDetailsComponent.ShowQuestionTooltip}}"
                            tooltipPosition="left" *ngIf="question.answer">
                    </button>

                    <button pButton pRipple type="button" class="p-button-rounded" icon="far fa-comment"
                            pTooltip="{{i18nService.localize().AdvertiserCampaignDetailsComponent.AnswerQuestionTooltip}}"
                            (click)="showAnswerQuestion(question)"
                            tooltipPosition="left" *ngIf="!question.answer">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!(questions.length>0)">
          <p>{{i18nService.localize().AdvertiserCampaignDetailsComponent.NoQuestionsInfo}}</p>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button"
                label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
    </div>

    <p-dialog [(visible)]="showFeedbackOrQuestionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{modalHeadline}}</div>
      </ng-template>
      <p [innerHTML]="feedbackOrQuestionText"></p>
      <p *ngIf="answerText" style="margin-bottom: 0; font-weight: bold">
        {{i18nService.localize().AdvertiserCampaignDetailsComponent.YourAnswer}}:
      </p>
      <p [innerHTML]="answerText" *ngIf="answerText"></p>
    </p-dialog>

    <p-dialog [(visible)]="showAnswerQuestionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.AnswerQuestionHeadline}}</div>
      </ng-template>

      <div class="p-fluid">
        <div class="p-field">
          <label style="font-weight: bold;">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Question}}
            :</label>
          <p style="margin-top: 0">
            {{selectedQuestion}}
          </p>
        </div>
        <div class="p-field">
          <label for="answer"
                 style="font-weight: bold;">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Answer}}
            :</label>
          <textarea id="answer" type="text" rows="6" pInputTextarea [(ngModel)]="selectedAnswer"></textarea>

          <p style="color: gray;font-size: small;">
            {{i18nService.localize().AdvertiserCampaignDetailsComponent.StayRespectfulHint}}
          </p>
        </div>
      </div>

      <div style="text-align: right">
        <button pButton pRipple type="button" icon="fas fa-check" [disabled]="!this.selectedAnswer"
                class="p-button-rounded" (click)="answerQuestion()">
        </button>
        <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                class="p-button-rounded p-button-secondary" (click)="this.showAnswerQuestionModal=false">
        </button>
      </div>
    </p-dialog>

  </div>
</div>
