import {AffiliateBannerPicture} from './affiliate-banner-picture.model';
import * as moment from "moment/moment";

export class Affiliate {

  public id!: string;

  public name!: string;
  public description!: string;

  public logoURL!: string;

  public bannerURLs: AffiliateBannerPicture[] = [];

  public streamerToken!: string;

  public APIUrl!: string;

  public keywords: string[] = [];

  public type!: string;

  public requested: boolean = false;

  public totalClicks: number = 0;

  public progress!: number;
  public dailyProgress!: number;

  public topAffiliate: boolean = false;

  public startDate!: string;
  public startDateAsDate!: Date;

  public endDate!: string;

  public status!: string

  public paused: boolean = false;

  public chatMessage!: string;

  public tinyURl!: string;

  constructor() {}

  public getLink(pictureId: string) : string {

    // Bei Links für Chatnachrichten wir die UUID des ersten Bilds verwendet, wenn es Bilder gibt
    if (pictureId === 'NONE') {
      if (this.bannerURLs.length > 0) {
        let banner:AffiliateBannerPicture = this.bannerURLs[0];
        return this.tinyURl+'/'+banner.uuid;
      } else {
        return this.APIUrl+'affiliate/'+this.streamerToken;
      }

    } else {

      let uuid: string | undefined = '';
      for (let i = 0; i < this.bannerURLs.length; i++) {
        let banner:AffiliateBannerPicture = this.bannerURLs[i];

        if (banner.id === pictureId) {
          uuid = banner.uuid;
        }
      }

      // neue Tiny Links
      if (uuid) {
        return this.tinyURl+'/'+uuid;

      // alte links
      } else {

        if (this.type === 'EXTERNAL') {
          return this.APIUrl+'link/'+this.streamerToken+'/'+this.id+'/'+pictureId;
        } else {
          return this.APIUrl+'link/'+this.streamerToken+'/'+this.id;
        }
      }
    }
  }

  public getReadableType(lang: string) : string {
    if(this.type === 'INTERNAL' || this.type === 'CAMPAIGN') {
      if(lang === 'DE') {
        return 'Klick-Kampagne'
      } else {
        return 'Click Campaign'
      }
    } else {
      return 'Affiliate'
    }
  }

  public isStartDateInFuture() : boolean {
    if (this.startDate) {
      return this.startDateAsDate > new Date();
    } else {
      return false;
    }
  }

  public getDaysToStart() : number {
    if (this.startDate) {
      let start = moment(this.startDateAsDate);
      let today = moment(new Date);

      return start.diff(today, 'days');
    } else {
      return 0;
    }
  }

}
