import {CampaignScheduling} from './campaign-scheduling.model';
import {CampaignDemographic} from './campaign-demographic.model';
import {CampaignAffiliate} from './campaign-affiliate.model';
import {Picture} from './campaign-picture.model';

export class AdvertiserCampaign {

  public id!: string;

  public name!: string;
  public displayName!: string;
  public description!: string;

  public viewsPlanned!: number;
  public views!: number;
  public viewsAccounted!: number;

  public progress!: number;

  public scheduling!: CampaignScheduling;

  public demographic!: CampaignDemographic;

  public gameKeywords: string[] = [];
  public ignoredGameKeywords: string[] = [];
  public keywords: string[] = [];
  public keywordIds: string[] = [];

  public pictures: Picture[] = [];

  public status!: string;

  public numberOfStreamersAttached = 0;

  public budget!: number;
  public daysBetween!: number;
  public streamerFocus!: string;
  public intensityFocus!: string;
  public targetAgeFrom!: number;
  public targetAgeTo!: number;
  public targetGender: string[] = [];
  public streamerGender: string[] = [];
  public targetCountries: string[] = [];
  public streamerAgeFrom: number = 0;
  public streamerAgeTo: number = 0;

  public logoURL!: string;

  public affiliate!: CampaignAffiliate;

  constructor() {}

  public getStatus(): string {
    if (this.status !== 'INACTIVE' && this.status !== 'ENDED' && this.status !== 'DELETED' &&
      (this.numberOfStreamersAttached === 0 || (this.pictures != undefined && this.pictures.length === 0))) {
      return 'IN_REVIEW';
    } else {
      return this.status;
    }
  }

  public hasDemographic(): boolean {
    if (this.demographic) {
      return this.demographic.hasAgeInfos() || this.demographic.hasGenderInfos() || this.demographic.hasCountries();
    } else {
      return false;
    }
  }

  public hasLongDescription() : boolean {
    return this.description.length > 21;
  }

  public getDescriptionExcerpt() : string {
    if (this.hasLongDescription()) {
      return this.description.slice(0,21)+ "...";
    } else {
      return this.description;
    }
  }
}
