import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {AgencyService} from '../../services/agency.service';
import {GameStreamStatistic} from "../../model/advertiser/game-stream-statistic.model";
import {DashboardCampaign} from "../../model/advertiser/dashboard-campaign.model";
import Chart from 'chart.js/auto';
import {Subscription} from "rxjs";

@Component({
  selector: 'agency-dashboard',
  templateUrl: './agency-dashboard.component.html',
  styleUrls: ['./agency-dashboard.component.scss'],
})
export class AgencyDashboardComponent implements OnInit, OnDestroy {
  private langChangeSubscription!: Subscription;

  public responsiveOptions: any;

  public clickChart!: any;
  public viewChart!: any;
  public chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  public gameStreamStatistics: GameStreamStatistic[] = [];

  public displayDetails = false;
  public campaign = '';
  public campaignId = '';
  public campaignName = '';
  public campaignType = '';
  public numberOfStreamer = 0;
  public daysLeftForBalanceText: string = '';
  public daysLeftForBalance: number = 0;

  public balanceChartData: any;
  public balanceChartOptions: any;

  constructor(
    public globalService: GlobalService,
    public agencyService: AgencyService,
    public i18nService: I18nService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe(() => {
      this.updateBalanceChart();
    });

    if (this.globalService.agency) {
      this.agencyService.getAllClicksChart().then((res) => {
        this.clickChart = res;
      });

      this.agencyService.getAllViewsChart().then((res) => {
        this.viewChart = res;
      });

      this.agencyService.getAllGameStreamStatistics().then((res) => {
        this.gameStreamStatistics = res;
      });
    }

    this.updateBalanceChart();
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  updateBalanceChart() {
    if (this.globalService.agency) {
      const today = new Date();
      const availableBalanceDate = new Date(this.globalService.agency.balance_valid_until);
      const daysLeft = Math.ceil((availableBalanceDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
      this.daysLeftForBalance = daysLeft;

      if (daysLeft > 0) {
        this.daysLeftForBalanceText = this.i18nService.localize().AgencyDashboardComponent.AvailabilityOfBalance + ' ' + daysLeft + ' ' + this.i18nService.localize().AgencyDashboardComponent.Days;
      } else if (daysLeft === 0) {
        this.daysLeftForBalanceText = this.i18nService.localize().AgencyDashboardComponent.Today;
      } else {
        this.daysLeftForBalanceText = this.i18nService.localize().AgencyDashboardComponent.Expired;
      }

      const {balance, usedBalance} = this.globalService.agency;

      const data = usedBalance > 0
        ? [usedBalance, balance]
        : [balance];

      const labels = usedBalance > 0
        ? [this.i18nService.localize().AgencyDashboardComponent.UsedBalance, this.i18nService.localize().AgencyDashboardComponent.AvailableBalance]
        : [this.i18nService.localize().AgencyDashboardComponent.AvailableBalance];

      const backgroundColor = usedBalance > 0
        ? ['#001528', '#82b1ff']
        : ['#82b1ff'];

      const hoverBackgroundColor = usedBalance > 0
        ? ['#001528', '#82b1ff']
        : ['#82b1ff'];

      this.balanceChartData = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: hoverBackgroundColor,
            cutout: '70%',
          },
        ],
      };

      this.balanceChartOptions = {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: 'black',
            formatter: (_value: any, context: any) => {
              return context.chart.data.labels[context.dataIndex];
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                let value = context.raw;
                return value.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
              }
            }
          },
          customCenterText: {
            text: `${this.i18nService.localize().AgencyDashboardComponent.AvailableBalance}\n${balance.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR'
            })}`,
          }
        },
      };

      Chart.register({
        id: 'customCenterText',
        beforeDraw: function (chart: any) {
          if (chart.config.options.plugins.customCenterText) {
            const ctx = chart.ctx;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = 'bold 16px Arial';
            ctx.fillStyle = 'black';
            const lines = chart.config.options.plugins.customCenterText.text.split('\n');
            lines.forEach((line: string, index: number) => {
              ctx.fillText(line, centerX, centerY + index * 20);
            });
            ctx.restore();
          }
        }
      });
    }
  }

  showCampaign(campaign: DashboardCampaign) {
    this.campaignType = campaign.type;
    this.campaignId = campaign.id;
    this.campaignName = campaign.name;
    this.numberOfStreamer = campaign.streamer;
    this.displayDetails = true;
  }

  closeCampaign() {
    this.displayDetails = false;
    this.campaignId = '';
    this.numberOfStreamer = 0;
  }
}
