import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from '../services/i18n.service';
import { StreamerService } from '../services/streamer.service';
import { GlobalService } from '../services/global.service';
import { IpService } from '../services/ip.service';
import { AdvertiserService } from '../services/advertiser.service';
import { AgencyService } from '../services/agency.service';

@Component({
  selector: 'accept-terms-dialog',
  templateUrl: 'accept-terms-dialog.component.html',
  styleUrls: ['accept-terms-dialog.component.css']
})
export class AcceptTermsDialogComponent {

  constructor(public i18nService: I18nService, private streamerService: StreamerService,
              public globalService: GlobalService, private advertiserService: AdvertiserService,
              private agencyService: AgencyService, private ipService: IpService) {
  }

  @Input()
  public show: boolean = false;

  @Output()
  public showChange = new EventEmitter<boolean>();

  public updateShow() {
    this.showChange.emit(this.show);
  }

  acceptTerms() {
    this.ipService.getIp().then(res => {
      if (this.globalService.streamer) {
        this.streamerService.acceptTermsOfUse(res.ip).then(result => {
          this.show = false;
        });
      } else if (this.globalService.advertiser) {
        this.advertiserService.acceptTermsAndConditions(res.ip).then(result => {
          this.show = false;
        });
      } else if (this.globalService.agency) {
        this.agencyService.acceptTermsAndConditions(res.ip).then(result => {
          this.show = false;
        });
      } else {
        this.logout();
      }

    }).catch((err) => {
      console.log(err);
    });
  }

  logout() {
    if (this.globalService.streamer || this.globalService.advertiser || this.globalService.agency) {
      if (this.globalService.advertiser) {
        this.advertiserService.logout(this.globalService.advertiser.rememberLogin).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else if (this.globalService.streamer) {
        this.streamerService.logout().then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else {
        this.agencyService.logout(false).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      }
    } else {
      setTimeout(() => {
        window.location.href = this.globalService.links.siteURL;
      }, 100);
    }
  }

  close() {
    this.show = false;
    this.updateShow();
  }

}
