import {MultilangModel} from '../multilang.model';

export class Country extends MultilangModel {

  constructor(
    public id: string,
    langMap: Map<string, object>
  ) {
    super(langMap);
  }

}
