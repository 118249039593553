<p-card *ngIf="globalService.streamer" [style]="bigFonts ? {'height':'100%'} : {}">
  <div style="padding-top: 0.75em;" *ngIf="globalService.streamer.campaignsInvolved.length>0">
    <!-- [autoplayInterval]="3000" -->
    <p-carousel [value]="this.globalService.streamer.campaignsInvolved" [numVisible]="1" [numScroll]="1"
                [circular]="true" [showIndicators]="false">
      <ng-template let-campaign pTemplate="item">
        <div class="p-grid"
             [style]="campaign.paused ? {'-webkit-filter': 'grayscale(100%)', 'filter': 'grayscale(100%)'} : {}">
          <div class="p-col-12 p-lg-4 p-xl-4">
            <img src="{{campaign.picUrl}}" [alt]="campaign.displayName ? campaign.displayName : campaign.name" class="campaign-image"/>
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8">
            <div class="p-grid">
              <div class="p-col-12 p-xl-6">
                <p class="campaign-header">
                  {{ campaign.displayName ? campaign.displayName : campaign.name }}
                  <a
                    *ngIf="campaign.paused && !(campaign.daily_views && campaign.daily_views <= campaign.counted_daily_views)"
                    class="pauseLink">
                    {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.Paused }}
                    <i style="margin-left: 1em " class="fas fa-info-circle tooltip"
                       (mouseenter)="showPausedBy = true" (mouseleave)="showPausedBy = false">
                      <div class="tooltiptext">
                        <span
                          *ngIf="campaign.pausedType == 'SELF'"> {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PausedByStreamerInfo }}
                        </span>
                        <span
                          *ngIf="campaign.pausedType == 'ADMIN'"> {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PausedByAdminInfo }}
                        </span>
                      </div>
                    </i>
                  </a>

                </p>
                <p *ngIf="campaign.startTime"> {{ campaign.startTime }} - {{ campaign.endTime }} Uhr</p>
                <p *ngIf="campaign.daily_views && campaign.daily_views <= campaign.counted_daily_views"
                   style="font-weight: bold">
                  Tägliche Views erreicht
                </p>
              </div>
              <div class="p-col-12 p-xl-6" style="text-align: right;">
                <button pButton pRipple type="button" class="p-button-rounded"
                        pTooltip="{{this.i18nService.localize().StreamerCampaignsOverviewComponent.DescriptionTooltip}}"
                        *ngIf="campaign.description" icon="fas fa-info" tooltipPosition="left"
                        (click)="showDescriptionDialog(campaign)">
                </button>
                <button pButton pRipple type="button" class="p-button-rounded" style="margin-left: 0.5em;"
                        pTooltip="Fragen oder Feedback an den Kampagnen-Ersteller geben" *ngIf="!showPictureHint"
                        icon="fas fa-comments" tooltipPosition="left" (click)="showFeedbackPanel(campaign.id)">
                </button>
                <button pButton pRipple type="button" class="p-button-rounded" style="margin-left: 0.5em;"
                        pTooltip="{{this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseTooltip}}"
                        icon="fas fa-pause" tooltipPosition="left" (click)="showPauseConfirmDialog(campaign.id)"
                        *ngIf="!campaign.paused">
                </button>
                <button pButton pRipple type="button" class="p-button-rounded" style="margin-left: 0.5em;"
                        pTooltip="{{this.i18nService.localize().StreamerCampaignsOverviewComponent.ResumeTooltip}}"
                        icon="fas fa-play" tooltipPosition="left" (click)="resumeYourself(campaign.id)"
                        *ngIf="campaign.paused&&campaign.pausedType==='SELF'">
                </button>
                <button pButton pRipple type="button" class="p-button-rounded" style="margin-left: 0.5em;"
                        pTooltip="{{this.i18nService.localize().StreamerCampaignsOverviewComponent.QuitTooltip}}"
                        icon="fas fa-unlink" tooltipPosition="left" (click)="showQuitConfirmDialog(campaign.id)"
                        *ngIf="showQuitButton">
                </button>
              </div>
            </div>

            <div *ngIf="!bigFonts">
              <p style="margin: 0" *ngIf="campaign.startDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.StartDate }}
                  : </b>{{ campaign.startDate }}
              </p>
              <p style="margin: 0" *ngIf="campaign.endDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.EndDate }}
                  : </b>{{ campaign.endDate }}
              </p>
              <p style="margin: 0" *ngIf="campaign.startDate && campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.CampaignStartsIn }} {{ campaign.getDaysToStart() }} {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.Days }}</b>
              </p>

              <p style="margin: 0" *ngIf="campaign.endDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.DaysLeft }}
                  : </b>{{ calculateCampaignDaysLeft(campaign.startDate, campaign.endDate) }}
              </p>

              <p style="margin: 0" *ngIf="showViews && !campaign.isStartDateInFuture()">
                <b>Views: </b>{{ campaign.views }}
              </p>
            </div>

            <div *ngIf="bigFonts">
              <p style="margin: 0; font-size: x-large;" *ngIf="campaign.startDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.StartDate }}
                  : </b>{{ campaign.startDate }}
              </p>

              <p style="margin: 0; font-size: x-large;" *ngIf="campaign.endDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.EndDate }}
                  : </b>{{ campaign.endDate }}
              </p>

              <p style="margin: 0; font-size: x-large;" *ngIf="campaign.endDate && !campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.DaysLeft }}
                  : </b>{{ calculateCampaignDaysLeft(campaign.startDate, campaign.endDate) }}
              </p>

              <p style="margin: 0; font-size: xx-large;" *ngIf="campaign.startDate && campaign.isStartDateInFuture()">
                <b>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.CampaignStartsIn }} {{ campaign.getDaysToStart() }} {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.Days }}</b>
              </p>

              <p style="margin: 0; font-size: xx-large;" *ngIf="showViews && !campaign.isStartDateInFuture()">
                <b>Views: </b>{{ campaign.views }}
              </p>
            </div>

            <div class="p-grid">
              <div class="p-col-12 p-xl-9" style="padding-top: 1em">
                <p-progressBar *ngIf="campaign.progress && !campaign.isStartDateInFuture()" [value]="campaign.progress"
                               pTooltip="{{this.i18nService.localize().StreamerCampaignsOverviewComponent.ProgressTooltip}}"
                               tooltipPosition="right" [showValue]="false">
                </p-progressBar>
              </div>
              <div class="p-col-12 p-xl-3" *ngIf="campaign.progress && !campaign.isStartDateInFuture()"
                   style="margin: auto">
                <span style="text-align: center;font-size: x-large;font-weight: bold;">{{ campaign.progress }}%</span>
              </div>
            </div>

            <p style="font-size: 9pt;" *ngIf="showPictureHint">
              <span class="little-star">*</span>
              {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.BannerHint }}
            </p>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  <div *ngIf="globalService.streamer.campaignsInvolved.length==0">
    <p>
      {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.InNoCampaignHint1 }}
      <!--      <b>{{this.i18nService.localize().StreamerCampaignsOverviewComponent.InNoCampaignHint2}}</b>-->
      <!--      {{this.i18nService.localize().StreamerCampaignsOverviewComponent.InNoCampaignHint3}}-->
    </p>
  </div>
</p-card>

<!-- Info- und Bearbeitungsdialoge -->
<p-dialog [(visible)]="quitCampaignDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.QuitDialogHeader }}</div>
  </ng-template>
  <p>
    {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.QuitDialogText }}
  </p>
  <div style="text-align: right">
    <button pButton pRipple type="button" icon="fas fa-check"
            class="p-button-rounded" (click)="quitCampaign()"></button>
    <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
            class="p-button-rounded p-button-secondary" (click)="this.quitCampaignDialog=false"></button>
  </div>
</p-dialog>

<p-dialog [(visible)]="pauseCampaignDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDialogHeader }}</div>
  </ng-template>
  <p>
    {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDialogText }}
  </p>
  <div style="text-align: right">
    <button pButton pRipple type="button" icon="fas fa-check"
            class="p-button-rounded" (click)="pauseYourself()"></button>
    <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
            class="p-button-rounded p-button-secondary" (click)="this.pauseCampaignDialog=false"></button>
  </div>
</p-dialog>

<p-dialog [(visible)]="descriptionDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.Description }}</div>
  </ng-template>
  <div class="p-grid" *ngIf="selectedCampaign">
    <div class="p-col-12">
      <p>
        {{ selectedCampaign.description }}
      </p>
    </div>
    <div class="p-col-12" *ngIf="selectedCampaign.gameKeywords.length > 0">
      <p style="font-weight: bold; margin-top: 0">
        {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.DesiredKeywords }}:
      </p>
      <p-tag *ngFor="let keyword of this.selectedCampaign.gameKeywords" value="{{keyword}}" severity="info">
      </p-tag>
    </div>
    <div class="p-col-12" *ngIf="selectedCampaign.ignoredGameKeywords.length > 0">
      <p style="font-weight: bold; margin-top: 0">
        {{ this.i18nService.localize().StreamerCampaignsOverviewComponent.UndesiredKeywords }}:
      </p>
      <p-tag *ngFor="let keyword of this.selectedCampaign.ignoredGameKeywords" value="{{keyword}}" severity="warning">
      </p-tag>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="pauseDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseQuestion }}</div>
  </ng-template>
  <p class="textWithNewlines">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDescription }}</p>
  <p
    style="font-weight: bold">{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDescriptionQuestion }}</p>
  <p class="textWithNewlines">
    <span>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDescription2 }}</span>
    <span style="color:#de350b">* {{ '{' }}background-color: rgba(0, 0, 0, 0) !important; overflow: hidden}</span>
    <span>{{ this.i18nService.localize().StreamerCampaignsOverviewComponent.PauseDescription3 }}</span>
  </p>
</p-dialog>

<streamer-feedback-panel [show]="showFeedback" (showChange)="showFeedback=$event"
                         [campaignId]="this.feedbackCampaignId">
</streamer-feedback-panel>
