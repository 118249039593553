<div *ngIf="this.globalService.advertiser||this.globalService.agency">
  <div class="tab-menu">
    <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="this.activeTab=1">
      <span class="p-menuitem-icon fas fa-info-circle" style="margin-right: 0.5rem;"></span>
      <span class="p-menuitem-text">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.InformationTab}}</span>
    </div>
    <div [class]="this.activeTab===2 ? 'tab tab-selected' : 'tab'" (click)="this.activeTab=2">
      <span class="p-menuitem-icon fas fa-chart-bar" style="margin-right: 0.5rem;"></span>
      <span class="p-menuitem-text">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.StatisticTab}}</span>
    </div>
    <div [class]="this.activeTab===3 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="this.activeTab=3">
      <span class="p-menuitem-icon fas fa-comments" style="margin-right: 0.5rem;"></span>
      <span class="p-menuitem-text">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.FeedbackTab}}</span>
    </div>
  </div>
  <div class="tab-card" *ngIf="affiliate">

    <!-- Informationen Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 1">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
      <div class="p-col-12" style="margin-bottom: 2em;" *ngIf="affiliate.type!='CAMPAIGN'">
        <p class="tab-card-headline" style="margin-top: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.CurrentStatusHeader}}</p>
        <p-progressBar [value]="affiliate.progress"></p-progressBar>
      </div>
      <div class="p-col-12 p-lg-6 p-xl-6 border-cross-left" style="padding: 1em;">
        <div class="p-grid">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{affiliate.name}}</p>
          </div>
          <div class="p-col-12" *ngIf="affiliate.bannerPics&&affiliate.bannerPics.length>0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Pictures}}:</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.bannerPics&&affiliate.bannerPics.length>0">
            <p-carousel [value]="affiliate.bannerPics" orientation="horizontal" [autoplayInterval]="6000" [circular]="true"
                        [numVisible]="1" [numScroll]="1" [showIndicators]="false">
              <ng-template let-pic pTemplate="item">
                <div class="p-grid p-nogutter">
                  <div class="p-col-12">
                    <img src="{{pic}}" class="banner-picture" alt="Banner-Picture" style="width: 100%;"/>
                  </div>
                  <div class="p-col">
                    <div style="text-align: center">
                      <button pButton pRipple type="button" icon="fas fa-trash"
                              [disabled]="affiliate.bannerPics.length==1" (click)="deletePicture(pic)"
                              class="p-button-rounded">
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
            <div class="box">
              <button pButton pRipple type="button" icon="fas fa-plus"
                      [disabled]="affiliate.bannerPics.length>=3" class="p-button-rounded" (click)="this.showPicUpload=true">
              </button>
            </div>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.bannerPics&&affiliate.bannerPics.length>0">
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.description">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Description}}:</p>
            <span [innerHTML]="affiliate.getDescriptionExcerpt()"></span>
            <a (click)="this.showDescriptionModal=true" *ngIf="affiliate.hasLongDescription()" style="cursor: pointer; text-decoration: underline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.MoreDescriptionLink}}</a>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.campaignName">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.AssociatedCampaign}}:</p>
            {{affiliate.campaignName}}<br>
          </div>
          <div class="p-col-6">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Status}}:</p>
            {{affiliate.status}} <i class="far fa-question-circle" (click)="statusOP.show($event)"></i>
            <p-overlayPanel #statusOP [showCloseIcon]="true" [dismissable]="true">
              <ng-template pTemplate>
                <p>
                  <b>ACTIVE</b>: {{i18nService.localize().AdvertiserAffiliateDetailsComponent.ActivePanelText}}<br>
                  <b>WAITING</b>: {{i18nService.localize().AdvertiserAffiliateDetailsComponent.WaitingPanelText}}<br>
                  <b>INACTIVE</b>: {{i18nService.localize().AdvertiserAffiliateDetailsComponent.InactivePanelText}}<br>
                  <b>DELETED</b>: {{i18nService.localize().AdvertiserAffiliateDetailsComponent.DeletedPanelText}}<br>
                  <b>ENDED</b>: {{i18nService.localize().AdvertiserAffiliateDetailsComponent.EndedPanelText}}<br>
                </p>
              </ng-template>
            </p-overlayPanel>
          </div>
          <div class="p-col-12">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.Branch}}:</p>
            <div *ngIf="affiliate.keywords.length > 0">
              <p-tag *ngFor="let keyword of affiliate.keywords" value="{{keyword}}" [rounded]="true"></p-tag>
            </div>
          </div>
          <div class="p-col-4" *ngIf="affiliate.chatMessage">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ChatMessage}}:</p>
            <p style="margin: 0; word-break: break-all">
              {{affiliate.chatMessage}}
            </p>
          </div>
          <div class="p-col-8" *ngIf="affiliate.tagId && globalService.enableGoogleTags">
            <p class="inner-headline">Tag Id:</p>
            <p style="margin: 0; word-break: break-all">
              {{affiliate.tagId}}
            </p>
          </div>
          <div class="p-col-4" *ngIf="affiliate.targetCountries && affiliate.targetCountries.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetCountry}}:</p>
            <span *ngFor="let country of affiliate.targetCountries, let i = index">
              {{country}}{{i < affiliate.targetCountries.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="affiliate.targetAgeTo">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetGroupAge}}:</p>
            {{affiliate.targetAgeFrom}} - {{affiliate.targetAgeTo}}
          </div>
          <div class="p-col-4" *ngIf="affiliate.targetGender && affiliate.targetGender.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.TargetGender}}:</p>
            <span *ngFor="let gender of affiliate.targetGender, let i = index">
              {{gender == "male" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Male :
              gender == "female" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Female :
                i18nService.localize().AdvertiserCampaignDetailsComponent.Divers}}{{i < affiliate.targetGender.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="affiliate.intensityFocus">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.AdFocus}}:</p>
            {{affiliate.intensityFocus == "intensive" ? "Hohe Intensität" : affiliate.intensityFocus == "range" ? "Hohe Reichweite" : "Balanced"}}
          </div>
          <div class="p-col-4" *ngIf="affiliate.streamerFocus">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerFocus}}:</p>
            {{affiliate.streamerFocus == "micro" ? "auf kleine Streamer" : affiliate.streamerFocus == "macro" ? "auf große Streamer" : "Balanced"}}
          </div>
          <div class="p-col-4" *ngIf="affiliate.streamerGender && affiliate.streamerGender.length > 0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerGender}}:</p>
            <span *ngFor="let gender of affiliate.streamerGender, let i = index">
              {{gender == "male" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Male :
              gender == "female" ? i18nService.localize().AdvertiserCampaignDetailsComponent.Female :
                i18nService.localize().AdvertiserCampaignDetailsComponent.Divers}}{{i < affiliate.streamerGender.length - 1 ? ', ' : ''}}
            </span>
          </div>
          <div class="p-col-4" *ngIf="affiliate.streamerAgeTo">
            <p class="inner-headline">{{i18nService.localize().AdvertiserCampaignDetailsComponent.StreamerGroupAge}}:</p>
            {{affiliate.streamerAgeFrom}} - {{affiliate.streamerAgeTo}}
          </div>


        </div>
      </div>

      <p-dialog [(visible)]="showDescriptionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.DescriptionHeader}}</div>
        </ng-template>
        <p [innerHTML]="affiliate.description"></p>
      </p-dialog>

      <p-dialog [(visible)]="showPicUpload" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.UploadPicHeader}}</div>
        </ng-template>

        <p class="error" *ngIf="!picturesOk">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.PicUploadError}}</p>
        <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png" [maxFileSize]=1500000 id="banner"
                      [showUploadButton]="false" [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                      chooseLabel="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ChooseButton}}"
                      cancelLabel="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.DeleteButton}}">
          <ng-template pTemplate="content">
            <ul *ngIf="uploadedPictures.length">
              <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
        <p class="text-muted">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BannerHint}}</p>
        <div style="text-align: right">
          <div *ngIf="!working">
            <button pButton pRipple type="button" icon="fas fa-check"
                    [disabled]="this.uploadedPicturesBackup.length<=0" class="p-button-rounded" (click)="uploadPic()"></button>
            <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                    class="p-button-rounded p-button-secondary" (click)="this.showPicUpload=false"></button>
          </div>
          <div *ngIf="working">
            <p-progressSpinner [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
          </div>
        </div>
      </p-dialog>

      <div class="p-col-12 p-lg-6 p-xl-6 border-cross-right" style="padding: 1em 1em 1em 3em;">
        <div class="p-grid" *ngIf="affiliate.initialBudget">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BudgetHeader}}</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.startDate">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.StartDate}}:</p>
            {{affiliate.startDate}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="affiliate.calculatedEndDate">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ExpectedEndDate}}:</p>
            {{affiliate.calculatedEndDate}}
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p><span class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Budget}}:</span> {{affiliate.initialBudgetText}} €</p>
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8">
            <div style="width: 50%;margin: 0 auto;">
              <p-chart type="doughnut" [data]="budgetData"></p-chart>
            </div>
          </div>
        </div>
        <div class="p-grid" *ngIf="!affiliate.initialBudget">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.NoBudgetHeader}}</p>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" style="border-right: 1px solid black; padding: 1em;">
        <div class="p-grid" *ngIf="affiliate.dailyClicks">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ClicksHeader}}</p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p style="margin: 1em 0 0 0;">
              <span class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.PlanedClicks}}:</span> {{affiliate.initialClicks}}
            </p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p style="margin: 1em 0 0 0;">
              <span class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ClicksLeft}}:</span> {{affiliate.currentClicks}}
            </p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p style="margin: 0 0 1em 0;">
              <span class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.PlanedDailyClicks}}:</span> {{affiliate.initialDailyClicks}}
            </p>
          </div>
          <div class="p-col-12 p-lg-6 p-xl-6">
            <p style="margin: 0 0 1em 0;">
              <span class="inner-headline" [pTooltip]=i18nService.localize().AdvertiserAffiliateDetailsComponent.DailyClicksTooltip>
                {{i18nService.localize().AdvertiserAffiliateDetailsComponent.DailyClicksLeft}}:
              </span> {{affiliate.dailyClicks}}
            </p>
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8">
            <div style="width: 50%;margin: 0 auto;">
              <p-chart type="doughnut" [data]="clicksData"></p-chart>
            </div>
          </div>
        </div>
        <div class="p-grid" *ngIf="!affiliate.dailyClicks">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.NoDailyClicksHeader}}</p>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 1em 1em 1em 3em;">
        <div class="p-grid">
          <div class="p-col-12">
            <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.RedirectionHeader}}</p>
          </div>
          <div class="p-col-12" *ngIf="affiliate.link">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Link}}:</p>
            {{affiliate.link}}
          </div>
          <div class="p-col-12" *ngIf="affiliate.code">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Code}}:</p>
            {{affiliate.code}}
          </div>
          <div class="p-col-12" *ngIf="affiliate.files && affiliate.files.length>0">
            <p class="inner-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Files}}:</p>
            <p-table [value]="affiliate.files" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-text-center tableTopLeft">Name</th>
                  <th class="p-text-center tableTopRight">Download</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-file>
                <tr>
                  <td>{{file.name}}</td>
                  <td>
                    <a href="{{file.downloadURL}}" target="_blank">
                      <button pButton pRipple class="p-button-rounded" icon="fas fa-download"></button>
                    </a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

    </div>

    <!-- Statistik Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 2">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
      <div class="p-col-12" style="margin-bottom: 2em;" *ngIf="affiliate.type!='CAMPAIGN'">
        <p class="tab-card-headline" style="margin-top: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.CurrentStatusHeader}}</p>
        <p-progressBar [value]="affiliate.progress"></p-progressBar>
      </div>

      <div class="p-col-6 p-lg-6 p-xl-6" *ngIf="affiliate.type!='CAMPAIGN'">
        <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.StreamerInCampaignHeader}}</p>
        <div class="streamer-block">
          <img src="/assets/images/streamer.svg" alt="streamerLogo">
          <p class="streamer-block-text">{{numberOfStreamer}}</p>
        </div>
      </div>

      <div class="p-col-6 p-lg-6 p-xl-6" *ngIf="deviceData||browserData">
        <p class="tab-card-headline">
          {{i18nService.localize().AdvertiserAffiliateDetailsComponent.ClickUseragentHeader}}
        </p>
        <div class="p-grid">
          <div class="p-col-12 p-md-6" *ngIf="deviceData">
            <p-chart type="pie" [data]="deviceData"></p-chart>
          </div>
          <div class="p-col-12 p-md-6" *ngIf="browserData">
            <p-chart type="doughnut" [data]="browserData"></p-chart>
          </div>
        </div>
      </div>

      <div class="p-col-12" *ngIf="burndownData">
        <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BudgetHeader}}</p>
        <p-tabMenu [model]="burndownItems" *ngIf="burndownData" [activeItem]="burndownItems[0]" [style]="{marginTop: '1em'}"></p-tabMenu>
        <div style="padding: 1em 2em 1em 0;">
          <p-chart *ngIf="burndownData" type="line" [data]="burndownData" [options]="burndownOptions"></p-chart>
        </div>
      </div>

      <div class="p-col-12" *ngIf="burnupData">
        <p class="tab-card-headline" style="margin-bottom: 0">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ClicksHeader}}</p>
        <p-tabMenu [model]="burnupItems" *ngIf="burnupData" [activeItem]="burnupItems[0]" [style]="{marginTop: '1em'}"></p-tabMenu>
        <div style="padding: 1em 2em 1em 0;">
          <p-chart *ngIf="burnupData" type="line" [data]="burnupData" [options]="burnupOptions"></p-chart>
        </div>
      </div>

      <div class="p-col-12" *ngIf="conversionBurnupData && globalService.enableGoogleTags">
        <p class="tab-card-headline" style="margin-bottom: 0">TAG CONVERSIONS</p>
        <p>{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Total}}: {{totalConversions}}</p>
        <p-tabMenu [model]="conversionBurnupItems" *ngIf="conversionBurnupData" [activeItem]="conversionBurnupItems[0]" [style]="{marginTop: '1em'}"></p-tabMenu>
        <div style="padding: 1em 2em 1em 0;">
          <p-chart *ngIf="conversionBurnupData" type="line" [data]="conversionBurnupData" [options]="burnupOptions"></p-chart>
        </div>
      </div>

      <div class="p-col-12" *ngIf="affiliate.hasDemographic()">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserAffiliateDetailsComponent.AudienceHeader}}</p>
        <div class="p-grid">
          <div class="p-col-12 p-md-6" *ngIf="affiliate.demographic.hasGenderInfos()">
            <div style="width: 40%; margin: 0 auto">
              <p-chart type="pie" [data]="genderData"></p-chart>
            </div>
          </div>
          <div class="p-col-12 p-md-6" *ngIf="affiliate.demographic.hasAgeInfos()" style="padding-right: 5em;">
            <p-chart type="bar" [data]="ageData" [options]="chartOptions"></p-chart>
          </div>
          <!--              <div class="p-col-4" *ngIf="selectedCampaign.demographic.hasCountries()">-->
          <!--                <p-chart type="horizontalBar" [data]="countryData" [options]="chartOptions"></p-chart>-->
          <!--              </div>-->
        </div>
      </div>

      <div class="p-col-12" *ngIf="streamerGameData">
        <p class="tab-card-headline" style="margin-bottom: 0">
          {{i18nService.localize().AdvertiserAffiliateDetailsComponent.StreamerKeywordCountHeader}}
        </p>
        <div>
          <p-chart type="bar" [data]="streamerGameData"></p-chart>
        </div>
      </div>

      <div class="p-col-12" *ngIf="heatOptions">
        <p class="tab-card-headline" style="margin-top: 0">
          {{i18nService.localize().AdvertiserAffiliateDetailsComponent.ClickMapHeader}}
        </p>
        <div class="p-grid">
          <div class="p-col-12">
            <p-tabMenu [model]="cwSelectItems" [activeItem]="cwSelectItems[0]"
                       [style]="{marginTop: '1em'}"></p-tabMenu>
          </div>
          <div class="p-col-12">
            <div style="width: 95%; margin: 0 auto">
              <apx-chart [series]="heatOptions.series" [chart]="heatOptions.chart" [dataLabels]="heatOptions.dataLabels"
                         [colors]="heatOptions.colors" [plotOptions]="heatOptions.plotOptions">
              </apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

    </div>

    <!-- Feedback | Fragen Tab -->
    <div class="p-grid" *ngIf="this.activeTab === 3">
      <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.StreamerFeedbackHeader}}</h2>
        <div *ngIf="feedbacks.length > 0">
          <div class="table-scrollable">
            <p-table [value]="feedbacks" [rows]="20" [lazy]="true" (onLazyLoad)="updateFeedbacks($event)"
                     [paginator]="true" [totalRecords]="totalFeedbacks" [loading]="loadingFeedbacks" sortMode="single"
                     sortField="question_date" [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()"
                     responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="question_date" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.FeedbackDate}}
                    <p-sortIcon field="question_date"></p-sortIcon>
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.TextExcerpt}}
                  </th>
                  <th class="tableTopRight">
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.Action}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-feedback>
                <tr>
                  <td>{{feedback.feedbackDate}}</td>
                  <td>{{feedback.getMessageExcerpt()}}</td>
                  <td>
                    <button pButton pRipple type="button" class="p-button-rounded" icon="fas fa-comment-dots"
                            (click)="showText(feedback, 'FEEDBACK')"
                            pTooltip="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ShowFeedbackTooltip}}"
                            tooltipPosition="left" *ngIf="feedback.isMessageOverlyLong()">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!(feedbacks.length>0)">
          <p>{{i18nService.localize().AdvertiserAffiliateDetailsComponent.NoFeedbackInfo}}</p>
        </div>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.StreamerQuestionsHeader}}</h2>
        <div *ngIf="questions.length > 0">
          <div class="table-scrollable">
            <p-table [value]="questions" [rows]="20" [lazy]="true" (onLazyLoad)="updateQuestions($event)"
                     [paginator]="true" [totalRecords]="totalQuestions" [loading]="loadingQuestions"
                     sortMode="single" sortField="question_date" [defaultSortOrder]="getDefaultSortOrder()"
                     [sortOrder]="getDefaultSortOrder()" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="question_date" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.QuestionDate}}
                    <p-sortIcon field="question_date"></p-sortIcon>
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.TextExcerpt}}
                  </th>
                  <th>
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.AnswerDate}}
                  </th>
                  <th class="tableTopRight">
                    {{i18nService.localize().AdvertiserAffiliateDetailsComponent.Action}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-question>
                <tr>
                  <td>{{question.questionDate}}</td>
                  <td>{{question.getMessageExcerpt()}}</td>
                  <td>{{question.answerDate}}</td>
                  <td>
                    <button pButton pRipple type="button" class="p-button-rounded" icon="fas fa-comment-dots"
                            (click)="showText(question, 'QUESTION')"
                            pTooltip="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.ShowQuestionTooltip}}"
                            tooltipPosition="left" *ngIf="question.answer">
                    </button>

                    <button pButton pRipple type="button" class="p-button-rounded" icon="far fa-comment"
                            pTooltip="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.AnswerQuestionTooltip}}"
                            (click)="showAnswerQuestion(question)"
                            tooltipPosition="left" *ngIf="!question.answer">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!(questions.length>0)">
          <p>{{i18nService.localize().AdvertiserAffiliateDetailsComponent.NoQuestionsInfo}}</p>
        </div>
      </div>

      <div class="p-col-12" style="text-align: left">
        <button pButton pRipple type="button" label="{{i18nService.localize().AdvertiserAffiliateDetailsComponent.BackButton}}"
                class="p-button-rounded logout" icon="fas fa-chevron-left" (click)="back()">
        </button>
      </div>
    </div>

    <p-dialog [(visible)]="showFeedbackOrQuestionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{modalHeadline}}</div>
      </ng-template>
      <p [innerHTML]="feedbackOrQuestionText"></p>
      <p  *ngIf="answerText" style="margin-bottom: 0; font-weight: bold">
        {{i18nService.localize().AdvertiserAffiliateDetailsComponent.YourAnswer}}:
      </p>
      <p [innerHTML]="answerText" *ngIf="answerText"></p>
    </p-dialog>

    <p-dialog [(visible)]="showAnswerQuestionModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.AnswerQuestionHeadline}}</div>
      </ng-template>

      <div class="p-fluid">
        <div class="p-field">
          <label style="font-weight: bold;">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Question}}:</label>
          <p style="margin-top: 0">
            {{selectedQuestion}}
          </p>
        </div>
        <div class="p-field">
          <label for="answer" style="font-weight: bold;">{{i18nService.localize().AdvertiserAffiliateDetailsComponent.Answer}}:</label>
          <textarea id="answer" type="text" rows="6" pInputTextarea [(ngModel)]="selectedAnswer"></textarea>

          <p style="color: gray;font-size: small;">
            {{i18nService.localize().AdvertiserAffiliateDetailsComponent.StayRespectfulHint}}
          </p>
        </div>
      </div>

      <div style="text-align: right">
          <button pButton pRipple type="button" icon="fas fa-check" [disabled]="!this.selectedAnswer"
                  class="p-button-rounded" (click)="answerQuestion()">
          </button>
          <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                  class="p-button-rounded p-button-secondary" (click)="this.showAnswerQuestionModal=false">
          </button>
      </div>
    </p-dialog>

  </div>
</div>
