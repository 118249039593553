<div *ngIf="this.globalService.advertiser">
  <div class="headline">{{i18nService.localize().AdvertiserBillsComponent.BillsHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserBillsComponent.CampaignBillsHeader}}</h2>
        <div *ngIf="bills.length > 0">
          <div class="table-scrollable">
            <p-table [value]="bills" [rows]="20" [lazy]="true" (onLazyLoad)="updateBills($event)"
                     [paginator]="true" [totalRecords]="totalBills" [loading]="loadingBills" sortMode="single"
                     sortField="bill_date" [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()"
                     responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="bill_nr" style="width:200px" class="tableTopLeft">
                    {{i18nService.localize().AdvertiserBillsComponent.TableBillNr}}
                    <p-sortIcon field="bill_nr"></p-sortIcon>
                  </th>
                  <th pSortableColumn="bill_date" style="width:200px">
                    {{i18nService.localize().AdvertiserBillsComponent.TableBillDate}}
                    <p-sortIcon field="bill_date"></p-sortIcon>
                  </th>
                  <th style="width:400px">
                    {{i18nService.localize().AdvertiserBillsComponent.TableCampaign}}
                  </th>
                  <th pSortableColumn="sum" style="width:150px">
                    {{i18nService.localize().AdvertiserBillsComponent.TableAmount}}
                    <p-sortIcon field="sum"></p-sortIcon>
                  </th>
                  <th style="width:100px" class="tableTopRight">
                    {{i18nService.localize().AdvertiserBillsComponent.TableDownload}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-campaignBill>
                <tr>
                  <td class="word-break">{{campaignBill.billNr}}</td>
                  <td>{{campaignBill.billDate}}</td>
                  <td>{{campaignBill.campaignName}}</td>
                  <td>{{campaignBill.sum}} €</td>
                  <td>
                    <button pButton pRipple type="button" class="p-button-rounded"
                      (click)="downloadPDF(campaignBill.id)" icon="fas fa-download"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!(bills.length>0)">
          <p>{{i18nService.localize().AdvertiserBillsComponent.NoCampaignBills}}</p>
        </div>
      </div>

    </div>
  </div>
</div>
