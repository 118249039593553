import {Injectable} from '@angular/core';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {I18nService} from './i18n.service';
import {DiscountCode} from '../model/advertiser/discount-code.model';

@Injectable()
export class DiscountService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus, public i18nService: I18nService) {
    super(streamBoostEventBus);
  }

  public checkCode(code: string): Promise<DiscountCode> {
    return new Promise((resolve, reject) => {
      let currentLang = this.i18nService.getCurrentLang();

      this.streamBoostEventBus.send('stream.web.facade.discount.check',
        {
          message: {
            code,
            'lang': currentLang
          }
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
              localStorage.clear();
              return;
            }

            let discountCode = new DiscountCode();
            discountCode.id = response.body.response.code.id;
            discountCode.code = response.body.response.code.code;
            discountCode.percent = response.body.response.code.percent;
            discountCode.type = response.body.response.code.type;
            discountCode.explanation = response.body.response.code.explanation;

            resolve(discountCode)
          }
        )
        .catch(
          (err: any) => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

}
