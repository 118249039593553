<div *ngIf="this.globalService.advertiser">
  <div class="headline">TAGS</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12 start">
        <p-card class="noPadding" [style]="{'height':'100%'}">
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">
              <h2 class="card-headline" style="margin-top: 0;">
                {{i18nService.localize().AdvertiserTagsComponent.TagsHeadline}}
              </h2>
            </div>
            <div class="p-col-12">
              <p style="margin-top: 0">
                {{i18nService.localize().AdvertiserTagsComponent.TagsIntro1}}<br>{{i18nService.localize().AdvertiserTagsComponent.TagsIntro2}}
              </p>

              <ol>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointOne1}}<a href="https://tagmanager.google.com">Google Tag Manager</a>{{i18nService.localize().AdvertiserTagsComponent.PointOne2}}
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointTwo}}
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointThree1}}<b>Tags</b>{{i18nService.localize().AdvertiserTagsComponent.PointThreeOn}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointThreeNew}}</b>.
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointFour1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFourConfig}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFour2}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFourMore}}</b>.
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointFive1}}
                  <ul>
                    <li class="list-item">
                      {{i18nService.localize().AdvertiserTagsComponent.PointFive11}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFiveGoToWorkspace}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFive12}}
                    </li>
                    <li class="list-item">
                      {{i18nService.localize().AdvertiserTagsComponent.PointFive21}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFiveSave}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFive22}}
                    </li>
                  </ul>
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointSix1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointSixIds}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointSix2}}
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointSeven}}
                </li>
                <li class="list-item">
                  {{i18nService.localize().AdvertiserTagsComponent.PointEight1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointEightSave}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointEight2}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointEightSend}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointEight3}}
                </li>
              </ol>
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 abstand">
        <p-table [value]="tags" [rows]="10" [lazy]="true" (onLazyLoad)="updateTags($event)" [paginator]="true"
                 [totalRecords]="totalRecordsTags" [loading]="loadingTags" sortMode="single" sortField="start_date"
                 [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()" *ngIf="(tags.length>0)"
                 responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="p-text-center tableTopLeft" pSortableColumn="name" style="width:450px">
                {{i18nService.localize().AdvertiserTagsComponent.CampaignName}}
                <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="status" style="width:100px">
                Status
                <p-sortIcon field="status"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="tag_id" >
                Tag-ID
                <p-sortIcon field="tag_id"></p-sortIcon>
              </th>
              <th class="p-text-center" >
                Advertiser-ID
              </th>
              <th class="p-text-center tableTopRight">
                {{i18nService.localize().AdvertiserTagsComponent.TotalConversions}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tag>
            <tr>
              <td>{{tag.affiliateName}}</td>
              <td>{{tag.status}}</td>
              <td>
                <div class="p-grid">
                  <div class="p-col-7" style="overflow: scroll">
                    <p>
                      {{tag.tagId}}
                    </p>
                  </div>
                  <div class="p-col-5">
                    <button pButton pRipple type="button" label="Id kopieren" style="margin-top: 0.5em;"
                            icon="pi pi-clone" class="p-button-rounded" (click)="copyLink(tag.tagId)">
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <div class="p-grid">
                  <div class="p-col-6" style="overflow: scroll">
                    <p>
                      {{tag.advertiserId}}
                    </p>
                  </div>
                  <div class="p-col-6">
                    <button pButton pRipple type="button" label="Id kopieren" style="margin-top: 0.5em;"
                            icon="pi pi-clone" class="p-button-rounded" (click)="copyLink(tag.advertiserId)">
                    </button>
                  </div>
                </div>
              </td>
              <td>{{tag.totalConversions}}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="!(tags.length>0)">
           <p>
             {{i18nService.localize().AdvertiserTagsComponent.NoTags}}
           </p>
        </div>
      </div>

    </div>

  </div>
</div>
