<div *ngIf="this.globalService.streamer">
  <div class="headline">{{this.i18nService.localize().StreamerCampaignsComponent.CampaignsHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12 p-lg-6 p-xl-5">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerCampaignsComponent.YourCampaigns}}</h2>
        <streamer-campaigns-overview [showQuitButton]="true" [showViews]="false" [showPictureHint]="true" [bigFonts]="false">
        </streamer-campaigns-overview>

        <h2 class="card-headline">{{this.i18nService.localize().StreamerCampaignsComponent.AdvertisingPrefHeader}}</h2>
        <p-card>
          <div>
            <p class="card-p" *ngIf="this.globalService.streamer.hasKeywords()">{{this.i18nService.localize().StreamerCampaignsComponent.KeywordsHint}}</p>
            <p class="card-p" *ngIf="!this.globalService.streamer.hasKeywords()">{{this.i18nService.localize().StreamerCampaignsComponent.NoKeywordsHint}}</p>
            <div *ngIf="!changeKeywords">
              <p-tag *ngFor="let keyword of this.globalService.streamer.adKeywords" value="{{keyword}}"
                     [rounded]="true" [style]="{'margin':'0.25em'}">
              </p-tag>
            </div>
            <div *ngIf="changeKeywords">
              <ng-multiselect-dropdown3 [settings]="this.dropdownSettings" [data]="keywordsDropDownList" [(ngModel)]="selectedKeywordItems">
              </ng-multiselect-dropdown3>
            </div>
          </div>
          <p-footer>
            <button pButton pRipple type="button" class="p-button-rounded" (click)="changeKeyword()" icon="fas fa-pencil-alt" *ngIf="!changeKeywords" style="margin-right: .25em"></button>
            <button pButton pRipple type="button" class="p-button-rounded" (click)="saveKeywordChanges()" icon="fas fa-check" *ngIf="changeKeywords" style="margin-right: .25em"></button>
            <button pButton pRipple type="button" class="p-button-rounded p-button-secondary" (click)="abortKeywordChanges()" icon="fas fa-times" *ngIf="changeKeywords"></button>
          </p-footer>
        </p-card>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerCampaignsComponent.TutorialHeader}}</h2>
          <video width="100%" controls preload="auto">
            <source src="assets/images/Tutorial_Displayanzeigen.mp4" type="video/mp4">
            {{this.i18nService.localize().StreamerCampaignsComponent.VideoNotSupportedError}}
          </video>
      </div>

      <div class="p-col-12 p-lg-11 p-xl-11">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationHeader}}</h2>
        <p-card>
          <div class="p-grid">
            <div class="p-col-12 p-xl-4">
              <img src="assets/images/AdinLivestream.gif" alt="stream-example" style="width: 100%;">
            </div>
            <div class="p-col-12 p-xl-8">
              <p class="display-text">
                {{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationText1}}
                <a (click)="moreStatusInfoModal=true" style="text-decoration: underline; cursor: pointer">{{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationLink}}</a>.
                {{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationText2}}
              </p>
              <p class="income-header">{{this.i18nService.localize().StreamerCampaignsComponent.YourIncomeHeader}}</p>
              <p class="display-text">
                {{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationText3}}
              </p>
              <p class="display-text">
                {{this.i18nService.localize().StreamerCampaignsComponent.CampaignExplanationText4}}
              </p>
            </div>
            <div class="p-col-12 p-xl-4">
              <p-image src="assets/images/OBS_Vorschau.jpg" alt="Image" width="95%" [preview]="true"></p-image>
            </div>
            <div class="p-col-12 p-xl-8">
              <div class="p-grid">
                <div class="p-col-12">
                  <p class="income-header">{{this.i18nService.localize().StreamerCampaignsComponent.OBSHelpHeader}}</p>
                  <p class="display-text">
                    {{this.i18nService.localize().StreamerCampaignsComponent.OBSHelpText}}
                  </p>
                </div>
                <div class="p-col-12 p-xl-6">
                  <p class="link-header">
                    Ad-Link
                  </p>
                  <p style="margin-top: 0;text-decoration: underline;word-wrap: break-word;" *ngIf="this.globalService.streamer.active">
                    {{this.globalService.streamer.ad_url}}
                  </p>
                  <p style="margin-top: 0;text-decoration: underline;word-wrap: break-word;" *ngIf="!this.globalService.streamer.active">
                    {{this.i18nService.localize().StreamerCampaignsComponent.Adlinkdeactivated}}
                  </p>
                </div>
                <div class="p-col-12 p-xl-6">
                  <p class="link-header">
                    CSS-Code
                  </p>
                  <p style="margin-top: 0;word-wrap: break-word;">
                    * {{ '{' }}background-color: rgba(0, 0, 0, 0) !important; overflow: hidden}
                  </p>
                </div>
              </div>
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-01.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 infoText">
              {{this.i18nService.localize().StreamerCampaignsComponent.Step1Text}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-02.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 infoText">
              {{this.i18nService.localize().StreamerCampaignsComponent.Step2Text}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-03.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 infoText" style="margin-left: 0;">
              {{this.i18nService.localize().StreamerCampaignsComponent.Step3Text}}
            </div>
          </div>
        </p-card>
      </div>

      <streamer-status-dialog [show]="moreStatusInfoModal" (showChange)="moreStatusInfoModal=$event"></streamer-status-dialog>

    </div>
  </div>
</div>
