import {Component, OnInit} from '@angular/core';
import {RoutingService} from '../../services/routing.service';
import {AdvertiserService} from "../../services/advertiser.service";
import {CookieService} from 'ngx-cookie-service';
import {IpService} from '../../services/ip.service';
import {I18nService} from '../../services/i18n.service';

@Component({
  selector: 'advertiser-login',
  templateUrl: './advertiser-login.component.html' ,
  styleUrls: ['./advertiser-login.component.css']
})
export class AdvertiserLoginComponent implements OnInit{
  email: string = "";
  password: string = "";
  loginError: string = "";
  activationResend: boolean;

  working: boolean = false;

  checked: boolean = false;

  constructor(private advertiserService: AdvertiserService, public routingService: RoutingService,
              public cookieService: CookieService, public ipService: IpService,
              public i18nService: I18nService) {
    this.email = "";
    this.password = "";
    this.activationResend = false;

    if (this.cookieService.get('streamboost-language')) {
      this.i18nService.setLang(this.cookieService.get('streamboost-language'));
    } else {
      this.i18nService.setLang(navigator.language.toUpperCase());
    }
  }

  ngOnInit(): void {
    // Weiterleitung falls es einen vorhandenen Session Cookie gibt
    if (this.cookieService.get('streamboost-session')) {
      this.working = true;
      var token = this.cookieService.get('streamboost-session');
      this.advertiserService.userLoggedIn(token).then((data) => {
        this.routingService.switchToPathWithReload('/advertiser/dashboard');
        this.working = false;
      }).catch((error) => {
        this.advertiserService.logout(false);
        this.working = false;
      })
    }
  }

  resetError() {
    this.loginError='';
  }

  login() {
    if (this.email === '' || !this.email) {
      this.loginError = 'email';
      return;
    } else if (this.password === '' || !this.password) {
      this.loginError = 'Password';
      return;
    }

    this.working = true;
    this.ipService.getIp().then(res => {

      this.advertiserService.authenticate(this.email, this.password, res.ip, this.checked).then((data) => {
        this.routingService.switchToPathWithReload('/advertiser/dashboard');
        this.working = false;
      })
      .catch((error) => {
        this.loginError = error;
        this.working = false;
      });

    }).catch((err) => {
      console.log(err);
      this.working = false;
    });
  }

}
