export class DisplayClickBill {

  public id!: string;

  public billNr!: string;

  public billDate!: string;

  public campaignName!: string;

  public sum!: string;

  constructor() {}

}
