<div class="p-grid" *ngIf="!streamBoostOnline" style="text-align: center;padding-top: 5em;">
  <div class="p-col"></div>
  <div class="p-col-12 p-md-5 p-lg-4">
    <p-progressSpinner></p-progressSpinner>
    <p style="font-weight: bold;">{{i18nService.localize().AppComponent.Loading}}</p>
  </div>
  <div class="p-col"></div>
</div>

<div class="p-grid p-nogutter" *ngIf="streamBoostOnline">

  <div class="p-col-12 {{mobileNavBar ? 'fixedMenu' : ''}}" style="padding: 0;" *ngIf="showMenu">

    <p-megaMenu>
      <i class="fas fa-bars menuButton p-ml-5 p-mr-3" (click)="changeMenu()" *ngIf="!globalService.menuIsOpen"></i>
      <i class="fas fa-times menuButton menuButtonCross p-ml-5 p-mr-3" (click)="changeMenu()"
        *ngIf="globalService.menuIsOpen"></i>
      <img src="assets/images/final_logo.png" height="60" class="p-mr-2">
      <div class="notification" *ngIf="notificationText&&notificationText.length>0">
        <p-tag severity="danger" [rounded]=true value="{{notificationText}}"></p-tag>
      </div>
      <button pButton pRipple type="button" label="Logout" class="p-button-rounded logout hideForMobile"
        (click)="logout()" *ngIf="globalService.streamer||globalService.advertiser||globalService.agency">
      </button>
      <div class="socialIcons hideForMobile">
        <a href="https://www.youtube.com/channel/UCM3YPLqE8KpocXwbdQAoHkA" target="_blank" class="social_link">
          <i class="fab fa-youtube-square"></i>
        </a>
        <a href="https://www.facebook.com/streamboost.de" target="_blank" class="social_link">
          <i class="fab fa-facebook-square"></i>
        </a>
        <a href="https://www.instagram.com/streamboost.de/" target="_blank" class="social_link">
          <i class="fab fa-instagram-square"></i>
        </a>
        <a href="https://www.linkedin.com/company/streamboost-gmbh" target="_blank" class="social_link">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://discord.gg/YCG9VBfuQP" target="_blank" class="social_link">
          <i class="fab fa-discord"></i>
        </a>
        <button pButton pRipple type="button" class="p-button-rounded p-button-text" (click)="this.visibleHelpBar = true"
                icon="pi pi-question-circle" style="padding: 0.7rem 0 0.3rem 0 !important;" pTooltip="Hilfe oder Fragen"
                *ngIf="globalService.streamer||globalService.advertiser||globalService.agency">
        </button>
      </div>
    </p-megaMenu>

    <mobileMenu [class]="calculateMenuHeight()" *ngIf="mobileNavBar" style="display: block">
    </mobileMenu>

  </div>

  <menu class="{{ globalService.menuIsOpen ? 'normalMenu' : 'miniMenu' }} side-menu hideMenuForMobile"
    *ngIf="showMenu && !mobileNavBar"></menu>

  <div class="fillPage" [class]="determineContentClass()" style="padding: 0">
    <router-outlet></router-outlet>
  </div>

  <p-sidebar [(visible)]="visibleHelpBar" position="right" [modal]="false">
    <h3>{{i18nService.localize().AppComponent.HelpHeader}}</h3>

    <p>
      {{i18nService.localize().AppComponent.HelpText}}
      <a href="{{this.globalService.links.streamerDocLink}}" target="_blank" *ngIf="globalService.streamer">{{i18nService.localize().AppComponent.Handbook}}</a>
      <a href="{{this.globalService.links.advertiserDocLink}}" target="_blank" *ngIf="globalService.advertiser||globalService.agency">{{i18nService.localize().AppComponent.Handbook}}</a>
    </p>
    <p *ngIf="globalService.streamer||globalService.advertiser">
      {{i18nService.localize().AppComponent.HelpText2}}
    </p>

    <div *ngIf="globalService.streamer||globalService.advertiser||globalService.agency">
      <div class="p-fluid">

        <div class="p-field">
          <label for="subject">{{i18nService.localize().AppComponent.HelpSubject}}</label>
          <input type="text" id="subject" pInputText [(ngModel)]="subject">
        </div>

        <div class="p-field">
          <label for="reason">{{i18nService.localize().AppComponent.HelpConcern}}</label>
          <textarea [rows]="5" [cols]="30" pInputTextarea id="reason" [(ngModel)]="concern">
          </textarea>
        </div>

      </div>

      <button pButton pRipple type="submit" icon="fas fa-check" class="p-button-rounded" (click)="submitEmail()"
              pTooltip="{{i18nService.localize().AppComponent.HelpSendTooltip}}"></button>
    </div>

  </p-sidebar>

  <accept-terms-dialog [show]="showTermsDialog" (showChange)="showTermsDialog=$event">
  </accept-terms-dialog>



  <p-dialog [modal]="true" position="center" [(visible)]="showSetGenderDialog" [blockScroll]="true" [closeOnEscape]="false" [closable]="false"
            [baseZIndex]="1" [draggable]="false" [style]="{width: '70%', maxWidth: '770px'}">
    <ng-template pTemplate="header">
      <div class="headline">{{i18nService.localize().AppComponent.SetGenderHeader}}</div>
    </ng-template>

    <div class="ui-fluid">
      <p class="textWithNewlines">{{i18nService.localize().AppComponent.SetGenderText}}</p>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12" *ngIf="!hasAddress">
        <label for="postalCode" style="font-weight: bold">{{i18nService.localize().AppComponent.PostalCode}}*:</label>
        <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field"
               style="border-radius: 12px;">
      </div>

      <div class="p-field p-col-12" *ngIf="!hasGender">
        <label for="gender" style="font-weight: bold">{{i18nService.localize().AppComponent.GenderStreamer}}*:</label>
        <p-selectButton [options]="genderOptions" id="gender" [(ngModel)]="gender" optionLabel="label"
                        optionValue="value" [style]="{'border-radius':'12px'}">
        </p-selectButton>
      </div>
    </div>

    <div style="text-align: right">
      <button pButton pRipple type="button" (click)="updateStreamerGender()" icon="fas fa-check"
              style="margin-right: .25em" class="p-button-rounded" [disabled]="!checkStreamerValues()">
      </button>
<!--      <button pButton pRipple type="button" (click)="closeGenderDialog()" icon="fas fa-times"-->
<!--              class="p-button-rounded p-button-secondary">-->
<!--      </button>-->
    </div>
  </p-dialog>

  <div class="p-col-12 mobilePolicy" *ngIf="mobileNavBar">
    <ul>
      <li><a href="{{this.globalService.links.legalLink}}"
          target="_blank">{{this.i18nService.localize().MenuComponent.LegalNoticeLink}}</a></li>
      <li><a href="{{this.globalService.links.privacyLink}}"
          target="_blank">{{this.i18nService.localize().MenuComponent.PrivacyPolicyLink}}</a></li>
      <li><a href="{{this.globalService.links.termsLink}}"
          target="_blank">{{this.i18nService.localize().MenuComponent.TermsOfUseLink}}</a></li>
      <li><a href="{{this.globalService.links.cookiesLink}}"
          target="_blank">{{this.i18nService.localize().MenuComponent.CookiesInfoLink}}</a></li>
    </ul>
  </div>

</div>
