import {Injectable} from '@angular/core';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {Advertiser} from '../model/advertiser/advertiser.model';
import {Streamer} from '../model/streamer/streamer.model';
import {StreamerService} from './streamer.service';
import {AdvertiserService} from './advertiser.service';
import {Subscription} from 'rxjs';
import {Links} from '../model/links.model';
import {Agency} from '../model/agency/agency.model';
import {AgencyService} from './agency.service';

@Injectable()
export class GlobalService extends SuperService {

  public userSet = false;

  public menuIsOpen: boolean = false;
  public showMenuText: boolean = false;

  public links: Links = new Links();
  public enableYoutubeAuth: boolean = false;
  public enableGoogleTags: boolean = false;
  public activeCompetition: boolean = false;

  public streamerUpdateSubscription: Subscription;
  public advertiserUpdateSubscription: Subscription;
  public agencyUpdateSubscription: Subscription;

  private urlRegEx = "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+-~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
  private emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  /**
   * Die einzige Möglichkeit Advertiser Infos zu bekommen
   */
  public advertiser?: Advertiser;

  /**
   * Die einzige Möglichkeit Streamer Infos zu bekommen
   */
  public streamer?: Streamer;

  /**
   * Die einzige Möglichkeit Agentur Infos zu bekommen
   */
  public agency?: Agency;

  constructor(public streamBoostEventBus: StreamBoostEventBus, private streamerService: StreamerService,
    private advertiserService: AdvertiserService, private agencyService: AgencyService) {
    super(streamBoostEventBus);

    this.streamerUpdateSubscription = this.streamerService.streamerChanged.subscribe((new_streamer) => {
      this.streamer = new_streamer;
    });

    this.advertiserUpdateSubscription = this.advertiserService.advertiserChanged.subscribe((new_advertiser) => {
      this.advertiser = new_advertiser;
    });

    this.agencyUpdateSubscription = this.agencyService.agencyChanged.subscribe((new_agency) => {
      this.agency = new_agency;
    });
  }

  /**
   * Prüft ob ein Nutzer angemeldet ist, indem versucht wird mit dem vielleicht vorhanden Token einen Nutzer zu laden.
   * Es wird immer true reported weil es ja auch sein kann das wir uns auf einer öffentlichen Seite befinden (login / register),
   * außer Streamboost ist generell nicht online.
   *
   * !! Wichtig ist allein die Tatsache das ein Nutzer schon direkt im Voraus geladen wird !!
   */
  public isUserSet(): Promise<boolean> {
    return new Promise((resolve) => {

      // Erst wird geprüft ob Streamboost überhaupt online ist
      if (this.streamBoostEventBus.isOnline()) {

        // Dann wird versucht einen Streamer zu laden
        this.streamerService.updateStreamerInformation()
          .then(() => {
              resolve(true);
          })
          .catch(() => {

            // Falls das nicht geht wird versucht einen Advertiser zu laden
            this.advertiserService.updateAdvertiserInformation()
              .then(() => {
                resolve(true);
              })
              .catch(() => {

                // Als letzte Möglichkeit wird versucht eine Agentur zu laden
                this.agencyService.updateAgencyInformation()
                  .then(() => {
                    resolve(true);
                  })
                  .catch(() => {
                    resolve(true);
                  });
              })
            }
          )
      } else {
        resolve(false);
      }
    });

  }

  public sleep(timeInMs: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, timeInMs));
  }

  public isEmailOk(search: string): boolean {
    var searchfind: boolean;
    searchfind = this.emailReg.test(search);
    return searchfind
  }

  public isValidUrl(urlString: string): boolean {
    try {
      let pattern = new RegExp(this.urlRegEx);
      let valid = pattern.test(urlString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }

  /**
   * Abfragen der generellen Links
   */
  public updateLinksAndVersion() {
    this.streamBoostEventBus.send(
      'stream.web.facade.system.infos',
      { 'message': '' })
      .then((response: any) => {
        if (response.body.code) {
          console.log(response.body.code);
          return;
        }

        var infos = response.body.response;
        this.links = new Links();
        this.links.deleteAccount = infos.deleteAccount;
        this.links.legalLink = infos.legal_link;
        this.links.siteURL = infos.streamer_url;
        this.links.adminURL = infos.admin_url;
        this.links.termsLink = infos.terms_link;
        this.links.privacyLink = infos.privacy_link;
        this.links.cookiesLink = infos.cookies_link;
        this.links.streamerDocLink = infos.streamer_doc_link;
        this.links.advertiserDocLink = infos.advertiser_doc_link;
        this.links.conditionsLink = infos.conditions_link;
      });
  }

  /**
   * Abfragen ob der Youtube Auth aktiviert ist
   */
  public updateEnableYoutubeAuth() {
    this.streamBoostEventBus.send(
      'stream.web.facade.system.youtube.switch',
      { 'message': '' })
      .then((response: any) => {
        if (response.body.code) {
          console.log(response.body.code);
          return;
        }

        this.enableYoutubeAuth = response.body.response.youtube_auth;
      });

  }

  /**
   * Abfragen ob die Google Tags aktiviert ist
   */
  public updateEnableGoogleTags(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.system.tags.switch',
        { 'message': '' })
        .then((response: any) => {
          if (response.body.code) {
            console.log(response.body.code);
            return;
          }

          this.enableGoogleTags = response.body.response.google_tags;

          resolve(this.enableGoogleTags);
        });
      }
    );
  }

  /**
   * Abfragen ob es im Moment ein laufendes Gewinnspiel gibt
   */
  public updateCompetitionAvailable(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.system.competition.switch',
          { 'message': '' })
          .then((response: any) => {
            if (response.body.code) {
              console.log(response.body.code);
              return;
            }

            this.activeCompetition = response.body.response.active_competition;

            resolve(this.activeCompetition);
          });
      }
    );
  }

  /**
   * Sendet eine Hilfe Email an Streamboost
   */
  public sendHelpMail(subject:String, concern: String): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.send.help.email',
          {
            'message': {
              subject,
              concern
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getCurrentUserType(): string {
    if (this.streamer) {
      return "streamer";
    } else if (this.advertiser) {
      return "advertiser";
    } else if (this.agency) {
      return "agency";
    } else {
      return "NONE";
    }
  }

  public hasLoggedInUserAcceptedTheCurrentTerms(): boolean {
    if (this.streamer && !this.streamer.terms_confirmation) {
      return false;
    } else if (this.advertiser && !this.advertiser.agbConfirmation) {
      return false;
    } else if (this.agency && this.agency.isUserAdmin() && !this.agency.agbConfirmation) {
      return false;
    } else {
      return true;
    }
  }

}
