import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalService} from 'src/app/services/global.service';
import {I18nService} from 'src/app/services/i18n.service';
import {IpService} from 'src/app/services/ip.service';
import {RoutingService} from 'src/app/services/routing.service';
import {AgencyAdvertiser} from '../../model/agency/agency-advertiser.model';
import {KeywordService} from '../../services/keyword.service';
import {AdvertiserService} from '../../services/advertiser.service';
import {PictureService} from '../../services/picture.service';
import {AgencyService} from '../../services/agency.service';

@Component({
  selector: 'agency-advertisers',
  templateUrl: './agency-advertisers.component.html',
  styleUrls: ['./agency-advertisers.component.scss']
})
export class AgencyAdvertisersComponent implements OnInit {

  public selectedAdvertiserId?:string;

  // Advertiser Edit
  public selectedAdvertiserForEdit?: AgencyAdvertiser;
  public showEditAdvertiser: boolean = false;
  public countryEdit: any;
  public countriesDropDownList : any[] = [];

  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public picturesOk = true;

  constructor(public globalService: GlobalService, public ipService: IpService,
              public i18nService: I18nService, public routingService: RoutingService,
              public keywordService: KeywordService, public route: ActivatedRoute,
              public advertiserService: AdvertiserService, public agencyService: AgencyService,
              private pictureService: PictureService) {
  }

  ngOnInit(): void {

    let advertiserIdFromRoute = this.route.snapshot.params.advertiserId;
    if (advertiserIdFromRoute) {
      this.selectedAdvertiserId = advertiserIdFromRoute;
    }

    this.keywordService.getAllCountries().then((countries) => {
      this.countriesDropDownList = [];
      countries.forEach((country) => {
        this.countriesDropDownList.push(
          {
            // @ts-ignore
            item_id: country.id,
            // @ts-ignore
            item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
          });
      });
    }).catch((err) => {
      console.log(err);
    });

  }

  selectAdvertiserForDetails(advertiser: AgencyAdvertiser) {
    this.selectedAdvertiserId = advertiser.id;
  }

  closeAdvertiserDetails() {
    delete this.selectedAdvertiserId;
  }

  // Advertiser Edit
  selectAdvertiserForEdit(advertiser: AgencyAdvertiser) {
    this.showEditAdvertiser = true;
    this.selectedAdvertiserForEdit = advertiser;
    this.countryEdit = this.selectedAdvertiserForEdit.getCountryEntry();
    this.uploadedPicturesBackup = [];
    this.uploadedPictures = [];
  }

  changeAdvertiser() {
    if (this.selectedAdvertiserForEdit) {

      this.advertiserService.changeProfileInformation(this.selectedAdvertiserForEdit.companyName,
        this.selectedAdvertiserForEdit.website, this.selectedAdvertiserForEdit.street, this.selectedAdvertiserForEdit.nr,
        this.selectedAdvertiserForEdit.city, this.selectedAdvertiserForEdit.postalCode, this.countryEdit.item_id,
        this.selectedAdvertiserForEdit.id).then(result => {

          if (this.uploadedPicturesBackup.length == 1 && this.selectedAdvertiserForEdit) {
            this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, this.selectedAdvertiserForEdit.id, 'LOGO', 'advertiser')
              .then(() => {
                setTimeout(() => {
                  this.agencyService.updateAgencyInformation();
                  this.closeAdvertiserEdit();
                }, 200);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.closeAdvertiserEdit();
          }
      });
    }
  }

  closeAdvertiserEdit() {
    this.showEditAdvertiser = false;
    this.countryEdit = {};
    delete this.selectedAdvertiserForEdit;
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.checkPicture(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  checkPicture(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {

          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_width > 500) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }
}
