export class Competition {

  public id!: string;

  public name!: string;
  public description!: string;
  public logoLink!: string;
  public link!: string;

  constructor() {}

}
