<p-dialog [(visible)]="show" closeIcon="fas fa-times" [style]="{width: '90vw', maxWidth: '1035px'}" (visibleChange)="updateShow()">
  <ng-template pTemplate="header">
    <div class="headline">{{i18nService.localize().StreamerStatusDialogComponent.OverviewHeader}}</div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-4">
      <p-card [style]="{'height':'100%'}">
        <ng-template pTemplate="header">
          <img src="assets/images/bronze_3.png" alt="Bronze" style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
        </ng-template>
        <p class="bronze-headline">{{i18nService.localize().StreamerStatusDialogComponent.Benefits}}:</p>
        <ul>
          <li>3 {{i18nService.localize().StreamerStatusDialogComponent.ProgramsToChoose}}</li>
          <li>TKP: 1,60€</li>
        </ul>
        <hr style="border: 1px solid #f8bf8e; margin-right: 9em;">
        <p class="bronze-headline">{{i18nService.localize().StreamerStatusDialogComponent.Requirements}}:</p>
        <p>-</p>
      </p-card>
    </div>
    <div class="p-col-4">
      <p-card>
        <ng-template pTemplate="header">
          <img src="assets/images/silber_3.png" alt="Silber" style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
        </ng-template>
        <p class="silber-headline">{{i18nService.localize().StreamerStatusDialogComponent.Benefits}}:</p>
        <ul>
          <li>5 {{i18nService.localize().StreamerStatusDialogComponent.ProgramsToChoose}}</li>
          <li>TKP: 2,40€</li>
        </ul>
        <hr style="border: 1px solid #a2a1a1; margin-right: 9em;">
        <p class="silber-headline">{{i18nService.localize().StreamerStatusDialogComponent.Requirements}}:</p>
        <ul>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.SilverRequirement1}}</li>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.SilverRequirement2}}</li>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.SilverRequirement3}}</li>
        </ul>
      </p-card>
    </div>
    <div class="p-col-4">
      <p-card>
        <ng-template pTemplate="header">
          <img src="assets/images/gold_3.png" alt="Gold" style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
        </ng-template>
        <p class="gold-headline">{{i18nService.localize().StreamerStatusDialogComponent.Benefits}}:</p>
        <ul>
          <li>8 {{i18nService.localize().StreamerStatusDialogComponent.ProgramsToChoose}}</li>
          <li>TKP: 3,20€</li>
        </ul>
        <hr style="border: 1px solid #f2c54c; margin-right: 9em;">
        <p class="gold-headline">{{i18nService.localize().StreamerStatusDialogComponent.Requirements}}:</p>
        <ul>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.GoldRequirement1}}</li>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.GoldRequirement2}}</li>
          <li>{{i18nService.localize().StreamerStatusDialogComponent.GoldRequirement3}}</li>
        </ul>
      </p-card>
    </div>
  </div>
</p-dialog>
