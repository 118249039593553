export class MultilangModel {

  private langMap = new Map<string, object>();

  constructor(langMap: Map<string, object>) {
    this.langMap = langMap;
  }

  public getValueInLanguage(value: string, preferredLang: string) : string {

    if (this.langMap.has(preferredLang)) {
      let lang = this.langMap.get(preferredLang);
      // @ts-ignore
      return lang[value];
    } else {
      let otherLang = this.getOtherLang(preferredLang);
      let lang = this.langMap.get(otherLang);
      // @ts-ignore
      return lang[value];
    }

  }

  private getOtherLang(lang: string) : string {
    if (lang.toUpperCase() === 'DE') {
      return 'EN';
    } else {
      return 'DE';
    }
  }

}
