import {Component, OnInit} from '@angular/core';
import {GlobalService} from 'src/app/services/global.service';
import {I18nService} from 'src/app/services/i18n.service';
import {PictureService} from '../../services/picture.service';
import {Subscription} from "rxjs";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageCroppedEvent, LoadedImage} from "ngx-image-cropper";

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class AgencyPicturesComponent implements OnInit {

  public pictures: { id: string, name: string, uploadDate: string, uploadUser: string, usableBy: string }[] = []

  public showAskDelete: boolean = false;
  public pictureIdToDelete?: string;

  public showPicUpload = false;
  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: File[] = [];
  public picturesOk = true;
  public working = false;
  public uploadPicture: boolean = false;
  public uploadGif: boolean = false;

  public langChangeSubscription!: Subscription;

  public imageChangedEvent: Event | null = null;
  public croppedImage: SafeUrl = '';
  public showCropper = false;
  public fileName: string = '';

  constructor(
    public globalService: GlobalService,
    public i18nService: I18nService,
    private pictureService: PictureService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.getAgencyPictures();

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      if (this.globalService.agency) {
        this.getAgencyPictures();
      }
    });
  }

  public blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, {type: blob.type});
  }


  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showCropper = true;
    this.fileName = event.target.files[0].name;
  }

  public imageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl != null) {
      this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
      if (event.blob) {
        const file = this.blobToFile(event.blob, this.fileName);
        this.uploadedPicturesBackup = [file];
      }
    }
  }

  public imageLoaded(image: LoadedImage) {
    // show cropper
  }

  public cropperReady() {
    this.showCropper = true;
  }

  public loadImageFailed() {
    // show message
  }

  private getAgencyPictures() {
    this.pictures = [];
    this.pictureService.getAllAgencyPictures().then((res) => {
      let currentLang = this.i18nService.getCurrentLang();
      let allPic: { id: string, name: string, uploadDate: string, uploadUser: string, usableBy: string }[] = [];

      for (let i = 0; i < res.pictures.length; i++) {
        let usableBy = res.pictures[i].usable_as;

        let usableString: string = '';
        if (currentLang == 'DE') {
          usableString += 'Benutzbar als: ';
        } else {
          usableString += 'Usable as: '
        }

        usableString += usableBy[0];
        for (let j = 1; j < usableBy.length; j++) {
          usableString += ', ' + usableBy[j];
        }

        allPic.push({
          id: res.pictures[i]._id, name: res.pictures[i].name, uploadDate: res.pictures[i].upload_date,
          uploadUser: res.pictures[i].username, usableBy: usableString
        })
      }
      this.pictures = allPic;
    });
  }

  public askDeletePicture(pictureId: string) {
    this.pictureIdToDelete = pictureId;
    this.showAskDelete = true;
  }

  public closeAskDelete() {
    delete this.pictureIdToDelete;
    this.showAskDelete = false;
  }

  public deletePicture() {
    if (this.pictureIdToDelete) {
      this.pictureService.deleteAgencyPicture(this.pictureIdToDelete).then(result => {
        this.getAgencyPictures();
        this.closeAskDelete();
      });
    }
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.checkPicture(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  checkPicture(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {

          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_width > 500) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }

  uploadPic() {
    if (this.globalService.agency) {
      this.working = true;
      this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, this.globalService.agency.id, 'AGENCY', 'agency')
        .then(() => {
          setTimeout(() => {
            this.getAgencyPictures();
            this.working = false;
            this.uploadPicture = false;
          }, 200);
        })
        .catch(error => {
          console.log(error);
          this.working = false;
          this.uploadPicture = false;
        });
    }
  }

  showUploadPicture() {
    this.imageChangedEvent = null;
    this.croppedImage = '';
    this.fileName = '';
    this.showCropper = false;
    this.uploadedPictures = [];
    this.uploadedPicturesBackup = [];
    this.uploadPicture = !this.uploadPicture
  }

  showUploadGif() {
    this.uploadedPictures = [];
    this.uploadedPicturesBackup = [];
    this.uploadGif = !this.uploadGif
  }

}
