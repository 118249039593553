<p-megaMenu [model]="items" orientation="vertical" *ngIf="menuIsShown">
  <ng-template pTemplate="end">
    <div class="notification" *ngIf="notificationText&&notificationText.length>0">
      <p-tag severity="danger" [rounded]=true value="{{notificationText}}"></p-tag>
    </div>
    <hr *ngIf="(globalService.streamer&&!globalService.streamer.inRegister)||globalService.advertiser">
    <div class="menuText" *ngIf="globalService.streamer&&!globalService.streamer.inRegister">
      {{this.i18nService.localize().MenuComponent.CurrentStatus}}<br>
      <div class="status-bronze" *ngIf="globalService.streamer.tier==1">
        {{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
      <div class="status-silber" *ngIf="globalService.streamer.tier==2">
        {{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
      <div class="status-gold" *ngIf="globalService.streamer.tier==3">
        {{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
    </div>
    <div class="menuText" *ngIf="globalService.streamer&&!globalService.streamer.inRegister">
      <button pButton pRipple type="button" label="Logout" class="p-button-rounded logout hideForMobile"
        (click)="logout()"></button>
    </div>
    <div class="menuText">
      <a class="lan {{isLang('DE') ? 'active' : ''}}" (click)="changeLang('DE')">DE <span
          class="lanSeparator">|&nbsp;</span></a>
      <a class="lan {{isLang('EN') ? 'active' : ''}}" (click)="changeLang('EN')">EN</a>
    </div>
    <div class="menuText">
      <a style="color: red; cursor: pointer; display: block" *ngIf="globalService.streamer&& !this.globalService.streamer.inRegister" (click)="deleteStreamerModal=true">
        {{this.i18nService.localize().MenuComponent.DeleteAccount}}
      </a>
    </div>
    <div class="menuText" *ngIf="globalService.advertiser||globalService.agency">
      <button pButton pRipple type="button" label="Logout" class="p-button-rounded logout hideForMobile"
        (click)="logout()"></button>
    </div>
    <div class="socialIcons">
      <a href="https://www.youtube.com/channel/UCM3YPLqE8KpocXwbdQAoHkA" target="_blank" class="social_link">
        <i class="fab fa-youtube-square"></i>
      </a>
      <a href="https://www.facebook.com/streamboost.de" target="_blank" class="social_link">
        <i class="fab fa-facebook-square"></i>
      </a>
      <a href="https://www.instagram.com/streamboost.de/" target="_blank" class="social_link">
        <i class="fab fa-instagram-square"></i>
      </a>
      <a href="https://www.linkedin.com/company/streamboost-gmbh" target="_blank" class="social_link">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="https://discord.gg/YCG9VBfuQP" target="_blank" class="social_link" style="margin: 0;">
        <i class="fab fa-discord"></i>
      </a>
    </div>
  </ng-template>
</p-megaMenu>

<streamer-delete-dialog [show]="deleteStreamerModal" (showChange)="deleteStreamerModal=$event">
</streamer-delete-dialog>
