export class AffiliateRequest {

  public id!: string;

  public affiliateId!: string;

  public inOut!: string;

  constructor() {}

}
