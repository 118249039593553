import { EventEmitter, Injectable } from '@angular/core';
import { SuperService } from './super.service';
import { StreamBoostEventBus } from '../streamboost-eventbus.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RoutingService } from './routing.service';
import { MathHelper } from './math.helper';
import { environment } from '../../environments/environment';
import { Streamer } from '../model/streamer/streamer.model';
import { Affiliate } from '../model/streamer/affiliate.model';
import { AffiliateRequest } from '../model/streamer/affiliate-request.model';
import { AffiliateBannerPicture } from '../model/streamer/affiliate-banner-picture.model';
import { StreamerCampaign } from "../model/streamer/streamer-campaign.model";
import { I18nService } from './i18n.service';
import { Stream } from '../model/streamer/streamer-stream.model';
import { StreamerBill } from '../model/streamer/streamer-bill.model';
import { ClickSales } from '../model/streamer/streamer-click.model';
import { Subscription } from 'rxjs';
import { SocialProfile } from '../model/streamer/social-profile.model';
import {IpService} from "./ip.service";

@Injectable()
export class StreamerService extends SuperService {

  public isLoggedIn = false;
  public loginState = new EventEmitter<boolean>();
  private streamer?: Streamer;
  public streamerChanged = new EventEmitter<Streamer>();

  public langChangeSubscription!: Subscription;

  constructor(public streamBoostEventBus: StreamBoostEventBus,
    private cookieService: CookieService, private httpClient: HttpClient,
    private routingService: RoutingService, private ipService: IpService,
    private http: HttpClient,
    private mathHelper: MathHelper, private i18nService: I18nService) {
    super(streamBoostEventBus);

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe( newLang => {
      if (this.streamer) {
        this.updateStreamerInformation();
      }
    });
  }

  public userLoggedIn(token: string) {
    this.cookieService.delete('streamboost-session', '/');

    this.isLoggedIn = true;
    this.loginState.emit(this.isLoggedIn);

    this.cookieService.set('streamboost-session', token, 5000000, '/', '', true);
    this.updateStreamerInformation().then(() => {
    });

  }

  public logout(): Promise<void> {
    return new Promise((resolve) => {

      this.isLoggedIn = false;
      this.loginState.emit(this.isLoggedIn);

      this.cookieService.delete('streamboost-session', '/');

      delete this.streamer;
      this.streamerChanged.emit(this.streamer);

      localStorage.clear();
      resolve();
    });
  }

  public updateStreamerInformation(): Promise<void> {
    return new Promise((resolve, reject) => {

      // Nach dem Benutzer holen, Handler anbinden
      this.getStreamerInformationInternal().then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  private getStreamerInformationInternal(): Promise<void> {
    return new Promise((resolve, reject) => {
      const lang = this.i18nService.getCurrentLang();

      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.streamer.info',
          {
            message: {
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              this.setStreamer(response.body.response);
              resolve();
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  private setStreamer(newStreamer: any) {
    this.streamer = new Streamer(this.mathHelper, this.i18nService);

    this.streamer.id = newStreamer._id;

    this.streamer.username = newStreamer.username;
    this.streamer.firstname = newStreamer.firstname;
    this.streamer.lastname = newStreamer.lastname;
    this.streamer.email = newStreamer.email;
    this.streamer.birthday = newStreamer.birthday;
    this.streamer.gender = newStreamer.gender;
    this.streamer.streamerPoints = newStreamer.streamer_points;

    this.streamer.terms_confirmation = newStreamer.terms_confirmation;
    this.streamer.paypal = newStreamer.paypal_email;
    this.streamer.taxNr = newStreamer.tax_number;

    this.streamer.partOfAnyCampaign = newStreamer.part_of_active_campaign;
    this.streamer.allowAffiliatePicturesOnStream = newStreamer.allow_affiliate_pictures;
    if (newStreamer.campaigns_involved) {
      const campaignsInvolved: StreamerCampaign[] = [];

      let i: number;
      for (i = 0; i < newStreamer.campaigns_involved.length; i++) {
        const campaign: StreamerCampaign = new StreamerCampaign();


        campaign.id = newStreamer.campaigns_involved[i].campaign_id;
        campaign.name = newStreamer.campaigns_involved[i].name;
        campaign.displayName = newStreamer.campaigns_involved[i].display_name;
        campaign.picUrl = newStreamer.campaigns_involved[i].pic_url;
        campaign.startDate = newStreamer.campaigns_involved[i].start_date;
        campaign.endDate = newStreamer.campaigns_involved[i].end_date;
        campaign.startTime = newStreamer.campaigns_involved[i].start_time;
        campaign.endTime = newStreamer.campaigns_involved[i].end_time;
        campaign.progress = newStreamer.campaigns_involved[i].progress;
        campaign.description = newStreamer.campaigns_involved[i].description;
        campaign.paused = newStreamer.campaigns_involved[i].paused;
        campaign.pausedType = newStreamer.campaigns_involved[i].paused_type;
        campaign.views = newStreamer.campaigns_involved[i].views;
        campaign.status  = newStreamer.campaigns_involved[i].status;
        campaign.gameKeywords = newStreamer.campaigns_involved[i].game_keywords;
        campaign.ignoredGameKeywords = newStreamer.campaigns_involved[i].ignored_game_keywords;

        if (campaign.dailyViews){
          campaign.dailyViews = newStreamer.campaigns_involved[i].daily_views;
        }

        if (campaign.counted_daily_views){
          campaign.counted_daily_views = newStreamer.campaigns_involved[i].counted_daily_views;
        }

        if (campaign.startDate) {
          campaign.startDateAsDate = new Date(newStreamer.campaigns_involved[i].start_date_as_date);
        }

        campaignsInvolved.push(campaign);
      }
      this.streamer.campaignsInvolved = campaignsInvolved;
    }

    this.streamer.inRegister = newStreamer.in_register;

    if (newStreamer.address) {
      this.streamer.street = newStreamer.address.street;
      this.streamer.nr = newStreamer.address.nr;
      this.streamer.postal_code = newStreamer.address.postal_code;
      this.streamer.city = newStreamer.address.city;
    }

    this.streamer.profile_image_url = newStreamer.profile_image_url;
    this.streamer.social_profiles = {};
    // this.streamer.roles = response.body.response.roles;
    this.streamer.active = newStreamer.active;
    this.streamer.tkp = newStreamer.tkp;
    this.streamer.tier = newStreamer.tier;
    this.streamer.tierChangeDays = newStreamer.tier_change_days;
    this.streamer.outstandingUpgradeRequest = newStreamer.outstanding_upgrade_request;
    if (this.streamer.outstandingUpgradeRequest) {
      if (newStreamer.upgrade_request.end_date) {
        this.streamer.reason = newStreamer.upgrade_request.reason;
      }
    }
    this.streamer.lastPaymentDate = new Date(newStreamer.last_payment_date.$date);

    this.streamer.viewsSinceLastPayment = newStreamer.since_last_payment.views_since_last_payment;
    this.streamer.clicksSinceLastPayment = newStreamer.since_last_payment.clicks_since_last_payment;
    this.streamer.salesSinceLastPayment = newStreamer.since_last_payment.sales_since_last_payment;
    this.streamer.earningsSinceLastPayment = newStreamer.since_last_payment.earnings_since_last_payment;
    this.streamer.earningsFromClicks = newStreamer.since_last_payment.earnings_from_clicks;
    this.streamer.earningsFromViews = newStreamer.since_last_payment.earnings_from_views;
    this.streamer.earningsFromSales = newStreamer.since_last_payment.earnings_from_sales;
    this.streamer.externalAffiliateClicks = newStreamer.since_last_payment.external_affiliate_clicks;

    this.streamer.preliminaryClicks = newStreamer.since_last_payment.preliminary_clicks;
    this.streamer.preliminarySales = newStreamer.since_last_payment.preliminary_sales;
    this.streamer.preliminaryEarnings = newStreamer.since_last_payment.preliminary_earnings;
    this.streamer.preliminaryEarningsFromClicks = newStreamer.since_last_payment.preliminary_earnings_from_clicks;
    this.streamer.preliminaryEarningsFromSales = newStreamer.since_last_payment.preliminary_earnings_from_sales;

    this.streamer.adKeywords = newStreamer.preferred_keywords;

    if (newStreamer.channel_demographic) {
      this.streamer.demographyInfo.percLessThen18 = newStreamer.channel_demographic.under_18;
      this.streamer.demographyInfo.perc18to24 = newStreamer.channel_demographic['18_to_24'];
      this.streamer.demographyInfo.perc25to34 = newStreamer.channel_demographic['25_to_34'];
      this.streamer.demographyInfo.perc35to44 = newStreamer.channel_demographic['35_to_44'];
      this.streamer.demographyInfo.percOlderThen44 = newStreamer.channel_demographic.over_44;

      this.streamer.demographyInfo.male = newStreamer.channel_demographic.male;
      this.streamer.demographyInfo.female = newStreamer.channel_demographic.female;
      this.streamer.demographyInfo.divers = newStreamer.channel_demographic.diverse;

      this.streamer.demographyInfo.countries = newStreamer.channel_demographic.countries;
    }

    this.streamer.ad_url = environment.streamAPIUrl + 'endpoint/' + newStreamer.token;
    this.streamer.affiliate_url = environment.streamAPIUrl + 'affiliate/' + newStreamer.token;
    this.streamer.competition_url = environment.streamAPIUrl + 'competition/' + newStreamer.token;

    this.streamer.currentConvRate = newStreamer.current_conv_rate;
    this.streamer.totalConvRate = newStreamer.total_conv_rate;

    this.isLoggedIn = true;

    if (newStreamer.affiliates) {
      const affiliates: Affiliate[] = [];
      const affiliatesWithoutDefault: Affiliate[] = [];

      const externalAffiliates: Affiliate[] = [];
      for (let i = 0; i < newStreamer.affiliates.length; i++) {
        const affiliate: Affiliate = new Affiliate();

        affiliate.id = newStreamer.affiliates[i]._id;
        affiliate.name = newStreamer.affiliates[i].name;
        affiliate.description = newStreamer.affiliates[i].description;
        affiliate.logoURL = '/picture/' + newStreamer.affiliates[i].logo_id;
        affiliate.streamerToken = newStreamer.token;
        affiliate.APIUrl = environment.streamAPIUrl;
        affiliate.keywords = newStreamer.affiliates[i].keywords;
        affiliate.type = newStreamer.affiliates[i].type;
        affiliate.bannerURLs = newStreamer.affiliates[i].banner_urls;
        affiliate.totalClicks = newStreamer.affiliates[i].total_clicks;
        affiliate.progress = newStreamer.affiliates[i].progress;
        affiliate.dailyProgress = newStreamer.affiliates[i].daily_progress;
        affiliate.startDate = newStreamer.affiliates[i].start_date;
        affiliate.endDate = newStreamer.affiliates[i].end_date;
        affiliate.status = newStreamer.affiliates[i].status;
        affiliate.paused = newStreamer.affiliates[i].paused;
        affiliate.chatMessage = newStreamer.affiliates[i].chat_message;
        affiliate.tinyURl = environment.streamTinyUrl;

        if (affiliate.startDate) {
          affiliate.startDateAsDate = new Date(newStreamer.affiliates[i].start_date_as_date);
        }

        if (newStreamer.affiliates[i].type === 'EXTERNAL') {
          externalAffiliates.push(affiliate);
        }

        if (newStreamer.affiliates[i].has_link) {
          affiliates.push(affiliate);
          affiliatesWithoutDefault.push(affiliate);
        }
      }
      this.streamer.participatingAffiliatesWithoutDefaultAffiliate = affiliatesWithoutDefault;

      if (affiliates.length > 0 && this.streamer.tier > 1) {
        affiliates.push(this.getDefaultAffiliate(newStreamer.token));
      }

      this.streamer.participatingAffiliates = affiliates;
      this.streamer.participatingExternalAffiliatesOnly = externalAffiliates;

      this.streamer.fillAffiliatesForLink();

      this.streamer.allowedNumberOfExternalAffiliates = newStreamer.allowed_number_of_external_affiliates;
      this.streamer.currentNumberOfExternalAffiliates = newStreamer.current_number_of_external_affiliates;
    }

    if (newStreamer.affiliate_requests) {
      const affiliateRequests: AffiliateRequest[] = [];

      for (let i = 0; i < newStreamer.affiliate_requests.length; i++) {
        const affiliateRequest: AffiliateRequest = new AffiliateRequest();

        affiliateRequest.id = newStreamer.affiliate_requests[i]._id;
        affiliateRequest.affiliateId = newStreamer.affiliate_requests[i].affiliate_id;
        affiliateRequest.inOut = newStreamer.affiliate_requests[i].type;

        affiliateRequests.push(affiliateRequest);
      }

      this.streamer.affiliateRequests = affiliateRequests;

      this.streamer.numberOfINRequests = newStreamer.number_of_in_requests;
      this.streamer.numberOfOUTRequests = newStreamer.number_of_out_requests;
    }

    let profiles: SocialProfile[] = [];
    for (let i = 0; i < newStreamer.social_profiles.length; i++) {
      let profile = new SocialProfile();
      profile.id = newStreamer.social_profiles[i].id;
      profile.network = newStreamer.social_profiles[i].name;
      profile.profileName = newStreamer.social_profiles[i].profile_name;
      profiles.push(profile);
    }
    this.streamer.socialProfiles = profiles;

    this.streamerChanged.emit(this.streamer);
  }

  getDefaultAffiliate(token: string): Affiliate {
    // var currentLang = this.i18nService.getCurrentLang();
    const currentLang = 'DE';

    const affiliate: Affiliate = new Affiliate();

    affiliate.id = 'NONE';

    if (currentLang === 'DE') {
      affiliate.name = 'Allgemeiner Streamboost Affiliate Link';
      affiliate.description = 'Dies ist der Link, der zu der Streamboost Affiliate Übersichtsseite führt.';
    } else {
      affiliate.name = 'Common Streamboost Affiliate Link';
      affiliate.description = 'This link leads to the Streamboost Affiliate Overview Page.';
    }

    affiliate.logoURL = '/assets/images/Infinity_logo.png';
    affiliate.streamerToken = token;
    affiliate.APIUrl = environment.streamAPIUrl;

    let pic1 = new AffiliateBannerPicture('1', 'assets/images/Affiliate_Panel_l.jpg');
    let pic2 = new AffiliateBannerPicture('1', 'assets/images/Affiliate_Panel_w.jpg');

    affiliate.bannerURLs.push(pic1);
    affiliate.bannerURLs.push(pic2);

    affiliate.type = 'EXTERNAL';

    return affiliate;
  }

  public saveKeywordChangesForStreamer(selectedItems: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.save.keyword.changes',
        {
          message: {
            items: selectedItems
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );

  }

  setNameGenderAndBirthday(firstname: string, lastname: string, birthday: Date, gender: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let birthdayString;
      if (birthday !== undefined) {
        birthdayString = birthday.toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
      } else {
        birthdayString = '';
      }

      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.set.name',
        {
          message: {
            firstname,
            lastname,
            birthday: birthdayString,
            gender
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  setGenderAndPostalCode(gender: string, postalCode: String): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.streamer.set.gender',
          {
            message: {
              gender,
              'postal_code': postalCode
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changeEmail(new_email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.change.email',
        {
          'message': {
            'new_email': new_email
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  setAddress(street: string, nr: string, city: string, postalCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.set.address',
        {
          message: {
            street,
            nr,
            city,
            postal_code: postalCode != null ? postalCode.toString() : ''
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  setPayment(paypal: string, taxNr: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.set.payment',
        {
          message: {
            paypal_address: paypal,
            tax_nr: taxNr
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  requestTierUpgrade(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.tier.upgrade',
        {
          'message': {
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public saveGenderDistributionForStreamer(percGenderMen: number,
    percGenderWomen: number,
    percGenderDivers: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.save.gender.changes',
        {
          message: {
            percGenderMen,
            percGenderWomen,
            percGenderDivers
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public saveAgeDistributionForStreamer(percLessThen18: number,
    perc18to24: number,
    perc25to34: number,
    perc35to44: number,
    percOlderThen44: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.save.age.changes',
        {
          message: {
            percLessThen18,
            perc18to24,
            perc25to34,
            perc35to44,
            percOlderThen44
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public saveCountryChangesForStreamer(selectedItems: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.save.country.changes',
        {
          message: {
            items: selectedItems
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public saveExternalAffiliateChanges(newToAdd: Affiliate[], newToRemove: Affiliate[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.save.affiliate.changes',
        {
          message: {
            new_affiliates: newToAdd,
            remove_affiliates: newToRemove
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getExternalTierLimitation(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send('stream.web.facade.streamer.affiliate.limitation',
        {
          message: {}
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              resolve(false);
            } else {
              resolve(response.body.response.limited_affiliates);
            }

          }
        )
        .catch(
          (err: any) => {
            console.log(err);
            resolve(false);
          }
        );
    });
  }

  public getStreamsOfStreamer(first?: number, rows?: number, sortField?: string, sortOrder?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.streams',
        {
          message: {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }

            const streamsFromDB = response.body.response.streams;
            let i: number;
            const streams: Stream[] = [];

            for (i = 0; i < streamsFromDB.length; i++) {
              const stream = new Stream(this.i18nService.getCurrentLang());

              stream.id = streamsFromDB[i].stream_id;
              stream.views = streamsFromDB[i].views_accounted;
              stream.startDate = streamsFromDB[i].start_date;
              stream.endDate = streamsFromDB[i].end_date;
              // stream.earnings = streamsFromDB[i].earnings;
              stream.duration = streamsFromDB[i].duration;
              stream.network = streamsFromDB[i].network;

              stream.earnings = this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(streamsFromDB[i].earnings, 3));

              if (streamsFromDB[i].streamer_bill_id) {
                stream.streamerBillId = streamsFromDB[i].streamer_bill_id;
              }

              streams.push(stream);
            }

            resolve(streams);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getMaxStreamsOfStreamer(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.streams.max',
        {
          message: {
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }
            resolve(response.body.response.max_count);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getBillsOfStreamer(first?: number, rows?: number, sortField?: string, sortOrder?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.bills',
        {
          message: {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }

            const billsFromDB = response.body.response.streamerbills;
            let i: number;
            const streamerbills: StreamerBill[] = [];

            for (i = 0; i < billsFromDB.length; i++) {
              const bill = new StreamerBill(this.i18nService.getCurrentLang());

              bill.id = billsFromDB[i]._id;
              bill.billNr = billsFromDB[i].bill_nr;
              bill.views = billsFromDB[i].views;
              bill.billDate = billsFromDB[i].bill_date;
              bill.earnings = billsFromDB[i].earnings;
              bill.totalClicks = billsFromDB[i].clicks_and_sales;
              bill.paid = billsFromDB[i].paid ? true : false;

              streamerbills.push(bill);
            }

            resolve(streamerbills);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getMaxBillsOfStreamer(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.bills.max',
        {
          message: {
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }
            resolve(response.body.response.max_count);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getAffiliateInvoicesOfStreamer(first?: number, rows?: number, sortField?: string, sortOrder?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.affiliate.invoices',
        {
          message: {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }

            const invoicesFroDB = response.body.response.affiliate_invoices;
            let i: number;
            const affiliateInvoices: ClickSales[] = [];

            for (i = 0; i < invoicesFroDB.length; i++) {
              const sales = new ClickSales(this.i18nService.getCurrentLang());

              sales.id = invoicesFroDB[i]._id;
              sales.clicksSales = invoicesFroDB[i].total;
              sales.invoiceDate = invoicesFroDB[i].invoice_date;
              sales.earnings = invoicesFroDB[i].earnings;
              sales.preliminary = invoicesFroDB[i].preliminary_invoice;
              sales.affiliateName = invoicesFroDB[i].affiliate_name;

              if (invoicesFroDB[i].streamer_bill_id) {
                sales.streamerBillId = invoicesFroDB[i].streamer_bill_id;
              }

              affiliateInvoices.push(sales);
            }

            resolve(affiliateInvoices);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getMaxAffiliateInvoicesOfStreamer(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.affiliate.invoices.max',
        {
          message: {
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }
            resolve(response.body.response.max_count);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  requestPayment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.payment.request',
        {
          message: {
          }
        })
        .then((result) => {
            if (result.body.code === 'Theseus') {
              this.setStreamer(result.body.response);
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
        }
        )
        .catch((error) => {
          console.log(error);
          reject(error);
        }
        );
    });
  }

  public registerStreamer(email: string, birthday: Date, percLessThen18: number, perc18to24: number,perc25to34: number,
                          perc35to44: number, percOlderThen44: number,percGenderMen: number, percGenderWomen: number,
                          percGenderDivers: number, selectedKeywords: any[], selectedCountries: any[], affiliates: Affiliate[],
                          gender: string, streamerAttentionReason: string, postalCode: String,country: String, faceCamUsage: boolean, shortcut: boolean) : Promise<any> {
    let birthdayString = "";
    if (birthday !== undefined) {
      birthdayString = birthday.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
    } else {
      birthdayString = '';
    }

    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.streamer.register',
          {
            message: {
              email,
              birthday: birthdayString,
              percLessThen18,
              perc18to24,
              perc25to34,
              perc35to44,
              percOlderThen44,
              percGenderMen,
              percGenderWomen,
              percGenderDivers,
              selectedKeywords,
              selectedCountries,
              affiliates,
              gender,
              streamerAttentionReason,
              'postal_code': postalCode,
              country,
              faceCamUsage,
              shortcut
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  removeSocialConnection(profile: SocialProfile): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.streamer.remove.social.profile',
          {
            message: {
              profile
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  acceptTermsOfUse(ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.accept.terms',
        {
          message: {
            ip_address: ip
          }
        })
      .then((result) => {
        {
          if (result.body.code === 'Theseus') {
            resolve(result.body);
          } else if (result.body.code) {
            reject(result.body.code);
          } else {
            resolve(result.body);
          }
        }

      })
      .catch((error) => {
        reject(error);

      });
    });
  }

  activateEmail(id: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.streamer.confirm.email',
        {
          'message': {
            'request_id': id,
            'ip_address': ip
          }
        }).then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }

        }).catch((error) => {
          reject(error);
        });

    });
  }

  quitCampaign(campaignId: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.quit',
          {
            message: {
              campaign_id: campaignId,
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  pauseYourselfInCampaign(campaignId: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.streamer.pause',
          {
            message: {
              campaign_id: campaignId,
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  resumeYourselfInCampaign(campaignId: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.streamer.resume',
          {
            message: {
              campaign_id: campaignId,
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {
                this.setStreamer(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  deleteStreamer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.streamer.delete', {
          message: {},
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getAllViewsChart(streamerId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.streamer.views',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response.views);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getStreamerTimeChart(streamerId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.streamer.stream.time',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response.views);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }
  public getStreamingTable(streamerId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.streamer.streams.table',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response.streams);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAverageViewsChart(streamerId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.streamer.average.views',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response.views);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllProfileInformation() {
    return new Promise((resolve, reject) => {
      this.ipService.getIp().then(res => {
        let ipAddress = res.ip;

        let token;
        if (this.cookieService.get('streamboost-session')) {
          token = this.cookieService.get('streamboost-session');
        }

        if (token) {
          let headers: HttpHeaders = new HttpHeaders({ 'token': token })
            .append('ip_address', '' + ipAddress).append('lang', this.i18nService.getCurrentLang());

          this.http.get('/file/streamer/' + this.streamer?.id, { headers: headers, responseType: 'arraybuffer' })
            .subscribe(response =>
              this.downloadFile(response, "application/zip")
            )
        }
      });
    });
  }

    /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
    private downloadFile(data: any, type: string) {
      let blob = new Blob([data], { type: type });
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }

}
