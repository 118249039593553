import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {StreamerService} from '../../services/streamer.service';
import {FeedbackService} from '../../services/feedback.service';
import {Question} from "../../model/streamer/streamer-question.model";
import {Subscription} from "rxjs";

@Component({
  selector: 'streamer-feedback-panel',
  templateUrl: './streamer-feedback-panel.component.html',
  styleUrls: ['./streamer-feedback-panel.component.scss']
})
export class StreamerFeedbackPanelComponent implements OnInit {

  @Input()
  public show: boolean = false;

  @Input()
  public campaignId: string = '';

  @Input()
  public affiliateId: string = '';

  @Output()
  public showChange = new EventEmitter<boolean>();

  public questionList: Question[] = [];

  public type?: string;
  public typeOptions = [
    { label: 'FRAGE', value: 'QUESTION' },
    { label: 'FEEDBACK', value: 'FEEDBACK' }
  ];
  public message?: string;

  public questionSend: boolean = false;
  public feedbackSend: boolean = false;

  public langChangeSubscription!: Subscription;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public streamerService: StreamerService, public feedbackService: FeedbackService) {

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      if (this.globalService.streamer) {
        if (newLang === 'DE') {
          this.typeOptions = [
            { label: 'FRAGE', value: 'QUESTION' },
            { label: 'FEEDBACK', value: 'FEEDBACK' }
          ];
        } else {
          this.typeOptions = [
            { label: 'QUESTION', value: 'QUESTION' },
            { label: 'FEEDBACK', value: 'FEEDBACK' }
          ];
        }
      }
    });

  }

  ngOnInit() {
    this.updateQuestions();
  }

  public updateShow() {
    delete this.message;
    delete this.type;

    this.questionSend = false;
    this.feedbackSend = false;
    this.updateQuestions();
    this.showChange.emit(this.show);
  }

  public validate(): boolean {
    return !!this.type && !!this.message;
  }

  public sendFeedback() {
    if (!!this.type && !!this.message) {
      this.feedbackService.saveFeedback(this.type, this.message, this.campaignId, this.affiliateId).then(result => {
        if (this.type == 'QUESTION') {
          this.questionSend = true;
          this.feedbackSend = false;
        } else {
          this.questionSend = false;
          this.feedbackSend = true;
        }

        delete this.message;
        delete this.type;

        this.updateQuestions();
      });
    }
  }

  public updateQuestions() {
    if (!!this.affiliateId || !!this.campaignId) {
      this.questionList = [];
      this.feedbackService.getQuestionsOfCampaignOrAffiliateForStreamer(this.campaignId, this.affiliateId).then(result => {
        this.questionList = result;
      });
    }
  }

  public sendClip(){
    window.open ('https://forms.gle/wMqcxF8PUi95NScb7');
  }
}
