export class CampaignFinishedStream {

  public streamId!: string;

  public streamerName!: string;

  public network!: string;

  public games!: string[];

  public startDate!: string;

  public endDate!: string;

  public views!: number;

  public streamDuration!: string;

  public adDuration!: string;

  public profileLink!: string;

  constructor() {}

}
