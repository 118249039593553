import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {I18nService} from './i18n.service';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {IpService} from './ip.service';
import {Subscription} from 'rxjs';
import {Agency} from '../model/agency/agency.model';
import {EventEmitter, Injectable} from '@angular/core';

import {GameStreamStatistic} from '../model/advertiser/game-stream-statistic.model';
import {AgencyAdvertiser} from '../model/agency/agency-advertiser.model';

@Injectable()
export class AgencyService extends SuperService {

  public isLoggedIn = false;
  public loginState = new EventEmitter<boolean>();
  public agency?: Agency;
  public agencyChanged = new EventEmitter<Agency>();

  public langChangeSubscription!: Subscription;

  constructor(public streamBoostEventBus: StreamBoostEventBus, private i18nService: I18nService,
              private cookieService: CookieService, private http: HttpClient, private ipService: IpService) {
    super(streamBoostEventBus);

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe(newLang => {
      if (this.agency) {
        this.getAgencyInformationInternal(newLang);
      }
    });

  }

  userLoggedIn(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cookieService.delete('streamboost-session', '/');

      this.isLoggedIn = true;
      this.loginState.emit(this.isLoggedIn);

      this.cookieService.set('streamboost-session', token, 5000000, '/', '', true);
      this.updateAgencyInformation().then(() => {
        resolve(true);
      }).catch((error) => {
          reject(error);
        }
      );
    });
  }

  agencyHasRememberLogin(): boolean {
    if (this.agency) {
      return this.agency.rememberLogin;
    } else {
      return false;
    }
  }

  authenticate(email: string, password: string, ip: string, rememberLogin: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.auth',
        {
          message: {
            email,
            password,
            ip,
            remember_login: rememberLogin
          }
        })
        .then((result) => {

            if (result.body.code) {
              reject(result.body.code);
              return;
            }

            if (result.body.response.token) {
              this.userLoggedIn(result.body.response.token).then(() => {
                resolve(result.body);
              }).catch(() => {
                reject(result.body);
              });
            }
          }
        )
        .catch((error) => {
            reject(error);
          }
        );
    });
  }

  authenticateWithToken(token: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.auth.token',
        {
          message: {
            token,
            ip
          }
        })
        .then((result) => {

            if (result.body.code === 'Theseus') {
              this.userLoggedIn(token).then(() => {
                resolve(true);
              }).catch(loginError => {
                reject(loginError);
              });
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body.response.number);
            }
          }
        )
        .catch((error) => {
            reject(error);
          }
        );
    });
  }

  logout(remember_login: boolean): Promise<void> {
    return new Promise((resolve) => {

      this.isLoggedIn = false;
      this.loginState.emit(this.isLoggedIn);

      if (!remember_login) {
        console.log('delete cookie');
        this.cookieService.delete('streamboost-session', '/');
      }

      delete this.agency;
      this.agencyChanged.emit(this.agency);

      localStorage.clear();
      resolve();
    });
  }

  public createNewAgencyUser(firstname: string, lastname: string, email: string, role: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.user.add',
        {
          message: {
            firstname,
            lastname,
            email,
            role,
            ip
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
      }
    );
  }


  public updateAgencyInformation(): Promise<any> {
    return new Promise((resolve, reject) => {

      // Nach dem Benutzer holen, Handler anbinden
      this.getAgencyInformationInternal(this.i18nService.getCurrentLang()).then(() => {
        resolve(true);
      }).catch((error) => reject(error));
    });
  }

  private getAgencyInformationInternal(lang: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.info',
          {
            message: {
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              this.setAgency(response.body.response);
              resolve();
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public setAgency(newAgency: any) {
    this.agency = new Agency();

    this.agency.id = newAgency._id;
    this.agency.agencyNr = newAgency.agency_nr;
    // this.agency.isPasswordInitial = newAgency.pwd_initial;
    this.agency.agencyName = newAgency.agency_name;
    this.agency.website = newAgency.website;
    this.agency.logoURL = newAgency.logo_url;
    this.agency.rememberLogin = newAgency.remember_login;
    this.agency.discount = newAgency.agency_discount;

    this.agency.balance = newAgency.balance;
    this.agency.is_balance_enabled = newAgency.is_balance_enabled;
    this.agency.balance_valid_until = newAgency.balance_valid_until;
    this.agency.aeBonus = newAgency.aeBonus;
    this.agency.totalAeBonus = newAgency.totalAeBonus;
    this.agency.usedBalance = newAgency.usedBalance;

    this.agency.userId = newAgency.user_id;
    this.agency.firstname = newAgency.firstname;
    this.agency.lastname = newAgency.lastname;
    this.agency.email = newAgency.email;
    this.agency.telephone = newAgency.telephone;
    this.agency.roles = newAgency.roles;

    this.agency.taxNumber = newAgency.tax_number;
    this.agency.country = newAgency.country;

    this.agency.customerId = newAgency.customer_id;

    if (newAgency.address) {
      this.agency.street = newAgency.address.street;
      this.agency.nr = newAgency.address.nr;
      this.agency.postalCode = newAgency.address.postal_code;
      this.agency.city = newAgency.address.city;
    }

    if (newAgency.advertisers) {
      const advertisers: AgencyAdvertiser[] = [];

      let i: number;
      for (i = 0; i < newAgency.advertisers.length; i++) {
        const advertiser: AgencyAdvertiser = new AgencyAdvertiser();

        advertiser.id = newAgency.advertisers[i]._id;
        advertiser.companyName = newAgency.advertisers[i].company_name;
        advertiser.active = newAgency.advertisers[i].active;

        let numberOfCampaigns = 0;
        let numberOfAffiliates = 0;
        for (let j = 0; j < newAgency.active_campaigns_affiliates.length; j++) {
          if (advertiser.id == newAgency.active_campaigns_affiliates[j].advertiser_id && newAgency.active_campaigns_affiliates[j].type == 'CAMPAIGN') {
            numberOfCampaigns++;
          }
          if (advertiser.id == newAgency.active_campaigns_affiliates[j].advertiser_id && newAgency.active_campaigns_affiliates[j].type == 'AFFILIATE') {
            numberOfAffiliates++;
          }
        }
        advertiser.numberOfCampaigns = numberOfCampaigns;
        advertiser.numberOfAffiliates = numberOfAffiliates;

        advertiser.budget = newAgency.advertisers[i].budget;
        advertiser.picId = newAgency.advertisers[i].logo_id;

        advertiser.street = newAgency.advertisers[i].address.street;
        advertiser.nr = newAgency.advertisers[i].address.nr;
        advertiser.postalCode = newAgency.advertisers[i].address.postal_code;
        advertiser.city = newAgency.advertisers[i].address.city;

        advertiser.countryId = newAgency.advertisers[i].country_id;
        advertiser.country = newAgency.advertisers[i].country;

        advertiser.website = newAgency.advertisers[i].website;

        advertisers.push(advertiser);
      }
      this.agency.advertisers = advertisers;
    }
    this.agency.activeCampaigns = newAgency.active_campaigns_affiliates;

    // this.advertiser.rememberLogin = newAdvertiser.remember_login;
    this.agency.allowAdminAccess = newAgency.allow_access;
    this.agency.agbConfirmation = newAgency.terms_confirmation;

    this.agencyChanged.emit(this.agency);
  }

  public getAllClicksChart(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.all.clicks',
          {
            message: {}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllViewsChart(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.all.views',
          {
            message: {}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.all.users',
          {
            message: {}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllGameStreamStatistics(): Promise<GameStreamStatistic[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.all.games',
          {
            message: {}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              let gameStreams = response.body.response.games_streams;
              let gameStreamStatistics: GameStreamStatistic[] = [];
              if (gameStreams) {
                gameStreamStatistics = gameStreams;
              }

              resolve(gameStreamStatistics);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public changeAgencyInformation(name: string, website: string, street: string, nr: string, city: string, postalCode: string): Promise<any> {
    return new Promise((resolve, reject) => {

        this.streamBoostEventBus.send(
          'stream.web.facade.agency.change.info',
          {
            message: {
              name,
              website,
              street,
              nr,
              city,
              postal_code: postalCode
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public changeProfile(firstname: string, lastname: string, email: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.agency.change.profile',
          {
            message: {
              firstname,
              lastname,
              email,
              ip
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public changeTaxInformation(taxNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {

        this.streamBoostEventBus.send(
          'stream.web.facade.change.agency.tax',
          {
            message: {
              tax_number: taxNumber
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }
  public startStripePortal(customerId: string): Promise<void>{
    console.log("Start Stripe Portal Service")
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        console.log("Sende Nachricht")
        this.streamBoostEventBus.send('stream.web.facade.agency.startCustomerPortal',
          {
            message: {
              customer_id: customerId
            }
          })
          .then(
            (response: any) => {
              console.log("Bin zurück")
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public changePasswordWithOldPassword(oldPwd: string, pwd: string, email: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.agency.change.password.renew',
          {
            'message': {
              'password': pwd,
              'old_password': oldPwd,
              'email': email,
              ip
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public checkAdvertiserAffiliation(advertiserId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.agency.advertiser.affiliation',
          {
            'message': {
              'advertiser_id': advertiserId
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public resetUserPassword(agencyUserId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.agency.user.password.reset',
          {
            'message': {
              'agency_user_id': agencyUserId
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public changeUserInfo(agencyUserId: string, firstname: string, lastname: string, email: string, roles: string[], ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.agency.user.change',
          {
            message: {
              'agency_user_id': agencyUserId,
              firstname,
              lastname,
              email,
              roles,
              ip
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAgency(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public grantAdminAccess(ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.grant.access',
        {
          'message': {
            ip
          }
        }).then((result) => {
        {
          if (result.body.code === 'Theseus') {
            this.setAgency(result.body.response);
            resolve(result.body);
          } else if (result.body.code) {
            reject(result.body.code);
          } else {
            resolve(result.body);
          }
        }
      }).catch((error) => {
        reject(error);
      });

    });
  }

  acceptTermsAndConditions(ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.accept.terms',
        {
          message: {
            ip_address: ip
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }

        })
        .catch((error) => {
          reject(error);

        });
    });
  }

  registerAgency(companyName: string, website: string, taxNumber: string, street: string, nr: number, postalCode: string,
                 city: string, country: any, firstname: string, lastname: string, email: string, telephone: number,
                 password: string, acceptTermsAndConditions: boolean, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.register',
        {
          message: {
            company_name: companyName,
            website,
            tax: taxNumber,
            street,
            nr,
            postal_code: postalCode,
            city,
            country,
            firstname,
            lastname,
            email,
            telephone,
            password,
            accept_terms: acceptTermsAndConditions,
            ip_address: ip
          }
        }).then((result) => {
        if (result.body.code === 'Theseus') {
          resolve(result.body.response.agency_id);
        } else {
          reject(result.body);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  activateAgencyAdmin(id: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.activate',
        {
          'message': {
            'request_id': id,
            'ip_address': ip
          }
        }).then((result) => {
        {

          if (result.body.code) {
            reject(result.body.code);
            return;
          }

          if (result.body.response.token) {
            this.userLoggedIn(result.body.response.token).then(() => {
              resolve(result.body);
            }).catch(() => {
              reject(result.body);
            });
          }
        }

      }).catch((error) => {
        reject(error);
      });

    });
  }

}
