import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Affiliate } from '../../model/streamer/affiliate.model';
import { StreamerService } from '../../services/streamer.service';
import { AffiliateService } from '../../services/affiliate.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'streamer-affiliates',
  templateUrl: './streamer-affiliates.component.html',
  styleUrls: ['./streamer-affiliates.component.scss']
})
export class StreamerAffiliatesComponent implements OnInit {

  public selectedAffiliateForLink!: Affiliate;
  public selectedBanner!: any;
  public selectedVersionNumber!: number;

  public tipTab: number = 1;

  public affiliateSource: Affiliate[] = [];
  public originalAffiliateSource: Affiliate[] = [];
  public selectedAffiliates: Affiliate[] = [];
  public originalSelectedAffiliates: Affiliate[] = [];
  public selectedAffiliate!: Affiliate;
  public newToAdd: Affiliate[] = [];
  public newToRemove: Affiliate[] = [];
  public tooManyAffiliates = false;
  public maxAffiliateLimitation = false;

  public changes: boolean = false;

  public showSentHint: boolean = false;

  public showAffiliateDescription: boolean = false;

  public moreStatusInfoModal: boolean = false;

  public affiliateTab: number = 1;

  constructor(public globalService: GlobalService, private streamerService: StreamerService,
    private affiliateService: AffiliateService, public i18nService: I18nService) {
    if (this.globalService.streamer) {
      this.setInitialAffiliateBanner();
      this.initializeAffiliatePrograms();
    }

    this.streamerService.getExternalTierLimitation().then(result => {
      this.maxAffiliateLimitation = result;
    });
  }

  ngOnInit(): void {
    if (this.globalService.streamer) {
      this.setInitialAffiliateBanner();
      this.initializeAffiliatePrograms();
    }

    this.streamerService.getExternalTierLimitation().then(result => {
      this.maxAffiliateLimitation = result;
    });
  }

  setInitialAffiliateBanner() {
    if (this.globalService.streamer && this.globalService.streamer.clickcampaignsForLink.length > 0) {
      this.selectedAffiliateForLink = this.globalService.streamer.clickcampaignsForLink[0].value;
    }
  }

  initializeAffiliatePrograms() {
    this.affiliateSource = [];
    this.selectedAffiliates = [];

    this.affiliateService.getActiveExternalAffiliates().then((result) => {
      const visibleExternalAffiliates: Affiliate[] = [];
      const requestedAffiliatesToBeRemoved: Affiliate[] = [];
      const requestedAffiliatesToBeAdded: Affiliate[] = [];

      for (let i = 0; i < result.length; i++) {
        let availableAffiliate = result[i];
        let found = false;

        if (this.globalService.streamer && this.globalService.streamer.participatingAffiliates.length > 0) {
          for (let j = 0; j < this.globalService.streamer.participatingExternalAffiliatesOnly.length; j++) {
            let selectedAffiliate = this.globalService.streamer.participatingExternalAffiliatesOnly[j];

            if (availableAffiliate.name === selectedAffiliate.name) {
              found = true;
            }
          }
        }

        // Anstehende Requests abgleichen
        if (this.globalService.streamer) {
          for (let k = 0; k < this.globalService.streamer.affiliateRequests.length; k++) {
            let affiliateRequest = this.globalService.streamer.affiliateRequests[k];

            if (affiliateRequest.affiliateId === availableAffiliate.id) {
              availableAffiliate.requested = true;

              // Wenn das Affiliate Programm angefordert wurde
              if (affiliateRequest.inOut === 'IN') {
                requestedAffiliatesToBeAdded.push(availableAffiliate);
                found = true;

                // Wenn das Programm abgewählt werden soll
              } else if (affiliateRequest.inOut === 'OUT') {
                requestedAffiliatesToBeRemoved.push(availableAffiliate);
                found = false;
              }
            }
          }
        }


        if (!found) {
          // console.log(availableAffiliate.name);
          visibleExternalAffiliates.push(availableAffiliate);
        }
      }

      this.affiliateSource = visibleExternalAffiliates;
      this.originalAffiliateSource = visibleExternalAffiliates.slice();

      const finalParticipatingExternalAffiliates: Affiliate[] = [];

      for (let k = 0; k < requestedAffiliatesToBeAdded.length; k++) {
        let affiliateToBeAdded = requestedAffiliatesToBeAdded[k];
        finalParticipatingExternalAffiliates.push(affiliateToBeAdded);
      }

      if (this.globalService.streamer && this.globalService.streamer.participatingAffiliates.length > 0) {
        for (let i = 0; i < this.globalService.streamer.participatingExternalAffiliatesOnly.length; i++) {
          let participatingAffiliate = this.globalService.streamer.participatingExternalAffiliatesOnly[i];

          let found = false;
          for (let j = 0; j < requestedAffiliatesToBeRemoved.length; j++) {
            let affiliateToBeRemoved = requestedAffiliatesToBeRemoved[j];

            if (participatingAffiliate.name === affiliateToBeRemoved.name) {
              found = true;
            }
          }

          if (!found) {
            finalParticipatingExternalAffiliates.push(participatingAffiliate);
          }
        }
      }

      this.selectedAffiliates = finalParticipatingExternalAffiliates;
      this.originalSelectedAffiliates = finalParticipatingExternalAffiliates.slice();

    });
  }

  selectBanner(banner: any, versionNumber: number) {
    this.selectedBanner = banner;
    this.selectedVersionNumber = versionNumber + 1;
  }

  updateChanges() {
    if (this.globalService.streamer) {
      this.tooManyAffiliates = false;
      this.newToAdd = [];
      this.newToRemove = [];

      this.newToRemove = this.originalSelectedAffiliates.filter(item => this.selectedAffiliates.indexOf(item) < 0 && item.requested == false);
      this.newToAdd = this.selectedAffiliates.filter(item => this.originalSelectedAffiliates.indexOf(item) < 0 && item.requested == false);

      if (this.newToAdd.length > 0 || this.newToRemove.length > 0) {

        if (this.maxAffiliateLimitation) {
          if ((this.globalService.streamer.numberOfINRequests - this.globalService.streamer.numberOfOUTRequests)
            + this.globalService.streamer.currentNumberOfExternalAffiliates
            + (this.newToAdd.length - this.newToRemove.length) <= this.globalService.streamer.allowedNumberOfExternalAffiliates) {

            this.changes = true;
          } else {
            this.tooManyAffiliates = true;
            this.changes = false;
          }
        } else {
          this.changes = true;
        }

      } else {
        this.changes = false;
      }
    }
  }

  selectAffiliate(event: any) {
    this.selectedAffiliate = event.items[0];
  }

  openDescriptionDialog() {
    this.showAffiliateDescription = true;
  }

  saveAffiliateChanges() {
    this.streamerService.saveExternalAffiliateChanges(this.newToAdd, this.newToRemove).then(result => {
      this.changes = false;
      this.showSentHint = true;

      // Update machen
      this.initializeAffiliatePrograms();
    });
  }

  resetAffiliates() {
    this.changes = false;
    this.tooManyAffiliates = false;
    this.affiliateSource = this.originalAffiliateSource.slice();
    this.selectedAffiliates = this.originalSelectedAffiliates.slice();
  }

  copyLink(pictureId: string) {
    const link = document.createElement('textarea');
    link.style.position = 'fixed';
    link.style.left = '0';
    link.style.top = '0';
    link.style.opacity = '0';
    link.value = this.selectedAffiliateForLink.getLink(pictureId);
    document.body.appendChild(link);
    link.focus();
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
  }

  generateBannerHeader(): string {
    let header = this.i18nService.localize().StreamerAffiliatesComponent.AllBannerHeader;

    if (this.selectedAffiliateForLink) {
      header += ': '+this.selectedAffiliateForLink.getReadableType(this.i18nService.getCurrentLang());
    }

    return header;
  }
}
