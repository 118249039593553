import {Injectable} from '@angular/core';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {CookieService} from 'ngx-cookie-service';
import {I18nService} from './i18n.service';
import {AdvertiserCampaign} from '../model/advertiser/advertiser-campaign.model';
import {CampaignScheduling} from '../model/advertiser/campaign-scheduling.model';
import {CampaignAffiliate} from '../model/advertiser/campaign-affiliate.model';
import {CampaignDemographic} from '../model/advertiser/campaign-demographic.model';
import {SimpleDemographicCountry} from '../model/advertiser/simple-demo-country.model';
import {PictureService} from './picture.service';
import {GameStreamStatistic} from '../model/advertiser/game-stream-statistic.model';
import {AdvertiserService} from './advertiser.service';
import {CampaignLiveStream} from '../model/advertiser/campaign-live-stream.model';
import {CampaignFinishedStream} from '../model/advertiser/campaign-finished-stream.model';
import {WizardWIP} from '../model/wizard-wip.model';
import {Router} from "@angular/router";

@Injectable()
export class CampaignService extends SuperService {

  private maximalViewCapacity = 0;

  constructor(public streamBoostEventBus: StreamBoostEventBus,
              private cookieService: CookieService, public router: Router,
              private i18nService: I18nService,
              private pictureService: PictureService,
              private advertiserService: AdvertiserService) {
    super(streamBoostEventBus);
  }

  public getCampaign(campaignId: string): Promise<AdvertiserCampaign> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const lang = this.i18nService.getCurrentLang();
        this.streamBoostEventBus.send('stream.web.facade.campaign',
          {
            message: {
              campaign_id: campaignId,
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              const campaign: AdvertiserCampaign = new AdvertiserCampaign();
              const campaignFromDB = response.body.response;

              if (campaignFromDB) {

                campaign.id = campaignFromDB._id;
                campaign.name = campaignFromDB.name;
                campaign.displayName = campaignFromDB.display_name;
                campaign.description = campaignFromDB.description;

                campaign.views = campaignFromDB.views;
                campaign.viewsAccounted = campaignFromDB.views_accounted;
                campaign.viewsPlanned = campaignFromDB.views_planned;

                campaign.status = campaignFromDB.status;
                campaign.pictures = campaignFromDB.pictures;
                campaign.progress = campaignFromDB.progress;
                campaign.keywords = campaignFromDB.keywords;
                campaign.gameKeywords = campaignFromDB.game_keywords;
                campaign.ignoredGameKeywords = campaignFromDB.ignored_game_keywords;
                campaign.keywords = campaignFromDB.keywords;
                campaign.streamerGender = campaignFromDB.streamer_gender;
                campaign.streamerAgeTo = campaignFromDB.streamer_age_to;
                campaign.streamerAgeFrom = campaignFromDB.streamer_age_from;
                campaign.keywordIds = campaignFromDB.keyword_ids;
                campaign.numberOfStreamersAttached = campaignFromDB.streamer_ids;

                campaign.streamerFocus = campaignFromDB.streamer_focus;
                campaign.intensityFocus = campaignFromDB.intensity_focus;
                campaign.targetAgeFrom = campaignFromDB.target_age_from;
                campaign.targetAgeTo = campaignFromDB.target_age_to;
                campaign.targetGender = campaignFromDB.target_gender;
                campaign.targetCountries = campaignFromDB.target_countries;
                campaign.streamerAgeFrom = campaignFromDB.streamer_age_from;
                campaign.streamerAgeTo = campaignFromDB.streamer_age_to;
                campaign.budget = campaignFromDB.budget;

                if (campaignFromDB.logo_url) {
                  campaign.logoURL = campaignFromDB.logo_url;
                }

                const schedulingFromDB = campaignFromDB.scheduling;
                if (schedulingFromDB) {
                  const scheduling: CampaignScheduling = new CampaignScheduling();
                  scheduling.days = schedulingFromDB.days;
                  scheduling.countedDailyViews = schedulingFromDB.counted_daily_views;
                  scheduling.dailyViews = schedulingFromDB.daily_views;
                  scheduling.startTime = schedulingFromDB.start_time;
                  scheduling.endTime = schedulingFromDB.end_time;
                  scheduling.startDate = schedulingFromDB.start_date;

                  if (schedulingFromDB.start_date_raw) {
                    scheduling.startDateRaw = new Date(schedulingFromDB.start_date_raw);
                  }
                  if (schedulingFromDB.end_date_raw) {
                    scheduling.endDateRaw = new Date(schedulingFromDB.end_date_raw);
                  }


                  if (schedulingFromDB.end_date) {
                    scheduling.endDate = schedulingFromDB.end_date;
                  } else {
                    scheduling.endDate = schedulingFromDB.calculated_end_date;
                  }

                  campaign.scheduling = scheduling;
                }

                const affiliateFromDB = campaignFromDB.affiliate;
                if (affiliateFromDB) {
                  const affiliate: CampaignAffiliate = new CampaignAffiliate();
                  affiliate.code = affiliateFromDB.code;
                  affiliate.link = affiliateFromDB.link;
                  affiliate.files = affiliateFromDB.files;

                  campaign.affiliate = affiliate;
                }
              }
              resolve(campaign);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getCampaignBySessionId(sessionId: string): Promise<AdvertiserCampaign> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const lang = this.i18nService.getCurrentLang();
        this.streamBoostEventBus.send('stream.web.facade.session.campaign',
          {
            message: {
              session_id: sessionId,
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                window.location.href = this.router.url.split('?')[0]
                return;
              }

              const campaign: AdvertiserCampaign = new AdvertiserCampaign();
              const campaignFromDB = response.body.response;

              if (campaignFromDB) {

                campaign.id = campaignFromDB._id;
                campaign.name = campaignFromDB.name;
                campaign.displayName = campaignFromDB.display_name;
                campaign.description = campaignFromDB.description;

                campaign.views = campaignFromDB.views;
                campaign.viewsAccounted = campaignFromDB.views_accounted;
                campaign.viewsPlanned = campaignFromDB.views_planned;

                campaign.status = campaignFromDB.status;
                campaign.pictures = campaignFromDB.pictures;
                campaign.progress = campaignFromDB.progress;
                campaign.keywords = campaignFromDB.keywords;
                campaign.gameKeywords = campaignFromDB.game_keywords;
                campaign.ignoredGameKeywords = campaignFromDB.ignored_game_keywords;
                campaign.keywords = campaignFromDB.keywords;
                campaign.streamerGender = campaignFromDB.streamer_gender;
                campaign.streamerAgeTo = campaignFromDB.streamer_age_to;
                campaign.streamerAgeFrom = campaignFromDB.streamer_age_from;
                campaign.keywordIds = campaignFromDB.keyword_ids;
                campaign.numberOfStreamersAttached = campaignFromDB.streamer_ids;

                campaign.streamerFocus = campaignFromDB.streamer_focus;
                campaign.intensityFocus = campaignFromDB.intensity_focus;
                campaign.targetAgeFrom = campaignFromDB.target_age_from;
                campaign.targetAgeTo = campaignFromDB.target_age_to;
                campaign.targetGender = campaignFromDB.target_gender;
                campaign.targetCountries = campaignFromDB.target_countries;
                campaign.streamerAgeFrom = campaignFromDB.streamer_age_from;
                campaign.streamerAgeTo = campaignFromDB.streamer_age_to;
                campaign.budget = campaignFromDB.budget;

                if (campaignFromDB.logo_url) {
                  campaign.logoURL = campaignFromDB.logo_url;
                }

                const schedulingFromDB = campaignFromDB.scheduling;
                if (schedulingFromDB) {
                  const scheduling: CampaignScheduling = new CampaignScheduling();
                  scheduling.days = schedulingFromDB.days;
                  scheduling.countedDailyViews = schedulingFromDB.counted_daily_views;
                  scheduling.dailyViews = schedulingFromDB.daily_views;
                  scheduling.startTime = schedulingFromDB.start_time;
                  scheduling.endTime = schedulingFromDB.end_time;
                  scheduling.startDate = schedulingFromDB.start_date;

                  if (schedulingFromDB.start_date_raw) {
                    scheduling.startDateRaw = new Date(schedulingFromDB.start_date_raw);
                  }
                  if (schedulingFromDB.end_date_raw) {
                    scheduling.endDateRaw = new Date(schedulingFromDB.end_date_raw);
                  }


                  if (schedulingFromDB.end_date) {
                    scheduling.endDate = schedulingFromDB.end_date;
                  } else {
                    scheduling.endDate = schedulingFromDB.calculated_end_date;
                  }

                  campaign.scheduling = scheduling;
                }

                const affiliateFromDB = campaignFromDB.affiliate;
                if (affiliateFromDB) {
                  const affiliate: CampaignAffiliate = new CampaignAffiliate();
                  affiliate.code = affiliateFromDB.code;
                  affiliate.link = affiliateFromDB.link;
                  affiliate.files = affiliateFromDB.files;

                  campaign.affiliate = affiliate;
                }
              }
              resolve(campaign);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getDemographicForCampaign(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.campaign.demo',
          {
            message: {
              campaign_id: campaignId,
              lang: this.i18nService.getCurrentLang().toLowerCase()
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              const campaignDemoFromDB = response.body.response;
              const demo: CampaignDemographic = new CampaignDemographic();

              demo.under18 = campaignDemoFromDB.under_18;
              demo.from18to24 = campaignDemoFromDB['18_to_24'];
              demo.from25to34 = campaignDemoFromDB['25_to_34'];
              demo.from35to44 = campaignDemoFromDB['35_to_44'];
              demo.over44 = campaignDemoFromDB.over_44;

              demo.male = campaignDemoFromDB.male;
              demo.female = campaignDemoFromDB.female;
              demo.diverse = campaignDemoFromDB.diverse;

              const countriesFromDB = campaignDemoFromDB.countries;
              if (countriesFromDB) {
                let i: number;
                for (i = 0; i < countriesFromDB.length; i++) {
                  const country: SimpleDemographicCountry = new SimpleDemographicCountry();
                  country.name = countriesFromDB[i].name;
                  country.count = countriesFromDB[i].count;
                  country.share = countriesFromDB[i].share;

                  demo.countries.push(country);
                }
              }

              resolve(demo);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getBurndownOfCampaign(campaignId: string, burndown: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.burndown',
          {
            message: {campaign_id: campaignId, burndown: burndown}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);

            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public pauseCampaign(campaignId: string, ipAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.pause',
          {
            message: {
              campaign_id: campaignId,
              ip_address: ipAddress
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve(response.body);
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                resolve(response.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  startCampaign(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.activate',
          {
            message: {
              campaign_id: campaignId,
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve(response.body);
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                resolve(response.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public updateCampaign(campaign_id: string, campaignName: string, displayName: string, description: string,
                        intensityFocus?: any, streamerFocus?: any, selectedKeywordItems?: any[],
                        views?: number,): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne speichern
        this.streamBoostEventBus.send('stream.web.facade.campaign.edit',
          {
            message: {
              id: campaign_id,
              campaign_name: campaignName,
              display_name: displayName,
              description,
              intensity_focus: intensityFocus,
              streamer_focus: streamerFocus,
              keywords: selectedKeywordItems,
              views,
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  private convertDates(dates: Date[]) {
    let startDateString;
    let endDateString;
    if (dates) {
      var startDate: Date = dates[0];
      var endDate: Date = dates[1];
      startDateString = startDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
      endDateString = endDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
    }
    return {startDateString, endDateString};
  }

  private b64toFile(b64Data: any, contentType: string, sliceSize: any, fileName: string): File {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let returnFile: any = new Blob(byteArrays, {type: contentType});
    returnFile.lastModifiedDate = new Date();
    returnFile.name = fileName;
    return <File>returnFile;
  }

  public getAllGameStreamStatistics(campaignId: string): Promise<GameStreamStatistic[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.all.games',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              let gameStreams = response.body.response.games_streams;
              let gameStreamStatistics: GameStreamStatistic[] = [];
              if (gameStreams) {
                gameStreamStatistics = gameStreams;
              }

              resolve(gameStreamStatistics);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getCampaignInfoForExtension(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const lang = this.i18nService.getCurrentLang();
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.extend.info',
          {
            message: {
              campaign_id: campaignId,
              lang
            }
          })
          .then(
            (result: any) => {
              if (result.body) {
                resolve(result.body.response);
              } else if (result.body.code) {
                reject(result.body.code);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public saveCampaignExtension(campaignId: string, billNr: string, budget: number, paypalPayment: boolean,
                               dailyViews: number, daysBetween: number, startDate: Date, viewBuffer: number,
                               initialViews: number, affiliate: boolean, tkp: number, advertiserId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let startDateString = startDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.extend',
          {
            message: {
              campaign_id: campaignId,
              bill_nr: billNr,
              budget: budget + '',
              paypal_payment: paypalPayment,
              daily_views: dailyViews + '',
              days_between: daysBetween,
              view_buffer: viewBuffer + '',
              initial_views: initialViews,
              affiliate_included: affiliate,
              tkp,
              start_date: startDateString,
              advertiser_id: advertiserId
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {

                if (this.advertiserService.isAdvertiserSet()) {
                  this.advertiserService.setAdvertiser(result.body.response);
                }

                resolve();
              } else if (result.body.code) {
                reject(result.body.code);
              }
              resolve();
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  deleteCampaign(campaignId: string, campaignState: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne löschen
        this.streamBoostEventBus.send('stream.web.facade.campaign.delete',
          {
            message: {
              campaign_id: campaignId,
              status: campaignState
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve();
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                reject();
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  getLiveStreamsOfCampaign(campaignId: string): Promise<CampaignLiveStream[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.live.streams',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let streams: CampaignLiveStream[] = [];

              let streamFromDB = response.body.response.streams;
              for (let i = 0; i < streamFromDB.length; i++) {
                const stream = new CampaignLiveStream();
                stream.streamId = streamFromDB[i].stream_id;
                stream.streamerName = streamFromDB[i].streamer;
                stream.network = streamFromDB[i].network;
                stream.profileLink = streamFromDB[i].profile_link;
                stream.games = streamFromDB[i].games;

                if (i % 2 == 0) {
                  stream.darkStyledEntry = false;
                }

                streams.push(stream);
              }

              resolve(streams);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  getFinishedStreamsOfCampaign(campaignId: string, first?: number, rows?: number, sortField?: string, sortOrder?: number): Promise<CampaignFinishedStream[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.finished.streams',
          {
            message: {
              campaign_id: campaignId,
              first,
              rows,
              sort_field: sortField,
              sort_order: sortOrder
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let streams: CampaignFinishedStream[] = [];

              let streamFromDB = response.body.response.streams;
              for (let i = 0; i < streamFromDB.length; i++) {
                const stream = new CampaignFinishedStream();
                stream.streamId = streamFromDB[i].stream_id;
                stream.streamerName = streamFromDB[i].streamer;
                stream.network = streamFromDB[i].network;
                stream.games = streamFromDB[i].games;
                stream.startDate = streamFromDB[i].start_date;
                stream.endDate = streamFromDB[i].end_date;
                stream.views = streamFromDB[i].views;
                stream.streamDuration = streamFromDB[i].stream_duration;
                stream.adDuration = streamFromDB[i].ad_duration;
                stream.profileLink = streamFromDB[i].profile_link;

                streams.push(stream);
              }

              resolve(streams);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getMaxFinishedStreamsOfCampaign(campaignId: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.campaign.finished.streams.max',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getStreamTimeHeatmapOfCampaign(campaignId: string, currentCW: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.heatmap',
          {
            message: {
              campaign_id: campaignId,
              lang: this.i18nService.getCurrentLang(),
              current_cw: currentCW
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);

            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getGameCategoryStreamsStatisticForCampaign(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.game.keywords',
          {
            message: {
              campaign_id: campaignId,
              lang: this.i18nService.getCurrentLang()
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getGameTimeStatisticForCampaign(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.game.times',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getGameStatisticForCampaign(campaignId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.campaign.game',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

    public getGameCategoryStatisticForCampaign(campaignId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.cookieService.get('streamboost-session')) {
                this.streamBoostEventBus.send('stream.web.facade.campaign.game.category',
                    {
                        message: {
                            campaign_id: campaignId
                        }
                    })
                    .then(
                        (response: any) => {
                            if (response.body.code) {
                                reject(response.body.code);
                                this.cookieService.deleteAll();
                                localStorage.clear();
                                return;
                            }
                            resolve(response.body.response);
                        }
                    )
                    .catch(
                        (err: any) => {
                            console.log(err);
                            reject(err);
                        }
                    );
            }
        });
    }


  public saveDisplayClickCampaignForAdvertiserOrAgency(createDisplayCampaign: boolean, createClickCampaign: boolean, campaignName: string,
                                                       displayName: string,
                                                       language: string, selectedKeywordItems: string[], intensityFocus: string,
                                                       streamerFocus: string, selectedGender: string[], ageRangeValues: number[],
                                                       streamerAgeRangeValues: number[], selectedGameKeywordItems: string[],
                                                       selectedNonGameKeywordItems: string[], description: string,
                                                       uploadedPicturesBackup: any[], agencyPictureIdsForDisplay: string[],
                                                       affiliateLink: string, uploadedBannersBackup: any[],
                                                       agencyPictureIdsForClick: string[], chatMessage: string,
                                                       googleTagId: string, totalBudget: number, totalDisplayBudget: number,
                                                       totalClickBudget: number, views: number, clicks: number, dailyViews: number,
                                                       dailyClicks: number, startDate: Date, tkp: number, discountCodeId: string,
                                                       billNr: string, paypalPayment: boolean, chosenAddress: string,
                                                       advertiserId: string, cpc: number, ipAddress: string, sessionId: string,
                                                       remainingBaseBalance: number, isBalancePay: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        let startDateString = startDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

        // Kampagnen speichern
        this.streamBoostEventBus.send('stream.web.facade.campaign.display.click.save',
          {
            message: {
              create_display_campaign: createDisplayCampaign,
              create_click_campaign: createClickCampaign,

              campaign_name: campaignName,
              display_name: displayName,
              language,
              keywords: selectedKeywordItems,
              intensity_focus: intensityFocus,
              streamer_focus: streamerFocus,
              gender: selectedGender,
              age_range: ageRangeValues,
              streamer_age_range: streamerAgeRangeValues,
              game_keywords: selectedGameKeywordItems,
              ignore_game_keywords: selectedNonGameKeywordItems,

              description,
              link: affiliateLink,
              chat_message: chatMessage,
              tag_id: googleTagId,

              total_budget: totalBudget + '',
              display_budget: totalDisplayBudget + '',
              click_budget: totalClickBudget + '',
              views,
              clicks,
              daily_views: dailyViews,
              daily_clicks: dailyClicks,
              start_date: startDateString,
              tkp,
              cpc,

              display_agency_picture_ids: agencyPictureIdsForDisplay,
              click_agency_picture_ids: agencyPictureIdsForClick,

              discount_code_id: discountCodeId,
              bill_nr: billNr,
              paypal_payment: paypalPayment,
              chosen_address: chosenAddress,

              advertiser_id: advertiserId,
              ip_address: ipAddress,

              session_id: sessionId,

              remaining_base_balance: remainingBaseBalance,
              is_balance_pay: isBalancePay
            }
          })
          .then(
            (response: any) => {
              if (response.body.response) {
                let campaigns = response.body.response;
                let newCampaignId = campaigns.campaign_id;
                let newAffiliateId = campaigns.affiliate_id;

                // Bilder speichern
                const uploadPromises: any[] = [];

                if (createDisplayCampaign) {
                  uploadPromises.push(this.pictureService.uploadImagesHttp(uploadedPicturesBackup, newCampaignId, 'BANNER', 'campaign'));
                }

                if (createClickCampaign) {
                  uploadPromises.push(this.pictureService.uploadImagesHttp(uploadedBannersBackup, newAffiliateId, 'AFFILIATE_BANNER', 'affiliate'));
                  uploadPromises.push(this.pictureService.copyLogoForInternalAffiliate(newAffiliateId, advertiserId));
                }

                Promise.all(uploadPromises).then((values) => {
                  resolve();
                }).catch((error) => {
                  console.log(error);
                  reject();
                });

              } else {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public submitForPaymentReview(advertiserId: string, campaignId: string) {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        console.log("send campaign for review with id: " + campaignId + " and advertiser id: " + advertiserId)
        // Kampagnen speichern
        this.streamBoostEventBus.send('stream.web.facade.wizard.for.review.wip',
          {
            message: {
              advertiser_id: advertiserId,
              campaign_id: campaignId
            }
          }).then(
          (response: any) => {
            if (response.body.code) {
              this.cookieService.deleteAll();
              localStorage.clear();
              return;
            }
            resolve(response.body.response);
          }
        ).catch(
          (err: any) => {
            console.log(err);
            reject(err);
          }
        );
      }
    });
  }

  public updateWizardWIP(createDisplayCampaign: boolean, createClickCampaign: boolean, selectedTarget: number,
                         displayClickPercent: number, campaignName: string, displayName: string, language: string,
                         selectedKeywordItems: string[], intensityFocus: string, streamerFocus: string,
                         selectedGender: string[], ageRangeValues: number[], streamerAgeRangeValues: number[],
                         selectedGameKeywordItems: string[], selectedNonGameKeywordItems: string[], description: string,
                         uploadedPicturesBackup: any[], agencyPictureIdsForDisplay: string[], affiliateLink: string,
                         uploadedBannersBackup: any[], agencyPictureIdsForClick: string[], chatMessage: string, activeStreamerAge: boolean,
                         totalBudget: number, dailyBudget: number, views: number, clicks: number, dailyViews: number,
                         dailyClicks: number, startDate: Date, endDate: Date, tkp: number,
                         bannerContainsAnimatedPicture: boolean, step: number, advertiserId: string, campaignId: string): Promise<string> {
    return new Promise((resolve, reject) => {

      let startDateString = '';
      let endDateString = '';
      if (startDate) {
        startDateString = startDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
      }
      if (endDate) {
        endDateString = endDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
      }

      // Wenn der Advertiser bzw. die Agentur angemeldet ist
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP aktualisieren
        this.streamBoostEventBus.send('stream.web.facade.wizard.update.wip',
          {
            message: {
              create_display_campaign: createDisplayCampaign,
              create_click_campaign: createClickCampaign,
              selected_target: selectedTarget,
              display_click_percent: displayClickPercent,

              campaign_name: campaignName,
              display_name: displayName,
              language,
              keywords: selectedKeywordItems,
              intensity_focus: intensityFocus,
              streamer_focus: streamerFocus,
              gender: selectedGender,
              age_range: ageRangeValues,
              streamer_age: activeStreamerAge,
              streamer_age_range: streamerAgeRangeValues,
              game_keywords: selectedGameKeywordItems,
              ignore_game_keywords: selectedNonGameKeywordItems,

              description,
              link: affiliateLink,
              chat_message: chatMessage,

              total_budget: totalBudget + '',
              daily_budget: dailyBudget + '',
              views,
              clicks,
              daily_views: dailyViews,
              daily_clicks: dailyClicks,
              start_date: startDateString,
              end_date: endDateString,
              tkp,
              contains_animated_picture: bannerContainsAnimatedPicture,

              display_agency_picture_ids: agencyPictureIdsForDisplay,
              click_agency_picture_ids: agencyPictureIdsForClick,

              step,

              advertiser_id: advertiserId,
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let wizardWIP = response.body.response;
              resolve(wizardWIP.id);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public setSessionWizardWIP(campaignId: string, sessionId: string, advertiserId?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Wenn der Advertiser bzw. die Agentur angemeldet ist
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP aktualisieren
        this.streamBoostEventBus.send('stream.web.facade.session.wizard.update.wip',
          {
            message: {
              campaign_id: campaignId,
              session_id: sessionId,
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let wizardWIP = response.body.response;
              resolve(wizardWIP.id);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public setSessionExtend(campaignId: string, sessionId: string, advertiserId?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Wenn der Advertiser bzw. die Agentur angemeldet ist
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP aktualisieren
        this.streamBoostEventBus.send('stream.web.facade.session.campaign.update',
          {
            message: {
              campaign_id: campaignId,
              session_id: sessionId,
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let success = response.body.response;
              resolve(success);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public findWizardWIP(advertiserId?: string, campaignId?: string): Promise<any | void> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP holen
        this.streamBoostEventBus.send('stream.web.facade.wizard.retrieve.wip',
          {
            message: {
              advertiser_id: advertiserId,
              campaign_id: campaignId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let wizardWIPFromDB = response.body.response;
              if (wizardWIPFromDB) {
                let wizardWIP = new WizardWIP();

                wizardWIP.id = wizardWIPFromDB._id;
                wizardWIP.step = wizardWIPFromDB.step;
                wizardWIP.createClickCampaign = wizardWIPFromDB.create_click_campaign;
                wizardWIP.createDisplayCampaign = wizardWIPFromDB.create_display_campaign;
                wizardWIP.displayClickPercent = wizardWIPFromDB.display_click_percent;
                wizardWIP.selectedTarget = wizardWIPFromDB.selected_target;
                wizardWIP.status = wizardWIPFromDB.status;
                wizardWIP.rejectedReason = wizardWIPFromDB.rejected_reason;

                wizardWIP.campaignName = wizardWIPFromDB.name;
                wizardWIP.displayName = wizardWIPFromDB.display_name;
                wizardWIP.keywords = wizardWIPFromDB.keywords;
                wizardWIP.targetGender = wizardWIPFromDB.target_gender;
                wizardWIP.language = wizardWIPFromDB.language;
                wizardWIP.targetAgeFrom = wizardWIPFromDB.age_from;
                wizardWIP.targetAgeTo = wizardWIPFromDB.age_to;
                wizardWIP.intensityFocus = wizardWIPFromDB.intensity_focus;
                wizardWIP.streamerFocus = wizardWIPFromDB.streamer_focus;
                wizardWIP.gameKeywords = wizardWIPFromDB.game_keywords;
                wizardWIP.ignoredGameKeywords = wizardWIPFromDB.ignored_game_keywords;
                wizardWIP.activateStreamerAge = wizardWIPFromDB.activate_streamer_age;
                wizardWIP.streamerAgeFrom = wizardWIPFromDB.streamer_age_from;
                wizardWIP.streamerAgeTo = wizardWIPFromDB.streamer_age_to;

                wizardWIP.description = wizardWIPFromDB.description;
                wizardWIP.link = wizardWIPFromDB.link;
                wizardWIP.chatMessage = wizardWIPFromDB.chat_message;
                wizardWIP.containsAnimatedPicture = wizardWIPFromDB.contains_animated_picture;
                wizardWIP.displayAgencyPictureIds = wizardWIPFromDB.display_agency_picture_ids;
                wizardWIP.clickAgencyPictureIds = wizardWIPFromDB.click_agency_picture_ids;

                if (wizardWIPFromDB.start_date) {
                  wizardWIP.startDate = new Date(wizardWIPFromDB.start_date);
                }

                if (wizardWIPFromDB.end_date) {
                  wizardWIP.endDate = new Date(wizardWIPFromDB.end_date);
                }
                wizardWIP.clicks = wizardWIPFromDB.clicks;
                wizardWIP.dailyClicks = wizardWIPFromDB.daily_clicks;
                wizardWIP.views = wizardWIPFromDB.views;
                wizardWIP.dailyViews = wizardWIPFromDB.daily_views;
                wizardWIP.totalBudget = wizardWIPFromDB.total_budget;
                wizardWIP.dailyBudget = wizardWIPFromDB.daily_budget;
                wizardWIP.tkp = wizardWIPFromDB.tkp;

                // pictures
                let campaignPics: File [] = [];
                if (wizardWIPFromDB.campaign_pics) {
                  var i: number;
                  for (i = 0; i < wizardWIPFromDB.campaign_pics.length; i++) {
                    campaignPics.push(this.b64toFile(wizardWIPFromDB.campaign_pics[i].content, wizardWIPFromDB.campaign_pics[i].content_type, null, wizardWIPFromDB.campaign_pics[i].name));
                  }
                }
                wizardWIP.campaignPics = campaignPics;

                let affiliatePics: File [] = [];
                if (wizardWIPFromDB.affiliate_pics) {
                  var i: number;
                  for (i = 0; i < wizardWIPFromDB.affiliate_pics.length; i++) {
                    affiliatePics.push(this.b64toFile(wizardWIPFromDB.affiliate_pics[i].content, wizardWIPFromDB.affiliate_pics[i].content_type, null, wizardWIPFromDB.affiliate_pics[i].name));
                  }
                }
                wizardWIP.affiliatePics = affiliatePics;

                resolve(wizardWIP);
              } else {
                // @ts-ignore
                resolve();
              }

            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public deleteWizardWIP(wipId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP löschen
        this.streamBoostEventBus.send('stream.web.facade.wizard.delete.wip',
          {
            message: {
              wip_id: wipId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve();
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                reject();
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

}
