import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../services/routing.service';
import { CookieService } from 'ngx-cookie-service';
import { SocialService } from '../../services/social.service';
import { I18nService } from '../../services/i18n.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'streamer-login',
  templateUrl: './streamer-login.component.html',
  styleUrls: ['./streamer-login.component.css']
})
export class StreamerLoginComponent implements OnInit {
  public loginError?: string;

  public working: boolean = false;

  constructor(private socialService: SocialService, public routingService: RoutingService,
              public i18nService: I18nService, public cookieService: CookieService,
              public globalService: GlobalService, public route: ActivatedRoute) {

    if (this.cookieService.get('streamboost-language')) {
      this.i18nService.setLang(this.cookieService.get('streamboost-language'));
    } else {
      this.i18nService.setLang(navigator.language.toUpperCase());
    }
  }

  ngOnInit(): void {
    this.loginError = this.route.snapshot.params.error;
  }

  loginWithTwitch() {
    this.working = true;

    this.socialService.getTwitchLoginURL(true)
      .then((data) => {
        window.location.href = data.response.url;
        this.working = false;
      })
      .catch((error) => {
        console.log(error);
        this.working = false;
      });

  }

  loginWithGoogle() {
    this.working = true;

    this.socialService.getGoogleLoginURL(true)
      .then((data) => {
        window.location.href = data.response.url;
        this.working = false;
      })
      .catch((error) => {
        console.log(error);
        this.working = false;
      });

  }

  resetError() {
    delete this.loginError;
  }

}
