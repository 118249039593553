<p-dialog [modal]="true" position="center" [(visible)]="show" [blockScroll]="true" [closeOnEscape]="false" [closable]="false"
          [baseZIndex]="1" [draggable]="false" [style]="{width: '70%', maxWidth: '770px'}">
  <ng-template pTemplate="header">
    <div class="headline" *ngIf="this.globalService.streamer">{{i18nService.localize().AcceptTermsDialogComponent.NewTermsHeader}}</div>
    <div class="headline" *ngIf="this.globalService.agency || this.globalService.advertiser">{{i18nService.localize().AcceptTermsDialogComponent.NewConditionsHeader}}</div>
  </ng-template>

  <div class="ui-fluid" *ngIf="this.globalService.streamer">
    <p>{{i18nService.localize().AcceptTermsDialogComponent.NewTermsText}}</p>
    <a href="{{this.globalService.links.termsLink}}" *ngIf="globalService.links" target="_blank">{{this.i18nService.localize().MenuComponent.TermsOfUseLink}}</a>
  </div>

  <div class="ui-fluid" *ngIf="this.globalService.agency || this.globalService.advertiser">
    <p>{{i18nService.localize().AcceptTermsDialogComponent.NewConditionsText}}</p>
    <a href="{{this.globalService.links.conditionsLink}}" *ngIf="globalService.links" target="_blank">{{this.i18nService.localize().MenuComponent.TermsAndConditionsLink}}</a>
  </div>

  <div style="text-align: right">
    <button pButton pRipple type="button" (click)="acceptTerms()" icon="fas fa-check"  style="margin-right: .25em" class="p-button-rounded"></button>
    <button pButton pRipple type="button" (click)="logout()" icon="fas fa-arrow-right"  class="p-button-rounded p-button-secondary"></button>
  </div>
</p-dialog>
