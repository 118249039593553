export class DemographyInfo {

  public id!: string;

  // Twitch or other ...
  public channelName!: string;

  public countries: string[] = [];

  // percentage age distribution
  public percLessThen18: number;
  public perc18to24: number;
  public perc25to34: number;
  public perc35to44: number;
  public percOlderThen44: number;

  // percentage Gender
  public male: number;
  public female: number;
  public divers: number;

  constructor() {
    this.channelName = '';

    this.percLessThen18 = 0;
    this.perc18to24 = 0;
    this.perc25to34 = 0;
    this.perc35to44 = 0;
    this.percOlderThen44 = 0;

    this.male = 0;
    this.female = 0;
    this.divers = 0;
  }

  public isValid(): boolean {
    if (this.channelName.length <= 0 ) {
      return false;
    }
    if (this.countries.length <= 0 ) {
      return false;
    }
    if (!this.validatePercentageAgeDistribution()) {
      return false;
    }
    if (!this.validatePercentageAgeDistribution()) {
      return false;
    }
    return true;
  }

  public validatePercentageAgeDistribution(): boolean {
    let sum: number = 0;

    sum = this.percLessThen18 + this.perc18to24 + this.perc25to34 + this.perc35to44 + this.percOlderThen44;

    if (sum > 100 || sum < 0){
      return false;
    } else {
      return true;
    }
  }

  public validatePercentageGenderDistribution(): boolean {
    let sum: number = 0;

    sum = this.male + this.female + this.divers;

    if (sum > 100 || sum < 0){
      return false;
    } else {
      return true;
    }
  }
}
