import { Injectable } from '@angular/core';
import { StreamerService } from './streamer.service';
import { StreamBoostEventBus } from '../streamboost-eventbus.service';
import { SuperService } from './super.service';

@Injectable()
export class SocialService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus,
    private streamerService: StreamerService) {
    super(streamBoostEventBus);
  }

  getTwitchLoginURL(register: boolean, streamer_id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.oauth.twitch',
        { 'message': {
          register,
          streamer_id
          }
        })
        .then((result) => {
          if (result.body.code) {
            reject(result.body.code);
            return;
          }

          if (result.body.response.url) {
            resolve(result.body);
          }
          reject(result.body);

        }
        )
        .catch((error) => {
          reject(error);
        }
        );
    })
  }

  getGoogleLoginURL(register: boolean, streamer_id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.oauth.google',
        { 'message': {
            register,
            streamer_id
          }
        })
        .then((result) => {
          if (result.body.code) {
            reject(result.body.code);
            return;
          }

          if (result.body.response.url) {
            resolve(result.body);
          }
          reject(result.body);

        }
        )
        .catch((error) => {
          reject(error);
        }
        );
    })
  }

  handleLoginCallback(code: string, state: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.oauth.login',
        {
          'message': { 'code': code, 'state': state, 'ip': ip }
        })
        .then((result) => {
          if (result.body.code) {
            reject(result.body);
          }
          if (result.body.response.token) {
            this.streamerService.userLoggedIn(result.body.response.token);
            resolve(result.body.response.additional);
          } else {
            resolve(result.body.response.additional);
          }
        }
        )
        .catch((error) => {
          reject(error);
        }
        );
    })
  }

}
