<div *ngIf="this.globalService.agency&&advertiser">
  <div class="p-grid">
    <div class="p-col-12 p-lg-1 p-xl-1" *ngIf="advertiser.logoURL" style="text-align: center;padding-top: 30px;">
      <img alt="logo" [src]="advertiser.logoURL" style="border-radius: 500px; height: 70px;width: 70px;background-color: white;"/>
    </div>
    <div class="p-col-12 p-lg-11 p-xl-11">
      <div class="p-grid">
        <div class="p-col-12 p-lg-3 p-xl-2" style="padding-top: 38px;">
          <button pButton pRipple type="button"
                  label="{{i18nService.localize().AdvertiserDetailsComponent.BackToOverviewButton}}" class="p-button-rounded"
                  icon="fas fa-chevron-left" (click)="back()">
          </button>
        </div>
        <div class="p-col-12 p-lg-9 p-xl-10">
          <p class="company-headline">{{advertiser.companyName}}</p>
        </div>
      </div>
    </div>

    <div id="tabs" class="p-col-12" *ngIf="!selectedCampaignId&&!selectedAffiliateId&&!selectedAffiliateIdForExtension&&!selectedCampaignIdForExtension">
      <div class="tab-menu">
        <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="this.activeTab=1">
          <span class="p-menuitem-icon fas fa-ad" style="margin-right: 0.5rem;"></span>
          <span class="p-menuitem-text">{{i18nService.localize().AdvertiserDetailsComponent.InformationTab}}</span>
        </div>
        <div [class]="this.activeTab===4 ? 'tab tab-selected' : 'tab'" (click)="this.activeTab=4" *ngIf="this.globalService.enableGoogleTags">
          <span class="p-menuitem-icon fas fa-filter" style="margin-right: 0.5rem;"></span>
          <span class="p-menuitem-text">Tags</span>
        </div>
        <div [class]="this.activeTab===2 ? 'tab tab-selected' : 'tab'" (click)="this.activeTab=2">
          <span class="p-menuitem-icon fas fa-chart-bar" style="margin-right: 0.5rem;"></span>
          <span class="p-menuitem-text">{{i18nService.localize().AdvertiserDetailsComponent.StatisticTab}}</span>
        </div>
        <div [class]="this.activeTab===3 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="this.activeTab=3">
          <span class="p-menuitem-icon fas fa-euro-sign" style="margin-right: 0.5rem;"></span>
          <span class="p-menuitem-text">{{i18nService.localize().AdvertiserDetailsComponent.BillsTab}}</span>
        </div>
      </div>
      <div class="tab-card" *ngIf="advertiser">

        <!-- Kampagnen Tab -->
        <div class="p-grid" *ngIf="this.activeTab === 1">

          <div class="p-col-12" style="margin-bottom: 2em;">
            <button pButton pRipple type="button" icon="fas fa-plus" (click)="gotoCreateNewWizard()"
                    class="p-button-rounded p-button-lg" label="Erstellen" style="margin-bottom: 1em">
            </button>

            <div class="p-grid">
              <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
                <p class="header">
                  {{i18nService.localize().AdvertiserDetailsComponent.ActiveDisplayHeader}}
                </p>
                <app-wizard-campaigns [wipCampaigns]="advertiser!.wipCampaigns" [showGridLine]="advertiser.allOtherDisplayCampaigns.length > 0" [advertiserId]="this.advertiserId!"></app-wizard-campaigns>
                <p *ngIf="advertiser.allOtherDisplayCampaigns.length==0">
                  {{i18nService.localize().AdvertiserDetailsComponent.NoCampaignsAvailable}}
                </p>
                <div [class]="(i+1) !== advertiser.allOtherDisplayCampaigns.length ? 'p-grid line' : 'p-grid'"
                     *ngFor="let campaign of advertiser.allOtherDisplayCampaigns; let i = index">
                  <div class="p-col-6 clickable" (click)="selectCampaignForDetails(campaign)">
                    <p pTooltip="Status: {{campaign.status}}" tooltipPosition="right" style="width: max-content;">
                      {{campaign.name}}
                    </p>
                  </div>
                  <div class="p-col-6 button-row">
                    <button pButton pRipple type="button" icon="fas fa-check"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.PayedTooltip}}"
                            tooltipPosition="left" style="background-color: #56BC6C; border-color: #56BC6C">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-info" (click)="selectCampaignForDetails(campaign)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.InfoCampaignTooltip}}"
                            tooltipPosition="left" style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-pause" (click)="pauseCampaign(campaign)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.PauseCampaignTooltip}}"
                            tooltipPosition="left" *ngIf="campaign.status==='ACTIVE'||campaign.status==='WAITING'"
                            style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-play" (click)="startCampaign(campaign)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.StartCampaignTooltip}}"
                            tooltipPosition="left" *ngIf="campaign.status==='PAUSED'" style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-pencil-alt" (click)="openEditCampaign(campaign)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.EditCampaignTooltip}}"
                            tooltipPosition="left"
                            *ngIf="campaign.status==='ACTIVE'||campaign.status==='WAITING'||campaign.status==='PAUSED'"
                            style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(campaign)"
                            class="p-button-rounded" pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.DeleteCampaignTooltip}}" tooltipPosition="left"
                            *ngIf="campaign.status!=='ACTIVE' && campaign.status!=='WAITING' && campaign.status!=='REVIEWED'"
                            style="margin-left: 0.5em;">
                    </button>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
                <p class="header">
                  {{i18nService.localize().AdvertiserDetailsComponent.ActiveClickHeader}}
                </p>
                <p *ngIf="advertiser.allOtherClickCampaigns.length==0">
                  {{i18nService.localize().AdvertiserDetailsComponent.NoCampaignsAvailable}}
                </p>
                <div [class]="(i+1) !== advertiser.allOtherClickCampaigns.length ? 'p-grid line' : 'p-grid'"
                     *ngFor="let affiliate of advertiser.allOtherClickCampaigns; let i = index">
                  <div class="p-col-8 clickable" (click)="selectAffiliateForDetails(affiliate)">
                    <p pTooltip="Status: {{affiliate.status}}" tooltipPosition="right" style="width: max-content;">
                      {{affiliate.name}}
                    </p>
                  </div>
                  <div class="p-col-4 button-row">
                    <button pButton pRipple type="button" icon="fas fa-info"
                            (click)="selectAffiliateForDetails(affiliate)" class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.InfoCampaignTooltip}}"
                            tooltipPosition="left">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-pause" (click)="pauseCampaign(affiliate)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.PauseCampaignTooltip}}"
                            *ngIf="(affiliate.status==='ACTIVE'||affiliate.status==='WAITING')&&affiliate.sub_type!='CAMPAIGN'"
                            style="margin-left: 0.5em;" tooltipPosition="left">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-play" (click)="startCampaign(affiliate)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.StartCampaignTooltip}}"
                            tooltipPosition="left" *ngIf="affiliate.status==='PAUSED'&&affiliate.sub_type!='CAMPAIGN'" style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(affiliate)"
                            class="p-button-rounded" pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}" tooltipPosition="left"
                            *ngIf="affiliate.status!=='ACTIVE' && affiliate.status!=='WAITING' && affiliate.status!=='REVIEWED'&&affiliate.sub_type!='CAMPAIGN'"
                            style="margin-left: 0.5em;">
                    </button>
                  </div>
                </div>
              </div>

              <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
                <p class="header">
                  {{i18nService.localize().AdvertiserDetailsComponent.InactiveDisplayHeader}}
                </p>
                <p *ngIf="advertiser.allInactiveDisplayCampaigns.length==0">
                  {{i18nService.localize().AdvertiserDetailsComponent.NoCampaignsAvailable}}
                </p>
                <div [class]="(i+1) !== advertiser.allInactiveDisplayCampaigns.length ? 'p-grid line' : 'p-grid'"
                  *ngFor="let campaign of advertiser.allInactiveDisplayCampaigns; let i = index">
                  <div class="p-col-8 clickable" (click)="selectCampaignForDetails(campaign)">
                    <p pTooltip="Status: {{campaign.status}}" tooltipPosition="right" style="width: max-content;">
                      {{campaign.name}}
                    </p>
                  </div>
                  <div class="p-col-4 button-row">
                    <button pButton pRipple type="button" icon="fas fa-info" (click)="selectCampaignForDetails(campaign)"
                            class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.InfoCampaignTooltip}}"
                            tooltipPosition="left">
                    </button>
                    <button pButton pRipple type="button" icon="far fa-calendar-plus" (click)="openExtendCampaign(campaign)"
                            class="p-button-rounded" pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.ExtendCampaignTooltip}}"
                            tooltipPosition="left" *ngIf="campaign.status === 'ENDED'"
                            style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(campaign)"
                            class="p-button-rounded" pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.DeleteCampaignTooltip}}" tooltipPosition="left"
                            *ngIf="campaign.status=='ENDED'" style="margin-left: 0.5em;">
                    </button>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
                <p class="header">
                  {{i18nService.localize().AdvertiserDetailsComponent.InactiveClickHeader}}
                </p>
                <p *ngIf="advertiser.allInactiveClickCampaigns.length==0">
                  {{i18nService.localize().AdvertiserDetailsComponent.NoCampaignsAvailable}}
                </p>
                <div [class]="(i+1) !== advertiser.allInactiveClickCampaigns.length ? 'p-grid line' : 'p-grid'"
                  *ngFor="let affiliate of advertiser.allInactiveClickCampaigns; let i = index">
                  <div class="p-col-8 clickable" (click)="selectAffiliateForDetails(affiliate)">
                    <p pTooltip="Status: {{affiliate.status}}" tooltipPosition="right" style="width: max-content;">
                      {{affiliate.name}}
                    </p>
                  </div>
                  <div class="p-col-4 button-row">
                    <button pButton pRipple type="button" icon="fas fa-info"
                            (click)="selectAffiliateForDetails(affiliate)" class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.InfoCampaignTooltip}}"
                            tooltipPosition="left">
                    </button>
                    <button pButton pRipple type="button" icon="far fa-calendar-plus"
                            (click)="openExtendAffiliate(affiliate)" class="p-button-rounded"
                            pTooltip="{{i18nService.localize().AdvertiserDetailsComponent.ExtendCampaignTooltip}}"
                            tooltipPosition="left" *ngIf="affiliate.status === 'ENDED'&&affiliate.sub_type === 'INTERNAL'"
                            style="margin-left: 0.5em;">
                    </button>
                    <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(affiliate)"
                            class="p-button-rounded" pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}" tooltipPosition="left"
                            *ngIf="affiliate.status=='ENDED'&&affiliate.sub_type!='CAMPAIGN'"
                            style="margin-left: 0.5em;">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Statistik Tab -->
        <div class="p-grid" *ngIf="this.activeTab === 2">

<!--          <div class="p-col-12">-->
<!--            <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.CampaignsHeader}}</h2>-->
<!--            <div style="padding-top: 0.75em;" *ngIf="this.advertiser.activeCampaigns.length>0">-->

<!--          <p-carousel [value]="this.advertiser.activeCampaigns" [numVisible]="5" [numScroll]="1"-->
<!--                      [circular]="false" [showIndicators]="false" [responsiveOptions]="responsiveOptions">-->
<!--            <ng-template let-campaign pTemplate="item">-->
<!--              <div class="item" (click)="showCampaign(campaign)" style="cursor: pointer;">-->
<!--                <div class="p-mb-3">-->
<!--                  <img [src]="campaign.logo_url" [alt]="campaign.name" class="item-pic">-->
<!--                </div>-->
<!--                <div>-->
<!--                  <p class="campaign-header" pTooltip="Status: {{campaign.status}}">{{campaign.name.toUpperCase()}}</p>-->
<!--                  <ul>-->
<!--                    <li *ngIf="campaign.streamer>0">{{campaign.streamer}} {{i18nService.localize().AdvertiserDetailsComponent.Streamer}}</li>-->
<!--                    <li *ngIf="campaign.type==='CAMPAIGN'">{{campaign.clicks_views}} {{i18nService.localize().AdvertiserDetailsComponent.Views}}</li>-->
<!--                    <li *ngIf="campaign.type==='AFFILIATE'">{{campaign.clicks_views}} {{i18nService.localize().AdvertiserDetailsComponent.Clicks}}</li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </p-carousel>-->
<!--            </div>-->
<!--          </div>-->

          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="viewChart">
            <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.ViewChartHeader}}</h2>
            <div style="margin: 1.5em;">
              <p-chart type="line" *ngIf="viewChart" [data]="viewChart" [options]="chartOptions"></p-chart>
            </div>
          </div>

          <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="clickChart">
            <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.ClickChartHeader}}</h2>
            <div style="margin: 1.5em;">
              <p-chart type="line" *ngIf="clickChart" [data]="clickChart" [options]="chartOptions"></p-chart>
            </div>
          </div>

          <div class="p-col-12">
            <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.StreamedGamesHeader}}</h2>
              <div style="margin: 1em;">
                <p-dataView [value]="this.gameStreamStatistics" [paginator]="true" [rows]="3">
                  <ng-template let-gameStream pTemplate="listItem">
                    <div class="p-col-12 list-object">
                      <div class="p-grid data-grid">
                        <div class="p-col-12 p-md-2 p-lg-1 p-xl-1">
                          <img [src]="gameStream.box_art" [alt]="gameStream.name" [title]="gameStream.name"/>
                        </div>
                        <div class="p-col-12 p-md-7 p-lg-9 p-xl-9">
                          <p style="color: #1d60a4; font-weight: bold; font-size: 14pt;">{{gameStream.name}}</p>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-2 p-xl-2" style="text-align: right;">
                          <p style="font-size: 14pt;">{{gameStream.number_of_streams}} {{i18nService.localize().AdvertiserDetailsComponent.Streams}}</p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-dataView>
              </div>
          </div>
        </div>

        <!-- Abrechnungen Tab -->
        <div class="p-grid" *ngIf="this.activeTab === 3">

          <div class="p-col-12">
            <h2 class="card-headline">{{i18nService.localize().AdvertiserBillsComponent.CampaignBillsHeader}}</h2>
            <div *ngIf="bills.length > 0">
              <div class="table-scrollable">
                <p-table [value]="bills" [rows]="20" [lazy]="true" (onLazyLoad)="updateBills($event)"
                         [paginator]="true" [totalRecords]="totalBills" [loading]="loadingBills" sortMode="single"
                         sortField="bill_date" [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()"
                         responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="bill_nr" style="width:200px" class="tableTopLeft">
                        {{i18nService.localize().AdvertiserBillsComponent.TableBillNr}}
                        <p-sortIcon field="bill_nr"></p-sortIcon>
                      </th>
                      <th pSortableColumn="bill_date" style="width:200px">
                        {{i18nService.localize().AdvertiserBillsComponent.TableBillDate}}
                        <p-sortIcon field="bill_date"></p-sortIcon>
                      </th>
                      <th style="width:400px">
                        {{i18nService.localize().AdvertiserBillsComponent.TableCampaign}}
                      </th>
                      <th pSortableColumn="sum" style="width:150px">
                        {{i18nService.localize().AdvertiserBillsComponent.TableAmount}}
                        <p-sortIcon field="earnings"></p-sortIcon>
                      </th>
                      <th style="width:100px" class="tableTopRight">
                        {{i18nService.localize().AdvertiserBillsComponent.TableDownload}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-campaignBill>
                    <tr>
                      <td class="word-break">{{campaignBill.billNr}}</td>
                      <td>{{campaignBill.billDate}}</td>
                      <td>{{campaignBill.campaignName}}</td>
                      <td>{{campaignBill.sum}} €</td>
                      <td>
                        <button pButton pRipple type="button" class="p-button-rounded"
                                (click)="downloadPDF(campaignBill.id)" icon="fas fa-download"></button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
            <div *ngIf="!(bills.length>0)">
              <p>{{i18nService.localize().AdvertiserBillsComponent.NoCampaignBills}}</p>
            </div>
          </div>

        </div>

        <!-- Tags Tab -->
        <div class="p-grid" *ngIf="this.activeTab === 4">

          <div class="p-col-12">
            <h2 class="card-headline" style="margin-top: 0;">
              {{i18nService.localize().AdvertiserTagsComponent.TagsHeadline}}
            </h2>
          </div>
          <div class="p-col-12">
            <p style="margin-top: 0">
              {{i18nService.localize().AdvertiserTagsComponent.TagsIntro1}}<br>{{i18nService.localize().AdvertiserTagsComponent.TagsIntro2}}
            </p>

            <ol>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointOne1}}<a href="https://tagmanager.google.com">Google Tag Manager</a>{{i18nService.localize().AdvertiserTagsComponent.PointOne2}}
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointTwo}}
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointThree1}}<b>Tags</b>{{i18nService.localize().AdvertiserTagsComponent.PointThreeOn}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointThreeNew}}</b>.
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointFour1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFourConfig}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFour2}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFourMore}}</b>.
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointFive1}}
                <ul>
                  <li class="list-item">
                    {{i18nService.localize().AdvertiserTagsComponent.PointFive11}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFiveGoToWorkspace}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFive12}}
                  </li>
                  <li class="list-item">
                    {{i18nService.localize().AdvertiserTagsComponent.PointFive21}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointFiveSave}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointFive22}}
                  </li>
                </ul>
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointSix1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointSixIds}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointSix2}}
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointSeven}}
              </li>
              <li class="list-item">
                {{i18nService.localize().AdvertiserTagsComponent.PointEight1}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointEightSave}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointEight2}}<b>{{i18nService.localize().AdvertiserTagsComponent.PointEightSend}}</b>{{i18nService.localize().AdvertiserTagsComponent.PointEight3}}
              </li>
            </ol>
          </div>

          <div class="p-col-12 abstand">
            <p-table [value]="tags" [rows]="10" [lazy]="true" (onLazyLoad)="updateTags($event)" [paginator]="true"
                     [totalRecords]="totalRecordsTags" [loading]="loadingTags" sortMode="single" sortField="start_date"
                     [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()" *ngIf="(tags.length>0)"
                     responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-text-center tableTopLeft" pSortableColumn="name" style="width:450px">
                    {{i18nService.localize().AdvertiserTagsComponent.CampaignName}}
                    <p-sortIcon field="name"></p-sortIcon>
                  </th>
                  <th class="p-text-center" pSortableColumn="status" style="width:100px">
                    Status
                    <p-sortIcon field="status"></p-sortIcon>
                  </th>
                  <th class="p-text-center" pSortableColumn="tag_id" >
                    Tag Id
                    <p-sortIcon field="tag_id"></p-sortIcon>
                  </th>
                  <th class="p-text-center" >
                    Advertiser Id
                  </th>
                  <th class="p-text-center tableTopRight" >
                    {{i18nService.localize().AdvertiserTagsComponent.TotalConversions}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-tag>
                <tr>
                  <td>{{tag.affiliateName}}</td>
                  <td>{{tag.status}}</td>
                  <td>
                    <div class="p-grid">
                      <div class="p-col">
                        <p>
                          {{tag.tagId}}
                        </p>
                      </div>
                      <div class="p-col">
                        <button pButton pRipple type="button" label="Id kopieren" style="margin-top: 0.5em;"
                                icon="pi pi-clone" class="p-button-rounded" (click)="copyLink(tag.tagId)">
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="p-grid">
                      <div class="p-col">
                        <p>
                          {{tag.advertiserId}}
                        </p>
                      </div>
                      <div class="p-col">
                        <button pButton pRipple type="button" label="Id kopieren" style="margin-top: 0.5em;"
                                icon="pi pi-clone" class="p-button-rounded" (click)="copyLink(tag.advertiserId)">
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>{{tag.totalConversions}}</td>
                </tr>
              </ng-template>
            </p-table>
            <div *ngIf="!(tags.length>0)">
              <p>{{i18nService.localize().AdvertiserTagsComponent.NoTags}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <p-dialog [(visible)]="showEditCampaign" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
              [draggable]="false" [modal]="true" [closeOnEscape]="true" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{i18nService.localize().AdvertiserDetailsComponent.EditCampaignHeader}}</div>
      </ng-template>
      <div class="p-fluid">
        <div class="p-field">
          <label for="name"
                 style="font-weight: bold;">{{i18nService.localize().AdvertiserDetailsComponent.Name}}:</label>
          <input id="name" type="text" pInputText [(ngModel)]="nameEdit">
        </div>
        <div class="p-field">
          <label for="description"
                 style="font-weight: bold;">{{i18nService.localize().AdvertiserDetailsComponent.Description}}:</label>
          <textarea id="description" type="text" rows="4" pInputTextarea [(ngModel)]="descriptionEdit"></textarea>
        </div>
      </div>
      <div style="text-align: right">
        <button pButton pRipple type="button" icon="pi pi-check" (click)="changeCampaign()" class="p-button-rounded">
        </button>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="showEditCampaign=false"
                class="p-button-rounded" style="margin-left: 0.5em">
        </button>
      </div>
    </p-dialog>

    <p-dialog [(visible)]="showRemoveCampaign" [style]="{width: '50vw'}" closeIcon="fas fa-times">
      <ng-template pTemplate="header">
        <div class="headline">{{i18nService.localize().AdvertiserDetailsComponent.DeleteDialogHeader}}</div>
      </ng-template>
      <p>{{i18nService.localize().AdvertiserDetailsComponent.DeleteDialogText}}</p>
      <div style="text-align: right">
        <button pButton pRipple type="button" icon="fas fa-check"
                class="p-button-rounded" (click)="this.selectedDeleteCampaign!.type == 'AFFILIATE' ? deleteAffiliate() : deleteCampaign()"></button>
        <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                class="p-button-rounded p-button-secondary" (click)="this.showRemoveCampaign=false"></button>
      </div>
    </p-dialog>

    <div class="p-col-12" *ngIf="selectedCampaignId&&!selectedAffiliateId&&!selectedAffiliateIdForExtension">
      <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.DisplayCampaign}}</h2>
      <advertiser-campaign-details [campaignId]="selectedCampaignId"
                                   [numberOfStreamer]="selectedCampaignNumberOfStreamer" (closeEmit)="closeCampaignDetails()">
      </advertiser-campaign-details>
    </div>

    <div class="p-col-12" *ngIf="selectedAffiliateId&&!selectedCampaignId&&!selectedAffiliateIdForExtension">
      <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.ClickCampaign}}</h2>
      <advertiser-affiliate-details [affiliateId]="selectedAffiliateId"
                                    [numberOfStreamer]="selectedAffiliateNumberOfStreamer" (closeEmit)="closeAffiliateDetails()">
      </advertiser-affiliate-details>
    </div>

    <div class="p-col-12" *ngIf="!selectedAffiliateId&&!selectedCampaignId&&selectedAffiliateIdForExtension">
      <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.ExtendClickCampaign}}</h2>
      <advertiser-affiliate-extend [affiliateId]="selectedAffiliateIdForExtension" [advertiserId]="advertiserId"
                                   [affiliateName]="selectedAffiliateNameForExtension"
                                   (closeEmit)="closeExtendAffiliate()">
      </advertiser-affiliate-extend>
    </div>

    <div class="p-col-12" *ngIf="!selectedCampaignId&&!selectedAffiliateId&&selectedCampaignIdForExtension">
      <h2 class="card-headline">{{i18nService.localize().AdvertiserDetailsComponent.ExtendDisplayCampaign}}</h2>
      <advertiser-campaign-extend [campaignId]="selectedCampaignIdForExtension" [advertiserId]="advertiserId"
                                  [campaignName]="selectedCampaignNameForExtension"
                                  (closeEmit)="closeExtendCampaign()">
      </advertiser-campaign-extend>
    </div>

  </div>
</div>
