import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'streamer-status-dialog',
  templateUrl: 'streamer-status-dialog.component.html',
  styleUrls: ['streamer-status-dialog.component.css']
})
export class StreamerStatusDialogComponent {

  constructor(public i18nService: I18nService) {
  }

  @Input()
  public show: boolean = false;

  @Output()
  public showChange = new EventEmitter<boolean>();

  public updateShow() {
    this.showChange.emit(this.show);
  }

}
