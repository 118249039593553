import { Injectable } from '@angular/core';

@Injectable()
export class MathHelper {

  constructor() {
  }

  public precisionRound(value: number, precision: number) {
    if (precision < 0) {
      const factor = Math.pow(10, precision);
      return Math.round(value * factor) / factor;
    } else {
      return +(Math.round(Number(value + 'e+' + precision)) + 'e-' + precision);
    }
  }

  public numberWithCommas(x: any) {
    return x.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

}
