import {Component} from '@angular/core';
import {MegaMenuItem} from 'primeng/api';
import {GlobalService} from '../services/global.service';
import {RoutingService} from '../services/routing.service';
import {I18nService} from '../services/i18n.service';
import {Subscription} from 'rxjs';
import {StreamerService} from '../services/streamer.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
  public menuIsShown: boolean = false;

  public items: MegaMenuItem[] = [];
  public itemsMini: MegaMenuItem[] = [];

  public langChangeSubscription!: Subscription;

  public deleteStreamerModal: boolean = false;

  constructor(
    public globalService: GlobalService,
    public routingService: RoutingService,
    public i18nService: I18nService,
    public streamerService: StreamerService) {

    // Wenn ein Streamer, ein Advertiser oder eine Agentur angemeldet ist, zeige das Menü an
    if (globalService.streamer || globalService.advertiser || globalService.agency) {
      this.menuIsShown = true;
      if (globalService.getCurrentUserType() === 'streamer') {
        this.fillMenuStreamer()
      } else if (globalService.getCurrentUserType() === 'advertiser') {
        this.fillMenuAdvertiser()
      } else if (globalService.getCurrentUserType() === 'agency') {
        this.fillMenuAgency();
      }

      // Falls das Menü sichtbar ist, soll die Sprache angepasst werden, wenn sie sich ändert
      this.langChangeSubscription =
        this.i18nService.langChangeEmitter.subscribe((newLang) => {
          this.items = [];
          this.itemsMini = [];

          if (globalService.getCurrentUserType() === 'streamer') {
            this.fillMenuStreamer()
          } else if (globalService.getCurrentUserType() === 'advertiser') {
            this.fillMenuAdvertiser()
          } else if (globalService.getCurrentUserType() === 'agency') {
            this.fillMenuAgency();
          }
        });

    // Wenn man ein Advertiser ist, der sich registrieren möchte
    } else if (this.routingService.getRoute().includes('/advertiser/register')) {
      this.menuIsShown = true;

    // Wenn man eine Agentur oder ein Advertiser ist, die sich registrieren möchte
    } else if (this.routingService.getRoute().includes('/register')) {
      this.menuIsShown = true;

    // Wenn nicht, dann nicht
    } else {
      this.menuIsShown = false;
    }
  }

  fillMenuAdvertiser() {
    this.globalService.updateEnableGoogleTags().then(after => {

      if (this.globalService.enableGoogleTags) {
        this.items = [
          {
            label:
            this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu,
            icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/advertiser/dashboard');
            },
          },
          {
            label:
            this.i18nService.localize().MenuComponent.AdvertiserCampaignsMenu,
            icon: 'displaykampagne',
            command: () => {
              this.routingService.switchToPath('/advertiser/campaigns');
            },
          },
          {
            label: this.i18nService.localize().MenuComponent.AdvertiserTagsMenu,
            icon: 'tags',
            command: () => {
              this.routingService.switchToPath('/advertiser/tags');
            },
          },
          {
            label: this.i18nService.localize().MenuComponent.AdvertiserBillsMenu,
            icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/advertiser/bills');
            },
          },
          {
            label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu,
            icon: 'profil',
            command: () => {
              this.routingService.switchToPath('/advertiser/profile');
            },
          },
        ];

        this.itemsMini = [
          {
            icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/advertiser/dashboard');
            },
          },
          {
            icon: 'displaykampagne',
            command: () => {
              this.routingService.switchToPath('/advertiser/campaigns');
            },
          },
          {
            icon: 'tags',
            command: () => {
              this.routingService.switchToPath('/advertiser/tags');
            },
          },
          {
            icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/advertiser/bills');
            },
          },
          {
            icon: 'profil',
            command: () => {
              this.routingService.switchToPath('/advertiser/profile');
            },
          },
        ];
      } else {
        this.items = [
          {
            label:
            this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu,
            icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/advertiser/dashboard');
            },
          },
          {
            label:
            this.i18nService.localize().MenuComponent.AdvertiserCampaignsMenu,
            icon: 'displaykampagne',
            command: () => {
              this.routingService.switchToPath('/advertiser/campaigns');
            },
          },
          {
            label: this.i18nService.localize().MenuComponent.AdvertiserBillsMenu,
            icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/advertiser/bills');
            },
          },
          {
            label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu,
            icon: 'profil',
            command: () => {
              this.routingService.switchToPath('/advertiser/profile');
            },
          },
        ];

        this.itemsMini = [
          {
            icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/advertiser/dashboard');
            },
          },
          {
            icon: 'displaykampagne',
            command: () => {
              this.routingService.switchToPath('/advertiser/campaigns');
            },
          },
          {
            icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/advertiser/bills');
            },
          },
          {
            icon: 'profil',
            command: () => {
              this.routingService.switchToPath('/advertiser/profile');
            },
          },
        ];
      }
    });

  }

  fillMenuStreamer() {
    if (this.globalService.streamer && !this.globalService.streamer.inRegister) {

      this.globalService.updateCompetitionAvailable().then(after => {
        if (this.globalService.activeCompetition) {

          this.items = [
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerDashboardMenu,
              icon: 'dashboard',
              command: () => {
                this.routingService.switchToPath('/streamer/home');
              },
            },
            {
              label: this.i18nService.localize().MenuComponent.StreamerIncomeMenu,
              icon: 'einnahmen',
              command: () => {
                this.routingService.switchToPath('/streamer/income');
              },
            },
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerCampaignsMenu,
              icon: 'display-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/campaigns');
              },
            },
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerAffiliateMenu,
              icon: 'klick-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/affiliates');
              },
            },
            {
              label: this.i18nService.localize().MenuComponent.StreamerContestMenu,
              icon: 'gewinnspiel',
              command: () => {
                this.routingService.switchToPath('/streamer/contest');
              },
            },
            {
              label: this.i18nService.localize().MenuComponent.StreamerNewsMenu,
              icon: 'news',
              command: () => {
                this.routingService.switchToPath('/streamer/benefits');
              },
            },
          ];

          this.itemsMini = [
            {
              icon: 'dashboard',
              command: () => {
                this.routingService.switchToPath('/streamer/home');
              },
            },
            {
              icon: 'einnahmen',
              command: () => {
                this.routingService.switchToPath('/streamer/income');
              },
            },
            {
              icon: 'display-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/campaigns');
              },
            },
            {
              icon: 'klick-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/affiliates');
              },
            },
            {
              icon: 'gewinnspiel',
              command: () => {
                this.routingService.switchToPath('/streamer/contest');
              },
            },
            {
              icon: 'news',
              command: () => {
                this.routingService.switchToPath('/streamer/benefits');
              },
            },
          ];

        } else {

          this.items = [
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerDashboardMenu,
              icon: 'dashboard',
              command: () => {
                this.routingService.switchToPath('/streamer/home');
              },
            },
            {
              label: this.i18nService.localize().MenuComponent.StreamerIncomeMenu,
              icon: 'einnahmen',
              command: () => {
                this.routingService.switchToPath('/streamer/income');
              },
            },
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerCampaignsMenu,
              icon: 'display-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/campaigns');
              },
            },
            {
              label:
              this.i18nService.localize().MenuComponent.StreamerAffiliateMenu,
              icon: 'klick-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/affiliates');
              },
            },
            {
              label: this.i18nService.localize().MenuComponent.StreamerNewsMenu,
              icon: 'news',
              command: () => {
                this.routingService.switchToPath('/streamer/benefits');
              },
            },
          ];

          this.itemsMini = [
            {
              icon: 'dashboard',
              command: () => {
                this.routingService.switchToPath('/streamer/home');
              },
            },
            {
              icon: 'einnahmen',
              command: () => {
                this.routingService.switchToPath('/streamer/income');
              },
            },
            {
              icon: 'display-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/campaigns');
              },
            },
            {
              icon: 'klick-streamer',
              command: () => {
                this.routingService.switchToPath('/streamer/affiliates');
              },
            },
            {
              icon: 'news',
              command: () => {
                this.routingService.switchToPath('/streamer/benefits');
              },
            },
          ];

        }

      });
    }
  }

  fillMenuAgency() {
    this.items = [
      {
        label:
        this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu,
        icon: 'dashboard',
        command: () => {
          this.routingService.switchToPath('/agency/dashboard');
        }
      },
      {
        label:
        this.i18nService.localize().MenuComponent.AgencyAdvertisersMenu,
        icon: 'displaykampagne',
        command: () => {
          this.routingService.switchToPath('/agency/advertisers');
        },
      },
      {
        label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu,
        icon: 'profil',
        command: () => {
          this.routingService.switchToPath('/agency/profile');
        },
      }
    ];

    this.itemsMini = [
      {
        icon: 'dashboard',
        command: () => {
          this.routingService.switchToPath('/agency/dashboard');
        },
      },
      {
        icon: 'displaykampagne',
        command: () => {
          this.routingService.switchToPath('/agency/advertisers');
        },
      },
      {
        icon: 'profil',
        command: () => {
          this.routingService.switchToPath('/agency/profile');
        },
      }
    ];

    if(this.globalService.agency!.roles.includes("ADMIN")){
      this.items.push(
        {
          label: this.i18nService.localize().MenuComponent.AgencyUsersMenu,
          icon: 'nutzer',
          command: () => {
            this.routingService.switchToPath('/agency/users');
          }
        }
      )

      this.itemsMini.push(
        {
          icon: 'nutzer',
          command: () => {
            this.routingService.switchToPath('/agency/users');
          },
        }
      )
    }

    if(this.globalService.agency!.roles.includes("PICTURES")){
      this.items.push(
        {
          label: this.i18nService.localize().MenuComponent.AgencyPictureMenu,
          icon: 'bilder',
          command: () => {
            this.routingService.switchToPath('/agency/pictures');
          }
        }
      )

      this.itemsMini.push(
        {
          icon: 'bilder',
          command: () => {
            this.routingService.switchToPath('/agency/pictures');
          },
        }
      )
    }

  }

  changeLang(newLang: string) {
    this.i18nService.changeLang(newLang);
  }

  isLang(lang: string): boolean {
    return lang === this.i18nService.getCurrentLang();
  }

}
