import {MultilangModel} from '../multilang.model';

export class Keyword extends MultilangModel{

  constructor(
    public id: string,
    langMap: Map<string, object>
  ){
    super(langMap);
  }

}
