export class CampaignLiveStream {

  public streamId!: string;

  public streamerName!: string;

  public network!: string;

  public games!: string[];

  public profileLink!: string;

  public darkStyledEntry: boolean = true;

  constructor() {}

}
