<div *ngIf="this.globalService.agency">
  <div class="headline" *ngIf="!selectedAdvertiserId">
    {{i18nService.localize().AgencyAdvertisersComponent.AgencyAdvertisersHeader}}
  </div>
  <div class="p-col-12">
    <div class="p-grid abstand">
      <div class="p-col-12" *ngIf="!selectedAdvertiserId">
        <button pButton pRipple class="p-button-rounded" type="button" icon="fas fa-plus"
                label="{{this.i18nService.localize().AgencyAdvertisersComponent.NewAgencyAdvertiser}}"
                (click)="this.routingService.switchToPath('/agency/advertiser/add')"
                style="margin-bottom: 0.5em; margin-top: 4.0em;">
        </button>
      </div>

      <div class="p-col-12" style="padding-top: 0" *ngIf="!selectedAdvertiserId">
        <p-table [value]="globalService.agency.advertisers" responsiveLayout="scroll" dataKey="companyName">
          <ng-template pTemplate="header">
            <tr>
              <th class="tableTopLeft" style="width: 3rem"></th>
              <th pSortableColumn="companyName">{{i18nService.localize().AgencyAdvertisersComponent.CompanyName}} <p-sortIcon field="companyName"></p-sortIcon></th>
              <th>{{i18nService.localize().AgencyAdvertisersComponent.ActiveCampaign}}</th>
              <th>{{i18nService.localize().AgencyAdvertisersComponent.ActiveClickCampaigns}}</th>
              <th>{{i18nService.localize().AgencyAdvertisersComponent.TotalBudget}}</th>
              <th>{{i18nService.localize().AgencyAdvertisersComponent.Status}}</th>
              <th class="tableTopRight">{{i18nService.localize().AgencyAdvertisersComponent.Actions}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-advertiser let-expanded="expanded">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="advertiser"
                        class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                </button>
              </td>
              <td>
                <img alt="logo" [src]="'/picture/' + advertiser.picId" width="32" style="vertical-align: middle;margin-right: 0.5em;" *ngIf="advertiser.picId" />
                {{advertiser.companyName}}
              </td>
              <td>{{advertiser.numberOfCampaigns}}</td>
              <td>{{advertiser.numberOfAffiliates}}</td>
              <td>{{advertiser.budget}}€</td>
              <td>
                <span *ngIf="advertiser.active" class="onlineDot"></span>
                <span *ngIf="!advertiser.active" class="offlineDot"></span>
              </td>
              <td>
                <button pButton pRipple type="button" icon="fas fa-ad" style="margin-right: 0.5em"
                        label="{{i18nService.localize().AgencyAdvertisersComponent.Campaigns}}"
                        class="p-button-rounded" pTooltip="{{i18nService.localize().AgencyAdvertisersComponent.InfoTooltip}}"
                        tooltipPosition="left" (click)="this.selectAdvertiserForDetails(advertiser)">
                </button>
                <button pButton pRipple type="button" icon="fas fa-pencil-alt" style="margin-right: 0.5em"
                        class="p-button-rounded" (click)="this.selectAdvertiserForEdit(advertiser)"
                        pTooltip="{{i18nService.localize().AgencyAdvertisersComponent.EditTooltip}}"
                        tooltipPosition="left">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-advertiser>
            <tr>
              <td colspan="7">
                <div class="p-3">
                  <div class="p-grid">
                    <div class="p-col p-offset-1" style="text-align: left;">
                      <p class="font-weight-bold">{{i18nService.localize().AgencyAdvertisersComponent.Website}}:</p>
                      <p style="margin-top: 0">{{advertiser.website}}</p>
                    </div>
                    <div class="p-col" style="text-align: left;">
                      <p class="font-weight-bold">{{i18nService.localize().AgencyAdvertisersComponent.StreetNr}}:</p>
                      <p style="margin-top: 0">{{advertiser.street}} {{advertiser.nr}}</p>
                    </div>
                    <div class="p-col" style="text-align: left;">
                      <p class="font-weight-bold">{{i18nService.localize().AgencyAdvertisersComponent.City}}:</p>
                      <p style="margin-top: 0">{{advertiser.postalCode}} {{advertiser.city}}</p>
                    </div>
                    <div class="p-col" style="text-align: left;">
                      <p class="font-weight-bold">{{i18nService.localize().AgencyAdvertisersComponent.Country}}:</p>
                      <p style="margin-top: 0">{{advertiser.country}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <p-dialog [(visible)]="showEditAdvertiser" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="true" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AgencyAdvertisersComponent.EditAdvertiserHeader}}</div>
        </ng-template>

        <div class="p-fluid p-formgrid p-grid" *ngIf="selectedAdvertiserForEdit">
          <div class="p-field p-col-12 p-md-6">
            <label for="companyname" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.CompanyName}}:</label>
            <input id="companyname" type="text" pInputText [(ngModel)]="selectedAdvertiserForEdit.companyName" class="field">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="website" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.Website}}:</label>
            <input id="website" type="text" pInputText [(ngModel)]="selectedAdvertiserForEdit.website" class="field">
          </div>

          <div class="p-field p-col-12">
            <label for="logo" style="font-weight: bold;">Logo:</label>
            <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png"
                          [maxFileSize]=1500000 id="logo" [showUploadButton]="false"
                          [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                          chooseLabel="{{i18nService.localize().AdvertiserProfileComponent.ChooseButton}}"
                          cancelLabel="{{i18nService.localize().AdvertiserProfileComponent.CancelButton}}">
              <ng-template pTemplate="content">
                <ul *ngIf="uploadedPictures.length">
                  <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes
                  </li>
                </ul>
              </ng-template>
            </p-fileUpload>
            <p class="warning" *ngIf="!picturesOk">{{i18nService.localize().AgencyAdvertisersComponent.PicError}}</p>
          </div>

          <div class="p-field p-col-12 p-md-8">
            <label for="street" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.Street}}:</label>
            <input id="street" type="text" pInputText [(ngModel)]="selectedAdvertiserForEdit.street" class="field">
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="nr" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.Nr}}:</label>
            <input id="nr" type="text" pInputText [(ngModel)]="selectedAdvertiserForEdit.nr" class="field">
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="postalcode" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.PostalCode}}:</label>
            <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="selectedAdvertiserForEdit.postalCode" class="field">
          </div>

          <div class="p-field p-col-12 p-md-8">
            <label for="city" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.City}}:</label>
            <input id="city" type="text" pInputText [(ngModel)]="selectedAdvertiserForEdit.city" class="field">
          </div>

          <div class="p-field p-col-12">
            <label for="country" style="font-weight: bold">{{i18nService.localize().AgencyAdvertisersComponent.Country}}:</label>
            <p-dropdown id="country" [options]="countriesDropDownList" [(ngModel)]="countryEdit"
                        optionLabel="item_text" [showClear]="true"
                        [style]="{'border-radius': '12px', 'width': '100%'}"
                        [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
            </p-dropdown>
          </div>
        </div>

        <div style="text-align: right">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="changeAdvertiser()"
                  class="p-button-rounded">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="closeAdvertiserEdit()"
                  class="p-button-rounded" style="margin-left: 0.5em">
          </button>
        </div>
      </p-dialog>

      <div class="p-col-12" *ngIf="selectedAdvertiserId">
        <advertiser-details [advertiserId]="selectedAdvertiserId" (closeEmit)="closeAdvertiserDetails()"></advertiser-details>
      </div>

    </div>
  </div>

</div>
