<div *ngIf="(this.globalService.advertiser||this.globalService.agency) && !sessionId">
  <p-card>
    <div class="p-grid" style="padding: 2em;">
      <div class="p-col-12">
        <p class="tab-card-headline" style="margin-bottom: 0">{{affiliateName}}</p>
      </div>
      <div class="p-col-12 p-md-6">
        <p style="margin-right: 1em;">
          {{i18nService.localize().AdvertiserAffiliateExtendComponent.ExtendText}}
        </p>

        <div class="p-fluid">
          <div class="p-field p-grid">
            <div class="p-field-radiobutton p-col-12 p-mb-3 p-md-3 p-mb-md-0">
              <p-radioButton name="budgetSelect" value="total" inputId="total"
                             [(ngModel)]="budgetSelect" (onClick)="updateBudgetInputsTotal()">
              </p-radioButton>
              <!--suppress XmlInvalidId -->
              <label for="total" style="margin-left: 0.5em; font-weight:bold">
                {{i18nService.localize().AdvertiserAffiliateExtendComponent.NewBudget}}:
              </label>
            </div>
            <div class="p-col-12 p-md-9">
              <p-inputNumber [(ngModel)]="totalBudget" mode="currency" currency="EUR" locale="de-DE"
                             (ngModelChange)="updateBudgets()" id="totalBudget"
                             [disabled]="disableTotalBudget">
              </p-inputNumber>
            </div>
          </div>
          <div class="p-field p-grid">
            <div class="p-field-radiobutton p-col-12 p-mb-3 p-md-3 p-mb-md-0">
              <p-radioButton name="budgetSelect" value="daily" inputId="daily"
                             [(ngModel)]="budgetSelect" (onClick)="updateBudgetInputsDaily()">
              </p-radioButton>
              <!--suppress XmlInvalidId -->
              <label for="daily" style="margin-left: 0.5em; font-weight:bold">
                {{i18nService.localize().AdvertiserCampaignExtendComponent.NewDailyBudget}}:
              </label>
            </div>
            <div class="p-col-12 p-md-9">
              <p-inputNumber [(ngModel)]="dailyBudget" mode="currency" currency="EUR" locale="de-DE"
                             (ngModelChange)="updateBudgets()" id="dailyBudget"
                             [disabled]="disableDailyBudget">
              </p-inputNumber>
            </div>
          </div>
        </div>

        <div class="p-grid" style="margin-top: 1em;">

          <div class="p-col-12 p-md-6">
            <label for="startDate" style="font-weight:bold">
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.NewStartDate}}:
            </label>
            <p-calendar [(ngModel)]="newStartDate" dateFormat="dd.mm.yy" [style]="{'width':'100%'}"
                        [disabled]="!(this.dailyBudget>0 || this.totalBudget>0)"
                        (onSelect)="updateDaysBetween()" (onInput)="updateDaysBetween()"
                        [minDate]="minDate" id="startDate">
            </p-calendar>
          </div>
          <div class="p-col-12 p-md-6">
            <label for="endDate" style="font-weight:bold">
              {{i18nService.localize().AdvertiserCampaignExtendComponent.ExpectedEndDate}}:
            </label>
            <p-calendar [(ngModel)]="newEndDate" dateFormat="dd.mm.yy" [style]="{'width':'100%'}"
                        [disabled]="!(this.dailyBudget>0 || this.totalBudget>0) && this.newStartDate === undefined"
                        (onSelect)="updateDaysBetween()" (onInput)="updateDaysBetween()"
                        [minDate]="newStartDate" id="endDate">
            </p-calendar>
          </div>

          <div class="p-col-12 p-md-3">
            <p style="font-weight:bold">
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.Clicks}}:
            </p>
            <p>
              {{this.initialClicks}}
            </p>
          </div>

          <div class="p-col-12 p-md-3">
            <p style="font-weight:bold">
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.NewDailyClicks}}:
            </p>
            <p>
              {{this.initialDailyClicks}}
            </p>
          </div>

          <div class="p-col-12 p-md-3">
            <p style="font-weight:bold">
              {{i18nService.localize().AdvertiserCampaignExtendComponent.DayDuration}}:
            </p>
            <p>
              {{this.daysBetween}} {{i18nService.localize().AdvertiserCampaignExtendComponent.Days}}
            </p>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-xl-6">
        <p style="font-weight:bold">
          {{i18nService.localize().AdvertiserAffiliateExtendComponent.PaymentOptions}}:
        </p>
        <p-accordion [(activeIndex)]="paymentIndex" [multiple]="false">
          <p-accordionTab header="{{i18nService.localize().AdvertiserAffiliateExtendComponent.DirectTransfer}}"
                          [selected]="true">
            <div *ngIf="!this.isInCalculation && totalBudgetWithTax > 0; else paymentInfo">
              <app-payment [productName]="affiliateName" [price]="totalBudgetWithTax"
                           [returnUrl]="router.url + '?startDate=' + newStartDate.getFullYear() + '-' + (newStartDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newStartDate.getDate().toString().padStart(2, '0') +
                           '&endDate=' + newEndDate.getFullYear() + '-' + (newEndDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newEndDate.getDate().toString().padStart(2, '0') +
                           '&totalBudget=' + totalBudget + '&action=affiliateExtend'"
                           [campaignId]="affiliateId" action="affiliateExtend"></app-payment>
            </div>
            <ng-template #paymentInfo>
              <div *ngIf="totalBudget == 0 || newStartDate == undefined || newEndDate == undefined; else spinner">
                <p>Bitte wählen Sie ein Budget und einen Zeitraum aus.</p>
              </div>
              <ng-template #spinner>
                <p-progressSpinner></p-progressSpinner>
              </ng-template>
            </ng-template>
            <p class="info" *ngIf="paypalPaymentDone">
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.PaymentDone}}
            </p>
            <p class="error" *ngIf="paymentError">
              {{i18nService.localize().AdvertiserCampaignExtendComponent.PaymentError}}
            </p>
            <p class="error" *ngIf="NoBudgetError">
              {{i18nService.localize().AdvertiserCampaignExtendComponent.BudgetError}}
            </p>
          </p-accordionTab>
          <p-accordionTab header="{{i18nService.localize().AdvertiserAffiliateExtendComponent.BankTransfer}}">
            <p>
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.BankTransferText1}}
              <b>{{this.totalBudgetWithTaxString}} €</b>
              {{i18nService.localize().AdvertiserAffiliateExtendComponent.BankTransferText2}}<br>
            </p>
            <p><b>Streamboost GmbH</b></p>
            <p><b>{{i18nService.localize().AdvertiserAffiliateExtendComponent.Institute}}:</b> Deutsche Bank<br></p>
            <p><b>IBAN:</b> DE64 1001 0010 0948 7951 03<br></p>
            <p><b>BIC:</b> PBNKDEFF<br></p>
            <p><b>{{i18nService.localize().AdvertiserAffiliateExtendComponent.Reference}}: </b> {{billNr}}<br></p>
          </p-accordionTab>
        </p-accordion>
      </div>

      <div class="p-col-12" style="text-align: right; margin-top: 1em;">
        <button pButton pRipple type="button" icon="pi pi-check" (click)="extendAffiliate()"
                class="p-button-rounded" *ngIf="checkAffiliateExtend()" [disabled]="deactivateButton()"
                pTooltip="{{i18nService.localize().AdvertiserAffiliateExtendComponent.ExtendButtonTooltip}}"
                tooltipPosition="left">
        </button>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="back()"
                class="p-button-rounded" style="margin-left: 0.5em"
                pTooltip="{{i18nService.localize().AdvertiserAffiliateExtendComponent.BackButtonTooltip}}"
                tooltipPosition="left">
        </button>
      </div>
    </div>

  </p-card>
</div>

<app-payment-confirmation *ngIf="showPaymentInfoPage" [success]="paymentSuccess"
                          [advertiserId]="advertiserId"></app-payment-confirmation>
