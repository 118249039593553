<p-sidebar [(visible)]="show" position="right" [modal]="false" (visibleChange)="updateShow()">
  <div>
    <h3>{{i18nService.localize().StreamerFeedbackPanelComponent.QuestionFeedbackHeader}}</h3>

    <p class="textWithNewlines">{{i18nService.localize().StreamerFeedbackPanelComponent.QuestionFeedbackInfoText}}</p>

    <div>
      <div class="p-fluid">

        <div class="p-field">
          <label for="roles" style="font-weight: bold">{{i18nService.localize().StreamerFeedbackPanelComponent.Concern}}:</label>
          <p-selectButton [options]="typeOptions" id="roles" [(ngModel)]="type" optionLabel="label"
                          optionValue="value" [style]="{'border-radius':'12px'}">
          </p-selectButton>
        </div>

        <div class="p-field">
          <label for="question" style="font-weight: bolder">{{i18nService.localize().StreamerFeedbackPanelComponent.QuestionOrFeedback}}:</label>
          <textarea [rows]="8" [cols]="30" maxlength="700" pInputTextarea id="question" [(ngModel)]="message">
          </textarea>
        </div>

      </div>
    </div>

    <p style="color: gray;font-size: small;">
      {{i18nService.localize().StreamerFeedbackPanelComponent.BeRespectfulHint}}
    </p>

    <p class="info" *ngIf="feedbackSend">
      {{i18nService.localize().StreamerFeedbackPanelComponent.FeedbackSent}}
    </p>

    <p class="info" *ngIf="questionSend">
      {{i18nService.localize().StreamerFeedbackPanelComponent.QuestionSent}}
    </p>

    <button pButton pRipple type="submit" icon="fas fa-check" class="p-button-rounded"
            pTooltip="{{i18nService.localize().StreamerFeedbackPanelComponent.ButtonTooltip}}"
            [disabled]="!validate()" (click)="sendFeedback()">
    </button>

    <div class="p-field">
      <label for="clips" style="font-weight: bold ; margin-top: 20px">{{i18nService.localize().StreamerFeedbackPanelComponent.ClipHeader}}:</label>
      <div id="clips">
        {{i18nService.localize().StreamerFeedbackPanelComponent.ClipText}}
      </div>
      <p-button label={{i18nService.localize().StreamerFeedbackPanelComponent.ClipButton}} [style]="{'border-radius':'12px', 'margin-top': '10px'}"
      (click)="sendClip()">
      </p-button >
    </div>

  </div>

  <p-divider *ngIf="questionList.length>0"></p-divider>

  <div *ngIf="questionList.length>0">
    <h3>{{i18nService.localize().StreamerFeedbackPanelComponent.AnswersHeader}}</h3>

    <div *ngFor="let question of questionList">
      <p-fieldset [legend]="question.message" [toggleable]="true" [collapsed]="true" >
        <div *ngIf="question.status==='ANSWERED'">
          {{question.answer}}
        </div>
        <div *ngIf="question.status==='IN_REVIEW'">
          {{i18nService.localize().StreamerFeedbackPanelComponent.QuestionInReviewHint}}
        </div>
        <div *ngIf="question.status==='ACCEPTED'">
          {{i18nService.localize().StreamerFeedbackPanelComponent.QuestionAcceptedHint}}
        </div>
        <div *ngIf="question.status==='DENIED'">
          {{i18nService.localize().StreamerFeedbackPanelComponent.QuestionDeniedHint}} -
          {{question.reason}}
        </div>
      </p-fieldset>
    </div>

  </div>

</p-sidebar>
