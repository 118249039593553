import { StreamBoostEventBus } from '../streamboost-eventbus.service';

export class SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {
  }

  public getEventState() {
    return this.streamBoostEventBus.isOnline();
  }

  // mind. eine Zahl, klein / Gross Buchstaben / mind. 8 zeichen maximal 20
  private pwdRegex = RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$');

  public checkNewPassword(newPassword: string, newPassword2: string): boolean {
    if (!newPassword || newPassword.length <= 0 || newPassword !== newPassword2) {
      return false;
    } else return this.pwdRegex.test(newPassword);
  }

}
