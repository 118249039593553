import { Injectable } from '@angular/core';
import { SuperService } from './super.service';
import { StreamBoostEventBus } from '../streamboost-eventbus.service';
import { News } from '../model/streamer/news.model';
import { I18nService } from './i18n.service';
import {DomSanitizer} from "@angular/platform-browser";
import {CookieService} from "ngx-cookie-service";

@Injectable()
export class NewsService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus, private cookieService: CookieService, public i18nService: I18nService, public sanitizer:DomSanitizer) {
    super(streamBoostEventBus);
  }

  public getCurrentNews(typeId: string): Promise<News[]> {
    let lang = this.i18nService.getCurrentLang();

    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.news.retrieve',
        {
          'message': {
            'lang': lang.toLowerCase(),
            'type': typeId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let newsFromDB = response.body.response.news;
          let news: News[] = [];
          for (let j = 0; j < newsFromDB.length; j++) {
            let newsArticle: News = new News(newsFromDB[j].text, this.sanitizer);

            newsArticle.id = newsFromDB[j]._id;
            newsArticle.headline = newsFromDB[j].headline;
            newsArticle.category = newsFromDB[j].category;
            newsArticle.creationDate = newsFromDB[j].creation_date;
            newsArticle.picUrl = newsFromDB[j].pic_url;
            newsArticle.pinned = newsFromDB[j].pinned;

            news.push(newsArticle);
          }

          // Sortiere die News-Artikel zuerst nach "pinned" und dann nach Datum
          news.sort((a, b) => {
            if (b.pinned === a.pinned) {
              // Wenn beide gleich gepinnt sind, nach Datum sortieren (neuestes zuerst)
              return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
            }
            // Ansonsten die gepinnten Artikel nach oben sortieren
            return b.pinned ? 1 : -1;
          });

          resolve(news);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public saveClick(newsId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.news.clicks',
          {
            message: {
              news_id: newsId,
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve(response.body);
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                resolve(response.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }



}
