<p-card *ngIf="globalService.streamer" [style]="bigFonts ? {'height':'100%'} : {}">
  <div style="padding-top: 0.75em;" *ngIf="globalService.streamer.participatingAffiliates.length>0">
    <!-- [autoplayInterval]="3000" -->
    <p-carousel [value]="this.globalService.streamer.participatingAffiliatesWithoutDefaultAffiliate" [numVisible]="1" [numScroll]="1"
                [circular]="true" [showIndicators]="false">
      <ng-template let-affiliate pTemplate="item">
        <div class="p-grid" [style]="affiliate.paused ? {'-webkit-filter': 'grayscale(100%)', 'filter': 'grayscale(100%)'} : {}">
          <div class="p-col-12 p-lg-4 p-xl-4">
            <img src="{{affiliate.logoURL}}" [alt]="affiliate.name" class="campaign-image" />
          </div>
          <div class="p-col-12 p-lg-8 p-xl-8">
            <div class="p-grid">
              <div class="p-col-12 p-xl-8">
                <p class="campaign-header">
                  {{affiliate.name}}
                  <a *ngIf="affiliate.paused" class="pauseLink" (click)="pauseDialog=true">{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.Paused}}</a>
                </p>
              </div>
              <div class="p-col-12 p-xl-4" style="text-align: right;">
                <button pButton pRipple type="button" class="p-button-rounded"
                        pTooltip="{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.DescriptionTooltip}}" *ngIf="affiliate.description"
                        icon="fas fa-info" tooltipPosition="left" (click)="showDescriptionDialog(affiliate.description)">
                </button>
                <button pButton pRipple type="button" class="p-button-rounded" style="margin-left: 0.5em;"
                        pTooltip="Fragen oder Feedback an den Kampagnen-Ersteller geben" *ngIf="affiliate.startDate"
                        icon="fas fa-comments" tooltipPosition="left" (click)="showFeedbackPanel(affiliate.id)">
                </button>
              </div>
            </div>

            <div *ngIf="!bigFonts">
              <p style="margin: 0" *ngIf="affiliate.startDate && !affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.StartDate}}: </b>{{affiliate.startDate}}
              </p>
              <p style="margin: 0" *ngIf="affiliate.endDate && !affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.EndDate}}: </b>{{affiliate.endDate}}
              </p>
              <p style="margin: 0" *ngIf="affiliate.startDate && affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.CampaignStartsIn}} {{affiliate.getDaysToStart()}} {{this.i18nService.localize().StreamerAffiliatesOverviewComponent.Days}}</b>
              </p>
              <p style="margin: 0" *ngIf="!affiliate.isStartDateInFuture()">
                <b>Klicks: </b>{{affiliate.totalClicks}}
              </p>
            </div>
            <div *ngIf="bigFonts">
              <p style="margin: 0; font-size: x-large;" *ngIf="affiliate.startDate && !affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.StartDate}}: </b>{{affiliate.startDate}}
              </p>
              <p style="margin: 0; font-size: x-large;" *ngIf="affiliate.endDate && !affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.EndDate}}: </b>{{affiliate.endDate}}
              </p>
              <p style="margin: 0; font-size: xx-large;" *ngIf="affiliate.startDate && affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.CampaignStartsIn}} {{affiliate.getDaysToStart()}} {{this.i18nService.localize().StreamerAffiliatesOverviewComponent.Days}}</b>
              </p>
              <p style="margin: 0; font-size: xx-large;" *ngIf="!affiliate.isStartDateInFuture()">
                <b>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.Clicks}}: </b>{{affiliate.totalClicks}}
              </p>
            </div>

            <div class="p-grid">
              <div class="p-col-12 p-xl-10">
                <p-progressBar *ngIf="affiliate.type === 'INTERNAL' && !affiliate.isStartDateInFuture()" [value]="affiliate.dailyProgress"
                               pTooltip="{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.ProgressTooltip}}"
                               tooltipPosition="left" [showValue]="false">
                </p-progressBar>
              </div>
              <div class="p-col-12 p-xl-2" *ngIf="affiliate.type === 'INTERNAL' && !affiliate.isStartDateInFuture()" style="margin: auto">
                <span style="text-align: center;font-size: x-large;font-weight: bold;">{{affiliate.dailyProgress}}%</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  <div *ngIf="globalService.streamer.participatingAffiliates.length==0">
    <p>
      {{this.i18nService.localize().StreamerAffiliatesOverviewComponent.InNoCampaignHint1}}
      {{this.i18nService.localize().StreamerAffiliatesOverviewComponent.InNoCampaignHint2}}
      <a (click)="this.routingService.switchToPath('/streamer/affiliates')" style="font-weight: bold; cursor: pointer">
        {{this.i18nService.localize().StreamerAffiliatesOverviewComponent.InNoCampaignHint3}}
      </a>
    </p>
  </div>
</p-card>

<!-- Infodialoge -->
<p-dialog [(visible)]="descriptionDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.Description}}</div>
  </ng-template>
  <p [innerHTML]="selectedDescription">
  </p>
</p-dialog>

<p-dialog [(visible)]="pauseDialog" [style]="{width: '50vw'}" closeIcon="fas fa-times">
  <ng-template pTemplate="header">
    <div class="headline">{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.PauseQuestion}}</div>
  </ng-template>
  <p class="textWithNewlines">{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.PauseDescription}}</p>
  <p style="font-weight: bold">{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.PauseDescriptionQuestion}}</p>
  <p class="textWithNewlines">
    <span>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.PauseDescription2}}</span>
    <span style="color:#de350b">* {{ '{' }}background-color: rgba(0, 0, 0, 0) !important; overflow: hidden}</span>
    <span>{{this.i18nService.localize().StreamerAffiliatesOverviewComponent.PauseDescription3}}</span>
  </p>
</p-dialog>

<streamer-feedback-panel [show]="showFeedback" (showChange)="showFeedback=$event" [affiliateId]="this.feedbackAffiliateId">
</streamer-feedback-panel>
