<div *ngIf="this.globalService.agency&&this.globalService.agency.roles.includes('ADMIN')">
  <div class="headline">
    {{i18nService.localize().AgencyUsersComponent.AgencyUsersHeader}}
  </div>

  <div class="p-col-12">
    <div class="p-grid abstand">
      <div class="p-col-12">
        <button pButton pRipple class="p-button-rounded" type="button" icon="fas fa-plus"
          label="{{this.i18nService.localize().AgencyUsersComponent.NewAgencyUser}}"
          style="margin-bottom: 0.5em; margin-top: 4.0em;" (click)="showCreateUser = true">
        </button>
      </div>

      <div class="p-col-12" style="padding-top: 0" *ngIf="passwordGenerated">
        <p class="info">
          {{i18nService.localize().AgencyUsersComponent.PasswordChangedInfo}}
        </p>
      </div>

      <div class="p-col-12" style="padding-top: 0">
        <p-table [value]="users">
          <ng-template pTemplate="header">
            <tr>
              <th class="tableTopLeft">{{i18nService.localize().AgencyUsersComponent.Firstname}}</th>
              <th>{{i18nService.localize().AgencyUsersComponent.Lastname}}</th>
              <th>{{i18nService.localize().AgencyUsersComponent.Email}}</th>
              <th>{{i18nService.localize().AgencyUsersComponent.Roles}}</th>
              <th class="tableTopRight">{{i18nService.localize().AgencyAdvertisersComponent.Actions}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
            <tr>
              <td>{{user.firstname}}</td>
              <td>{{user.lastname}}</td>
              <td>{{user.email}}</td>
              <td>{{user.roles}}</td>
              <td>
                <button pButton pRipple type="button" icon="fas fa-key" style="margin-right: 0.5em"
                  class="p-button-rounded"
                  pTooltip="{{i18nService.localize().AgencyUsersComponent.PasswordChangeTooltip}}"
                  tooltipPosition="left" (click)="resetUserPassword(user.id)">
                </button>
                <button pButton pRipple type="button" icon="fas fa-pencil-alt" style="margin-right: 0.5em"
                  class="p-button-rounded" pTooltip="{{i18nService.localize().AgencyUsersComponent.EditTooltip}}"
                  tooltipPosition="left" (click)="openUserEdit(user)">
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <p-dialog [(visible)]="showEditUser" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
        [draggable]="false" [modal]="true" [closeOnEscape]="true" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AgencyUsersComponent.EditUserHeadline}}</div>
        </ng-template>

        <div class="p-fluid p-formgrid p-grid" *ngIf="selectedUserForEdit">
          <div class="p-field p-col-12 p-md-6">
            <label for="firstname" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Firstname}}:</label>
            <input id="firstname" type="text" pInputText [(ngModel)]="selectedUserForEdit.firstname" class="field"
                   [disabled]="selectedUserForEdit.id == this.globalService.agency.userId">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="lastname" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Lastname}}:</label>
            <input id="lastname" type="text" pInputText [(ngModel)]="selectedUserForEdit.lastname" class="field"
                   [disabled]="selectedUserForEdit.id == this.globalService.agency.userId">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="email" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Email}}:</label>
            <input id="email" type="text" pInputText [(ngModel)]="selectedUserForEdit.email" class="field"
                   [disabled]="selectedUserForEdit.id == this.globalService.agency.userId">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="roles" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Roles}}:</label>
            <p-selectButton [options]="roleOptions" id="editRoles" [(ngModel)]="selectedUserForEdit.roles" optionLabel="label"
                            optionValue="value" [style]="{'border-radius':'12px'}" [multiple]="true">
            </p-selectButton>
          </div>

          <p class="warning" *ngIf="emailError">
            {{i18nService.localize().AgencyProfileComponent.EmailError}}
          </p>

        </div>

        <div style="text-align: right">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="changeUser()" class="p-button-rounded">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="closeUserEdit()" class="p-button-rounded"
            style="margin-left: 0.5em">
          </button>
        </div>
      </p-dialog>

      <p-dialog [(visible)]="showCreateUser" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="false" [closable]="false" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AgencyUsersComponent.CreateUserHeadline}}</div>
        </ng-template>

        <div class="p-fluid p-formgrid p-grid">

          <div class="p-field p-col-12 p-md-6">
            <label for="newUserFirstName" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Firstname}}:</label>
            <input pInputText type="text" name="newUserFirstName" id="newUserFirstName" [(ngModel)]="firstname">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="newUserLastName" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Lastname}}:</label>
            <input pInputText type="text" name="newUserLastName" id="newUserLastName" [(ngModel)]="lastname">
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="email" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Email}}:</label>
            <input pInputText type="text" name="email" id="email2" [(ngModel)]="email">
            <p *ngIf="!validmail" class="warning">
              {{i18nService.localize().AgencyUsersComponent.InvalidEmail}}
            </p>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="roles" style="font-weight: bold">{{i18nService.localize().AgencyUsersComponent.Roles}}:</label>
            <p-selectButton [options]="roleOptions" id="roles" [(ngModel)]="role" optionLabel="label"
                            optionValue="value" [style]="{'border-radius':'12px'}">
            </p-selectButton>
          </div>

        </div>

        <div style="text-align: right;">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="createNewUser()" class="p-button-rounded"
                  [disabled]="!checkAllValues()">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="closeUserCreate()" class="p-button-rounded"
                  style="margin-left: .5em">
          </button>
        </div>

      </p-dialog>

    </div>
  </div>
</div>
