export class AgencyUser {

  public id!: string;
  public firstname!: string;
  public lastname!: string;
  public active!: boolean;

  public email!:string;
  public roles: string[] = [];

}
