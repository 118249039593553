<div *ngIf="this.globalService.streamer">
  <div class="headline" style="margin-bottom: 1em;">{{ this.i18nService.localize().StreamerNewsComponent.Header }}</div>
  <div class="p-col-12" style="margin: 1em;" *ngIf="!selectedNews">
    <p-selectButton [options]="newsOptions" [(ngModel)]="newsType" optionLabel="label" optionValue="value"
                    (onChange)="this.updateNews()">
    </p-selectButton>
  </div>
  <div class="p-col-12 newsPadding" *ngIf="!selectedNews">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-4" *ngFor="let n of news" style="cursor: pointer">
        <p-card (click)="selectNewsForReading(n)">
          <ng-template pTemplate="header">
            <img alt="Card" [src]="n.picUrl" width="300" style="border-top-right-radius: 40px; border-top-left-radius: 40px;">
            <div style="display: flex; align-items: center; justify-content: center;">
              <i *ngIf="n.pinned" class="pi pi-bookmark-fill p-mr-2" style="color: #2196f3;"></i>
              <span><h3>{{ n.headline }}</h3></span>
            </div>
          </ng-template>
          <p>{{ this.i18nService.localize().StreamerNewsComponent.ContinueReading }}</p>
        </p-card>
      </div>
    </div>
  </div>

  <div class="p-col-12" *ngIf="selectedNews">
    <div style="padding: 0 1em;">
      <p-card>
        <div class="p-grid">
          <div class="p-col-12">
            <button pButton pRipple icon="fas fa-arrow-left" class="p-button-rounded"
                    pTooltip="{{this.i18nService.localize().StreamerNewsComponent.BackButtonTooltip}}"
                    (click)="goBack()">
            </button>
          </div>
          <div class="p-col-12 p-xl-8 p-xl-offset-2">
            <img [src]=selectedNews.picUrl style="width: 20%;">

            <h1 style="margin-bottom: 0;">{{ selectedNews.headline }}</h1>
            <p class="news-date">{{ selectedNews.creationDate }}</p>

            <p [innerHTML]="selectedNews.sanitizedText"></p>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>
