export const environment = {
  production: false,
  envName: 'dev',
  streamEventBusUrl: 'https://test.streamboost.de/eventbus',
  streamWebUrl: 'https://test.streamboost.de',
  streamWebsocket: 'https://test.streamboost.de/websocket',
  streamAPIUrl: 'https://test.streamboost.de/api/',  // Geändert zu HTTPS
  streamTinyUrl: 'https://test.streamboost.de/tinyurl',  // Geändert zu HTTPS und angepasst
  stripeApiKey: 'pk_test_51P9P8yRuCJE167QwBIPYUycoFRbXXLNR7lWVXq9Ijm9EAYRdAAP4dYVb2C8va7vgGdY5i5OWtVX5FZ52jdDq9tYj00vW08NZaz',
};
