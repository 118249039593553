import {Component, Input, OnInit} from '@angular/core';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentService} from "../services/paymentService";
import {environment} from "../../environments/environment";
import {CampaignService} from "../services/campaign.service";
import {AffiliateService} from "../services/affiliate.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() productName: string = "";
  @Input() price!: number;
  @Input() returnUrl: string = "";

  // Optional
  @Input() campaignId?: string;
  @Input() advertiserId?: string;
  @Input() action?: string;

  constructor(private paymentService: PaymentService, private campaignService: CampaignService, public affiliateService: AffiliateService,) {
  }

  async ngOnInit() {
    let stripe = await loadStripe(environment.stripeApiKey);
    const response: any = await this.paymentService.createCheckoutSession(this.productName, this.price, this.returnUrl);
    const clientSecret: string = response.client_secret;
    const checkout = await stripe!.initEmbeddedCheckout({
      clientSecret
    });
    checkout.mount('#checkout');
    this.paymentService.checkout = checkout;
    if (this.campaignId && this.action == "create") {
      await this.campaignService.setSessionWizardWIP(this.campaignId, response.session_id, this.advertiserId);
    } else if (this.campaignId && this.action == "extend") {
      await this.campaignService.setSessionExtend(this.campaignId, response.session_id, this.advertiserId);
    } else if (this.campaignId && this.action == "affiliateExtend") {
      await this.affiliateService.setSessionExtend(this.campaignId, response.session_id);
    }
  }
}
