export class FileModel {

  public id!: string;

  public contentType!: string;

  public name!: string;

  public downloadURL!: string;

  constructor() {

  }

}
