import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { I18nService } from 'src/app/services/i18n.service';
import { PictureService } from '../../services/picture.service';
import { IpService } from 'src/app/services/ip.service';
import { AgencyService } from '../../services/agency.service';

@Component({
  selector: 'profile-agency',
  templateUrl: './profile-agency.component.html',
  styleUrls: ['./profile-agency.component.scss']
})
export class AgencyProfileComponent implements OnInit {

  public changeProfile = false;
  public changeContact = false;
  public changeLogo = false;
  public changeTax = false
  public emailError = false;

  private ip: any;

  public oldPassword!: string;
  public newPassword!: string;
  public newPassword2!: string;
  public changePassword = false;
  public passwordError = false;
  public authError = false;
  public authError2 = false;
  public passwordChanged = false;

  public showPicUpload = false;
  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public picturesOk = true;
  public working = false;

  constructor(public globalService: GlobalService, public ipService: IpService,
              public i18nService: I18nService, public pictureService: PictureService,
              public agencyService: AgencyService) {

  }

  ngOnInit(): void {
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.checkPicture(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  checkPicture(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {

          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_width > 500) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }

  uploadPic() {
    if (this.globalService.agency && this.globalService.agency.isUserAdmin()) {
      this.working = true;
      this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, this.globalService.agency.id, 'LOGO', 'agency')
        .then(() => {
          setTimeout(() => {
            this.agencyService.updateAgencyInformation();
            this.working = false;
            this.changeLogo = false;
          }, 200);
        })
        .catch(error => {
          console.log(error);
          this.working = false;
          this.changeLogo = false;
        });
    }
  }

  showChangeLogo() {
    this.uploadedPictures = [];
    this.uploadedPicturesBackup = [];
    this.changeLogo = !this.changeLogo
  }

  changeProfileInfo() {
    this.changeProfile = !this.changeProfile
  }

  saveProfileChanges() {
    if (this.globalService.agency && this.globalService.agency.isUserAdmin()) {
      this.changeProfileInfo();
      this.agencyService.changeAgencyInformation(
        this.globalService.agency.agencyName.trim(),
        this.globalService.agency.website.trim(),
        this.globalService.agency.street.trim(),
        this.globalService.agency.nr.trim(),
        this.globalService.agency.city.trim(),
        this.globalService.agency.postalCode
      ).then();
    }

  }

  abortProfileChanges() {
    this.changeProfileInfo();
  }

  changeContactInfo() {
    this.changeContact = true;
  }

  saveContactChanges() {
    if (this.globalService.agency) {
      this.emailError = false;

      if (this.globalService.isEmailOk(this.globalService.agency.email)) {
        this.ipService.getIp().then(res => {
          if (this.globalService.agency) {
            this.agencyService.changeProfile(
              this.globalService.agency.firstname.trim(),
              this.globalService.agency.lastname.trim(),
              this.globalService.agency.email.trim(),
              res.ip
            ).then(result => {
              this.changeContact = false;
            }).catch(error => {
              this.emailError = true;
            });
          }
        });
      } else {
        this.emailError = true;
      }
    }
  }

  abortContactChanges() {
    this.agencyService.updateAgencyInformation().then(reply => {
      this.changeContact = false;
    });
  }

  changeTaxInfo() {
    this.changeTax = true;
  }

  saveTaxChanges() {
    if (this.globalService.agency && this.globalService.agency.isUserAdmin()) {
      this.changeTax = false;
      this.agencyService.changeTaxInformation(this.globalService.agency.taxNumber.trim()).then();
    }
  }

  abortTaxChanges() {
    this.changeTax = false;
  }

  openPasswordDialog() {
    this.changePassword = true;
    this.passwordChanged = false;
  }
  startStripePortal(){
    this.agencyService.startStripePortal(this.globalService.agency?.customerId!).then(reply => {
      this.changeContact = false;
      window.location.href = "" + reply;
    });
  }

  savePasswordChange() {
    if (this.globalService.agency) {
      this.passwordError = false;
      this.authError = false;
      this.passwordChanged = false;

      if (this.agencyService.checkNewPassword(this.newPassword, this.newPassword2)) {
        this.ipService.getIp().then(res => {

          if (this.globalService.agency) {
            this.agencyService.changePasswordWithOldPassword(this.oldPassword, this.newPassword,
              this.globalService.agency.email, res.ip).then(result => {
              this.passwordChanged = true;
              this.abort();
            }).catch((error) => {
              if (error === 'Doppelganger') {
                this.authError = true;
              } else {
                console.log(error);
              }
            });
          }

        });
      } else {
        this.newPassword = '';
        this.newPassword2 = '';
        this.oldPassword = '';
        this.passwordError = true;
      }
    }
  }

  abort() {
    this.newPassword = '';
    this.newPassword2 = '';
    this.oldPassword = '';
    this.authError = false;
    this.passwordError = false;
    this.changePassword = false;
    this.authError2 = false;
    this.oldPassword = '';
  }

  grantAdminAccess() {
    this.ipService.getIp().then(res => {
      this.ip = res.ip;
      if (this.globalService.agency) {
        this.agencyService.grantAdminAccess(this.ip).catch(error => {
          console.log(error);
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }
}
