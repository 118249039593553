<div>
  <div class="headline">{{i18nService.localize().AdvertiserRegisterComponent.RegisterHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12">
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">

              <p class="headline-in-step" style="margin: 0;">
                {{i18nService.localize().AdvertiserRegisterComponent.WelcomeHeader}}
              </p>

              <p *ngIf="!registerSent">
                {{i18nService.localize().AdvertiserRegisterComponent.InfoText1}}
                <br>
                {{i18nService.localize().AdvertiserRegisterComponent.InfoText2}}
                <b>{{i18nService.localize().AdvertiserRegisterComponent.Email}}</b>
                {{i18nService.localize().AdvertiserRegisterComponent.InfoText3}}
                <b>{{i18nService.localize().AdvertiserRegisterComponent.Password}}</b>
                {{i18nService.localize().AdvertiserRegisterComponent.InfoText4}}
                <br>
              </p>

              <p *ngIf="registerSent">
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText1}}
                <br><br>
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText2}}
                <a (click)="this.routingService.switchToPath('/advertiser/login')" style="cursor: pointer; text-decoration: underline;">
                  Login
                </a>
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText3}}
              </p>

              <div *ngIf="!registerSent">
                <p class="headline-in-step">
                  {{i18nService.localize().AdvertiserRegisterComponent.CompanyInfoHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12">
                    <label for="companyname" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.CompanyName}}</label>
                    <input id="companyname" type="text" pInputText [(ngModel)]="companyName" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="website" style="font-weight: bold">Website:</label>
                    <input id="website" type="text" pInputText [(ngModel)]="website" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="tax" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Tax}}</label>
                    <input id="tax" type="text" pInputText [(ngModel)]="taxNumber" class="field" maxlength="30">
                  </div>
                </div>

                <p class="headline-in-step">
                  {{i18nService.localize().AdvertiserRegisterComponent.AddressHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-8">
                    <label for="street" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Street}}</label>
                    <input id="street" type="text" pInputText [(ngModel)]="street" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-4">
                    <label for="nr" style="font-weight: bold">Nr.*:</label>
                    <input id="nr" type="text" pInputText [(ngModel)]="nr" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-4">
                    <label for="postalcode" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.PostalCode}}</label>
                    <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-8">
                    <label for="city" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.City}}</label>
                    <input id="city" type="text" pInputText [(ngModel)]="city" class="field">
                  </div>

                  <div class="p-field p-col-12">
                    <label for="country" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Country}}</label>
                    <p-dropdown id="country" [options]="countriesDropDownList" [(ngModel)]="country"
                                optionLabel="item_text" [showClear]="true"
                                [style]="{'border-radius': '12px', 'width': '100%'}"
                                [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-dropdown>
                  </div>
                </div>

                <p class="headline-in-step">
                  {{i18nService.localize().AdvertiserRegisterComponent.ContactHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-6">
                    <label for="firstname" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Firstname}}</label>
                    <input id="firstname" type="text" pInputText [(ngModel)]="firstname" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="lastname" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Lastname}}</label>
                    <input id="lastname" type="text" pInputText [(ngModel)]="lastname" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="email" style="font-weight: bold">E-Mail*:</label>
                    <input id="email" type="email" pInputText [(ngModel)]="email" class="field">
                    <small *ngIf="emailError" id="email-help" class="p-error">{{i18nService.localize().AdvertiserRegisterComponent.EmailInUse}}</small>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="telephone" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.Telefon}}</label>
                    <input id="telephone" type="number" pInputText [(ngModel)]="telephone" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="password" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.PasswordOnce}}</label>
                    <p-password id="password" [(ngModel)]="password" [toggleMask]="false" class="field"
                                [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="passwordToo" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.PasswordAgain}}</label>
                    <p-password id="passwordToo" [(ngModel)]="passwordToo" [toggleMask]="false" [feedback]="false"
                                class="field" [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>
                </div>

                <div class="p-grid" style="margin-top: 1em;">
                  <div class="p-col-12 p-offset-6">
                    {{i18nService.localize().AdvertiserRegisterComponent.TermsText}}<a href="{{this.globalService.links.conditionsLink}}" target="_blank">{{i18nService.localize().AdvertiserRegisterComponent.TermsAndConditionLink}}</a>
                  </div>
                  <div class="p-col-12 p-offset-6 field-checkbox">
                    <p-checkbox [(ngModel)]="acceptTermsAndConditions" [binary]="true" inputId="accept"></p-checkbox>
                    <!--suppress XmlInvalidId -->
                    <label for="accept" style="margin-left: 0.5em;">{{i18nService.localize().AdvertiserRegisterComponent.AcceptCheckboxText}}</label>
                  </div>
                </div>
              </div>

            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" (click)="register()" *ngIf="!working&&!registerSent"
                      label="{{i18nService.localize().AdvertiserRegisterComponent.RegisterButton}}" class="p-button-rounded"
                      [disabled]="!checkAllValues()" style="margin-right: .25em">
              </button>
            </div>
          </div>
        </p-card>
      </div>

    </div>
  </div>
</div>
