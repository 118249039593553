import { Component, OnInit } from '@angular/core';
import { AgencyUser } from 'src/app/model/agency/agency-user.model';
import { AgencyService } from 'src/app/services/agency.service';
import { GlobalService } from 'src/app/services/global.service';
import { I18nService } from 'src/app/services/i18n.service';
import { PictureService } from 'src/app/services/picture.service';
import { RoutingService } from 'src/app/services/routing.service';
import { IpService } from "../../services/ip.service";

@Component({
  selector: 'app-users',
  templateUrl: './agency-users.component.html',
  styleUrls: ['./agency-users.component.scss']
})
export class AgencyUsersComponent implements OnInit {

  public users: AgencyUser[] = [];

  public passwordGenerated: boolean = false;

  // Nutzer bearbeiten
  public selectedUserForEdit?: AgencyUser;
  public showEditUser: boolean = false;
  public emailError = false;

  // Nutzer erstellen
  public firstname: string = "";
  public lastname: string = "";
  public email: string = "";
  public role: string = "";
  public showCreateUser = false;
  public roleOptions = [
    { label: 'USER', value: 'USER' },
    { label: 'PICTURES', value: 'PICTURES' }
    // { label: 'BILLS', value: 'BILLS' },
  ];
  public validmail = true;

  constructor(public globalService: GlobalService,
    public i18nService: I18nService, public pictureService: PictureService,
    public routingService: RoutingService, public agencyService: AgencyService,
    public ipService: IpService) {

  }

  public ngOnInit(): void {
    if (this.globalService.agency && this.globalService.agency.roles.includes('ADMIN')) {
      this.updateAgencyUsers();
    } else {
      this.routingService.switchToPath('/agency/dashboard');
    }
  }

  private updateAgencyUsers() {
    this.users = [];
    this.agencyService.getAllUsers().then((res) => {
      const users: AgencyUser[] = [];

      let i: number;
      for (i = 0; i < res.agencyusers.length; i++) {
        const user: AgencyUser = new AgencyUser();
        user.id = res.agencyusers[i]._id;
        user.firstname = res.agencyusers[i].firstname;
        user.lastname = res.agencyusers[i].lastname;
        user.active = res.agencyusers[i].active;
        user.email = res.agencyusers[i].email;
        user.roles = res.agencyusers[i].roles;

        users.push(user);
      }
      this.users = users;
    });
  }

  // Passwort ändern
  public resetUserPassword(agencyUserId: string) {
    this.passwordGenerated = false;

    if (agencyUserId === this.globalService.agency?.userId) {
      this.routingService.switchToPath('/agency/profile');
    } else {
      this.agencyService.resetUserPassword(agencyUserId).then(() => {
        this.passwordGenerated = true;
      });
    }

  }

  // Nutzer bearbeiten
  public openUserEdit(user: AgencyUser) {
    this.selectedUserForEdit = user;
    this.showEditUser = true;
  }

  public closeUserEdit() {
    delete this.selectedUserForEdit;
    this.showEditUser = false;
  }

  public changeUser() {
    if (this.selectedUserForEdit) {
      this.ipService.getIp().then(res => {
        if (this.selectedUserForEdit) {

          if (this.globalService.isEmailOk(this.selectedUserForEdit.email)) {
            this.agencyService.changeUserInfo(this.selectedUserForEdit.id, this.selectedUserForEdit.firstname,
              this.selectedUserForEdit.lastname, this.selectedUserForEdit.email, this.selectedUserForEdit.roles, res.ip).then(() => {
                this.updateAgencyUsers();

                // Bei Änderungen der eigenen Rollen muss die komplette Seite neu geladen werden
                if (this.selectedUserForEdit && this.selectedUserForEdit.id === this.globalService.agency?.userId) {
                  this.closeUserEdit();
                  this.routingService.switchToPathWithReload('/agency/users');
                } else {
                  this.closeUserEdit();
                }
            }).catch(error => {
              console.log(error);
              this.updateAgencyUsers();
              this.emailError = true;
            });
          } else {
            this.emailError = true;
          }
        }
      });
    }
  }

  // Nutzer erstellen
  public closeUserCreate() {
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.role = "";
    this.showCreateUser = false;
    this.validmail = true;
  }

  public createNewUser() {
    if (this.globalService.isEmailOk(this.email)) {
      this.ipService.getIp().then(res => {
        this.agencyService.createNewAgencyUser(this.firstname, this.lastname, this.email, this.role, res.ip).then(() => {
          this.closeUserCreate();
          this.updateAgencyUsers();
        }).catch(error => {
          console.log(error);
        });
      });
    } else {
      this.validmail = false;
    }
  }

  checkAllValues(): boolean {
    let creationAllowed: boolean = true;

    if (!this.firstname) {
      creationAllowed = false;
    }

    if (!this.lastname) {
      creationAllowed = false;
    }

    if (!this.email) {
      creationAllowed = false;
    }

    if (!this.role) {
      creationAllowed = false;
    }

    return creationAllowed;
  }


}
