import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialService } from '../../services/social.service';
import { RoutingService } from '../../services/routing.service';
import { IpService } from '../../services/ip.service';
import { CookieService } from 'ngx-cookie-service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.css']
})
export class OauthCallbackComponent implements OnInit {

  waitForStreamBoost: any;
  seriousError = false;

  constructor(public socialService: SocialService, public router: Router,
              public route: ActivatedRoute, public routingService: RoutingService,
              public ipService: IpService, private cookieService: CookieService,
              public i18nService: I18nService) {
  }

  ngOnInit() {
    this.waitForStreamBoost = setInterval(() => {
      const state = this.socialService.getEventState();
      if (state) {
        if (this.route.snapshot.queryParams.code != null && this.route.snapshot.queryParams.state != null) {

          // Sprache vom letzten Cookie einstellen
          if (this.cookieService.get('streamboost-language')) {
            this.i18nService.setLang(this.cookieService.get('streamboost-language'));
          } else {
            this.i18nService.setLang(navigator.language.toUpperCase());
          }

          this.ipService.getIp().then(res => {

            this.socialService.handleLoginCallback(this.route.snapshot.queryParams.code,
              this.route.snapshot.queryParams.state, res.ip)
              .then((result) => {
                let gotoRegister = result.register;

                // Wenn es den Streamer noch nicht gibt, gehts zur Registrierung
                if (gotoRegister === true) {
                  let registeredWithNetwork = result.with_network;
                  this.routingService.switchToPathWithReload('/streamer/register', {error: 'none', with: registeredWithNetwork});

                // sonst gehts zum Home Screen
                } else if (gotoRegister === false) {
                  this.routingService.switchToPathWithReload('/streamer/home');
                } else {
                  this.seriousError = true;
                }

              })
              .catch((error) => {
                if (error.code === 'Dwarf') {
                  this.seriousError = true;
                } else if (error.code === 'Yig') {

                  // Zurück zur Registrierung mit Error
                  this.routingService.switchToPathWithReload('/streamer/register', {error: 'loginError', with: error.response.additional.with_network});
                } else if (error.code === 'Doppelganger') {

                  console.log(error);

                  // Zurück zum Login
                  if (error.response.additional.login) {
                    this.routingService.switchToPathWithReload('/streamer/login', {error: 'NoRights'});

                  // oder zum Profil
                  } else {
                    this.routingService.switchToPathWithReload('/streamer/home', {error: 'NoRights'});
                  }

                }else {
                  console.log(error);
                }
              });

          }).catch((err) => {
            console.log(err);
            this.seriousError = true;
          });

        }
        clearInterval(this.waitForStreamBoost);
      }
    }, 500);
  }

}
