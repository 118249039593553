import {EventEmitter, Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {SuperService} from './super.service';
import {Advertiser} from '../model/advertiser/advertiser.model';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {GameStreamStatistic} from '../model/advertiser/game-stream-statistic.model';
import {Subscription} from 'rxjs';
import {I18nService} from './i18n.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IpService} from './ip.service';
import {AgencyService} from './agency.service';

@Injectable()
export class AdvertiserService extends SuperService {

  public isLoggedIn = false;
  public loginState = new EventEmitter<boolean>();
  private advertiser?: Advertiser;
  public advertiserChanged = new EventEmitter<Advertiser>();

  public langChangeSubscription!: Subscription;

  constructor(public streamBoostEventBus: StreamBoostEventBus, private i18nService: I18nService,
              private cookieService: CookieService, private http: HttpClient, private ipService: IpService,
              private agencyService: AgencyService) {
    super(streamBoostEventBus);

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe(newLang => {
      if (this.advertiser) {
        this.getAdvertiserInformationInternal(newLang);
      }
    });

  }

  userLoggedIn(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cookieService.delete('streamboost-session', '/');

      this.isLoggedIn = true;
      this.loginState.emit(this.isLoggedIn);

      this.cookieService.set('streamboost-session', token, 5000000, '/', '', true);
      this.updateAdvertiserInformation().then(() => {
        resolve(true);
      }).catch((error) => {
          reject(error);
        }
      );
    });
  }

  advertiserHasRememberLogin(): boolean {
    if (this.advertiser) {
      return this.advertiser.rememberLogin;
    } else {
      return false;
    }
  }

  authenticateWithToken(token: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.auth.token',
        {
          message: {
            token,
            ip
          }
        })
        .then((result) => {

            if (result.body.code === 'Theseus') {
              this.userLoggedIn(token).then(() => {
                resolve(true);
              }).catch(loginError => {
                reject(loginError);
              });
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body.response.number);
            }
          }
        )
        .catch((error) => {
            reject(error);
          }
        );
    });
  }

  authenticate(email: string, password: string, ip: string, rememberLogin: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.auth',
        {
          message: {
            email,
            password,
            ip,
            remember_login: rememberLogin
          }
        })
        .then((result) => {

            if (result.body.code) {
              reject(result.body.code);
              return;
            }

            if (result.body.response.token) {
              this.userLoggedIn(result.body.response.token).then(() => {
                resolve(result.body);
              }).catch(() => {
                reject(result.body);
              });
            }
          }
        )
        .catch((error) => {
            reject(error);
          }
        );
    });
  }

  logout(remember_login: boolean): Promise<void> {
    return new Promise((resolve) => {

      this.isLoggedIn = false;
      this.loginState.emit(this.isLoggedIn);

      if (!remember_login) {
        this.cookieService.delete('streamboost-session', '/');
      }

      delete this.advertiser;
      this.advertiserChanged.emit(this.advertiser);

      localStorage.clear();
      resolve();
    });
  }

  public updateAdvertiserInformation(): Promise<any> {
    return new Promise((resolve, reject) => {

      // Nach dem Benutzer holen, Handler anbinden
      this.getAdvertiserInformationInternal(this.i18nService.getCurrentLang()).then(() => {
        resolve(true);
      }).catch((error) => reject(error));
    });
  }

  public startStripePortal(customerId: string): Promise<void>{
    console.log("Start Stripe Portal Service")
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        console.log("Sende Nachricht")
        this.streamBoostEventBus.send('stream.web.facade.agency.startCustomerPortal',
          {
            message: {
              customer_id: customerId
            }
          })
          .then(
            (response: any) => {
              console.log("Bin zurück")
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  private getAdvertiserInformationInternal(lang: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.info',
          {
            message: {
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              this.setAdvertiser(response.body.response);
              resolve();
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public setAdvertiser(newAdvertiser: any) {
    this.advertiser = this.fillAdvertiser(newAdvertiser);
    this.advertiserChanged.emit(this.advertiser);
  }

  private fillAdvertiser(newAdvertiser: any): Advertiser {
    let advertiser = new Advertiser();

    advertiser.id = newAdvertiser._id;
    advertiser.advertiserNr = newAdvertiser.advertiser_nr;
    advertiser.isPasswordInitial = newAdvertiser.pwd_initial;
    advertiser.companyName = newAdvertiser.company_name;
    advertiser.website = newAdvertiser.website;
    advertiser.logoURL = newAdvertiser.logo_url;

    advertiser.firstname = newAdvertiser.firstname;
    advertiser.lastname = newAdvertiser.lastname;
    advertiser.email = newAdvertiser.email;
    advertiser.telephone = newAdvertiser.telephone;
    advertiser.taxNumber = newAdvertiser.tax_number;
    advertiser.country = newAdvertiser.country;
    advertiser.countryId = newAdvertiser.country_id;

    if (newAdvertiser.address) {
      advertiser.street = newAdvertiser.address.street;
      advertiser.nr = newAdvertiser.address.nr;
      advertiser.postalCode = newAdvertiser.address.postal_code;
      advertiser.city = newAdvertiser.address.city;
    }

    advertiser.activeCampaigns = newAdvertiser.active_campaigns_affiliates;
    advertiser.allInactiveDisplayCampaigns = newAdvertiser.all_inactive_campaigns;
    advertiser.allOtherDisplayCampaigns = newAdvertiser.all_other_campaigns;
    advertiser.wipCampaigns = newAdvertiser.all_wip_campaigns;
    advertiser.allInactiveClickCampaigns = newAdvertiser.all_inactive_affiliates;
    advertiser.allOtherClickCampaigns = newAdvertiser.all_other_affiliates;

    advertiser.rememberLogin = newAdvertiser.remember_login;
    advertiser.allowAdminAccess = newAdvertiser.allow_access;

    advertiser.agbConfirmation = newAdvertiser.terms_confirmation;

    advertiser.customerId = newAdvertiser.customer_id;

    return advertiser;
  }

  public getAllClicksChart(advertiserId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.all.clicks',
          {
            message: {
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllViewsChart(advertiserId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.all.views',
          {
            message: {
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  getNextBillNumber(): Promise<string> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.next.bill.nr',
          {
            message: {}
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body.response.number);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changeProfileInformation(name: string, website: string, street: string, nr: string, city: string,
                           postalCode: string, country?: string, advertiserId?: string): Promise<any> {
    return new Promise((resolve, reject) => {

        this.streamBoostEventBus.send(
          'stream.web.facade.advertiser.change.profile',
          {
            message: {
              name,
              website,
              street,
              nr,
              city,
              postal_code: postalCode != null ? postalCode.toString() : '',
              country: country,
              advertiser_id: advertiserId
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                if (this.isAdvertiserSet()) {
                  this.setAdvertiser(result.body.response);
                } else {
                  this.agencyService.setAgency(result.body.response);
                }

                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changeContact(firstname: string, lastname: string, email: string, telephone: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.advertiser.change.contact',
          {
            message: {
              firstname,
              lastname,
              email,
              telephone,
              ip
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAdvertiser(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changeTaxInformation(taxNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {

        this.streamBoostEventBus.send(
          'stream.web.facade.advertiser.change.tax',
          {
            message: {
              tax_number: taxNumber
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAdvertiser(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changePassword(pwd: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.change.advertiser.password',
          {
            'message': {
              'password': pwd
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAdvertiser(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  changePasswordWithOldPassword(oldPwd: string, pwd: string, email: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.advertiser.change.password.renew',
          {
            'message': {
              'password': pwd,
              'old_password': oldPwd,
              'email': email,
              ip
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                this.setAdvertiser(result.body.response);
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  deleteAdvertiser(password: string, email: string, ip: string, rememberLogin: boolean, siteURL: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.delete',
        {
          message: {
            password,
            ip_address: ip,
            email
          }
        })
        .then((result) => {
            if (result.body.code === 'Theseus') {
              this.logout(rememberLogin).then(() => {

                setTimeout(() => {
                  window.location.href = siteURL;
                }, 100);
              });

            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        )
        .catch((error) => {
            console.log('ERROR');
            console.log(error);
            reject(error);
          }
        );
    });
  }

  public getAllGameStreamStatistics(advertiserId?: string): Promise<GameStreamStatistic[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.all.games',
          {
            message: {
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              let gameStreams = response.body.response.games_streams;
              let gameStreamStatistics: GameStreamStatistic[] = [];
              if (gameStreams) {
                gameStreamStatistics = gameStreams;
              }

              resolve(gameStreamStatistics);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAllProfileInformation() {
    return new Promise((resolve, reject) => {
      this.ipService.getIp().then(res => {
        let ipAddress = res.ip;

        let token;
        if (this.cookieService.get('streamboost-session')) {
          token = this.cookieService.get('streamboost-session');
        }

        if (token) {
          let headers: HttpHeaders = new HttpHeaders({'token': token})
            .append('ip_address', '' + ipAddress).append('lang', this.i18nService.getCurrentLang());

          this.http.get('/file/advertiser/' + this.advertiser?.id, {headers: headers, responseType: 'arraybuffer'})
            .subscribe(response =>
              this.downloadFile(response, "application/zip")
            )
        }
      });
    });
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  private downloadFile(data: any, type: string) {
    let blob = new Blob([data], {type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  registerAdvertiser(companyName: string, website: string, taxNumber: string, street: string, nr: number, postalCode: string,
                     city: string, country: any, firstname: string, lastname: string, email: string, telephone: number,
                     password: string, acceptTermsAndConditions: boolean, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.register',
        {
          message: {
            company_name: companyName,
            website,
            tax: taxNumber,
            street,
            nr,
            postal_code: postalCode,
            city,
            country,
            firstname,
            lastname,
            email,
            telephone,
            password,
            accept_terms: acceptTermsAndConditions,
            ip_address: ip
          }
        }).then((result) => {
        if (result.body.code === 'Theseus') {
          resolve(result.body.response.advertiser_id);
        } else {
          reject(result.body);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  registerAdvertiserSimple(companyName: string, email: string, password: string, acceptTermsAndConditions: boolean, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.register.simple',
        {
          message: {
            company_name: companyName,
            email,
            password,
            accept_terms: acceptTermsAndConditions,
            ip_address: ip
          }
        }).then((result) => {
        if (result.body.code === 'Theseus') {
          resolve(result.body.response.advertiser_id);
        } else {
          reject(result.body);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  registerAdvertiserSimpleAdditional(website: string, taxNumber: string, street: string, nr: string, postalCode: string,
                                     city: string, country: any, firstname: string, lastname: string,
                                     telephone: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.register.additional',
        {
          message: {
            website,
            tax: taxNumber,
            street,
            nr,
            postal_code: postalCode,
            city,
            country,
            firstname,
            lastname,
            telephone
          }
        }).then((result) => {

        if (result.body.code === 'Theseus') {
          if (this.isAdvertiserSet()) {
            this.setAdvertiser(result.body.response);
          }
          resolve(result.body);
        } else if (result.body.code) {
          reject(result.body.code);
        } else {
          resolve(result.body);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  createAdvertiserForAgency(companyName: string, website: string, street: string, nr: number, postalCode: string,
                            city: string, country: any, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.create',
        {
          message: {
            company_name: companyName,
            website,
            street,
            nr,
            postal_code: postalCode,
            city,
            country,
            ip_address: ip
          }
        }).then((result) => {
        if (result.body.code === 'Theseus') {
          resolve(result.body.response.advertiser_id);
        } else {
          reject(result.body);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  activateAdvertiser(id: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.activate',
        {
          'message': {
            'request_id': id,
            'ip_address': ip
          }
        }).then((result) => {
        {

          if (result.body.code) {
            reject(result.body.code);
            return;
          }

          if (result.body.response.token) {
            this.userLoggedIn(result.body.response.token).then(() => {
              resolve(result.body);
            }).catch(() => {
              reject(result.body);
            });
          }
        }

      }).catch((error) => {
        reject(error);
      });

    });
  }

  cancelMarketingMails(id: string, ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.cancel.mails',
        {
          'message': {
            'id': id,
            'ip_address': ip
          }
        }).then((result) => {
        {
          if (result.body.code === 'Theseus') {
            resolve(result.body);
          } else if (result.body.code) {
            reject(result.body.code);
          } else {
            resolve(result.body);
          }
        }
      }).catch((error) => {
        reject(error);
      });

    });
  }

  getAdvertiserForAgency(id: string): Promise<Advertiser> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.agency.advertiser',
        {
          'message': {
            'advertiser_id': id,
            'lang': this.i18nService.getCurrentLang()
          }
        }).then((result) => {
        {
          if (result.body.code) {
            reject(result.body.code);
            localStorage.clear();
            return;
          }
          resolve(this.fillAdvertiser(result.body.response));
        }
      }).catch((error) => {
        reject(error);
      });

    });
  }

  public isAdvertiserSet(): boolean {
    return !!this.advertiser;
  }

  public grantAdminAccess(ip: string): Promise<Advertiser> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.grant.access',
        {
          'message': {
            ip
          }
        }).then((result) => {
        {
          if (result.body.code === 'Theseus') {
            this.setAdvertiser(result.body.response);
            resolve(result.body);
          } else if (result.body.code) {
            reject(result.body.code);
          } else {
            resolve(result.body);
          }
        }
      }).catch((error) => {
        reject(error);
      });

    });
  }

  acceptTermsAndConditions(ip: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.accept.terms',
        {
          message: {
            ip_address: ip
          }
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }

        })
        .catch((error) => {
          reject(error);

        });
    });
  }

}
