import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {AdvertiserService} from '../../services/advertiser.service';
import {DashboardCampaign} from '../../model/advertiser/dashboard-campaign.model';
import {IpService} from '../../services/ip.service';
import {CampaignService} from '../../services/campaign.service';
import {RoutingService} from '../../services/routing.service';
import {I18nService} from '../../services/i18n.service';
import {AffiliateService} from '../../services/affiliate.service';
import {ActivatedRoute, Router} from "@angular/router";
import {WizardWIP} from "../../model/wizard-wip.model";
import {Message} from "primeng/api";
import {PaymentService} from "../../services/paymentService";

@Component({
  selector: 'advertiser-campaigns',
  templateUrl: './advertiser-campaigns.component.html',
  styleUrls: ['./advertiser-campaigns.component.scss']
})
export class AdvertiserCampaignsComponent implements OnInit {

  public selectedCampaignId?: string;
  public selectedCampaignNumberOfStreamer: number = 0;

  public selectedAffiliateId?: string;
  public selectedAffiliateNumberOfStreamer: number = 0;

  public selectedAffiliateIdForExtension?: string;
  public selectedAffiliateNameForExtension!: string;

  public selectedCampaignIdForExtension?: string;
  public selectedCampaignNameForExtension!: string;

  public messages: Message[] = [];
  public showEditCampaign = false;
  public campaignIdOfEdit!: string;
  public nameEdit!: string;
  public displayNameEdit!: string;
  public descriptionEdit!: string;

  public paymentSessionId?: string;
  public showRemoveCampaign: boolean = false;
  public selectedDeleteCampaign?: DashboardCampaign;

  constructor(public globalService: GlobalService, public advertiserService: AdvertiserService,
              public ipService: IpService, public campaignService: CampaignService,
              public routingService: RoutingService, public i18nService: I18nService,
              public affiliateService: AffiliateService, public router: Router, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const action = this.route.snapshot.queryParams['action'];
    this.paymentSessionId = this.route.snapshot.queryParams['session_id'];
    if (this.paymentSessionId) {
      if (action === 'extend') {
        this.campaignService.getCampaignBySessionId(this.paymentSessionId).then(campaign => {
          if (campaign) {
            this.selectedCampaignIdForExtension = campaign.id;
            this.selectedCampaignNameForExtension = campaign.name;
          }
        });
      } else if (action === 'affiliateExtend') {
        this.affiliateService.getAffiliateBySessionId(this.paymentSessionId).then(affiliate => {
          if (affiliate) {
            this.selectedAffiliateIdForExtension = affiliate.id;
            this.selectedAffiliateNameForExtension = affiliate.name;
          }
        });
      }
    }

    if (this.globalService.advertiser) {
    }
    this.showSuccessMessage(this.i18nService.localize().AdvertiserCampaignsComponent.CampaignCreatedSuccessMessage);
  }

  showSuccessMessage(message: string) {
    const created = this.route.snapshot.queryParamMap.get('created');
    if (created === 'true') {
      this.messages = [{
        severity: 'success',
        summary: this.i18nService.localize().AdvertiserCampaignsComponent.Success,
        detail: message
      }];
    }
  }

  selectCampaignForDetails(campaign: DashboardCampaign) {
    this.selectedCampaignId = campaign.id;
    this.selectedCampaignNumberOfStreamer = campaign.streamer;
  }

  closeCampaignDetails() {
    delete this.selectedCampaignId;
    this.selectedCampaignNumberOfStreamer = 0;
  }

  selectAffiliateForDetails(affiliate: DashboardCampaign) {
    this.selectedAffiliateId = affiliate.id;
    this.selectedAffiliateNumberOfStreamer = affiliate.streamer;
  }

  closeAffiliateDetails() {
    delete this.selectedAffiliateId;
    this.selectedAffiliateNumberOfStreamer = 0;
  }

  pauseCampaign(campaign: DashboardCampaign) {
    if (campaign.type === 'AFFILIATE') {
      this.ipService.getIp().then(res => {
        this.affiliateService.pauseAffiliate(campaign.id, res.ip).then(result => {
          this.advertiserService.updateAdvertiserInformation();
        });

      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.ipService.getIp().then(res => {
        this.campaignService.pauseCampaign(campaign.id, res.ip).then(result => {
          this.advertiserService.updateAdvertiserInformation();
        });

      }).catch((err) => {
        console.log(err);
      });
    }
  }

  startCampaign(campaign: DashboardCampaign) {
    if (campaign.type === 'AFFILIATE') {
      this.affiliateService.startAffiliate(campaign.id).then(result => {
        this.advertiserService.updateAdvertiserInformation();
      });
    } else {
      this.campaignService.startCampaign(campaign.id).then(result => {
        this.advertiserService.updateAdvertiserInformation();
      });
    }
  }

  openDeleteCampaign(campaign: DashboardCampaign) {
    this.selectedDeleteCampaign = campaign;
    this.showRemoveCampaign = true;
  }

  deleteCampaign() {
    if (this.selectedDeleteCampaign) {
      this.campaignService.deleteCampaign(this.selectedDeleteCampaign.id, this.selectedDeleteCampaign.status).then(result => {
        delete this.selectedDeleteCampaign;
        this.showRemoveCampaign = false;
        this.advertiserService.updateAdvertiserInformation();
      });
    }
  }

  deleteAffiliate() {
    if (this.selectedDeleteCampaign) {
      this.affiliateService.deleteAffiliate(this.selectedDeleteCampaign.id, this.selectedDeleteCampaign.status).then(result => {
        delete this.selectedDeleteCampaign;
        this.showRemoveCampaign = false;
        this.advertiserService.updateAdvertiserInformation();
      });
    }
  }

  openEditCampaign(campaign: DashboardCampaign) {
    this.nameEdit = campaign.name;
    this.displayNameEdit = campaign.displayName;
    this.descriptionEdit = campaign.description;
    this.campaignIdOfEdit = campaign.id;
    this.showEditCampaign = true;
  }

  changeCampaign() {
    this.campaignService.updateCampaign(this.campaignIdOfEdit, this.nameEdit, this.displayNameEdit, this.descriptionEdit)
      .then(result => {
        this.advertiserService.updateAdvertiserInformation();
        this.showEditCampaign = false;
      });
  }

  openExtendAffiliate(affiliate: DashboardCampaign) {
    this.advertiserService.updateAdvertiserInformation().then(result => {
      this.selectedAffiliateIdForExtension = affiliate.id;
      this.selectedAffiliateNameForExtension = affiliate.name;
    });
  }

  closeExtendAffiliate() {
    delete this.selectedAffiliateIdForExtension;
    this.selectedAffiliateNameForExtension = "";
  }

  openExtendCampaign(campaign: DashboardCampaign) {
    this.advertiserService.updateAdvertiserInformation().then(result => {
      this.selectedCampaignIdForExtension = campaign.id;
      this.selectedCampaignNameForExtension = campaign.name;
    });
  }

  closeExtendCampaign() {
    delete this.selectedCampaignIdForExtension;
    this.selectedCampaignNameForExtension = "";
  }

  gotoCreateNewWizard() {
    this.advertiserService.updateAdvertiserInformation().then(result => {
      this.routingService.switchToPath('/advertiser/campaign/create');
    });
  }

}
