<div *ngIf="this.globalService.streamer">
  <div class="headline">{{this.i18nService.localize().StreamerHomeComponent.HomeHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">
      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerHomeComponent.OverviewHeader}}</h2>
      </div>
      <div class="p-col-12 p-md-6">
        <streamer-campaigns-overview [showQuitButton]="false" [showViews]="true" [showPictureHint]="false"
                                     [bigFonts]="true">
        </streamer-campaigns-overview>
      </div>
      <div class="p-col-12 p-md-6">
        <streamer-affiliates-overview [bigFonts]="true"></streamer-affiliates-overview>
      </div>

      <div class="p-col-12 p-md-12 p-xl-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerHomeComponent.AudienceHeader}}</h2>
      </div>
      <div class="p-col-12">
        <p-card>
          <div class="p-grid">
            <div class="p-col-12 p-xl-4" style="margin: 1em;">
              <div *ngIf="!changeGenderDistributionFlag">
                <p class="card-p" *ngIf="globalService.streamer.hasGenderDemographics()">
                  {{this.i18nService.localize().StreamerHomeComponent.GenderHeaderText}}</p>
                <p class="card-p" *ngIf="!globalService.streamer.hasGenderDemographics()">
                  {{this.i18nService.localize().StreamerHomeComponent.GenderHint}}</p>
                <div style="width: 50%;margin: 0 auto;">
                  <p-chart *ngIf="globalService.streamer.hasGenderDemographics()" type="pie"
                           [data]="genderData"></p-chart>
                </div>
              </div>
              <div *ngIf="changeGenderDistributionFlag">
                <p class="card-p">{{this.i18nService.localize().StreamerHomeComponent.GenderChangeHint}}</p>
                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="genderCheck" value="tmpPercGenderDivers" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.GenderDivers}}"
                                [(ngModel)]="selectedGenderValues"></p-checkbox>
                    <span class="value-span">{{tmpPercGenderDivers}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPercGenderDivers"
                            (onChange)="updateGenderSlider('tmpPercGenderDivers')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="genderCheck" value="tmpPercGenderMen" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.GenderMale}}"
                                [(ngModel)]="selectedGenderValues"></p-checkbox>
                    <span class="value-span">{{tmpPercGenderMen}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPercGenderMen"
                            (onChange)="updateGenderSlider('tmpPercGenderMen')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="genderCheck" value="tmpPercGenderWomen" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.GenderFemale}}"
                                [(ngModel)]="selectedGenderValues"></p-checkbox>
                    <span class="value-span">{{tmpPercGenderWomen}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPercGenderWomen"
                            (onChange)="updateGenderSlider('tmpPercGenderWomen')"></p-slider>
                </div>
              </div>
              <div class="p-card-footer">
                <button pButton pRipple type="button" (click)="changeGenderDistribution()" icon="fas fa-pencil-alt"
                        *ngIf="!changeGenderDistributionFlag" class="p-button-rounded"
                        style="margin-right: 1em"></button>
                <button pButton pRipple type="button" (click)="saveGenderDistributionChanges()" icon="fas fa-check"
                        *ngIf="changeGenderDistributionFlag" class="p-button-rounded"
                        style="margin-right: 0.25em"></button>
                <button pButton pRipple type="button" (click)="abortGenderDistributionChanges()" icon="fas fa-times"
                        *ngIf="changeGenderDistributionFlag" class="p-button-rounded p-button-secondary"
                        style="margin-right: 1em"></button>
              </div>
            </div>

            <div class="vertical-border"></div>

            <div class="p-col-12 p-xl-4" style="margin: 1em;">
              <div *ngIf="!changeAgeDistributionFlag">
                <p class="card-p" *ngIf="globalService.streamer.hasAgeDemographics()">
                  {{this.i18nService.localize().StreamerHomeComponent.AgeHeaderText}}</p>
                <p class="card-p" *ngIf="!globalService.streamer.hasAgeDemographics()">
                  {{this.i18nService.localize().StreamerHomeComponent.AgeHint}}</p>
                <p-chart *ngIf="globalService.streamer.hasAgeDemographics()" type="bar" [data]="ageData"
                         [options]="ageOptions"></p-chart>
              </div>
              <div *ngIf="changeAgeDistributionFlag">
                <p class="card-p">{{this.i18nService.localize().StreamerHomeComponent.AgeChangeHint}}</p>
                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="ageCheck" value="tmpPercLessThen18" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.AgeUnder18}}"
                                [(ngModel)]="selectedAgeValues"></p-checkbox>
                    <span class="value-span">{{tmpPercLessThen18}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPercLessThen18"
                            (onChange)="updateAgeSlider('tmpPercLessThen18')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="ageCheck" value="tmpPerc18to24" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.Age18To24}}"
                                [(ngModel)]="selectedAgeValues"></p-checkbox>
                    <span class="value-span">{{tmpPerc18to24}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPerc18to24" (onChange)="updateAgeSlider('tmpPerc18to24')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="ageCheck" value="tmpPerc25to34" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.Age25To34}}"
                                [(ngModel)]="selectedAgeValues"></p-checkbox>
                    <span class="value-span">{{tmpPerc25to34}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPerc25to34" (onChange)="updateAgeSlider('tmpPerc25to34')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="ageCheck" value="tmpPerc35to44" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.Age35To44}}"
                                [(ngModel)]="selectedAgeValues"></p-checkbox>
                    <span class="value-span">{{tmpPerc35to44}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPerc35to44" (onChange)="updateAgeSlider('tmpPerc35to44')"></p-slider>
                </div>

                <div class="slider">
                  <div class="slider">
                    <p-checkbox name="ageCheck" value="tmpPercOlderThen44" class="checkbox-slider"
                                label="{{this.i18nService.localize().StreamerHomeComponent.AgeOver44}}"
                                [(ngModel)]="selectedAgeValues"></p-checkbox>
                    <span class="value-span">{{tmpPercOlderThen44}}</span>
                  </div>
                  <p-slider [(ngModel)]="tmpPercOlderThen44"
                            (onChange)="updateAgeSlider('tmpPercOlderThen44')"></p-slider>
                </div>
              </div>
              <div class="p-card-footer">
                <button pButton pRipple type="button" (click)="changeAgeDistribution()"
                        *ngIf="!changeAgeDistributionFlag" icon="fas fa-pencil-alt" class="p-button-rounded"
                        style="margin-right: 1em"></button>
                <button pButton pRipple type="button" (click)="saveAgeDistributionChanges()"
                        *ngIf="changeAgeDistributionFlag" icon="fas fa-check" class="p-button-rounded"
                        style="margin-right: 0.25em"></button>
                <button pButton pRipple type="button" (click)="abortAgeDistributionChanges()"
                        *ngIf="changeAgeDistributionFlag" icon="fas fa-times"
                        class="p-button-rounded p-button-secondary"
                        style="margin-right: 1em"></button>
              </div>
            </div>

            <div class="vertical-border"></div>

            <div class="p-col-12 p-xl-3" style="margin: 1em;">
              <div>
                <p class="card-p" *ngIf="globalService.streamer.hasCountries()">
                  {{this.i18nService.localize().StreamerHomeComponent.CountriesHeaderText}}</p>
                <p class="card-p" *ngIf="!globalService.streamer.hasCountries()">
                  {{this.i18nService.localize().StreamerHomeComponent.CountriesHint}}</p>
                <div *ngIf="!changeCountries">
                  <p-tag *ngFor="let country of this.globalService.streamer.demographyInfo.countries"
                         value="{{country}}" [rounded]="true"></p-tag>
                </div>
                <div *ngIf="changeCountries">
                  <ng-multiselect-dropdown3 [settings]="dropdownSettings"
                                            [placeholder]="this.i18nService.localize().StreamerHomeComponent.Countries"
                                            [data]="countriesDropDownList" [(ngModel)]="selectedCountryItems">
                  </ng-multiselect-dropdown3>
                </div>
              </div>
              <div style="margin-top: 1em; text-align: right">
                <button pButton pRipple type="button" (click)="changeCountry()" icon="fas fa-pencil-alt"
                        *ngIf="!changeCountries" class="p-button-rounded" style="margin-right: 1em;"></button>
                <button pButton pRipple type="button" (click)="saveCountryChanges()" icon="fas fa-check"
                        *ngIf="changeCountries" class="p-button-rounded" style="margin-right: .25em;"></button>
                <button pButton pRipple type="button" (click)="abortCountryChanges()" icon="fas fa-times"
                        *ngIf="changeCountries" class="p-button-rounded p-button-secondary"
                        style="margin-right: 1em"></button>
              </div>
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 abstand">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerHomeComponent.ViewsTable}}</h2>
      </div>

      <div class="p-col-12">
        <div class="tab-menu">
          <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="activateTab(1)">
            <span class="p-menuitem-text">{{this.i18nService.localize().StreamerHomeComponent.MaxViewsChart}}</span>
          </div>
          <div [class]="this.activeTab===2 ? 'tab tab-selected' : 'tab'" (click)="activateTab(2)">
            <span class="p-menuitem-text">{{this.i18nService.localize().StreamerHomeComponent.AverageViewsChart}}</span>
          </div>
          <div [class]="this.activeTab===3 ? 'tab tab-selected' : 'tab'" (click)="activateTab(3)">
            <span class="p-menuitem-text">{{this.i18nService.localize().StreamerHomeComponent.StreamTimeChart}}</span>
          </div>
          <div [class]="this.activeTab===5 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="activateTab(5)">
            <span class="p-menuitem-text">{{this.i18nService.localize().StreamerHomeComponent.StreamGameChart}}</span>
          </div>
        </div>
        <div class="tab-card">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="card">
                <div style="position: relative; overflow: hidden;"
                    [ngStyle]=" { 'padding-bottom': activeTab != 5 ? '50%' : '0' }"
                     *ngIf="chartData != undefined"> <!-- /* 4:3 ratio */-->
                  <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 20px;">
                    <p-chart type="line" [data]="chartData" [options]="options" *ngIf="activeTab != 5"></p-chart>
                  </div>
                  <div *ngIf="activeTab == 5">
                    <p-table [value]="chartData" responsiveLayout="scroll">
                      <ng-template pTemplate="header">
                        <tr>
                          <th
                            class="p-text-center tableTopLeft">{{this.i18nService.localize().StreamerHomeComponent.StreamGamePicColumn}}</th>
                          <th
                            class="p-text-center">{{this.i18nService.localize().StreamerHomeComponent.StreamGameColumn}}</th>
                          <th
                            class="p-text-center">{{this.i18nService.localize().StreamerHomeComponent.StreamTimeColumn}}</th>
                          <th
                            class="p-text-center">{{this.i18nService.localize().StreamerHomeComponent.StreamGameCategoryColumn}}</th>
                          <th
                            class="p-text-center tableTopRight">{{this.i18nService.localize().StreamerHomeComponent.StreamViewsColumn}}</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-game>
                        <tr style="text-align: left">
                          <td *ngIf="game.game_box_arts">
                            <img *ngFor="let game_box_art of game.game_box_arts"
                                 [src]="game_box_art"
                                 alt="game"
                                 style="width: 50px; height: 75px; margin-right: 10px">
                          </td>
                          <td>{{game.game_names}}</td>
                          <td>{{game.start_date}}</td>
                          <td>{{game.game_keywords}}</td>
                          <td>{{game.views_accounted}}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <p *ngIf="chartData == undefined">
                  {{this.i18nService.localize().StreamerHomeComponent.NoViewsTable}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerHomeComponent.ProfileHeader}}</h2>
      </div>
      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <img src="{{this.globalService.streamer.profile_image_url}}" class="img-rounded" alt="avatar">
      </div>
      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <p-card [style]="{'height':'100%'}">
          <h2 class="profile-headline">{{this.i18nService.localize().StreamerHomeComponent.LoginDataHeader}}</h2>
          <div style="margin: 1em;">
            <div *ngIf="!changeEmail">
              <p class="font-weight-bold">{{this.i18nService.localize().StreamerHomeComponent.Username}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.username}}</p>
              <p class="font-weight-bold">{{this.i18nService.localize().StreamerHomeComponent.Email}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.email}}</p>
              <p class="info" *ngIf="emailChanged">
                {{this.i18nService.localize().StreamerHomeComponent.EmailChangeHint1}}<br/>
                {{this.i18nService.localize().StreamerHomeComponent.EmailChangeHint2}}
              </p>
            </div>
            <div *ngIf="changeEmail">
              <div class="p-fluid">
                <div class="p-field">
                  <label for="inputEmail">{{this.i18nService.localize().StreamerHomeComponent.Email}}:</label>
                  <input type="text" id="inputEmail" [(ngModel)]="inputEmail" [disabled]="disableInputFirstname"
                         pInputText>
                  <p class="error" *ngIf="wrongEmail">{{this.i18nService.localize().StreamerHomeComponent.EmailError}}
                  </p>
                  <!--            <p class="info">Deine Email wird erst geändert wenn du den Aktivierungs-Link geklickt hast.</p>-->
                </div>
              </div>
            </div>
          </div>
          <ng-template pTemplate="footer">
            <button pButton pRipple type="button" (click)="changeEmailAddress()" icon="fas fa-pencil-alt"
                    *ngIf="!changeEmail" style="margin-right: .25em" class="p-button-rounded"></button>
            <button pButton pRipple type="button" (click)="saveEmailChange()" icon="fas fa-check" *ngIf="changeEmail"
                    style="margin-right: .25em" class="p-button-rounded"></button>
            <button pButton pRipple type="button" (click)="abortEmailChange()" icon="fas fa-times" *ngIf="changeEmail"
                    class="p-button-rounded p-button-secondary"></button>
          </ng-template>
        </p-card>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <p-card [style]="{'height':'100%'}">
          <h2 class="profile-headline">{{this.i18nService.localize().StreamerHomeComponent.PersonalInfoHeader}}</h2>
          <div style="margin: 1em;">
            <div *ngIf="!changeProfile" class="p-grid">
              <div class="p-col-12">
                <p class="font-weight-bold-without-margin">
                  {{this.i18nService.localize().StreamerHomeComponent.Name}}
                </p>
                <p *ngIf="this.globalService.streamer.firstname || this.globalService.streamer.lastname"
                   style="margin: 0">{{this.globalService.streamer.firstname}} {{this.globalService.streamer.lastname}}
                </p>
                <p *ngIf="!this.globalService.streamer.firstname && !this.globalService.streamer.lastname"
                   style="margin: 0"> &nbsp; </p>
              </div>
              <div class="p-col-12" style="padding: 0.5rem 0.5rem 0 0.5rem !important;">
                <p class="font-weight-bold-without-margin">
                  {{this.i18nService.localize().StreamerHomeComponent.Birthday}}
                </p>
                <p *ngIf="this.globalService.streamer.birthday" style="margin: 0">
                  {{this.globalService.streamer.birthday}}</p>
                <p *ngIf="!this.globalService.streamer.birthday" style="margin: 0">
                  {{this.i18nService.localize().StreamerHomeComponent.NoBirthdayHint}}
                </p>
              </div>
              <div class="p-col-12" style="padding: 0.5rem 0.5rem 0 0.5rem !important;">
                <p class="font-weight-bold-without-margin">
                  {{this.i18nService.localize().StreamerHomeComponent.Gender}}
                </p>
                <p style="margin: 0">{{this.globalService.streamer.getGender()}}</p>
              </div>
            </div>
            <div *ngIf="changeProfile">
              <div class="p-fluid">
                <div class="p-field">
                  <label for="inputFirstname">{{this.i18nService.localize().StreamerHomeComponent.Firstname}}:</label>
                  <input type="text" id="inputFirstname" [(ngModel)]="inputFirstname" [disabled]="disableInputFirstname"
                         pInputText>
                </div>

                <div class="p-field">
                  <label for="inputLastname">{{this.i18nService.localize().StreamerHomeComponent.Lastname}}:</label>
                  <input type="text" id="inputLastname" [(ngModel)]="inputLastname" [disabled]="disableInputLastname"
                         pInputText>
                </div>

                <div class="p-field">
                  <label for="inputLastname">{{this.i18nService.localize().StreamerHomeComponent.Birthday}}:</label>
                  <p-calendar [(ngModel)]="inputBirthday" [minDate]="minDate" [maxDate]="maxDate" dateFormat="dd.mm.yy"
                              [readonlyInput]="true">
                  </p-calendar>
                </div>

                <div class="p-field">
                  <label for="gender">{{i18nService.localize().StreamerHomeComponent.Gender}}*:</label>
                  <select [(ngModel)]="inputGender" id="gender"
                          class="p-inputtext p-component p-element ng-dirty ng-touched">
                    <option value="male">{{this.i18nService.localize().StreamerHomeComponent.GenderMale}}</option>
                    <option value="female">{{this.i18nService.localize().StreamerHomeComponent.GenderFemale}}</option>
                    <option value="divers">{{this.i18nService.localize().StreamerHomeComponent.GenderDivers}}</option>
                  </select>
                </div>

                <p class="error" *ngIf="nameDialogError=='Phantom Warrior'">
                  {{this.i18nService.localize().StreamerHomeComponent.FillEverythingError}}</p>
                <p class="error" *ngIf="nameDialogError=='Eidolon'">
                  {{this.i18nService.localize().StreamerHomeComponent.Under18Error}}</p>
                <p class="error" *ngIf="nameDialogError=='Dwarf'">
                  {{this.i18nService.localize().StreamerHomeComponent.UnkownError}}</p>
              </div>
            </div>
          </div>
          <p-footer>
            <button pButton pRipple type="button" class="p-button-rounded p-mr-2"
                    pTooltip="{{i18nService.localize().AdvertiserProfileComponent.DownloadProfileInfos}}"
                    *ngIf="!changeProfile"
                    icon="fas fa-download" tooltipPosition="left" (click)="downloadProfileInformation()">
            </button>
            <button pButton pRipple type="button" (click)="changeProfileInfo()" icon="fas fa-pencil-alt"
                    *ngIf="!changeProfile" style="margin-right: .25em" class="p-button-rounded"></button>
            <button pButton pRipple type="button" (click)="saveProfileChanges()" icon="fas fa-check"
                    *ngIf="changeProfile" style="margin-right: .25em" class="p-button-rounded"></button>
            <button pButton pRipple type="button" (click)="abortProfileChanges()" icon="fas fa-times"
                    *ngIf="changeProfile" class="p-button-rounded p-button-secondary"></button>
          </p-footer>
        </p-card>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <p-card [style]="{'height':'100%'}">
          <h2 class="profile-headline">{{this.i18nService.localize().StreamerHomeComponent.AddressHeader}}</h2>
          <div style="margin: 1em;">
            <div *ngIf="this.globalService.streamer.hasAddress() && !changeAddress">
              <p class="font-weight-bold">{{this.i18nService.localize().StreamerHomeComponent.StreetNr}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.street}} {{this.globalService.streamer.nr}}</p>
              <p class="font-weight-bold">{{this.i18nService.localize().StreamerHomeComponent.City}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.postal_code}} {{this.globalService.streamer.city}}
              </p>
            </div>
            <div *ngIf="!this.globalService.streamer.hasAddress() && !changeAddress">
              <p class="font-weight-bold">{{this.i18nService.localize().StreamerHomeComponent.AddressHint}}</p>
            </div>
            <div *ngIf="changeAddress">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-9">
                  <label for="street">{{this.i18nService.localize().StreamerHomeComponent.Street}}:</label>
                  <input id="street" type="text" pInputText [(ngModel)]="streetEdit"
                         (keydown)="accept_alphabet_and_dash($event)">
                </div>
                <div class="p-field p-col-3">
                  <label for="nr">{{this.i18nService.localize().StreamerHomeComponent.Nr}}.:</label>
                  <input id="nr" type="text" pInputText [(ngModel)]="nrEdit">
                </div>
                <div class="p-field p-col-12">
                  <label for="postalCode">{{this.i18nService.localize().StreamerHomeComponent.PostalCode}}:</label>
                  <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCodeEdit"
                         class="field">
                </div>
                <div class="p-field p-col-12">
                  <label for="city">{{this.i18nService.localize().StreamerHomeComponent.City}}:</label>
                  <input id="city" type="text" pInputText [(ngModel)]="cityEdit"
                         (keydown)="accept_alphabet_and_dash($event)">
                </div>

                <div class="p-col-12">
                  <p class="error" *ngIf="addressDialogError=='Phantom Warrior'">
                    {{this.i18nService.localize().StreamerHomeComponent.FillEverythingError}}</p>
                </div>
              </div>
            </div>
          </div>
          <p-footer>
            <button pButton pRipple type="button" (click)="changeAddressInfo()" icon="fas fa-pencil-alt"
                    *ngIf="!changeAddress" class="p-button-rounded" style="margin-right: .25em"></button>
            <button pButton pRipple type="button" (click)="saveAddressChanges()" icon="fas fa-check"
                    *ngIf="changeAddress" class="p-button-rounded" style="margin-right: .25em"></button>
            <button pButton pRipple type="button" (click)="abortAddressChanges()" icon="fas fa-times"
                    *ngIf="changeAddress" class="p-button-rounded p-button-secondary"></button>
          </p-footer>
        </p-card>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <p-card [style]="{'height':'100%'}">
          <h2 class="profile-headline-2">{{this.i18nService.localize().StreamerHomeComponent.PaymentHeader}}</h2>
          <div style="margin: 1em;">
            <div *ngIf="!changePayment && this.globalService.streamer.hasPaymentInfo()">
              <p class="font-weight-bold" *ngIf="this.globalService.streamer.paypal">
                {{this.i18nService.localize().StreamerHomeComponent.PaypalAdress}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.paypal}}</p>
              <p class="font-weight-bold" *ngIf="this.globalService.streamer.taxNr">
                {{this.i18nService.localize().StreamerHomeComponent.TaxNr}}</p>
              <p style="margin-top: 0">{{this.globalService.streamer.taxNr}}</p>
            </div>
            <div *ngIf="!this.globalService.streamer.hasPaymentInfo() && !changePayment">
              <p class="font-weight-bold" style="word-break: break-word;">
                {{this.i18nService.localize().StreamerHomeComponent.PaymentHint}}</p>
            </div>
            <div *ngIf="changePayment">
              <div class="p-fluid">
                <div class="p-field">
                  <label for="paypal">{{this.i18nService.localize().StreamerHomeComponent.PaypalAdress}}:</label>
                  <input id="paypal" type="email" pInputText [(ngModel)]="paypalEdit" (input)="checkEmailValidity()">
                </div>
                <div class="p-field">
                  <label for="tax">{{this.i18nService.localize().StreamerHomeComponent.TaxNr}}.:</label>
                  <input id="tax" type="text" pInputText [(ngModel)]="taxEdit">
                </div>
              </div>

              <p class="error" *ngIf="paymentDialogError=='Phantom Warrior'">
                {{this.i18nService.localize().StreamerHomeComponent.FillEverythingError}}</p>
            </div>
          </div>
          <p-footer>
            <button pButton pRipple type="button" (click)="changePaymentInfo()" icon="fas fa-pencil-alt"
                    *ngIf="!changePayment" class="p-button-rounded" style="margin-right: .25em"></button>
            <button pButton pRipple type="button" (click)="savePaymentChanges()" icon="fas fa-check"
                    *ngIf="changePayment" class="p-button-rounded" style="margin-right: .25em"
                    [disabled]="!isEmailValid"></button>
            <button pButton pRipple type="button" (click)="abortPaymentChanges()" icon="fas fa-times"
                    *ngIf="changePayment" class="p-button-rounded p-button-secondary"></button>
          </p-footer>
        </p-card>
      </div>

      <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
        <!--        <p class="status-headline">DEIN DERZEITIGER STATUS:</p>-->
        <p-card [style]="{'height':'100%'}">
          <ng-template pTemplate="header">
            <img src="assets/images/bronze_2.png" alt="Bronze"
                 style="border-top-left-radius: 40px;border-top-right-radius: 40px;"
                 *ngIf="globalService.streamer.tier==1">
            <img src="assets/images/silber_2.png" alt="Silver"
                 style="border-top-left-radius: 40px;border-top-right-radius: 40px;"
                 *ngIf="globalService.streamer.tier==2">
            <img src="assets/images/gold_2.png" alt="Gold"
                 style="border-top-left-radius: 40px;border-top-right-radius: 40px;"
                 *ngIf="globalService.streamer.tier==3">
          </ng-template>
          <div *ngIf="this.globalService.streamer.streamerPoints">
            <p class="font-weight-bold "
               style="margin-top: 0"> {{this.i18nService.localize().StreamerHomeComponent.StreamerPoints}} </p>
            <p style="margin-top: 0"> {{this.globalService.streamer.streamerPoints}} </p>
          </div>
          <div class="status-body"
               *ngIf="(globalService.streamer.outstandingUpgradeRequest||this.globalService.streamer.tierChangeDays > 0)&&globalService.streamer.tier<3">
            <p style="margin-top: 0;" *ngIf="this.globalService.streamer.tierChangeDays > 0">
              {{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeHint1}}
              <b>{{this.globalService.streamer.tierChangeDays}}</b>{{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeHint2}}
            </p>
            <p style="margin-top: 0;"
               *ngIf="this.globalService.streamer.outstandingUpgradeRequest&&!this.globalService.streamer.reason">
              {{this.i18nService.localize().StreamerHomeComponent.StatusCheckHint}}
            </p>
            <p style="margin-top: 1em; color:#fbc02d; font-weight: bolder"
               *ngIf="this.globalService.streamer.outstandingUpgradeRequest&&this.globalService.streamer.reason">
              {{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeDenied}}
            </p>
          </div>
          <div class="p-card-footer">
            <button pButton pRipple type="button" (click)="showInfoModalDialog()" icon="fas fa-info"
                    style="margin-right: .25em" class="p-button-rounded"
                    pTooltip="{{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeMoreInfoTooltip}}"
                    tooltipPosition="left"></button>
            <button pButton pRipple type="button" style="margin-right: .25em"
                    *ngIf="this.globalService.streamer.isTierUpgradeButtonVisible()"
                    [disabled]="this.globalService.streamer.isTierUpgradeButtonDisabled()"
                    pTooltip="{{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeTooltip}}"
                    tooltipPosition="left" (click)="requestTierUpgrade()" icon="fas fa-caret-square-up"
                    class="p-button-rounded"></button>
            <button pButton pRipple type="button"
                    *ngIf="this.globalService.streamer.outstandingUpgradeRequest&&this.globalService.streamer.reason&&globalService.streamer.tier<3"
                    (click)="showReasonModalDialog()" icon="far fa-comment"
                    pTooltip="{{this.i18nService.localize().StreamerHomeComponent.StatusUpgradeReasonTooltip}}"
                    tooltipPosition="left" class="p-button-rounded p-button-warning"></button>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-md-8 p-lg-6 p-xl-5 p-xxl-4" *ngIf="globalService.enableYoutubeAuth">
        <p-card [style]="{'height':'100%'}">
          <h2 class="profile-headline">{{this.i18nService.localize().StreamerHomeComponent.ConnectionsHeader}}</h2>
          <div>
            <p-table [value]="globalService.streamer.socialProfiles" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th class="tableTopLeft p-text-center">
                    {{this.i18nService.localize().StreamerHomeComponent.Network}}
                  </th>
                  <th class="p-text-center">
                    {{this.i18nService.localize().StreamerHomeComponent.ProfileName}}
                  </th>
                  <th class="tableTopRight p-text-center">
                    {{this.i18nService.localize().StreamerHomeComponent.Actions}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-profile>
                <tr>
                  <td>
                    <p class="{{profile.getNetworkName()}}">{{profile.getNetworkName()}}</p>
                  </td>
                  <td>{{profile.profileName}}</td>
                  <td>
                    <button pButton pRipple type="button" (click)="openRemoveConfirmationDialog(profile)"
                            icon="fas fa-trash-alt" style="margin-right: .25em" class="p-button-rounded"
                            pTooltip="{{this.i18nService.localize().StreamerHomeComponent.RemoveConnectionTooltip}}"
                            tooltipPosition="left" [disabled]="globalService.streamer.socialProfiles.length===1">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <p *ngIf="addProfileError" class="profile-error">
              {{this.i18nService.localize().StreamerHomeComponent.ConnectionRightsError}}<br><br>
              {{this.i18nService.localize().StreamerHomeComponent.ConnectionRightsError2}}
              <a href="https://myaccount.google.com/permissions">Google Permissions</a>
            </p>
          </div>
          <div class="p-card-footer" *ngIf="globalService.streamer.socialProfiles.length<2">
            <button pRipple pButton (click)="loginWithGoogle()" label="Youtube Profil hinzufügen"
                    class="p-button-rounded" style="background-color: #ff0000; border-color: #ff0000;"
                    *ngIf="globalService.streamer.socialProfiles.length === 1 && getTheOtherNetwork() === 'Google'">
            </button>

            <button pRipple pButton (click)="loginWithTwitch()" label="Twitch Profil hinzufügen"
                    class="p-button-rounded" style="background-color: #a970ff; border-color: #a970ff;"
                    *ngIf="globalService.streamer.socialProfiles.length === 1 && getTheOtherNetwork() === 'Twitch'">
            </button>
          </div>
        </p-card>

        <p-dialog [(visible)]="showRemoveConfirmModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
          <ng-template pTemplate="header">
            <div class="headline">{{this.i18nService.localize().StreamerHomeComponent.RemoveConnectionConfirmQuestion}}
            </div>
          </ng-template>
          <p>{{this.i18nService.localize().StreamerHomeComponent.RemoveConnectionConfirmText}}</p>
          <div style="text-align: right">
            <button pButton pRipple type="button" icon="fas fa-check" class="p-button-rounded"
                    (click)="removeConnection()"></button>
            <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                    class="p-button-rounded p-button-secondary" (click)="this.showRemoveConfirmModal=false"></button>
          </div>
        </p-dialog>
      </div>

      <streamer-status-dialog [show]="moreStatusInfoModal"
                              (showChange)="moreStatusInfoModal=$event"></streamer-status-dialog>

      <p-dialog [(visible)]="showReasonModal" [style]="{width: '50vw'}" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{this.i18nService.localize().StreamerHomeComponent.ReasonHeader}}</div>
        </ng-template>
        <p>{{this.globalService.streamer.reason}}</p>
      </p-dialog>
    </div>

  </div>
</div>
