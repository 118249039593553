import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {AffiliateService} from '../../services/affiliate.service';
import {MathHelper} from '../../services/math.helper';
import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {I18nService} from '../../services/i18n.service';
import {AdvertiserService} from '../../services/advertiser.service';
import * as moment from 'moment';
import {PaymentService} from "../../services/paymentService";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'advertiser-affiliate-extend',
  templateUrl: './advertiser-affiliate-extend.component.html',
  styleUrls: ['./advertiser-affiliate-extend.component.scss']
})
export class AdvertiserAffiliateExtendComponent implements OnInit {

  @Input()
  public affiliateId!: string;

  @Input()
  public sessionId?: string;

  @Input()
  public advertiserId!: string;

  @Input()
  public affiliateName!: string;

  @Output()
  public closeEmit = new EventEmitter<void>();

  public showExtendCampaign = false;
  public newStartDate!: Date;
  public newEndDate!: Date;
  public daysBetween: number = 0;
  public current: Date = new Date();
  public minDate: Date = new Date(this.current.getTime() + 259200000);
  public billNr!: string;
  public payPalConfig?: IPayPalConfig;
  public paymentIndex: any = 0;
  public paypalPaymentDone: boolean = false;
  public paymentError: boolean = false;
  public NoBudgetError: boolean = false;

  public budgetSelect: string = 'total';

  public calculationTimeOut: any;
  public isInCalculation: boolean = true;
  public totalBudget: number = 0;
  public totalBudgetString: string = '0';
  public dailyBudget: number = 0;
  public dailyBudgetString: string = '0';
  public disableTotalBudget: boolean = false;
  public disableDailyBudget: boolean = true;
  public totalBudgetWithTaxForPaypal: string = '0';
  public totalBudgetWithTaxString: string = '0';
  public totalBudgetWithTax: number = 0;
  public tooFewDaysError: boolean = false;

  public initialClicks: number = 0;
  public initialDailyClicks: number = 0;

  public paymentSuccess!: boolean;
  public showPaymentInfoPage: boolean = false;

  constructor(public globalService: GlobalService, public affiliateService: AffiliateService, public route: ActivatedRoute,
              public mathHelper: MathHelper, public i18nService: I18nService, public router: Router,
              public advertiserService: AdvertiserService, private paymentService: PaymentService) {
  }

  async ngOnInit() {
    if (this.globalService.advertiser || this.globalService.agency) {
      await this.affiliateService.getAffiliateInfoForExtension(this.affiliateId).then(result => {
        // this.initialBudget = result.initial_budget;
        // this.initialClicks = result.initial_clicks;
        // this.initialDailyClicks = result.initial_daily_clicks
        this.generateNumber();
      });

      if (this.sessionId) {
        this.newStartDate = new Date(this.route.snapshot.queryParams['startDate']);
        this.newEndDate = new Date(this.route.snapshot.queryParams['endDate']);
        this.totalBudget = this.route.snapshot.queryParams['totalBudget'];

        if (this.newStartDate && this.newEndDate && this.totalBudget) {
          this.updateDaysBetween();
          this.paymentService.fulfillPayment(this.sessionId, () => {
            this.extendAffiliate();
            this.paymentSuccess = true;
          });
          this.showPaymentInfoPage = true;
        }
      }
    }
  }

  generateNumber() {
    if (this.globalService.advertiser || this.globalService.agency) {
      this.advertiserService.getNextBillNumber().then(result => {
        let today = new Date();
        if (this.globalService.advertiser) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.advertiser.advertiserNr + '-' + result;
        } else if (this.globalService.agency) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.agency.agencyNr + '-' + result;
        }
      });
    }
  }

  extendAffiliate() {
    this.affiliateService.saveAffiliateExtension(this.affiliateId, this.billNr, this.initialClicks,
      this.paypalPaymentDone, this.initialDailyClicks, this.newStartDate, this.totalBudget, this.advertiserId).then(result => {
    });
  }

  public checkAffiliateExtend(): boolean {
    if (this.paymentIndex == 1) {
      return true;
    } else {
      return false;
    }
  }

  public deactivateButton(): boolean {
    if (this.totalBudget > 0 && this.dailyBudget > 0 && this.daysBetween > 2) {
      return false;
    } else {
      return true;
    }
  }

  updateBudgetInputsTotal() {
    this.disableTotalBudget = false;
    this.disableDailyBudget = true;
  }

  updateBudgetInputsDaily() {
    this.disableTotalBudget = true;
    this.disableDailyBudget = false;
  }

  public isTaxNeeded(): boolean {
    if (this.globalService.advertiser) {
      return this.globalService.advertiser.isTaxNeeded();
    } else if (this.globalService.agency) {
      return this.globalService.agency.isTaxNeeded();
    } else {
      return true;
    }
  }

  updateDaysBetween() {
    let millisecPerDay = 1000 * 3600 * 24;

    if (this.newStartDate && this.newEndDate) {
      this.newStartDate.setHours(0);
      this.newStartDate.setMinutes(0);
      this.newStartDate.setSeconds(0);
      this.newStartDate.setMilliseconds(0);

      this.newEndDate.setHours(0);
      this.newEndDate.setMinutes(0);
      this.newEndDate.setSeconds(0);
      this.newEndDate.setMilliseconds(0);

      const differenceInTime = this.newEndDate.getTime() - this.newStartDate.getTime();
      this.daysBetween = this.mathHelper.precisionRound((differenceInTime / millisecPerDay) + 1, 0);

      if (this.daysBetween <= 2) {
        this.tooFewDaysError = true;
      } else {
        this.tooFewDaysError = false;

        // console.log(this.startDate+' - '+this.endDate+' / '+differenceInTime+ ' / '+this.daysBetween);
        this.updateBudgets();
      }
    } else {
      this.tooFewDaysError = true;
      this.daysBetween = 0;
    }
  }

  updateBudgets() {
    this.isInCalculation = true;
    clearTimeout(this.calculationTimeOut);
    if (this.disableDailyBudget) {
      if (this.totalBudget > 0 && this.daysBetween > 0) {
        this.dailyBudget = this.mathHelper.precisionRound(this.totalBudget / this.daysBetween, 2);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);

        this.calculateBudgetWithTax();
        this.calculateClicks();
      }
    } else {
      if (this.dailyBudget > 0 && this.daysBetween > 0) {
        this.totalBudget = this.mathHelper.precisionRound(this.daysBetween * this.dailyBudget, 2);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);

        this.calculateBudgetWithTax();
        this.calculateClicks();
      }
    }
    this.calculationTimeOut = setTimeout(() => {
      this.isInCalculation = false;
    }, 3000);
  }

  calculateBudgetWithTax() {
    this.totalBudgetWithTax = this.totalBudget;

    if (this.globalService.advertiser || this.globalService.agency) {
      if (this.isTaxNeeded()) {
        this.totalBudgetWithTax = this.totalBudget + (this.totalBudget * 0.19);
      }
    }

    this.totalBudgetWithTaxString = this.mathHelper.numberWithCommas(this.totalBudgetWithTax);
    this.totalBudgetWithTaxForPaypal = this.totalBudgetWithTax.toString();
  }

  public calculateClicks() {
    this.initialClicks = this.mathHelper.precisionRound(this.totalBudget / 0.8, 0);

    if (this.daysBetween > 0) {
      this.initialDailyClicks = this.mathHelper.precisionRound(this.initialClicks / this.daysBetween, 0);
    }
  }

  // public updateEndDate() {
  //   var tempDate = new Date(this.newStartDate.getTime());
  //   var days = this.mathHelper.precisionRound(this.initialClicks / this.initialDailyClicks, 0);
  //   this.newEndDate = this.addDays(tempDate, days);
  // }
  //
  // private addDays(date: Date, days: number): Date {
  //   date.setDate(date.getDate() + days);
  //   return date;
  // }
  //
  // public endDateAsString(): string {
  //   return moment(this.newEndDate).format('DD.MM.yyyy');
  // }
  //
  // public getBudget() :string {
  //   var budgetWithTax;
  //   budgetWithTax = Number(this.initialBudget);
  //
  //   if (this.globalService.advertiser || this.globalService.agency) {
  //     if (this.isTaxNeeded()) {
  //       budgetWithTax = Number(this.initialBudget) + (Number(this.initialBudget) * 0.19);
  //     }
  //   }
  //
  //   return budgetWithTax.toString();
  // }

  back() {
    this.closeEmit.emit()
  }


}
