<img src="assets/images/new_login_background.png" id="background" alt="">
<div class="p-grid p-ai-center vertical-container">
  <div class="p-col"></div>
  <div class="p-col-12 p-md-5 p-lg-4 foreground">
    <div style="text-align: center;">
      <img src="assets/images/new_login_logo.png" alt="streamboost" class="streamboostLogo">
    </div>
    <div class="tab-menu">
      <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="this.activeTab=1">
        <span class="p-menuitem-text">BRAND</span>
      </div>
      <div [class]="this.activeTab===2 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="this.activeTab=2">
        <span class="p-menuitem-text">{{i18nService.localize().AgencyRegisterComponent.AgenciesRegister}}</span>
      </div>
    </div>
    <div class="tab-card">

      <!-- Advertiser Login -->
      <div class="p-grid" style="padding: 2em 2em 1em 2em;" *ngIf="this.activeTab === 1">
        <div class="p-col-12">
          <h1 class="login-headline">LOGIN</h1>
        </div>
        <div class="p-col-12 content">
          <input type="email" id="inputEmail" [(ngModel)]="email" class="text-fields"
                 placeholder="{{i18nService.localize().AdvertiserLoginComponent.EmailPlaceholder}}"
                 required="" autofocus="" pInputText>
        </div>
        <div class="p-col-12 content">
          <input type="password" id="inputPassword" [(ngModel)]="password" class="text-fields"
                 placeholder="{{i18nService.localize().AdvertiserLoginComponent.PasswordPlaceholder}}"
                 required="" pInputText>
        </div>
        <div class="p-col-12">
          <p-checkbox [(ngModel)]="checked" [binary]=true id="remember"></p-checkbox>
          <label for="remember" style="margin-left: 0.5em;">
            {{i18nService.localize().AdvertiserLoginComponent.RememberMe}}
          </label>
        </div>
        <div class="p-col-12 content">
          <p class="error" *ngIf="loginError=='Doppelganger' || loginError=='Hobgoblin'">
            {{i18nService.localize().AdvertiserLoginComponent.LoginError}}
            <a class="closeError" (click)="resetError()"><b>x</b></a>
          </p>
          <button pButton pRipple type="button" (click)="loginAdvertiser()" class="p-button-rounded login-btn"
                  label="{{i18nService.localize().AdvertiserLoginComponent.LoginButton}}"
                  [loading]=working>
          </button>
          <button pButton pRipple type="button" (click)="this.routingService.switchToPathWithReload('register')"
                  class="p-button-rounded register-btn"
                  label="{{i18nService.localize().AdvertiserLoginComponent.RegisterButton}}">
          </button>
        </div>
        <div class="p-col-12 content">
          <p class="textMuted">© 2020-2023</p>
        </div>
      </div>

      <!-- Agency Login -->
      <div class="p-grid" style="padding: 2em 2em 1em 2em;" *ngIf="this.activeTab === 2">
        <div class="p-col-12">
          <h1 class="login-headline">LOGIN</h1>
        </div>
        <div class="p-col-12 content">
          <input type="email" id="inputEmail2" [(ngModel)]="email" class="text-fields"
                 placeholder="{{i18nService.localize().AgencyLoginComponent.EmailPlaceholder}}"
                 required="" autofocus="" pInputText>
        </div>
        <div class="p-col-12 content">
          <input type="password" id="inputPassword2" [(ngModel)]="password" class="text-fields"
                 placeholder="{{i18nService.localize().AgencyLoginComponent.PasswordPlaceholder}}"
                 required="" pInputText>
        </div>
        <div class="p-col-12">
          <p-checkbox [(ngModel)]="checked" [binary]=true id="rememberAgency"></p-checkbox>
          <label for="remember" style="margin-left: 0.5em;">
            {{i18nService.localize().AdvertiserLoginComponent.RememberMe}}
          </label>
        </div>
        <div class="p-col-12 content">
          <p class="error" *ngIf="loginError=='Doppelganger' || loginError=='Hobgoblin'">
            {{i18nService.localize().AgencyLoginComponent.LoginError}}
            <a class="closeError" (click)="resetError()"><b>x</b></a>
          </p>
          <button pButton pRipple type="button" (click)="loginAgency()" class="p-button-rounded login-btn"
                  label="{{i18nService.localize().AgencyLoginComponent.LoginButton}}"
                  [loading]=working>
          </button>
          <button pButton pRipple type="button" (click)="this.routingService.switchToPathWithReload('register')"
                  class="p-button-rounded register-btn"
                  label="{{i18nService.localize().AdvertiserLoginComponent.RegisterButton}}">
          </button>
        </div>
        <div class="p-col-12 content">
          <p class="textMuted">© 2020-2023</p>
        </div>
      </div>

    </div>
  </div>
  <div class="p-col"></div>
</div>


