import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

declare var EventBus: any;

@Injectable()
export class StreamBoostEventBus {
  private eventBus: any = null;
  private serverUrl = environment.streamEventBusUrl;
  private streamBoostReconnectInterval: any;

  constructor(private cookieService: CookieService, private router: Router) {
    this.eventBus = new EventBus(this.serverUrl, {});
    this.streamBoostReconnectInterval = setInterval(() => {
      if (this.eventBus.state === 3) {
        console.log('Reconnect...');
        this.reconnect();
      }
    }, 1000);
  }

  sleep(time: any): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  send(eventbusUrl: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.eventBus.state !== 1) {
        reject('No EventBus!');
      }

      // Session Token an jeden Request hängen wenn einer da ist

      if (this.cookieService.get('streamboost-session')) {
        data.token = this.cookieService.get('streamboost-session');
        // console.log('using: '+data.token);
      }

      this.eventBus.send(eventbusUrl, data,
        (err: any, res: any) => {
          if (err) {
            reject(err);
          } else {
            if (res.body) {
              if (res.body.code) {
                if (res.body.code === 'Tarrasque') {
                  this.cookieService.delete('streamboost-session');
                  this.router.navigate(['/home']);
                }
              }
            }
            resolve(res);
          }
        }
      );
    });
  }

  reconnect(): void {
    this.eventBus = new EventBus(this.serverUrl);
  }

  registerHandler(address: any, headers: any, callback: any): void {
    // console.log("register");
    this.eventBus.registerHandler(address, headers, callback);
  }

  unregisterHandler(address: any, headers: any, callback: any): void {
    // console.log("unregister");
    this.eventBus.unregisterHandler(address, headers, callback);
  }

  isOnline(): boolean {
    if (this.eventBus.state === 1) {
      return true;
    }
    return false;
  }
}
