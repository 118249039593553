import { Injectable } from "@angular/core";
import { SuperService } from "./super.service";
import { StreamBoostEventBus } from "../streamboost-eventbus.service";
import { Competition } from "../model/streamer/competition.model";

@Injectable()
export class CompetitionService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {
    super(streamBoostEventBus);
  }

  public getCurrentCompetition(): Promise<any> {

    return new Promise((resolve, reject) => {

      // Affiliate für Kampagne speichern
      this.streamBoostEventBus.send('stream.web.facade.competition.current',
        {
          message: {
          }
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
              localStorage.clear();
              return;
            }

            let competition: Competition = new Competition();
            competition.id = response.body.response.competition._id;
            competition.name = response.body.response.competition.name;
            competition.description = response.body.response.competition.description;
            competition.link = response.body.response.competition.link;

            let logoId = response.body.response.competition.logo_id;
            competition.logoLink = '/picture/' + logoId;

            resolve(competition)
          }
        )
        .catch(
          (err: any) => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

}
