import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {Injectable} from '@angular/core';
import {Question} from '../model/streamer/streamer-question.model';
import {CampaignFeedback} from "../model/advertiser/campaign-feedback.model";
import {CampaignQuestion} from "../model/advertiser/campaign-question.model";

@Injectable()
export class FeedbackService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {
    super(streamBoostEventBus);
  }

  public saveFeedback(type: string, message: string, campaignId: string, affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.feedback.give',
          {
            message: {
              type,
              message,
              campaign_id:campaignId,
              affiliate_id: affiliateId
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getQuestionsOfCampaignOrAffiliateForStreamer(campaignId: string, affiliateId: string): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.feedback.for.streamer',
        {
          'message': {
            campaign_id: campaignId,
            affiliate_id: affiliateId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let questionsFromDB = response.body.response.streamerquestionfeedbacks;
          let questions: Question[] = [];
          for (let j = 0; j < questionsFromDB.length; j++) {
            let newsQuestion: Question = new Question();

            newsQuestion.id = questionsFromDB[j]._id;
            newsQuestion.message = questionsFromDB[j].message;
            newsQuestion.answer = questionsFromDB[j].answer;
            newsQuestion.status = questionsFromDB[j].status;
            newsQuestion.type = questionsFromDB[j].type;
            newsQuestion.reason = questionsFromDB[j].reason;

            questions.push(newsQuestion);
          }

          resolve(questions);
        });
    });

  }

  public getFeedbackOfAffiliate(first: number, rows: number, sortField: string,
                                sortOrder: number, affiliateId: string): Promise<CampaignFeedback[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.feedback.of.affiliate',
        {
          'message': {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder,
            affiliate_id: affiliateId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let feedbacksFromDB = response.body.response.streamerquestionfeedbacks;
          let feedbacks: CampaignFeedback[] = [];
          for (let j = 0; j < feedbacksFromDB.length; j++) {
            let newsFeedback: CampaignFeedback = new CampaignFeedback();

            newsFeedback.id = feedbacksFromDB[j]._id;
            newsFeedback.message = feedbacksFromDB[j].message;
            newsFeedback.feedbackDate = feedbacksFromDB[j].question_date;

            feedbacks.push(newsFeedback);
          }

          resolve(feedbacks);
        });
    });

  }

  public getMaxFeedbackOfAffiliate(affiliateId?: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.feedback.of.affiliate.max',
          {
            message: {
              affiliate_id: affiliateId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getQuestionsOfAffiliate(first: number, rows: number, sortField: string,
                                sortOrder: number, affiliateId: string): Promise<CampaignQuestion[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.questions.of.affiliate',
        {
          'message': {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder,
            affiliate_id: affiliateId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let questionsFromDB = response.body.response.streamerquestionfeedbacks;
          let questions: CampaignQuestion[] = [];
          for (let j = 0; j < questionsFromDB.length; j++) {
            let newsQuestion: CampaignQuestion = new CampaignQuestion();

            newsQuestion.id = questionsFromDB[j]._id;
            newsQuestion.message = questionsFromDB[j].message;
            newsQuestion.questionDate = questionsFromDB[j].question_date;
            newsQuestion.answerDate = questionsFromDB[j].answer_date;
            newsQuestion.answer = questionsFromDB[j].answer;

            questions.push(newsQuestion);
          }

          resolve(questions);
        });
    });
  }

  public getMaxQuestionsOfAffiliate(affiliateId: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.questions.of.affiliate.max',
          {
            message: {
              affiliate_id: affiliateId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public answerAffiliateStreamerQuestion(affiliateId: string, feedbackId: string, answer: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.question.affiliate.answer',
          {
            message: {
              affiliate_id: affiliateId,
              feedback_id: feedbackId,
              answer
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getFeedbackOfCampaign(first: number, rows: number, sortField: string,
                                sortOrder: number, campaignId: string): Promise<CampaignFeedback[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.feedback.of.campaign',
        {
          'message': {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder,
            campaign_id: campaignId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let feedbacksFromDB = response.body.response.streamerquestionfeedbacks;
          let feedbacks: CampaignFeedback[] = [];
          for (let j = 0; j < feedbacksFromDB.length; j++) {
            let newsFeedback: CampaignFeedback = new CampaignFeedback();

            newsFeedback.id = feedbacksFromDB[j]._id;
            newsFeedback.message = feedbacksFromDB[j].message;
            newsFeedback.feedbackDate = feedbacksFromDB[j].question_date;

            feedbacks.push(newsFeedback);
          }

          resolve(feedbacks);
        });
    });

  }

  public getMaxFeedbackOfCampaign(campaignId?: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.feedback.of.campaign.max',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getQuestionsOfCampaign(first: number, rows: number, sortField: string,
                                 sortOrder: number, campaignId: string): Promise<CampaignQuestion[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.questions.of.campaign',
        {
          'message': {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder,
            campaign_id: campaignId
          }
        })
        .then((response: any) => {
          if (response.body.code) {
            reject(response.body.code);
            return;
          }

          let questionsFromDB = response.body.response.streamerquestionfeedbacks;
          let questions: CampaignQuestion[] = [];
          for (let j = 0; j < questionsFromDB.length; j++) {
            let newsQuestion: CampaignQuestion = new CampaignQuestion();

            newsQuestion.id = questionsFromDB[j]._id;
            newsQuestion.message = questionsFromDB[j].message;
            newsQuestion.questionDate = questionsFromDB[j].question_date;
            newsQuestion.answerDate = questionsFromDB[j].answer_date;
            newsQuestion.answer = questionsFromDB[j].answer;

            questions.push(newsQuestion);
          }

          resolve(questions);
        });
    });
  }

  public getMaxQuestionsOfCampaign(campaignId: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.questions.of.campaign.max',
          {
            message: {
              campaign_id: campaignId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public answerCampaignStreamerQuestion(campaignId: string, feedbackId: string, answer: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.question.campaign.answer',
          {
            message: {
              campaign_id: campaignId,
              feedback_id: feedbackId,
              answer
            }
          })
          .then((result) => {
            {
              if (result.body.code === 'Theseus') {
                resolve(result.body);
              } else if (result.body.code) {
                reject(result.body.code);
              } else {
                resolve(result.body);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

}
