import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {KeywordService} from '../../services/keyword.service';
import {RoutingService} from '../../services/routing.service';
import {MathHelper} from '../../services/math.helper';
import {AdvertiserService} from '../../services/advertiser.service';
import {IpService} from '../../services/ip.service';
import {AgencyService} from '../../services/agency.service';
import {PictureService} from '../../services/picture.service';

@Component({
  selector: 'agency-create-advertiser',
  templateUrl: './agency-create-advertiser.component.html',
  styleUrls: ['./agency-create-advertiser.component.scss']
})
export class AgencyCreateAdvertiserComponent implements OnInit {

  // Grundsätzliches
  public companyName!: string;
  public website!: string;

  // Logo
  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public picturesOk = true;

  // Adresse
  public street!: string;
  public nr!: number;
  public postalCode!: string;
  public city!: string;
  public country: any;

  public working = false;

  // Länderauswahl
  public countriesDropDownList :any[] = [];

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public keywordService: KeywordService, public advertiserService: AdvertiserService,
              public routingService: RoutingService, public mathHelper: MathHelper,
              public ipService: IpService, private agencyService: AgencyService,
              private pictureService: PictureService) {
  }

  ngOnInit(): void {

    this.keywordService.getAllCountries().then((countries) => {
      this.countriesDropDownList = [];
      countries.forEach((country) => {
        this.countriesDropDownList.push(
          {
            // @ts-ignore
            item_id: country.id,
            // @ts-ignore
            item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
          });
      });
    }).catch((err) => {
      console.log(err);
    });

  }

  checkAllValues(): boolean {
    let creationAllowed: boolean = true;

    if (!this.companyName) {
      creationAllowed = false;
    }

    if (!this.street) {
      creationAllowed = false;
    }

    if (!this.nr) {
      creationAllowed = false;
    }

    if (!this.postalCode) {
      creationAllowed = false;
    }

    if (!this.city) {
      creationAllowed = false;
    }

    return creationAllowed;
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.checkPicture(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  checkPicture(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {

          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_width > 500) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }

  register() {
    this.working = true;

    if (this.checkAllValues()) {

      this.ipService.getIp().then(res => {
        this.advertiserService.createAdvertiserForAgency(this.companyName, this.website, this.street, this.nr,
          this.postalCode, this.city, this.country.item_id, res.ip).then(result => {
            this.working = false;

          if (this.uploadedPicturesBackup.length == 1) {
            this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, result, 'LOGO', 'advertiser')
              .then(() => {
                setTimeout(() => {
                  this.agencyService.updateAgencyInformation();
                  this.goBack();
                }, 200);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.goBack();
          }

        }).catch(err => {
          this.working = false;
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  goBack() {
    this.agencyService.updateAgencyInformation()
      .then(() => {
        this.routingService.switchToPath('/agency/advertisers');
      })
      .catch(() => {
        this.agencyService.logout(false);
      });
  }

}
