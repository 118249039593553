import {DomSanitizer} from "@angular/platform-browser";

export class News {

  public id!: string;

  public headline!: string;

  public picUrl!: string;

  public category!: string;

  public creationDate!: string;

  public sanitizedText: any;

  public pinned!: boolean;

  constructor(private text: string, public sanitizer:DomSanitizer) {
    this.sanitizedText = this.sanitizer.bypassSecurityTrustHtml(text);
  }

}
