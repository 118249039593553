<div *ngIf="this.globalService.streamer">
  <div class="headline">{{this.i18nService.localize().StreamerAffiliatesComponent.AffiliateHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerAffiliatesComponent.TutorialHeader}}</h2>
      </div>

      <div class="p-col-12 p-xl-5">
        <img src="/assets/images/Klick_Vorschau.jpg" alt="whatclickcampaign" style="width: 100%">
      </div>

      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="p-grid" style="padding: 0.5rem;">
          <div (click)="this.affiliateTab=1" [className]="this.affiliateTab == 1 ? 'p-col-6 afflilate-tab-left afflilate-tab-selected' : 'p-col-6 afflilate-tab-left'">
            {{this.i18nService.localize().StreamerAffiliatesComponent.ClickCampaigns}}
          </div>
          <div (click)="this.affiliateTab=2" [className]="this.affiliateTab == 2 ? 'p-col-6 afflilate-tab-right afflilate-tab-selected' : 'p-col-6 afflilate-tab-right'">
            {{this.i18nService.localize().StreamerAffiliatesComponent.Affiliates}}
          </div>
          <div class="p-col-12" *ngIf="affiliateTab==1" style="padding: 0;">
            <div class="p-panel-14-content">
              <p-listbox [options]="this.globalService.streamer.clickcampaignsForLink" [(ngModel)]="selectedAffiliateForLink"
                         [listStyle]="{'max-height':'385px', 'height':'385px'}" [style]="{'width':'100%'}" [multiple]="false"
                         [filter]="true" (onChange)="this.selectedBanner = null">
                <ng-template let-affiliate let-i="index" pTemplate="item">
                  <div style="display: flex;align-items: center;width: 100%;" *ngIf="affiliate.status !== 'PAUSED'">
                    <div style="text-align: center; margin-right: 1em;">
                      <img src="{{affiliate.value.logoURL}}" style="border-radius: .25rem;" width="50">
                    </div>
                    <div style="width: 100%">
                      {{affiliate.label}}
                      <span *ngIf="affiliate.value.isStartDateInFuture()" style="font-weight: bolder">
                    {{this.i18nService.localize().StreamerAffiliatesComponent.Upcoming}}
                  </span>
                    </div>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>

          <div class="p-col-12" *ngIf="affiliateTab==2" style="padding: 0;">
            <div class="p-panel-14-content">
              <p-listbox [options]="this.globalService.streamer.affiliatesForLink" [(ngModel)]="selectedAffiliateForLink"
                         [listStyle]="{'max-height':'385px', 'height':'385px'}" [style]="{'width':'100%'}" [multiple]="false"
                         [filter]="true" (onChange)="this.selectedBanner = null">
                <ng-template let-affiliate let-i="index" pTemplate="item">
                  <div style="display: flex;align-items: center;width: 100%;" *ngIf="affiliate.status !== 'PAUSED'">
                    <div style="text-align: center; margin-right: 1em;">
                      <img src="{{affiliate.value.logoURL}}" style="border-radius: .25rem;" width="50">
                    </div>
                    <div style="width: 100%">
                      {{affiliate.label}}
                      <span *ngIf="affiliate.value.isStartDateInFuture()" style="font-weight: bolder">
                    {{this.i18nService.localize().StreamerAffiliatesComponent.Upcoming}}
                  </span>
                    </div>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>

        </div>
      </div>

      <div class="p-col-12 p-md-6 p-xl-4" *ngIf="!selectedBanner" style="">
        <p-panel [header]=generateBannerHeader()>
          <div *ngIf="selectedAffiliateForLink">
            <div class="p-grid">
              <div class="p-col-12">
                <p *ngIf="selectedAffiliateForLink.type==='CAMPAIGN'||selectedAffiliateForLink.type==='INTERNAL'" style="margin: 0">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.ClickCampaignCompensation}}
                </p>
                <p *ngIf="selectedAffiliateForLink.type==='EXTERNAL'" style="margin: 0">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.AffiliateCompensation}}
                </p>
              </div>
            </div>
            <div class="p-grid" *ngFor="let banner of selectedAffiliateForLink.bannerURLs; let i = index" style="margin-top: 0.5em;">
              <div class="p-col-2">
                <img src="{{banner.picture_url}}" width="100%" alt="banner">
              </div>
              <div class="p-col-4" style="text-align: center; margin: auto;">
                <a (click)="selectBanner(banner, i)" class="banner-link">Version {{(i+1)}}</a>
              </div>
              <div class="p-col-6" style="text-align: right; margin: auto;">
                <button pButton pRipple type="button" label="{{this.i18nService.localize().StreamerAffiliatesComponent.LinkButton}}"
                        icon="pi pi-clone" class="p-button-rounded logout" (click)="copyLink(banner.id)"></button>
              </div>
            </div>
            <div class="p-grid" *ngIf="selectedAffiliateForLink.chatMessage" style="margin-top: 0.5em;">
              <div class="p-col-6" style="margin: auto; word-break: break-all">
                <p style="font-weight: bold; margin: 0">Chat-Nachricht:</p>
                {{selectedAffiliateForLink.chatMessage}}
              </div>
              <div class="p-col-6" style="text-align: right; margin: auto;">
                <button pButton pRipple type="button" label="{{this.i18nService.localize().StreamerAffiliatesComponent.LinkButton}}"
                        icon="pi pi-clone" class="p-button-rounded logout" (click)="copyLink('NONE')"></button>
              </div>
            </div>
          </div>
          <div *ngIf="!selectedAffiliateForLink">
            Nichts gefunden
          </div>
        </p-panel>
      </div>

      <div class="p-col-12 p-md-6 p-xl-4" *ngIf="selectedBanner">
        <p-panel header="{{this.i18nService.localize().StreamerAffiliatesComponent.ChosenBannerHeader}}">
          <div class="p-grid">
            <div class="p-col-6">
              <p>Version {{selectedVersionNumber}}</p>
            </div>
            <div class="p-col-6" style="text-align: right;">
              <button pButton pRipple type="button" label="{{this.i18nService.localize().StreamerAffiliatesComponent.LinkButton}}"
                      icon="pi pi-clone" class="p-button-rounded logout" (click)="copyLink(selectedBanner.id)"></button>
            </div>
            <div class="p-col-6">
              <img src="{{selectedBanner.picture_url}}" width="250" alt="selectedbanner">
            </div>
            <div class="p-col-6" style="text-align: right;">
              <p style="margin-top: 13.4em;">
                <a (click)="this.selectedBanner = null" class="banner-link">{{this.i18nService.localize().StreamerAffiliatesComponent.BackLink}}</a>
              </p>
            </div>
          </div>
        </p-panel>
      </div>

      <div class="p-col-12">
        <p-card>
          <div class="p-grid">
            <div class="p-col-12">
              <h2 style="margin: 1em 1em 0 1em; font-style: italic;">
                {{this.i18nService.localize().StreamerAffiliatesComponent.ClickTutorialHeadline}}
              </h2>
            </div>
            <div class="p-col-12 p-md-5 p-xl-5" style="margin-left: 1.5em;">
              <h3 style="color: #1d60a4;">Stream Info</h3>
              <ol>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ClickTutorialStep1}}</li>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ClickTutorialStep2}}</li>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ClickTutorialStep3}}</li>
              </ol>
            </div>

            <div class="p-col-12 p-md-5 p-xl-5" style="margin-left: 1.5em;">
              <h3 style="color: #1d60a4;">ChatBot</h3>
              <p>
                {{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorial}}
                <b>{{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorial2}}</b>
                {{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorial3}}
              </p>
              <ol>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorialStep1}}</li>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorialStep2}}</li>
                <li>{{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorialStep3}}</li>
              </ol>
              <p>
                {{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotTutorialPS}}
              </p>
              <p style="margin-bottom: 0;">
                {{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotExampleHeader}}
              </p>
              <p style="font-weight: bold; margin-top: 0;">
                {{this.i18nService.localize().StreamerAffiliatesComponent.ChatBotExample}}
              </p>
            </div>

          </div>
        </p-card>
      </div>

      <div class="p-col-12">
        <p-card>
          <div class="p-grid">
            <div class="p-col-12">
              <h2 style="margin: 1em 1em 0 1em; font-style: italic;">
                {{this.i18nService.localize().StreamerAffiliatesComponent.AffiliateTutorialHeadline}}
              </h2>
            </div>
            <div class="p-col-12 p-md-12 p-xl-8">
              <p style="margin: 2em 2em 2em 1.5em;">
                {{this.i18nService.localize().StreamerAffiliatesComponent.AffiliateText}}
              </p>
            </div>
            <div class="p-col-12 p-md-12 p-xl-4">
              <div class="p-grid tip-grid">
                <div
                  [className]="tipTab == 1 ? 'p-col-4 tip-col tip-col-inner tip-col-selected-left' : 'p-col-4 tip-col tip-col-inner'"
                  (click)="tipTab=1">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.HintHeader}} 1
                </div>
                <div
                  [className]="tipTab == 2 ? 'p-col-4 tip-col tip-col-inner tip-col-selected' : 'p-col-4 tip-col tip-col-inner'"
                  (click)="tipTab=2">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.HintHeader}} 2
                </div>
                <div [className]="tipTab == 3 ? 'p-col-4 tip-col tip-col-selected-right' : 'p-col-4 tip-col'"
                  (click)="tipTab=3">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.HintHeader}} 3
                </div>
                <div class="p-col-12" *ngIf="tipTab == 1">
                  <p style="margin: 1em;">
                    {{this.i18nService.localize().StreamerAffiliatesComponent.Hint1Text}}
                  </p>
                </div>
                <div class="p-col-12" *ngIf="tipTab == 2">
                  <p style="margin: 1em;">
                    {{this.i18nService.localize().StreamerAffiliatesComponent.Hint2Text}}
                  </p>
                </div>
                <div class="p-col-12" *ngIf="tipTab == 3">
                  <p style="margin: 1em;">
                    {{this.i18nService.localize().StreamerAffiliatesComponent.Hint3Text}}
                  </p>
                </div>
              </div>
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-01.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 p-lg-3 infoText">
              {{this.i18nService.localize().StreamerAffiliatesComponent.Step1Text}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-02.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 p-lg-3 infoText">
              {{this.i18nService.localize().StreamerAffiliatesComponent.Step2Text}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-1">
              <img src="/assets/images/123_Bilder-03.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 p-lg-3 infoText" style="margin-left: 0;">
              {{this.i18nService.localize().StreamerAffiliatesComponent.Step3Text}}
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12" style="margin-top: 3em;">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerAffiliatesComponent.PartnerHeader}}</h2>
        <p style="color: #495057">
          {{this.i18nService.localize().StreamerAffiliatesComponent.PartnerText1}}
        </p>
        <p style="color: #495057">
          {{this.i18nService.localize().StreamerAffiliatesComponent.PartnerText2}}
        </p>

        <div class="p-grid" style="margin-top: 1em;">
          <div class="p-col-12 p-sm-4">
            <p-card>
              <ng-template pTemplate="header">
                <img src="assets/images/bronze_3.png" alt="Bronze"
                  style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
              </ng-template>
              <div style="text-align: center">
                <p class="tier-panel">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.Tier1AffiliateText}}
                </p>
                <a class="tier-link-bronze" (click)="moreStatusInfoModal=true">{{this.i18nService.localize().StreamerAffiliatesComponent.MoreLink}}</a>
              </div>
            </p-card>
          </div>
          <div class="p-col-12 p-sm-4">
            <p-card>
              <ng-template pTemplate="header">
                <img src="assets/images/silber_3.png" alt="Silber"
                  style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
              </ng-template>
              <div style="text-align: center">
                <p class="tier-panel">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.Tier2AffiliateText}}
                </p>
                <a class="tier-link-silber" (click)="moreStatusInfoModal=true">{{this.i18nService.localize().StreamerAffiliatesComponent.MoreLink}}</a>
              </div>
            </p-card>
          </div>
          <div class="p-col-12 p-sm-4">
            <p-card>
              <ng-template pTemplate="header">
                <img src="assets/images/gold_3.png" alt="Gold"
                  style="border-top-left-radius: 40px;border-top-right-radius: 40px;">
              </ng-template>
              <div style="text-align: center">
                <p class="tier-panel">
                  {{this.i18nService.localize().StreamerAffiliatesComponent.Tier3AffiliateText}}
                </p>
                <a class="tier-link-gold" (click)="moreStatusInfoModal=true">{{this.i18nService.localize().StreamerAffiliatesComponent.MoreLink}}</a>
              </div>
            </p-card>
          </div>
        </div>
      </div>

      <div class="p-col-12" style="margin-top: 3em;">
        <p class="error" *ngIf="tooManyAffiliates">
          {{this.i18nService.localize().StreamerAffiliatesComponent.TooManyAffiliatesError}}
        </p>
        <p-pickList [source]="affiliateSource" [target]="selectedAffiliates"
                    sourceHeader="{{this.i18nService.localize().StreamerAffiliatesComponent.AvailablePrograms}}"
                    targetHeader="{{this.i18nService.localize().StreamerAffiliatesComponent.ChosenPrograms}}"
                    [dragdrop]="true" [sourceStyle]="{'height':'300px'}"
                    [targetStyle]="{'height':'370px'}" [responsive]="true" [showSourceControls]="false"
                    [showTargetControls]="false" (onMoveToTarget)="updateChanges()" (onMoveToSource)="updateChanges()"
                    (onMoveAllToSource)="updateChanges()" (onMoveAllToTarget)="updateChanges()"
                    (onSourceSelect)="selectAffiliate($event)" (onTargetSelect)="selectAffiliate($event)" filterBy="name,keywords"
                    [showTargetFilter]="false">
          <ng-template let-affiliate pTemplate="item">
            <div class="ui-helper-clearfix">
              <div [className]="affiliate.topAffiliate ? 'p-grid better-affiliate' : 'p-grid normal-affiliate'" style="">
                <div class="p-col-2">
                  <img src="{{affiliate.logoURL}}" style="border-radius: .25rem;" width="55">
                </div>
                <div class="p-col-3" style="text-align: center">
                  {{affiliate.name}}
                  <span *ngIf="affiliate.requested" style="font-weight: bolder">{{this.i18nService.localize().StreamerAffiliatesComponent.Requested}}</span>
                  <br *ngIf="affiliate.topAffiliate">
                  <span *ngIf="affiliate.topAffiliate" style="font-weight: bolder">TOP AFFILIATE</span>
                </div>
                <div class="p-col-6" style="text-align: center">
                  <p-tag *ngFor="let keyword of affiliate.keywords" value="{{keyword}}" [rounded]="true"></p-tag>
                </div>
                <div class="p-col-1">
                  <button pButton pRipple type="button" (click)="openDescriptionDialog()" icon="fas fa-info"
                    class="p-button-rounded" style="z-index: 1;"></button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-pickList>
        <div style="text-align: right; margin-top: 1em;">
          <button pButton pRipple type="button" (click)="saveAffiliateChanges()" [disabled]="!changes"
            icon="fas fas fa-check" class="p-button-rounded" label="{{this.i18nService.localize().StreamerAffiliatesComponent.SaveChangesButton}}"
            style="margin-right: .25em"></button>
          <button pButton pRipple type="button" (click)="resetAffiliates()" icon="fas fa-times"
            class="p-button-rounded p-button-secondary" label="{{this.i18nService.localize().StreamerAffiliatesComponent.ResetChangesButton}}"
            style="margin-right: .25em"></button>
        </div>
      </div>

      <p-dialog [modal]="true" position="center" [(visible)]="showSentHint" [blockScroll]="true" [closeOnEscape]="false"
        [closable]="true" [draggable]="false" [style]="{width: '25vw'}" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{this.i18nService.localize().StreamerAffiliatesComponent.Hint}}</div>
        </ng-template>
        <p>
          {{this.i18nService.localize().StreamerAffiliatesComponent.SendText}}
        </p>
      </p-dialog>

      <p-dialog position="center" [(visible)]="showAffiliateDescription" [blockScroll]="true" [closeOnEscape]="true"
        [closable]="true" [draggable]="false" [style]="{width: '70%', maxWidth: '770px'}" [modal]="true"
        closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{this.i18nService.localize().StreamerAffiliatesComponent.DescriptionHeader}}</div>
        </ng-template>
        <p class="multi_lines_text" *ngIf="selectedAffiliate" [innerHTML]="selectedAffiliate.description"></p>
      </p-dialog>

      <streamer-status-dialog [show]="moreStatusInfoModal" (showChange)="moreStatusInfoModal=$event">
      </streamer-status-dialog>

    </div>
  </div>
</div>
