import { Component, HostListener, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StreamBoostEventBus } from './streamboost-eventbus.service';
import { GlobalService } from './services/global.service';
import { RoutingService } from './services/routing.service';
import { AdvertiserService } from './services/advertiser.service';
import { StreamerService } from './services/streamer.service';
import { PrimeNGConfig } from 'primeng/api';
import { I18nService } from './services/i18n.service';
import { NotificationService } from './services/notification.service';
import { IpService } from './services/ip.service';
import { Subscription } from 'rxjs';
import { AgencyService } from './services/agency.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public streamBoostOnline = false;

  public streamBoostOnlineInterval: any;

  public showMenu: boolean = true;

  public notificationText?: string;

  public visibleHelpBar = false;
  public subject?: String;
  public concern?: String;

  // Updated Terms Dialog
  public showTermsDialog = false;

  // Streamer Geschlecht und PLZ Dialog
  public showSetGenderDialog = false;
  public postalCode!: String;
  public gender!: string;
  public genderOptions : any[];
  public hasAddress = false;
  public hasGender = false;

  public langChangeSubscription!: Subscription;

  constructor(private streamBoostEventBus: StreamBoostEventBus, private cookieService: CookieService,
              private routingService: RoutingService, public globalService: GlobalService,
              private advertiserService: AdvertiserService, private streamerService: StreamerService,
              private primengConfig: PrimeNGConfig, public i18nService: I18nService,
              private notificationService: NotificationService, private ipService: IpService,
              private agencyService: AgencyService) {

    this.genderOptions = [
      {label: this.i18nService.localize().StreamerRegisterComponent.Male, value: 'male'},
      {label: this.i18nService.localize().StreamerRegisterComponent.Female, value: 'female'},
      {label: this.i18nService.localize().StreamerRegisterComponent.Divers, value: 'divers'}
    ];

    // Sprache vom letzten Cookie einstellen
    if (this.cookieService.get('streamboost-language')) {
      this.i18nService.setLang(this.cookieService.get('streamboost-language'));
    } else {
      this.i18nService.setLang(navigator.language.toUpperCase());
    }

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      this.primengConfig.setTranslation(this.i18nService.getCurrentPrimeNGConfig());
    });

    this.checkOnlineAndUserSet();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(this.i18nService.getCurrentPrimeNGConfig());
    this.getScreenSize();
  }

  private checkOnlineAndUserSet() {
    this.globalService.isUserSet().then((result) => {
      this.streamBoostOnline = result;
      if (!result) {
        // Wenn Streamboost noch nicht online ist, probiere es so lange bis es da ist
        this.globalService.sleep(1000).then(() => {
          this.checkOnlineAndUserSet()
        })
      } else {
        this.updateNotification();
        this.checkAndShowTermsOfUseDialog();
        this.checkAndShowGenderDialog();
        this.globalService.updateLinksAndVersion();
        this.globalService.updateEnableYoutubeAuth();
        this.globalService.updateEnableGoogleTags();
        this.globalService.updateCompetitionAvailable();

        // Wenn ein Streamer, ein Advertiser oder eine Agentur angemeldet ist, zeige das Menü an
        if (this.globalService.streamer || this.globalService.advertiser || this.globalService.agency) {
          this.showMenu = true;

        // Wenn man ein Advertiser ist, der sich registrieren möchte
        } else if (this.routingService.getRoute().includes('/advertiser/register')) {
          this.showMenu = true;

        // Wenn man eine Agentur oder eine Advertiser ist, die sich registrieren möchte
        } else if (this.routingService.getRoute().includes('/register')) {
          this.showMenu = true;

        // Wenn man seinen Advertiser Account aktivieren möchte oder die Marketing E-Mails abbestellen möchte
        } else if (this.routingService.getRoute().includes('/activate') || this.routingService.getRoute().includes('/cancel')
          || this.routingService.getRoute().includes('/takeover')) {
          this.showMenu = false;

        // Wenn man unangemeldet auf eine Seite geht, wird man zum Login umgeleitet
        } else {
          this.rerouteToLogin();
          this.showMenu = false;
        }
      }
    });

  }

  checkAndShowTermsOfUseDialog() {
    if (!this.globalService.hasLoggedInUserAcceptedTheCurrentTerms()) {
      this.showTermsDialog = true;
    }
  }

  checkAndShowGenderDialog() {
    if (this.globalService.streamer && !this.globalService.streamer.inRegister &&
      (!this.globalService.streamer.gender || !this.globalService.streamer.postal_code)) {
      this.hasAddress = !!this.globalService.streamer.postal_code;
      this.hasGender = this.globalService.streamer.gender != null;

      this.showSetGenderDialog = true;
    }
  }

  updateStreamerGender() {
    if (this.globalService.streamer) {
      this.streamerService.setGenderAndPostalCode(this.gender, this.postalCode).then(() => {
        this.showSetGenderDialog = false;
      });
    }
  }

  closeGenderDialog() {
    this.showSetGenderDialog = false;
  }

  checkStreamerValues(): boolean {
    let submitAllowed: boolean = true;

    if (!this.hasGender) {
      if (!this.gender) {
        submitAllowed = false;
      }
    }

    if (!this.hasAddress) {
      if (!this.postalCode) {
        submitAllowed = false;
      } else {
        if (this.postalCode.toString().length < 5) {
          submitAllowed = false;
        }
      }
    }

    return submitAllowed;
  }

  private rerouteToLogin() {
    if (!this.routingService.getRoute().includes('register') && !this.routingService.getRoute().includes('login') && !this.routingService.getRoute().includes('callback')) {
      if (this.routingService.getRoute().includes('streamer')) {
        this.routingService.switchToPath('/streamer/login');
      } else if (this.routingService.getRoute().includes('agency')) {
        this.routingService.switchToPath('/agency/login');
      } else {
        this.routingService.switchToPath('/advertiser/login');
      }
    }
  }

  submitEmail() {
    if (this.globalService.advertiser || this.globalService.streamer || this.globalService.agency) {
      if (this.subject && this.concern) {
        this.globalService.sendHelpMail(this.subject, this.concern).then(result => {
          delete this.subject;
          delete this.concern;
          this.visibleHelpBar = false;
        });
      }
    }
  }

  scrHeight: any;
  scrWidth: any;
  mobileNavBar = false;
  changeBar = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 1150) {

      // Die Möglichkeit der Sichtbarkeit darf nur sein wenn überhaupt jemand angemeldet ist
      if (this.globalService.streamer || this.globalService.advertiser || this.globalService.agency) {
        this.mobileNavBar = true;
      } else {
        this.mobileNavBar = false;
      }
    } else {
      if (this.mobileNavBar) {
        this.changeBar = true;
        this.mobileNavBar = false;
        setTimeout(() => {
          this.changeBar = false;
        }, 2000);
      }
    }
  }

  public determineContentClass(): string {
    if (this.showMenu) {
      if (this.mobileNavBar) {
        return 'p-col-12 pageForMobileNav';
      }
      if (this.globalService.menuIsOpen) {
        if (this.changeBar) {
          return 'p-col-10 noTrans';
        } else {
          return 'p-col-10';
        }
      } else {
        if (this.changeBar) {
          return 'maxContent noTrans';
        } else {
          return 'maxContent';
        }
      }
    } else {
      return 'maxContentWithoutMenu'
    }
  }

  public calculateMenuHeight(): string {
    var fixClass = "side-menu ";
    fixClass += this.globalService.menuIsOpen ? 'mobileMenu ' : 'closedMenu ';
    if (fixClass.includes("mobileMenu")) {
      if (this.globalService.advertiser) {
        fixClass += "advertiserMobileMenuHeight";
      } else {
        fixClass += "streamerMobileMenuHeight";
      }
    }
    return fixClass;
  }


  changeMenu() {
    this.globalService.menuIsOpen = !this.globalService.menuIsOpen;
    this.globalService.showMenuText = !this.globalService.showMenuText;
  }

  logout() {
    if (this.globalService.streamer || this.globalService.advertiser || this.globalService.agency) {
      if (this.globalService.advertiser) {
        this.advertiserService.logout(false).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else if (this.globalService.streamer) {
        this.streamerService.logout().then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else {
        this.agencyService.logout(false).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      }
    }
  }

  updateNotification() {
    this.notificationService.getNotification().then(result => {
      this.notificationText = result;
    })
  }

}
