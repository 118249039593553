import {Component, OnInit} from '@angular/core';
import {GlobalService} from 'src/app/services/global.service';
import {I18nService} from 'src/app/services/i18n.service';
import {Tag} from '../../model/advertiser/tag.model';
import {AffiliateService} from '../../services/affiliate.service';
import {TableLazyLoadEvent} from 'primeng/table';

@Component({
  selector: 'advertiser-tags',
  templateUrl: './advertiser-tags.component.html',
  styleUrls: ['./advertiser-tags.component.scss']
})
export class AdvertiserTagsComponent implements OnInit {

  public tags: Tag[] = [];
  public totalRecordsTags!: number;
  public loadingTags!: boolean;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public affiliateService: AffiliateService) {

  }

  ngOnInit(): void {
    this.initializeTags();
  }

  initializeTags() {
    this.loadingTags = true;
    this.affiliateService.getTagsOfAdvertiser().then((result) => {
      this.tags = result;

      this.affiliateService.getMaxTagsOfAdvertiser().then((max) => {
        this.totalRecordsTags = max;
        this.loadingTags = false;
      });

    });
  }

  updateTags(event: TableLazyLoadEvent) {
    this.loadingTags = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.affiliateService.getTagsOfAdvertiser('', event.first, rows, sortField, sortOrder).then((result) => {
      this.tags = result;

      this.affiliateService.getMaxTagsOfAdvertiser().then((max) => {
        this.totalRecordsTags = max;
        this.loadingTags = false;
      });
    });
  }

  getDefaultSortOrder(): number {
    return -1;
  }

  copyLink(id: string) {
    const link = document.createElement('textarea');
    link.style.position = 'fixed';
    link.style.left = '0';
    link.style.top = '0';
    link.style.opacity = '0';
    link.value = id;
    document.body.appendChild(link);
    link.focus();
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
  }
}
