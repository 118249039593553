<div>
  <div class="headline">{{i18nService.localize().AdvertiserRegisterComponent.RegisterHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12">
        <div class="tab-menu" *ngIf="!registerSent">
          <div [class]="this.activeTab===1 ? 'tab tab-left-selected' : 'tab tab-left'" (click)="this.activeTab=1">
            <span class="p-menuitem-text">BRAND</span>
          </div>
          <div [class]="this.activeTab===2 ? 'tab tab-right-selected' : 'tab tab-right'" (click)="this.activeTab=2">
            <span class="p-menuitem-text">{{i18nService.localize().AgencyRegisterComponent.AgenciesRegister}}</span>
          </div>
        </div>
        <div class="tab-card">

          <!-- Advertiser Simple Register Tab -->
          <div class="p-grid" *ngIf="this.activeTab === 1">
            <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">

              <p class="headline-in-step" style="margin: 0;">
                {{i18nService.localize().AdvertiserRegisterComponent.WelcomeHeader}}
              </p>

              <p *ngIf="!registerSent">
                {{i18nService.localize().AdvertiserRegisterComponent.InfoText1}}
              </p>


              <p *ngIf="registerSent">
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText1}}
                <br><br>
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText2}}
                <a (click)="this.routingService.switchToPath('/advertiser/login')" style="cursor: pointer; text-decoration: underline;">
                  Login
                </a>
                {{i18nService.localize().AdvertiserRegisterComponent.AfterRegisterText3}}
              </p>

              <div *ngIf="!registerSent">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-6">
                    <label for="companyname" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.CompanyName}}</label>
                    <input id="companyname" type="text" pInputText [(ngModel)]="companyName" class="field">
                  </div>


                  <div class="p-field p-col-12 p-md-6">
                    <label for="email" style="font-weight: bold">E-Mail*:</label>
                    <input id="email" type="email" pInputText [(ngModel)]="email" class="field">
                    <small *ngIf="emailError" id="email-help" class="p-error">{{i18nService.localize().AdvertiserRegisterComponent.EmailInUse}}</small>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="password" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.PasswordOnce}}</label>
                    <p-password id="password" [(ngModel)]="password" [toggleMask]="false" class="field"
                                [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="passwordToo" style="font-weight: bold">{{i18nService.localize().AdvertiserRegisterComponent.PasswordAgain}}</label>
                    <p-password id="passwordToo" [(ngModel)]="passwordToo" [toggleMask]="false" [feedback]="false"
                                class="field" [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>
                </div>

                <div class="p-grid" style="margin-top: 1em;">
                  <div class="p-col-12 p-offset-6">
                    {{i18nService.localize().AdvertiserRegisterComponent.TermsText}}<a href="{{this.globalService.links.conditionsLink}}" target="_blank">{{i18nService.localize().AdvertiserRegisterComponent.TermsAndConditionLink}}</a>
                  </div>
                  <div class="p-col-12 p-offset-6 field-checkbox">
                    <p-checkbox [(ngModel)]="acceptTermsAndConditions" [binary]="true" inputId="accept"></p-checkbox>
                    <!--suppress XmlInvalidId -->
                    <label for="accept" style="margin-left: 0.5em;">{{i18nService.localize().AdvertiserRegisterComponent.AcceptCheckboxText}}</label>
                  </div>
                </div>
              </div>

            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" (click)="registerAdvertiser()" *ngIf="!working&&!registerSent"
                      label="{{i18nService.localize().AdvertiserRegisterComponent.RegisterButton}}" class="p-button-rounded"
                      [disabled]="!checkAllValuesAdvertiser()" style="margin-right: .25em">
              </button>
            </div>

          </div>

          <!-- Agency Register Tab -->
          <div class="p-grid" *ngIf="this.activeTab === 2">
            <div class="p-col-12" style="text-align: left;margin-bottom: 1em;">

              <p class="headline-in-step" style="margin: 0;">
                {{i18nService.localize().AgencyRegisterComponent.WelcomeHeader}}
              </p>

              <p *ngIf="!registerSent">
                {{i18nService.localize().AgencyRegisterComponent.InfoText1}}
              </p>

              <p *ngIf="registerSent">
                {{i18nService.localize().AgencyRegisterComponent.AfterRegisterText1}}
                <br><br>
                {{i18nService.localize().AgencyRegisterComponent.AfterRegisterText2}}
                <a (click)="this.routingService.switchToPath('/agency/login')" style="cursor: pointer; text-decoration: underline;">
                  Login
                </a>
                {{i18nService.localize().AgencyRegisterComponent.AfterRegisterText3}}
              </p>

              <div *ngIf="!registerSent">
                <p class="headline-in-step">
                  {{i18nService.localize().AgencyRegisterComponent.CompanyInfoHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12">
                    <label for="companyname2" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.CompanyName}}</label>
                    <input id="companyname2" type="text" pInputText [(ngModel)]="companyName" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="website" style="font-weight: bold">Website:</label>
                    <input id="website" type="text" pInputText [(ngModel)]="website" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="tax" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Tax}}</label>
                    <input id="tax" type="text" pInputText [(ngModel)]="taxNumber" class="field">
                  </div>
                </div>

                <p class="headline-in-step">
                  {{i18nService.localize().AgencyRegisterComponent.AddressHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-8">
                    <label for="street" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Street}}</label>
                    <input id="street" type="text" pInputText [(ngModel)]="street" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-4">
                    <label for="nr" style="font-weight: bold">Nr.*:</label>
                    <input id="nr" type="text" pInputText [(ngModel)]="nr" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-4">
                    <label for="postalcode" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.PostalCode}}</label>
                    <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-8">
                    <label for="city" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.City}}</label>
                    <input id="city" type="text" pInputText [(ngModel)]="city" class="field">
                  </div>

                  <div class="p-field p-col-12">
                    <label for="country" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Country}}</label>
                    <p-dropdown id="country" [options]="countriesDropDownList" [(ngModel)]="country"
                                optionLabel="item_text" [showClear]="true"
                                [style]="{'border-radius': '12px', 'width': '100%'}"
                                [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-dropdown>
                  </div>
                </div>

                <p class="headline-in-step">
                  {{i18nService.localize().AgencyRegisterComponent.ContactHeader}}
                </p>

                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-12 p-md-6">
                    <label for="firstname" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Firstname}}</label>
                    <input id="firstname" type="text" pInputText [(ngModel)]="firstname" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="lastname" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Lastname}}</label>
                    <input id="lastname" type="text" pInputText [(ngModel)]="lastname" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="email2" style="font-weight: bold">E-Mail*:</label>
                    <input id="email2" type="email" pInputText [(ngModel)]="email" class="field">
                    <small *ngIf="emailError" id="email-help2" class="p-error">{{i18nService.localize().AgencyRegisterComponent.EmailInUse}}</small>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="telephone" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.Telefon}}</label>
                    <input id="telephone" type="number" pInputText [(ngModel)]="telephone" class="field">
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="password2" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.PasswordOnce}}</label>
                    <p-password id="password2" [(ngModel)]="password" [toggleMask]="false" class="field"
                                [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>

                  <div class="p-field p-col-12 p-md-6">
                    <label for="passwordToo2" style="font-weight: bold">{{i18nService.localize().AgencyRegisterComponent.PasswordAgain}}</label>
                    <p-password id="passwordToo2" [(ngModel)]="passwordToo" [toggleMask]="false" [feedback]="false"
                                class="field" [inputStyle]="{'border-radius': '12px', 'width': '100%'}">
                    </p-password>
                  </div>
                </div>

                <div class="p-grid" style="margin-top: 1em;">
                  <div class="p-col-12 p-offset-6">
                    {{i18nService.localize().AgencyRegisterComponent.TermsText}}<a href="{{this.globalService.links.conditionsLink}}" target="_blank">{{i18nService.localize().AgencyRegisterComponent.TermsAndConditionLink}}</a>
                  </div>
                  <div class="p-col-12 p-offset-6 field-checkbox">
                    <p-checkbox [(ngModel)]="acceptTermsAndConditions" [binary]="true" inputId="accept"></p-checkbox>
                    <!--suppress XmlInvalidId -->
                    <label for="accept" style="margin-left: 0.5em;">{{i18nService.localize().AgencyRegisterComponent.AcceptCheckboxText}}</label>
                  </div>
                </div>
              </div>

            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" (click)="registerAgency()" *ngIf="!working&&!registerSent"
                      label="{{i18nService.localize().AgencyRegisterComponent.RegisterButton}}" class="p-button-rounded"
                      [disabled]="!checkAllValuesAgency()" style="margin-right: .25em">
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>
