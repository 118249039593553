import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {PictureService} from '../../services/picture.service';
import {ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexTitleSubtitle} from 'ng-apexcharts';
import {Subscription} from 'rxjs';
import {AdvertiserService} from '../../services/advertiser.service';
import {Advertiser} from "../../model/advertiser/advertiser.model";
import {DashboardCampaign} from '../../model/advertiser/dashboard-campaign.model';
import {CampaignService} from '../../services/campaign.service';
import {AffiliateService} from '../../services/affiliate.service';
import {IpService} from '../../services/ip.service';
import {RoutingService} from '../../services/routing.service';
import {GameStreamStatistic} from '../../model/advertiser/game-stream-statistic.model';
import {BillService} from '../../services/bill.service';
import {FileService} from '../../services/file.service';
import {Tag} from '../../model/advertiser/tag.model';
import {DisplayClickBill} from '../../model/bill.model';
import {TableLazyLoadEvent} from 'primeng/table';

export type ChartOptions = {
  chart: ApexChart;
  colors: any;
  dataLabels: ApexDataLabels;
  series: ApexAxisChartSeries;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'advertiser-details',
  templateUrl: './advertiser-details.component.html',
  styleUrls: ['./advertiser-details.component.scss'],
})
export class AdvertiserDetailsComponent implements OnInit {

  @Input()
  public advertiserId!: string;

  @Output()
  public closeEmit = new EventEmitter<void>();

  public advertiser!: Advertiser;

  public activeTab: number = 1;

  public dailyViewData: any;
  public viewData: any;

  public working = false;

  public langChangeSubscription!: Subscription;

  // Kampagnen Tab
  public selectedCampaignId?:string;
  public selectedCampaignNumberOfStreamer:number = 0;

  public selectedAffiliateId?:string;
  public selectedAffiliateNumberOfStreamer:number = 0;

  public showEditCampaign = false;
  public campaignIdOfEdit!: string;
  public nameEdit!: string;
  public displayNameEdit!: string;
  public descriptionEdit!: string;

  public showRemoveCampaign: boolean = false;
  public selectedDeleteCampaign?: DashboardCampaign;

  public selectedAffiliateIdForExtension?:string;
  public selectedAffiliateNameForExtension!:string;

  public selectedCampaignIdForExtension?:string;
  public selectedCampaignNameForExtension!:string;

  // Statistik Tab
  public viewChart!: any;
  public chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  public clickChart!: any;
  public gameStreamStatistics: GameStreamStatistic[] = [];

  // Abrechnungen Tab
  public bills: DisplayClickBill[] = [];
  public totalBills!: number;
  public loadingBills!: boolean;

  // Tags Tab
  public tags: Tag[] = [];
  public totalRecordsTags!: number;
  public loadingTags!: boolean;

  constructor(
    public globalService: GlobalService,
    public advertiserService: AdvertiserService,
    public i18nService: I18nService,
    public pictureService: PictureService,
    public campaignService: CampaignService,
    public affiliateService: AffiliateService,
    private ipService: IpService,
    public routingService: RoutingService,
    public billService: BillService,
    public fileService: FileService
  ) {

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      if (this.globalService.agency) {
        this.initializeBills();
      }
    });
  }

  ngOnInit(): void {
    if (this.globalService.agency) {
      this.updateAdvertiser().then(result => {

        this.advertiserService.getAllClicksChart(this.advertiserId).then((res) => {
          this.clickChart = res;
        });

        this.advertiserService.getAllViewsChart(this.advertiserId).then((res) => {
          this.viewChart = res;
        });

        this.advertiserService.getAllGameStreamStatistics(this.advertiserId).then((res) => {
          this.gameStreamStatistics = res;
        });

        this.initializeBills();
        this.initializeTags();
      });
    }
  }

  back() {
    this.closeEmit.emit();
  }

  updateAdvertiser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.advertiserService.getAdvertiserForAgency(this.advertiserId).then((result) => {
        this.advertiser = result;
        resolve(true);
      });
    });
  }

  gotoCreateNewWizard() {
    this.updateAdvertiser().then(result => {
      this.routingService.switchToPath('/advertiser/campaign/create', { advertiserId: this.advertiserId });
    });
  }

  // Tab 1: Kampagnen
  selectCampaignForDetails(campaign: DashboardCampaign) {
    this.selectedCampaignId = campaign.id;
    this.selectedCampaignNumberOfStreamer = campaign.streamer;
  }

  closeCampaignDetails() {
    delete this.selectedCampaignId;
    this.selectedCampaignNumberOfStreamer = 0;
  }

  selectAffiliateForDetails(affiliate: DashboardCampaign) {
    this.selectedAffiliateId = affiliate.id;
    this.selectedAffiliateNumberOfStreamer = affiliate.streamer;
  }

  closeAffiliateDetails() {
    delete this.selectedAffiliateId;
    this.selectedAffiliateNumberOfStreamer = 0;
  }

  pauseCampaign(campaign: DashboardCampaign) {
    if (campaign.type === 'AFFILIATE') {
      this.ipService.getIp().then(res => {
        this.affiliateService.pauseAffiliate(campaign.id, res.ip).then(result => {
          this.updateAdvertiser();
        });

      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.ipService.getIp().then(res => {
        this.campaignService.pauseCampaign(campaign.id, res.ip).then(result => {
          this.updateAdvertiser();
        });

      }).catch((err) => {
        console.log(err);
      });
    }
  }

  startCampaign(campaign: DashboardCampaign) {
    if (campaign.type === 'AFFILIATE') {
      this.affiliateService.startAffiliate(campaign.id).then(result => {
        this.updateAdvertiser();
      });
    } else {
      this.campaignService.startCampaign(campaign.id).then(result => {
        this.updateAdvertiser();
      });
    }
  }

  openEditCampaign(campaign: DashboardCampaign) {
    this.nameEdit = campaign.name;
    this.displayNameEdit = campaign.displayName;
    this.descriptionEdit = campaign.description;
    this.campaignIdOfEdit = campaign.id;
    this.showEditCampaign = true;
  }

  changeCampaign() {
    this.campaignService.updateCampaign(this.campaignIdOfEdit, this.nameEdit, this.displayNameEdit, this.descriptionEdit)
      .then(result => {
        this.updateAdvertiser();
        this.showEditCampaign = false;
      });
  }

  openDeleteCampaign(campaign: DashboardCampaign) {
    this.selectedDeleteCampaign = campaign;
    this.showRemoveCampaign = true;
  }

  deleteCampaign() {
    if (this.selectedDeleteCampaign) {
      this.campaignService.deleteCampaign(this.selectedDeleteCampaign.id, this.selectedDeleteCampaign.status).then(result => {
        delete this.selectedDeleteCampaign;
        this.updateAdvertiser();
        this.showRemoveCampaign = false;
      });
    }
  }

  deleteAffiliate() {
    if (this.selectedDeleteCampaign) {
      this.affiliateService.deleteAffiliate(this.selectedDeleteCampaign.id, this.selectedDeleteCampaign.status).then(result => {
        delete this.selectedDeleteCampaign;
        this.updateAdvertiser();
        this.showRemoveCampaign = false;
      });
    }
  }

  openExtendCampaign(campaign: DashboardCampaign) {
    this.updateAdvertiser().then(result => {
      this.selectedCampaignIdForExtension = campaign.id;
      this.selectedCampaignNameForExtension = campaign.name;
    });
  }

  closeExtendCampaign() {
    this.updateAdvertiser().then(result => {
      delete this.selectedCampaignIdForExtension;
      this.selectedCampaignNameForExtension = "";
    });
  }

  openExtendAffiliate(affiliate: DashboardCampaign) {
    this.updateAdvertiser().then(result => {
      this.selectedAffiliateIdForExtension = affiliate.id;
      this.selectedAffiliateNameForExtension = affiliate.name;
    });
  }

  closeExtendAffiliate() {
    this.updateAdvertiser().then(result => {
      delete this.selectedAffiliateIdForExtension;
      this.selectedAffiliateNameForExtension = "";
    });
  }

  // Tab 3: Abrechnungen
  initializeBills() {
    this.loadingBills = true;
    this.billService.getBillsOfAdvertiser(undefined, undefined, undefined, undefined, this.advertiserId).then((result) => {
      this.bills = result;

      this.billService.getMaxBillsOfAdvertiser(this.advertiserId).then((max) => {
        this.totalBills = max;
        this.loadingBills = false;
      });

    });
  }

  updateBills(event: TableLazyLoadEvent) {
    this.loadingBills = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.billService.getBillsOfAdvertiser(event.first, rows, sortField, sortOrder, this.advertiserId).then((result) => {
      this.bills = result;

      this.billService.getMaxBillsOfAdvertiser(this.advertiserId).then((max) => {
        this.totalBills = max;
        this.loadingBills = false;
      });
    });
  }

  downloadPDF(billId: string) {
    this.fileService.downloadBill(billId);
  }

  getDefaultSortOrder() :number {
    return -1;
  }

  // Tab 4: Tags
  initializeTags() {
    this.loadingTags = true;
    this.affiliateService.getTagsOfAdvertiser(this.advertiserId).then((result) => {
      this.tags = result;

      this.affiliateService.getMaxTagsOfAdvertiser(this.advertiserId).then((max) => {
        this.totalRecordsTags = max;
        this.loadingTags = false;
      });

    });
  }

  updateTags(event: TableLazyLoadEvent) {
    this.loadingTags = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.affiliateService.getTagsOfAdvertiser(this.advertiserId, event.first, rows, sortField, sortOrder).then((result) => {
      this.tags = result;

      this.affiliateService.getMaxTagsOfAdvertiser(this.advertiserId).then((max) => {
        this.totalRecordsTags = max;
        this.loadingTags = false;
      });
    });
  }

  copyLink(id: string) {
    const link = document.createElement('textarea');
    link.style.position = 'fixed';
    link.style.left = '0';
    link.style.top = '0';
    link.style.opacity = '0';
    link.value = id;
    document.body.appendChild(link);
    link.focus();
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
  }
}
