import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {KeywordService} from '../../services/keyword.service';
import {RoutingService} from '../../services/routing.service';
import {MathHelper} from '../../services/math.helper';
import {AdvertiserService} from '../../services/advertiser.service';
import {IpService} from '../../services/ip.service';

@Component({
  selector: 'advertiser-register',
  templateUrl: './advertiser-register.component.html',
  styleUrls: ['./advertiser-register.component.scss']
})
export class AdvertiserRegisterComponent implements OnInit {

  // Grundsätzliches
  public companyName!: string;
  public website!: string;
  public taxNumber!: string;

  // Adresse
  public street!: string;
  public nr!: number;
  public postalCode!: string;
  public city!: string;
  public country: any;

  // Kontakt
  public firstname!:string;
  public lastname!:string;
  public email!:string;
  public telephone!:number;

  public password!:string;
  public passwordToo!:string;

  public working = false;
  public registerSent = false;

  // Länderauswahl
  public countriesDropDownList: any[] = [];

  public emailError = false;

  public acceptTermsAndConditions: boolean = false;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public keywordService: KeywordService, public advertiserService: AdvertiserService,
              public routingService: RoutingService, public mathHelper: MathHelper,
              public ipService: IpService) {
  }

  ngOnInit(): void {

    this.keywordService.getAllCountries().then((countries) => {
      this.countriesDropDownList = [];
      countries.forEach((country) => {
        this.countriesDropDownList.push(
          {
            // @ts-ignore
            item_id: country.id,
            // @ts-ignore
            item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
          });
      });
    }).catch((err) => {
      console.log(err);
    });

  }

  checkAllValues(): boolean {
    let registerAllowed: boolean = true;

    if (!this.companyName) {
      registerAllowed = false;
    }

    if (!this.street) {
      registerAllowed = false;
    }

    if (!this.nr) {
      registerAllowed = false;
    }

    if (!this.postalCode) {
      registerAllowed = false;
    }

    if (!this.city) {
      registerAllowed = false;
    }

    if (!this.firstname) {
      registerAllowed = false;
    }

    if (!this.lastname) {
      registerAllowed = false;
    }

    if (!this.email) {
      registerAllowed = false;
    }

    if (!this.password) {
      registerAllowed = false;
    }

    if (!this.passwordToo) {
      registerAllowed = false;
    }

    if (this.password !== this.passwordToo) {
      registerAllowed = false;
    }

    if (!this.acceptTermsAndConditions) {
      registerAllowed = false;
    }

    return registerAllowed;
  }

  register() {
    this.working = true;

    if (this.checkAllValues()) {

      this.ipService.getIp().then(res => {
        this.advertiserService.registerAdvertiser(this.companyName, this.website, this.taxNumber, this.street, this.nr,
          this.postalCode, this.city, this.country.item_id, this.firstname, this.lastname, this.email, this.telephone, this.password,
          this.acceptTermsAndConditions, res.ip).then(result => {
            this.working = false;
            this.registerSent = true;
            this.emailError = false;

        }).catch(err => {
          this.working = false;

          if (err.code === 'Manticore') {
            this.emailError = true;
          } else {
            this.emailError = false;
            console.log(err);
          }

        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
