<div *ngIf="this.globalService.advertiser">
    <div class="headline">{{i18nService.localize().AdvertiserProfileComponent.ProfilHeader}}</div>
    <div class="p-col-12">
        <div class="p-grid abstand">

            <div class="p-col-12 start">
                <div class="p-grid">

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2" style="padding: 3em 1em 4em 4em;">
                        <div>
                          <div *ngIf="!globalService.advertiser.logoURL" class="p-text-center"
                               style="height: 170px; width: 170px; background: #1d60a4; border-radius: 500px;">
                            <img class="defaultPic" src="/assets/images/Icons/Navi_Profil_blau.svg" alt="">
                          </div>
                          <img src="{{globalService.advertiser.logoURL}}" style="height: 170px; width: 170px; border-radius: 500px;"
                               *ngIf="globalService.advertiser.logoURL">
                        </div>
                        <div style="text-align: right;">
                          <button pButton pRipple type="button" (click)="showChangeLogo()" icon="fas fa-pencil-alt"
                                  [disabled]=changeLogo style="margin-right: .25em" class="p-button-rounded"></button>
                        </div>

                        <p-dialog [(visible)]="changeLogo" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
                                  [resizable]="false">
                            <ng-template pTemplate="header">
                                <div class="headline">{{i18nService.localize().AdvertiserProfileComponent.UploadProfilLogoHeader}}</div>
                            </ng-template>
                            <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png"
                                          [maxFileSize]=1500000 id="logo" [showUploadButton]="false"
                                          [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                                          chooseLabel="{{i18nService.localize().AdvertiserProfileComponent.ChooseButton}}"
                                          cancelLabel="{{i18nService.localize().AdvertiserProfileComponent.CancelButton}}"
                                          *ngIf="changeLogo">
                              <ng-template pTemplate="content">
                                <ul *ngIf="uploadedPictures.length">
                                  <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes
                                  </li>
                                </ul>
                              </ng-template>
                            </p-fileUpload>

                          <div class="p-col-12">
                            <p-messages severity="error" *ngIf="!picturesOk">
                              <ng-template pTemplate>
                                <span class="custom-message">
                                  {{i18nService.localize().AdvertiserProfileComponent.PicError}}
                                </span>
                              </ng-template>
                            </p-messages>
                          </div>
                            <div style="text-align: right; margin-top: 1em">
                              <button pButton pRipple type="button" icon="pi pi-check" (click)="uploadPic()"
                                      class="p-button-rounded">
                              </button>
                              <button pButton pRipple type="button" icon="pi pi-times" (click)="changeLogo=false"
                                      class="p-button-rounded" style="margin-left: 0.5em">
                              </button>
                            </div>
                        </p-dialog>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{this.globalService.advertiser.companyName.toUpperCase()}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeProfile">
                                  <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.StreetNr}}</p>
                                  <p style="margin-top: 0" *ngIf="!this.globalService.advertiser.street">
                                    {{i18nService.localize().AdvertiserProfileComponent.NotSpecified}}
                                  </p>
                                  <p style="margin-top: 0" *ngIf="this.globalService.advertiser.street">{{this.globalService.advertiser.street}}
                                        {{this.globalService.advertiser.nr}}
                                  </p>
                                  <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.City}}</p>
                                  <p style="margin-top: 0" *ngIf="!this.globalService.advertiser.city">
                                    {{i18nService.localize().AdvertiserProfileComponent.NotSpecified}}
                                  </p>
                                  <p style="margin-top: 0" *ngIf="this.globalService.advertiser.city">
                                    {{this.globalService.advertiser.postalCode}} {{this.globalService.advertiser.city}}
                                  </p>
                                  <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.Country}}</p>
                                  <p style="margin-top: 0" *ngIf="!this.globalService.advertiser.country">
                                    {{i18nService.localize().AdvertiserProfileComponent.NotSpecified}}
                                  </p>
                                  <p style="margin-top: 0" *ngIf="this.globalService.advertiser.country">
                                    {{this.globalService.advertiser.country}}
                                  </p>
                                </div>
                                <div *ngIf="changeProfile">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-8">
                                            <label for="street">{{i18nService.localize().AdvertiserProfileComponent.Street}}:</label>
                                            <input id="street" type="text" pInputText
                                                [(ngModel)]="this.globalService.advertiser.street">
                                        </div>
                                        <div class="p-field p-col-4">
                                            <label for="nr">{{i18nService.localize().AdvertiserProfileComponent.Nr}}:</label>
                                            <input id="nr" type="text" pInputText (input)="checkNumberAndHyphen(this.globalService.advertiser.nr)"
                                                [(ngModel)]="this.globalService.advertiser.nr">
                                        </div>
                                        <div class="p-col-12">
                                          <p-messages severity="error" *ngIf="showValidationMessage && i18nService.localize().AdvertiserProfileComponent.Nr.length > 0">
                                            <ng-template pTemplate>
                                            <span class="custom-message">
                                              {{i18nService.localize().AdvertiserProfileComponent.InputError}}
                                            </span>
                                            </ng-template>
                                          </p-messages>
                                        </div>
                                      <div class="p-field p-col-12">
                                            <label for="postalCode">{{i18nService.localize().AdvertiserProfileComponent.PostalCode}}:</label>
                                            <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="this.globalService.advertiser.postalCode">
                                        </div>
                                        <div class="p-field p-col-12">
                                            <label for="city">{{i18nService.localize().AdvertiserProfileComponent.City}}:</label>
                                            <input id="city" type="text" pInputText
                                                [(ngModel)]="this.globalService.advertiser.city">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p-footer>
                                <button pButton pRipple type="button" (click)="changeProfileInfo()"
                                    icon="fas fa-pencil-alt" *ngIf="!changeProfile" style="margin-right: .25em"
                                    class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveProfileChanges()"
                                        icon="fas fa-check" *ngIf="changeProfile" style="margin-right: .25em" [disabled]="showValidationMessage"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.SaveChangesTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="abortProfileChanges()"
                                        icon="fas fa-times" *ngIf="changeProfile"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AdvertiserProfileComponent.ContactHeader}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeContact">
                                  <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.Name}}</p>
                                  <p style="margin-top: 0" *ngIf="!this.globalService.advertiser.firstname">
                                    {{i18nService.localize().AdvertiserProfileComponent.NotSpecified}}
                                  </p>
                                  <p style="margin-top: 0" *ngIf="this.globalService.advertiser.firstname">
                                    {{this.globalService.advertiser.firstname}} {{this.globalService.advertiser.lastname}}
                                  </p>
                                  <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.Email}}</p>
                                  <p style="margin-top: 0">
                                    {{this.globalService.advertiser.email}}
                                  </p>
                                </div>
                                <div *ngIf="changeContact">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12">
                                            <label for="name">{{i18nService.localize().AdvertiserProfileComponent.Firstname}}:</label>
                                            <input id="name" type="text" pInputText
                                                [(ngModel)]="this.globalService.advertiser.firstname">
                                        </div>
                                        <div class="p-field p-col-12">
                                            <label for="lastname">{{i18nService.localize().AdvertiserProfileComponent.Lastname}}:</label>
                                            <input id="lastname" type="text" pInputText
                                                [(ngModel)]="this.globalService.advertiser.lastname">
                                        </div>
                                        <div class="p-field p-col-12">
                                            <label for="email">{{i18nService.localize().AdvertiserProfileComponent.Email}}:</label>
                                            <input id="email" type="text" pInputText
                                                [(ngModel)]="this.globalService.advertiser.email">
                                        </div>
                                    </div>

                                  <div class="p-col-12">
                                    <p-messages severity="error" *ngIf="emailError">
                                      <ng-template pTemplate>
                                        <span class="custom-message">
                                          {{i18nService.localize().AdvertiserProfileComponent.EmailError}}
                                        </span>
                                      </ng-template>
                                    </p-messages>
                                  </div>

                                </div>
                            </div>
                            <p-footer>
                                <button pButton pRipple type="button" (click)="changeContactInfo()"
                                    icon="fas fa-pencil-alt" *ngIf="!changeContact" style="margin-right: .25em"
                                    class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveContactChanges()"
                                        icon="fas fa-check" *ngIf="changeContact" style="margin-right: .25em"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.SaveChangesTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="abortContactChanges()"
                                        icon="fas fa-times" *ngIf="changeContact"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AdvertiserProfileComponent.TaxHeader}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeTax">
                                    <p class="font-weight-bold">{{i18nService.localize().AdvertiserProfileComponent.Tax}}</p>
                                    <p style="margin-top: 0; margin-bottom: 4.5em; word-wrap: anywhere;" *ngIf="this.globalService.advertiser.taxNumber">{{this.globalService.advertiser.taxNumber}}</p>
                                    <p style="margin-top: 0; margin-bottom: 4.5em;" *ngIf="!this.globalService.advertiser.taxNumber">
                                      {{i18nService.localize().AdvertiserProfileComponent.NoTax}}
                                    </p>
                                </div>
                                <div *ngIf="changeTax">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12">
                                            <label for="tax">{{i18nService.localize().AdvertiserProfileComponent.Tax}}:</label>
                                            <input id="tax" type="text" pInputText maxlength="30" [(ngModel)]="this.globalService.advertiser.taxNumber" (input)="checkNumberAndHyphen(this.globalService.advertiser.taxNumber)">
                                        </div>
                                    </div>
                                  <div class="p-col-12">
                                    <p-messages severity="error" *ngIf="showValidationMessage && this.globalService.advertiser.taxNumber.length > 0">
                                      <ng-template pTemplate>
                                            <span class="custom-message">
                                              {{i18nService.localize().AdvertiserProfileComponent.InputError}}
                                            </span>
                                      </ng-template>
                                    </p-messages>
                                  </div>
                                </div>
                            </div>
                            <p-footer>
                              <button pButton pRipple type="button" (click)="startStripePortal()"
                                      *ngIf="!changeContact" style="margin-right: 20px;"
                                      pTooltip="{{i18nService.localize().AdvertiserProfileComponent.StripeCustomerPortal}}"
                                      class="p-button-rounded">STRIPE
                              </button>
                                <button pButton pRipple type="button" (click)="changeTaxInfo()"
                                    icon="fas fa-pencil-alt" *ngIf="!changeTax" style="margin-right: .25em"
                                    class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveTaxChanges()"
                                        icon="fas fa-check" *ngIf="changeTax" style="margin-right: .25em" [disabled]="showValidationMessage"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.SaveChangesTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="abortTaxChanges()"
                                        icon="fas fa-times" *ngIf="changeTax"
                                        pTooltip="{{i18nService.localize().AdvertiserProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12  p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AdvertiserProfileComponent.AccountHeader}}</h2>
                            <div style="margin: 1em;">
                                <div class="p-col-12" *ngIf="!changePassword&&!deleteAccount">
                                    <button pButton pRipple type="button"
                                            label="{{i18nService.localize().AdvertiserProfileComponent.ChangePasswordButton}}"
                                            class="p-button-rounded" (click)="openPasswordDialog()">
                                    </button>
                                </div>
                                <div class="p-col-12" *ngIf="!deleteAccount&&!changePassword">
                                    <button pButton pRipple type="button"
                                            label="{{i18nService.localize().AdvertiserProfileComponent.DownloadProfileInfos}}"
                                            class="p-button-rounded" (click)="downloadProfileInformation()">
                                    </button>
                                </div>
                                <div class="p-col-12" *ngIf="!deleteAccount&&!changePassword">
                                  <button pButton pRipple type="button" *ngIf="!globalService.advertiser.allowAdminAccess"
                                          pTooltip="{{i18nService.localize().AdvertiserProfileComponent.AllowAccessTooltip}}"
                                          label="{{i18nService.localize().AdvertiserProfileComponent.AllowAccessButton}}"
                                          style="margin-bottom: 1.7em;" class="p-button-rounded" (click)="grantAdminAccess()">
                                  </button>
                                  <p class="info" *ngIf="globalService.advertiser.allowAdminAccess">
                                    {{i18nService.localize().AdvertiserProfileComponent.AllowAccessInfoText}}
                                  </p>
                                </div>
                                <div class="p-col-12" *ngIf="!deleteAccount&&!changePassword">
                                    <button pButton pRipple type="button"
                                            label="{{i18nService.localize().AdvertiserProfileComponent.DeleteAccountButton}}"
                                            class="p-button-rounded p-button-danger" (click)="openRemoveConfirmation()">
                                    </button>
                                </div>
                                <div *ngIf="changePassword">
                                    <div class="p-fluid">
                                        <div class="p-field">
                                            <label for="oldPassword">{{i18nService.localize().AdvertiserProfileComponent.OldPassword}}</label>
                                            <input pInputText type="password" name="oldPassword"
                                                id="oldPassword" [(ngModel)]="this.oldPassword"
                                                class="form-control" />
                                        </div>
                                        <div class="p-field">
                                            <label for="newPassword">{{i18nService.localize().AdvertiserProfileComponent.NewPassword}}</label>
                                            <input pInputText type="password" name="newPassword"
                                                id="newPassword" [(ngModel)]="this.newPassword"
                                                class="form-control" />
                                        </div>
                                        <div class="p-field">
                                            <label for="newPasswordToo">{{i18nService.localize().AdvertiserProfileComponent.NewPasswordAgain}}</label>
                                            <input pInputText type="password" name="newPasswordToo"
                                                [(ngModel)]="this.newPassword2" id="newPasswordToo"
                                                class="form-control" />
                                        </div>
                                        <p class="text-muted">
                                          {{i18nService.localize().AdvertiserProfileComponent.PasswordHint}}
                                        </p>
                                        <p id="pwdErrMsg1" class="error" *ngIf="this.passwordError">
                                          {{i18nService.localize().AdvertiserProfileComponent.PasswordError}}
                                        </p>
                                        <p id="pwdErrMsg2" class="error" *ngIf="this.authError">
                                          {{i18nService.localize().AdvertiserProfileComponent.AuthError}}
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="deleteAccount">
                                  <div class="p-fluid">
                                    <p style="margin-bottom: 1em;">
                                      {{i18nService.localize().AdvertiserProfileComponent.DeleteAccountText}}
                                    </p>
                                    <div class="p-field">
                                      <label for="oldPassword2">
                                        {{i18nService.localize().AdvertiserProfileComponent.Password}}
                                      </label>
                                      <input pInputText type="password" name="oldPassword" id="oldPassword2"
                                             [(ngModel)]="this.oldPassword" class="form-control" />
                                    </div>
                                    <p id="pwdErrMsg3" class="error" *ngIf="this.authError2">
                                      {{i18nService.localize().AdvertiserProfileComponent.AuthError}}
                                    </p>
                                  </div>
                                </div>
                            </div>
                            <p class="info" *ngIf="this.passwordChanged">
                              {{i18nService.localize().AdvertiserProfileComponent.PasswordChanged}}
                            </p>
                            <p-footer>
                                  <button pButton pRipple type="button" icon="fas fa-check"
                                          pTooltip="{{i18nService.localize().AdvertiserProfileComponent.ChangePasswordTooltip}}"
                                          tooltipPosition="left" class="p-button-rounded" (click)="savePasswordChange()"
                                          *ngIf="changePassword&&!deleteAccount" style="margin-right: .25em;">
                                  </button>
                                  <button pButton pRipple type="button" icon="fas fa-check"
                                          pTooltip="{{i18nService.localize().AdvertiserProfileComponent.DeleteAccountTooltip}}"
                                          tooltipPosition="left" class="p-button-rounded" (click)="removeAccount()"
                                          *ngIf="!changePassword&&deleteAccount" style="margin-right: .25em;">
                                  </button>
                                  <button pButton pRipple type="button" icon="fas fa-times"
                                          pTooltip="{{i18nService.localize().AdvertiserProfileComponent.CancelTooltip}}"
                                          tooltipPosition="left" class="p-button-rounded p-button-secondary"
                                          (click)="abort()" *ngIf="changePassword||deleteAccount">
                                  </button>
                            </p-footer>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
