import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from "../../../services/global.service";
import {AdvertiserService} from "../../../services/advertiser.service";
import {IpService} from "../../../services/ip.service";
import {CampaignService} from "../../../services/campaign.service";
import {RoutingService} from "../../../services/routing.service";
import {I18nService} from "../../../services/i18n.service";
import {AffiliateService} from "../../../services/affiliate.service";
import {ActivatedRoute, Router} from "@angular/router";
import {WizardWIP} from "../../../model/wizard-wip.model";

@Component({
  selector: 'app-wizard-campaigns',
  templateUrl: './wizard-campaigns.component.html',
  styleUrls: ['./wizard-campaigns.component.scss']
})
export class WizardCampaignsComponent implements OnInit {
  @Input() wipCampaigns: WizardWIP[] = [];
  @Input() showGridLine: boolean = false;
  // For Agency
  @Input() advertiserId?: string;

  constructor(public globalService: GlobalService, public advertiserService: AdvertiserService,
              public ipService: IpService, public campaignService: CampaignService,
              public routingService: RoutingService, public i18nService: I18nService,
              public affiliateService: AffiliateService, public router: Router, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  gotoPayNewWizard(campaign?: WizardWIP) {
    if (this.globalService.agency) {
      this.router.navigate(['/advertiser/campaign/create'], {queryParams:{ advertiserId: this.advertiserId, campaignId: campaign?.id }});
    } else if (this.globalService.advertiser) {
      this.advertiserService.updateAdvertiserInformation().then(result => {
        this.router.navigate(['/advertiser/campaign/create'], {queryParams: {campaignId: campaign?.id}});
      });
    }
  }

}
