<p-megaMenu [model]="itemsMini" orientation="vertical" *ngIf="!globalService.showMenuText&&menuIsShown">
  <hr *ngIf="(globalService.streamer&&!globalService.streamer.inRegister)||globalService.advertiser">
</p-megaMenu>
<p-megaMenu [model]="items" orientation="vertical" *ngIf="globalService.showMenuText&&menuIsShown">
  <ng-template pTemplate="end">
    <hr *ngIf="(globalService.streamer&&!globalService.streamer.inRegister)||globalService.advertiser">
    <div class="menuText" *ngIf="globalService.streamer&&!globalService.streamer.inRegister">
      {{this.i18nService.localize().MenuComponent.CurrentStatus}}<br>
      <div class="status-bronze" *ngIf="globalService.streamer.tier==1">{{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
      <div class="status-silber" *ngIf="globalService.streamer.tier==2">{{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
      <div class="status-gold" *ngIf="globalService.streamer.tier==3">{{globalService.streamer.getStatusAsString(this.i18nService.getCurrentLang())}}</div>
    </div>
    <div class="menuText policyText">
      <a class="lan {{isLang('DE') ? 'active' : ''}}" (click)="changeLang('DE')">DE <span class="lanSeparator">|&nbsp;</span></a>
      <a class="lan {{isLang('EN') ? 'active' : ''}}" (click)="changeLang('EN')">EN</a>
      <ul>
        <li><a style="color: red; cursor: pointer;" *ngIf="globalService.streamer&& !this.globalService.streamer.inRegister" (click)="deleteStreamerModal=true">{{this.i18nService.localize().MenuComponent.DeleteAccount}}</a></li>
        <li><a href="{{this.globalService.links.legalLink}}" target="_blank">{{this.i18nService.localize().MenuComponent.LegalNoticeLink}}</a></li>
        <li><a href="{{this.globalService.links.privacyLink}}" target="_blank">{{this.i18nService.localize().MenuComponent.PrivacyPolicyLink}}</a></li>
        <li><a href="{{this.globalService.links.termsLink}}" target="_blank">{{this.i18nService.localize().MenuComponent.TermsOfUseLink}}</a></li>
        <li><a href="{{this.globalService.links.cookiesLink}}" target="_blank">{{this.i18nService.localize().MenuComponent.CookiesInfoLink}}</a></li>
        <li><a href="{{this.globalService.links.conditionsLink}}" target="_blank">{{this.i18nService.localize().MenuComponent.TermsAndConditionsLink}}</a></li>
      </ul>
    </div>
  </ng-template>
</p-megaMenu>

<streamer-delete-dialog [show]="deleteStreamerModal" (showChange)="deleteStreamerModal=$event">
</streamer-delete-dialog>
