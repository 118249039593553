<div
  *ngIf="this.globalService.agency && (this.globalService.agency.advertisers.length !== 0 || this.globalService.agency.activeCampaigns.length !== 0); else noAdvertiserNoActiveCampaigns">
  <div class="p-col-12">
    <div class="p-grid abstand" *ngIf="agencyService.agency && agencyService.agency.is_balance_enabled">
      <div class="p-col-12" style="display: flex;">
        <div class="p-col-4" *ngIf="agencyService.agency.balance > 0 && daysLeftForBalance >= 0">
          <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.CreditHeader.toUpperCase() }}</h2>
          <div class="p-card" style="padding-top: 0.75em;">
            <div class="p-card-body">
              <h3 class="p-text-center">{{ i18nService.localize().AgencyDashboardComponent.BookedAdBudget }} {{ (agencyService.agency.balance + agencyService.agency.usedBalance).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €</h3>
              <p-chart type="doughnut" [data]="balanceChartData" [options]="balanceChartOptions" />
            </div>
            <div class="p-card-footer p-d-flex p-justify-center p-align-center">
              <h3>{{ daysLeftForBalanceText }}</h3>
            </div>
          </div>
        </div>
        <div class="p-col-4" *ngIf="agencyService.agency && agencyService.agency.totalAeBonus > 0">
          <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.AchievedDiscount.toUpperCase() }}</h2>
          <div class="p-card" style="padding-top: 0.75em;">
            <div class="p-card-body" *ngIf="agencyService.agency">
              <p-table
                [value]="agencyService.agency.aeBonus"
                [scrollable]="true"
                scrollHeight="600px"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{ i18nService.localize().AgencyDashboardComponent.Amount }}</th>
                    <th>{{ i18nService.localize().AgencyDashboardComponent.Date }}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bonus>
                  <tr>
                    <td class="p-text-left">{{ bonus.amount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €</td>
                    <td class="p-text-left">{{ bonus.issued_at | date:'dd.MM.yyyy':'de-DE' }}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td><strong>{{ i18nService.localize().AgencyDashboardComponent.Total }}:</strong> {{ agencyService.agency.totalAeBonus.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €</td>
                    <td></td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.CampaignsHeader }}</h2>
      <div style="padding-top: 0.75em;" *ngIf="this.globalService.agency.activeCampaigns.length > 0">
        <p-carousel [value]="this.globalService.agency.activeCampaigns" [numVisible]="5" [numScroll]="1"
                    [circular]="false" [showIndicators]="false" [responsiveOptions]="responsiveOptions">
          <ng-template let-campaign pTemplate="item">
            <div class="item" (click)="showCampaign(campaign)" style="cursor: pointer;">
              <div class="p-mb-3">
                <img [src]="campaign.logo_url" [alt]="campaign.name" class="item-pic">
              </div>
              <div>
                <p class="campaign-header"
                   pTooltip="Status: {{campaign.status}}">{{ campaign.name.toUpperCase() }}</p>
                <ul>
                  <li>{{ campaign.advertiser_name }}</li>
                  <li
                    *ngIf="campaign.streamer > 0">{{ campaign.streamer }} {{ i18nService.localize().AgencyDashboardComponent.Streamer }}
                  </li>
                  <li
                    *ngIf="campaign.type === 'CAMPAIGN'">{{ campaign.clicks_views }} {{ i18nService.localize().AgencyDashboardComponent.Views }}
                  </li>
                  <li
                    *ngIf="campaign.type === 'AFFILIATE'">{{ campaign.clicks_views }} {{ i18nService.localize().AgencyDashboardComponent.Clicks }}
                  </li>
                </ul>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>

    <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="viewChart && clickChart">
      <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.ViewChartHeader }}</h2>
      <div style="display: flex; justify-content: space-between;">
        <div style="width: 48%;">
          <p-card>
            <div style="margin: 1.5em;">
              <p-chart type="line" [data]="viewChart" [options]="chartOptions"></p-chart>
            </div>
          </p-card>
        </div>
        <div style="width: 48%;">
          <p-card>
            <div style="margin: 1.5em;">
              <p-chart type="line" [data]="clickChart" [options]="chartOptions"></p-chart>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <div class="p-col-12" *ngIf="!viewChart || !clickChart">
      <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.StreamedGamesHeader }}</h2>
      <p-card>
        <div style="margin: 1em;">
          <p-dataView [value]="this.gameStreamStatistics" [paginator]="true" [rows]="3">
            <ng-template let-gameStream pTemplate="listItem">
              <div class="p-col-12 list-object">
                <div class="p-grid data-grid">
                  <div class="p-col-12 p-md-2 p-lg-1 p-xl-1">
                    <img [src]="gameStream.box_art" [alt]="gameStream.name" [title]="gameStream.name"/>
                  </div>
                  <div class="p-col-12 p-md-7 p-lg-9 p-xl-9">
                    <p style="color: #1d60a4; font-weight: bold; font-size: 14pt;">{{ gameStream.name }}</p>
                  </div>
                  <div class="p-col-12 p-md-3 p-lg-2 p-xl-2" style="text-align: right;">
                    <p
                      style="font-size: 14pt;">{{ gameStream.number_of_streams }} {{ i18nService.localize().AgencyDashboardComponent.Streams }}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </div>
      </p-card>
    </div>

  </div>

  <div class="p-grid abstand" *ngIf="displayDetails" style="margin-top: 1em;">
    <advertiser-campaign-details [campaignId]="campaignId" *ngIf="campaignType === 'CAMPAIGN'"
                                 [numberOfStreamer]="numberOfStreamer" (closeEmit)="closeCampaign()">
    </advertiser-campaign-details>
    <advertiser-affiliate-details [affiliateId]="campaignId" *ngIf="campaignType === 'AFFILIATE'"
                                  [numberOfStreamer]="numberOfStreamer" (closeEmit)="closeCampaign()">
    </advertiser-affiliate-details>
  </div>
</div>

<ng-template #noAdvertiserNoActiveCampaigns>
  <div class="p-col-12">
    <div class="p-grid abstand">
      <div class="p-col-12" style="display: flex;">
        <div class="p-col-4">
          <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.CreditHeader }}</h2>
          <div class="p-card" style="padding-top: 0.75em;">
            <div class="p-card-body">
              <p-chart type="doughnut" [data]="balanceChartData" [options]="balanceChartOptions" />
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <h2 class="card-headline">{{ i18nService.localize().AgencyDashboardComponent.NoAdvertiserNoCampaigns }}</h2>
        <p-card>
          <div style="margin: 1em;">
            <p>{{ i18nService.localize().AgencyDashboardComponent.NoAdvertiserOrCampaignsCreated }}</p>
            <p>{{ i18nService.localize().AgencyDashboardComponent.Click }} <a href="/agency/advertisers">{{ i18nService.localize().AgencyDashboardComponent.Here }}</a> {{ i18nService.localize().AgencyDashboardComponent.CreateAdvertiser }}</p>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</ng-template>
