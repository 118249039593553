import {MultilangModel} from '../multilang.model';
import {Keyword} from "./keyword.model";

export class Keywordgroup extends MultilangModel {

  constructor(
    public id: string,
    langMap: Map<string, object>,
    public group: Keyword[]
  ) {
    super(langMap);
  }


}
