export class Question {

  public id!: string;

  public type!: string;

  public message!: string;

  public answer!: string;

  public status!: string;

  public reason!: string;

  constructor() {
  }

}
