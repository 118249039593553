export class ClickSales {

  public id!: string;

  public invoiceDate!: string;

  public clicksSales!: string;

  public earnings!: string;

  public preliminary: boolean = false;

  public affiliateName!: string;

  public streamerBillId: string = '';

  constructor(private currentLang: string) {

  }

  public getStatus(): string {
    if (this.preliminary === true) {
      if (this.currentLang == 'DE') {
        return 'In Arbeit';
      } else {
        return 'In progress';
      }
    }

    if (this.streamerBillId !== '') {
      if (this.currentLang == 'DE') {
        return 'Abgerechnet';
      } else {
        return 'Settled';
      }
    }

    if (this.currentLang == 'DE') {
      return 'Bereit zur Auszahlung';
    } else {
      return 'Ready for payment';
    }
  }

}
