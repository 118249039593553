<div *ngIf="this.globalService.streamer">
  <div class="headline">{{ i18nService.localize().StreamerRegisterComponent.RegisterHeader }}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <!--      <div class="p-col-12" style="margin-top: 2em;">-->
      <!--        <div class="p-grid">-->
      <!--          <div class="col-step step">-->
      <!--            <p [class]="this.step === 0 ? 'step-number-selected' : 'step-number'">1</p>-->
      <!--          </div>-->
      <!--          <div class="col-step step">-->
      <!--            <p [class]="this.step === 1 ? 'step-number-selected' : 'step-number'">2</p>-->
      <!--          </div>-->
      <!--          <div class="col-step step">-->
      <!--            <p [class]="this.step === 2 ? 'step-number-selected' : 'step-number'">3</p>-->
      <!--          </div>-->
      <!--          <div class="col-step-small step-end">-->
      <!--            <p [class]="this.step === 3 ? 'step-number-selected' : 'step-number'">4</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="p-col-12" *ngIf="step === 0">
        <h2 class="card-headline">{{ i18nService.localize().StreamerRegisterComponent.PersonalHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">

              <p class="headline-in-step" style="margin: 0;">
                {{ i18nService.localize().StreamerRegisterComponent.Welcome }}
              </p>

              <div *ngIf="globalService.enableYoutubeAuth" class="p-grid">
                <div class="p-col-12">
                  <p style="margin-bottom: 1em;">
                    {{ i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterText1 }}
                    <b>{{ registerWithNetwork }}</b>{{ i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterText2 }}
                    <b>{{ registerWithNetwork }}</b>{{ i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterText3 }}
                  </p>

                  <button pRipple pButton (click)="loginWithGoogle()" label="Login with Youtube"
                          class="p-button-rounded" style="background-color: #ff0000; border-color: #ff0000;"
                          *ngIf="registerWithNetwork === 'Twitch'">
                  </button>

                  <button pRipple pButton (click)="loginWithTwitch()" label="Login with Twitch"
                          class="p-button-rounded" style="background-color: #a970ff; border-color: #a970ff;"
                          *ngIf="registerWithNetwork === 'Google'">
                  </button>
                </div>
                <div class="p-col-12">
                  <p-tag value="{{createErrorLoginMessage()}}"
                         *ngIf="loginError==='loginError'" severity="danger" icon="pi pi-exclamation-triangle">
                  </p-tag>

                  <p *ngIf="loginError==='none'" style="margin-bottom: 0">
                    {{ i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterText4 }}
                  </p>
                </div>
              </div>

              <p style="margin-bottom: 3em;" *ngIf="!globalService.enableYoutubeAuth">
                {{ i18nService.localize().StreamerRegisterComponent.ProfileText }}
              </p>

              <p class="headline-in-step">
                {{ i18nService.localize().StreamerRegisterComponent.ProfileHeader }}
              </p>

              <p style="margin-bottom: 1em;" *ngIf="globalService.enableYoutubeAuth">
                {{ i18nService.localize().StreamerRegisterComponent.ProfileText }}
              </p>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="name"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Email }}
                    *:</label>
                  <input id="name" type="email" pInputText [(ngModel)]="email" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="birthday"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Birthday }}
                    *:</label>
                  <p-calendar id="birthday" [(ngModel)]="birthday" [readonlyInput]="false" [minDate]="minDate"
                              [maxDate]="maxDate" (onSelect)="checkAge()" dateFormat="dd.mm.yy"
                              [inputStyle]="{'border-radius':'12px'}" placeholder="dd.MM.yyyy">
                  </p-calendar>

                  <p-tag value="{{i18nService.localize().StreamerRegisterComponent.BirthdayError}}"
                         *ngIf="ageError" severity="danger" icon="pi pi-exclamation-triangle">
                  </p-tag>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="postalCode"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.PostalCode }}
                    *:</label>
                  <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="country"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Country }}
                    *:</label>
                  <p-dropdown id="country" [options]="streamercountries" [(ngModel)]="streamerCountry"
                              optionLabel="name" [showClear]="true"
                              [style]="{'border-radius': '12px', 'width': '100%'}"
                              [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                  </p-dropdown>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="gender"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.GenderStreamer }}
                    *:</label>
                  <p-selectButton [options]="genderOptions" id="gender" [(ngModel)]="gender" optionLabel="label"
                                  optionValue="value" [style]="{'border-radius':'12px'}">
                  </p-selectButton>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="attention"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Attention}}
                    *:</label>
                  <p-dropdown id="attention" [options]="attentionOptions" [(ngModel)]="streamerAttention"
                              optionLabel="name" [showClear]="true"
                              [style]="{'border-radius': '12px', 'width': '100%'}"
                              [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                  </p-dropdown>
                  <textarea style="width: 100%; margin-top: 1em; height: 50px" [(ngModel)]="attentionReason" *ngIf="streamerAttention.code == 'other'"> </textarea>
                </div>
              </div>
            </div>

            <div class="p-col-12" style="text-align: right;">
              <!--              <button pButton pRipple type="button" (click)="register(true)" *ngIf="!working"-->
              <!--                      label="{{i18nService.localize().StreamerRegisterComponent.EndButton}}" class="p-button-rounded"-->
              <!--                      [disabled]="!checkPersonalValues()" style="margin-right: .25em">-->
              <!--              </button>-->
              <button pButton pRipple type="button" class="p-button-rounded" iconPos="right"
                      (click)="goToNextStep()" icon="fas fa-chevron-right"
                      label="{{i18nService.localize().StreamerRegisterComponent.ContinueRegisterButton}}"
                      [disabled]="!checkPersonalValues()" style="margin-right: .25em">
              </button>
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12" *ngIf="step === 1">
        <h2 class="card-headline">{{ i18nService.localize().StreamerRegisterComponent.AudienceHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">
              <div class="ui-fluid">

                <!--                <p class="headline-in-step" style="margin-top: 0;">-->
                <!--                  {{i18nService.localize().StreamerRegisterComponent.StreamHeader}}-->
                <!--                </p>-->

                <div class="p-field" style="margin-bottom: 3em;">
                  <label style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Categories }}
                    :</label>
                  <div class="p-grid">
                    <div class="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3" *ngFor="let keyword of keywords">
                      <p-checkbox name="group1" value="{{keyword.item_id}}" [(ngModel)]="selectedKeywordItems"
                                  inputId="{{keyword.item_text}}"></p-checkbox>
                      <!--suppress XmlInvalidId -->
                      <label for="{{keyword.item_text}}" style="margin-left: 0.5em;">{{ keyword.item_text }}</label>
                    </div>
                  </div>
                </div>

                <div class="p-field" style="margin-bottom: 3em;">
                  <label for="countries"
                         style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Countries }}
                    :</label>
                  <ng-multiselect-dropdown3 id="countries"
                                            placeholder="{{i18nService.localize().StreamerRegisterComponent.CountriesPlaceholder}}"
                                            [settings]="this.dropdownSettings" [data]="countriesDropDownList"
                                            [(ngModel)]="selectedCountryItems">
                  </ng-multiselect-dropdown3>
                </div>

                <div style="margin-bottom: 3em;">
                  <label style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.FaceCam }}</label>
                  <div style="margin-top: 1em">
                  <span style="margin-right: 1em;" >
                      <p-radioButton name="facecamusage" [value]="true" [(ngModel)]="faceCamUsage"
                                     id="faceCamUsageYes"></p-radioButton>
                      <label for="faceCamUsageYes" style=" margin-left: 0.5em" class="ml-2">Ja</label>
                    </span>

                  <p-radioButton name="facecamusage" [value]="false" [(ngModel)]="faceCamUsage" id="faceCamUsageNo"></p-radioButton>
                  <label for="faceCamUsageNo" style=" margin-left: 0.5em"  class="ml-2">Nein</label>
                </div>
                </div>

                <div class="p-field" style="margin-bottom: 3em;">
                  <label style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.Gender }}
                    :</label>
                  <div class="slider">
                    <div class="slider">
                      <p-checkbox name="genderCheck" value="tmpPercGenderDivers" class="checkbox-slider"
                                  label="{{i18nService.localize().StreamerRegisterComponent.Divers}}"
                                  [(ngModel)]="selectedGenderValues"></p-checkbox>
                      <span class="value-span">{{ tmpPercGenderDivers }} %</span>
                    </div>
                    <p-slider [(ngModel)]="tmpPercGenderDivers"
                              (onChange)="updateGenderSlider('tmpPercGenderDivers')"></p-slider>
                  </div>

                  <div class="slider">
                    <div class="slider">
                      <p-checkbox name="genderCheck" value="tmpPercGenderMen" class="checkbox-slider"
                                  label="{{i18nService.localize().StreamerRegisterComponent.Male}}"
                                  [(ngModel)]="selectedGenderValues"></p-checkbox>
                      <span class="value-span">{{ tmpPercGenderMen }} %</span>
                    </div>
                    <p-slider [(ngModel)]="tmpPercGenderMen"
                              (onChange)="updateGenderSlider('tmpPercGenderMen')"></p-slider>
                  </div>

                  <div class="slider">
                    <div class="slider">
                      <p-checkbox name="genderCheck" value="tmpPercGenderWomen" class="checkbox-slider"
                                  label="{{i18nService.localize().StreamerRegisterComponent.Female}}"
                                  [(ngModel)]="selectedGenderValues"></p-checkbox>
                      <span class="value-span">{{ tmpPercGenderWomen }} %</span>
                    </div>
                    <p-slider [(ngModel)]="tmpPercGenderWomen"
                              (onChange)="updateGenderSlider('tmpPercGenderWomen')"></p-slider>
                  </div>
                </div>

                <div class="p-field">
                  <label style="font-weight: bold">{{ i18nService.localize().StreamerRegisterComponent.AgeRange }}
                    :</label>
                  <div class="slider">
                    <div class="slider">
                      <span
                        class="value-span">{{ ageRangeValues[0] + ' - ' + ageRangeValues[1] }} {{ i18nService.localize().StreamerRegisterComponent.Years }}</span>
                    </div>
                    <p-slider [(ngModel)]="ageRangeValues" [min]="12" [max]="60" [range]="true"
                              (onChange)="checkRange($event)" *ngIf="showSlider"></p-slider>
                  </div>

                </div>

              </div>
            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()"
                      *ngIf="!working" icon="fas fa-chevron-left"
                      label="{{i18nService.localize().StreamerRegisterComponent.BackButton}}"
                      iconPos="left" style="margin-right: .25em">
              </button>
              <!--              <button pButton pRipple type="button" class="p-button-rounded" iconPos="right"-->
              <!--                      [disabled]="!checkPersonalValues()" (click)="goToNextStep()"-->
              <!--                      icon="fas fa-chevron-right" label="{{i18nService.localize().StreamerRegisterComponent.ContinueButton}}"-->
              <!--                      style="margin-right: .25em">-->
              <!--              </button>-->
              <button pButton pRipple type="button" class="p-button-rounded" (click)="register(false)" [loading]=working
                      icon="fas fa-check" label="{{i18nService.localize().StreamerRegisterComponent.ReadyButton}}"
                      [disabled]="!checkPersonalValues()" style="margin-right: .25em">
              </button>
            </div>
          </div>
        </p-card>
      </div>

      <!-- ToDo: Vielleicht wieder einbauen oder wegschmeißen, wird sich zeigen
      <div class="p-col-12" *ngIf="this.step === 2">
        <h2 class="card-headline">{{i18nService.localize().StreamerRegisterComponent.AffiliateHeader}}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">

              <p class="headline-in-step" style="margin-top: 0;">
                {{i18nService.localize().StreamerRegisterComponent.AffiliateQuestion}}
              </p>

              <p style="margin-bottom: 1em;">
                {{i18nService.localize().StreamerRegisterComponent.AffiliateText}}
              </p>

              <p class="error" *ngIf="tooManyAffiliates">{{i18nService.localize().StreamerRegisterComponent.AffiliateError}}</p>
              <p-pickList [source]="affiliateSource" [target]="selectedAffiliates"
                          sourceHeader="{{i18nService.localize().StreamerRegisterComponent.AvailablePrograms}}"
                          targetHeader="{{i18nService.localize().StreamerRegisterComponent.SelectedPrograms}}"
                          [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'370px'}" [responsive]="true" [dragdrop]=true
                          [showSourceControls]="false" [showTargetControls]="false" (onMoveToTarget)="updateChanges()" (onMoveToSource)="updateChanges()"
                          (onMoveAllToSource)="updateChanges()" (onMoveAllToTarget)="updateChanges()" (onSourceSelect)="selectAffiliate($event)"
                          (onTargetSelect)="selectAffiliate($event)" filterBy="name,keywords" [showTargetFilter]="false">
                <ng-template let-affiliate pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <div class="p-grid" style="margin-top: 0.5em;">
                      <div class="p-col-2">
                        <img src="{{affiliate.logoURL}}" style="border-radius: .25rem;" width="55">
                      </div>
                      <div class="p-col-3" style="text-align: center">
                        {{affiliate.name}}
                        <span *ngIf="affiliate.requested" style="font-weight: bolder">{{i18nService.localize().StreamerRegisterComponent.Requested}}</span>
                      </div>
                      <div class="p-col-6" style="text-align: center">
                        <p-tag *ngFor="let keyword of affiliate.keywords" value="{{keyword}}" [rounded]="true"></p-tag>
                      </div>
                      <div class="p-col-1">
                        <button pButton pRipple type="button" (click)="this.showAffiliateDescription=true" icon="fas fa-info" class="p-button-rounded" style="z-index: 1;"></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-pickList>

            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()" iconPos="left"
                      icon="fas fa-chevron-left" label="{{i18nService.localize().StreamerRegisterComponent.BackButton}}"  style="margin-right: .25em"></button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToNextStep()"
                      [disabled]="tooManyAffiliates" icon="fas fa-chevron-right" iconPos="right"
                      label="{{i18nService.localize().StreamerRegisterComponent.ContinueButton}}" style="margin-right: .25em"></button>
            </div>
          </div>
        </p-card>

        <p-dialog position="center" [(visible)]="showAffiliateDescription" [blockScroll]="true" [closeOnEscape]="true" [closable]="true"
                  [draggable]="false" [style]="{width: '70%', maxWidth: '770px'}" [modal]="true" closeIcon="fas fa-times">
          <ng-template pTemplate="header">
            <div class="headline">{{i18nService.localize().StreamerRegisterComponent.DescriptionHeader}}</div>
          </ng-template>
          <p class="multi_lines_text" *ngIf="selectedAffiliate" [innerHTML]="selectedAffiliate.description" ></p>
        </p-dialog>
      </div>

      <div class="p-col-12" *ngIf="this.step === 3">
        <h2 class="card-headline">{{i18nService.localize().StreamerRegisterComponent.CampaignsHeader}}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em;">

            <div class="p-col-12">
              <p class="headline-in-step" style="margin-top: 0;">
                {{i18nService.localize().StreamerRegisterComponent.CampaignsInStreamHeader}}
              </p>
            </div>

            <div class="p-col-12 p-md-5 p-xl-3">
              <img src="assets/images/AdinLivestream.gif" alt="stream-example" width="300">
              <div style="margin-left: 1.5em;">
                <p class="link-header">
                  {{i18nService.localize().StreamerRegisterComponent.Adlink}}
                </p>
                <p class="ad-link" *ngIf="globalService.streamer">
                  {{this.globalService.streamer.ad_url}}
                </p>
              </div>
            </div>
            <div class="p-col-12 p-md-7 p-xl-9">
              <p class="display-text">
                {{i18nService.localize().StreamerRegisterComponent.CampaignText1}}
              </p>
              <p class="income-header">{{i18nService.localize().StreamerRegisterComponent.Payment}}:</p>
              <p class="display-text">
                {{i18nService.localize().StreamerRegisterComponent.CampaignText2}}
              </p>
              <p class="display-text">
                {{i18nService.localize().StreamerRegisterComponent.CampaignText3}}
              </p>
              <p class="income-header">{{i18nService.localize().StreamerRegisterComponent.Function}}:</p>
              <ul>
                <li>
                  {{i18nService.localize().StreamerRegisterComponent.FunctionText1}}
                </li>
                <li>
                  {{i18nService.localize().StreamerRegisterComponent.FunctionText2}}
                </li>
              </ul>
            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()" *ngIf="!working"
                      icon="fas fa-chevron-left" label="{{i18nService.localize().StreamerRegisterComponent.BackButton}}"  style="margin-right: .25em" iconPos="left">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="register(false)" [loading]=working
                      icon="fas fa-check" label="{{i18nService.localize().StreamerRegisterComponent.ReadyButton}}"
                      [disabled]="!checkAllValues()" style="margin-right: .25em">
              </button>
            </div>
          </div>
        </p-card>
      </div>
      -->

    </div>
  </div>
</div>
