<div *ngIf="this.globalService.advertiser">
  <div class="p-col-12">
    <div class="p-grid abstand" *ngIf="!displayDetails">

      <div class="p-col-12">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserDashboardComponent.CampaignsHeader}}</h2>
        <div style="padding-top: 0.75em;" *ngIf="this.globalService.advertiser.activeCampaigns.length>0">

            <!-- [autoplayInterval]="3000" -->
            <p-carousel [value]="this.globalService.advertiser.activeCampaigns" [numVisible]="updateScreenSize() / 430" [numScroll]="1"
                        [circular]="false" [showIndicators]="false" [responsiveOptions]="responsiveOptions">
              <ng-template let-campaign pTemplate="item">
                <div class="item" (click)="showCampaign(campaign)" style="cursor: pointer;">
                  <div class="p-mb-3">
                    <img [src]="campaign.logo_url" [alt]="campaign.name" class="item-pic">
                  </div>
                  <div>
                    <p class="campaign-header" pTooltip="Status: {{campaign.status}}">{{campaign.name.toUpperCase()}}</p>
                    <ul>
                      <li *ngIf="campaign.streamer>0">{{campaign.streamer}} {{i18nService.localize().AdvertiserDashboardComponent.Streamer}}</li>
                      <li *ngIf="campaign.type==='CAMPAIGN'">{{campaign.clicks_views}} {{i18nService.localize().AdvertiserDashboardComponent.Views}}</li>
                      <li *ngIf="campaign.type==='AFFILIATE'">{{campaign.clicks_views}} {{i18nService.localize().AdvertiserDashboardComponent.Clicks}}</li>
                    </ul>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="viewChart">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserDashboardComponent.ViewChartHeader}}</h2>
        <p-card>
          <div style="margin: 1.5em;">
            <p-chart type="line" *ngIf="viewChart" [data]="viewChart" [options]="chartOptions"></p-chart>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-lg-6 p-xl-6" *ngIf="clickChart">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserDashboardComponent.ClickChartHeader}}</h2>
        <p-card>
          <div style="margin: 1.5em;">
            <p-chart type="line" *ngIf="clickChart" [data]="clickChart" [options]="chartOptions"></p-chart>
          </div>
        </p-card>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserDashboardComponent.StreamedGamesHeader}}</h2>
        <p-card>
          <div style="margin: 1em;">
            <p-dataView [value]="this.gameStreamStatistics" [paginator]="true" [rows]="3">
              <ng-template let-gameStream pTemplate="listItem">
                <div class="p-col-12 list-object">
                  <div class="p-grid data-grid">
                    <div class="p-col-12 p-md-2 p-lg-1 p-xl-1">
                      <img [src]="gameStream.box_art" [alt]="gameStream.name" [title]="gameStream.name"/>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-9 p-xl-9">
                      <p style="color: #1d60a4; font-weight: bold; font-size: 14pt;">{{gameStream.name}}</p>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-2 p-xl-2" style="text-align: right;">
                      <p
                        style="font-size: 14pt;">{{gameStream.number_of_streams}} {{i18nService.localize().AdvertiserDashboardComponent.Streams}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-dataView>
          </div>
        </p-card>
      </div>

    </div>

    <div class="p-grid abstand" *ngIf="displayDetails" style="margin-top: 1em;">
      <advertiser-campaign-details [campaignId]="campaignId" *ngIf="campaignType==='CAMPAIGN'"
                                   [numberOfStreamer]="numberOfStreamer" (closeEmit)="closeCampaign()">
      </advertiser-campaign-details>
      <advertiser-affiliate-details [affiliateId]="campaignId" *ngIf="campaignType==='AFFILIATE'"
                                    [numberOfStreamer]="numberOfStreamer" (closeEmit)="closeCampaign()">
      </advertiser-affiliate-details>
    </div>

  </div>
</div>
