import { Component, OnInit, Renderer2 } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { AdvertiserService } from '../../services/advertiser.service';
import { RoutingService } from '../../services/routing.service';
import { GameStreamStatistic } from '../../model/advertiser/game-stream-statistic.model';
import { I18nService } from '../../services/i18n.service';
import { DashboardCampaign } from 'src/app/model/advertiser/dashboard-campaign.model';

@Component({
  selector: 'advertiser-dashboard',
  templateUrl: './advertiser-dashboard.component.html',
  styleUrls: ['./advertiser-dashboard.component.scss'],
})
export class AdvertiserDashboardComponent implements OnInit {
  public responsiveOptions: any;

  public clickChart!: any;
  public viewChart!: any;
  public chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  public gameStreamStatistics: GameStreamStatistic[] = [];

  // Anzeige der Kampagnen Details
  public displayDetails = false;
  public campaign = '';
  public campaignId = '';
  public campaignName = '';
  public campaignType = '';
  public numberOfStreamer = 0;

  constructor(
    public globalService: GlobalService,
    public advertiserService: AdvertiserService,
    private routingService: RoutingService,
    public i18nService: I18nService,
    public renderer: Renderer2
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    this.updateScreenSize();
    if (this.globalService.advertiser) {
      //  Weiterleitung machen falls der Advertiser noch keine aktiven Kampagnen oder Affiliate Programme hat
      if (this.globalService.advertiser.activeCampaigns.length === 0) {
        this.routingService.switchToPath('/advertiser/campaigns');
      }

      this.advertiserService.getAllClicksChart().then((res) => {
        this.clickChart = res;
      });

      this.advertiserService.getAllViewsChart().then((res) => {
        this.viewChart = res;
      });

      this.advertiserService.getAllGameStreamStatistics().then((res) => {
        this.gameStreamStatistics = res;
      });
    }
  }

  updateScreenSize() {
    const screenWidth = window.innerWidth;
    return screenWidth;
  }

  showCampaign(campaign: DashboardCampaign) {
    this.campaignType = campaign.type;
    this.campaignId = campaign.id;
    this.campaignName = campaign.name;
    this.numberOfStreamer = campaign.streamer;
    // this.numberOfStreamer++;
    this.displayDetails = true;
  }

  closeCampaign() {
    this.displayDetails = false;
    this.campaignId = '';
    this.numberOfStreamer = 0;
  }
}
