import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoutingService} from '../../services/routing.service';
import {IpService} from '../../services/ip.service';
import {I18nService} from '../../services/i18n.service';
import {CookieService} from 'ngx-cookie-service';
import {GlobalService} from '../../services/global.service';
import {AgencyService} from '../../services/agency.service';

@Component({
  selector: 'agency-activation',
  templateUrl: './agency-activation.component.html',
  styleUrls: ['./agency-activation.component.css']
})
export class AgencyActivationComponent implements OnInit {

  public waitForStreamBoost: any;

  public activationError!: string;

  public activationComplete = false;

  private ip!: string;

  constructor(private agencyService: AgencyService, public routingService: RoutingService,
              private route: ActivatedRoute, public ipService: IpService, public i18nService: I18nService,
              private cookieService: CookieService, private globalService: GlobalService) {
  }

  ngOnInit() {
    this.waitForStreamBoost = setInterval(() => {
      const state = this.agencyService.getEventState();
      if (state) {
        if (this.route.snapshot.params['id'] != null) {

          // IP Adresse holen
          this.ipService.getIp().then(res => {
            this.ip = res.ip;

            this.agencyService.activateAgencyAdmin(this.route.snapshot.params['id'], this.ip).then( () => {
              this.activationComplete = true;
              this.globalService.sleep(3000).then(() => {

                if (this.cookieService.get('streamboost-session')) {
                  this.routingService.switchToPathWithReload('agency/dashboard');
                } else {
                  window.location.href = 'https://www.streamboost.de';
                }
              });

            }).catch((error) => {
              this.activationError = error;
            });

          }).catch((err) => {
            this.activationError = err;
          });
        }

        clearInterval(this.waitForStreamBoost);
      }
      }, 500);
  }
}
