<div *ngIf="this.globalService.agency && this.globalService.agency.roles.includes('PICTURES')">
  <div class="headline">
    {{ i18nService.localize().AgencyPictureComponent.AgencyPictureHeader }}
  </div>

  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12 start">
        <p-dataView [value]="pictures" [layout]="'grid'" [paginator]="true" [rows]="16">
          <ng-template pTemplate="header">
            <div class="p-grid">
              <div class="p-col-12 p-md-4">
                <button pButton pRipple class="p-button-rounded p-mr-2" type="button" icon="fas fa-plus"
                        label="{{this.i18nService.localize().AgencyPictureComponent.NewAgencyPicture}}"
                        (click)="showUploadPicture()">
                </button>
                <button pButton pRipple class="p-button-rounded" type="button" icon="fas fa-plus"
                        label="{{this.i18nService.localize().AgencyPictureComponent.NewAgencyGifPicture}}"
                        (click)="showUploadGif()">
                </button>
              </div>
            </div>
          </ng-template>
          <ng-template let-picture pTemplate="gridItem">
            <div class="p-col-3 p-pl-2 p-pr-2 p-pt-2 p-pb-2">
              <p-card>
                <ng-template pTemplate="header">
                  <div style="padding-top: 5%; text-align: center">
                    <b>{{ picture.name }}</b>
                  </div>
                </ng-template>
                <div style="text-align: center; position: relative;">
                  <img src="/picture/{{picture.id}}" alt="Agency Picture" style="max-width: 100%;">
                  <div>
                    <div>{{ picture.usableBy }}</div>
                    <button pButton type="button" icon="pi pi-trash" class="p-button-raised p-button-rounded"
                            (click)="askDeletePicture(picture.id)">
                    </button>
                  </div>
                </div>
                <ng-template pTemplate="footer" style="text-align: center">
                  <div style="padding-top: 2px; text-align: center">
                    {{ picture.uploadDate }} <br>
                    {{ picture.uploadUser }}
                  </div>
                </ng-template>
              </p-card>
            </div>
          </ng-template>
        </p-dataView>
      </div>

      <p-dialog [(visible)]="showAskDelete" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="false" [closable]="false" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{ this.i18nService.localize().AgencyPictureComponent.PictureDeleteHeader }}</div>
        </ng-template>

        <p>
          {{ this.i18nService.localize().AgencyPictureComponent.PictureDeleteQuestion }}
        </p>

        <div style="text-align: right;">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="deletePicture()" class="p-button-rounded">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="closeAskDelete()" class="p-button-rounded"
                  style="margin-left: .5em">
          </button>
        </div>

      </p-dialog>

      <p-dialog [(visible)]="uploadGif" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
                [resizable]="false">
        <ng-template pTemplate="header">
          <div class="headline">{{ this.i18nService.localize().AgencyPictureComponent.UploadGifHeader }}</div>
        </ng-template>
        <p [innerHTML]="this.i18nService.localize().AgencyPictureComponent.UploadPictureText">
        </p>

        <p-fileUpload name="pictures[]" [multiple]=false accept="image/gif"
                      [maxFileSize]=1500000 id="pictures" [showUploadButton]="false"
                      [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                      chooseLabel="{{i18nService.localize().AgencyProfileComponent.ChooseButton}}"
                      cancelLabel="{{i18nService.localize().AgencyProfileComponent.CancelButton}}"
                      *ngIf="uploadGif">
          <ng-template pTemplate="content">
            <ul *ngIf="uploadedPictures.length">
              <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes
              </li>
            </ul>
          </ng-template>
        </p-fileUpload>

        <p class="warning" *ngIf="!picturesOk">
          {{i18nService.localize().AgencyProfileComponent.PicError}}
        </p>

        <div style="text-align: right; margin-top: 1em">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="uploadPic()"
                  class="p-button-rounded">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="uploadGif=false"
                  class="p-button-rounded" style="margin-left: 0.5em">
          </button>
        </div>
      </p-dialog>

      <p-dialog [(visible)]="uploadPicture" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
                [resizable]="false">
        <ng-template pTemplate="header">
          <div class="headline">{{ this.i18nService.localize().AgencyPictureComponent.UploadPictureHeader }}</div>
        </ng-template>
        <p [innerHTML]="this.i18nService.localize().AgencyPictureComponent.UploadPictureText">
        </p>
        <input type="file" accept="image/png, image/jpeg" (change)="fileChangeEvent($event)" style="all: unset;"
               class="p-ripple p-element p-button p-component p-fileupload-choose">
        <div *ngIf="showCropper">
          <h4>{{ this.i18nService.localize().AgencyPictureComponent.UploadPictureCropperText }}</h4>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="6/5"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [cropperMinWidth]="300"
            [cropperMinHeight]="250"
            [cropperMaxWidth]="300"
            [cropperMaxHeight]="250"
          ></image-cropper>

          <h4>{{ this.i18nService.localize().AgencyPictureComponent.UploadPictureCropperPreview }}</h4>
          <img [src]="croppedImage" alt="vorschau"/>

          <p>Wenn du mit deiner Auswahl zufrieden bist, kannst du das Bild direkt über das blaue Häkchen hochladen.</p>

          <div style="text-align: right; margin-top: 1em">
            <button pButton pRipple type="button" icon="pi pi-check" (click)="uploadPic()"
                    class="p-button-rounded">
            </button>
            <button pButton pRipple type="button" icon="pi pi-times" (click)="uploadPicture=false"
                    class="p-button-rounded" style="margin-left: 0.5em">
            </button>
          </div>
        </div>
      </p-dialog>

    </div>
  </div>

</div>
