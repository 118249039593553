export class StreamerBill {

  public id!: string;

  public billNr!: string;

  public billDate!: string;

  public views!: number;

  public totalClicks!: number;

  public earnings!: string;

  public paid!: boolean;

  constructor(private currentLang: string) {
  }

  public getStatus(): string {
    if (this.paid === false) {
      if (this.currentLang == 'DE') {
        return 'In Arbeit';
      } else {
        return 'In progress';
      }
    } else {
      if (this.currentLang == 'DE') {
        return 'Ausgezahlt';
      } else {
        return 'Paid out';
      }
    }
  }
}
