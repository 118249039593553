import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {RoutingService} from '../../services/routing.service';

@Component({
  selector: 'streamer-affiliates-overview',
  templateUrl: './streamer-affiliates-overview.component.html',
  styleUrls: ['./streamer-affiliates-overview.component.scss']
})
export class StreamerAffiliatesOverviewComponent implements OnInit {

  @Input()
  public bigFonts: boolean = true;

  public descriptionDialog:boolean = false;
  public selectedDescription?: string;

  public pauseDialog:boolean = false;

  public showFeedback: boolean = false;
  public feedbackAffiliateId!: string;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public routingService: RoutingService) {
  }

  ngOnInit() {
  }

  /*
   * Anzeigen der Beschreibung einer Kampagne
   */
  showDescriptionDialog(description: string) {
    this.selectedDescription = description;
    this.descriptionDialog = true;
  }

  /**
   * Anzeigen der Feedback Leiste
   */
  showFeedbackPanel(affiliateId: string) {
    this.feedbackAffiliateId = affiliateId;
    this.showFeedback=true
  }

}
