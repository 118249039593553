import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {MathHelper} from '../../services/math.helper';
import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {I18nService} from '../../services/i18n.service';
import {AdvertiserService} from '../../services/advertiser.service';
import * as moment from 'moment';
import {CampaignService} from '../../services/campaign.service';
import {ActivatedRoute, Router} from "@angular/router";
import {PaymentService} from "../../services/paymentService";

@Component({
  selector: 'advertiser-campaign-extend',
  templateUrl: './advertiser-campaign-extend.component.html',
  styleUrls: ['./advertiser-campaign-extend.component.scss']
})
export class AdvertiserCampaignExtendComponent implements OnInit {

  @Input()
  public campaignId!: string;

  @Input()
  public sessionId?: string;

  @Input()
  public advertiserId!: string;

  @Input()
  public campaignName!: string;

  @Output()
  public closeEmit = new EventEmitter<void>();

  public showExtendCampaign = false;
  public newStartDate!: Date;
  public newEndDate!: Date;
  public current: Date = new Date();
  public minDate: Date = new Date(this.current.getTime() + 259200000);
  public billNr!: string;
  public payPalConfig?: IPayPalConfig;
  public paymentIndex: any = 0;
  public paypalPaymentDone: boolean = false;
  public paymentError: boolean = false;
  public NoBudgetError: boolean = false;

  public budgetSelect: string = 'total';

  public calculationTimeOut: any;
  public isInCalculation: boolean = true;
  public hasScheduling: boolean = true;
  public totalBudget: number = 0;
  public totalBudgetString: string = '0';
  public dailyBudget: number = 0;
  public dailyBudgetString: string = '0';
  public disableTotalBudget: boolean = false;
  public disableDailyBudget: boolean = true;
  public totalBudgetWithTaxForPaypal: string = '0';
  public totalBudgetWithTaxString: string = '0';
  public totalBudgetWithTax: number = 0;
  public tooFewDaysError: boolean = false;

  public initialViews: number = 0;
  public initialDailyViews: number = 0;
  public viewBuffer: number = 0;
  public startTime!: string;
  public endTime!: string;
  public daysBetween: number = 0;
  public days: string[] = [];
  public affiliate: boolean = false;
  public tkp!: number;

  public paymentSuccess!: boolean;
  public showPaymentInfoPage: boolean = false;

  constructor(public globalService: GlobalService, public campaignService: CampaignService, public route: ActivatedRoute,
              public mathHelper: MathHelper, public i18nService: I18nService, public router: Router,
              public advertiserService: AdvertiserService, private paymentService: PaymentService) {
  }

  async ngOnInit() {
    if (this.globalService.advertiser || this.globalService.agency) {

      console.log(this.campaignId);
      await this.campaignService.getCampaignInfoForExtension(this.campaignId).then(result => {
        if (result.scheduling) {
          this.startTime = result.scheduling.start_time;
          this.endTime = result.scheduling.end_time;
          this.days = result.scheduling.days;
          this.hasScheduling = true;
        } else {
          this.hasScheduling = false;
        }

        if (result.affiliate_id) {
          this.affiliate = true;
        }
        this.viewBuffer = result.view_buffer_percent;
        this.tkp = result.tkp;

        this.generateNumber();
      });

      if (this.sessionId) {
        this.newStartDate = new Date(this.route.snapshot.queryParams['startDate']);
        this.newEndDate = new Date(this.route.snapshot.queryParams['endDate']);
        this.totalBudget = this.route.snapshot.queryParams['totalBudget'];

        if (this.newStartDate && this.newEndDate && this.totalBudget) {
          this.updateDaysBetween();
          this.paymentService.fulfillPayment(this.sessionId, () => {
            this.extendCampaign();
            this.paymentSuccess = true;
          });
          this.showPaymentInfoPage = true;
        }
      }
    }
  }

  generateNumber() {
    if (this.globalService.advertiser || this.globalService.agency) {
      this.advertiserService.getNextBillNumber().then(result => {
        let today = new Date();
        if (this.globalService.advertiser) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.advertiser.advertiserNr + '-' + result;
        } else if (this.globalService.agency) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.agency.agencyNr + '-' + result;
        }
      });
    }
  }

  extendCampaign() {
    this.campaignService.saveCampaignExtension(this.campaignId, this.billNr, this.totalBudget,
      this.paypalPaymentDone, this.initialDailyViews, this.daysBetween, this.newStartDate,
      this.viewBuffer, this.initialViews, this.affiliate, this.tkp, this.advertiserId).then(result => {
    });
  }

  public checkAffiliateExtend(): boolean {
    if (this.paymentIndex == 1) {
      return true;
    } else {
      return false;
    }
  }

  public deactivateButton(): boolean {
    if (this.totalBudget > 0 && this.dailyBudget > 0 && this.daysBetween > 2) {
      return false;
    } else {
      return true;
    }
  }

  updateBudgetInputsTotal() {
    this.disableTotalBudget = false;
    this.disableDailyBudget = true;
  }

  updateBudgetInputsDaily() {
    this.disableTotalBudget = true;
    this.disableDailyBudget = false;
  }

  public isTaxNeeded(): boolean {
    if (this.globalService.advertiser) {
      return this.globalService.advertiser.isTaxNeeded();
    } else if (this.globalService.agency) {
      return this.globalService.agency.isTaxNeeded();
    } else {
      return true;
    }
  }

  updateDaysBetween() {
    let millisecPerDay = 1000 * 3600 * 24;

    if (this.newStartDate && this.newEndDate) {
      this.newStartDate.setHours(0);
      this.newStartDate.setMinutes(0);
      this.newStartDate.setSeconds(0);
      this.newStartDate.setMilliseconds(0);

      this.newEndDate.setHours(0);
      this.newEndDate.setMinutes(0);
      this.newEndDate.setSeconds(0);
      this.newEndDate.setMilliseconds(0);

      const differenceInTime = this.newEndDate.getTime() - this.newStartDate.getTime();
      this.daysBetween = this.mathHelper.precisionRound((differenceInTime / millisecPerDay) + 1, 0);

      if (this.daysBetween <= 2) {
        this.tooFewDaysError = true;
      } else {
        this.tooFewDaysError = false;

        // console.log(this.startDate+' - '+this.endDate+' / '+differenceInTime+ ' / '+this.daysBetween);
        console.log('Days Between: ' + this.daysBetween);
        this.updateBudgets();
      }
    } else {
      this.tooFewDaysError = true;
      this.daysBetween = 0;
    }
  }

  updateBudgets() {
    this.isInCalculation = true;
    clearTimeout(this.calculationTimeOut);
    if (this.disableDailyBudget) {
      if (this.totalBudget > 0 && this.daysBetween > 0) {
        this.dailyBudget = this.mathHelper.precisionRound(this.totalBudget / this.daysBetween, 2);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);

        this.calculateBudgetWithTax();
        this.calculateViews();
      }
    } else {
      if (this.dailyBudget > 0 && this.daysBetween > 0) {
        this.totalBudget = this.mathHelper.precisionRound(this.daysBetween * this.dailyBudget, 2);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);

        this.calculateBudgetWithTax();
        this.calculateViews();
      }
    }
    this.calculationTimeOut = setTimeout(() => {
      this.isInCalculation = false;
    }, 3000);
  }

  calculateBudgetWithTax() {
    this.totalBudgetWithTax = this.totalBudget;

    if (this.globalService.advertiser || this.globalService.agency) {
      if (this.isTaxNeeded()) {
        this.totalBudgetWithTax = this.totalBudget + (this.totalBudget * 0.19);
      }
    }

    this.totalBudgetWithTaxString = this.mathHelper.numberWithCommas(this.totalBudgetWithTax);
    this.totalBudgetWithTaxForPaypal = this.totalBudgetWithTax.toString();
  }

  public calculateViews() {
    console.log("Calculate Views");
    const tkp = this.tkp;
    console.log("TKP: " + tkp);

    if (this.totalBudget < tkp) {
      console.log('No Budget');
      this.totalBudget = tkp;
    }

    // 500 Budget / 10 * 1000 = Views
    this.initialViews = this.totalBudget / tkp * 1000;
    console.log("Initial Views: " + this.initialViews);
    this.initialViews = this.mathHelper.precisionRound(this.initialViews, 0);

    if (this.daysBetween > 0) {
      this.initialDailyViews = this.mathHelper.precisionRound(this.initialViews / this.daysBetween, 0);
    }
  }

  back() {
    this.closeEmit.emit()
  }


}
