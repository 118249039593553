import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {AdvertiserService} from '../../services/advertiser.service';
import {BillService} from '../../services/bill.service';
import {FileService} from '../../services/file.service';
import {I18nService} from '../../services/i18n.service';
import {DisplayClickBill} from '../../model/bill.model';
import {TableLazyLoadEvent} from 'primeng/table';

@Component({
  selector: 'advertiser-bills',
  templateUrl: './advertiser-bills.component.html' ,
  styleUrls: ['./advertiser-bills.component.scss']
})
export class AdvertiserBillsComponent implements OnInit {

  public bills: DisplayClickBill[] = [];
  public totalBills!: number;
  public loadingBills!: boolean;

  constructor(public globalService: GlobalService, public advertiserService: AdvertiserService,
              public billService: BillService, public fileService: FileService,
              public i18nService: I18nService) {
    if (globalService.advertiser) {
      this.initializeBills();
    }
  }

  ngOnInit(): void {
    if (this.globalService.advertiser) {
      this.initializeBills();
    }
  }

  initializeBills() {
    this.loadingBills = true;
    this.billService.getBillsOfAdvertiser().then((result) => {
      this.bills = result;

      this.billService.getMaxBillsOfAdvertiser().then((max) => {
        this.totalBills = max;
        this.loadingBills = false;
      });

    });
  }

  updateBills(event: TableLazyLoadEvent) {
    this.loadingBills = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;
    this.billService.getBillsOfAdvertiser(event.first, rows, sortField, sortOrder).then((result) => {
      this.bills = result;

      this.billService.getMaxBillsOfAdvertiser().then((max) => {
        this.totalBills = max;
        this.loadingBills = false;
      });
    });
  }

  downloadPDF(billId: string) {
    this.fileService.downloadBill(billId);
  }

  getDefaultSortOrder() :number {
    return -1;
  }

}
