<div *ngIf="this.globalService.advertiser">
  <div class="headline">{{i18nService.localize().AdvertiserCampaignsComponent.CampaignsHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12"
           *ngIf="!selectedCampaignId&&!selectedAffiliateId&&!selectedAffiliateIdForExtension&&!selectedCampaignIdForExtension">
        <!--        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.Campaigns}}</h2>-->
        <button pButton pRipple type="button" icon="fas fa-plus" (click)="gotoCreateNewWizard()"
                class="p-button-rounded p-button-lg"
                label="{{i18nService.localize().AdvertiserCampaignsComponent.CreateButton}}"
                style="margin-bottom: 1em; margin-top: 1em;">
        </button>
        <p-card>
          <div class="p-grid">
            <div class="p-col-12" *ngIf="messages.length > 0">
              <p-messages [(value)]="messages" [enableService]="false"
                          [closable]="false"></p-messages>
            </div>
            <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em;">
              <p class="header">
                {{i18nService.localize().AdvertiserCampaignsComponent.DisplayCampaigns}}
              </p>
              <app-wizard-campaigns [wipCampaigns]="globalService.advertiser!.wipCampaigns" [showGridLine]="globalService.advertiser!.allOtherDisplayCampaigns.length > 0"></app-wizard-campaigns>
              <div
                [class]="(i+1) !== globalService.advertiser!.allOtherDisplayCampaigns.length ? 'p-grid line' : 'p-grid'"
                *ngFor="let campaign of globalService.advertiser!.allOtherDisplayCampaigns; let i = index">
                <div class="p-col-6 clickable" (click)="selectCampaignForDetails(campaign)">
                  <p pTooltip="Status: {{campaign.status}}" tooltipPosition="right" style="width: max-content;">
                    {{campaign.name}}
                  </p>
                </div>
                <div class="p-col-6 button-row">
                  <button pButton pRipple type="button" icon="fas fa-check"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.PayedTooltip}}"
                          tooltipPosition="left" style="background-color: #56BC6C; border-color: #56BC6C">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-info" (click)="selectCampaignForDetails(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.InfoCampaignTooltip}}"
                          tooltipPosition="left" style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-pause" (click)="pauseCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.PauseCampaignTooltip}}"
                          tooltipPosition="left" *ngIf="campaign.status==='ACTIVE'||campaign.status==='WAITING'"
                          style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-play" (click)="startCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.StartCampaignTooltip}}"
                          tooltipPosition="left" *ngIf="campaign.status==='PAUSED'" style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-pencil-alt" (click)="openEditCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.EditCampaignTooltip}}"
                          tooltipPosition="left"
                          *ngIf="campaign.status==='ACTIVE'||campaign.status==='WAITING'||campaign.status==='PAUSED'"
                          style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}"
                          tooltipPosition="left"
                          *ngIf="campaign.status!=='ACTIVE' && campaign.status!=='WAITING' && campaign.status!=='REVIEWED'"
                          style="margin-left: 0.5em;">
                  </button>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
              <p class="header">
                {{i18nService.localize().AdvertiserCampaignsComponent.ClickCampaigns}}
              </p>
              <div
                [class]="(i+1) !== globalService.advertiser!.allOtherClickCampaigns.length ? 'p-grid line' : 'p-grid'"
                *ngFor="let affiliate of globalService.advertiser!.allOtherClickCampaigns; let i = index">
                <div class="p-col-8 clickable" (click)="selectAffiliateForDetails(affiliate)">
                  <p pTooltip="Status: {{affiliate.status}}" tooltipPosition="right" style="width: max-content;">
                    {{affiliate.name}}
                  </p>
                </div>
                <div class="p-col-4 button-row">
                  <button pButton pRipple type="button" icon="fas fa-info"
                          (click)="selectAffiliateForDetails(affiliate)" class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.InfoCampaignTooltip}}"
                          tooltipPosition="left">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-pause" (click)="pauseCampaign(affiliate)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.PauseCampaignTooltip}}"
                          *ngIf="(affiliate.status==='ACTIVE'||affiliate.status==='WAITING')&&affiliate.sub_type!='CAMPAIGN'"
                          style="margin-left: 0.5em;" tooltipPosition="left">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-play" (click)="startCampaign(affiliate)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.StartCampaignTooltip}}"
                          tooltipPosition="left" *ngIf="affiliate.status==='PAUSED'&&affiliate.sub_type!='CAMPAIGN'"
                          style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(affiliate)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}"
                          tooltipPosition="left"
                          *ngIf="affiliate.status!=='ACTIVE' && affiliate.status!=='WAITING' && affiliate.status!=='REVIEWED'&&affiliate.sub_type!='CAMPAIGN'"
                          style="margin-left: 0.5em;">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>

      <p-dialog [(visible)]="showEditCampaign" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="true" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserCampaignsComponent.EditCampaignHeader}}</div>
        </ng-template>
        <div class="p-fluid">
          <div class="p-field">
            <label for="name"
                   style="font-weight: bold;">{{i18nService.localize().AdvertiserCampaignsComponent.Name}}:</label>
            <input id="name" type="text" pInputText [(ngModel)]="nameEdit">
          </div>
          <div class="p-field">
            <label for="description"
                   style="font-weight: bold;">{{i18nService.localize().AdvertiserCampaignsComponent.Description}}
              :</label>
            <textarea id="description" type="text" rows="4" pInputTextarea [(ngModel)]="descriptionEdit"></textarea>
          </div>
        </div>
        <div style="text-align: right">
          <button pButton pRipple type="button" icon="pi pi-check" (click)="changeCampaign()" class="p-button-rounded">
          </button>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="showEditCampaign=false"
                  class="p-button-rounded" style="margin-left: 0.5em">
          </button>
        </div>
      </p-dialog>

      <div class="p-col-12"
           *ngIf="!selectedCampaignId&&!selectedAffiliateId&&!selectedAffiliateIdForExtension&&!selectedCampaignIdForExtension">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.ClosedCampaignsHeader}}</h2>
        <p-card>
          <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
              <p class="header">
                {{i18nService.localize().AdvertiserCampaignsComponent.DisplayCampaigns}}
              </p>
              <div
                [class]="(i+1) !== globalService.advertiser!.allInactiveDisplayCampaigns.length ? 'p-grid line' : 'p-grid'"
                *ngFor="let campaign of globalService.advertiser!.allInactiveDisplayCampaigns; let i = index">
                <div class="p-col-8 clickable" (click)="selectCampaignForDetails(campaign)">
                  <p pTooltip="Status: {{campaign.status}}" tooltipPosition="right" style="width: max-content;">
                    {{campaign.name}}
                  </p>
                </div>
                <div class="p-col-4 button-row">
                  <button pButton pRipple type="button" icon="fas fa-info" (click)="selectCampaignForDetails(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.InfoCampaignTooltip}}"
                          tooltipPosition="left">
                  </button>
                  <button pButton pRipple type="button" icon="far fa-calendar-plus"
                          (click)="openExtendCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.ExtendCampaignTooltip}}"
                          tooltipPosition="left" *ngIf="campaign.status === 'ENDED'"
                          style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(campaign)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}"
                          tooltipPosition="left"
                          *ngIf="campaign.status=='ENDED'" style="margin-left: 0.5em;">
                  </button>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-lg-6 p-xl-6" style="padding: 0 2em 0 2em;">
              <p class="header">
                {{i18nService.localize().AdvertiserCampaignsComponent.ClickCampaigns}}
              </p>
              <div
                [class]="(i+1) !== globalService.advertiser!.allInactiveClickCampaigns.length ? 'p-grid line' : 'p-grid'"
                *ngFor="let affiliate of globalService.advertiser!.allInactiveClickCampaigns; let i = index">
                <div class="p-col-8 clickable" (click)="selectAffiliateForDetails(affiliate)">
                  <p pTooltip="Status: {{affiliate.status}}" tooltipPosition="right" style="width: max-content;">
                    {{affiliate.name}}
                  </p>
                </div>
                <div class="p-col-4 button-row">
                  <button pButton pRipple type="button" icon="fas fa-info"
                          (click)="selectAffiliateForDetails(affiliate)" class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.InfoCampaignTooltip}}"
                          tooltipPosition="left">
                  </button>
                  <button pButton pRipple type="button" icon="far fa-calendar-plus"
                          (click)="openExtendAffiliate(affiliate)" class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.ExtendCampaignTooltip}}"
                          tooltipPosition="left" *ngIf="affiliate.status === 'ENDED'&&affiliate.sub_type === 'INTERNAL'"
                          style="margin-left: 0.5em;">
                  </button>
                  <button pButton pRipple type="button" icon="fas fa-trash-alt" (click)="openDeleteCampaign(affiliate)"
                          class="p-button-rounded"
                          pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.DeleteCampaignTooltip}}"
                          tooltipPosition="left"
                          *ngIf="affiliate.status=='ENDED'&&affiliate.sub_type!='CAMPAIGN'"
                          style="margin-left: 0.5em;">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>

      <p-dialog [(visible)]="showRemoveCampaign" [style]="{width: '50vw'}" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{i18nService.localize().AdvertiserCampaignsComponent.DeleteDialogHeader}}</div>
        </ng-template>
        <p>{{i18nService.localize().AdvertiserCampaignsComponent.DeleteDialogText}}</p>
        <div style="text-align: right">
          <button pButton pRipple type="button" icon="fas fa-check"
                  class="p-button-rounded"
                  (click)="this.selectedDeleteCampaign!.type == 'AFFILIATE' ? deleteAffiliate() : deleteCampaign()"></button>
          <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
                  class="p-button-rounded p-button-secondary" (click)="this.showRemoveCampaign=false"></button>
        </div>
      </p-dialog>

      <div class="p-col-12" *ngIf="selectedCampaignId&&!selectedAffiliateId&&!selectedAffiliateIdForExtension">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.DisplayCampaign}}</h2>
        <advertiser-campaign-details [campaignId]="selectedCampaignId"
                                     [numberOfStreamer]="selectedCampaignNumberOfStreamer"
                                     (closeEmit)="closeCampaignDetails()">
        </advertiser-campaign-details>
      </div>

      <div class="p-col-12" *ngIf="selectedAffiliateId&&!selectedCampaignId&&!selectedAffiliateIdForExtension">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.ClickCampaign}}</h2>
        <advertiser-affiliate-details [affiliateId]="selectedAffiliateId"
                                      [numberOfStreamer]="selectedAffiliateNumberOfStreamer"
                                      (closeEmit)="closeAffiliateDetails()">
        </advertiser-affiliate-details>
      </div>

      <div class="p-col-12" *ngIf="!selectedAffiliateId&&!selectedCampaignId&&selectedAffiliateIdForExtension">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.ExtendClickCampaign}}</h2>
        <advertiser-affiliate-extend [affiliateId]="selectedAffiliateIdForExtension"
                                     [advertiserId]="this.globalService.advertiser.id"
                                     [affiliateName]="selectedAffiliateNameForExtension"
                                     (closeEmit)="closeExtendAffiliate()" [sessionId]="paymentSessionId">
        </advertiser-affiliate-extend>
      </div>

      <div class="p-col-12" *ngIf="!selectedCampaignId&&!selectedAffiliateId&&selectedCampaignIdForExtension">
        <h2 class="card-headline">{{i18nService.localize().AdvertiserCampaignsComponent.ExtendDisplayCampaign}}</h2>
        <advertiser-campaign-extend [campaignId]="selectedCampaignIdForExtension"
                                    [campaignName]="selectedCampaignNameForExtension"
                                    [advertiserId]="this.globalService.advertiser.id"
                                    (closeEmit)="closeExtendCampaign()" [sessionId]="paymentSessionId">
        </advertiser-campaign-extend>
      </div>

    </div>
  </div>
</div>
