<div *ngIf="this.globalService.streamer">
  <div class="headline">{{this.i18nService.localize().StreamerContestComponent.ContestHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerContestComponent.HowItWorksHeader}}</h2>
      </div>

      <div class="p-col-12 p-md-8">
        <p-card>
          <div class="infoBox p-grid">
            <div class="p-col-12">
              {{this.i18nService.localize().StreamerContestComponent.ContestText1}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-2">
              <img src="/assets/images/123_Bilder-01.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 p-lg-4 infoText">
              {{this.i18nService.localize().StreamerContestComponent.Step1Text}}
            </div>
            <div class="p-col-6 p-sm-3 p-lg-2">
              <img src="/assets/images/123_Bilder-02.png" class="infoZahl">
            </div>
            <div class="p-col-6 p-sm-3 p-lg-4 infoText">
              {{this.i18nService.localize().StreamerContestComponent.Step2Text}}
            </div>
            <!--            <div class="p-col-12">-->
            <!--              {{this.i18nService.localize().StreamerContestComponent.ContestText2}}-->
            <!--            </div>-->
            <div class="p-col-12 p-sm-6">
              <div class="streamboostBoxHead">
                {{this.i18nService.localize().StreamerContestComponent.YourBenefits}}
              </div>
              <div class="streamboostBox">
                {{this.i18nService.localize().StreamerContestComponent.BenefitsText}}
              </div>
            </div>
            <div class="p-col-12 p-sm-6">
              <div class="streamboostBoxHead">
                {{this.i18nService.localize().StreamerContestComponent.Drawing}}
              </div>
              <div class="streamboostBox winner">
                {{this.i18nService.localize().StreamerContestComponent.DrawingText}}
              </div>
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-md-4">
        <div class="p-grid">
          <div class="p-col-12 p-sm-6 p-md-12">
            <p-panel header="Banner">
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-12 p-sm-6 p-xl-2">
                    <img src="assets/images/Gewinnspiel.jpg" alt="" style="width: 100%">
                  </div>
                  <div class="p-col-12 p-sm-6 p-xl-4" style="text-align: center; margin: auto;">
                    Version 1
                  </div>
                  <div class="p-col-12 p-xl-6" style="text-align: right; margin: auto; text-align: center;">
                    <button pButton pRipple type="button"
                      label="{{this.i18nService.localize().StreamerContestComponent.CopyLinkButton}}" icon="pi pi-clone"
                      class="p-button-rounded logout" (click)="copyLink()"></button>
                  </div>
                </div>
              </div>
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-12 p-sm-6 p-xl-2">
                    <img src="assets/images/Gewinnspiel_2.jpg" width="100%" alt="">
                  </div>
                  <div class="p-col-12 p-sm-6 p-xl-4" style="text-align: center; margin: auto;">
                    Version 2
                  </div>
                  <div class="p-col-12 p-xl-6" style="text-align: right; margin: auto; text-align: center;">
                    <button pButton pRipple type="button"
                      label="{{this.i18nService.localize().StreamerContestComponent.CopyLinkButton}}" icon="pi pi-clone"
                      class="p-button-rounded logout" (click)="copyLink()"></button>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="p-col-12 p-sm-6 p-md-12" *ngIf="currentCompetition">
            <p-card class="check">
              <ng-template pTemplate="header">
                <div class="cardHead">
                  {{this.i18nService.localize().StreamerContestComponent.CurrentContestHeader}}
                </div>
              </ng-template>
              <div style="margin: 0 1em 1em 1em;" *ngIf="currentCompetition.link">
                <a href="{{currentCompetition.link}}">
                  {{currentCompetition.name}}
                </a>
                <p class="inner_text" [innerHTML]="currentCompetition.description"></p>
              </div>
              <div style="margin: 0 1em 1em 1em;" *ngIf="!currentCompetition.link">
                {{this.i18nService.localize().StreamerContestComponent.noCurrentCompetition}}
              </div>
            </p-card>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
