import {DashboardCampaign} from './dashboard-campaign.model';
import {AgencyAdvertiser} from "./agency-advertiser.model";
import {AgencyUser} from './agency-user.model';

export class Agency {

  public id!: string;
  public agencyNr!: string;

  public agencyName!: string;
  public website!: string;
  public logoURL!: string;

  public userId!: string;
  public firstname!: string;
  public lastname!: string;
  public email!: string;
  public telephone!: string;
  public roles!: string[];

  public street!: string;
  public nr!: string;
  public postalCode!: string;
  public city!: string;

  public isPasswordInitial: boolean = false;
  public taxNumber!: string;
  public country!: string;

  public agbConfirmation: boolean = false;

  public activeCampaigns: DashboardCampaign[] = [];

  public advertisers: AgencyAdvertiser[] = [];

  public users: AgencyUser[] = [];

  public rememberLogin: boolean = false;

  public discount: number = 0;

  public allowAdminAccess: boolean = false;

  public customerId!: string;

  public is_balance_enabled: boolean = false;
  public balance: number = 0;
  public balance_valid_until: string = '';
  public aeBonus: { amount: number, issued_at: string }[] = [];
  public totalAeBonus: number = 0;
  public usedBalance: number = 0;

  constructor() {}

  public hasAddress(): boolean {
    if (this.street || this.nr || this.postalCode || this.city) {
      return true;
    } else {
      return false;
    }
  }

  public isTaxNeeded(): boolean {
    if (this.country === 'Deutschland' || this.country === 'Germany') {
      return true;
    } else {
      return false;
    }
  }

  public isUserAdmin(): boolean {
    return this.roles.includes('ADMIN');
  }

}
