<div
  [class]="(i+1) < wipCampaigns.length || showGridLine ? 'p-grid line' : 'p-grid'"
  *ngFor="let wipCampaign of wipCampaigns; let i = index">
  <div class="p-col-6 clickable">
    <p pTooltip="Status: {{wipCampaign.status}}" (click)="gotoPayNewWizard(wipCampaign)"
       tooltipPosition="right" style="width: max-content;">
      {{wipCampaign.name}}
    </p>
  </div>
  <div class="p-col-6 button-row">
    <button pButton pRipple type="button" icon="fas fa-history" (click)="gotoPayNewWizard(wipCampaign)"
            class="p-button-rounded" *ngIf="wipCampaign.status == 'IN_REVIEW_TO_PAY'"
            pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.InReviewForPaymentTooltip}}"
            tooltipPosition="left" style="background-color: grey; border-color: grey">
    </button>
    <button pButton pRipple type="button" icon="fas fa-euro-sign" (click)="gotoPayNewWizard(wipCampaign)"
            class="p-button-rounded" *ngIf="wipCampaign.status == 'READY_TO_PAY'"
            pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.ReadyForPaymentTooltip}}"
            tooltipPosition="left" style="background-color: #56BC6C; border-color: #56BC6C">
    </button>
    <button pButton pRipple type="button" icon="fas fa-times" (click)="gotoPayNewWizard(wipCampaign)"
            class="p-button-rounded" *ngIf="wipCampaign.status == 'REJECTED_TO_PAY'"
            pTooltip="{{i18nService.localize().AdvertiserCampaignsComponent.RejectedForPaymentTooltip}}"
            tooltipPosition="left" style="background-color: red; border-color: red">
    </button>
  </div>
</div>
