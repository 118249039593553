import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {AdvertiserService} from './advertiser.service';
import {I18nService} from './i18n.service';
import {Affiliate} from '../model/streamer/affiliate.model';
import {AdvertiserAffiliate} from '../model/advertiser/advertiser-affiliate.model';
import {FileModel} from '../model/advertiser/file.model';
import {PictureService} from './picture.service';
import {FileService} from './file.service';
import {CampaignDemographic} from '../model/advertiser/campaign-demographic.model';
import {SimpleDemographicCountry} from '../model/advertiser/simple-demo-country.model';
import {Tag} from '../model/advertiser/tag.model';
import {Router} from "@angular/router";

@Injectable()
export class AffiliateService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus,
              private advertiserService: AdvertiserService,
              private cookieService: CookieService,
              private i18nService: I18nService,
              private router: Router,
              private pictureService: PictureService,
              private fileService: FileService) {
    super(streamBoostEventBus);
  }

  public getActiveExternalAffiliates(): Promise<Affiliate[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const lang = this.i18nService.getCurrentLang();

        this.streamBoostEventBus.send('stream.web.facade.affiliates.external',
          {
            message: {
              lang: lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              const affiliatesFromDB = response.body.response.affiliates;
              const externalAffiliates: Affiliate[] = [];

              if (affiliatesFromDB) {
                let i: number;
                for (i = 0; i < affiliatesFromDB.length; i++) {
                  const affiliate: Affiliate = new Affiliate();

                  affiliate.id = affiliatesFromDB[i]._id;
                  affiliate.name = affiliatesFromDB[i].name;
                  affiliate.description = affiliatesFromDB[i].description;
                  affiliate.logoURL = affiliatesFromDB[i].logo_url;
                  affiliate.keywords = affiliatesFromDB[i].keywords;

                  if (affiliatesFromDB[i].top) {
                    affiliate.topAffiliate = true;
                  }

                  externalAffiliates.push(affiliate);
                }
              }
              resolve(externalAffiliates);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getAffiliateInfoForExtension(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.extend.info',
          {
            message: {
              affiliate_id: affiliateId
            }
          })
          .then(
            (result: any) => {
              if (result.body) {
                resolve(result.body.response);
              } else if (result.body.code) {
                reject(result.body.code);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public saveAffiliateExtension(affiliateId: string, billNr: string, clicks: number, paypalPayment: boolean,
                                dailyClicks: number, startDate: Date, budget: number, advertiserId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let startDateString = startDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.extend',
          {
            message: {
              affiliate_id: affiliateId,
              bill_nr: billNr,
              clicks,
              budget: budget + '',
              paypal_payment: paypalPayment,
              daily_clicks: dailyClicks,
              start_date: startDateString,
              advertiser_id: advertiserId
            }
          })
          .then(
            (result: any) => {
              if (result.body.code === 'Theseus') {

                if (this.advertiserService.isAdvertiserSet()) {
                  this.advertiserService.setAdvertiser(result.body.response);
                }

                resolve();
              } else if (result.body.code) {
                reject(result.body.code);
              }
              resolve();
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public setSessionExtend(affiliateId: string, sessionId: string, advertiserId?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Wenn der Advertiser bzw. die Agentur angemeldet ist
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne WIP aktualisieren
        this.streamBoostEventBus.send('stream.web.facade.session.affiliate.update',
          {
            message: {
              affiliate_id: affiliateId,
              session_id: sessionId,
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              let success = response.body.response;
              resolve(success);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }


  public getBudgetBurndownOfAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.budget.burndown',
          {
            message: {affiliate_id: affiliateId}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });

  }

  public getClickBurnupOfAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.click.burnup',
          {
            message: {affiliate_id: affiliateId}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });

  }

  public getConversionBurnupOfAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.conversion.burnup',
          {
            message: {affiliate_id: affiliateId}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });

  }

  getAffiliate(affiliateId: string): Promise<AdvertiserAffiliate> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const lang = this.i18nService.getCurrentLang();

        this.streamBoostEventBus.send('stream.web.facade.affiliate',
          {
            message: {
              affiliate_id: affiliateId,
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              const affiliate: AdvertiserAffiliate = new AdvertiserAffiliate();
              const affiliateFromDB = response.body.response;
              // console.log(affiliateFromDB);

              if (affiliateFromDB) {

                affiliate.id = affiliateFromDB._id;
                affiliate.name = affiliateFromDB.name;
                affiliate.lastInvoiceDate = affiliateFromDB.last_invoice_date;
                affiliate.status = affiliateFromDB.status;

                affiliate.code = affiliateFromDB.code;
                affiliate.link = affiliateFromDB.link;
                affiliate.chatMessage = affiliateFromDB.chat_message;
                affiliate.tagId = affiliateFromDB.tag_id;

                affiliate.logoURL = affiliateFromDB.logo_url;

                affiliate.keywords = affiliateFromDB.keywords;

                affiliate.linkClicks = affiliateFromDB.link_clicks;
                affiliate.codeClicks = affiliateFromDB.code_clicks;
                affiliate.fileClicks = affiliateFromDB.file_clicks;
                affiliate.clicks = affiliateFromDB.clicks;

                const type = affiliateFromDB.type;
                affiliate.type = type;
                if (type === 'CAMPAIGN') {
                  affiliate.campaignId = affiliateFromDB.campaign_id;
                  affiliate.campaignName = affiliateFromDB.campaign_name;
                }

                if (type === 'INTERNAL') {
                  affiliate.progress = affiliateFromDB.progress;
                  affiliate.description = affiliateFromDB.description;

                  const schedulingFromDB = affiliateFromDB.scheduling;
                  console.log(schedulingFromDB)
                  if (schedulingFromDB) {
                    affiliate.budget = schedulingFromDB.budget;
                    affiliate.initialBudget = schedulingFromDB.initial_budget;
                    affiliate.initialClicks = schedulingFromDB.initial_clicks;
                    affiliate.currentClicks = schedulingFromDB.clicks;
                    affiliate.targetAgeFrom = affiliateFromDB.target_age_from;
                    affiliate.targetAgeTo = affiliateFromDB.target_age_to;
                    affiliate.targetGender = affiliateFromDB.target_gender;
                    affiliate.targetCountries = affiliateFromDB.target_countries;
                    affiliate.intensityFocus = affiliateFromDB.intensity_focus;
                    affiliate.streamerFocus = affiliateFromDB.streamer_focus;
                    affiliate.streamerGender = affiliateFromDB.streamer_gender;
                    affiliate.streamerAgeTo = affiliateFromDB.streamer_age_to;
                    affiliate.streamerAgeFrom = affiliateFromDB.streamer_age_from;
                    affiliate.dailyClicks = schedulingFromDB.daily_clicks;
                    affiliate.initialDailyClicks = schedulingFromDB.initial_daily_clicks;

                    affiliate.budgetText = schedulingFromDB.budget_text;
                    affiliate.initialBudgetText = schedulingFromDB.initial_budget_text;

                    affiliate.startDate = schedulingFromDB.start_date;
                    affiliate.calculatedEndDate = schedulingFromDB.calculated_end_date;
                  }
                }

                let filesList: FileModel[] = [];
                const fileArray = affiliateFromDB.files;
                if (fileArray) {
                  // @ts-ignore
                  fileArray.forEach((fileObj) => {
                    let file: FileModel = new FileModel();
                    file.id = fileObj._id;
                    file.contentType = fileObj.content_type;
                    file.downloadURL = fileObj.download_url;
                    file.name = fileObj.name;

                    filesList.push(file);
                  });
                }
                affiliate.files = filesList;

                // Werbebanner
                affiliate.bannerPics = affiliateFromDB.banner_pics;
              }

              resolve(affiliate);
            });
      }
    });
  }

  getAffiliateBySessionId(session_id: string): Promise<AdvertiserAffiliate> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        const lang = this.i18nService.getCurrentLang();

        this.streamBoostEventBus.send('stream.web.facade.session.affiliate',
          {
            message: {
              session_id: session_id,
              lang
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                window.location.href = this.router.url.split('?')[0]
                return;
              }

              const affiliate: AdvertiserAffiliate = new AdvertiserAffiliate();
              const affiliateFromDB = response.body.response;
              // console.log(affiliateFromDB);

              if (affiliateFromDB) {

                affiliate.id = affiliateFromDB._id;
                affiliate.name = affiliateFromDB.name;
                affiliate.lastInvoiceDate = affiliateFromDB.last_invoice_date;
                affiliate.status = affiliateFromDB.status;

                affiliate.code = affiliateFromDB.code;
                affiliate.link = affiliateFromDB.link;
                affiliate.chatMessage = affiliateFromDB.chat_message;
                affiliate.tagId = affiliateFromDB.tag_id;

                affiliate.logoURL = affiliateFromDB.logo_url;

                affiliate.keywords = affiliateFromDB.keywords;

                affiliate.linkClicks = affiliateFromDB.link_clicks;
                affiliate.codeClicks = affiliateFromDB.code_clicks;
                affiliate.fileClicks = affiliateFromDB.file_clicks;
                affiliate.clicks = affiliateFromDB.clicks;

                const type = affiliateFromDB.type;
                affiliate.type = type;
                if (type === 'CAMPAIGN') {
                  affiliate.campaignId = affiliateFromDB.campaign_id;
                  affiliate.campaignName = affiliateFromDB.campaign_name;
                }

                if (type === 'INTERNAL') {
                  affiliate.progress = affiliateFromDB.progress;
                  affiliate.description = affiliateFromDB.description;

                  const schedulingFromDB = affiliateFromDB.scheduling;
                  console.log(schedulingFromDB)
                  if (schedulingFromDB) {
                    affiliate.budget = schedulingFromDB.budget;
                    affiliate.initialBudget = schedulingFromDB.initial_budget;
                    affiliate.initialClicks = schedulingFromDB.initial_clicks;
                    affiliate.currentClicks = schedulingFromDB.clicks;
                    affiliate.targetAgeFrom = affiliateFromDB.target_age_from;
                    affiliate.targetAgeTo = affiliateFromDB.target_age_to;
                    affiliate.targetGender = affiliateFromDB.target_gender;
                    affiliate.targetCountries = affiliateFromDB.target_countries;
                    affiliate.intensityFocus = affiliateFromDB.intensity_focus;
                    affiliate.streamerFocus = affiliateFromDB.streamer_focus;
                    affiliate.streamerGender = affiliateFromDB.streamer_gender;
                    affiliate.streamerAgeTo = affiliateFromDB.streamer_age_to;
                    affiliate.streamerAgeFrom = affiliateFromDB.streamer_age_from;
                    affiliate.dailyClicks = schedulingFromDB.daily_clicks;
                    affiliate.initialDailyClicks = schedulingFromDB.initial_daily_clicks;

                    affiliate.budgetText = schedulingFromDB.budget_text;
                    affiliate.initialBudgetText = schedulingFromDB.initial_budget_text;

                    affiliate.startDate = schedulingFromDB.start_date;
                    affiliate.calculatedEndDate = schedulingFromDB.calculated_end_date;
                  }
                }

                let filesList: FileModel[] = [];
                const fileArray = affiliateFromDB.files;
                if (fileArray) {
                  // @ts-ignore
                  fileArray.forEach((fileObj) => {
                    let file: FileModel = new FileModel();
                    file.id = fileObj._id;
                    file.contentType = fileObj.content_type;
                    file.downloadURL = fileObj.download_url;
                    file.name = fileObj.name;

                    filesList.push(file);
                  });
                }
                affiliate.files = filesList;

                // Werbebanner
                affiliate.bannerPics = affiliateFromDB.banner_pics;
              }

              resolve(affiliate);
            });
      }
    });
  }

  public pauseAffiliate(affiliateId: string, ipAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.pause',
          {
            message: {
              affiliate_id: affiliateId,
              ip_address: ipAddress
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve(response.body);
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                resolve(response.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  startAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.activate',
          {
            message: {
              affiliate_id: affiliateId,
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve(response.body);
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                resolve(response.body);
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getClickTimeHeatmapOfAffiliate(affiliateId: string, currentCW: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.heatmap',
          {
            message: {
              affiliate_id: affiliateId,
              lang: this.i18nService.getCurrentLang(),
              current_cw: currentCW
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);

            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getClickUseragentChartsOfAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.click.useragent',
          {
            message: {affiliate_id: affiliateId}
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getDemographicForAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.advertiser.affiliate.demo',
          {
            message: {
              affiliate_id: affiliateId,
              lang: this.i18nService.getCurrentLang().toLowerCase()
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }

              const campaignDemoFromDB = response.body.response;
              const demo: CampaignDemographic = new CampaignDemographic();

              demo.under18 = campaignDemoFromDB.under_18;
              demo.from18to24 = campaignDemoFromDB['18_to_24'];
              demo.from25to34 = campaignDemoFromDB['25_to_34'];
              demo.from35to44 = campaignDemoFromDB['35_to_44'];
              demo.over44 = campaignDemoFromDB.over_44;

              demo.male = campaignDemoFromDB.male;
              demo.female = campaignDemoFromDB.female;
              demo.diverse = campaignDemoFromDB.diverse;

              const countriesFromDB = campaignDemoFromDB.countries;
              if (countriesFromDB) {
                let i: number;
                for (i = 0; i < countriesFromDB.length; i++) {
                  const country: SimpleDemographicCountry = new SimpleDemographicCountry();
                  country.name = countriesFromDB[i].name;
                  country.count = countriesFromDB[i].count;
                  country.share = countriesFromDB[i].share;

                  demo.countries.push(country);
                }
              }

              resolve(demo);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getGameCategoryStreamerStatisticForAffiliate(affiliateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.affiliate.game.keywords',
          {
            message: {
              affiliate_id: affiliateId,
              lang: this.i18nService.getCurrentLang()
            }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                this.cookieService.deleteAll();
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

  public getTagsOfAdvertiser(advertiserId?: string, first?: number, rows?: number, sortField?: string, sortOrder?: number): Promise<any> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.affiliate.tags',
          {
            message: {
              advertiser_id: advertiserId,
              first,
              rows,
              sort_field: sortField,
              sort_order: sortOrder
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }

              const tagsFromDB = response.body.response.tags;
              let i: number;
              const tags: Tag[] = [];

              for (i = 0; i < tagsFromDB.length; i++) {
                const tag = new Tag();

                tag.tagId = tagsFromDB[i].tag_id;
                tag.affiliateName = tagsFromDB[i].name;
                tag.status = tagsFromDB[i].status;
                tag.advertiserId = tagsFromDB[i].advertiser_id;
                tag.totalConversions = tagsFromDB[i].total_conversions;
                tags.push(tag);
              }

              resolve(tags);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  public getMaxTagsOfAdvertiser(advertiserId?: string): Promise<number> {
    return new Promise((resolve, reject) => {
        this.streamBoostEventBus.send(
          'stream.web.facade.affiliate.tags.max',
          {
            message: {
              advertiser_id: advertiserId
            }
          })
          .then((response) => {
            {
              if (response.body.code) {
                reject(response.body.code);
                return;
              }
              resolve(response.body.response.max_count);

            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  deleteAffiliate(affiliateId: string, affiliateState: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {

        // Kampagne löschen
        this.streamBoostEventBus.send('stream.web.facade.affiliate.delete',
          {
            message: {
              affiliate_id: affiliateId,
              status: affiliateState,
              delete_impact: "SAFE"
            }
          })
          .then(
            (response: any) => {
              if (response.body.code === 'Theseus') {
                resolve();
              } else if (response.body.code) {
                reject(response.body.code);
              } else {
                reject();
              }
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      }
    });
  }

}
