import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {StreamerService} from '../../services/streamer.service';
import {StreamerCampaign} from "../../model/streamer/streamer-campaign.model";

@Component({
  selector: 'streamer-campaigns-overview',
  templateUrl: './streamer-campaigns-overview.component.html',
  styleUrls: ['./streamer-campaigns-overview.component.scss']
})
export class StreamerCampaignsOverviewComponent implements OnInit {

  @Input()
  public showQuitButton: boolean = true;

  @Input()
  public showViews: boolean = true;

  @Input()
  public showPictureHint: boolean = true;

  @Input()
  public bigFonts: boolean = false;

  public quitCampaignDialog:boolean = false;
  public campaignIdToQuit?: string;

  public descriptionDialog:boolean = false;
  public selectedCampaign?: StreamerCampaign;

  public pauseDialog:boolean = false;
  public pauseCampaignDialog:boolean = false;
  public campaignIdToPause?: string;

  public showFeedback: boolean = false;
  public feedbackCampaignId!: string;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public streamerService: StreamerService) {
  }

  ngOnInit() {
  }

  /**
   * Methoden zum austreten aus einer Displaykampagne
   */
  showQuitConfirmDialog(campaignId: string) {
    this.quitCampaignDialog = true;
    this.campaignIdToQuit = campaignId;
  }

  quitCampaign() {
    if (this.campaignIdToQuit) {
      this.streamerService.quitCampaign(this.campaignIdToQuit).then(result => {
        delete this.campaignIdToQuit;
        this.quitCampaignDialog = false;
      }).catch(error => {
        console.log(error);
        delete this.campaignIdToQuit;
        this.quitCampaignDialog = false;
      });
    }
  }

  /**
   * Methoden zum selbständigen Pausieren und wiederaufnehmen in der Kampagne
   */
  showPauseConfirmDialog(campaignId: string) {
    this.pauseCampaignDialog = true;
    this.campaignIdToPause = campaignId;
  }

  pauseYourself() {
    if (this.campaignIdToPause) {
      this.streamerService.pauseYourselfInCampaign(this.campaignIdToPause).then(result => {
        delete this.campaignIdToPause;
        this.pauseCampaignDialog = false;
      }).catch(error => {
        console.log(error);
        delete this.campaignIdToPause;
        this.pauseCampaignDialog = false;
      });
    }
  }

  showPausedBy = false;
  resumeYourself(campaignId: string) {
    if (campaignId) {
      this.streamerService.resumeYourselfInCampaign(campaignId).then(result => {
      }).catch(error => {
        console.log(error);
      });
    }
  }

  /*
   * Anzeigen der Beschreibung einer Kampagne
   */
  showDescriptionDialog(campaign: StreamerCampaign) {
    this.selectedCampaign = campaign;
    this.descriptionDialog = true;
  }

  /**
   * Anzeigen der Feedback Leiste
   */
  showFeedbackPanel(campaignId: string) {
    this.feedbackCampaignId = campaignId;
    this.showFeedback=true
  }

  calculateCampaignDaysLeft(startDateString: string, endDateString: string){
    var startDay: number = parseInt(startDateString.substring(0,2));
    var startMonth: number = parseInt(startDateString.substring(3,6));
    var startYear: number = parseInt(startDateString.substring(6,10));

    var endDay: number = parseInt(startDateString.substring(0,2));
    var endMonth: number = parseInt(startDateString.substring(3,6));
    var endYear: number = parseInt(startDateString.substring(6,10));

      var startDate = new Date(startDay, startMonth, startYear);
      var endDate = new Date(endDay, endMonth, endYear);

    var timeDiff = endDate.getTime() - startDate.getTime();
    var diffInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return diffInDays;
  }

}
