import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoutingService} from '../../services/routing.service';
import {IpService} from '../../services/ip.service';
import {I18nService} from '../../services/i18n.service';
import {AgencyService} from '../../services/agency.service';

@Component({
  selector: 'agency-admin-takeover',
  templateUrl: './admin-takeover.component.html',
  styleUrls: ['./admin-takeover.component.css']
})
export class AgencyAdminTakeoverComponent implements OnInit {

  public waitForStreamBoost: any;

  public loginError!: string;

  constructor(private agencyService: AgencyService, public routingService: RoutingService,
              private route: ActivatedRoute, public ipService: IpService, public i18nService: I18nService) {
  }

  ngOnInit() {
    this.waitForStreamBoost = setInterval(() => {
      const state = this.agencyService.getEventState();
      if (state) {
        if (this.route.snapshot.params['token'] != null) {

          this.ipService.getIp().then(res => {
            let ipAddress = res.ip;

            this.agencyService.authenticateWithToken(this.route.snapshot.params['token'], ipAddress).then( () => {
              this.routingService.switchToPathWithReload('/agency/dashboard');
            }).catch((error) => {
              console.log(error);
              this.loginError = error;
            });

          }).catch((err) => {
            this.loginError = err;
          });
        }

        clearInterval(this.waitForStreamBoost);
      }
      }, 500);
  }
}
