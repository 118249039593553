import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {KeywordService} from '../../services/keyword.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown3';
import {AffiliateService} from '../../services/affiliate.service';
import {Affiliate} from '../../model/streamer/affiliate.model';
import {RoutingService} from '../../services/routing.service';
import {StreamerService} from '../../services/streamer.service';
import {MathHelper} from '../../services/math.helper';
import {SocialService} from '../../services/social.service';

@Component({
    selector: 'streamer-register',
    templateUrl: './streamer-register.component.html',
    styleUrls: ['./streamer-register.component.scss']
})
export class StreamerRegisterComponent implements OnInit {

    // Aktuelle Schritt-Nummer
    public step: number = 0;

    // Grundsätzliches
    public email: string = '';
    public birthday!: Date;
    public postalCode!: String;
    public gender!: string;
    public ageError = false;
    public registerWithNetwork?: string;
    public loginError?: string;
    public genderOptions: any[];
    public faceCamUsage!: boolean;

    // Geburtstagsconfig
    public minDate!: Date;
    public maxDate!: Date;

    public showSlider = true;
    public working = false;

    // Werbepräferenzen
    public keywords: any[] = [];
    public selectedKeywordItems: any[] = [];

    // Länder
    public countriesDropDownList: any[] = [];
    public selectedCountryItems: any[] = [];
    public dropdownSettings: IDropdownSettings = {};
    public streamerCountry!: any;
    public streamercountries: any[] = [
        {
            name: 'Deutschland',
            code: 'Deutschland'
        },
        {
            name: 'Schweiz',
            code: 'Schweiz'
        },
        {
            name: 'Österreich',
            code: 'Österreich'
        },
        {
            name: 'USA',
            code: 'USA'
        },
    ];

    public streamerAttention!: { name: string, code: string };
    public attentionReason!: string;
    public attentionOptions: any[] = [
        {
            name: 'Durch Freunde/Andere Streamer',
            code: 'friends / streamer'
        },
        {
            name: 'Durch Werbung für streamboost',
            code: 'advertising'
        },
        {
            name: 'Über die streamboost-Website',
            code: 'website'
        },
        {
            name: 'Empfehlung auf TikTok oder Twitch',
            code: 'Tiktok / Twitch'
        },
        {
            name: 'Auf einem Event / einer Messe',
            code: 'event'
        },
        {
            name: 'Anderer Grund',
            code: 'other'
        }
    ]

    // Geschlecht
    public tmpPercGenderMen: number = 0;
    public tmpPercGenderWomen: number = 0;
    public tmpPercGenderDivers: number = 0;
    public selectedGenderValues: string[] = [];

    // Alter
    public tmpPercLessThen18: number = 0;
    public tmpPerc18to24: number = 0;
    public tmpPerc25to34: number = 0;
    public tmpPerc35to44: number = 0;
    public tmpPercOlderThen44: number = 0;
    public ageRangeValues: number[] = [12, 21];

    // Affiliate Programme
    public affiliateSource: Affiliate[] = [];
    public selectedAffiliates: Affiliate[] = [];
    public showAffiliateDescription = false;
    public selectedAffiliate!: Affiliate;
    public tooManyAffiliates = false;
    public maxAffiliateLimitation = false;

    constructor(public globalService: GlobalService, public i18nService: I18nService,
                public keywordService: KeywordService, public affiliateService: AffiliateService,
                public routingService: RoutingService, public streamerService: StreamerService,
                public mathHelper: MathHelper, public route: ActivatedRoute,
                private socialService: SocialService) {

        this.genderOptions = [
            {label: this.i18nService.localize().StreamerRegisterComponent.Male, value: 'male'},
            {label: this.i18nService.localize().StreamerRegisterComponent.Female, value: 'female'},
            {label: this.i18nService.localize().StreamerRegisterComponent.Divers, value: 'divers'}
        ];
    }

    ngOnInit(): void {

        //  Weiterleitung machen falls der Streamer schon registriert ist
        if (this.globalService.streamer) {
            if (!this.globalService.streamer.inRegister) {
                this.routingService.switchToPath('/streamer/home');
            }
        }

        // Ursprungsnetzwerk aus der URL holen
        this.registerWithNetwork = this.route.snapshot.params.with;
        this.loginError = this.route.snapshot.params.error;

        // Keywords und Länder holen und füllen
        this.keywordService.getAllAdKeywords().then((keywords) => {
            this.keywords = [];
            for (let j = 0; j < keywords.length; j++) {
                const id = keywords[j].id;
                const text = keywords[j].getValueInLanguage('word', this.i18nService.getCurrentLang());
                this.keywords.push({item_id: id, item_text: text});
            }
        }).catch((err) => {
            console.log(err);
        });

        this.keywordService.getAllCountries().then((countries) => {
            this.countriesDropDownList = [];
            countries.forEach((country) => {
                this.countriesDropDownList.push(
                    {
                        // @ts-ignore
                        item_id: country.id,
                        // @ts-ignore
                        item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
                    });
            });
        }).catch((err) => {
            console.log(err);
        });

        this.streamerService.getExternalTierLimitation().then(result => {
            this.maxAffiliateLimitation = result;
        });

        // Affiliate Programme füllen
        this.affiliateService.getActiveExternalAffiliates().then((result) => {
            const visibleExternalAffiliates: Affiliate[] = [];

            var i;
            for (i = 0; i < result.length; i++) {
                var availableAffiliate = result[i];
                var found = false;

                var j;
                for (j = 0; j < this.selectedAffiliates.length; j++) {
                    var selectedAffiliate = this.selectedAffiliates[j];

                    if (availableAffiliate.name === selectedAffiliate.name) {
                        found = true;
                    }
                }
                if (!found) {
                    visibleExternalAffiliates.push(availableAffiliate);
                }
            }

            this.affiliateSource = visibleExternalAffiliates;
        });

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: false
        };

        this.minDate = new Date();
        this.minDate.setFullYear(this.minDate.getFullYear() - 90);
        this.maxDate = new Date();
    }

    goToNextStep() {
        if (this.step === 0) {
            this.step = 1;
        } else if (this.step === 1) {
            this.step = 2;
        } else if (this.step === 2) {
            this.step = 3;
        }
    }

    goToPreviousStep() {
        if (this.step === 1) {
            this.step = 0;
        } else if (this.step === 2) {
            this.step = 1;
        } else if (this.step === 3) {
            this.step = 2;
        }
    }

    checkAge() {
        if (this.birthday) {
            var diff = (this.birthday.getTime() - new Date().getTime()) / 1000;
            diff /= (60 * 60 * 24);

            // 6574.5 = 18 Jahre in Tagen
            this.ageError = Math.abs(diff) < 6574.5;
        }
    }

    checkRange(event: any) {
        let max: number = 60;

        let low = this.ageRangeValues[0];
        let high = this.ageRangeValues[1];

        if ((low + 9) > high) {

            while ((low + 9) > high) {
                if (high < max) {
                    high++;
                } else {
                    low--;
                }
            }

            this.ageRangeValues[0] = low;
            this.ageRangeValues[1] = high;

            this.showSlider = false;
            this.globalService.sleep(5).then(() => {
                this.showSlider = true;
            });
        }

    }

    checkPersonalValues(): boolean {
        let nextStepAllowed: boolean = true;

        if (!this.email) {
            nextStepAllowed = false;
        }

        if (this.email && !this.globalService.isEmailOk(this.email)) {
            nextStepAllowed = false;
        }

        if (!this.birthday) {
            nextStepAllowed = false;
        }

        this.checkAge();

        if (this.ageError) {
            nextStepAllowed = false;
        }

        if (!this.gender) {
            nextStepAllowed = false;
        }

        if (!this.postalCode) {
            nextStepAllowed = false;
        } else {
            if (this.postalCode.toString().length < 4) {
                nextStepAllowed = false;
            }
        }

        if (!this.streamerCountry) {
            nextStepAllowed = false;
        }

        return nextStepAllowed;
    }

    updateGenderSlider(currentSliderName: string) {
        if (this.tmpPercGenderWomen + this.tmpPercGenderMen + this.tmpPercGenderDivers > 100) {
            const sliderToTurnDown = this.getHighestGenderSlider(currentSliderName);
            // @ts-ignore
            if (this[sliderToTurnDown] > 0) {
                // @ts-ignore
                this[sliderToTurnDown] = this[sliderToTurnDown] - 1;
            }
        } else {
            const sliderToTurnUp = this.getLowestGenderSlider(currentSliderName);
            // @ts-ignore
            if (this[sliderToTurnUp] < 100) {
                // @ts-ignore
                this[sliderToTurnUp] = this[sliderToTurnUp] + 1;
            }
        }
    }

    getHighestGenderSlider(currentSliderName: string): any {
        const genderSliderMap = this.createGenderMap();
        const sortedGenderMap = new Map([...genderSliderMap.entries()].sort((a, b) => b[1] - a[1]));

        for (const [key] of sortedGenderMap) {
            if (key !== currentSliderName) {
                return key;
            }
        }
    }

    getLowestGenderSlider(currentSliderName: string): any {
        const genderSliderMap = this.createGenderMap();
        const sortedGenderMap = new Map([...genderSliderMap.entries()].sort((a, b) => a[1] - b[1]));

        for (const [key] of sortedGenderMap) {
            if (key !== currentSliderName) {
                return key;
            }
        }
    }

    createGenderMap(): Map<string, number> {
        const genderSliderMap = new Map();

        if (!this.selectedGenderValues.includes('tmpPercGenderDivers')) {
            genderSliderMap.set('tmpPercGenderDivers', this.tmpPercGenderDivers);
        }

        if (!this.selectedGenderValues.includes('tmpPercGenderMen')) {
            genderSliderMap.set('tmpPercGenderMen', this.tmpPercGenderMen);
        }

        if (!this.selectedGenderValues.includes('tmpPercGenderWomen')) {
            genderSliderMap.set('tmpPercGenderWomen', this.tmpPercGenderWomen);
        }

        return genderSliderMap;
    }

    selectAffiliate(event: any) {
        this.selectedAffiliate = event.items[0];
    }

    updateChanges() {
        if (this.globalService.streamer) {
            this.tooManyAffiliates = false;

            if (this.maxAffiliateLimitation) {

                // Prüfen wie viel man auswählen darf
                let numberOfNewAffiliates = this.selectedAffiliates.length;
                if (numberOfNewAffiliates > this.globalService.streamer.allowedNumberOfExternalAffiliates) {
                    this.tooManyAffiliates = true;
                }
            }
        }

    }

    checkAllValues(): boolean {
        let registerAllowed: boolean = true;

        if (!this.email) {
            registerAllowed = false;
        }

        if (!this.birthday) {
            registerAllowed = false;
        }

        return registerAllowed;
    }

    register(shortcut: boolean) {
        this.working = true;

        if (this.checkAllValues()) {
            if (!shortcut) {
                this.convertAgeRangeToAgeCategories();
            }

            if (this.streamerAttention.code !== 'other') {
                this.attentionReason = this.streamerAttention.code;
            }

            this.streamerService.registerStreamer(this.email, this.birthday, this.tmpPercLessThen18, this.tmpPerc18to24,
                this.tmpPerc25to34, this.tmpPerc35to44, this.tmpPercOlderThen44, this.tmpPercGenderMen, this.tmpPercGenderWomen,
                this.tmpPercGenderDivers, this.selectedKeywordItems, this.selectedCountryItems, this.selectedAffiliates,
                this.gender, this.attentionReason, this.postalCode, this.streamerCountry.name, this.faceCamUsage, shortcut).then(res => {
                this.working = false;

                this.routingService.switchToPathWithReload('/streamer/home');
            }).catch((err: any) => {
                console.log(err);
                this.working = false;
            });
        }
    }

    convertAgeRangeToAgeCategories() {
        let ageFrom = this.ageRangeValues[0];
        let ageTo = this.ageRangeValues[1];

        // Wenn überhaupt eine Altersspanne angegeben wurde
        if (ageTo > 12 && ageFrom > 0) {
            let ageValues: number[] = [];

            while (ageFrom <= ageTo) {
                ageValues.push(ageFrom);
                ageFrom++;
            }

            let countUpTo17 = 0;
            let count18To24 = 0;
            let count25To34 = 0;
            let count35To44 = 0;
            let countFrom45 = 0;

            let total = 0;
            for (let i = 0; i < ageValues.length; i++) {

                switch (ageValues[i]) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                        countUpTo17++;
                        break;
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 24:
                        count18To24++;
                        break;
                    case 25:
                    case 26:
                    case 27:
                    case 28:
                    case 29:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                        count25To34++;
                        break;
                    case 35:
                    case 36:
                    case 37:
                    case 38:
                    case 39:
                    case 40:
                    case 41:
                    case 42:
                    case 43:
                    case 44:
                        count35To44++;
                        break;
                    default:
                        countFrom45++;
                }
                total++;
            }

            this.tmpPercLessThen18 = this.mathHelper.precisionRound(countUpTo17 / total * 100, 0);
            this.tmpPerc18to24 = this.mathHelper.precisionRound(count18To24 / total * 100, 0);
            this.tmpPerc25to34 = this.mathHelper.precisionRound(count25To34 / total * 100, 0);
            this.tmpPerc35to44 = this.mathHelper.precisionRound(count35To44 / total * 100, 0);
            this.tmpPercOlderThen44 = this.mathHelper.precisionRound(countFrom45 / total * 100, 0);

            // console.log('u18: '+this.tmpPercLessThen18);
            // console.log('18-24: '+this.tmpPerc18to24);
            // console.log('25-34: '+this.tmpPerc25to34);
            // console.log('35-44: '+this.tmpPerc35to44);
            // console.log('o44: '+this.tmpPercOlderThen44);
        }

    }

    // Login mit dem jeweils anderen Netzwerk für die Account Verknüpfung
    loginWithTwitch() {
        if (this.globalService.streamer) {
            this.working = true;

            this.socialService.getTwitchLoginURL(true, this.globalService.streamer.id)
                .then((data) => {
                    window.location.href = data.response.url;
                    this.working = false;
                })
                .catch((error) => {
                    this.working = false;
                });
        }
    }

    loginWithGoogle() {
        if (this.globalService.streamer) {
            this.working = true;

            this.socialService.getGoogleLoginURL(true, this.globalService.streamer.id)
                .then((data) => {
                    window.location.href = data.response.url;
                    this.working = false;
                })
                .catch((error) => {
                    this.working = false;
                });
        }
    }

    createErrorLoginMessage(): string {
        let otherNetwork;
        if (this.registerWithNetwork === 'Google') {
            otherNetwork = 'Twitch';
        } else {
            otherNetwork = 'Google';
        }

        return this.i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterError1 +
            otherNetwork +
            this.i18nService.localize().StreamerRegisterComponent.DifferentAccountRegisterError2;
    }

}
