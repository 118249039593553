import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class IpService {

  private ip: any;

  constructor(private httpClient: HttpClient) {
  }

  getIpInternal(): Promise<any> {
    return this.httpClient.get(environment.streamWebUrl + '/ownip').pipe(
      map(res => {
        return res;
      }))
      .toPromise();
  }

  getIpExternally(): Promise<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('origin', 'https://www.streamboost.de');

    return this.httpClient.get('https://cors-anywhere.herokuapp.com/https://api64.ipify.org?format=json', { headers }).pipe(
      map(res => {
        return res;
      }))
      .toPromise().catch(() => {
        console.log('Failed to get ip from external source');
      });
  }

  getIp(): Promise<any> {
    return new Promise((resolve) => {
      this.getIpInternal().then(res2 => {
        resolve(res2);
      });
    });
  }

  // getIp(): Promise<any> {
  //   return new Promise((resolve) => {
  //     // console.log(this.ip);
  //
  //     if (this.ip) {
  //       resolve(this.ip);
  //     } else {
  //
  //       this.getIpExternally().then(res1 => {
  //         if (!res1) {
  //           this.getIpInternal().then(res2 => {
  //             resolve(res2);
  //           });
  //         } else {
  //           const ip = res1.ip;
  //
  //           if (!ip) {
  //             this.getIpInternal().then(res2 => {
  //               resolve(res2);
  //             });
  //
  //           } else {
  //             this.ip = res1;
  //             resolve(res1);
  //           }
  //         }
  //
  //       }).catch( () => {
  //           this.getIpInternal().then(res => {
  //             resolve(res);
  //           });
  //         }
  //       );
  //     }
  //   });
  //
  // }

}

