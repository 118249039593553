import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxPayPalModule} from 'ngx-paypal';
import {NgxSummernoteModule} from 'ngx-summernote';

import {MenuComponent} from './menu/menu.component';
import {OauthCallbackComponent} from './streamer/oauth-callback/oauth-callback.component';
import {StreamerLoginComponent} from './streamer/login/streamer-login.component';
import {StreamerHomeComponent} from './streamer/home/streamer-home.component';
import {StreamerNewsComponent} from './streamer/news/streamer-news.component';
import {StreamerContestComponent} from './streamer/contest/streamer-contest.component';
import {StreamerCampaignsComponent} from './streamer/campaigns/streamer-campaigns.component';
import {StreamerAffiliatesComponent} from './streamer/affiliates/streamer-affiliates.component';
import {StreamerStatusDialogComponent} from './streamer/status-dialog/streamer-status-dialog.component';
import {StreamerEmailActivationComponent} from './streamer/email-activation/email-activation.component';
import {StreamerIncomeComponent} from './streamer/income/streamer-income.component';
import {StreamerRegisterComponent} from './streamer/register/streamer-register.component';
import {StreamerDeleteDialogComponent} from './streamer/delete-dialog/streamer-delete-dialog.component';
import {StreamerCampaignsOverviewComponent} from './streamer/campaigns-overview/streamer-campaigns-overview.component';
import {
  StreamerAffiliatesOverviewComponent
} from './streamer/affiliates-overview/streamer-affiliates-overview.component';
import {StreamerFeedbackPanelComponent} from './streamer/feedback-panel/streamer-feedback-panel.component';
import {AdvertiserLoginComponent} from './advertiser/login/advertiser-login.component';
import {AdvertiserDashboardComponent} from './advertiser/dashboard/advertiser-dashboard.component';
import {AdvertiserBillsComponent} from './advertiser/bills/advertiser-bills.component';
import {AdvertiserCampaignsComponent} from './advertiser/campaigns/advertiser-campaigns.component';
import {AdvertiserCampaignDetailsComponent} from './advertiser/campaign-details/advertiser-campaign-details.component';
import {
  AdvertiserAffiliateDetailsComponent
} from './advertiser/affiliate-details/advertiser-affiliate-details.component';
import {AdvertiserAffiliateExtendComponent} from './advertiser/affiliate-extend/advertiser-affiliate-extend.component';
import {AdvertiserProfileComponent} from './advertiser/profile/profile-advertiser.component';
import {AdvertiserRegisterComponent} from './advertiser/register/advertiser-register.component';
import {AdvertiserActivationComponent} from './advertiser/activation/advertiser-activation.component';
import {AdvertiserCampaignExtendComponent} from './advertiser/campaign-extend/advertiser-campaign-extend.component';
import {AdvertiserCancelMailsComponent} from './advertiser/cancel-mails/advertiser-cancel-mails.component';
import {AdvertiserAdminTakeoverComponent} from './advertiser/admin-takeover/admin-takeover.component';
import {AgencyLoginComponent} from './agency/login/agency-login.component';
import {AgencyDashboardComponent} from './agency/dashboard/agency-dashboard.component';
import {AgencyCreateAdvertiserComponent} from './agency/create-advertiser/agency-create-advertiser.component';
import {AgencyAdvertisersComponent} from './agency/advertisers/agency-advertisers.component';
import {AdvertiserDetailsComponent} from './agency/advertiser-details/advertiser-details.component';
import {AgencyProfileComponent} from './agency/profile/profile-agency.component';
import {AgencyUsersComponent} from './agency/users/agency-users.component';
import {AgencyAdminTakeoverComponent} from './agency/admin-takeover/admin-takeover.component';
import {AgencyPicturesComponent} from './agency/pictures/pictures.component';
import {AcceptTermsDialogComponent} from './accept-terms-dialog/accept-terms-dialog.component';
import {AgencyActivationComponent} from './agency/activation/agency-activation.component';
import {AdvertiserTagsComponent} from './advertiser/tags/advertiser-tags.component';
import {CampaignWizardComponent} from './campaign-wizard/campaign-wizard.component';
import {AgencyAdvertiserRegisterComponent} from './register/agency-advertiser-register.component';
import {AdvertiserAgencyLoginComponent} from './login/advertiser-agency-login.component';

import {StreamBoostEventBus} from './streamboost-eventbus.service';
import {GlobalService} from './services/global.service';
import {RoutingService} from './services/routing.service';
import {IpService} from './services/ip.service';
import {SocialService} from './services/social.service';
import {StreamerService} from './services/streamer.service';
import {AdvertiserService} from './services/advertiser.service';
import {MathHelper} from './services/math.helper';
import {NewsService} from './services/news.service';
import {CompetitionService} from './services/competition.service';
import {KeywordService} from './services/keyword.service';
import {I18nService} from './services/i18n.service';
import {AffiliateService} from './services/affiliate.service';
import {CampaignService} from './services/campaign.service';
import {BillService} from './services/bill.service';
import {FileService} from './services/file.service';
import {PictureService} from './services/picture.service';
import {NotificationService} from './services/notification.service';
import {DiscountService} from './services/discount.service';
import {AgencyService} from './services/agency.service';
import {FeedbackService} from './services/feedback.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CookieService} from 'ngx-cookie-service';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TagModule} from 'primeng/tag';
import {NgMultiselectDropdown3Module} from "ng-multiselect-dropdown3";
import {MegaMenuModule} from 'primeng/megamenu';
import {SharedModule} from 'primeng/api';
import {CardModule} from 'primeng/card';
import {PanelModule} from 'primeng/panel';
import {CarouselModule} from 'primeng/carousel';
import {RippleModule} from 'primeng/ripple';
import {CalendarModule} from 'primeng/calendar';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {SliderModule} from 'primeng/slider';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import {PickListModule} from 'primeng/picklist';
import {DragDropModule} from 'primeng/dragdrop';
import {TooltipModule} from 'primeng/tooltip';
import {TableModule} from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TabMenuModule} from 'primeng/tabmenu';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AccordionModule} from 'primeng/accordion';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import {DataViewModule} from 'primeng/dataview';
import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';
import {SidebarModule} from 'primeng/sidebar';
import {DropdownModule} from 'primeng/dropdown';
import {PasswordModule} from 'primeng/password';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ImageModule} from 'primeng/image';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {NgApexchartsModule} from 'ng-apexcharts';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import {MenuModule} from 'primeng/menu';
import {NgIf} from '@angular/common';
import {PaymentComponent} from './payment/payment.component';
import {PaymentService} from "./services/paymentService";
import {PaymentConfirmationComponent} from "./payment/payment-conformation/payment-confirmation.component";
import {CountUpModule} from "ngx-countup";
import {WizardCampaignsComponent} from './advertiser/campaigns/wizard-campaigns/wizard-campaigns.component';
import {ImageCropperComponent} from "ngx-image-cropper";

import 'hammerjs';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    StreamerLoginComponent,
    OauthCallbackComponent,
    StreamerHomeComponent,
    StreamerNewsComponent,
    StreamerCampaignsComponent,
    StreamerContestComponent,
    StreamerAffiliatesComponent,
    StreamerIncomeComponent,
    StreamerStatusDialogComponent,
    StreamerRegisterComponent,
    StreamerEmailActivationComponent,
    StreamerDeleteDialogComponent,
    StreamerCampaignsOverviewComponent,
    StreamerAffiliatesOverviewComponent,
    StreamerFeedbackPanelComponent,
    AdvertiserLoginComponent,
    AdvertiserDashboardComponent,
    AdvertiserBillsComponent,
    AdvertiserProfileComponent,
    AdvertiserBillsComponent,
    AdvertiserCampaignsComponent,
    AdvertiserCampaignDetailsComponent,
    AdvertiserAffiliateDetailsComponent,
    AdvertiserAffiliateExtendComponent,
    AdvertiserRegisterComponent,
    AdvertiserActivationComponent,
    AdvertiserCampaignExtendComponent,
    AdvertiserCancelMailsComponent,
    AgencyLoginComponent,
    AgencyDashboardComponent,
    AgencyCreateAdvertiserComponent,
    AgencyProfileComponent,
    MobileMenuComponent,
    AgencyAdvertisersComponent,
    AdvertiserDetailsComponent,
    AgencyUsersComponent,
    AdvertiserAdminTakeoverComponent,
    AgencyAdminTakeoverComponent,
    AgencyPicturesComponent,
    AcceptTermsDialogComponent,
    AgencyActivationComponent,
    AdvertiserTagsComponent,
    CampaignWizardComponent,
    AgencyAdvertiserRegisterComponent,
    AdvertiserAgencyLoginComponent,
    PaymentComponent,
    PaymentConfirmationComponent,
    WizardCampaignsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ButtonModule,
    InputTextModule,
    ProgressSpinnerModule,
    MegaMenuModule,
    SharedModule,
    CardModule,
    CarouselModule,
    RippleModule,
    NgMultiselectDropdown3Module,
    PanelModule,
    TagModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    SliderModule,
    DialogModule,
    ListboxModule,
    PickListModule,
    DragDropModule,
    TooltipModule,
    TableModule,
    ProgressBarModule,
    OverlayPanelModule,
    TabMenuModule,
    FileUploadModule,
    InputTextareaModule,
    AccordionModule,
    InputSwitchModule,
    RadioButtonModule,
    InputNumberModule,
    DataViewModule,
    SidebarModule,
    DropdownModule,
    PasswordModule,
    SelectButtonModule,
    ImageModule,
    TriStateCheckboxModule,
    DividerModule,
    FieldsetModule,
    MenuModule,
    NgxPayPalModule,
    NgApexchartsModule,
    NgxSummernoteModule.forRoot(),
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    NgIf,
    PasswordModule,
    RippleModule,
    ProgressSpinnerModule,
    CountUpModule,
    ImageCropperComponent
  ],
  providers: [StreamBoostEventBus, GlobalService, CookieService, RoutingService, IpService, SocialService,
    StreamerService, AdvertiserService, MathHelper, NewsService, CompetitionService, KeywordService, I18nService,
    AffiliateService, CampaignService, BillService, FileService, PictureService, NotificationService, DiscountService,
    AgencyService, FeedbackService, PaymentService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
