import { StreamBoostEventBus } from '../streamboost-eventbus.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SuperService } from './super.service';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import { AdvertiserService } from './advertiser.service';
import { AgencyPicture } from '../model/agency/agency-picture.model';

@Injectable()
export class PictureService extends SuperService {
  constructor(
    public streamBoostEventBus: StreamBoostEventBus,
    private cookieService: CookieService,
    private http: HttpClient,
    private advertiserService: AdvertiserService
  ) {
    super(streamBoostEventBus);
  }

  uploadImageEventBus(fileName: string, file: string, campaign_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.picture.upload', {
          message: {
            filename: fileName,
            file: file,
            campaign_id: campaign_id,
          },
        })
        .then((result) => {
          {
            if (result.body.code === 'Theseus') {
              resolve(result.body);
            } else if (result.body.code) {
              reject(result.body.code);
            } else {
              resolve(result.body);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  uploadImagesHttp(files: File[], entity_id: string, type: string, entity: string, advertiserId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const uploadPromises: any[] = [];

      for (let i = 0; i < files.length; i++) {
        let formData = new FormData();
        formData.append('camp-upload', files[i], files[i].name);
        formData.append('type', type);

        uploadPromises.push(this.uploadImageHttp(formData, entity_id, type, entity, advertiserId));
      }

      Promise.all(uploadPromises).then((values) => {
        resolve();
      }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  }

  uploadImageHttp(formData: FormData, entity_id: string, type: string, entity: string, advertiserId?: string): Promise<void> {
    return new Promise((resolve, reject) => {

      let token;
      if (this.cookieService.get('streamboost-session')) {
        token = this.cookieService.get('streamboost-session');
      }

      if (token) {
        let headers: HttpHeaders = new HttpHeaders({ token: token });
        this.uploadInternal(entity, entity_id, formData, headers, resolve, reject);
      } else {
        if (advertiserId) {
          let headers: HttpHeaders = new HttpHeaders({ advertiser_id: advertiserId });
          this.uploadInternal(entity, entity_id, formData, headers, resolve, reject);
        }
      }
    });
  }

  private uploadInternal(entity: string, entity_id: string, formData: FormData, headers: HttpHeaders, resolve: (value: (PromiseLike<void> | void)) => void, reject: (reason?: any) => void) {
    this.http
      .post('/picture/upload/' + entity + '/' + entity_id, formData, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              // console.log('sending');
              break;
            case HttpEventType.Response:
              // console.log('response');
              resolve();
              break;
            case HttpEventType.UploadProgress: {
              // console.log('uploading in progress')
              break;
            }
          }
        },
        (error) => {
          console.log(error);
          reject();
        }
      );
  }

  deleteAffiliateBanner(pic_id: string, affiliate_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.picture.affiliate.remove', {
          message: {
            pic_id,
            affiliate_id,
          },
        })
        .then((result: any) => {
          if (result.body.code === 'Theseus') {
            this.advertiserService.setAdvertiser(result.body.response);
            resolve();
          } else if (result.body.code) {
            reject(result.body.code);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteCampaignPicture(pic_id: string, campaign_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.picture.campaign.remove', {
          message: {
            pic_id,
            campaign_id,
          },
        })
        .then((result: any) => {
          if (result.body.code === 'Theseus') {
            this.advertiserService.setAdvertiser(result.body.response);
            resolve();
          } else if (result.body.code) {
            reject(result.body.code);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public checkLogoOrStreamBanner(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // @ts-ignore
          const img_height = rs.currentTarget['height'];
          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_height != 250 && img_width != 300) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  checkAffiliateBanner(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // @ts-ignore
          const img_width = rs.currentTarget['width'];

          if (img_width > 500) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  checkCampaignBanner(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // @ts-ignore
          const img_width = rs.currentTarget['width'];
          // @ts-ignore
          const img_height = rs.currentTarget['height'];

          if (img_width > 300 && img_height > 250) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  deleteAgencyPicture(pic_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.picture.agency.remove', {
          message: {
            pic_id
          },
        })
        .then((result: any) => {
          if (result.body.code === 'Theseus') {
            resolve();
          } else if (result.body.code) {
            reject(result.body.code);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getAllAgencyPictures(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.all.pictures',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAgencyPicturesForCampaigns(): Promise<AgencyPicture[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.campaign.pictures',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              let pictures: AgencyPicture[] = [];

              let picResponse = response.body.response.pictures;
              for (let i = 0; i < picResponse.length; i++) {
                let picture: AgencyPicture = new AgencyPicture();
                picture.id = picResponse[i]._id;
                picture.name = picResponse[i].name;
                picture.contentType = picResponse[i].content_type;

                pictures.push(picture);
              }

              resolve(pictures);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public getAgencyPicturesForAffiliates(): Promise<AgencyPicture[]> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.agency.affiliate.pictures',
          {
            message: { }
          })
          .then(
            (response: any) => {
              if (response.body.code) {
                reject(response.body.code);
                localStorage.clear();
                return;
              }

              let pictures: AgencyPicture[] = [];

              let picResponse = response.body.response.pictures;
              for (let i = 0; i < picResponse.length; i++) {
                let picture: AgencyPicture = new AgencyPicture();
                picture.id = picResponse[i]._id;
                picture.name = picResponse[i].name;
                picture.contentType = picResponse[i].content_type;

                pictures.push(picture);
              }

              resolve(pictures);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  public copyLogoForInternalAffiliate(affiliateId: string, advertiserId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cookieService.get('streamboost-session')) {
        this.streamBoostEventBus.send('stream.web.facade.picture.copy.logo',
          {
            message: {
              affiliate_id: affiliateId,
              advertiser_id: advertiserId
            }
          })
          .then(
            (response: any) => {
              if (response.body.code !== 'Theseus') {
                reject(response.body.code);
                localStorage.clear();
                return;
              }
              resolve(response.body.response);
            }
          )
          .catch(
            (err: any) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  resetWizardWIPPictures(wip_id: String, campaign_pics: boolean, affiliate_pics: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus
        .send('stream.web.facade.wizard.wip.pictures.reset', {
          message: {
            wip_id,
            campaign_pics,
            affiliate_pics,
          },
        })
        .then((result: any) => {
          if (result.body.code === 'Theseus') {
            resolve();
          } else if (result.body.code) {
            reject(result.body.code);
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
