<div *ngIf="this.globalService.agency">
    <div class="headline">{{i18nService.localize().AgencyProfileComponent.ProfilHeader}}</div>
    <div class="p-col-12">
        <div class="p-grid abstand">

            <div class="p-col-12 start">
                <div class="p-grid">

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2" style="padding: 3em 1em 4em 4em;">
                        <div>
                          <div *ngIf="!globalService.agency.logoURL" class="p-text-center"
                               style="height: 170px; width: 170px; background: #1d60a4; border-radius: 500px;">
                            <img class="defaultPic" src="/assets/images/Icons/Navi_Profil_blau.svg" alt="">
                          </div>
                          <img src="{{globalService.agency.logoURL}}" style="height: 170px; width: 170px; border-radius: 500px;"
                               *ngIf="globalService.agency.logoURL">
                        </div>
                        <div style="text-align: right;">
                          <button pButton pRipple type="button" (click)="showChangeLogo()" icon="fas fa-pencil-alt"
                                  [disabled]=changeLogo style="margin-right: .25em" class="p-button-rounded"
                                  *ngIf="this.globalService.agency.isUserAdmin()">
                          </button>
                        </div>

                        <p-dialog [(visible)]="changeLogo" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
                                  [resizable]="false">
                            <ng-template pTemplate="header">
                                <div class="headline">{{i18nService.localize().AgencyProfileComponent.UploadProfilLogoHeader}}</div>
                            </ng-template>
                            <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png"
                                          [maxFileSize]=1500000 id="logo" [showUploadButton]="false"
                                          [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                                          chooseLabel="{{i18nService.localize().AgencyProfileComponent.ChooseButton}}"
                                          cancelLabel="{{i18nService.localize().AgencyProfileComponent.CancelButton}}"
                                          *ngIf="changeLogo">
                              <ng-template pTemplate="content">
                                <ul *ngIf="uploadedPictures.length">
                                  <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes
                                  </li>
                                </ul>
                              </ng-template>
                            </p-fileUpload>

                            <p class="warning" *ngIf="!picturesOk">
                              {{i18nService.localize().AgencyProfileComponent.PicError}}
                            </p>

                            <div style="text-align: right; margin-top: 1em">
                              <button pButton pRipple type="button" icon="pi pi-check" (click)="uploadPic()"
                                      class="p-button-rounded">
                              </button>
                              <button pButton pRipple type="button" icon="pi pi-times" (click)="changeLogo=false"
                                      class="p-button-rounded" style="margin-left: 0.5em">
                              </button>
                            </div>
                        </p-dialog>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{this.globalService.agency.agencyName}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeProfile">
                                    <p *ngIf="this.globalService.agency.firstname || this.globalService.agency.lastname"
                                        class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.StreetNr}}</p>
                                    <p style="margin-top: 0">{{this.globalService.agency.street}}
                                        {{this.globalService.agency.nr}}</p>
                                    <p class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.City}}</p>
                                    <p style="margin-top: 0">{{this.globalService.agency.postalCode}} {{this.globalService.agency.city}}</p>
                                  <p class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.Country}}</p>
                                  <p style="margin-top: 0">{{this.globalService.agency.country}}</p>
                                </div>
                                <div *ngIf="changeProfile">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-8">
                                            <label for="street">{{i18nService.localize().AgencyProfileComponent.Street}}:</label>
                                            <input id="street" type="text" pInputText
                                                [(ngModel)]="this.globalService.agency.street">
                                        </div>
                                        <div class="p-field p-col-4">
                                            <label for="nr">{{i18nService.localize().AgencyProfileComponent.Nr}}:</label>
                                            <input id="nr" type="text" pInputText
                                                [(ngModel)]="this.globalService.agency.nr">
                                        </div>
                                        <div class="p-field p-col-12">
                                            <label for="postalCode">{{i18nService.localize().AgencyProfileComponent.PostalCode}}:</label>
                                            <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="this.globalService.agency.postalCode">
                                        </div>
                                        <div class="p-field p-col-12">
                                            <label for="city">{{i18nService.localize().AgencyProfileComponent.City}}:</label>
                                            <input id="city" type="text" pInputText
                                                [(ngModel)]="this.globalService.agency.city">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p-footer>
                                <button pButton pRipple type="button" (click)="changeProfileInfo()"
                                        icon="fas fa-pencil-alt" *ngIf="!changeProfile && this.globalService.agency.isUserAdmin()"
                                        style="margin-right: .25em"
                                        class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveProfileChanges()"
                                        icon="fas fa-check" *ngIf="changeProfile && this.globalService.agency.isUserAdmin()"
                                        style="margin-right: .25em" pTooltip="{{i18nService.localize().AgencyProfileComponent.SaveChangesTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="abortProfileChanges()"
                                        icon="fas fa-times" *ngIf="changeProfile && this.globalService.agency.isUserAdmin()"
                                        pTooltip="{{i18nService.localize().AgencyProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AgencyProfileComponent.ContactHeader}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeContact">
                                  <p *ngIf="this.globalService.agency.firstname || this.globalService.agency.lastname"
                                      class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.Name}}</p>
                                  <p style="margin-top: 0">{{this.globalService.agency.firstname}}
                                      {{this.globalService.agency.lastname}}</p>
                                  <p class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.Email}}</p>
                                  <p style="margin-top: 0">{{this.globalService.agency.email}}</p>
                                  <p class="font-weight-bold" *ngIf="this.globalService.agency.telephone">
                                    {{i18nService.localize().AgencyProfileComponent.Telephone}}
                                  </p>
                                  <p style="margin-top: 0" *ngIf="this.globalService.agency.telephone">
                                    {{this.globalService.agency.telephone}}
                                  </p>
                                </div>
                                <div *ngIf="changeContact">
                                  <div class="p-fluid p-formgrid p-grid">
                                      <div class="p-field p-col-12">
                                          <label for="name">{{i18nService.localize().AgencyProfileComponent.Firstname}}:</label>
                                          <input id="name" type="text" pInputText
                                              [(ngModel)]="this.globalService.agency.firstname">
                                      </div>
                                      <div class="p-field p-col-12">
                                          <label for="lastname">{{i18nService.localize().AgencyProfileComponent.Lastname}}:</label>
                                          <input id="lastname" type="text" pInputText
                                              [(ngModel)]="this.globalService.agency.lastname">
                                      </div>
                                      <div class="p-field p-col-12">
                                          <label for="email">{{i18nService.localize().AgencyProfileComponent.Email}}:</label>
                                          <input id="email" type="text" pInputText
                                              [(ngModel)]="this.globalService.agency.email">
                                      </div>
                                  </div>
                                  <p class="warning" *ngIf="emailError">
                                    {{i18nService.localize().AgencyProfileComponent.EmailError}}
                                  </p>
                                </div>
                            </div>
                            <p-footer>
                                <button pButton pRipple type="button" (click)="changeContactInfo()"
                                    icon="fas fa-pencil-alt" *ngIf="!changeContact" style="margin-right: .25em"
                                    class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveContactChanges()"
                                        icon="fas fa-check" *ngIf="changeContact" style="margin-right: .25em"
                                        pTooltip="{{i18nService.localize().AgencyProfileComponent.SaveChangesTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="abortContactChanges()"
                                        icon="fas fa-times" *ngIf="changeContact"
                                        pTooltip="{{i18nService.localize().AgencyProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AgencyProfileComponent.TaxHeader}}</h2>
                            <div style="margin: 1em;">
                                <div *ngIf="!changeTax">
                                    <p class="font-weight-bold">{{i18nService.localize().AgencyProfileComponent.Tax}}</p>
                                    <p style="margin-top: 0; margin-bottom: 4.5em; word-wrap: anywhere;" *ngIf="this.globalService.agency.taxNumber">{{this.globalService.agency.taxNumber}}</p>
                                    <p style="margin-top: 0; margin-bottom: 4.5em;" *ngIf="!this.globalService.agency.taxNumber">
                                      {{i18nService.localize().AgencyProfileComponent.NoTax}}
                                    </p>
                                </div>
                                <div *ngIf="changeTax">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12">
                                            <label for="tax">{{i18nService.localize().AgencyProfileComponent.Tax}}:</label>
                                            <input id="tax" type="text" pInputText maxlength="30"
                                                   [(ngModel)]="this.globalService.agency.taxNumber">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p-footer>
                              <button pButton pRipple type="button" (click)="startStripePortal()"
                                      *ngIf="this.globalService.agency?.customerId" style="margin: 1em;"
                                      pTooltip="{{i18nService.localize().AgencyProfileComponent.StripeCustomerPortal}}"
                                      class="p-button-rounded"> STRIPE
                              </button>
                                <button pButton pRipple type="button" (click)="changeTaxInfo()"
                                        icon="fas fa-pencil-alt" *ngIf="!changeTax && this.globalService.agency.isUserAdmin()"
                                        style="margin-right: .25em" class="p-button-rounded">
                                </button>
                                <button pButton pRipple type="button" (click)="saveTaxChanges()"
                                        icon="fas fa-check" *ngIf="changeTax && this.globalService.agency.isUserAdmin()"
                                        style="margin-right: .25em" tooltipPosition="left" class="p-button-rounded"
                                        pTooltip="{{i18nService.localize().AgencyProfileComponent.SaveChangesTooltip}}">
                                </button>
                                <button pButton pRipple type="button" (click)="abortTaxChanges()"
                                        icon="fas fa-times" *ngIf="changeTax && this.globalService.agency.isUserAdmin()"
                                        pTooltip="{{i18nService.localize().AgencyProfileComponent.CancelTooltip}}"
                                        tooltipPosition="left" class="p-button-rounded p-button-secondary">
                                </button>
                            </p-footer>
                        </p-card>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-xxl-2">
                        <p-card [style]="{'height':'100%'}">
                            <h2 class="profile-headline">{{i18nService.localize().AgencyProfileComponent.AccountHeader}}</h2>
                            <div style="margin: 1em;">
                                <div class="p-col-12" *ngIf="!changePassword">
                                    <button pButton pRipple type="button"
                                            label="{{i18nService.localize().AgencyProfileComponent.ChangePasswordButton}}"
                                            class="p-button-rounded" (click)="openPasswordDialog()">
                                    </button>
                                </div>
                                <div class="p-col-12" *ngIf="!changePassword && this.globalService.agency.isUserAdmin()">
                                  <button pButton pRipple type="button" *ngIf="!globalService.agency.allowAdminAccess"
                                          pTooltip="{{i18nService.localize().AgencyProfileComponent.AllowAccessTooltip}}"
                                          label="{{i18nService.localize().AgencyProfileComponent.AllowAccessButton}}"
                                          style="margin-bottom: 1.7em;" class="p-button-rounded" (click)="grantAdminAccess()">
                                  </button>
                                  <p class="info" *ngIf="globalService.agency.allowAdminAccess">
                                    {{i18nService.localize().AgencyProfileComponent.AllowAccessInfoText}}
                                  </p>
                                </div>
                                <div *ngIf="changePassword">
                                    <div class="p-fluid">
                                        <div class="p-field">
                                            <label for="oldPassword">{{i18nService.localize().AgencyProfileComponent.OldPassword}}</label>
                                            <input pInputText type="password" name="oldPassword"
                                                id="oldPassword" [(ngModel)]="this.oldPassword"
                                                class="form-control" />
                                        </div>
                                        <div class="p-field">
                                            <label for="newPassword">{{i18nService.localize().AgencyProfileComponent.NewPassword}}</label>
                                            <input pInputText type="password" name="newPassword"
                                                id="newPassword" [(ngModel)]="this.newPassword"
                                                class="form-control" />
                                        </div>
                                        <div class="p-field">
                                            <label for="newPasswordToo">{{i18nService.localize().AgencyProfileComponent.NewPasswordAgain}}</label>
                                            <input pInputText type="password" name="newPasswordToo"
                                                [(ngModel)]="this.newPassword2" id="newPasswordToo"
                                                class="form-control" />
                                        </div>
                                        <p class="text-muted">
                                          {{i18nService.localize().AgencyProfileComponent.PasswordHint}}
                                        </p>
                                        <p id="pwdErrMsg1" class="error" *ngIf="this.passwordError">
                                          {{i18nService.localize().AgencyProfileComponent.PasswordError}}
                                        </p>
                                        <p id="pwdErrMsg2" class="error" *ngIf="this.authError">
                                          {{i18nService.localize().AgencyProfileComponent.AuthError}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p class="info" *ngIf="this.passwordChanged">
                              {{i18nService.localize().AgencyProfileComponent.PasswordChanged}}
                            </p>
                            <p-footer>
                                  <button pButton pRipple type="button" icon="fas fa-check"
                                          pTooltip="{{i18nService.localize().AgencyProfileComponent.ChangePasswordTooltip}}"
                                          tooltipPosition="left" class="p-button-rounded" (click)="savePasswordChange()"
                                          *ngIf="changePassword" style="margin-right: .25em;">
                                  </button>
                                  <button pButton pRipple type="button" icon="fas fa-times"
                                          pTooltip="{{i18nService.localize().AgencyProfileComponent.CancelTooltip}}"
                                          tooltipPosition="left" class="p-button-rounded p-button-secondary"
                                          (click)="abort()" *ngIf="changePassword">
                                  </button>
                            </p-footer>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
