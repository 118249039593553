import {Component, OnInit} from '@angular/core';
import {RoutingService} from '../services/routing.service';
import {AdvertiserService} from "../services/advertiser.service";
import {CookieService} from 'ngx-cookie-service';
import {IpService} from '../services/ip.service';
import {I18nService} from '../services/i18n.service';
import {AgencyService} from "../services/agency.service";

@Component({
  selector: 'advertiser-agency-login',
  templateUrl: './advertiser-agency-login.component.html',
  styleUrls: ['./advertiser-agency-login.component.scss']
})
export class AdvertiserAgencyLoginComponent implements OnInit {

  public activeTab: number = 1;

  email: string = "";
  password: string = "";
  loginError: string = "";
  activationResend: boolean;

  working: boolean = false;

  checked: boolean = false;

  constructor(private agencyService: AgencyService, private advertiserService: AdvertiserService,
              public routingService: RoutingService,
              public cookieService: CookieService, public ipService: IpService,
              public i18nService: I18nService) {
    this.email = "";
    this.password = "";
    this.activationResend = false;

    if (this.cookieService.get('streamboost-language')) {
      this.i18nService.setLang(this.cookieService.get('streamboost-language'));
    } else {
      this.i18nService.setLang(navigator.language.toUpperCase());
    }
  }

  ngOnInit(): void {
    try {
      this.advertiserLoginWithCookie();
      this.agencyLoginWithCookie();
    } catch (error) {
      this.advertiserService.logout(false);
      this.agencyService.logout(false);
      console.log("AHHHHHH");
      this.working = false;
    }
  }

  advertiserLoginWithCookie() {
    // Weiterleitung falls es einen vorhandenen Session Cookie gibt
    if (this.cookieService.get('streamboost-session')) {
      this.working = true;
      var token = this.cookieService.get('streamboost-session');
      this.advertiserService.userLoggedIn(token).then((data) => {
        if (this.advertiserService.advertiserHasRememberLogin()) {
          this.routingService.switchToPathWithReload('/advertiser/dashboard');
        } else {
          this.advertiserService.logout(false);
        }
        this.working = false;
      }).catch((error) => {
        // Tu nichts da eventuell ein Agency Cookie vorhanden ist
      })
    }
  };

  agencyLoginWithCookie() {
    // Weiterleitung falls es einen vorhandenen Session Cookie gibt
    if (this.cookieService.get('streamboost-session')) {
      this.working = true;
      var token = this.cookieService.get('streamboost-session');
      this.agencyService.userLoggedIn(token).then((data) => {
        if (this.agencyService.agencyHasRememberLogin()) {
          this.routingService.switchToPathWithReload('/agency/dashboard');
        } else {
          this.advertiserService.logout(false);
        }
        this.working = false;
      }).catch((error) => {
        throw new Error()
      })
    }
  };

  resetError() {
    this.loginError = '';
  }

  loginAdvertiser() {
    if (this.email === '' || !this.email) {
      this.loginError = 'email';
      return;
    } else if (this.password === '' || !this.password) {
      this.loginError = 'Password';
      return;
    }

    this.working = true;
    this.ipService.getIp().then(res => {

      this.advertiserService.authenticate(this.email, this.password, res.ip, this.checked).then((data) => {
        this.routingService.switchToPathWithReload('/advertiser/dashboard');
        this.working = false;
      })
        .catch((error) => {
          this.loginError = error;
          this.working = false;
        });

    }).catch((err) => {
      console.log(err);
      this.working = false;
    });
  }

  loginAgency() {
    if (this.email === '' || !this.email) {
      this.loginError = 'email';
      return;
    } else if (this.password === '' || !this.password) {
      this.loginError = 'Password';
      return;
    }

    this.working = true;
    this.ipService.getIp().then(res => {

      this.agencyService.authenticate(this.email, this.password, res.ip, this.checked).then((data) => {
        this.routingService.switchToPathWithReload('/agency/dashboard');
        this.working = false;
      })
        .catch((error) => {
          this.loginError = error;
          this.working = false;
        });

    }).catch((err) => {
      console.log(err);
      this.working = false;
    });
  }

}
