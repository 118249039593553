import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Keyword } from '../../model/streamer/keyword.model';
import { StreamerService } from '../../services/streamer.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';
import { KeywordService } from '../../services/keyword.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'streamer-campaigns',
  templateUrl: './streamer-campaigns.component.html',
  styleUrls: ['./streamer-campaigns.component.scss']
})
export class StreamerCampaignsComponent implements OnInit {

  private keywords: Keyword[] = [];
  public changeKeywords = false;
  public keywordsDropDownList: any[] = [];
  public selectedKeywordItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};

  public moreStatusInfoModal: boolean = false;

  constructor(public globalService: GlobalService, private streamerService: StreamerService,
    public keywordService: KeywordService, public i18nService: I18nService) {
  }

  ngOnInit(): void {

    // Keywords holen und füllen
    this.keywordService.getAllAdKeywords().then((keywords) => {
      this.keywords = keywords;
      this.updateKeywordDropDownLists();
    }).catch((err) => {
      console.log(err);
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  /**
   * Methoden Keywords
   */
  abortKeywordChanges() {
    this.selectedKeywordItems = [];
    this.changeKeywords = false;
  }

  changeKeyword() {
    if (this.globalService.streamer) {
      for (const keyword of this.globalService.streamer.adKeywords) {
        this.keywords.forEach((keywordIterator) => {
          if (keyword === keywordIterator.getValueInLanguage('word', this.i18nService.getCurrentLang())) {
            this.selectedKeywordItems.push(
              {
                // @ts-ignore
                item_id: keywordIterator.id,
                // @ts-ignore
                item_text: keywordIterator.getValueInLanguage('word', this.i18nService.getCurrentLang())
              });
          }
        });
      }
      this.changeKeywords = true;
    }
  }

  saveKeywordChanges() {
    this.streamerService.saveKeywordChangesForStreamer(this.selectedKeywordItems).then(() => {
      this.abortKeywordChanges();
    }).catch((err) => {
      console.log(err);
    });
  }

  updateKeywordDropDownLists() {
    this.keywordsDropDownList = [];
    this.keywords.forEach((keyword) => {
      this.keywordsDropDownList.push(
        {
          // @ts-ignore
          item_id: keyword.id,
          // @ts-ignore
          item_text: keyword.getValueInLanguage('word', this.i18nService.getCurrentLang())
        });
    });
  }
}
