<div class="headline" *ngIf="showHeadline">Zahlungsbestätigung</div>
<div class="p-col-12">
  <div class="p-grid abstand">
    <div class="p-col-12">
      <p-card>
        <p-progressSpinner *ngIf="!showOutcome"></p-progressSpinner>
        <div class="confirmation-container" *ngIf="showOutcome">
          <h1 *ngIf="success; else failureTemplate">Zahlung erfolgreich</h1>
          <ng-template #failureTemplate>
            <h1>Zahlung fehlgeschlagen</h1>
          </ng-template>
          <p *ngIf="success; else failureMessage">Vielen Dank für Ihre Zahlung. Ihre Kampagne wurde erfolgreich
            erstellt.</p>
          <ng-template #failureMessage>
            <p>Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
          </ng-template>
          <button (click)="switchToCampaigns()">Zurück zum Dashboard</button>
        </div>
      </p-card>
    </div>
  </div>
</div>
