import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {AdvertiserAffiliate} from '../../model/advertiser/advertiser-affiliate.model';
import {AffiliateService} from '../../services/affiliate.service';
import {PictureService} from '../../services/picture.service';
import {MathHelper} from '../../services/math.helper';
import {MenuItem} from 'primeng/api';
import {I18nService} from '../../services/i18n.service';
import {Subscription} from 'rxjs';
import {CampaignFeedback} from '../../model/advertiser/campaign-feedback.model';
import {FeedbackService} from '../../services/feedback.service';
import {CampaignQuestion} from "../../model/advertiser/campaign-question.model";
import {TableLazyLoadEvent} from 'primeng/table';

@Component({
  selector: 'advertiser-affiliate-details',
  templateUrl: './advertiser-affiliate-details.component.html' ,
  styleUrls: ['./advertiser-affiliate-details.component.scss']
})
export class AdvertiserAffiliateDetailsComponent implements OnInit {

  @Input()
  public affiliateId!: string;

  @Input()
  public numberOfStreamer!: number;

  @Output()
  public closeEmit = new EventEmitter<void>();

  public affiliate!:AdvertiserAffiliate;

  public activeTab:number = 1;

  public showDescriptionModal = false;

  public showPicUpload = false;
  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public picturesOk = true;
  public working = false;

  public budgetData: any;
  public clicksData: any;

  public burndownData: any;
  public burndownItems!: MenuItem[];
  public burndowns: any;
  public burndownOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  public burnupData: any;
  public burnupItems!: MenuItem[];
  public burnups: any;
  public burnupOptions = {
    scales: {
      y: {
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  // Tag Conversion Burnup
  public conversionBurnupData: any;
  public conversionBurnupItems!: MenuItem[];
  public conversionBurnups: any;
  public totalConversions: number = 0;

  //Heatmap
  public heatOptions?: any;
  public cwSelectItems!: MenuItem[];
  public currentVisibleCW: string = "ALL";

  //Klick Pies
  public deviceData: any;
  public browserData: any;

  // Zuschauer
  public genderData: any;
  public ageData: any;
  public chartOptions = {
    scales: {
      y: {
        max: 100,
        beginAtZero: true,
      },
    },
  };
  public countryData: any;

  // Spielekategorie Statistik
  public streamerGameData?: any;

  // Feedback Tab
  public feedbacks: CampaignFeedback[] = [];
  public totalFeedbacks!: number;
  public loadingFeedbacks!: boolean;

  public questions: CampaignQuestion[] = [];
  public totalQuestions!: number;
  public loadingQuestions!: boolean;

  public showFeedbackOrQuestionModal: boolean = false;
  public feedbackOrQuestionText?: string;
  public answerText?: string;
  public modalHeadline: string = "FRAGE";

  public showAnswerQuestionModal: boolean = false;
  public selectedQuestion?: string;
  public selectedAnswer?: string;
  public selectedForQuestionForAnswer?: CampaignQuestion;

  public langChangeSubscription!: Subscription;

  constructor(public globalService: GlobalService, public affiliateService: AffiliateService,
              public pictureService: PictureService, public mathHelper: MathHelper,
              public i18nService: I18nService, public feedbackService: FeedbackService) {

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      if (this.globalService.advertiser || this.globalService.agency) {
        this.updateBudgetBurnCharts();
        this.updateBurnCharts().then(() => {
          this.updateHeatmapCWSelector();
          this.updateClickTimeHeatmap();
        });
        this.updateConversionBurnCharts();
        this.updateDemographicCharts();
        this.updateGameCategoryStreamChart();
      }
    });
  }

  ngOnInit(): void {
    if (this.globalService.advertiser || this.globalService.agency) {
      this.updateAffiliate();
      this.initializeFeedbackOrQuestions();
    }
  }

  back() {
    this.closeEmit.emit()
  }

  updateAffiliate() {
    this.affiliateService.getAffiliate(this.affiliateId).then((result) => {
      this.affiliate = result;
      this.updateClickDataChart();
      this.updateDailyClicksDataChart();
      this.updateBudgetBurnCharts();
      this.updateBurnCharts().then(() => {
        this.updateHeatmapCWSelector();
        this.updateClickTimeHeatmap();
      });
      this.updateConversionBurnCharts();
      this.updateClickUseragentCharts();
      this.updateDemographicCharts()
      this.updateGameCategoryStreamChart();
    });
  }

  deletePicture(pic: any) {
    let picId = pic.substring(9, pic.length);
    this.pictureService.deleteAffiliateBanner(picId, this.affiliateId).then(() => {
      this.updateAffiliate();
    });
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.pictureService.checkAffiliateBanner(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }

  uploadPic() {
    this.working = true;
    this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, this.affiliateId, 'AFFILIATE_BANNER', 'affiliate')
      .then(() => {
        setTimeout(() => {
          this.showPicUpload=false;
          this.working = false;
          this.updateAffiliate();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
        this.working = false;
      });
  }

  updateClickDataChart() {
    this.budgetData = {};

    if (this.affiliate.budget) {
      this.budgetData = {
        labels: [
          this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Rest + ' (€)',
          this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Used + ' (€)'
        ],
        datasets: [
          {
            data: [
              this.affiliate.budget,
              this.mathHelper.precisionRound(this.affiliate.initialBudget - this.affiliate.budget, 2)
            ],
            backgroundColor: [
              '#393939',
              '#3faff7'
            ],
            hoverBackgroundColor: [
              '#393939',
              '#3faff7'
            ]
          }]
      };
    }
  }

  updateDailyClicksDataChart() {
    this.clicksData = {};

    if (this.affiliate.dailyClicks) {

      this.clicksData = {
        labels: [
          this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Rest,
          this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Used
        ],
        datasets: [
          {
            data: [
              this.affiliate.currentClicks,
              this.affiliate.clicks
            ],
            backgroundColor: [
              '#393939',
              '#3faff7'
            ],
            hoverBackgroundColor: [
              '#393939',
              '#3faff7'
            ]
          }]
      };

    }
  }

  updateBurnCharts() : Promise<any> {
    return new Promise((resolve, reject) => {
      this.affiliateService.getClickBurnupOfAffiliate(this.affiliateId).then(result => {
        this.burnups = result;
        this.updateBurnupMenu();
        this.burnupData = this.burnups.days_burnup;

        resolve('');
      });
    });
  }

  updateBudgetBurnCharts() {
    if (this.affiliate.type === 'INTERNAL') {
      this.affiliateService.getBudgetBurndownOfAffiliate(this.affiliateId).then(result => {
        this.burndowns = result;
        this.updateBurndownMenu();

        this.burndownData = this.burndowns.days_burndown;
      });
    } else {
      this.burndownData = null;
      this.burndownItems = [];
    }
  }

  updateConversionBurnCharts() {
    if (this.affiliate.type === 'INTERNAL') {
      this.affiliateService.getConversionBurnupOfAffiliate(this.affiliateId).then(result => {
        this.conversionBurnups = result;
        this.totalConversions = this.conversionBurnups.total_conversions;
        this.updateConversionBurnupMenu();

        this.conversionBurnupData = this.conversionBurnups.days_burnup;
      });
    } else {
      this.conversionBurnupData = null;
      this.conversionBurnupItems = [];
    }
  }

  updateBurndownMenu() {
    this.burndownItems = [];

    if (this.burndowns.days_burndown) {
      this.burndownItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Day,
        command: () => {
          this.burndownData = this.burndowns.days_burndown;
        }
      });
    }

    if (this.burndowns.cw_burndown) {
      this.burndownItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Week,
        command: () => {
          this.burndownData = this.burndowns.cw_burndown;
        }
      });
    }

    if (this.burndowns.month_burndown) {
      this.burndownItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Month,
        command: () => {
          this.burndownData = this.burndowns.month_burndown;
        }
      });
    }
  }

  updateBurnupMenu() {
    this.burnupItems = [];

    if (this.burnups.days_burnup) {
      this.burnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Day,
        command: () => {
          this.burnupData = this.burnups.days_burnup;
        }
      });
    }

    if (this.burnups.cw_burnup) {
      this.burnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Week,
        command: () => {
          this.burnupData = this.burnups.cw_burnup;
        }
      });
    }

    if (this.burnups.month_burnup) {
      this.burnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Month,
        command: () => {
          this.burnupData = this.burnups.month_burnup;
        }
      });
    }
  }

  updateConversionBurnupMenu() {
    this.conversionBurnupItems = [];

    if (this.conversionBurnups.days_burnup) {
      this.conversionBurnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Day,
        command: () => {
          this.conversionBurnupData = this.conversionBurnups.days_burnup;
        }
      });
    }

    if (this.conversionBurnups.cw_burnup) {
      this.conversionBurnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Week,
        command: () => {
          this.conversionBurnupData = this.conversionBurnups.cw_burnup;
        }
      });
    }

    if (this.conversionBurnups.month_burnup) {
      this.conversionBurnupItems.push({
        label: this.i18nService.localize().AdvertiserAffiliateDetailsComponent.Month,
        command: () => {
          this.conversionBurnupData = this.conversionBurnups.month_burnup;
        }
      });
    }
  }

  /**
   * Heatmap Methoden
   */
  updateClickTimeHeatmap() {
    this.affiliateService.getClickTimeHeatmapOfAffiliate(this.affiliateId, this.currentVisibleCW).then((result) => {
      // this.heatOptions = result;

      if (result.series) {
        this.heatOptions = {
          series: result.series,
          chart: {
            height: 500,
            type: "heatmap"
          },
          dataLabels: {
            enabled: true
          },
          colors: ["#155ea7"],
          title: {
            text: ""
          },
          // plotOptions: {
          //   heatmap: {
          //     distributed: true,
              // colorScale: {
              //   ranges: [
              //     {
              //       from: 0,
              //       to: 20,
              //       color: '#3faff7',
              //       name: 'wenig',
              //     },
              //     {
              //       from: 21,
              //       to: 50,
              //       color: '#155ea7',
              //       name: 'medium',
              //     },
              //     {
              //       from: 51,
              //       to: 100,
              //       color: '#ffcc4c',
              //       name: 'hoch',
              //     },
              //     {
              //       from: 101,
              //       to: 5000,
              //       color: '#ff7272',
              //       name: 'sehr hoch',
              //     }
              //   ]
              // }
          //   }
          // }
        };
      } else {
        delete this.heatOptions;
      }

    });
  }

  updateHeatmapCWSelector() {
    this.cwSelectItems = [];

    this.cwSelectItems.push({
      label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.SelectAll,
      command: () => {
        this.currentVisibleCW = 'ALL';
        this.updateClickTimeHeatmap();
      },
    });

    if (this.burnups.cw_burnup) {

      let labelsArray: string[] = this.burnups.cw_burnup.labels;
      if (labelsArray.length < 12) {
        for (let i = 1; i < labelsArray.length; i++) {
          this.cwSelectItems.push({
            label: labelsArray[i],
            command: () => {
              this.currentVisibleCW = labelsArray[i];
              this.updateClickTimeHeatmap();
            },
          });
        }
      } else {
        console.log('Too many CWs for Heatmap, sorry');
      }

    }
  }

  updateClickUseragentCharts() {
    this.affiliateService.getClickUseragentChartsOfAffiliate(this.affiliateId).then((result) => {
      // console.log(result);

      if (result.percent_desktop) {
        this.deviceData = {
          labels: [
            'Desktop (%)',
            'Mobile (%)'
          ],
          datasets: [
            {
              data: [
                result.percent_desktop,
                result.percent_mobile
              ],
              backgroundColor: ['#3faff7', '#1d60a4'],
              hoverBackgroundColor: ['#3faff7', '#1d60a4'],
            },
          ],
        };
      }

      if (result.browser_labels) {
        this.browserData = {
          labels: result.browser_labels,
          datasets: [
            {
              data: result.browser_data,
              backgroundColor: result.browser_colors,
              hoverBackgroundColor: result.browser_colors,
            },
          ],
        };
      }

    });
  }

  updateDemographicCharts() {
    this.genderData = {};
    this.ageData = {};
    this.countryData = {};

    this.affiliateService
      .getDemographicForAffiliate(this.affiliate.id)
      .then((result) => {
        if (result) {
          this.affiliate.demographic = result;

          if (this.affiliate.demographic.hasGenderInfos()) {
            this.genderData = {
              labels: [
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Male,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Female,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Divers,
              ],
              datasets: [
                {
                  data: [
                    this.affiliate.demographic.male,
                    this.affiliate.demographic.female,
                    this.affiliate.demographic.diverse,
                  ],
                  backgroundColor: ['#3faff7', '#ff80bf', '#ffff00'],
                  hoverBackgroundColor: ['#3faff7', '#ff80bf', '#ffff00'],
                },
              ],
            };
          }

          if (this.affiliate.demographic.hasAgeInfos()) {
            this.ageData = {
              labels: [
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .AgeUnder18,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age18To24,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age25To34,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age35To44,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .AgeOver44,
              ],
              datasets: [
                {
                  label:
                  this.i18nService.localize()
                    .AdvertiserCampaignDetailsComponent.Age,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                  data: [
                    this.affiliate.demographic.under18,
                    this.affiliate.demographic.from18to24,
                    this.affiliate.demographic.from25to34,
                    this.affiliate.demographic.from35to44,
                    this.affiliate.demographic.over44,
                  ],
                },
              ],
            };
          }

          if (this.affiliate.demographic.hasCountries()) {
            this.countryData = {
              labels: this.affiliate.demographic.getCountryNames(),
              datasets: [
                {
                  label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.Countries,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                  data: this.affiliate.demographic.getCountryValues(),
                },
              ],
            };
          }
        }
      });
  }

  updateGameCategoryStreamChart() {
    delete this.streamerGameData;

    this.affiliateService
      .getGameCategoryStreamerStatisticForAffiliate(this.affiliate.id)
      .then((result) => {
        if (result.game_keywords.labels.length > 0) {
          this.streamerGameData = {
            labels: result.game_keywords.labels,
            datasets: [
              {
                label: 'Streamer',
                data: result.game_keywords.data,
                backgroundColor: '#1d60a4',
                borderColor: '#1d60a4',
              },
            ],
          };
        }
      });
  }

  // Feedback oder Fragen Tab
  initializeFeedbackOrQuestions() {
    this.feedbacks = [];

    this.initializeFeedbacks();
    this.initializeQuestions();
  }

  initializeFeedbacks() {
    this.loadingFeedbacks = true;
    this.feedbackService.getFeedbackOfAffiliate(0, 20,'question_date', -1, this.affiliateId).then((result) => {
      this.feedbacks = result;

      this.feedbackService.getMaxFeedbackOfAffiliate(this.affiliateId).then((max) => {
        this.totalFeedbacks = max;
        this.loadingFeedbacks = false;
      });

    });
  }

  updateFeedbacks(event: TableLazyLoadEvent) {
    this.loadingFeedbacks = true;
    if (event.first != null && event.rows != null && event.sortField != null && event.sortOrder != null) {
      this.feedbackService.getFeedbackOfAffiliate(event.first, event.rows, event.sortField.toString(), event.sortOrder, this.affiliateId).then((result) => {
        this.feedbacks = result;

        this.feedbackService.getMaxFeedbackOfAffiliate(this.affiliateId).then((max) => {
          this.totalFeedbacks = max;
          this.loadingFeedbacks = false;
        });
      });
    }
  }

  initializeQuestions() {
    this.loadingQuestions = true;
    this.feedbackService.getQuestionsOfAffiliate(0, 20,'question_date', -1, this.affiliateId).then((result) => {
      this.questions = result;

      this.feedbackService.getMaxQuestionsOfAffiliate(this.affiliateId).then((max) => {
        this.totalQuestions = max;
        this.loadingQuestions = false;
      });

    });
  }

  updateQuestions(event: TableLazyLoadEvent) {
    this.loadingQuestions = true;
    if (event.first != null && event.rows != null && event.sortField != null && event.sortOrder != null) {
      this.feedbackService.getQuestionsOfAffiliate(event.first, event.rows, event.sortField.toString(), event.sortOrder, this.affiliateId).then((result) => {
        this.questions = result;

        this.feedbackService.getMaxQuestionsOfAffiliate(this.affiliateId).then((max) => {
          this.totalQuestions = max;
          this.loadingQuestions = false;
        });
      });
    }

  }

  getDefaultSortOrder() :number {
    return -1;
  }

  showText(feedbackOrQuestion: any, type: string) {
    delete this.feedbackOrQuestionText;
    delete this.answerText;
    this.feedbackOrQuestionText = feedbackOrQuestion.message;
    if (feedbackOrQuestion.answer) {
      this.answerText = feedbackOrQuestion.answer;
    }

    let currentLang = this.i18nService.getCurrentLang();
    if (type === 'QUESTION') {
      if (currentLang === 'DE') {
        this.modalHeadline = 'FRAGE';
      } else {
        this.modalHeadline = 'QUESTION';
      }
    } else {
      this.modalHeadline = 'FEEDBACK';
    }

    this.showFeedbackOrQuestionModal = true;
  }

  showAnswerQuestion(question: CampaignQuestion) {
    delete this.selectedAnswer;
    delete this.selectedQuestion;
    delete this.selectedForQuestionForAnswer;
    this.selectedQuestion = question.message;
    this.selectedForQuestionForAnswer = question;

    this.showAnswerQuestionModal = true;
  }

  answerQuestion() {
    if (this.selectedAnswer && this.selectedForQuestionForAnswer) {
      this.feedbackService.answerAffiliateStreamerQuestion(this.affiliateId, this.selectedForQuestionForAnswer.id, this.selectedAnswer).then(result => {
        this.initializeQuestions();
        this.showAnswerQuestionModal = false;
      });
    }
  }

}
