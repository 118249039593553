import {Injectable} from '@angular/core';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {DisplayClickBill} from '../model/bill.model';

@Injectable()
export class BillService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {

    super(streamBoostEventBus);
  }

  public getBillsOfAdvertiser(first?: number, rows?: number, sortField?: string,
                                      sortOrder?: number, advertiserId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.bills',
        {
          message: {
            first,
            rows,
            sort_field: sortField,
            sort_order: sortOrder,
            advertiser_id: advertiserId
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }
            const billsFromDB = response.body.response.displayclickcampaignbills;

            let i: number;
            const bills: DisplayClickBill[] = [];

            for (i = 0; i < billsFromDB.length; i++) {
              const bill = new DisplayClickBill();

              bill.id = billsFromDB[i]._id;
              bill.billNr = billsFromDB[i].bill_nr;
              bill.sum = billsFromDB[i].sum;
              bill.billDate = billsFromDB[i].bill_date;
              bill.campaignName = billsFromDB[i].name;

              bills.push(bill);
            }

            resolve(bills);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

  public getMaxBillsOfAdvertiser(advertiserId?: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send(
        'stream.web.facade.advertiser.bills.max',
        {
          message: {
            advertiser_id: advertiserId
          }
        })
        .then((response) => {
          {
            if (response.body.code) {
              reject(response.body.code);
              return;
            }
            resolve(response.body.response.max_count);

          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    );
  }

}
