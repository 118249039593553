export class CampaignQuestion {

  public id!: string;

  public message!: string;

  public answer!: string;

  public questionDate!: string;

  public answerDate!: string;

  constructor() {
  }

  getMessageExcerpt(): string {
    if (this.message) {
      if (this.message.length > 100) {
        return this.message.substring(0, 97)+'...';
      } else {
        return this.message;
      }
    } else {
      return 'N/A';
    }
  }

  isMessageOverlyLong(): boolean {
    return this.message.length > 100;
  }
}
