export class SimpleDemographicCountry {

  public name!: string;

  public count!: number;

  public share!: number;

  constructor(){
  }

}
