import * as moment from "moment";

export class StreamerCampaign {

  public id!: string;

  public name!: string;
  public displayName!: string;

  public picUrl! : string;

  public startDate!: string;
  public startDateAsDate!: Date;
  public endDate!: string;
  public startTime!: string;
  public endTime!: string;

  public progress!: number;

  public description!: string;

  public paused: boolean = false;

  public pausedType!: string;

  public dailyViews!: number;

  public counted_daily_views!: number;

  public views: number = 0;

  public status!: string;

  public gameKeywords: string[] = [];

  public ignoredGameKeywords: string[] = [];

  constructor() {}

  public isStartDateInFuture() : boolean {
    if (this.startDate) {
      return this.startDateAsDate > new Date();
    } else {
      return false;
    }
  }

  public getDaysToStart() : number {
    if (this.startDate) {
      let start = moment(this.startDateAsDate);
      let today = moment(new Date);

      return start.diff(today, 'days');
    } else {
      return 0;
    }
  }

}
