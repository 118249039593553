import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalService} from '../../services/global.service';
import {I18nService} from '../../services/i18n.service';
import {StreamerService} from '../../services/streamer.service';
import * as moment from 'moment';
import {IDropdownSettings} from 'ng-multiselect-dropdown3';
import {Country} from '../../model/streamer/country.model';
import {KeywordService} from '../../services/keyword.service';
import {SocialProfile} from '../../model/streamer/social-profile.model';
import {SocialService} from '../../services/social.service';


@Component({
  selector: 'streamer-home',
  templateUrl: './streamer-home.component.html',
  styleUrls: ['./streamer-home.component.scss']
})
export class StreamerHomeComponent implements OnInit {

  // Profil Änderungen
  public changeProfile = false;
  public showNameDialog = false;
  public inputFirstname!: string;
  public inputLastname!: string;
  public inputBirthday!: Date;
  public nameDialogError!: string;
  public inputEmail!: string;
  public emailChanged = false;
  public emailDialogError!: string;
  public changeEmail = false;
  public disableInputFirstname = false;
  public disableInputLastname = false;
  public wrongEmail = false;
  public genderOptions: any[];
  public inputGender!: string;

  // Geburtstagsconfig
  public minDate!: Date;
  public maxDate!: Date;

  // Adressänderungen
  public changeAddress = false;
  public streetEdit!: string;
  public nrEdit!: string;
  public postalCodeEdit!: string;
  public cityEdit!: string;
  public addressDialogError!: string;

  // Zahlungsinformationen ändern
  public changePayment = false;
  public paypalEdit!: string;
  public taxEdit!: string;
  public paymentDialogError!: string;
  public isEmailValid: boolean = false;

  // Zuschauerinformationen ändern
  // Geschlecht
  public changeGenderDistributionFlag = false;
  public genderData: any;
  public tmpPercGenderMen: number = 0;
  public tmpPercGenderWomen: number = 0;
  public tmpPercGenderDivers: number = 0;
  public selectedGenderValues: string[] = [];

  // Alter
  public changeAgeDistributionFlag = false;
  public ageData: any;
  public ageOptions: any;
  public tmpPercLessThen18: number = 0;
  public tmpPerc18to24: number = 0;
  public tmpPerc25to34: number = 0;
  public tmpPerc35to44: number = 0;
  public tmpPercOlderThen44: number = 0;
  public selectedAgeValues: string[] = [];

  // Länder
  private countries: Country[] = [];
  public changeCountries = false;
  public countriesDropDownList: any[] = [];
  public selectedCountryItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};

  // Status Upgrade Request
  public showReasonModal: boolean = false;
  public moreStatusInfoModal: boolean = false;

  // Soziale Profile
  public showRemoveConfirmModal: boolean = false;
  public selectedProfile?: SocialProfile;
  public addProfileError?: string;

  //View-Statistik
  public activeTab: number = 1;
  public allViewsLineData: any;
  public avgViewsLineData: any;
  public streamTimeLineData: any;
  public streamGameLineData: any;
  public chartData: any;
  public options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public streamerService: StreamerService, public keywordService: KeywordService,
              private socialService: SocialService, public route: ActivatedRoute) {

    this.genderOptions = [
      {label: this.i18nService.localize().StreamerRegisterComponent.Male, value: 'male'},
      {label: this.i18nService.localize().StreamerRegisterComponent.Female, value: 'female'},
      {label: this.i18nService.localize().StreamerRegisterComponent.Divers, value: 'divers'}
    ];

    if (this.globalService.streamer) {
      this.setEditFields();
      this.initGenderDistribution();
      this.initAgeDistribution();
    }

  }

  ngOnInit() {
    this.tmpPercLessThen18 = 0;
    this.tmpPerc18to24 = 0;
    this.tmpPerc25to34 = 0;
    this.tmpPerc35to44 = 0;
    this.tmpPercOlderThen44 = 0;

    this.tmpPercGenderMen = 0;
    this.tmpPercGenderWomen = 0;
    this.tmpPercGenderDivers = 0;

    this.addProfileError = this.route.snapshot.params.error;

    if (this.globalService.streamer) {
      this.setEditFields();
      this.initGenderDistribution();
      this.initAgeDistribution();
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    // Länder holen und füllen
    this.keywordService.getAllCountries().then((countries) => {
      this.countries = countries;
      this.updateCountryDropDownLists();
    }).catch((err) => {
      console.log(err);
    });

    this.setStreamingTable()

    this.setAllViewsStatistics();
    this.setAverageViewsStatistics();
    this.setStreamingTimeStatistics();
  }

  setEditFields() {
    if (this.globalService.streamer) {
      this.inputFirstname = this.globalService.streamer.firstname;
      this.inputLastname = this.globalService.streamer.lastname;
      this.inputEmail = this.globalService.streamer.email;

      if (this.globalService.streamer.birthday) {
        const momentDate = moment(this.globalService.streamer.birthday, 'dd.MM.yyyy');
        this.inputBirthday = momentDate.toDate();
      }

      this.cityEdit = this.globalService.streamer.city;
      this.nrEdit = this.globalService.streamer.nr;
      this.streetEdit = this.globalService.streamer.street;
      this.postalCodeEdit = this.globalService.streamer.postal_code;

      if (this.globalService.streamer.gender) {
        this.inputGender = this.globalService.streamer.gender;
      }

      this.paypalEdit = this.globalService.streamer.paypal;
      this.taxEdit = this.globalService.streamer.taxNr;
    }

    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 90);
    this.maxDate = new Date();
  }

  /**
   * Profil Änderungen
   */
  changeProfileInfo() {
    this.disableInputFirstname = false;
    this.disableInputLastname = false;
    this.setEditFields();

    this.changeProfile = true;
  }

  changeEmailAddress() {
    this.changeEmail = true;
    this.emailDialogError = '';
  }

  saveProfileChanges() {
    this.streamerService.setNameGenderAndBirthday(this.inputFirstname, this.inputLastname,
      this.inputBirthday, this.inputGender).then(() => {
      this.changeProfile = false;
      this.nameDialogError = '';
    }).catch((err) => {
      this.nameDialogError = err;
    });
  }

  abortProfileChanges() {
    this.changeProfile = false;
    this.nameDialogError = '';
  }

  saveEmailChange() {
    this.wrongEmail = false;
    if (this.globalService.isEmailOk(this.inputEmail)) {
      this.streamerService.changeEmail(this.inputEmail).then(() => {
        this.emailChanged = true;
        this.emailDialogError = '';
        this.changeEmail = false;
      }).catch((err) => {
        this.emailDialogError = err;
      });
    } else {
      this.wrongEmail = true;
    }
  }

  /**
   * Adressänderungen
   */
  abortEmailChange() {
    this.changeEmail = false;
    this.emailDialogError = '';
  }

  changeAddressInfo() {
    this.changeAddress = true;
    this.setEditFields();
  }

  abortAddressChanges() {
    this.changeAddress = false;
    this.addressDialogError = '';
  }

  saveAddressChanges() {
    this.streamerService.setAddress(this.streetEdit, this.nrEdit, this.cityEdit, this.postalCodeEdit).then(() => {
      this.changeAddress = false;
      this.addressDialogError = '';
    }).catch((err) => {
      this.addressDialogError = err;
      console.log(err);
    });
  }

  /**
   * Zahlungsinformationen ändern
   */
  changePaymentInfo() {
    this.changePayment = true;
  }

  savePaymentChanges() {
    this.streamerService.setPayment(this.paypalEdit, this.taxEdit).then(() => {
      this.changePayment = false;
      this.paymentDialogError = '';
    }).catch((err) => {
      this.paymentDialogError = err;
      // console.log(err);
    });
  }

  abortPaymentChanges() {
    this.changePayment = false;
    this.paymentDialogError = '';
  }

  /**
   * Tier Upgrade
   */
  requestTierUpgrade() {
    if (this.globalService.streamer) {
      if (this.globalService.streamer.tier < 3) {
        this.streamerService.requestTierUpgrade().then(() => {

        }).catch((err) => {
          console.log(err);
        });
      }
    }
  }


  /**
   * Methoden Geschlecht
   */
  initGenderDistribution() {
    if (this.globalService.streamer) {
      this.tmpPercGenderMen = this.globalService.streamer.demographyInfo.male;
      this.tmpPercGenderWomen = this.globalService.streamer.demographyInfo.female;
      this.tmpPercGenderDivers = this.globalService.streamer.demographyInfo.divers;
      this.updateGenderChart();
    }

  }

  updateGenderChart() {
    if (this.globalService.streamer) {
      this.genderData = {};

      this.genderData = {
        labels: [this.i18nService.localize().StreamerHomeComponent.GenderMale, this.i18nService.localize().StreamerHomeComponent.GenderFemale, this.i18nService.localize().StreamerHomeComponent.GenderDivers],
        datasets: [
          {
            data: [this.globalService.streamer.demographyInfo.male, this.globalService.streamer.demographyInfo.female, this.globalService.streamer.demographyInfo.divers],
            backgroundColor: [
              '#3faff7',
              '#ff80bf',
              '#ffff00'
            ],
            hoverBackgroundColor: [
              '#3faff7',
              '#ff80bf',
              '#ffff00'
            ]
          }]
      };
    }
  }

  changeGenderDistribution() {
    this.changeGenderDistributionFlag = true;
    this.selectedGenderValues = [];
  }

  saveGenderDistributionChanges() {
    this.streamerService.saveGenderDistributionForStreamer(this.tmpPercGenderMen,
      this.tmpPercGenderWomen, this.tmpPercGenderDivers).then(() => {
      this.initGenderDistribution();
      this.changeGenderDistributionFlag = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  abortGenderDistributionChanges() {
    this.initGenderDistribution();
    this.changeGenderDistributionFlag = false;
  }

  updateGenderSlider(currentSliderName: string) {
    if (this.tmpPercGenderWomen + this.tmpPercGenderMen + this.tmpPercGenderDivers > 100) {
      const sliderToTurnDown = this.getHighestGenderSlider(currentSliderName);
      // @ts-ignore
      if (this[sliderToTurnDown] > 0) {
        // @ts-ignore
        this[sliderToTurnDown] = this[sliderToTurnDown] - 1;
      }
    } else {
      const sliderToTurnUp = this.getLowestGenderSlider(currentSliderName);
      // @ts-ignore
      if (this[sliderToTurnUp] < 100) {
        // @ts-ignore
        this[sliderToTurnUp] = this[sliderToTurnUp] + 1;
      }
    }
  }

  getHighestGenderSlider(currentSliderName: string): any {
    const genderSliderMap = this.createGenderMap();
    const sortedGenderMap = new Map([...genderSliderMap.entries()].sort((a, b) => b[1] - a[1]));

    for (const [key] of sortedGenderMap) {
      if (key !== currentSliderName) {
        return key;
      }
    }
  }

  getLowestGenderSlider(currentSliderName: string): any {
    const genderSliderMap = this.createGenderMap();
    const sortedGenderMap = new Map([...genderSliderMap.entries()].sort((a, b) => a[1] - b[1]));

    for (const [key] of sortedGenderMap) {
      if (key !== currentSliderName) {
        return key;
      }
    }
  }

  createGenderMap(): Map<string, number> {
    const genderSliderMap = new Map();

    if (!this.selectedGenderValues.includes('tmpPercGenderDivers')) {
      genderSliderMap.set('tmpPercGenderDivers', this.tmpPercGenderDivers);
    }

    if (!this.selectedGenderValues.includes('tmpPercGenderMen')) {
      genderSliderMap.set('tmpPercGenderMen', this.tmpPercGenderMen);
    }

    if (!this.selectedGenderValues.includes('tmpPercGenderWomen')) {
      genderSliderMap.set('tmpPercGenderWomen', this.tmpPercGenderWomen);
    }

    return genderSliderMap;
  }

  /*
   * Methoden Alter
   */
  initAgeDistribution() {
    if (this.globalService.streamer) {
      this.tmpPercLessThen18 = this.globalService.streamer.demographyInfo.percLessThen18;
      this.tmpPerc18to24 = this.globalService.streamer.demographyInfo.perc18to24;
      this.tmpPerc25to34 = this.globalService.streamer.demographyInfo.perc25to34;
      this.tmpPerc35to44 = this.globalService.streamer.demographyInfo.perc35to44;
      this.tmpPercOlderThen44 = this.globalService.streamer.demographyInfo.percOlderThen44;

      this.updateAgeChart();
    }
  }

  updateAgeChart() {
    if (this.globalService.streamer) {
      this.ageOptions = {
        indexAxis: 'y',
        scales: {
          x: {
            max: 100,
            beginAtZero: true
          }
        }
      };

      this.ageData = {
        labels: [this.i18nService.localize().StreamerHomeComponent.AgeUnder18, this.i18nService.localize().StreamerHomeComponent.Age18To24,
          this.i18nService.localize().StreamerHomeComponent.Age25To34, this.i18nService.localize().StreamerHomeComponent.Age35To44,
          this.i18nService.localize().StreamerHomeComponent.AgeOver44],
        datasets: [
          {
            label: 'Alter (%)',
            backgroundColor: '#1d60a4',
            borderColor: '#1d60a4',
            data: [
              this.globalService.streamer.demographyInfo.percLessThen18,
              this.globalService.streamer.demographyInfo.perc18to24,
              this.globalService.streamer.demographyInfo.perc25to34,
              this.globalService.streamer.demographyInfo.perc35to44,
              this.globalService.streamer.demographyInfo.percOlderThen44
            ]
          }]
      };
    }
  }

  changeAgeDistribution() {
    this.changeAgeDistributionFlag = true;
  }

  saveAgeDistributionChanges() {
    this.streamerService.saveAgeDistributionForStreamer(this.tmpPercLessThen18,
      this.tmpPerc18to24, this.tmpPerc25to34, this.tmpPerc35to44, this.tmpPercOlderThen44).then(() => {
      this.changeAgeDistributionFlag = false;
      this.initAgeDistribution();
    }).catch((err) => {
      console.log(err);
    });
  }

  abortAgeDistributionChanges() {
    this.initAgeDistribution();
    this.changeAgeDistributionFlag = false;
  }

  updateAgeSlider(currentSliderName: string) {
    if (this.tmpPercLessThen18 + this.tmpPerc18to24 + this.tmpPerc25to34 + this.tmpPerc35to44 + this.tmpPercOlderThen44 > 100) {
      const sliderToTurnDown = this.getHighestAgeSlider(currentSliderName);
      // @ts-ignore
      if (this[sliderToTurnDown] > 0) {
        // @ts-ignore
        this[sliderToTurnDown] = this[sliderToTurnDown] - 1;
      }
    } else {
      const sliderToTurnUp = this.getLowestAgeSlider(currentSliderName);
      // @ts-ignore
      if (this[sliderToTurnUp] < 100) {
        // @ts-ignore
        this[sliderToTurnUp] = this[sliderToTurnUp] + 1;
      }
    }
  }

  getHighestAgeSlider(currentSliderName: string): any {
    const ageSliderMap = this.createAgeMap();
    const sortedAgeMap = new Map([...ageSliderMap.entries()].sort((a, b) => b[1] - a[1]));

    for (const [key] of sortedAgeMap) {
      if (key !== currentSliderName) {
        return key;
      }
    }
  }

  getLowestAgeSlider(currentSliderName: string): any {
    const ageSliderMap = this.createAgeMap();
    const sortedAgeMap = new Map([...ageSliderMap.entries()].sort((a, b) => a[1] - b[1]));

    for (const [key] of sortedAgeMap) {
      if (key !== currentSliderName) {
        return key;
      }
    }
  }

  createAgeMap(): Map<string, number> {
    const ageSliderMap = new Map();

    if (!this.selectedAgeValues.includes('tmpPercLessThen18')) {
      ageSliderMap.set('tmpPercLessThen18', this.tmpPercLessThen18);
    }

    if (!this.selectedAgeValues.includes('tmpPerc18to24')) {
      ageSliderMap.set('tmpPerc18to24', this.tmpPerc18to24);
    }

    if (!this.selectedAgeValues.includes('tmpPerc25to34')) {
      ageSliderMap.set('tmpPerc25to34', this.tmpPerc25to34);
    }

    if (!this.selectedAgeValues.includes('tmpPerc35to44')) {
      ageSliderMap.set('tmpPerc35to44', this.tmpPerc35to44);
    }

    if (!this.selectedAgeValues.includes('tmpPercOlderThen44')) {
      ageSliderMap.set('tmpPercOlderThen44', this.tmpPercOlderThen44);
    }

    return ageSliderMap;
  }

  /**
   * Methoden Countries
   */
  updateCountryDropDownLists() {
    this.countriesDropDownList = [];
    this.countries.forEach((country) => {
      this.countriesDropDownList.push(
        {
          // @ts-ignore
          item_id: country.id,
          // @ts-ignore
          item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
        });
    });
  }

  abortCountryChanges() {
    this.selectedCountryItems = [];
    this.changeCountries = false;
  }

  changeCountry() {
    if (this.globalService.streamer) {
      for (const country of this.globalService.streamer.demographyInfo.countries) {
        this.countries.forEach((countryIterator) => {
          if (country === countryIterator.getValueInLanguage('name', this.i18nService.getCurrentLang())) {
            this.selectedCountryItems.push({
              // @ts-ignore
              item_id: countryIterator.id,
              // @ts-ignore
              item_text: countryIterator.getValueInLanguage('name', this.i18nService.getCurrentLang())
            });
          }
        });
      }
      this.changeCountries = true;
    }
  }

  saveCountryChanges() {
    this.streamerService.saveCountryChangesForStreamer(this.selectedCountryItems).then(() => {
      this.abortCountryChanges();
    }).catch((err) => {
      console.log(err);
    });
  }

  /**
   * Status Upgrade Request
   */
  showReasonModalDialog() {
    this.showReasonModal = true;
  }

  showInfoModalDialog() {
    this.moreStatusInfoModal = true;
  }

  /**
   * Verbindungen
   */
  openRemoveConfirmationDialog(profile: SocialProfile) {
    this.selectedProfile = profile;
    this.showRemoveConfirmModal = true;
  }

  removeConnection() {
    if (this.selectedProfile) {
      this.streamerService.removeSocialConnection(this.selectedProfile).then(result => {
        delete this.selectedProfile;
        this.showRemoveConfirmModal = false;
      });
    }
  }

  getTheOtherNetwork(): string {
    if (this.globalService.streamer) {
      if (this.globalService.streamer.socialProfiles.length === 1) {
        let connectedNetwork: SocialProfile = this.globalService.streamer.socialProfiles[0];

        if (connectedNetwork.network === 'Twitch') {
          return 'Google';
        } else {
          return 'Twitch';
        }
      } else {
        return 'Nothing';
      }
    } else {
      return 'Nothing';
    }
  }

  loginWithTwitch() {
    if (this.globalService.streamer) {
      this.socialService.getTwitchLoginURL(false, this.globalService.streamer.id)
        .then((data) => {
          window.location.href = data.response.url;
        })
        .catch((error) => {
        });
    }
  }

  loginWithGoogle() {
    if (this.globalService.streamer) {
      this.socialService.getGoogleLoginURL(false, this.globalService.streamer.id)
        .then((data) => {
          window.location.href = data.response.url;
        })
        .catch((error) => {
        });
    }
  }

  setAllViewsStatistics() {
    this.streamerService.getAllViewsChart().then((data) => {
      this.allViewsLineData = data;
      this.chartData = this.allViewsLineData;
    }).catch((error) => {
    });
  }

  setAverageViewsStatistics() {
    this.streamerService.getAverageViewsChart().then((data) => {
      this.avgViewsLineData = data;
    }).catch((error) => {
    });
  }

  setStreamingTimeStatistics() {
    this.streamerService.getStreamerTimeChart().then((data) => {
      console.log(data)
      this.streamTimeLineData = data;
    }).catch((error) => {
    });
  }

  setStreamingTable() {
    this.streamerService.getStreamingTable().then((data) => {
      this.streamGameLineData = data;
    }).catch((error) => {
    });
  }

  activateTab(tabNumber: number) {
    this.activeTab = tabNumber;

    if (tabNumber === 1) {
      this.chartData = this.allViewsLineData;
    } else if (tabNumber === 2) {
      this.chartData = this.avgViewsLineData;
    } else if (tabNumber === 3) {
      this.chartData = this.streamTimeLineData;
    } else if (tabNumber === 5) {
      this.chartData = this.streamGameLineData;
    }
  }

  downloadProfileInformation() {
    this.streamerService.getAllProfileInformation();
  }

  accept_alphabet_and_dash(event: KeyboardEvent): boolean | undefined {
    const allowedKeys = /^[a-zA-Z-.]+$/;

    if (!allowedKeys.test(event.key)) {
      return false;
    }
    return undefined;
  }

  checkEmailValidity() {
    // Regular Expression zur E-Mail-Validierung
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    this.isEmailValid = emailPattern.test(this.paypalEdit);
  }
}
