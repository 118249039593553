import {MathHelper} from '../../services/math.helper';
import {DemographyInfo} from './demography-info.model';
import {AffiliateRequest} from './affiliate-request.model';
import {Affiliate} from './affiliate.model';
import {StreamerCampaign} from './streamer-campaign.model';
import {SocialProfile} from './social-profile.model';
import {I18nService} from '../../services/i18n.service';

export class Streamer {

  public id!: string;

  public username!: string;
  public firstname!: string;
  public lastname!: string;
  public email!: string;
  public birthday!: string;
  public roles!: string[];
  public gender!: string;

  public street!: string;
  public nr!: string;
  public postal_code!: string;
  public city!: string;

  public profile_image_url!: string;
  public ad_url!: string;
  public affiliate_url!: string;
  public competition_url!: string;
  public social_profiles!: {};

  public terms_confirmation: boolean = false;
  public active!: boolean;

  public paypal!: string;
  public taxNr!: string;

  public adKeywords!: string[];

  public demographyInfo: DemographyInfo = new DemographyInfo();

  public lastPaymentDate!: Date;
  public tkp!: number;
  public tier: number = 1;
  public tierChangeDays = 0;
  public outstandingUpgradeRequest = false;
  public reason!: string;
  public streamerPoints!: number;

  public viewsSinceLastPayment!: number;
  public earningsSinceLastPayment!: number;
  public earningsFromViews!: number;
  public earningsFromClicks!: number;
  public earningsFromSales!: number;
  public clicksSinceLastPayment!: number;
  public salesSinceLastPayment!: number;
  public externalAffiliateClicks!: number;

  public preliminaryClicks!: number;
  public preliminarySales!: number;
  public preliminaryEarnings!: number;
  public preliminaryEarningsFromClicks!: number;
  public preliminaryEarningsFromSales!: number;

  public partOfAnyCampaign = false;
  public campaignsInvolved: StreamerCampaign[] = [];

  public participatingAffiliates: Affiliate[] = [];
  public participatingAffiliatesWithoutDefaultAffiliate: Affiliate[] = [];
  public participatingExternalAffiliatesOnly: Affiliate[] = [];
  public currentNumberOfExternalAffiliates: number = 0;
  public allowedNumberOfExternalAffiliates: number = 0;
  public affiliatesForLink: any = [];
  public clickcampaignsForLink: any = [];

  public allowAffiliatePicturesOnStream = false;

  public affiliateRequests: AffiliateRequest[] = [];
  public numberOfINRequests: number = 0;
  public numberOfOUTRequests: number = 0;

  public currentConvRate!: string;
  public totalConvRate!: string;

  public inRegister = false;

  public socialProfiles: SocialProfile[] = [];

  constructor(private mathHelper: MathHelper, private i18nService: I18nService) {
  }

  public hasAddress(): boolean {
    if (this.street && this.nr && this.postal_code && this.city) {
      return true;
    } else {
      return false;
    }
  }

  public hasKeywords(): boolean {
    return !(this.adKeywords.length == 0);
  }

  public hasCountries(): boolean {
    return !(this.demographyInfo.countries.length == 0);
  }

  public hasGenderDemographics(): boolean {
    return (this.demographyInfo.male > 0) || (this.demographyInfo.female > 0) || (this.demographyInfo.divers > 0);
  }

  public hasAgeDemographics(): boolean {
    return (this.demographyInfo.percLessThen18 > 0) || (this.demographyInfo.perc18to24 > 0) ||
      (this.demographyInfo.perc25to34 > 0) || (this.demographyInfo.perc35to44 > 0) || (this.demographyInfo.percOlderThen44 > 0);
  }

  public hasPaymentInfo(): boolean {
    if (this.paypal) {
      return true;
    }else {
      return false;
    }
  }

  public hasEverythingNeededForPayment() {
    let additionalValues = false;
    if (this.firstname && this.lastname && this.birthday) {
      additionalValues = true;
    }

    return this.hasPaymentInfo() && this.hasAddress() && additionalValues;
  }

  public getLastPaymentDateString(): string {
    return this.lastPaymentDate.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
  }

  public getTKPString(): string {
    return this.mathHelper.numberWithCommas(this.tkp);
  }

  public getViewsSinceLastPaymentString(): string {
    return this.mathHelper.numberWithCommas(this.viewsSinceLastPayment);
  }

  public getClicksSinceLastPaymentString(): string {
    return this.mathHelper.numberWithCommas(this.clicksSinceLastPayment);
  }

  public getSalesSinceLastPaymentString(): string {
    return this.mathHelper.numberWithCommas(this.salesSinceLastPayment);
  }

  public getEarningsSinceLastPayment(): string {
    return this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(this.earningsSinceLastPayment, 3))
  }

  public getPreliminaryClicksSinceLastPaymentString(): string {
    return this.mathHelper.numberWithCommas(this.preliminaryClicks);
  }

  public getPreliminarySalesSinceLastPaymentString(): string {
    return this.mathHelper.numberWithCommas(this.preliminarySales);
  }

  public getPreliminaryEarningsSinceLastPayment(): string {
    return this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(this.preliminaryEarnings, 3))
  }

  public getEarningsFromSalesSinceLastPayment(): string {
    return this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(this.earningsFromSales, 3))
  }

  public getEarningsFromViewsSinceLastPayment(): string {
    return this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(this.earningsFromViews, 3))
  }

  public getEarningsFromClicksSinceLastPayment(): string {
    return this.mathHelper.numberWithCommas(this.mathHelper.precisionRound(this.earningsFromClicks, 3))
  }

  public isEnoughEarningsForPayment(): boolean {
    return this.earningsSinceLastPayment > 10;
  }

  public getStatusAsString(lang: string) : string {
    if (this.tier === 1) {
      return 'BRONZE';
    } else if (this.tier === 2) {
      if (lang === 'de') {
        return 'SILBER';
      } else {
        return 'SILVER';
      }
    } else {
      return 'GOLD';
    }
  }

  public isTierUpgradeButtonDisabled():boolean {
    if (this.tier === 1 || this.tier === 2) {
      if (this.tierChangeDays === 0) {
        if (this.outstandingUpgradeRequest) {
          if (this.reason) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  }

  public isTierUpgradeButtonVisible():boolean {
    if (this.tier === 3) {
      return false;
    }

    if (this.outstandingUpgradeRequest && !this.reason) {
      return false;
    }

    return true;
  }

  public fillAffiliatesForLink() {
    if (this.participatingAffiliates.length > 0) {
      var k = 0;
      for (k = 0; k < this.participatingAffiliates.length; k++) {
        const affiliate: Affiliate = this.participatingAffiliates[k];
        if (affiliate.type === 'INTERNAL' || affiliate.type === 'CAMPAIGN') {
          this.clickcampaignsForLink.push({label: affiliate.name, value: affiliate})
        } else {
          this.affiliatesForLink.push({label: affiliate.name, value: affiliate})
        }
      }
    }
  }

  public getGender():string {
    if (this.gender) {
      if (this.i18nService.getCurrentLang() == 'DE') {
        if (this.gender === 'male') {
          return 'Männlich';
        } else if (this.gender === 'female') {
          return 'Weiblich';
        } else {
          return 'Diverse';
        }
      } else {
        if (this.gender === 'male') {
          return 'Male';
        } else if (this.gender === 'female') {
          return 'Female';
        } else {
          return 'Divers';
        }
      }
    } else {
      if (this.i18nService.getCurrentLang() == 'DE') {
        return 'Unbekannt';
      } else {
        return 'Unknown';
      }
    }
  }

}
