import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StreamerBill } from 'src/app/model/streamer/streamer-bill.model';
import { ClickSales } from 'src/app/model/streamer/streamer-click.model';
import { GlobalService } from 'src/app/services/global.service';
import { StreamerService } from 'src/app/services/streamer.service';
import { Stream } from '../../model/streamer/streamer-stream.model';
import { I18nService } from 'src/app/services/i18n.service';
import { FileService } from 'src/app/services/file.service';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'streamer-income',
  templateUrl: './streamer-income.component.html',
  styleUrls: ['./streamer-income.component.scss']
})
export class StreamerIncomeComponent implements OnInit {

  public activeStatistik = 4;

  public streamerUpdateSubscription!: Subscription;

  public streams: Stream[] = [];

  public streamerBills: StreamerBill[] = [];

  public clicks: ClickSales[] = [];

  public loadingStreams!: boolean;
  public loadingBills!: boolean;
  public loadingClicks!: boolean;

  public totalRecordsStreams!: number;
  public totalRecordsBills!: number;
  public totalRecordsClicks!: number;

  constructor(public globalService: GlobalService,
    private streamerService: StreamerService,
    public i18nService: I18nService, public fileService: FileService) {

    this.streamerUpdateSubscription = this.streamerService.streamerChanged.subscribe((new_streamer) => {
      this.initializeStreams();
      this.initializeBills();
      this.initializeClickSales();
    });

  }

  ngOnInit(): void {
    this.initializeStreams();
    this.initializeBills();
    this.initializeClickSales();
  }

  initializeStreams() {
    this.loadingStreams = true;
    this.streamerService.getStreamsOfStreamer().then((result) => {
      this.streams = result;

      this.streamerService.getMaxStreamsOfStreamer().then((max) => {
        this.totalRecordsStreams = max;
        this.loadingStreams = false;
      });

    });
  }

  updateStreams(event: TableLazyLoadEvent) {
    this.loadingStreams = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.streamerService.getStreamsOfStreamer(event.first, rows, sortField, sortOrder).then((result) => {
      this.streams = result;

      this.streamerService.getMaxStreamsOfStreamer().then((max) => {
        this.totalRecordsStreams = max;
        this.loadingStreams = false;
      });
    });

  }

  initializeBills() {
    this.loadingBills = true;
    this.streamerService.getBillsOfStreamer().then((result) => {
      this.streamerBills = result;

      this.streamerService.getMaxBillsOfStreamer().then((max) => {
        this.totalRecordsBills = max;
        this.loadingBills = false;
      });

    });
  }

  updateBills(event: TableLazyLoadEvent) {
    this.loadingBills = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.streamerService.getBillsOfStreamer(event.first, rows, sortField, sortOrder).then((result) => {
      this.streamerBills = result;

      this.streamerService.getMaxBillsOfStreamer().then((max) => {
        this.totalRecordsBills = max;
        this.loadingBills = false;
      });
    });

  }

  initializeClickSales() {
    this.loadingClicks = true;
    this.streamerService.getAffiliateInvoicesOfStreamer().then((result) => {
      this.clicks = result;

      this.streamerService.getMaxAffiliateInvoicesOfStreamer().then((max) => {
        this.totalRecordsClicks = max;
        this.loadingClicks = false;
      });

    });
  }

  updateClickSales(event: TableLazyLoadEvent) {
    this.loadingClicks = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.streamerService.getAffiliateInvoicesOfStreamer(event.first, rows, sortField, sortOrder).then((result) => {
      this.clicks = result;

      this.streamerService.getMaxAffiliateInvoicesOfStreamer().then((max) => {
        this.totalRecordsClicks = max;
        this.loadingClicks = false;
      });
    });

  }

  requestPayment() {
    this.streamerService.requestPayment();
  }

  getDefaultSortOrder(): number {
    return -1;
  }

  downloadPDF(billId: string) {
    this.fileService.downloadBill(billId);
  }

}
