import { Component, OnInit } from '@angular/core';
import { CompetitionService } from '../../services/competition.service';
import { Competition } from '../../model/streamer/competition.model';
import { GlobalService } from '../../services/global.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'streamer-contest',
  templateUrl: './streamer-contest.component.html',
  styleUrls: ['./streamer-contest.component.scss']
})
export class StreamerContestComponent implements OnInit {

  public currentCompetition!: Competition;

  constructor(private competitionService: CompetitionService, public globalService: GlobalService,
              public i18nService: I18nService) {
    this.competitionService.getCurrentCompetition().then(result => {
      this.currentCompetition = result;
    }).catch(error => {
      console.log(error);
    });
  }

  ngOnInit(): void {
    this.competitionService.getCurrentCompetition().then(result => {
      this.currentCompetition = result;
    }).catch(error => {
      console.log(error);
    });
  }

  copyLink() {
    if (this.globalService.streamer) {
      const link = document.createElement('textarea');
      link.style.position = 'fixed';
      link.style.left = '0';
      link.style.top = '0';
      link.style.opacity = '0';
      link.value = this.globalService.streamer.competition_url;
      document.body.appendChild(link);
      link.focus();
      link.select();
      document.execCommand('copy');
      document.body.removeChild(link);
    }
  }

}
