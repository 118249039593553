import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {Injectable} from '@angular/core';
import {SuperService} from './super.service';

@Injectable()
export class NotificationService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {
    super(streamBoostEventBus);
  }

  getNotification(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.streamBoostEventBus.send(
        'stream.web.facade.notification',
        {
          message: {
          }
        })
        .then((result) => {
          {
            if (result.body.code) {
              reject(result.body.code);
              return;
            }

            if (result.body.response) {
              resolve(result.body.response.text);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
      }
    );
  }

}
