<div class="p-grid" style="text-align: center;padding-top: 5em;">
  <div class="p-col"></div>
  <div class="p-col-12 p-md-5 p-lg-4">
    <p-progressSpinner></p-progressSpinner>
    <p style="font-weight: bold">{{i18nService.localize().OauthCallbackComponent.Loading}}</p>
    <button pButton pRipple class="p-button-rounded" *ngIf="seriousError"
            (click)="this.routingService.switchToPath('/streamer/login')">
      {{i18nService.localize().OauthCallbackComponent.ErrorButton}}
    </button>
  </div>
  <div class="p-col"></div>
</div>
