export class CampaignScheduling {

  public dailyViews!: number;
  public countedDailyViews!: number;

  public startDate!: string;
  public endDate!: string;

  public startDateRaw!: Date;
  public endDateRaw!: Date;

  public startTime!: string;
  public endTime!: string;

  public daysBetween!: number;

  public days :string[] = [];

  constructor() {}

}
