import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../services/global.service';
import {I18nService} from '../services/i18n.service';
import {KeywordService} from '../services/keyword.service';
import {RoutingService} from '../services/routing.service';
import {IpService} from '../services/ip.service';
import {AgencyService} from '../services/agency.service';
import {PictureService} from '../services/picture.service';
import {AdvertiserService} from "../services/advertiser.service";

@Component({
  selector: 'agency-advertiser-register',
  templateUrl: './agency-advertiser-register.component.html',
  styleUrls: ['./agency-advertiser-register.component.scss']
})
export class AgencyAdvertiserRegisterComponent implements OnInit {

  public activeTab: number = 1;

  // Grundsätzliches
  public companyName!: string;
  public website!: string;
  public taxNumber!: string;

  // Adresse
  public street!: string;
  public nr!: number;
  public postalCode!: string;
  public city!: string;
  public country: any;

  // Kontakt
  public firstname!:string;
  public lastname!:string;
  public email!:string;
  public telephone!:number;

  public password!:string;
  public passwordToo!:string;

  public working = false;
  public registerSent = false;

  // Länderauswahl
  public countriesDropDownList: any[] = [];

  public emailError = false;

  public acceptTermsAndConditions: boolean = false;

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public keywordService: KeywordService, public agencyService: AgencyService,
              public routingService: RoutingService, public ipService: IpService,
              public advertiserService: AdvertiserService) {
  }

  ngOnInit(): void {

    this.keywordService.getAllCountries().then((countries) => {
      this.countriesDropDownList = [];
      countries.forEach((country) => {
        this.countriesDropDownList.push(
          {
            // @ts-ignore
            item_id: country.id,
            // @ts-ignore
            item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
          });
      });
    }).catch((err) => {
      console.log(err);
    });

  }

  checkAllValuesAdvertiser(): boolean {
    let registerAllowed: boolean = true;

    if (!this.companyName) {
      registerAllowed = false;
    }

    if (!this.email) {
      registerAllowed = false;
    }

    if (!this.password) {
      registerAllowed = false;
    }

    if (!this.passwordToo) {
      registerAllowed = false;
    }

    if (this.password !== this.passwordToo) {
      registerAllowed = false;
    }

    if (!this.acceptTermsAndConditions) {
      registerAllowed = false;
    }

    return registerAllowed;
  }

  registerAdvertiser() {
    this.working = true;

    if (this.checkAllValuesAdvertiser()) {

      this.ipService.getIp().then(res => {
        this.advertiserService.registerAdvertiserSimple(this.companyName, this.email, this.password,
          this.acceptTermsAndConditions, res.ip).then(result => {
          this.working = false;
          this.registerSent = true;
          this.emailError = false;

        }).catch(err => {
          this.working = false;

          if (err.code === 'Manticore') {
            this.emailError = true;
          } else {
            this.emailError = false;
            console.log(err);
          }

        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  checkAllValuesAgency(): boolean {
    let registerAllowed: boolean = true;

    if (!this.companyName) {
      registerAllowed = false;
    }

    if (!this.street) {
      registerAllowed = false;
    }

    if (!this.nr) {
      registerAllowed = false;
    }

    if (!this.postalCode) {
      registerAllowed = false;
    }

    if (!this.city) {
      registerAllowed = false;
    }

    if (!this.firstname) {
      registerAllowed = false;
    }

    if (!this.lastname) {
      registerAllowed = false;
    }

    if (!this.email) {
      registerAllowed = false;
    }

    if (!this.password) {
      registerAllowed = false;
    }

    if (!this.passwordToo) {
      registerAllowed = false;
    }

    if (this.password !== this.passwordToo) {
      registerAllowed = false;
    }

    if (!this.acceptTermsAndConditions) {
      registerAllowed = false;
    }

    return registerAllowed;
  }

  registerAgency() {
    this.working = true;

    if (this.checkAllValuesAgency()) {

      this.ipService.getIp().then(res => {
        this.agencyService.registerAgency(this.companyName, this.website, this.taxNumber, this.street, this.nr,
          this.postalCode, this.city, this.country.item_id, this.firstname, this.lastname, this.email, this.telephone, this.password,
          this.acceptTermsAndConditions, res.ip).then(result => {
            this.working = false;
            this.registerSent = true;
            this.emailError = false;
        }).catch(err => {
          this.working = false;

          if (err.code === 'Manticore') {
            this.emailError = true;
          } else {
            this.emailError = false;
            console.log(err);
          }

        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}
