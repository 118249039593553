<div  *ngIf="this.globalService.agency">
  <div class="headline">{{i18nService.localize().AgencyCreateAdvertiserComponent.CreationHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12" style="margin-top: 2em;">
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">

              <p class="headline-in-step">
                {{i18nService.localize().AgencyCreateAdvertiserComponent.CompanyInfoHeader}}
              </p>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="companyname" style="font-weight: bold">{{i18nService.localize().AgencyCreateAdvertiserComponent.CompanyName}}</label>
                  <input id="companyname" type="text" pInputText [(ngModel)]="companyName" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="website" style="font-weight: bold">Website:</label>
                  <input id="website" type="text" pInputText [(ngModel)]="website" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="logo" style="font-weight: bold;">Logo:</label>
                  <p-fileUpload name="pictures[]" [multiple]=false accept="image/jpeg, image/png"
                                [maxFileSize]=1500000 id="logo" [showUploadButton]="false"
                                [files]="getCurrentPictures()" [fileLimit]="1" (onSelect)="setPictures($event)"
                                chooseLabel="{{i18nService.localize().AdvertiserProfileComponent.ChooseButton}}"
                                cancelLabel="{{i18nService.localize().AdvertiserProfileComponent.CancelButton}}">
                    <ng-template pTemplate="content">
                      <ul *ngIf="uploadedPictures.length">
                        <li *ngFor="let file of uploadedPictures">{{file.name}} - {{file.size}} bytes
                        </li>
                      </ul>
                    </ng-template>
                  </p-fileUpload>
                  <p class="warning" *ngIf="!picturesOk">{{i18nService.localize().AgencyCreateAdvertiserComponent.PicError}}</p>
                </div>

              </div>

              <p class="headline-in-step">
                {{i18nService.localize().AgencyCreateAdvertiserComponent.AddressHeader}}
              </p>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                  <label for="street" style="font-weight: bold">{{i18nService.localize().AgencyCreateAdvertiserComponent.Street}}</label>
                  <input id="street" type="text" pInputText [(ngModel)]="street" class="field">
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="nr" style="font-weight: bold">Nr*:</label>
                  <input id="nr" type="text" pInputText [(ngModel)]="nr" class="field">
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="postalcode" style="font-weight: bold">{{i18nService.localize().AgencyCreateAdvertiserComponent.PostalCode}}</label>
                  <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field">
                </div>

                <div class="p-field p-col-12 p-md-8">
                  <label for="city" style="font-weight: bold">{{i18nService.localize().AgencyCreateAdvertiserComponent.City}}</label>
                  <input id="city" type="text" pInputText [(ngModel)]="city" class="field">
                </div>

                <div class="p-field p-col-12">
                  <label for="country" style="font-weight: bold">{{i18nService.localize().AgencyCreateAdvertiserComponent.Country}}</label>
                  <p-dropdown id="country" [options]="countriesDropDownList" [(ngModel)]="country"
                              optionLabel="item_text" [showClear]="true"
                              [style]="{'border-radius': '12px', 'width': '100%'}"
                              [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                  </p-dropdown>
                </div>
              </div>

            </div>
            <div class="p-col-12" style="text-align: right;">
              <button pButton pRipple type="button" class="p-button-rounded p-button-danger"
                      icon="fas fa-times" *ngIf="!working"
                      label="{{i18nService.localize().AgencyCreateAdvertiserComponent.CancelButton}}"
                      (click)="this.routingService.switchToPath('/agency/advertisers')"
                      style="margin-right: .25em" iconPos="left">
              </button>
              <button pButton pRipple type="button" (click)="register()" *ngIf="!working" icon="fas fa-check"
                      label="{{i18nService.localize().AgencyCreateAdvertiserComponent.CreateButton}}" class="p-button-rounded"
                      [disabled]="!checkAllValues()" style="margin-right: .25em">
              </button>
            </div>
          </div>
        </p-card>
      </div>

    </div>
  </div>
</div>
