import {FileModel} from './file.model';
import {CampaignDemographic} from "./campaign-demographic.model";

export class AdvertiserAffiliate {

  public id!: string;

  public name!: string;
  public description!: string;

  public initialBudgetText!: string;
  public budgetText!: string;

  public initialBudget!: number;
  public budget!: number;

  public initialClicks!: number;
  public currentClicks!: number;

  public initialDailyClicks!: number;
  public dailyClicks!: number;

  public clicks!: number
  public linkClicks!: number;
  public codeClicks!: number;
  public fileClicks!: number;

  public startDate!: string;
  public calculatedEndDate!: string;

  public code!: string;
  public link!: string;
  public files: FileModel[] = [];
  public chatMessage!: string;
  public tagId!: string;

  public keywords: string[] = [];
  public targetAgeFrom!: number;
  public targetAgeTo!: number;
  public targetGender: string[] = [];
  public targetCountries: string[] = [];
  public streamerFocus!: string;
  public intensityFocus!: string;
  public streamerAgeFrom!: number;
  public streamerAgeTo!: number;
  public streamerGender: string[] = [];


  public logoURL!: string;
  public status!: string;

  public progress!: number;

  public type!: string;

  public campaignId!: string;
  public campaignName!: string;

  public lastInvoiceDate!: string;

  public bannerPics: string[] = [];

  public demographic!: CampaignDemographic;

  constructor() {}

  public hasClickSpreading() : boolean {
    if(this.linkClicks || this.codeClicks || this.fileClicks) {
      return true;
    } else {
      return false;
    }
  }

  public hasLongDescription() : boolean {
    return this.description.length > 21;
  }

  public getDescriptionExcerpt() : string {
    if (this.hasLongDescription()) {
      return this.description.slice(0,21)+ "... ";
    } else {
      return this.description;
    }
  }

  public hasDemographic(): boolean {
    if (this.demographic) {
      return this.demographic.hasAgeInfos() || this.demographic.hasGenderInfos() || this.demographic.hasCountries();
    } else {
      return false;
    }
  }

}
