import {Injectable} from '@angular/core';
import {SuperService} from './super.service';
import {StreamBoostEventBus} from '../streamboost-eventbus.service';
import {Keyword} from '../model/streamer/keyword.model';
import {Country} from '../model/streamer/country.model';
import {Keywordgroup} from "../model/streamer/keywordgroup.model";

/**
 * Der Service für jede Art von "Keywords"
 */
@Injectable()
export class KeywordService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus) {
    super(streamBoostEventBus);
  }

  public getAllAdKeywords(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send('stream.web.facade.keyword.all',
        {
          message: {}
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
            } else {
              const keywordModels = [];

              const keywords = response.body.response.keywords;
              for (let j = 0; j < keywords.length; j++) {
                const langMap = new Map<string, object>();
                if (keywords[j].de) {
                  langMap.set('DE', keywords[j].de);
                }

                if (keywords[j].en) {
                  langMap.set('EN', keywords[j].en);
                }

                const keyword = new Keyword(keywords[j].id, langMap);
                keywordModels.push(keyword);
              }

              resolve(keywordModels);
            }
          }
        );

    });
  }

  public getAllAdKeywordgroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send('stream.web.facade.keywordgroup.all',
        {
          message: {}
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
            } else {
              const keywordgroupModels = [];

              const keywordgroups = response.body.response.keywordgroups;
              for (let j = 0; j < keywordgroups.length; j++) {
                const langMap = new Map<string, object>();
                if (keywordgroups[j].de) {
                  langMap.set('DE', keywordgroups[j].de);
                }

                if (keywordgroups[j].en) {
                  langMap.set('EN', keywordgroups[j].en);
                }

                const keywordgroup = new Keywordgroup(keywordgroups[j]._id, langMap, keywordgroups[j].keywords);
                keywordgroupModels.push(keywordgroup);
              }

              resolve(keywordgroupModels);
            }
          }
        );

    });
  }

  public getAllGameKeywords(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send('stream.web.facade.game.keyword.all',
        {
          message: {}
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
            } else {
              const keywordModels = [];

              const keywords = response.body.response.keywords;
              for (let j = 0; j < keywords.length; j++) {

                const langMap = new Map<string, object>();
                if (keywords[j].de) {
                  langMap.set('DE', keywords[j].de);
                }

                if (keywords[j].en) {
                  langMap.set('EN', keywords[j].en);
                }

                const keyword = new Keyword(keywords[j].id, langMap);
                keywordModels.push(keyword);
              }

              resolve(keywordModels);
            }
          }
        );

    });
  }

  public getAllCountries(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.streamBoostEventBus.send('stream.web.facade.country.all',
        {
          'message': {}
        })
        .then(
          (response: any) => {
            if (response.body.code) {
              reject(response.body.code);
            } else {
              const countryModels = [];
              let countries = response.body.response['countries'];

              for (let j = 0; j < countries.length; j++) {

                let langMap = new Map<string, object>();
                if (countries[j]['de']) {
                  langMap.set('DE', countries[j]['de']);
                }

                if (countries[j]['en']) {
                  langMap.set('EN', countries[j]['en']);
                }

                const country = new Country(countries[j]._id, langMap);
                countryModels.push(country);
              }

              resolve(countryModels);
            }
          }
        );
    });
  }

}
