import { StreamBoostEventBus } from '../streamboost-eventbus.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SuperService } from './super.service';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FileService extends SuperService {

  constructor(public streamBoostEventBus: StreamBoostEventBus, private cookieService: CookieService, private http: HttpClient) {
    super(streamBoostEventBus);
  }

  uploadAffiliateFilesHttp(files: File[], affiliate_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('camp-upload', files[i], files[i].name);
      }

      let token;
      if (this.cookieService.get('streamboost-session')) {
        token = this.cookieService.get('streamboost-session');
      }

      if (token) {
        let headers: HttpHeaders = new HttpHeaders({'token': token});

        this.http.post('/file/upload/'+affiliate_id, formData, {
          headers: headers, reportProgress: true, observe: 'events'
        }).subscribe( (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                // console.log("sending");
                break;
              case HttpEventType.Response:
                // console.log("response");
                resolve();
                break;
              case HttpEventType.UploadProgress: {
                // console.log("uploading in progress")
                break;
              }
            }
          },
          error => {
            console.log(error);
            reject();
          });
      }

    });
  }

  downloadBill(billId: string): Promise<any> {
    return new Promise((resolve, reject) => {

      let token;
      if (this.cookieService.get('streamboost-session')) {
        token = this.cookieService.get('streamboost-session');
      }

      if (token) {
        let headers: HttpHeaders = new HttpHeaders({ 'token': token });

        this.http.get('/bill/' + billId, { headers: headers, responseType: 'arraybuffer' })
          .subscribe(response =>
            FileService.downloadFile(response, "application/pdf")
          )
      }
    });
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  private static downloadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

}
