import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { CampaignService } from '../../services/campaign.service';
import { AdvertiserCampaign } from '../../model/advertiser/advertiser-campaign.model';
import { MenuItem } from 'primeng/api';
import { GameStreamStatistic } from '../../model/advertiser/game-stream-statistic.model';
import { I18nService } from '../../services/i18n.service';
import { CampaignLiveStream } from '../../model/advertiser/campaign-live-stream.model';
import { CampaignFinishedStream } from '../../model/advertiser/campaign-finished-stream.model';
import { PictureService } from '../../services/picture.service';
import { ApexAxisChartSeries, ApexTitleSubtitle, ApexDataLabels, ApexChart } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { CampaignFeedback } from '../../model/advertiser/campaign-feedback.model';
import { CampaignQuestion } from '../../model/advertiser/campaign-question.model';
import { FeedbackService } from '../../services/feedback.service';
import { TableLazyLoadEvent } from 'primeng/table';

export type ChartOptions = {
  chart: ApexChart;
  colors: any;
  dataLabels: ApexDataLabels;
  series: ApexAxisChartSeries;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'advertiser-campaign-details',
  templateUrl: './advertiser-campaign-details.component.html',
  styleUrls: ['./advertiser-campaign-details.component.scss'],
})
export class AdvertiserCampaignDetailsComponent implements OnInit {
  @Input()
  public campaignId!: string;

  @Input()
  public numberOfStreamer!: number;

  @Output()
  public closeEmit = new EventEmitter<void>();

  public campaign!: AdvertiserCampaign;

  public activeTab: number = 1;

  public dailyViewData: any;
  public viewData: any;

  public showDescriptionModal = false;
  public showPicUpload = false;
  public picturesOk = true;

  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public working = false;

  // Views
  public burndownData: any;
  public burndownItems!: MenuItem[];
  public burndowns: any;
  public burndownOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  public burndown = false;

  // Zuschauer
  public genderData: any;
  public ageData: any;
  public chartOptions = {
    scales: {
      y: {
        max: 100,
        beginAtZero: true,
      },
    },
  };
  public countryData: any;

  public gameStreamStatistics: GameStreamStatistic[] = [];
  public gameTimesData: any;
  public gameViewData: any;
  public streamedCategoryViewData: any;

  // Live Streams
  public streams: CampaignLiveStream[] = [];
  public loadingStreams: boolean = false;

  // Fertige Streams
  public finishedStreams: CampaignFinishedStream[] = [];
  public totalFinishedStreams!: number;
  public loadingFinishedStreams: boolean = false;

  //Heatmap
  public heatOptions?: any;
  public cwSelectItems!: MenuItem[];
  public currentVisibleCW: string = "ALL";

  // Feedback Tab
  public feedbacks: CampaignFeedback[] = [];
  public totalFeedbacks!: number;
  public loadingFeedbacks!: boolean;

  public questions: CampaignQuestion[] = [];
  public totalQuestions!: number;
  public loadingQuestions!: boolean;

  public showFeedbackOrQuestionModal: boolean = false;
  public feedbackOrQuestionText?: string;
  public answerText?: string;
  public modalHeadline: string = "FRAGE";

  public showAnswerQuestionModal: boolean = false;
  public selectedQuestion?: string;
  public selectedAnswer?: string;
  public selectedForQuestionForAnswer?: CampaignQuestion;

  public langChangeSubscription!: Subscription;

  constructor(
    public globalService: GlobalService,
    public campaignService: CampaignService,
    public i18nService: I18nService,
    public pictureService: PictureService,
    public feedbackService: FeedbackService
  ) {

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe((newLang) => {
      if (this.globalService.advertiser || this.globalService.agency) {
        this.updateBurnCharts().then(() => {
          this.updateHeatmapCWSelector();
          this.updateStreamTimeHeatmap();
          this.updateGameTimesChart();
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.globalService.advertiser || this.globalService.agency) {
      this.updateCampaign();
      this.initializeFeedbackOrQuestions();
    }
  }

  updateDailyViewDataChart() {
    this.dailyViewData = {};

    if (this.campaign.scheduling) {
      if (
        this.campaign.scheduling.dailyViews &&
        this.campaign.scheduling.dailyViews > 0
      ) {
        this.dailyViewData = {
          labels: [
            this.i18nService.localize().AdvertiserCampaignDetailsComponent
              .Remaining,
            this.i18nService.localize().AdvertiserCampaignDetailsComponent
              .Current,
          ],
          datasets: [
            {
              data: [
                this.campaign.scheduling.dailyViews -
                  this.campaign.scheduling.countedDailyViews,
                this.campaign.scheduling.countedDailyViews,
              ],
              backgroundColor: ['#3faff7', '#393939'],
              hoverBackgroundColor: ['#3faff7', '#393939'],
            },
          ],
        };
      }
    }
  }

  updateViewDataChart() {
    this.viewData = {};

    this.viewData = {
      labels: [
        this.i18nService.localize().AdvertiserCampaignDetailsComponent
          .PlannedViews,
        this.i18nService.localize().AdvertiserCampaignDetailsComponent.Views,
      ],
      datasets: [
        {
          data: [
            this.campaign.viewsPlanned - this.campaign.viewsAccounted,
            this.campaign.viewsAccounted,
          ],
          backgroundColor: ['#3faff7', '#393939'],
          hoverBackgroundColor: ['#3faff7', '#393939'],
        },
      ],
    };
  }

  back() {
    this.closeEmit.emit();
  }

  setBurndown() {
    this.burndown = true;
    this.updateBurnCharts();
  }

  setBurnup() {
    this.burndown = false;
    this.updateBurnCharts();
  }

  getBurnType(): string {
    if (this.burndown) {
      return 'Burndown';
    } else {
      return 'Burnup';
    }
  }

  updateBurnCharts() : Promise<any> {
    return new Promise((resolve, reject) => {
      this.campaignService
        .getBurndownOfCampaign(this.campaignId, this.burndown)
        .then((result) => {
          this.burndowns = result;
          this.updateBurndownMenu();

          if (this.burndown) {
            this.burndownData = this.burndowns.days_burndown;
          } else {
            this.burndownData = this.burndowns.days_burnup;
          }

          resolve('');
        });
    });
  }

  updateBurndownMenu() {
    this.burndownItems = [];

    if (this.burndowns.days_burndown || this.burndowns.days_burnup) {
      this.burndownItems.push({
        label:
          this.i18nService.localize().AdvertiserCampaignDetailsComponent.Day,
        command: () => {
          if (this.burndown) {
            this.burndownData = this.burndowns.days_burndown;
          } else {
            this.burndownData = this.burndowns.days_burnup;
          }
        },
      });
    }

    if (this.burndowns.cw_burndown || this.burndowns.cw_burnup) {
      this.burndownItems.push({
        label:
          this.i18nService.localize().AdvertiserCampaignDetailsComponent.Week,
        command: () => {
          if (this.burndown) {
            this.burndownData = this.burndowns.cw_burndown;
          } else {
            this.burndownData = this.burndowns.cw_burnup;
          }
        },
      });
    }

    if (this.burndowns.month_burndown || this.burndowns.month_burnup) {
      this.burndownItems.push({
        label:
          this.i18nService.localize().AdvertiserCampaignDetailsComponent.Month,
        command: () => {
          if (this.burndown) {
            this.burndownData = this.burndowns.month_burndown;
          } else {
            this.burndownData = this.burndowns.month_burnup;
          }
        },
      });
    }
  }

  updateDemographicCharts() {
    this.genderData = {};
    this.ageData = {};
    this.countryData = {};

    this.campaignService
      .getDemographicForCampaign(this.campaign.id)
      .then((result) => {
        if (result) {
          this.campaign.demographic = result;

          if (this.campaign.demographic.hasGenderInfos()) {
            this.genderData = {
              labels: [
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Male,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Female,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Divers,
              ],
              datasets: [
                {
                  data: [
                    this.campaign.demographic.male,
                    this.campaign.demographic.female,
                    this.campaign.demographic.diverse,
                  ],
                  backgroundColor: ['#3faff7', '#ff80bf', '#ffff00'],
                  hoverBackgroundColor: ['#3faff7', '#ff80bf', '#ffff00'],
                },
              ],
            };
          }

          if (this.campaign.demographic.hasAgeInfos()) {
            this.ageData = {
              labels: [
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .AgeUnder18,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age18To24,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age25To34,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .Age35To44,
                this.i18nService.localize().AdvertiserCampaignDetailsComponent
                  .AgeOver44,
              ],
              datasets: [
                {
                  label:
                    this.i18nService.localize()
                      .AdvertiserCampaignDetailsComponent.Age,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                  data: [
                    this.campaign.demographic.under18,
                    this.campaign.demographic.from18to24,
                    this.campaign.demographic.from25to34,
                    this.campaign.demographic.from35to44,
                    this.campaign.demographic.over44,
                  ],
                },
              ],
            };
          }

          if (this.campaign.demographic.hasCountries()) {
            this.countryData = {
              labels: this.campaign.demographic.getCountryNames(),
              datasets: [
                {
                  label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.Countries,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                  data: this.campaign.demographic.getCountryValues(),
                },
              ],
            };
          }
        }
      });
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      return this.uploadedPicturesBackup;
    } else {
      return this.uploadedPictures;
    }
  }

  uploadPic() {
    this.working = true;
    this.pictureService
      .uploadImagesHttp(
        this.uploadedPicturesBackup,
        this.campaignId,
        'BANNER',
        'campaign'
      )
      .then(() => {
        setTimeout(() => {
          this.showPicUpload = false;
          this.working = false;
          this.updateCampaign();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        this.working = false;
      });
  }

  updateGameStreamStatistics() {
    this.campaignService
      .getAllGameStreamStatistics(this.campaign.id)
      .then((result) => {
        this.gameStreamStatistics = result;
      });
  }

  /*
  Live Streams
   */
  goToLiveStreams() {
    this.updateLiveStreams();
    this.activeTab = 3;
  }

  updateLiveStreams() {
    this.loadingStreams = true;
    this.campaignService
      .getLiveStreamsOfCampaign(this.campaign.id)
      .then((result) => {
        this.streams = result;
        this.loadingStreams = false;
      });
  }

  /*
  Fertige Streams
   */
  initializeFinishedStreams() {
    this.loadingFinishedStreams = true;
    this.campaignService
      .getFinishedStreamsOfCampaign(this.campaign.id)
      .then((result) => {
        this.finishedStreams = result;

        this.campaignService
          .getMaxFinishedStreamsOfCampaign(this.campaign.id)
          .then((max) => {
            this.totalFinishedStreams = max;
            this.loadingFinishedStreams = false;
          });
      });
  }

  updateFinishedStreams(event: TableLazyLoadEvent) {
    this.loadingFinishedStreams = true;
    let rows: any = event.rows;
    let sortField: any = event.sortField;
    let sortOrder: any = event.sortOrder;

    this.campaignService.getFinishedStreamsOfCampaign(
        this.campaign.id,
        event.first,
        rows,
        sortField,
        sortOrder
      )
      .then((result) => {
        this.finishedStreams = result;

        this.campaignService
          .getMaxFinishedStreamsOfCampaign(this.campaign.id)
          .then((max) => {
            this.totalFinishedStreams = max;
            this.loadingFinishedStreams = false;
          });
      });
  }

  getDefaultSortOrder(): number {
    return -1;
  }

  deletePicture(pic: any) {
    var picId = pic.link.substring(9, pic.length);
    this.pictureService
      .deleteCampaignPicture(picId, this.campaignId)
      .then(() => {
        this.updateCampaign();
      });
  }

  updateCampaign() {
    this.campaignService.getCampaign(this.campaignId).then((result) => {
      this.campaign = result;
      console.log(this.campaign);

      // Statistiken
      this.updateDailyViewDataChart();
      this.updateViewDataChart();
      this.updateBurnCharts().then(() => {
        this.updateHeatmapCWSelector();
        this.updateStreamTimeHeatmap();
      });
      this.updateDemographicCharts();
      this.updateGameStreamStatistics();
      this.updateGameTimesChart();
      this.updateGameChart();
      this.updateStreamedCategoryChart();

      // Live Streams
      this.updateLiveStreams();
      this.initializeFinishedStreams();
    });
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.pictureService.checkCampaignBanner(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  /**
   * Heatmap Methoden
   */
  updateStreamTimeHeatmap() {
    this.campaignService.getStreamTimeHeatmapOfCampaign(this.campaignId, this.currentVisibleCW).then((result) => {
      // this.heatOptions = result;

      if (result.series) {
        this.heatOptions = {
          series: result.series,
          chart: {
            height: 500,
            type: "heatmap"
          },
          dataLabels: {
            enabled: true
          },
          colors: ["#155ea7"],
          title: {
            text: ""
          },
          plotOptions: {
            heatmap: {
              distributed: true,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 250,
                    color: '#3faff7',
                    name: 'wenig',
                  },
                  {
                    from: 251,
                    to: 1000,
                    color: '#155ea7',
                    name: 'medium',
                  },
                  {
                    from: 1001,
                    to: 4000,
                    color: '#ffcc4c',
                    name: 'hoch',
                  },
                  {
                    from: 4001,
                    to: 20000,
                    color: '#ff7272',
                    name: 'sehr hoch',
                  }
                ]
              }
            }
          }
        };
      } else {
        delete this.heatOptions;
      }

    });
  }

  updateHeatmapCWSelector() {
    this.cwSelectItems = [];

    this.cwSelectItems.push({
      label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.SelectAll,
      command: () => {
        this.currentVisibleCW = 'ALL';
        this.updateStreamTimeHeatmap();
      },
    });

    if (this.burndowns.cw_burndown || this.burndowns.cw_burnup) {

      let labelsArray: string[];
      if (this.burndowns.cw_burndown) {
        labelsArray = this.burndowns.cw_burndown.labels;
      } else {
        labelsArray = this.burndowns.cw_burnup.labels;
      }

      if (labelsArray.length < 12) {
        for (let i = 1; i < labelsArray.length; i++) {
          this.cwSelectItems.push({
            label: labelsArray[i],
            command: () => {
              this.currentVisibleCW = labelsArray[i];
              this.updateStreamTimeHeatmap();
            },
          });
        }
      } else {
        console.log('Too many CWS for Heatmap, sorry');
      }

    }
  }

  /**
   * Spiele nach Zeit in Stunden
   **/
  updateGameTimesChart() {
    this.gameTimesData = {};

    this.campaignService
      .getGameTimeStatisticForCampaign(this.campaign.id)
      .then((result) => {
        if (result) {
          if (result.game_times.labels.length > 0) {
            this.gameTimesData = {
              labels: result.game_times.labels,
              datasets: [
                {
                  label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.TimeInHours,
                  data: result.game_times.data,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                },
              ],
            };
          }
        }
      });
  }

  updateGameChart() {
    this.gameViewData = {};

    this.campaignService
      .getGameStatisticForCampaign(this.campaign.id)
      .then((result) => {
        if (result) {
          if (result.game_views.labels.length > 0) {
            this.gameViewData = {
              labels: result.game_views.labels,
              datasets: [
                {
                  label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.ViewsInGame,
                  data: result.game_views.data,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                },
              ],
            };
          }
        }
      });
  }

  updateStreamedCategoryChart() {
    this.streamedCategoryViewData = {};

    this.campaignService
      .getGameCategoryStatisticForCampaign(this.campaign.id)
      .then((result) => {
        if (result) {
          if (result.game_views.labels.length > 0) {
            this.streamedCategoryViewData = {
              labels: result.game_views.labels,
              datasets: [
                {
                  label: this.i18nService.localize().AdvertiserCampaignDetailsComponent.ViewsInGame,
                  data: result.game_views.data,
                  backgroundColor: '#1d60a4',
                  borderColor: '#1d60a4',
                },
              ],
            };
          }
        }
      });
  }

  // Feedback oder Fragen Tab
  initializeFeedbackOrQuestions() {
    this.feedbacks = [];

    this.initializeFeedbacks();
    this.initializeQuestions();
  }

  initializeFeedbacks() {
    this.loadingFeedbacks = true;
    this.feedbackService.getFeedbackOfCampaign(0, 20,'question_date', -1, this.campaignId).then((result) => {
      this.feedbacks = result;

      this.feedbackService.getMaxFeedbackOfCampaign(this.campaignId).then((max) => {
        this.totalFeedbacks = max;
        this.loadingFeedbacks = false;
      });

    });
  }

  updateFeedbacks(event: TableLazyLoadEvent) {
    this.loadingFeedbacks = true;
    if (event.first != null && event.rows != null && event.sortField != null && event.sortOrder != null) {
      let rows: any = event.rows;
      let sortField: any = event.sortField;
      let sortOrder: any = event.sortOrder;

      this.feedbackService.getFeedbackOfCampaign(event.first, rows, sortField, sortOrder, this.campaignId).then((result) => {
        this.feedbacks = result;

        this.feedbackService.getMaxFeedbackOfCampaign(this.campaignId).then((max) => {
          this.totalFeedbacks = max;
          this.loadingFeedbacks = false;
        });
      });
    }
  }

  initializeQuestions() {
    this.loadingQuestions = true;
    this.feedbackService.getQuestionsOfCampaign(0, 20,'question_date', -1, this.campaignId).then((result) => {
      this.questions = result;

      this.feedbackService.getMaxQuestionsOfCampaign(this.campaignId).then((max) => {
        this.totalQuestions = max;
        this.loadingQuestions = false;
      });

    });
  }

  updateQuestions(event: TableLazyLoadEvent) {
    this.loadingQuestions = true;
    if (event.first != null && event.rows != null && event.sortField != null && event.sortOrder != null) {
      let rows: any = event.rows;
      let sortField: any = event.sortField;
      let sortOrder: any = event.sortOrder;

      this.feedbackService.getQuestionsOfCampaign(event.first, rows, sortField, sortOrder, this.campaignId).then((result) => {
        this.questions = result;

        this.feedbackService.getMaxQuestionsOfCampaign(this.campaignId).then((max) => {
          this.totalQuestions = max;
          this.loadingQuestions = false;
        });
      });
    }

  }

  showText(feedbackOrQuestion: any, type: string) {
    delete this.feedbackOrQuestionText;
    delete this.answerText;
    this.feedbackOrQuestionText = feedbackOrQuestion.message;
    if (feedbackOrQuestion.answer) {
      this.answerText = feedbackOrQuestion.answer;
    }

    let currentLang = this.i18nService.getCurrentLang();
    if (type === 'QUESTION') {
      if (currentLang === 'DE') {
        this.modalHeadline = 'FRAGE';
      } else {
        this.modalHeadline = 'QUESTION';
      }
    } else {
      this.modalHeadline = 'FEEDBACK';
    }

    this.showFeedbackOrQuestionModal = true;
  }

  showAnswerQuestion(question: CampaignQuestion) {
    delete this.selectedAnswer;
    delete this.selectedQuestion;
    delete this.selectedForQuestionForAnswer;
    this.selectedQuestion = question.message;
    this.selectedForQuestionForAnswer = question;

    this.showAnswerQuestionModal = true;
  }

  answerQuestion() {
    if (this.selectedAnswer && this.selectedForQuestionForAnswer) {
      this.feedbackService.answerCampaignStreamerQuestion(this.campaignId, this.selectedForQuestionForAnswer.id, this.selectedAnswer).then(result => {
        this.initializeQuestions();
        this.showAnswerQuestionModal = false;
      });
    }
  }

}
