import {Component} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {News} from '../../model/streamer/news.model';
import {NewsService} from '../../services/news.service';
import {Subscription} from 'rxjs';
import {I18nService} from '../../services/i18n.service';

@Component({
  selector: 'streamer-news',
  templateUrl: './streamer-news.component.html',
  styleUrls: ['./streamer-news.component.css']
})
export class StreamerNewsComponent {

  public news: News[] = [];

  public selectedNews?: News;
  public newsType: string = "57a31fb78f30f31ce41c037i";

  public newsOptions: any[];

  public langChangeSubscription!: Subscription;

  constructor(public globalService: GlobalService, private newsService: NewsService, public i18nService: I18nService) {
    this.updateNews();
    this.newsOptions = [
      {label: this.i18nService.localize().StreamerNewsComponent.NewsLabel, value: '57a31fb78f30f31ce41c037i'},
      {label: this.i18nService.localize().StreamerNewsComponent.AcademyLabel, value: '57a31fb78f30f31ce41c037j'}
    ];

    this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe( newLang => {
      this.updateNews();
      this.newsOptions = [
        {label: this.i18nService.localize().StreamerNewsComponent.NewsLabel, value: '57a31fb78f30f31ce41c037i'},
        {label: this.i18nService.localize().StreamerNewsComponent.AcademyLabel, value: '57a31fb78f30f31ce41c037j'}
      ];
    });
  }

  updateNews() {
    this.newsService.getCurrentNews(this.newsType).then(result => {
      this.news = result;
    });
  }

  selectNewsForReading(news: News) {
    console.log(news);
    this.selectedNews = news;
    this.newsService.saveClick(news.id);
  }

  goBack() {
    delete this.selectedNews;
  }

}
