export class Links {

  public adminURL!: string;

  public streamerURL!: string;
  public siteURL!: string;

  public deleteAccount!: string;

  public legalLink!: string;

  public privacyLink!: string;

  public cookiesLink!: string;

  public termsLink!: string;

  public streamerDocLink!: string;

  public advertiserDocLink!: string;

  public conditionsLink!: string;

  constructor() {
  }

}
