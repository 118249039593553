export class Stream {

  public id!: string;

  public startDate!: string;

  public endDate!: string;

  public duration!: string;

  public network!: string;

  public views!: number;

  public earnings!: string;

  public streamerBillId: string = '';

  constructor(private currentLang: string) {

  }

  public getStatus(): string {
    if (this.streamerBillId !== '') {
      if (this.currentLang == 'DE') {
        return 'Abgerechnet';
      } else {
        return 'Settled';
      }
    } else {
      if (this.currentLang == 'DE') {
        return 'Bereit zur Auszahlung';
      } else {
        return 'Ready for payment';
      }
    }

  }

  public getNetwork() :string {
    if (this.network === 'Google') {
      return 'Youtube';
    } else {
      return this.network;
    }
  }
}
