export class SocialProfile {

  public id!: string;

  public network!: string;

  public profileName!: string;

  constructor() {
  }

  getNetworkName(): string {
    if(this.network === 'Google') {
      return 'Youtube';
    } else {
      return this.network;
    }
  }

}
