import {Component} from '@angular/core';
import {MegaMenuItem} from 'primeng/api';
import {GlobalService} from '../services/global.service';
import {RoutingService} from '../services/routing.service';
import {I18nService} from '../services/i18n.service';
import {AdvertiserService} from '../services/advertiser.service';
import {StreamerService} from '../services/streamer.service';
import {NotificationService} from '../services/notification.service';
import {AgencyService} from '../services/agency.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mobileMenu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {

  public menuIsShown: boolean = false;

  public items: MegaMenuItem[] = [];

  public langChangeSubscription!: Subscription;

  public notificationText?: string;

  public deleteStreamerModal: boolean = false;

  constructor(public globalService: GlobalService, public routingService: RoutingService,
    public i18nService: I18nService, private advertiserService: AdvertiserService, public streamerService: StreamerService,
    private notificationService: NotificationService, private agencyService: AgencyService) {

    // Wenn ein Streamer, ein Advertiser oder eine Agentur angemeldet ist, zeige das Menü an
    if (globalService.streamer || globalService.advertiser || globalService.agency) {
      this.menuIsShown = true;
      if (globalService.getCurrentUserType() === 'streamer') {
        this.fillMenuStreamer()
      } else if (globalService.getCurrentUserType() === 'advertiser') {
        this.fillMenuAdvertiser()
      } else if (globalService.getCurrentUserType() === 'agency') {
        this.fillMenuAgency();
      }

      this.updateNotification();

      // Falls das Menü sichtbar ist, soll die Sprache angepasst werden, wenn sie sich ändert
      this.langChangeSubscription = this.i18nService.langChangeEmitter.subscribe(newLang => {
        this.items = [];
        if (globalService.getCurrentUserType() === 'streamer') {
          this.fillMenuStreamer()
        } else if (globalService.getCurrentUserType() === 'advertiser') {
          this.fillMenuAdvertiser()
        } else if (globalService.getCurrentUserType() === 'agency') {
          this.fillMenuAgency();
        }
      });

    // Wenn man ein Advertiser ist, der sich registrieren möchte
    } else if (this.routingService.getRoute().includes('/advertiser/register')) {
      this.menuIsShown = true;

    // Wenn man eine Agentur oder ein Advertiser ist, die sich registrieren möchte
    } else if (this.routingService.getRoute().includes('/register')) {
      this.menuIsShown = true;

    // Wenn nicht, dann nicht
    } else {
      this.menuIsShown = false;
    }

  }

  fillMenuAdvertiser() {
    if (this.globalService.enableGoogleTags) {
      this.items = [
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu, icon: 'dashboard',
          command: () => {
            this.routingService.switchToPath('/advertiser/dashboard');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserCampaignsMenu, icon: 'displaykampagne',
          command: () => {
            this.routingService.switchToPath('/advertiser/campaigns');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserTagsMenu, icon: 'tags',
          command: () => {
            this.routingService.switchToPath('/advertiser/tags');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserBillsMenu, icon: 'einnahmen',
          command: () => {
            this.routingService.switchToPath('/advertiser/bills');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu, icon: 'profil',
          command: () => {
            this.routingService.switchToPath('/advertiser/profile');
            this.globalService.menuIsOpen = false;
          }
        }
      ]
    } else {
      this.items = [
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu, icon: 'dashboard',
          command: () => {
            this.routingService.switchToPath('/advertiser/dashboard');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserCampaignsMenu, icon: 'displaykampagne',
          command: () => {
            this.routingService.switchToPath('/advertiser/campaigns');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserBillsMenu, icon: 'einnahmen',
          command: () => {
            this.routingService.switchToPath('/advertiser/bills');
            this.globalService.menuIsOpen = false;
          }
        },
        {
          label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu, icon: 'profil',
          command: () => {
            this.routingService.switchToPath('/advertiser/profile');
            this.globalService.menuIsOpen = false;
          }
        }
      ]
    }

  }

  fillMenuStreamer() {
    if (this.globalService.streamer && !this.globalService.streamer.inRegister) {

      if (this.globalService.activeCompetition) {
        this.items = [
          {
            label: this.i18nService.localize().MenuComponent.StreamerDashboardMenu, icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/streamer/home');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerIncomeMenu, icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/streamer/income');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerCampaignsMenu, icon: 'display-streamer',
            command: () => {
              this.routingService.switchToPath('/streamer/campaigns');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerAffiliateMenu, icon: 'klick-streamer',
            command: () => {
              this.routingService.switchToPath('/streamer/affiliates');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerContestMenu, icon: 'gewinnspiel',
            command: () => {
              this.routingService.switchToPath('/streamer/contest');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerNewsMenu, icon: 'news',
            command: () => {
              this.routingService.switchToPath('/streamer/benefits');
              this.globalService.menuIsOpen = false;
            }
          }
        ]
      } else {
        this.items = [
          {
            label: this.i18nService.localize().MenuComponent.StreamerDashboardMenu, icon: 'dashboard',
            command: () => {
              this.routingService.switchToPath('/streamer/home');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerIncomeMenu, icon: 'einnahmen',
            command: () => {
              this.routingService.switchToPath('/streamer/income');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerCampaignsMenu, icon: 'display-streamer',
            command: () => {
              this.routingService.switchToPath('/streamer/campaigns');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerAffiliateMenu, icon: 'klick-streamer',
            command: () => {
              this.routingService.switchToPath('/streamer/affiliates');
              this.globalService.menuIsOpen = false;
            }
          },
          {
            label: this.i18nService.localize().MenuComponent.StreamerNewsMenu, icon: 'news',
            command: () => {
              this.routingService.switchToPath('/streamer/benefits');
              this.globalService.menuIsOpen = false;
            }
          }
        ]
      }

    }
  }

  fillMenuAgency() {
    this.items = [
      {
        label: this.i18nService.localize().MenuComponent.AdvertiserDashboardMenu, icon: 'dashboard',
        command: () => {
          this.routingService.switchToPath('/agency/dashboard');
          this.globalService.menuIsOpen = false;
        }
      },
      {
        label: this.i18nService.localize().MenuComponent.AgencyAdvertisersMenu, icon: 'displaykampagne',
        command: () => {
          this.routingService.switchToPath('/agency/advertisers');
          this.globalService.menuIsOpen = false;
        }
      },
      {
        label: this.i18nService.localize().MenuComponent.AdvertiserProfilMenu, icon: 'profil',
        command: () => {
          this.routingService.switchToPath('/agency/profile');
          this.globalService.menuIsOpen = false;
        }
      }
    ]

    if (this.globalService.agency!.roles.includes("ADMIN")) {
      this.items.push(
        {
          label: this.i18nService.localize().MenuComponent.AgencyUsersMenu, icon: 'nutzer',
          command: () => {
            this.routingService.switchToPath('/agency/users');
            this.globalService.menuIsOpen = false;
          }
        }
      )
    }

    if(this.globalService.agency!.roles.includes("PICTURES")){
      this.items.push(
        {
          label: this.i18nService.localize().MenuComponent.AgencyPictureMenu,
          icon: 'bilder',
          command: () => {
            this.routingService.switchToPath('/agency/pictures');
          }
        }
      )
    }

  }

  changeLang(newLang: string) {
    this.i18nService.changeLang(newLang);
  }

  isLang(lang: string): boolean {
    return lang === this.i18nService.getCurrentLang();
  }


  logout() {
    if (this.globalService.streamer || this.globalService.advertiser) {
      if (this.globalService.advertiser) {
        this.advertiserService.logout(this.globalService.advertiser.rememberLogin).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else if (this.globalService.streamer) {
        this.streamerService.logout().then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      } else {
        this.agencyService.logout(false).then(() => {
          setTimeout(() => {
            window.location.href = this.globalService.links.siteURL;
          }, 100);
        });
      }
    }
  }

  updateNotification() {
    this.notificationService.getNotification().then(result => {
      this.notificationText = result;
    })
  }

}
