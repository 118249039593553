<div *ngIf="this.globalService.streamer">
  <div class="headline">{{this.i18nService.localize().StreamerIncomeComponent.IncomeHeader}}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12 p-xl-12 p-xxl-10 start">
        <p-card class="noPadding" [style]="{'height':'100%'}">
          <div class="p-grid p-nogutter">

            <div class="p-col-12 p-xl-2 noPadding">
              <a class="tableSideButton" (click)="activeStatistik = 1">
                <div class="sideTable p-col-12 radiusTopLeft {{activeStatistik == 1 ? 'activeStatistik' : ''}}">
                  {{this.i18nService.localize().StreamerIncomeComponent.Affiliates}}
                </div>
              </a>

              <a class="tableSideButton" (click)="activeStatistik = 2">
                <div class="sideTable p-col-12 {{activeStatistik == 2 ? 'activeStatistik' : ''}}">
                  {{this.i18nService.localize().StreamerIncomeComponent.DisplayCampaigns}}
                </div>
              </a>

              <a class="tableSideButton" (click)="activeStatistik = 3">
                <div class="sideTable p-col-12 {{activeStatistik == 3 ? 'activeStatistik' : ''}}">
                  {{this.i18nService.localize().StreamerIncomeComponent.ClickCampaigns}}
                </div>
              </a>

              <a class="tableSideButton" (click)="activeStatistik = 4">
                <div class="sideTable p-col-12 radiusBottomLeft {{activeStatistik == 4 ? 'activeStatistik' : ''}}">
                  {{this.i18nService.localize().StreamerIncomeComponent.TotalSales}}
                </div>
              </a>
            </div>

            <div class="p-col-12 p-xl-10">
              <div class="p-grid p-nogutter" *ngIf="activeStatistik===1">
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Klicks.svg" alt="Clicks_icon" height="65px"><br>
                    {{this.globalService.streamer.externalAffiliateClicks}}<br>{{this.i18nService.localize().StreamerIncomeComponent.Clicks}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_verkaeufe.svg" alt="Sales_icon" height="65px"><br>
                    {{this.globalService.streamer.getSalesSinceLastPaymentString()}}<br>{{this.i18nService.localize().StreamerIncomeComponent.Sales}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Conv-Rate.svg" alt="Conv_icon" height="65px"><br>
                    Conversion Rate <br>
                    {{this.globalService.streamer.totalConvRate}}% {{this.i18nService.localize().StreamerIncomeComponent.Total}}<br>
                    {{this.globalService.streamer.currentConvRate}}% {{this.i18nService.localize().StreamerIncomeComponent.Latest}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic lastStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_umsatz.svg" alt="Payment_icon"><br>
                    {{this.globalService.streamer.getEarningsFromSalesSinceLastPayment()}} €<br>{{this.i18nService.localize().StreamerIncomeComponent.Revenue}}
                  </div>
                </div>
              </div>
              <div class="p-grid p-nogutter" *ngIf="activeStatistik===2">
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Views.svg" alt="Views_icon" style="margin-bottom: 0.6em;"><br>
                    {{this.globalService.streamer.getViewsSinceLastPaymentString()}}<br>{{this.i18nService.localize().StreamerIncomeComponent.Views}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_tkp.svg" alt="Income_icon"><br>
                    {{this.globalService.streamer.getTKPString()}}<br>TKP
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Zeitraum.svg" alt="Time_icon"><br>
                    {{this.i18nService.localize().StreamerIncomeComponent.Since}}<br>{{this.globalService.streamer.getLastPaymentDateString()}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic lastStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_umsatz.svg" alt="Payment_icon"><br>
                    {{this.globalService.streamer.getEarningsFromViewsSinceLastPayment()}} €<br>{{this.i18nService.localize().StreamerIncomeComponent.Revenue}}
                  </div>
                </div>
              </div>
              <div class="p-grid p-nogutter" *ngIf="activeStatistik===3">
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Klicks.svg" alt="Clicks_icon" height="65px"><br>
                    {{this.globalService.streamer.getClicksSinceLastPaymentString()}}<br>{{this.i18nService.localize().StreamerIncomeComponent.Clicks}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_tkp.svg" alt="tkp_icon"><br>
                    0,1<br>CPC
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Zeitraum.svg" alt="Time_icon"><br>
                    {{this.i18nService.localize().StreamerIncomeComponent.Since}}<br>{{this.globalService.streamer.getLastPaymentDateString()}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic lastStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_umsatz.svg" alt="Payment_icon"><br>
                    {{this.globalService.streamer.getEarningsFromClicksSinceLastPayment()}} €<br>{{this.i18nService.localize().StreamerIncomeComponent.Revenue}}
                  </div>
                  <span style="font-size: 8.5pt;">
                    {{this.i18nService.localize().StreamerIncomeComponent.EndOfMonthOnly}}
                  </span>
                </div>
              </div>
              <div class="p-grid p-nogutter" *ngIf="activeStatistik===4">
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Klicks.svg" alt="Clicks_icon" height="65px"><br>
                    {{this.globalService.streamer.getEarningsFromClicksSinceLastPayment()}} €<br>
                    {{this.i18nService.localize().StreamerIncomeComponent.ClickCampaigns}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_verkaeufe.svg" alt="Sales_icon" height="65px"><br>
                    {{this.globalService.streamer.getEarningsFromSalesSinceLastPayment()}} €<br>
                    {{this.i18nService.localize().StreamerIncomeComponent.Affiliates}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_Ads.svg" alt="Ads_icon" height="65px"><br>
                    {{this.globalService.streamer.getEarningsFromViewsSinceLastPayment()}} €<br>
                    {{this.i18nService.localize().StreamerIncomeComponent.DisplayCampaigns}}
                  </div>
                </div>
                <div class="p-col-12 p-md-6 p-xl-3 noPadding middleStatistic lastStatistic">
                  <div class="StatisticText">
                    <img src="/assets/images/Icons/Einnahmen_umsatz.svg" alt="Payment_icon"><br>
                    {{this.globalService.streamer.getEarningsSinceLastPayment()}} €<br>{{this.i18nService.localize().StreamerIncomeComponent.TotalSales}}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-xl-6 p-xxl-2 start" style="word-break: break-all;">
        <p-card [style]="{'height':'100%'}">
          <p *ngIf="this.globalService.streamer.hasEverythingNeededForPayment() && this.globalService.streamer.isEnoughEarningsForPayment()"
             style="margin: 1em;word-break: break-word;">
            {{this.i18nService.localize().StreamerIncomeComponent.ReadyPaymentHint1}}
            <b>{{this.i18nService.localize().StreamerIncomeComponent.Now}}</b>
            {{this.i18nService.localize().StreamerIncomeComponent.ReadyPaymentHint2}}
          </p>
          <p *ngIf="!this.globalService.streamer.isEnoughEarningsForPayment()" style="margin: 1em;word-break: break-word;">
            {{this.i18nService.localize().StreamerIncomeComponent.LowPaymentHint1}}
            <b>10 €</b>
            {{this.i18nService.localize().StreamerIncomeComponent.LowPaymentHint2}}<br><br>
            {{this.i18nService.localize().StreamerIncomeComponent.LowPaymentHint3}}
          </p>
          <p *ngIf="!this.globalService.streamer.hasEverythingNeededForPayment()&&this.globalService.streamer.isEnoughEarningsForPayment()"
            style="margin: 1em;word-break: break-word;">
            {{this.i18nService.localize().StreamerIncomeComponent.FillPaymentHint}}
          </p>
          <p-footer>
            <div style="text-align: left; margin-left: 1em;">
              <button pButton pRipple class="p-button-rounded"
                [disabled]="!(this.globalService.streamer.isEnoughEarningsForPayment() && this.globalService.streamer.hasEverythingNeededForPayment())"
                type="button" label="{{this.i18nService.localize().StreamerIncomeComponent.PayoutButton}}" (click)="requestPayment()">
              </button>
            </div>
          </p-footer>
        </p-card>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerIncomeComponent.StreamsHeader}}</h2>
      </div>

      <div class="p-col-12 abstand">
        <p-table [value]="streams" [rows]="10" [lazy]="true" (onLazyLoad)="updateStreams($event)" [paginator]="true"
                 [totalRecords]="totalRecordsStreams" [loading]="loadingStreams" sortMode="single" sortField="start_date"
                 [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()" *ngIf="(streams.length>0)"
                 responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="p-text-center tableTopLeft" pSortableColumn="start_date" style="width:150px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableStartDate}}
                <p-sortIcon field="start_date"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="end_date" style="width:150px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableEndDate}}
                <p-sortIcon field="end_date"></p-sortIcon>
              </th>
              <th class="p-text-center" style="width:150px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableDuration}}
              </th>
              <th class="p-text-center" pSortableColumn="network" style="width:100px">
                {{this.i18nService.localize().StreamerIncomeComponent.TablePlatform}}
                <p-sortIcon field="network"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="views_accounted" style="width:150px">
                {{this.i18nService.localize().StreamerIncomeComponent.Views}}
                <p-sortIcon field="views_accounted"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="earnings" style="width:150px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableCredit}}
                <p-sortIcon field="earnings"></p-sortIcon>
              </th>
              <th class="p-text-center tableTopRight" style="width:200px">
                Status
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-stream>
            <tr>
              <td>{{stream.startDate}}</td>
              <td>{{stream.endDate}}</td>
              <td>{{stream.duration}}</td>
              <td>{{stream.getNetwork()}}</td>
              <td>{{stream.views}}</td>
              <td>{{stream.earnings}}</td>
              <td>{{stream.getStatus()}}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="!(streams.length>0)">
           <p>{{this.i18nService.localize().StreamerIncomeComponent.NoStreamsFound}}</p>
        </div>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerIncomeComponent.ClicksSalesHeader}}</h2>
      </div>

      <div class="p-col-12">
        <p-table [value]="clicks" [rows]="10" [lazy]="true" (onLazyLoad)="updateClickSales($event)" [paginator]="true"
                 [totalRecords]="totalRecordsClicks" [loading]="loadingClicks" sortMode="single" sortField="invoice_date"
                 [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()" *ngIf="(clicks.length>0)"
                 responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="p-text-center tableTopLeft" pSortableColumn="invoice_date" style="width:180px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableInvoiceDate}}
                <p-sortIcon field="invoice_date"></p-sortIcon>
              </th>
              <th class="p-text-center" style="width:350px">
                Affiliate Name
              </th>
              <th class="p-text-center" pSortableColumn="total" style="width:200px">
                 {{this.i18nService.localize().StreamerIncomeComponent.Clicks}}
                <p-sortIcon field="total"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="earnings" style="width:200px">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableCredit}}
                <p-sortIcon field="earnings"></p-sortIcon>
              </th>
              <th class="p-text-center tableTopRight" style="width:200px">
                Status
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-clickSales>
            <tr [ngClass]="clickSales.preliminary?'in_progress':'other'">
              <td>{{clickSales.invoiceDate}}</td>
              <td>{{clickSales.affiliateName}}</td>
              <td>{{clickSales.clicksSales}}</td>
              <td>{{clickSales.earnings}}</td>
              <td>{{clickSales.getStatus()}}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="!(clicks.length>0)">
           <p>{{this.i18nService.localize().StreamerIncomeComponent.NoClicksFound}}</p>
        </div>
      </div>

      <div class="p-col-12">
        <h2 class="card-headline">{{this.i18nService.localize().StreamerIncomeComponent.BillsHeader}}</h2>
      </div>

      <div class="p-col-12">
        <p-table [value]="streamerBills" [rows]="10" [lazy]="true" (onLazyLoad)="updateBills($event)" [paginator]="true"
                 [totalRecords]="totalRecordsBills" [loading]="loadingBills" sortMode="single" sortField="bill_date"
                 [defaultSortOrder]="getDefaultSortOrder()" [sortOrder]="getDefaultSortOrder()"
                 *ngIf="(streamerBills.length>0)" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="p-text-center tableTopLeft" pSortableColumn="bill_nr">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableBillNr}}
                <p-sortIcon field="bill_nr"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="bill_date">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableBillDate}}
                <p-sortIcon field="bill_date"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="views">
                {{this.i18nService.localize().StreamerIncomeComponent.Views}}
                <p-sortIcon field="views"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="clicks_and_sales">
                 {{this.i18nService.localize().StreamerIncomeComponent.Clicks}}
                <p-sortIcon field="clicks_and_sales"></p-sortIcon>
              </th>
              <th class="p-text-center" pSortableColumn="earnings">
                 {{this.i18nService.localize().StreamerIncomeComponent.TableCredit}}
                <p-sortIcon field="earnings"></p-sortIcon>
              </th>
              <th class="p-text-center" style="width:200px">
                Status
              </th>
              <th style="width:100px" class="p-text-center tableTopRight">
                {{i18nService.localize().AdvertiserBillsComponent.TableDownload}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-bill>
            <tr>
              <td style="word-break: break-all">{{bill.billNr}}</td>
              <td>{{bill.billDate}}</td>
              <td>{{bill.views}}</td>
              <td>{{bill.totalClicks}}</td>
              <td>{{bill.earnings}}</td>
              <td>{{bill.getStatus()}}</td>
              <td>
                <button pButton pRipple type="button" class="p-button-rounded"
                  (click)="downloadPDF(bill.id)" icon="fas fa-download"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="!(streamerBills.length>0)">
           <p>{{this.i18nService.localize().StreamerIncomeComponent.NoBillsFound}}</p>
        </div>
      </div>

    </div>

  </div>
</div>
