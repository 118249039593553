import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {IPayPalConfig} from 'ngx-paypal';
import * as moment from 'moment/moment';
import {GlobalService} from '../services/global.service';
import {I18nService} from '../services/i18n.service';
import {RoutingService} from '../services/routing.service';
import {CampaignService} from '../services/campaign.service';
import {AgencyService} from '../services/agency.service';
import {KeywordService} from '../services/keyword.service';
import {PictureService} from '../services/picture.service';
import {MathHelper} from '../services/math.helper';
import {AdvertiserService} from '../services/advertiser.service';
import {IpService} from '../services/ip.service';
import {DiscountService} from '../services/discount.service';
import {AgencyPicture} from '../model/agency/agency-picture.model';
import {DiscountCode} from '../model/advertiser/discount-code.model';
import {WizardWIP} from '../model/wizard-wip.model';
import {Keyword} from "../model/streamer/keyword.model";
import {PaymentService} from "../services/paymentService";
import {Message} from "primeng/api";

@Component({
  selector: 'campaign-wizard',
  templateUrl: './campaign-wizard.component.html',
  styleUrls: ['./campaign-wizard.component.scss']
})
export class CampaignWizardComponent implements OnInit {

  public googleTagsEnabled: boolean = false;

  // Aktuelle Schritt-Nummer
  public step: number = 0;
  public working = false;
  public createCampaignError = false;
  public status?: string;

  public advertiserId!: string;

  // Kampagnen Fortschritt
  private campaignToPay!: string;
  private wizardWIPId!: string;
  public showContinueWizard: boolean = false;
  public wizardWIP?: WizardWIP;

  // Help Panel
  public showHelpPanel: boolean = false;

  // Seite 1: Zielauswahl
  public selectedTarget: number = 1;
  public displayClickPercent: number = 100;
  public disablePercentSlider: boolean = true;

  // Seite 2: KPIs
  public campaignName!: string;
  public displayName!: string;
  public language: string = 'GERMAN';
  public keywordgroups: any[] = [];

  public selectedKeywordItems: string[] = [];
  public selectedKeywords: any[] = [];
  public smarty: boolean = true;
  public openMoreOptions: boolean = false;
  public intensityFocus = 'smart';
  public streamerFocus = 'smart';
  public selectedGender: string[] = ['male', 'female', 'divers'];
  public ageRangeValues: number[] = [18, 35];
  public showSlider = true;
  public streamerAgeRangeValues: number[] = [18, 35];
  public showStreamerSlider = true;
  public activeStreamerAge: boolean = false;

  public gameKeywords: any[] = [];

  public chattingGameKeyword: any;
  public musicGameKeyword: any;
  public actionGameKeyword: any;
  public roleplayGameKeyword: any;
  public sportsGameKeyword: any;
  public over18GameKeyword: any;
  public over18Content: string = 'no';

  public selectedGameKeywordBooleans: boolean[] = [];
  public selectedGameKeywordItems: string[] = [];
  public selectedGameKeywordNames: string[] = [];
  public selectedGameKeywordNamesError: boolean = false;
  public selectedNonGameKeywordItems: string[] = [];
  public selectedNonGameKeywordNames: string[] = [];

  // Seite 3: Anzeige
  public showDisplayBannerPanel: boolean = true;
  public showClickBannerPanel: boolean = false;
  public description: string = '';
  public letterCount: number = 500;

  public picturesOk = true;
  public uploadedPictures: any[] = [];
  public uploadedPicturesBackup: any[] = [];
  public bannerContainsAnimatedPicture = false;
  private doublePriceImageType = 'image/gif';
  private imageTypesArray: any[] = [];
  public campaignPixOk: boolean = true;

  public availablePicturesForDisplay: AgencyPicture[] = [];
  public usedPicturesForDisplay: AgencyPicture[] = [];

  public affiliateLink!: string;
  public wrongURL = false;
  public chatMessageEmpty: boolean = false;
  public affiliateLinkOk: boolean = true;

  public uploadedBanners: any[] = [];
  public uploadedBannersBackup: any[] = [];
  public bannersOk = true;
  public affiliatePixOk: boolean = true;
  public imageLimitError: boolean = false;

  public chatMessage: string = '';
  public chatMessageContainsLinkError: boolean = false;
  public chatLetterCount: number = 150;
  public googleTagId!: string;

  public availablePicturesForClick: AgencyPicture[] = [];
  public usedPicturesForClick: AgencyPicture[] = [];

  // Seite 4: Budgetplanung
  public budgetSelect: string = 'total';
  public dailyBudget: number = 0;
  public dailyBudgetString: string = '0';
  public disableDailyBudget: boolean = true;
  public totalBudget: number = 0;
  public discountlessBudget: number = 0;  // hieraus berechnet sich totalBudget
  public totalBudgetRaw: number = 0;
  public totalBudgetString: string = '0';
  public disableTotalBudget: boolean = false;
  public totalBudgetWithTaxForPaypal: string = '0';
  public totalBudgetWithTaxString: string = '0';
  public availableAdBudgetString: string = '0';
  public totalBudgetWithTax: number = 0;

  public current: Date = new Date();
  public minDate: Date = new Date(this.current.getTime() + 259200000);
  public startDate!: Date;
  public endDate!: Date;
  public daysBetween: number = 0;
  public tooFewDaysError: boolean = false;

  public totalDisplayBudgetRaw: number = 0;
  public totalDisplayBudget: number = 0;
  public totalDisplayBudgetString: string = '0';
  public totalDisplayBudgetWithTax: number = 0;
  public totalClickBudgetRaw: number = 0;
  public totalClickBudget: number = 0;
  public totalClickBudgetString: string = '0';
  public totalClickBudgetWithTax: number = 0;

  public views: number = 0;
  public dailyViews: number = 0;
  public clicks: number = 0;
  public dailyClicks: number = 0;

  // Seite 5: Überprüfung
  public codeString!: string;
  public discountCode?: DiscountCode;
  public noDiscountError: boolean = false;
  public discountExpired: boolean = false;

  public paypalPay = false;
  public isBalancePay = true;
  public payPalConfig?: IPayPalConfig;
  public paymentIndex: string = '2';
  public paypalPaymentDone = false;
  public paymentError: boolean = false;
  public paymentSuccess!: boolean;
  public showPaymentInfoPage: boolean = false;

  public baseBalance: number = 0;
  public remainingBaseBalance: number = 0;
  public isBalanceEnabled: boolean = false;
  public isBalanceEnough: boolean = false;
  public isBalancePaymentError: boolean = false;
  public isBalanceDateError: boolean = false;

  public chooseAddress: string = 'agency';

  public billNr: string = '';

  // Bonus Seite: Advertiser Registrierung abschließen
  public showRegister: boolean = false;
  public registerSent: boolean = false;
  public website!: string;
  public taxNumber!: string;
  public street!: string;
  public nr!: string;
  public postalCode!: string;
  public city!: string;
  public country: any;
  public countriesDropDownList: any [] = [];
  public firstname!: string;
  public lastname!: string;
  public telephone!: string;

  public messages: Message[] = [];

  constructor(public globalService: GlobalService, public i18nService: I18nService,
              public route: ActivatedRoute, public campaignService: CampaignService,
              public agencyService: AgencyService, public routingService: RoutingService,
              public keywordService: KeywordService, public pictureService: PictureService,
              public mathHelper: MathHelper, public sanitizer: DomSanitizer, public router: Router,
              private advertiserService: AdvertiserService, private ipService: IpService,
              private discountService: DiscountService, private paymentService: PaymentService) {
  }

  async ngOnInit() {
    if (this.globalService.advertiser) {
      this.advertiserId = this.globalService.advertiser.id;
      if (this.globalService.advertiser.country && this.globalService.advertiser.country !== '') {
        this.country = {
          item_id: this.globalService.advertiser.countryId,
          item_text: this.globalService.advertiser.country
        };
      }
    } else if (this.globalService.agency) {
      this.advertiserId = this.route.snapshot.params.advertiserId;
      if (!this.advertiserId) {
        this.advertiserId = this.route.snapshot.queryParams['advertiserId'];
      }

      if (this.agencyService.agency) {
        this.isBalanceEnabled = this.agencyService.agency.is_balance_enabled;
        this.availableAdBudgetString = this.mathHelper.numberWithCommas(this.agencyService.agency.balance);
        if (this.isBalanceEnabled) {
          this.baseBalance = this.agencyService.agency.balance;
          this.remainingBaseBalance = this.baseBalance;
          const today = new Date();
          this.isBalanceDateError = new Date(this.agencyService.agency.balance_valid_until) <= today;
        }
      }

      this.agencyService.checkAdvertiserAffiliation(this.advertiserId).then(result => {
        if (!result) {
          this.routingService.switchToPath('/agency/advertisers');
        }
      });
    }

    // Keywordgroups holen und füllen
    this.keywordService.getAllAdKeywordgroups().then((keywordgroups) => {
      this.keywordgroups = [];
      for (let j = 0; j < keywordgroups.length; j++) {
        const id = keywordgroups[j].id;
        const text = keywordgroups[j].getValueInLanguage('name', this.i18nService.getCurrentLang());
        const group: Keyword[] = keywordgroups[j].group;

        const keywords: any[] = [];
        for (let i = 0; i < group.length; i++) {
          const keyword: any = group[i];
          const keywordType: any = {};
          keywordType.item_id = keyword.id;
          keywordType.item_text = keyword[this.i18nService.getCurrentLang().toLowerCase()].name;
          keywordType.cpc = keyword.cpc;
          keywords.push(keywordType);
        }

        this.keywordgroups.push({item_id: id, item_text: text, item_group: keywords});
      }
    }).catch((err) => {
      console.log(err);
    });

    this.keywordService.getAllGameKeywords().then((keywords) => {
      this.gameKeywords = [];
      for (let j = 0; j < keywords.length; j++) {
        const id = keywords[j].id;
        const text = keywords[j].getValueInLanguage('word', this.i18nService.getCurrentLang());

        // Einfache Auswahl wird vorher entfernt und gesondert gespeichert
        if (id === '629a1ff2f18c6221ab0af770') { // 18+
          this.over18GameKeyword = {item_id: id, item_text: text};

          // 18 Content ist standartmäßig als Kategorie erlaubt
          // this.updateGameKeywordNames({value: true}, this.over18GameKeyword);
        } else if (id === '57a31fb78f30f31ce41c038k') { // Just Chatting
          this.chattingGameKeyword = {item_id: id, item_text: text};
        } else if (id === '57a31fb78f30f31ce41c038j') { // Musik
          this.musicGameKeyword = {item_id: id, item_text: text};
        } else if (id === '57a31fb78f30f31ce41c038l') { // Action und Abenteuer
          this.actionGameKeyword = {item_id: id, item_text: text};
        } else if (id === '57a31fb78f30f31ce41c038g') { // Rollenspiele
          this.roleplayGameKeyword = {item_id: id, item_text: text};
        } else if (id === '628b5fb63930322a1f546aa5') { // Sports
          this.sportsGameKeyword = {item_id: id, item_text: text};
        } else { // Alle anderen kommen in die Liste
          this.gameKeywords.push({item_id: id, item_text: text});
        }
      }
    }).catch((err) => {
      console.log(err);
    });

    // Länder für die Registrierung
    this.keywordService.getAllCountries().then((countries) => {
      this.countriesDropDownList = [];
      countries.forEach((country) => {
        this.countriesDropDownList.push(
          {
            // @ts-ignore
            item_id: country.id,
            // @ts-ignore
            item_text: country.getValueInLanguage('name', this.i18nService.getCurrentLang())
          });
      });
    }).catch((err) => {
      console.log(err);
    });

    this.globalService.updateEnableGoogleTags().then(enabled => {
      this.googleTagsEnabled = enabled;
    });

    this.campaignToPay = this.route.snapshot.queryParams['campaignId'];
    await this.campaignService.findWizardWIP(this.advertiserId, this.campaignToPay).then((result => {
      if (result) {
        this.wizardWIP = result;
        if (this.wizardWIP) {
          this.wizardWIPId = this.wizardWIP.id;
          if (this.wizardWIP.status) {
            this.status = this.wizardWIP.status;
            this.messages.push({severity: 'error', summary: 'Error', detail: this.wizardWIP.rejectedReason});
            this.continueCreation()

            const sessionId = this.route.snapshot.queryParams['session_id'];
            if (sessionId) {
              this.paymentService.fulfillPayment(sessionId, () => {
                this.create(sessionId);
                this.paymentSuccess = true;
              });
              this.showPaymentInfoPage = true;
            }
          } else {
            if (this.globalService.agency) {

              // Kampagnen Bilder holen
              this.getCampaignAgencyPictures().then(after => {

                // Laufenden Kampagnen Erstellungsfortschritt holen
                this.campaignService.findWizardWIP(this.advertiserId).then((result => {
                  if (result) {
                    this.wizardWIP = result;
                    if (this.wizardWIP) {
                      this.wizardWIPId = this.wizardWIP.id;
                      this.showContinueWizard = true;
                    }
                  }
                }));
              });
            } else {
              this.showContinueWizard = true;
            }
          }
        }
      }
    }));

    this.generateBillNumber();

    this.googleTagId = this.generateTagId(28);
  }

  getCampaignAgencyPictures(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.globalService.agency) {
        this.pictureService.getAgencyPicturesForCampaigns().then(result => {
          this.availablePicturesForDisplay = result;

          this.pictureService.getAgencyPicturesForAffiliates().then(result => {
            this.availablePicturesForClick = result;

            resolve('');
          }).catch(error => {
            console.log(error);
            reject(error);
          });
        }).catch(error => {
          console.log(error);
          reject(error);
        });

      } else {
        reject();
      }
    });
  }

  generateBillNumber() {
    if (this.globalService.advertiser || this.globalService.agency) {

      this.advertiserService.getNextBillNumber().then(result => {
        let today = new Date();
        if (this.globalService.advertiser) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.advertiser.advertiserNr + '-' + result;
        } else if (this.globalService.agency) {
          this.billNr = 'A-' + today.getFullYear() + '-' + this.globalService.agency.agencyNr + '-' + result;
        }
      });
    }
  }

  generateTagId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  goToNextStep() {
    this.createCampaignError = false;

    if (this.step === 2) {
      if (this.getNumberOfDisplayBanners() > 3 || this.getNumberOfClickBanners() > 3) {
        this.createCampaignError = true;
        this.imageLimitError = true;
        return;
      } else if (this.chatMessage.length === 0 && !this.isDisplayCampaignOnly()) {
        this.createCampaignError = true;
        this.chatMessageEmpty = true;
        return;
      }
    } else {
      this.imageLimitError = false;
      this.chatMessageEmpty = false;
    }

    if (this.validateSite()) {
      this.createCampaignError = false;
      if (this.step === 0) {
        this.step = 1;
      } else if (this.step === 1) {
        if (this.selectedGameKeywordNames.length === 0 || (this.selectedGameKeywordNames.length === 1 && this.selectedGameKeywordNames[0] === "18+")) {
          this.selectedGameKeywordNamesError = true;
        } else {
          this.selectedGameKeywordNamesError = false;
          this.step = 2;
        }
      } else if (this.step === 2) {
        this.resetPicturesOfWIP().then(() => {
          this.updateSavePicturesForWIP();
        });
        this.step = 3;
      } else if (this.step === 3) {
        if (this.globalService.advertiser) {

          // Wenn der Advertiser noch nicht alle Infos angegeben hat, um eine Rechnung erstellen zu können
          if (this.globalService.advertiser.needsMoreInformation()) {
            this.showRegister = true;
          } else {
            this.step = 4;
          }
        } else {
          this.step = 4;
        }
      }

      this.updateWorkInProgress();
    } else {
      this.createCampaignError = true;
    }
  }

  checkImageLimit() {
    this.imageLimitError = this.uploadedPicturesBackup.length > 3;
  }

  goToPreviousStep() {
    if (this.step === 1) {
      this.step = 0;
    } else if (this.step === 2) {
      this.step = 1;
    } else if (this.step === 3) {
      this.step = 2;
    } else if (this.step === 4) {
      this.removeDiscountCode();
      this.step = 3;
    }
  }

  cancel() {
    if (this.globalService.advertiser) {
      this.routingService.switchToPath('/advertiser/campaigns');
    } else if (this.globalService.agency) {
      this.routingService.switchToPath('/agency/advertisers', {advertiserId: this.advertiserId})
    }
  }

  validateSite() {
    if (this.step === 1) {
      return (this.campaignName !== undefined && this.campaignName.length > 0) && (this.displayName !== undefined && this.displayName.length > 0) && this.selectedKeywordItems.length !== 0 &&
        this.selectedGender.length !== 0;
    } else if (this.step === 2) {

      if (this.isCampaignMix() || this.isDisplayCampaignOnly()) {
        if (this.globalService.advertiser) {
          this.campaignPixOk = this.uploadedPicturesBackup.length !== 0;
        } else if (this.globalService.agency) {
          this.campaignPixOk = (this.uploadedPicturesBackup.length !== 0 || this.usedPicturesForDisplay.length !== 0)
            && (this.uploadedPicturesBackup.length + this.usedPicturesForDisplay.length) <= 3;
        }
      }

      if (this.isCampaignMix() || this.isClickCampaignOnly()) {
        if (this.globalService.advertiser) {
          this.affiliatePixOk = this.uploadedBannersBackup.length !== 0;
        } else if (this.globalService.agency) {
          this.affiliatePixOk = (this.uploadedBannersBackup.length !== 0 || this.usedPicturesForClick.length !== 0)
            && (this.uploadedBannersBackup.length + this.usedPicturesForClick.length) <= 3;
        }

        this.affiliateLinkOk = this.affiliateLink !== undefined && this.affiliateLink.length > 0;
      }

      return (this.description !== undefined && this.description.length > 0) && this.campaignPixOk && this.affiliatePixOk &&
        this.affiliateLinkOk;
    } else if (this.step === 3) {
      return this.totalBudget > 0 && this.dailyBudget > 0 && this.startDate !== undefined && this.endDate !== undefined &&
        this.daysBetween >= 2 && !this.tooFewDaysError;
    } else {
      return true;
    }
  }

  submitForPaymentReview() {
    if (this.campaignToPay) {
      this.wizardWIPId = this.campaignToPay;
    }

    this.campaignService.submitForPaymentReview(this.advertiserId, this.wizardWIPId).then(
      () => {
        if (this.globalService.agency) {
          this.routingService.switchToPathWithReload('/agency/advertisers');
        } else {
          this.routingService.switchToPathWithReload('/advertiser/campaigns');
        }
      }
    );
  }

  // Kampagnen Fortschritt
  updateWorkInProgress() {
    let agencyPictureIdsForDisplay: string[] = [];
    let agencyPictureIdsForClick: string[] = [];

    // Agentur Bilder Id sammeln
    if (this.globalService.agency) {
      for (let i = 0; i < this.usedPicturesForDisplay.length; i++) {
        agencyPictureIdsForDisplay.push(this.usedPicturesForDisplay[i].id);
      }

      for (let i = 0; i < this.usedPicturesForClick.length; i++) {
        agencyPictureIdsForClick.push(this.usedPicturesForClick[i].id);
      }
    }

    // Dem Backend sagen, was erstellt werden muss
    const createDisplayCampaign: boolean = this.isDisplayCampaignOnly() || this.isCampaignMix();
    const createClickCampaign: boolean = this.isClickCampaignOnly() || this.isCampaignMix();

    if (this.campaignToPay) {
      this.wizardWIPId = this.campaignToPay;
    }

    this.campaignService.updateWizardWIP(createDisplayCampaign, createClickCampaign, this.selectedTarget,
      this.displayClickPercent, this.campaignName, this.displayName, this.language, this.selectedKeywordItems, this.intensityFocus,
      this.streamerFocus, this.selectedGender, this.ageRangeValues, this.streamerAgeRangeValues, this.selectedGameKeywordItems,
      this.selectedNonGameKeywordItems, this.description, this.uploadedPicturesBackup, agencyPictureIdsForDisplay,
      this.affiliateLink, this.uploadedBannersBackup, agencyPictureIdsForClick, this.chatMessage, this.activeStreamerAge,
      this.totalBudget, this.dailyBudget, this.views, this.clicks, this.dailyViews, this.dailyClicks, this.startDate,
      this.endDate, this.getTKP(), this.bannerContainsAnimatedPicture, this.step, this.advertiserId, this.wizardWIPId).then(res => {
      this.wizardWIPId = res;
      if (this.campaignToPay) {
        this.switchToCampaignsWithReload()
      }
    }).catch((err: any) => {
      console.log(err);
    });
  }

  switchToCampaignsWithReload() {
    if (this.globalService.agency) {
      this.routingService.switchToPathWithReload('agency/advertisers');
    } else {
      this.routingService.switchToPathWithReload('/advertiser/campaigns');
    }
  }

  updateSavePicturesForWIP() {
    const uploadPromises: any[] = [];

    if (this.isCampaignMix() || this.isDisplayCampaignOnly()) {
      uploadPromises.push(this.pictureService.uploadImagesHttp(this.uploadedPicturesBackup, this.wizardWIPId, 'BANNER_WIP', 'wizardwip'));
    }

    if (this.isCampaignMix() || this.isClickCampaignOnly()) {
      uploadPromises.push(this.pictureService.uploadImagesHttp(this.uploadedBannersBackup, this.wizardWIPId, 'AFFILIATE_BANNER_WIP', 'wizardwip'));
    }

    Promise.all(uploadPromises).then((values) => {
      // Uploads abgeschlossen
    }).catch((error) => {
      console.log(error);
    });
  }

  resetPicturesOfWIP(): Promise<void> {
    return new Promise((resolve, reject) => {
      const resetDisplayPics = this.isCampaignMix() || this.isDisplayCampaignOnly();
      const resetClickPics = this.isCampaignMix() || this.isClickCampaignOnly();

      this.pictureService.resetWizardWIPPictures(this.wizardWIPId, resetDisplayPics, resetClickPics).then(() => {
        resolve();
      }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  }

  continueCreation() {
    if (this.wizardWIP) {

      // Navigation
      this.step = this.wizardWIP.step;
      this.selectedTarget = this.wizardWIP.selectedTarget;
      this.displayClickPercent = this.wizardWIP.displayClickPercent;

      if (this.selectedTarget === 4) {
        this.disablePercentSlider = false;
      }

      // Reguläre Angaben
      this.campaignName = this.wizardWIP.campaignName;
      this.displayName = this.wizardWIP.displayName;
      this.intensityFocus = this.wizardWIP.intensityFocus;
      this.streamerFocus = this.wizardWIP.streamerFocus;

      if (this.intensityFocus !== 'smart' && this.streamerFocus !== 'smart') {
        this.openMoreOptions = true;
        this.smarty = false;
      } else {
        this.openMoreOptions = false;
        this.smarty = true;
      }

      if (this.wizardWIP.streamerAgeFrom && this.wizardWIP.streamerAgeTo) {
        this.streamerAgeRangeValues = [];
        this.streamerAgeRangeValues.push(this.wizardWIP.streamerAgeFrom);
        this.streamerAgeRangeValues.push(this.wizardWIP.streamerAgeTo);
      }
      this.activeStreamerAge = this.wizardWIP.activateStreamerAge;

      this.language = this.wizardWIP.language;
      this.selectedGender = this.wizardWIP.targetGender;
      if (this.wizardWIP.targetAgeFrom && this.wizardWIP.targetAgeTo) {
        this.ageRangeValues = [];
        this.ageRangeValues.push(this.wizardWIP.targetAgeFrom);
        this.ageRangeValues.push(this.wizardWIP.targetAgeTo);
      }

      // Kategorien bzw. Branchen
      this.selectedKeywordItems = this.wizardWIP.keywords;
      this.selectedKeywords = [];
      for (let wipKeyword of this.wizardWIP.keywords) {
        for (let keywordGroup of this.keywordgroups) {

          for (let keyword of keywordGroup.item_group) {
            // @ts-ignore
            if (wipKeyword === keyword.item_id) {
              // @ts-ignore
              let keywordToAdd = {name: keyword.item_text, cpc: keyword.cpc};
              this.selectedKeywords.push(keywordToAdd);
            }
          }
        }

      }

      // Spiel Kategorien setzen
      this.selectedGameKeywordItems = [];
      this.selectedGameKeywordNames = [];
      this.selectedNonGameKeywordItems = [];
      this.selectedNonGameKeywordNames = [];
      this.selectedGameKeywordBooleans = [];

      // Ausgewählte setzen
      for (let i = 0; i < this.wizardWIP.gameKeywords.length; i++) {
        let gameKeywordId = this.wizardWIP.gameKeywords[i];
        this.selectedGameKeywordItems.push(gameKeywordId);

        // Spezial Fälle
        if (this.chattingGameKeyword && gameKeywordId === this.chattingGameKeyword.item_id) { // Just chatting
          this.selectedGameKeywordNames.push(this.chattingGameKeyword.item_text);
          this.selectedGameKeywordBooleans[60] = true;
        } else if (gameKeywordId === this.musicGameKeyword.item_id) { // Musik
          this.selectedGameKeywordNames.push(this.musicGameKeyword.item_text);
          this.selectedGameKeywordBooleans[61] = true;
        } else if (gameKeywordId === this.actionGameKeyword.item_id) { // Action
          this.selectedGameKeywordNames.push(this.actionGameKeyword.item_text);
          this.selectedGameKeywordBooleans[62] = true;
        } else if (gameKeywordId === this.roleplayGameKeyword.item_id) { // Rollenspiele
          this.selectedGameKeywordNames.push(this.roleplayGameKeyword.item_text);
          this.selectedGameKeywordBooleans[63] = true;
        } else if (gameKeywordId === this.sportsGameKeyword.item_id) { // Sportspiele
          this.selectedGameKeywordNames.push(this.sportsGameKeyword.item_text);
          this.selectedGameKeywordBooleans[64] = true;
          // } else if (gameKeywordId === this.over18GameKeyword.item_id) { // 18+
          //   this.selectedGameKeywordNames.push(this.over18GameKeyword.item_text);
          //   this.selectedGameKeywordBooleans[65] = true;
          //   this.over18Content = 'no';

          // Reguläre Keywords aus der Liste
        } else {

          let booleanIndex = 0;
          for (let j = 0; j < this.gameKeywords.length; j++) {
            let keyword = this.gameKeywords[j];

            // @ts-ignore
            if (gameKeywordId === keyword.item_id) {
              // @ts-ignore
              this.selectedGameKeywordNames.push(keyword.item_text);
              booleanIndex = j;
            }
          }
          this.selectedGameKeywordBooleans[booleanIndex] = true;
        }
      }

      // Ignorierte setzen
      for (let i = 0; i < this.wizardWIP.ignoredGameKeywords.length; i++) {
        let gameKeywordId = this.wizardWIP.ignoredGameKeywords[i];
        this.selectedNonGameKeywordItems.push(gameKeywordId);

        // Spezial Fälle
        if (gameKeywordId === this.chattingGameKeyword.item_id) { // Just chatting
          this.selectedNonGameKeywordNames.push(this.chattingGameKeyword.item_text);
          this.selectedGameKeywordBooleans[60] = false;
        } else if (gameKeywordId === this.musicGameKeyword.item_id) { // Musik
          this.selectedNonGameKeywordNames.push(this.musicGameKeyword.item_text);
          this.selectedGameKeywordBooleans[61] = false;
        } else if (gameKeywordId === this.actionGameKeyword.item_id) { // Action
          this.selectedNonGameKeywordNames.push(this.actionGameKeyword.item_text);
          this.selectedGameKeywordBooleans[62] = false;
        } else if (gameKeywordId === this.roleplayGameKeyword.item_id) { // Rollenspiele
          this.selectedNonGameKeywordNames.push(this.roleplayGameKeyword.item_text);
          this.selectedGameKeywordBooleans[63] = false;
        } else if (gameKeywordId === this.sportsGameKeyword.item_id) { // Sportspiele
          this.selectedNonGameKeywordNames.push(this.sportsGameKeyword.item_text);
          this.selectedGameKeywordBooleans[64] = false;
          // } else if (gameKeywordId === this.over18GameKeyword.item_id) { // 18+
          //   this.selectedNonGameKeywordNames.push(this.over18GameKeyword.item_text);
          //   this.selectedGameKeywordBooleans[65] = false;
          //   this.over18Content = 'yes';

          // Reguläre Keywords aus der Liste
        } else {

          let booleanIndex = 0;
          for (let j = 0; j < this.gameKeywords.length; j++) {
            let keyword = this.gameKeywords[j];

            // @ts-ignore
            if (gameKeywordId === keyword.item_id) {
              // @ts-ignore
              this.selectedNonGameKeywordNames.push(keyword.item_text);
              booleanIndex = j;
            }
          }
          this.selectedGameKeywordBooleans[booleanIndex] = false;
        }
      }

      // Budget
      this.totalBudget = Number.parseInt(this.wizardWIP.totalBudget);
      this.dailyBudget = Number.parseInt(this.wizardWIP.dailyBudget);
      this.views = this.wizardWIP.views;
      this.dailyViews = this.wizardWIP.dailyViews;
      this.clicks = this.wizardWIP.clicks;
      this.dailyClicks = this.wizardWIP.dailyClicks;

      if (this.wizardWIP.startDate) {
        this.startDate = this.wizardWIP.startDate;
      }
      if (this.wizardWIP.endDate) {
        this.endDate = this.wizardWIP.endDate;
      }

      // Nach dem Setzen des Budgets, alles andere ausrechnen
      this.updateDaysBetween();

      // Anzeigen
      this.description = this.wizardWIP.description;
      this.bannerContainsAnimatedPicture = this.wizardWIP.containsAnimatedPicture;
      this.affiliateLink = this.wizardWIP.link;
      this.chatMessage = this.wizardWIP.chatMessage;

      if (this.wizardWIP.campaignPics) {
        this.uploadedPictures = [];
        this.uploadedPicturesBackup = [];

        this.uploadedPictures = this.wizardWIP.campaignPics;
        this.uploadedPicturesBackup = this.wizardWIP.campaignPics;
      }

      if (this.wizardWIP.affiliatePics) {
        this.uploadedBanners = [];
        this.uploadedBannersBackup = [];

        this.uploadedBanners = this.wizardWIP.affiliatePics;
        this.uploadedBannersBackup = this.wizardWIP.affiliatePics;
      }

      if (this.wizardWIP.displayAgencyPictureIds && this.availablePicturesForDisplay) {
        let agencyPictureIds = this.wizardWIP.displayAgencyPictureIds;
        for (let i = 0; i < agencyPictureIds.length; i++) {

          let usedAgencyPicture = this.availablePicturesForDisplay.find(item => item.id === agencyPictureIds[i]);

          if (usedAgencyPicture) {
            const index = this.availablePicturesForDisplay.indexOf(usedAgencyPicture, 0);
            if (index > -1) {
              this.availablePicturesForDisplay.splice(index, 1);
            }
            this.usedPicturesForDisplay.push(usedAgencyPicture);
          }
        }
      }

      if (this.wizardWIP.clickAgencyPictureIds && this.availablePicturesForClick) {
        let agencyPictureIds = this.wizardWIP.clickAgencyPictureIds;
        for (let i = 0; i < agencyPictureIds.length; i++) {

          let usedAgencyPicture = this.availablePicturesForClick.find(item => item.id === agencyPictureIds[i]);

          if (usedAgencyPicture) {
            const index = this.availablePicturesForClick.indexOf(usedAgencyPicture, 0);
            if (index > -1) {
              this.availablePicturesForClick.splice(index, 1);
            }
            this.usedPicturesForClick.push(usedAgencyPicture);
          }
        }
      }
    }

    this.showContinueWizard = false;
  }

  restartCreation() {
    this.campaignService.deleteWizardWIP(this.wizardWIPId).then(() => {
      this.showContinueWizard = false;
      delete this.wizardWIP;
      this.step = 0;
    });
  }

  // Seite 1: Zielauswahl
  changeSelectedTargetButton(buttonId: number) {
    this.selectedTarget = buttonId;

    if (buttonId === 1) {
      this.disablePercentSlider = true;
      this.displayClickPercent = 100;
    } else if (buttonId === 2) {
      this.disablePercentSlider = true;
      this.displayClickPercent = 80;
    } else if (buttonId === 3) {
      this.disablePercentSlider = true;
      this.displayClickPercent = 70;
    } else {
      this.disablePercentSlider = false;
      this.displayClickPercent = 70;
    }

    this.updateBudgets();
  }

  updateSlider() {
    if (!this.disablePercentSlider) {
      if (this.displayClickPercent < 70) {
        this.displayClickPercent = 70;
      }
    }

    this.updateTotalBudgets();
  }

  // Seite 2: KPIs
  getCampaignCombinationName(): string {
    const lang = this.i18nService.getCurrentLang();

    // Die richtigen Panel auf Seite 3 setzen
    if (this.displayClickPercent == 100) {
      this.showDisplayBannerPanel = true;
      this.showClickBannerPanel = false;
    } else if (this.displayClickPercent == 0) {
      this.showDisplayBannerPanel = false;
      this.showClickBannerPanel = true;
    } else {
      this.showDisplayBannerPanel = true;
      this.showClickBannerPanel = false;
    }

    if (this.selectedTarget === 1) {
      if (lang === 'DE') {
        return 'Markenbekanntheit & Reichweite';
      } else {
        return 'Brand awareness & reach';
      }
    } else if (this.selectedTarget === 2) {
      if (lang === 'DE') {
        return 'Produkt- & Markenkaufbereitschaft';
      } else {
        return 'Product & brand purchase readiness';
      }
    } else if (this.selectedTarget === 3) {
      if (lang === 'DE') {
        return 'Zugriffe auf Webseite';
      } else {
        return 'Website accesses';
      }
    } else {
      if (lang === 'DE') {
        return 'Kampagne mit eigenen Zielvorhaben erstellen';
      } else {
        return 'Create campaign with own target plans';
      }
    }
  }

  updateKeywordNames(keyword: any) {
    let keywordToFind = {name: keyword.item_text, cpc: keyword.cpc};

    let foundAndDeleted = false;
    this.selectedKeywords.forEach((element, index) => {
      if (element.name == keywordToFind.name) {
        this.selectedKeywords.splice(index, 1);
        foundAndDeleted = true;
      }
    });


    if (!foundAndDeleted) {
      this.selectedKeywords.push(keywordToFind);
    }

    // console.log(this.selectedKeywords);

    this.updateBudgets();
  }

  updateFocusAndStreamerGroup(event: any) {
    if (this.smarty) {
      this.openMoreOptions = false;
      this.intensityFocus = 'smart';
      this.streamerFocus = 'smart';
    } else {
      this.openMoreOptions = true;
      this.intensityFocus = 'intensive';
      this.streamerFocus = 'micro';
    }
  }

  setSmartBalance(smartBalance: boolean) {
    if (smartBalance) {
      this.smarty = true;
      this.intensityFocus = 'smart';
      this.streamerFocus = 'smart';
    } else {
      this.smarty = false;
      this.intensityFocus = 'intensive';
      this.streamerFocus = 'micro';
    }
  }

  checkRange(event: any) {
    this.checkAgeRange('viewer');
  }

  checkStreamerRange(event: any) {
    this.checkAgeRange('streamer');
  }

  private checkAgeRange(type: string) {
    let max: number = 60;

    let low, high;
    if (type === 'viewer') {
      low = this.ageRangeValues[0];
      high = this.ageRangeValues[1];
    } else {
      low = this.streamerAgeRangeValues[0];
      high = this.streamerAgeRangeValues[1];
    }

    if ((low + 9) > high) {

      while ((low + 9) > high) {
        if (high < max) {
          high++;
        } else {
          low--;
        }
      }

      if (type === 'viewer') {
        this.ageRangeValues[0] = low;
        this.ageRangeValues[1] = high;

        this.showSlider = false;
        this.globalService.sleep(5).then(() => {
          this.showSlider = true;
        });
      } else {
        this.streamerAgeRangeValues[0] = low;
        this.streamerAgeRangeValues[1] = high;

        this.showStreamerSlider = false;
        this.globalService.sleep(5).then(() => {
          this.showStreamerSlider = true;
        });
      }
    }
  }

  updateGameKeywordNames(event: any, keyword: any) {
    let include: boolean = event.value;
    if (include != null) {

      // Wenn das Keyword eingeschlossen werden soll
      if (include) {

        // In Listen packen
        if (this.selectedGameKeywordNames.includes(keyword.item_text)) {
          this.selectedGameKeywordNames.forEach((element, index) => {
            if (element == keyword.item_text) this.selectedGameKeywordNames.splice(index, 1);
          });
        } else {
          this.selectedGameKeywordNames.push(keyword.item_text);
        }

        if (this.selectedGameKeywordItems.includes(keyword.item_id)) {
          this.selectedGameKeywordItems.forEach((element, index) => {
            if (element == keyword.item_id) this.selectedGameKeywordItems.splice(index, 1);
          });
        } else {
          this.selectedGameKeywordItems.push(keyword.item_id);
        }

        // Aus den anderen Listen löschen
        this.selectedNonGameKeywordNames.forEach((element, index) => {
          if (element == keyword.item_text) this.selectedNonGameKeywordNames.splice(index, 1);
        });
        this.selectedNonGameKeywordItems.forEach((element, index) => {
          if (element == keyword.item_id) this.selectedNonGameKeywordItems.splice(index, 1);
        });

        // Wenn das Keyword ausgeschlossen werden soll
      } else {

        // In Listen packen
        if (this.selectedNonGameKeywordNames.includes(keyword.item_text)) {
          this.selectedNonGameKeywordNames.forEach((element, index) => {
            if (element == keyword.item_text) this.selectedNonGameKeywordNames.splice(index, 1);
          });
        } else {
          this.selectedNonGameKeywordNames.push(keyword.item_text);
        }

        if (this.selectedNonGameKeywordItems.includes(keyword.item_id)) {
          this.selectedNonGameKeywordItems.forEach((element, index) => {
            if (element == keyword.item_id) this.selectedNonGameKeywordItems.splice(index, 1);
          });
        } else {
          this.selectedNonGameKeywordItems.push(keyword.item_id);
        }

        // Aus den anderen Listen löschen
        this.selectedGameKeywordNames.forEach((element, index) => {
          if (element == keyword.item_text) this.selectedGameKeywordNames.splice(index, 1);
        });
        this.selectedGameKeywordItems.forEach((element, index) => {
          if (element == keyword.item_id) this.selectedGameKeywordItems.splice(index, 1);
        });
      }

      // Wenn es in keine Liste soll muss es in beiden gelöscht werden
    } else {
      this.selectedGameKeywordNames.forEach((element, index) => {
        if (element == keyword.item_text) this.selectedGameKeywordNames.splice(index, 1);
      });
      this.selectedGameKeywordItems.forEach((element, index) => {
        if (element == keyword.item_id) this.selectedGameKeywordItems.splice(index, 1);
      });

      this.selectedNonGameKeywordNames.forEach((element, index) => {
        if (element == keyword.item_text) this.selectedNonGameKeywordNames.splice(index, 1);
      });
      this.selectedNonGameKeywordItems.forEach((element, index) => {
        if (element == keyword.item_id) this.selectedNonGameKeywordItems.splice(index, 1);
      });
    }

    // console.log(this.selectedGameKeywordBooleans);
    // console.log('Ja: '+this.selectedGameKeywordItems);
    // console.log('Nein: '+this.selectedNonGameKeywordItems);
  }

  // updateOver18Content(event: any) {
  //   if (this.over18Content === 'yes') {
  //     this.selectedGameKeywordBooleans[65] = true;
  //     this.updateGameKeywordNames({value: false}, this.over18GameKeyword);
  //   } else {
  //     this.selectedGameKeywordBooleans[65] = false;
  //     this.updateGameKeywordNames({value: true}, this.over18GameKeyword);
  //   }
  // }

  // Seite 3: Anzeige
  updateLetterCounter() {
    this.letterCount = 500 - this.description.length;
  }

  isDisplayCampaignOnly(): boolean {
    return this.displayClickPercent === 100;
  }

  isClickCampaignOnly(): boolean {
    return this.displayClickPercent === 0;
  }

  isCampaignMix(): boolean {
    return this.displayClickPercent >= 1 && this.displayClickPercent < 100;
  }

  openOtherPanel() {
    if (this.isCampaignMix()) {
      if (this.showDisplayBannerPanel) {
        this.showClickBannerPanel = true;
      } else {
        this.showDisplayBannerPanel = true;
      }
    }
  }

  getNumberOfDisplayBanners(): number {
    return this.uploadedPicturesBackup.length + this.usedPicturesForDisplay.length;
  }

  getNumberOfClickBanners(): number {
    return this.uploadedBannersBackup.length + this.usedPicturesForClick.length;
  }

  getCurrentPictures(): any[] {
    if (this.uploadedPicturesBackup.length > 0) {
      this.checkImageLimit();
      return this.uploadedPicturesBackup;
    } else {
      this.checkImageLimit();
      return this.uploadedPictures;
    }
  }

  setPictures(event: any) {
    const files = event.files;
    this.picturesOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];

      // Wenn ein animiertes Bild hochgeladen wird, wird der Preis verdoppelt
      this.imageTypesArray.push(fileType);
      this.checkIfAnimatedImagePresent();

      promises.push(this.pictureService.checkLogoOrStreamBanner(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.picturesOk = false;
        }
      }

      if (this.picturesOk) {
        this.uploadedPicturesBackup = event.currentFiles;
      }
    });
  }

  removePicture(event: any) {
    const fileToRemove = event.file;
    const fileType = fileToRemove['type'];

    // Wenn ein animiertes Bild entfernt wird, wird der Preis geteilt
    this.removeImageTypeFromArray(fileType);
    this.checkIfAnimatedImagePresent();

    const indexOfFile = this.uploadedPicturesBackup.findIndex(v => v.name == event.file.name);
    this.uploadedPicturesBackup.splice(indexOfFile, 1);
    this.checkImageLimit();
  }

  clearPictures() {
    this.imageTypesArray = [];
    this.checkIfAnimatedImagePresent();
    this.uploadedPicturesBackup = [];
    this.checkImageLimit();
  }

  removeImageTypeFromArray(imageType: string) {
    this.imageTypesArray.forEach((value, index) => {
      if (value == imageType) this.imageTypesArray.splice(index, 1);
    });
  }

  checkIfAnimatedImagePresent() {
    this.bannerContainsAnimatedPicture = this.imageTypesArray.includes(this.doublePriceImageType);
    // this.views = this.viewsRaw;
    this.updateBudgets();
  }

  updateAddPicture(event: any) {
    let newPictures = event.items;

    for (let i = 0; i < newPictures.length; i++) {
      let newPicture: AgencyPicture = newPictures[i];

      // Wenn ein animiertes Bild hochgeladen wird, wird der Preis verdoppelt
      this.imageTypesArray.push(newPicture.contentType);
      this.checkIfAnimatedImagePresent();

      // this.views = this.viewsRaw;
      this.updateBudgets();
    }
  }

  updateRemovePicture(event: any) {
    let newPictures = event.items;

    for (let i = 0; i < newPictures.length; i++) {
      let newPicture: AgencyPicture = newPictures[i];

      // Wenn ein animiertes Bild entfernt wird, wird der Preis geteilt
      this.removeImageTypeFromArray(newPicture.contentType);
      this.checkIfAnimatedImagePresent();

      // this.views = this.viewsRaw;
      // this.calculateBudget();
    }
  }

  checkURL() {
    if (this.affiliateLink) {
      this.wrongURL = !this.globalService.isValidUrl(this.affiliateLink);
    }
  }

  getCurrentBanners(): any[] {
    if (this.uploadedBannersBackup.length > 0) {
      this.checkImageLimit();
      return this.uploadedBannersBackup;
    } else {
      this.checkImageLimit();
      return this.uploadedBanners;
    }
  }

  clearBanners() {
    this.uploadedBannersBackup = [];
    this.checkImageLimit();
  }

  removeBanner(event: any) {
    const indexOfFile = this.uploadedBannersBackup.findIndex(v => v.name == event.file.name);
    this.uploadedBannersBackup.splice(indexOfFile, 1);
    this.checkImageLimit();
  }

  setBanners(event: any) {
    const files = event.files;
    this.bannersOk = true;

    const promises: any[] = [];
    let i;
    for (i = 0; i < files.length; i++) {
      const fileType = files[i]['type'];
      promises.push(this.pictureService.checkAffiliateBanner(files[i]));
    }

    Promise.all(promises).then((values) => {
      let j;
      for (j = 0; j < values.length; j++) {
        if (!values[j]) {
          this.bannersOk = false;
        }
      }

      if (this.bannersOk) {
        this.uploadedBannersBackup = event.currentFiles;
      }
    });
  }

  updateChatMessageLetterCounter() {
    this.chatLetterCount = 150 - this.chatMessage.length;
    this.checkForChatMessageForLinks();
  }

  checkForChatMessageForLinks() {
    this.chatMessageContainsLinkError = false;

    if (this.chatMessage.includes("http")) {
      this.chatMessageContainsLinkError = true;
    }

    if (this.chatMessage.includes("www.")) {
      this.chatMessageContainsLinkError = true;
    }
  }

  // Seite 4: Budgetplanung
  updateBudgetInputsTotal() {
    this.disableTotalBudget = false;
    this.disableDailyBudget = true;
  }

  updateBudgetInputsDaily() {
    this.disableTotalBudget = true;
    this.disableDailyBudget = false;
  }

  updateBudgets() {
    this.discountlessBudget = this.totalBudget;

    if (this.disableDailyBudget) {
      this.totalBudget = parseFloat(this.totalBudget.toFixed(2));

      if (this.totalBudget > 0 && this.daysBetween > 0) {
        this.dailyBudget = this.mathHelper.precisionRound(this.totalBudget / this.daysBetween, 2);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);

        this.updateTotalBudgets();
      }
    } else {
      if (this.dailyBudget > 0 && this.daysBetween > 0) {
        this.totalBudget = this.mathHelper.precisionRound(this.daysBetween * this.dailyBudget, 2);
        this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);
        this.dailyBudgetString = this.mathHelper.numberWithCommas(this.dailyBudget);

        this.updateTotalBudgets();
      }
    }
  }

  /**
   * Komplett Budgets für Display und Klick Kampagne basierend auf dem Gesamtbudget
   */
  updateTotalBudgets() {
    if (this.totalBudget > 0) {
      this.totalBudgetRaw = this.totalBudget;
      this.totalDisplayBudget = this.mathHelper.precisionRound(this.totalBudget * (this.displayClickPercent / 100), 2);
      this.totalClickBudget = this.mathHelper.precisionRound(this.totalBudget - this.totalDisplayBudget, 2);

      this.totalDisplayBudgetRaw = this.totalDisplayBudget;
      this.totalClickBudgetRaw = this.totalClickBudget;
      this.totalDisplayBudgetString = this.mathHelper.numberWithCommas(this.totalDisplayBudget);
      this.totalClickBudgetString = this.mathHelper.numberWithCommas(this.totalClickBudget);

      this.calculateBudgetWithTax();
      this.calculateClicksAndViews();
    }
  }

  calculateBudgetWithTax() {
    this.totalBudgetWithTax = this.totalBudget;
    this.totalDisplayBudgetWithTax = this.totalDisplayBudget;
    this.totalClickBudgetWithTax = this.totalClickBudget;

    if (this.isTaxNeeded()) {
      this.totalBudgetWithTax = this.totalBudget + (this.totalBudget * 0.19);
      this.totalBudgetWithTax = this.mathHelper.precisionRound(this.totalBudgetWithTax, 2);

      if (this.baseBalance >= this.totalBudgetWithTax) {
        this.remainingBaseBalance = this.baseBalance - this.totalBudget;
        this.isBalanceEnough = true;
        this.isBalancePaymentError = false;
      } else {
        this.isBalanceEnough = false;
        this.isBalancePaymentError = true;
        this.remainingBaseBalance = 0;
      }

      this.totalDisplayBudgetWithTax = this.totalDisplayBudget + (this.totalDisplayBudget * 0.19);
      this.totalDisplayBudgetWithTax = this.mathHelper.precisionRound(this.totalDisplayBudgetWithTax, 2);
      this.totalClickBudgetWithTax = this.totalClickBudget + (this.totalClickBudget * 0.19);
      this.totalClickBudgetWithTax = this.mathHelper.precisionRound(this.totalClickBudgetWithTax, 2);
    }

    this.totalBudgetWithTaxString = this.mathHelper.numberWithCommas(this.totalBudgetWithTax);
    this.totalBudgetWithTaxForPaypal = this.totalBudgetWithTax.toString();
  }

  updateDaysBetween() {
    let millisecPerDay = 1000 * 3600 * 24;
    this.createCampaignError = false;

    if (this.startDate && this.endDate) {
      this.startDate.setHours(0);
      this.startDate.setMinutes(0);
      this.startDate.setSeconds(0);
      this.startDate.setMilliseconds(0);

      this.endDate.setHours(0);
      this.endDate.setMinutes(0);
      this.endDate.setSeconds(0);
      this.endDate.setMilliseconds(0);

      const differenceInTime = this.endDate.getTime() - this.startDate.getTime();
      this.daysBetween = this.mathHelper.precisionRound((differenceInTime / millisecPerDay) + 1, 0);

      if (this.daysBetween <= 2) {
        this.tooFewDaysError = true;
      } else {
        this.tooFewDaysError = false;

        // console.log(this.startDate+' - '+this.endDate+' / '+differenceInTime+ ' / '+this.daysBetween);
        this.updateBudgets();
      }
    } else {
      this.tooFewDaysError = true;
      this.daysBetween = 0;
    }
  }

  /**
   * Gesamt Klicks und Views ausrechnen
   */
  calculateClicksAndViews() {
    if (this.isCampaignMix() || this.isClickCampaignOnly()) {
      this.calculateClicks();
    }

    if (this.isCampaignMix() || this.isDisplayCampaignOnly()) {
      this.calculateViews();
    }

  }

  getCPC(): number {
    let cpc: number = 0;
    this.selectedKeywords.forEach((element, index) => {
      if (element.cpc > cpc) {
        cpc = element.cpc;
      }
    });

    if (cpc === 0) {
      cpc = 0.8;
    }
    return cpc;
  }

  getReadableCPC(): string {
    return this.mathHelper.numberWithCommas(this.getCPC()) + " €";
  }

  public calculateClicks() {
    // Alter Wert: 0.8
    const cpc = this.getCPC();

    this.clicks = this.mathHelper.precisionRound(this.totalClickBudget / cpc, 0);

    if (this.daysBetween > 0) {
      this.dailyClicks = this.mathHelper.precisionRound(this.clicks / this.daysBetween, 0);
    }
  }

  getTKP(): number {
    if (this.bannerContainsAnimatedPicture) {
      return 20;
    } else {
      return 10;
    }
  }

  public calculateViews() {
    const tkp = this.getTKP();

    if (this.totalDisplayBudget < tkp) {
      this.totalDisplayBudget = tkp;
    }

    // 500 Budget / 10 * 1000 = Views
    this.views = this.totalDisplayBudget / tkp * 1000;
    this.views = this.mathHelper.precisionRound(this.views, 0);

    if (this.daysBetween > 0) {
      this.dailyViews = this.mathHelper.precisionRound(this.views / this.daysBetween, 0);
    }
  }

  // Seite 5: Überprüfung
  public getFocusType() {
    const lang = this.i18nService.getCurrentLang();

    if (this.smarty) {
      return 'Balanced'
    } else {
      let focusString = '';

      if (this.intensityFocus === 'range') {
        if (lang === 'DE') {
          focusString += 'Reichweite als primäres Werbeziel';
        } else {
          focusString += 'Range as primary advertising target';
        }
      } else if (this.intensityFocus === 'intensive') {
        if (lang === 'DE') {
          focusString += 'Ausgewählte Kunden als primäres Werbeziel';
        } else {
          focusString += 'Selected customers as primary advertising target';
        }
      }

      focusString += ' / ';

      if (this.streamerFocus === 'micro') {
        if (lang === 'DE') {
          focusString += 'Kleine StreamerInnen als favorisiertes Werbemedium';
        } else {
          focusString += 'Small Streamers as favoured advertising medium';
        }
      } else if (this.streamerFocus === 'macro') {
        if (lang === 'DE') {
          focusString += 'Größere StreamerInnen als favorisiertes Werbemedium';
        } else {
          focusString += 'Larger Streamer as favoured advertising medium';
        }
      }

      return focusString;
    }
  }

  getGenderTarget(): string {
    const lang = this.i18nService.getCurrentLang();
    let sentence = '';
    if (this.selectedGender.includes('male')) {
      if (lang === 'DE') {
        sentence += 'Männer';
      } else {
        sentence += 'Males';
      }
    }

    if (this.selectedGender.includes('female')) {
      if (sentence.length > 0) {
        sentence += ', ';
      }

      if (lang === 'DE') {
        sentence += 'Frauen';
      } else {
        sentence += 'Females';
      }
    }

    if (this.selectedGender.includes('divers')) {
      if (sentence.length > 0) {
        if (lang === 'DE') {
          sentence += ' und ';
        } else {
          sentence += ' and ';
        }
      }

      if (lang === 'DE') {
        sentence += 'Diverse';
      } else {
        sentence += 'Divers';
      }
    }

    if (sentence.length == 0) {
      if (lang === 'DE') {
        sentence += 'Niemanden';
      } else {
        sentence += 'Nobody';
      }
    }

    return sentence;
  }

  getPicURL(file: File) {
    let url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public isTaxNeeded(): boolean {
    if (this.globalService.advertiser) {
      return this.globalService.advertiser.isTaxNeeded();
    } else if (this.globalService.agency) {
      return this.globalService.agency.isTaxNeeded();
    } else {
      return true;
    }
  }

  public getStartDateAsString(): string {
    if (this.startDate != undefined) {
      return moment(this.startDate).format('DD.MM.yyyy');
    } else {
      return "N/A"
    }
  }

  public getEndDateAsString(): string {
    if (this.endDate != undefined) {
      return moment(this.endDate).format('DD.MM.yyyy');
    } else {
      return "N/A"
    }
  }

  checkDiscountCode() {
    if (this.codeString) {
      this.discountService.checkCode(this.codeString).then(result => {
        if (!result.isEmpty()) {
          this.noDiscountError = false;
          this.discountExpired = false;
          this.discountCode = result;

          this.totalBudget = this.totalBudgetRaw;

          if (this.discountCode.type === 'PRICE') {
            const percentValue = 100 - this.discountCode.percent;
            this.totalBudget = this.totalBudget * (percentValue / 100);
            this.totalBudget = this.mathHelper.precisionRound(this.totalBudget, 2);
            this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);

            // Auf die Einzelpreise anlegen
            this.totalDisplayBudget = this.totalDisplayBudget * (percentValue / 100);
            this.totalDisplayBudget = this.mathHelper.precisionRound(this.totalDisplayBudget, 2);
            this.totalClickBudget = this.totalClickBudget * (percentValue / 100);
            this.totalClickBudget = this.mathHelper.precisionRound(this.totalClickBudget, 2);
          }

          this.calculateBudgetWithTax();
        } else {
          delete this.discountCode;
          this.noDiscountError = true;
        }
      });
    }
  }

  removeDiscountCode() {
    if (this.discountCode) {
      delete this.discountCode;
      this.noDiscountError = false;
      this.codeString = "";

      this.totalBudget = this.totalBudgetRaw;
      this.totalDisplayBudget = this.totalDisplayBudgetRaw;
      this.totalClickBudget = this.totalClickBudgetRaw;
      this.totalBudgetString = this.mathHelper.numberWithCommas(this.totalBudget);
      this.calculateBudgetWithTax();
    }
  }

  getBillNrsForBankTransfer(): string {
    return this.billNr;
  }

  paymentOk(): boolean {
    if (this.paymentIndex === '1') {
      return false;
    } else {
      if (this.paypalPaymentDone) {
        return false;
      } else {
        return true;
      }
    }
  }

  create(sessionId: string) {
    this.working = true;
    let discountCodeId = "";

    // Wenn es einen Discount Code gibt, muss der vor dem Speichern nochmal gecheckt werden
    if (this.discountCode) {
      discountCodeId = this.discountCode.id;

      this.discountService.checkCode(this.discountCode.code).then(result => {
        if (!result.isEmpty()) {
          this.discountExpired = false;
          this.createInternal(discountCodeId, sessionId);
        } else {
          this.discountExpired = true;
          this.removeDiscountCode();
          this.working = false;
        }
      });

    } else {
      this.createInternal(discountCodeId, sessionId);
    }
    if (!sessionId) {
      if (this.globalService.agency) {
        this.router.navigate(['/agency/advertisers'], {queryParams: {created: 'true'}}).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(['/advertiser/campaigns'], {queryParams: {created: 'true'}}).then(() => {
          window.location.reload();
        });
      }
    }
  }

  private createInternal(discountCodeId: string, sessionId: string) {
    this.ipService.getIp().then(res => {

      let agencyPictureIdsForDisplay: string[] = [];
      let agencyPictureIdsForClick: string[] = [];

      // Agentur Bilder Id sammeln
      if (this.globalService.agency) {
        for (let i = 0; i < this.usedPicturesForDisplay.length; i++) {
          agencyPictureIdsForDisplay.push(this.usedPicturesForDisplay[i].id);
        }

        for (let i = 0; i < this.usedPicturesForClick.length; i++) {
          agencyPictureIdsForClick.push(this.usedPicturesForClick[i].id);
        }
      }

      // 18+ Spiele nur berücksichtigen wenn sie explizit ausgeschlossen werden oder in Kombination mit anderen Kategorien
      // if (this.selectedGameKeywordItems.length === 1 && this.selectedNonGameKeywordNames.length === 0
      //   && this.selectedGameKeywordItems[0] === this.over18GameKeyword.item_id) {
      //   this.selectedGameKeywordItems = [];
      // }

      // Dem Backend sagen, was erstellt werden muss
      const createDisplayCampaign: boolean = this.isDisplayCampaignOnly() || this.isCampaignMix();
      const createClickCampaign: boolean = this.isClickCampaignOnly() || this.isCampaignMix();

      // Alles erstellen
      this.campaignService.saveDisplayClickCampaignForAdvertiserOrAgency(createDisplayCampaign, createClickCampaign,
        this.campaignName, this.displayName, this.language, this.selectedKeywordItems, this.intensityFocus, this.streamerFocus,
        this.selectedGender, this.ageRangeValues, this.streamerAgeRangeValues, this.selectedGameKeywordItems,
        this.selectedNonGameKeywordItems, this.description, this.uploadedPicturesBackup, agencyPictureIdsForDisplay,
        this.affiliateLink, this.uploadedBannersBackup, agencyPictureIdsForClick, this.chatMessage, this.googleTagId,
        this.totalBudget, this.totalDisplayBudget, this.totalClickBudget, this.views, this.clicks,
        this.dailyViews, this.dailyClicks, this.startDate, this.getTKP(), discountCodeId, this.billNr,
        this.paypalPaymentDone, this.chooseAddress, this.advertiserId, this.getCPC(), res.ip, sessionId,
        this.remainingBaseBalance, this.isBalancePay)
        .then(res => {

          this.working = false;
          if (this.globalService.advertiser) {
            this.advertiserService.updateAdvertiserInformation();
          } else if (this.globalService.agency) {
            this.agencyService.updateAgencyInformation();
          }

        }).catch((err: any) => {
        console.log(err);
        this.working = false;
      });

    }).catch((err) => {
      console.log(err);
      this.working = false;
    });
  }

  // Bonus Seite: Advertiser Registrierung abschließen
  public register() {
    this.working = true;

    if (this.checkAllRegisterValues()) {
      this.advertiserService.registerAdvertiserSimpleAdditional(this.website, this.taxNumber, this.street, this.nr,
        this.postalCode, this.city, this.country.item_id, this.firstname, this.lastname, this.telephone).then(result => {

        this.working = false;
        this.registerSent = true;
        this.showRegister = false;

        // Nach den zusätzlichen Angaben geht es endlich weiter.
        this.step = 4;
      }).catch(err => {
        console.log(err);
        this.working = false;
      });

    }
  }

  public checkAllRegisterValues(): boolean {
    let registerAllowed: boolean = true;

    if (!this.globalService.advertiser?.street && !this.street) {
      registerAllowed = false;
    }

    if (!this.globalService.advertiser?.nr && !this.nr) {
      registerAllowed = false;
    }

    if (!this.globalService.advertiser?.postalCode && !this.postalCode) {
      registerAllowed = false;
    }

    if (!this.globalService.advertiser?.city && !this.city) {
      registerAllowed = false;
    }

    if (!this.globalService.advertiser?.firstname && !this.firstname) {
      registerAllowed = false;
    }

    if (!this.globalService.advertiser?.lastname && !this.lastname) {
      registerAllowed = false;
    }

    return registerAllowed;
  }

  protected readonly Number = Number;
}
