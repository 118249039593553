export class Tag {

  public tagId!: string;

  public advertiserId!: string;

  public status!: string;

  public affiliateName!: string;

  public creationDate!: string;

  public totalConversions: number = 0;

  constructor() {}

}
