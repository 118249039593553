<img src="assets/images/streamer_login_background.jpg" id="background" alt="">
<div>
  <div class="p-grid">
    <div class="p-col-12">
      &nbsp;
    </div>
  </div>
  <div class="p-grid p-ai-center vertical-container">
    <div class="p-col-12 foreground" style="margin-top: 5em;">
      <div style="text-align: center;">
        <img src="assets/images/login_Logo.png" alt="streamboost" class="streamboostLogo-alt">
      </div>
    </div>

    <div class="p-col-12 foreground" style="text-align: center;">

      <div class="p-grid" style="margin-top: 6em;">
        <div class="p-col-12">
          <button pButton pRipple type="button" class="p-button-lg twitchButton" label="Mit Twitch einloggen"
                  icon="fab fa-twitch" (click)="loginWithTwitch()">
          </button>
        </div>
        <div class="p-col-12" *ngIf="globalService.enableYoutubeAuth">
          <button pButton pRipple type="button" class="p-button-lg youtubeButton" label="Mit YouTube einloggen"
                  icon="fab fa-youtube" (click)="loginWithGoogle()" >
          </button>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-10 p-lg-10 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-2 foreground error-box" *ngIf="loginError">
      <p class="error-text">
        {{i18nService.localize().StreamerLoginComponent.ConnectionRightsError}}
        <a href="https://myaccount.google.com/permissions" style="color: white;font-weight: bold;">
          Google Permissions
        </a>
      </p>
    </div>

  </div>
  <div class="p-grid footer foreground">
    <div class="p-col-12">
      <a *ngIf="globalService.links" href="{{globalService.links.termsLink}}" target="_blank">{{i18nService.localize().StreamerLoginComponent.TermsOfUseLink}}</a>
      <p class="textMuted">© 2020-2023</p>
    </div>
  </div>
</div>

