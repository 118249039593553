<p-dialog [(visible)]="show" closeIcon="fas fa-times" [style]="{width: '90vw', maxWidth: '1035px'}" (visibleChange)="updateShow()">
  <ng-template pTemplate="header">
    <div class="headline">{{this.i18nService.localize().StreamerDeleteDialogComponent.DeleteHeader}}</div>
  </ng-template>
  <p>{{this.i18nService.localize().StreamerDeleteDialogComponent.ConfirmText}}</p>
  <div style="text-align: right">
    <button pButton pRipple type="button" icon="fas fa-check"
            class="p-button-rounded" (click)="deleteStreamer()"></button>
    <button pButton pRipple type="button" icon="fas fa-times" style="margin-left: 0.5em;"
            class="p-button-rounded p-button-secondary" (click)="close()"></button>
  </div>
</p-dialog>
