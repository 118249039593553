export class DiscountCode {

  public id!: string;

  public code!: string;

  public type!: string;

  public percent!: number;

  public explanation!: string;

  constructor() {}

  public isEmpty(): boolean {
    if (this.code == undefined) {
      return true;
    } else {
      return false;
    }
  }

}
