import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {RoutingService} from "../../services/routing.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {
  public showHeadline: boolean = false;

  public showOutcome: boolean = false;
  @Input() success: boolean = true;

  // Optional
  @Input() advertiserId?: string;

  constructor(private globalService: GlobalService, private router: Router) {
  }

  ngOnInit(): void {
    this.showHeadline = this.router.url.includes('/campaign/create');

    setTimeout(() => {
      this.showOutcome = true;
    }, 1000);
  }

  switchToCampaigns() {
    if (this.globalService.advertiser) {
      window.location.href = '/advertiser/campaigns';
    } else if (this.globalService.agency) {
      window.location.href = `/agency/advertisers?advertiserId=${this.advertiserId}`;
    }
  }

}
