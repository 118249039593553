import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalizationModel } from '../model/schema/localization.model';
// @ts-ignore
import LocalDE from '../../assets/localizations/local-DE.json';
// @ts-ignore
import LocalEN from '../../assets/localizations/local-EN.json';

@Injectable()
export class I18nService {

	// tslint:disable:indent
	langChangeEmitter: EventEmitter<string> = new EventEmitter();

	private currentSelectedLang = 'DE';
	private currentLocalization = LocalDE as LocalizationModel;

	// Hier ist eine Forward-Deklaration
	constructor(private cookieService: CookieService) {
	}

  /**
   * Ändert die Sprache und schickt die Änderung an alle die
   * es interessiert
   *
   * @param newLang
   */
	public changeLang(newLang: string) {
    this.setLang(newLang);

		// Expiration for 90 days
		const expirationDate = new Date();
		expirationDate.setDate(new Date().getDate() + 90);

		this.cookieService.set('streamboost-language', newLang, expirationDate, '/', '', true);
		this.langChangeEmitter.emit(this.currentSelectedLang);
	}

  /**
   * Setzt die aktuelle Sprache ohne Bescheid zu geben
   *
   * @param newLang
   */
  public setLang(newLang: string) {
    if (newLang === 'DE') {
      this.currentSelectedLang = newLang;
      this.currentLocalization = LocalDE as LocalizationModel;
    } else if (newLang === 'EN') {
      this.currentSelectedLang = newLang;
      this.currentLocalization = LocalEN as LocalizationModel;
    }
  }

	public localize(): LocalizationModel {
		return this.currentLocalization;
	}

	public getCurrentLang(): string {
		return this.currentSelectedLang;
	}

  /**
   * Gibt die passende Sprache für alle PrimeNG Komponenten zurück
   *
   * @param lang
   */
  public getPrimeNGConfig(lang: string): any {
    if (lang === 'DE') {
      return {
        startsWith: 'Startet mit',
        contains: 'Beinhaltet',
        notContains: 'Beinhaltet nicht',
        endsWith: 'Endet mit',
        equals: 'Gleich',
        notEquals: 'Nicht gleich',
        noFilter: 'Kein Filter',
        lt: 'Weniger als',
        lte: 'Weniger als oder gleich',
        gt: 'Größer als',
        gte: 'Größer als oder gleich',
        is: 'Ist',
        isNot: 'Ist nicht',
        before: 'Vorher',
        after: 'nachher',
        dateIs: 'Datum ist',
        dateIsNot: 'Datum ist nicht',
        dateBefore: 'Datum ist vorher',
        dateAfter: 'Datum ist nachher',
        clear: 'Löschen',
        apply: 'Anwenden',
        matchAll: 'Alle passend',
        matchAny: 'Manche passend',
        addRule: 'Regel hinzufügen',
        removeRule: 'Regel löschen',
        accept: 'Ja',
        reject: 'Nein',
        choose: 'Auswählen',
        upload: 'Hochladen',
        cancel: 'Abbrechen',
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['Son', 'Mon', 'Dien', 'Mitt', 'Don', 'Frei', 'Sam'],
        dayNamesMi: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
          'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        dateFormat: 'dd.mm.yy',
        firstDayOfWeek: 1,
        today: 'Heute',
        weekHeader: 'KW',
        weak: 'Schwach',
        medium: 'Mittel',
        strong: 'Stark',
        passwordPrompt: 'Passwort eingeben',
        emptyMessage: 'Nichts gefunden',
        emptyFilterMessage: 'Nichts gefunden'
      };
    } else {
      return {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        is: 'Is',
        isNot: 'Is not',
        before: 'Before',
        after: 'After',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMi: ["Su","Mo","Tu","We","Th","Fr","Sa"],
        monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        dateFormat: 'mm/dd/yy',
        firstDayOfWeek: 0,
        today: 'Today',
        weekHeader: 'Wk',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyMessage: 'No results found',
        emptyFilterMessage: 'No results found'
      };
    }
  }

  public getCurrentPrimeNGConfig(): any {
    return this.getPrimeNGConfig(this.currentSelectedLang);
  }

}


