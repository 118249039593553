import {DashboardCampaign} from "./dashboard-campaign.model";
import {WizardWIP} from "../wizard-wip.model";

export class Advertiser {

  public id!: string;
  public advertiserNr!: string;

  public companyName!: string;
  public website!: string;
  public logoURL!: string;

  public firstname!: string;
  public lastname!: string;
  public email!: string;
  public telephone!: string;

  public street!: string;
  public nr!: string;
  public postalCode!: string;
  public city!: string;
  public isPasswordInitial: boolean = false;
  public taxNumber!: string;
  public countryId!: string;
  public country!: string;

  public agbConfirmation: boolean = false;

  public activeCampaigns: DashboardCampaign[] = [];
  public allInactiveDisplayCampaigns: DashboardCampaign[] = [];
  public allOtherDisplayCampaigns: DashboardCampaign[] = [];
  public wipCampaigns: WizardWIP[] = [];
  public allInactiveClickCampaigns: DashboardCampaign[] = [];
  public allOtherClickCampaigns: DashboardCampaign[] = [];

  public rememberLogin: boolean = false;

  public allowAdminAccess: boolean = false;

  public customerId!: string;

  constructor() {}

  public hasAddress(): boolean {
    if (this.street || this.nr || this.postalCode || this.city) {
      return true;
    } else {
      return false;
    }
  }

  public isTaxNeeded(): boolean {
    if (this.country === 'Deutschland' || this.country === 'Germany') {
      return true;
    } else {
      return false;
    }
  }

  public needsMoreInformation(): boolean {
    if (!this.street || !this.nr || !this.postalCode || !this.city || !this.country || !this.firstname || !this.lastname) {
      return true;
    } else {
      return false;
    }
  }
}
