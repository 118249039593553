export class AgencyAdvertiser {

  public id!: string;
  public companyName!: string;
  public active!: boolean;

  public budget!:string;
  public numberOfCampaigns!: number;
  public numberOfAffiliates!: number;

  public picId!:string;

  public street!: string;
  public nr!: string;
  public postalCode!: string;
  public city!: string;

  public country!: string;
  public countryId!: string;

  public website!: string;

  public hasAddress(): boolean {
    if (this.street || this.nr || this.postalCode || this.city) {
      return true;
    } else {
      return false;
    }
  }

  public getCountryEntry() : any {
    return {
      item_id: this.countryId,
      item_text: this.country
    }
  }

}
