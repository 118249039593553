<!--suppress XmlInvalidId -->
<div *ngIf="(this.globalService.advertiser||this.globalService.agency) && !showPaymentInfoPage">
  <div class="headline">{{ i18nService.localize().CampaignWizardComponent.CreateHeader }}</div>
  <div class="p-col-12">
    <div class="p-grid abstand">

      <div class="p-col-12" style="margin-top: 2em;">
        <div class="p-grid">
          <div class="col-step step">
            <p [class]="this.step === 0 ? 'step-number-selected' : 'step-number'">1</p>
          </div>
          <div class="col-step step">
            <p [class]="this.step === 1 ? 'step-number-selected' : 'step-number'">2</p>
          </div>
          <div class="col-step step">
            <p [class]="this.step === 2 ? 'step-number-selected' : 'step-number'">3</p>
          </div>
          <div class="col-step step">
            <p [class]="this.step === 3 ? 'step-number-selected' : 'step-number'">4</p>
          </div>
          <div class="col-step-small step-end">
            <p [class]="this.step === 4 ? 'step-number-selected' : 'step-number'">5</p>
          </div>
        </div>
      </div>

      <p-dialog [(visible)]="showContinueWizard" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="false" [closable]="false" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{ i18nService.localize().CampaignWizardComponent.ContinueCreationHeader }}</div>
        </ng-template>
        <p class="textWithNewlines">
          {{ i18nService.localize().CampaignWizardComponent.ContinueCreationQuestion }}
        </p>
        <p style="font-weight: bolder">
          <i class="fas fa-exclamation-circle"></i>
          {{ i18nService.localize().CampaignWizardComponent.DiscountCodeContinueHint }}
        </p>
        <div style="text-align: right">
          <button type="button" pButton pRipple (click)="continueCreation()"
                  label="{{i18nService.localize().CampaignWizardComponent.ContinueButton}}"
                  class="p-button-rounded">
          </button>
          <button type="button" pButton pRipple (click)="restartCreation()"
                  label="{{i18nService.localize().CampaignWizardComponent.RestartButton}}" class="p-button-rounded"
                  style="margin-left: 0.5em;">
          </button>
        </div>
      </p-dialog>

      <!-- Hilfe Button -->
      <a class="float-button" href="https://calendly.com/merdan-streamboost/besprechung" target="_blank">
        <i class="fas fa-question float-icon"></i>
      </a>

      <!-- Schritt 1: Zielauswahl -->
      <div class="p-col-12" *ngIf="step === 0">
        <h2 class="card-headline">{{ i18nService.localize().CampaignWizardComponent.TargetHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em 3em 2em 3em;">
            <div class="p-col-12 p-md-8">
              <div class="p-grid">
                <div class="p-col-12 p-md-6">
                  <div class="p-grid target-button" [ngClass]="this.selectedTarget==1 ? 'selected-target-button' : ''"
                       (click)="changeSelectedTargetButton(1)">
                    <div class="p-col-6">
                      <i class="fas fa-bullhorn"></i>
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.BrandAwarenessTooltip}}"></i>
                    </div>
                    <div class="p-col-12">
                      <p style="font-weight: bold;">
                        {{ i18nService.localize().CampaignWizardComponent.BrandAwarenessHead }}
                      </p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.BrandAwarenessText }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="p-grid target-button" [ngClass]="this.selectedTarget==2 ? 'selected-target-button' : ''"
                       (click)="changeSelectedTargetButton(2)">
                    <div class="p-col-6">
                      <i class="fas fa-shopping-cart"></i>
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.BrandPurchaseReadinessTooltip}}"></i>
                    </div>
                    <div class="p-col-12">
                      <p style="font-weight: bold;">
                        {{ i18nService.localize().CampaignWizardComponent.BrandPurchaseReadinessHead }}
                      </p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.BrandPurchaseReadinessText }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="p-grid target-button" [ngClass]="this.selectedTarget==3 ? 'selected-target-button' : ''"
                       (click)="changeSelectedTargetButton(3)">
                    <div class="p-col-6">
                      <i class="fas fa-mouse-pointer"></i>
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.AccessToWebTooltip}}"></i>
                    </div>
                    <div class="p-col-12">
                      <p style="font-weight: bold;">
                        {{ i18nService.localize().CampaignWizardComponent.AccessToWebHead }}
                      </p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.AccessToWebText }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="p-grid target-button" [ngClass]="this.selectedTarget==4 ? 'selected-target-button' : ''"
                       (click)="changeSelectedTargetButton(4)">
                    <div class="p-col-6">
                      <i class="fas fa-project-diagram"></i>
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.CustomTargetTooltip}}"></i>
                    </div>
                    <div class="p-col-12">
                      <p style="font-weight: bold;">
                        {{ i18nService.localize().CampaignWizardComponent.CustomTargetHead }}
                      </p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.CustomTargetText }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="p-col-12">
                  <div class="p-grid" style="padding: 2em;">
                    <div class="p-col-6">
                      <p style="font-weight: bold; margin-top: 2.2em;">
                        {{ i18nService.localize().CampaignWizardComponent.DisplayPercent }}
                      </p>
                      {{ displayClickPercent }}%
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.DisplayClickPercentTooltip}}"
                         tooltipPosition="left"></i>
                      <p style="font-weight: bold;">
                        {{ i18nService.localize().CampaignWizardComponent.ClickPercent }}
                      </p>
                      {{ 100 - displayClickPercent }}%
                    </div>
                    <div class="p-col-12">
                      <p-slider [(ngModel)]="displayClickPercent" [min]="0" [max]="100" [animate]="true"
                                (onChange)="updateSlider()"
                                [style]="{'background':'#1d60a4'}" [disabled]="disablePercentSlider"
                                [step]="5"></p-slider>
                    </div>
                    <div class="p-col-12">
                      <h2 class="card-headline">Tutorial: Kampagnen erstellen</h2>
                      <a href="https://www.youtube.com/playlist?list=PLvtA8Nl_tgzO-8ylGsXrDPDqBk9NtaaI7"
                         target="_blank">
                        <img src="assets/images/youtube_banner.png" alt="Youtube Tutorial">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-grid info-box">
                <div class="p-col-12">
                  <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.InformationBoxHead }}</p>
                  <p style="margin-bottom: 0;">
                    {{ i18nService.localize().CampaignWizardComponent.InformationBoxText }}
                  </p>
                </div>
                <div class="p-col-12">
                  <p-image src="/assets/images/Vorschau_Kampagne_Hintergrund.png" alt="Vorschau" [preview]="true"
                           [imageStyle]="{width:'100%'}"></p-image>
                </div>
                <div class="p-col-12">
                  <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.DisplayAdBoxHead }}</p>
                  <p style="margin-bottom: 0;">
                    {{ i18nService.localize().CampaignWizardComponent.DisplayAdBoxText }}
                  </p>
                </div>
                <div class="p-col-12">
                  <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.ClickAdBoxHead }}</p>
                  <p style="margin-bottom: 0;">
                    {{ i18nService.localize().CampaignWizardComponent.ClickAdBoxText }}
                  </p>
                </div>
                <div class="p-col-12">
                  <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.ChatAdBoxHead }}</p>
                  <p style="margin-bottom: 0;">
                    {{ i18nService.localize().CampaignWizardComponent.ChatAdBoxText }}
                  </p>
                </div>
              </div>
            </div>
            <div class="p-col-12" style="text-align: left; margin-top: 2em;">
              <button pButton pRipple type="button" (click)="cancel()" iconPos="left"
                      label="{{i18nService.localize().CampaignWizardComponent.CancelButton}}"
                      class="p-button-rounded p-button-danger" icon="fas fa-times" style="margin-right: .25em">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToNextStep()" iconPos="right"
                      icon="fas fa-chevron-right" style="margin-right: .25em"
                      label="{{i18nService.localize().CampaignWizardComponent.NextButton}}">
              </button>
              <p-messages severity="error" *ngIf="createCampaignError">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PageOneFillError }}
                    </span>
                </ng-template>
              </p-messages>
            </div>
          </div>
        </p-card>
      </div>

      <!-- Schritt 2: KPIs -->
      <div class="p-col-12" *ngIf="step === 1">
        <h2 class="card-headline">{{ i18nService.localize().CampaignWizardComponent.KPIsHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em 3em 2em 3em;">
            <div class="p-col-12">
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Type }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ getCampaignCombinationName() }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label for="campaignname"
                         class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.CampaignName }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <input id="campaignname" type="text" pInputText [(ngModel)]="campaignName"
                           style="width: 97%;margin-right: 0.5em;">
                    <i class="far fa-question-circle"
                       pTooltip="{{i18nService.localize().CampaignWizardComponent.CampaignNameTooltip}}"
                       tooltipPosition="left"></i>
                  </div>
                  <div class="p-col-10 p-offset-2">
                    <p-messages severity="error"
                                *ngIf="createCampaignError && (campaignName === undefined || campaignName.length === 0)">
                      <ng-template pTemplate>
                        <span class="custom-message">
                          {{ i18nService.localize().CampaignWizardComponent.CampaignNameError }}
                        </span>
                      </ng-template>
                    </p-messages>
                  </div>
                  <br />
                  <label for="displayname"
                         class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.DisplayName }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <input id="displayname" type="text" pInputText [(ngModel)]="displayName"
                           style="width: 97%;margin-right: 0.5em;">
                    <i class="far fa-question-circle"
                       pTooltip="{{i18nService.localize().CampaignWizardComponent.DisplayNameTooltip}}"
                       tooltipPosition="left"></i>
                  </div>
                  <div class="p-col-10 p-offset-2">
                    <p-messages severity="error"
                                *ngIf="createCampaignError && (displayName === undefined || displayName.length === 0)">
                      <ng-template pTemplate>
                        <span class="custom-message">
                          {{ i18nService.localize().CampaignWizardComponent.DisplayNameError }}
                        </span>
                      </ng-template>
                    </p-messages>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Language }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.LanguageText }}
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.LanguageTooltip}}"></i>
                    </p>
                    <div class="p-field-radiobutton">
                      <p-radioButton name="city" value="GERMAN" [(ngModel)]="language"
                                     inputId="language1"></p-radioButton>
                      <!--suppress XmlInvalidId -->
                      <label for="language1">{{ i18nService.localize().CampaignWizardComponent.German }}</label>
                    </div>
                    <!--                    <div class="p-field-radiobutton">-->
                    <!--                      <p-radioButton name="city" value="ENGLISH" [(ngModel)]="language" inputId="language2"></p-radioButton>-->
                    <!--                      <label for="language2">{{i18nService.localize().CampaignWizardComponent.English}}</label>-->
                    <!--                    </div>-->
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Branch }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.BranchText }}
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.BranchTooltip}}"></i>
                    </p>
                    <p-accordion *ngFor="let keywordgroup of keywordgroups">
                      <p-accordionTab header="{{keywordgroup.item_text}}">
                        <div class="p-grid">
                          <div class="p-col-12 p-md-5 p-lg-4 p-xl-3" *ngFor="let keyword of keywordgroup.item_group"
                               style="margin-bottom: 1em;">
                            <p-checkbox name="keyword" class="p-mr-1" value="{{keyword.item_id}}"
                                        [(ngModel)]="selectedKeywordItems"
                                        inputId="keyword{{keyword.item_text}}"
                                        (onChange)="updateKeywordNames(keyword)"></p-checkbox>
                            <label for="keyword{{keyword.item_text}}">{{ keyword.item_text }}</label>
                          </div>
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                    <div class="p-col-12">
                      <p-messages severity="error"
                                  *ngIf="createCampaignError && this.selectedKeywordItems.length === 0">
                        <ng-template pTemplate>
                        <span class="custom-message">
                          {{ i18nService.localize().CampaignWizardComponent.KeywordError }}
                        </span>
                        </ng-template>
                      </p-messages>
                    </div>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.StreamerDefinition }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.StreamerDefinitionText }}
                    </p>
                    <div class="p-field-radiobutton">
                      <p-checkbox name="smartyGroup" inputId="smartFocus" label="Balanced" [binary]="true"
                                  (onChange)="updateFocusAndStreamerGroup($event)" [(ngModel)]="smarty">
                      </p-checkbox>
                      <!--suppress XmlInvalidId -->
                      <!--                      <label for="smartFocus" (click)="updateFocusAndStreamerGroup($event)"></label>-->
                      <i class="far fa-question-circle" style="margin-left: 0.25em;"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.BalancedTooltip}}"></i>
                    </div>
                    <p-accordion (onClose)="setSmartBalance(true)" (onOpen)="setSmartBalance(false)">
                      <p-accordionTab header="{{i18nService.localize().CampaignWizardComponent.MoreFocusTypesTab}}"
                                      [(selected)]="openMoreOptions">
                        <div class="p-grid">
                          <div class="p-col-6">
                            <p>
                              {{ i18nService.localize().CampaignWizardComponent.AdFocus }}
                            </p>
                          </div>
                          <div class="p-col-6">
                            <p>
                              {{ i18nService.localize().CampaignWizardComponent.StreamerFocus }}
                            </p>
                          </div>
                          <div class="p-col-6">
                            <div class="p-field-radiobutton">
                              <p-radioButton name="focusGroup" value="intensive" inputId="intensive"
                                             [(ngModel)]="intensityFocus">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label
                                for="intensive">{{ i18nService.localize().CampaignWizardComponent.Intensiv }}</label>
                              <i class="far fa-question-circle" style="margin-left: 0.25em;"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.IntensivTooltip}}"></i>
                            </div>
                          </div>
                          <div class="p-col-6">
                            <div class="p-field-radiobutton">
                              <p-radioButton inputId="micro" name="streamerGroup" value="micro"
                                             [(ngModel)]="streamerFocus">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label
                                for="micro">{{ i18nService.localize().CampaignWizardComponent.MicroStreamer }}</label>
                              <i class="far fa-question-circle" style="margin-left: 0.25em;"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.MicroStreamerTooltip}}"></i>
                            </div>
                          </div>
                          <div class="p-col-6">
                            <div class="p-field-radiobutton">
                              <p-radioButton name="focusGroup" value="range" inputId="range"
                                             [(ngModel)]="intensityFocus">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label for="range">{{ i18nService.localize().CampaignWizardComponent.Range }}</label>
                              <i class="far fa-question-circle" style="margin-left: 0.25em;"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.RangeTooltip}}"></i>
                            </div>
                          </div>
                          <div class="p-col-6">
                            <div class="p-field-radiobutton">
                              <p-radioButton inputId="macro" name="streamerGroup" value="macro"
                                             [(ngModel)]="streamerFocus">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label
                                for="macro">{{ i18nService.localize().CampaignWizardComponent.MacroStreamer }}</label>
                              <i class="far fa-question-circle" style="margin-left: 0.25em;"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.MacroStreamerTooltip}}"></i>
                            </div>
                          </div>
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      <p-checkbox [(ngModel)]="activeStreamerAge" [binary]="true" inputId="streamerAge" id="streamerAge"
                                  [style]="{'margin-right': '0.25em'}">
                      </p-checkbox>
                      {{ i18nService.localize().CampaignWizardComponent.StreamerAge }}<i class="far fa-question-circle"
                                                                                         pTooltip="{{i18nService.localize().CampaignWizardComponent.StreamerAgeTooltip}}"></i>
                    </p>

                    <div class="slider" style="margin-top: 1em;">
                      <div class="slider">
                      <span class="value-span">{{ streamerAgeRangeValues[0] + ' - ' + streamerAgeRangeValues[1] }}
                        {{ i18nService.localize().CampaignWizardComponent.Years }}</span>
                      </div>
                      <p-slider [(ngModel)]="streamerAgeRangeValues" [min]="18" [max]="60" [range]="true"
                                (onChange)="checkStreamerRange($event)" *ngIf="showStreamerSlider"
                                [disabled]="!activeStreamerAge">
                      </p-slider>
                    </div>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.TargetDefinition }}</label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.TargetDefinitionText }}
                    </p>
                    <div class="p-grid" style="margin-bottom: 1em;">
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-checkbox name="genderGroup" value="male"
                                    label="{{i18nService.localize().CampaignWizardComponent.Male}}"
                                    [(ngModel)]="selectedGender">
                        </p-checkbox>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-checkbox name="genderGroup" value="female"
                                    label="{{i18nService.localize().CampaignWizardComponent.Female}}"
                                    [(ngModel)]="selectedGender">
                        </p-checkbox>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-checkbox name="genderGroup" value="divers"
                                    label="{{i18nService.localize().CampaignWizardComponent.Divers}}"
                                    [(ngModel)]="selectedGender">
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="p-col-10 p-offset-2">
                    <p-messages severity="error" *ngIf="createCampaignError && this.selectedGender.length === 0">
                      <ng-template pTemplate>
                        <span class="custom-message">
                          {{ i18nService.localize().CampaignWizardComponent.GenderError }}
                        </span>
                      </ng-template>
                    </p-messages>
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.TargetAge }}
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.TargetAgeTooltip}}"></i>
                    </p>
                    <div class="slider">
                      <div class="slider">
                      <span class="value-span">{{ ageRangeValues[0] + ' - ' + ageRangeValues[1] }}
                        {{ i18nService.localize().CampaignWizardComponent.Years }}</span>
                      </div>
                      <p-slider [(ngModel)]="ageRangeValues" [min]="18" [max]="60" [range]="true"
                                (onChange)="checkRange($event)" *ngIf="showSlider"></p-slider>
                    </div>
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.GameCategoriesText }}
                      <i class="far fa-question-circle"
                         pTooltip="{{i18nService.localize().CampaignWizardComponent.GameCategoriesTooltip}}"></i>
                    </p>
                    <div class="p-grid" style="margin-bottom: 1em;">
                      <!-- 5 Branchen für die Vorauswahl -->
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[60]" inputId="Chatting"
                                            (onChange)="updateGameKeywordNames($event, this.chattingGameKeyword)">
                        </p-triStateCheckbox>
                        <!--suppress XmlInvalidId -->
                        <label for="Chatting" style="margin-left: 0.5em;">{{ chattingGameKeyword.item_text }}</label>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[61]" inputId="Musik"
                                            (onChange)="updateGameKeywordNames($event, this.musicGameKeyword)">
                        </p-triStateCheckbox>
                        <!--suppress XmlInvalidId -->
                        <label for="Musik" style="margin-left: 0.5em;">{{ musicGameKeyword.item_text }}</label>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[62]" inputId="Action"
                                            (onChange)="updateGameKeywordNames($event, this.actionGameKeyword)">
                        </p-triStateCheckbox>
                        <!--suppress XmlInvalidId -->
                        <label for="Action" style="margin-left: 0.5em;">{{ actionGameKeyword.item_text }}</label>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[63]" inputId="Rollplay"
                                            (onChange)="updateGameKeywordNames($event, this.roleplayGameKeyword)">
                        </p-triStateCheckbox>
                        <!--suppress XmlInvalidId -->
                        <label for="Rollplay" style="margin-left: 0.5em;">{{ roleplayGameKeyword.item_text }}</label>
                      </div>
                      <div class="p-col-12 p-md-5 p-lg-4 p-xl-2">
                        <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[64]" inputId="Sports"
                                            (onChange)="updateGameKeywordNames($event, this.sportsGameKeyword)">
                        </p-triStateCheckbox>
                        <!--suppress XmlInvalidId -->
                        <label for="Sports" style="margin-left: 0.5em;">{{ sportsGameKeyword.item_text }}</label>
                      </div>
                    </div>
                    <p-accordion>
                      <p-accordionTab header="{{i18nService.localize().CampaignWizardComponent.MoreGameCategoriesTab}}">
                        <div class="p-grid">
                          <div class="p-col-12 p-md-5 p-lg-4 p-xl-4" *ngFor="let keyword of gameKeywords; index as i"
                               style="margin-bottom: 1em;">
                            <p-triStateCheckbox [(ngModel)]="selectedGameKeywordBooleans[i]"
                                                inputId="{{keyword.item_text}}"
                                                (onChange)="updateGameKeywordNames($event, keyword)">
                            </p-triStateCheckbox>
                            <!--suppress XmlInvalidId -->
                            <label for="{{keyword.item_text}}"
                                   style="margin-left: 0.5em;">{{ keyword.item_text }}</label>
                          </div>
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    <p-messages severity="error" *ngIf="selectedGameKeywordNamesError">
                      <ng-template pTemplate>
                        <span class="custom-message">
                          {{ i18nService.localize().CampaignWizardComponent.GameKeywordError }}
                        </span>
                      </ng-template>
                    </p-messages>
                  </div>
                  <!-- Erstmal nur auskommentiert, wer weiß wann die Fachseite wieder ihre Meinung ändert :)  -->
                  <!--                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>-->
                  <!--                  <div class="p-col-12 p-md-10">-->
                  <!--                    <p>-->
                  <!--                      {{i18nService.localize().CampaignWizardComponent.DontUse18GameCategoryText}}-->
                  <!--                      <i class="far fa-question-circle" pTooltip="{{i18nService.localize().CampaignWizardComponent.DontUse18GameCategoryTooltip}}"></i>-->
                  <!--                    </p>-->
                  <!--                    <div class="p-field-radiobutton">-->
                  <!--                      <p-radioButton name="over18" value="yes" [(ngModel)]="over18Content" inputId="yes18"-->
                  <!--                                     label="{{i18nService.localize().CampaignWizardComponent.Yes}}"-->
                  <!--                                     (onClick)="updateOver18Content($event)"></p-radioButton>-->
                  <!--                    </div>-->
                  <!--                    <div class="p-field-radiobutton">-->
                  <!--                      <p-radioButton name="over18" value="no" [(ngModel)]="over18Content" inputId="no18"-->
                  <!--                                     label="{{i18nService.localize().CampaignWizardComponent.No}}"-->
                  <!--                                     (onClick)="updateOver18Content($event)"></p-radioButton>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>

              </div>

            </div>
            <div class="p-col-12" style="text-align: left;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()"
                      icon="fas fa-chevron-left"
                      label="{{i18nService.localize().CampaignWizardComponent.BackButton}}" iconPos="left"
                      style="margin-right: .25em">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" iconPos="right" (click)="goToNextStep()"
                      icon="fas fa-chevron-right"
                      label="{{i18nService.localize().CampaignWizardComponent.NextButton}}"
                      style="margin-right: .25em">
              </button>
              <p-messages severity="error" *ngIf="createCampaignError || selectedGameKeywordNamesError">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PageTwoFillError }}
                    </span>
                </ng-template>
              </p-messages>
            </div>
          </div>
        </p-card>
      </div>

      <!-- Schritt 3: Anzeige -->
      <div class="p-col-12" *ngIf="this.step === 2">
        <h2 class="card-headline">{{ i18nService.localize().CampaignWizardComponent.AdsHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em 3em 2em 3em;">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12 p-md-8">
                  <div class="p-fluid">
                    <div class="p-field p-grid">
                      <label for="description" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                        {{ i18nService.localize().CampaignWizardComponent.CampaignInformation }}:
                        <i class="far fa-question-circle"
                           pTooltip="{{i18nService.localize().CampaignWizardComponent.CampaignInformationTooltip}}"></i>
                      </label>
                      <div class="p-col-12 p-md-10" style="padding: 2em;">
                        <textarea id="description" type="text" [(ngModel)]="description" [maxlength]="500"
                                  (ngModelChange)="updateLetterCounter()" pInputTextarea
                                  placeholder="{{i18nService.localize().CampaignWizardComponent.CampaignDescriptionPlaceholder}}">
                        </textarea>
                        <p style="margin-top: 0; color: #adadad; font-size: small">
                          {{ letterCount }} {{ i18nService.localize().CampaignWizardComponent.WordsLeft }}
                        </p>
                      </div>
                      <div class="p-col-10 p-offset-2">
                        <p-messages severity="error"
                                    *ngIf="createCampaignError && (description === undefined || description.length === 0)">
                          <ng-template pTemplate>
                            <span class="custom-message">
                              {{ i18nService.localize().CampaignWizardComponent.DescriptionError }}
                            </span>
                          </ng-template>
                        </p-messages>
                      </div>
                    </div>
                  </div>
                  <p-accordion [style]="{'padding-right': '1.5em'}" (onClose)="openOtherPanel()">

                    <!-- Display Banner Bilder -->
                    <p-accordionTab *ngIf="isCampaignMix() || isDisplayCampaignOnly()"
                                    [(selected)]="showDisplayBannerPanel">
                      <ng-template pTemplate="header">
                        <div class="p-grid" style="width: 100%">
                          <div class="p-col">
                            {{ i18nService.localize().CampaignWizardComponent.YourDisplayAdsTab }}
                          </div>
                          <div class="p-col" style="text-align: end">
                            {{ i18nService.localize().CampaignWizardComponent.ChosenBanners }}{{ getNumberOfDisplayBanners() }}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <div class="p-grid">
                          <div class="p-col-12">
                            <p style="margin-top: 1em;">
                              {{ i18nService.localize().CampaignWizardComponent.DisplayBannerText }}
                              <i class="far fa-question-circle"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.DisplayBannerTooltip}}"></i>
                            </p>
                            <p class="error" *ngIf="!picturesOk">
                              {{ i18nService.localize().CampaignWizardComponent.DisplayBannerError }}
                            </p>
                            <p class="error" *ngIf="imageLimitError">
                              {{ i18nService.localize().CampaignWizardComponent.ImageLimitError }}
                            </p>
                            <p-fileUpload name="pictures[]" [multiple]=true accept="image/*" [maxFileSize]=10000000
                                          id="displayBanner"
                                          [showUploadButton]="false" [files]="getCurrentPictures()"
                                          (onSelect)="setPictures($event)"
                                          [fileLimit]="3" (onRemove)="removePicture($event)" (onClear)="clearPictures()"
                                          chooseLabel="{{i18nService.localize().CampaignWizardComponent.ChooseButton}}"
                                          cancelLabel="{{i18nService.localize().CampaignWizardComponent.DeleteButton}}">
                            </p-fileUpload>
                          </div>

                          <div class="p-col-12" *ngIf="this.globalService.agency">
                            <p style="margin-top: 1em;">
                              {{ i18nService.localize().CampaignWizardComponent.AgencyDisplayBannerText }}
                            </p>
                            <p-pickList [source]="availablePicturesForDisplay" [target]="usedPicturesForDisplay"
                                        [dragdrop]="true" [showSourceControls]="false"
                                        [showTargetControls]="false" [responsive]="true"
                                        sourceHeader="{{i18nService.localize().CampaignWizardComponent.AvailableAgencyPictures}}"
                                        targetHeader="{{i18nService.localize().CampaignWizardComponent.SelectedAgencyPictures}}"
                                        (onMoveToTarget)="updateAddPicture($event)"
                                        (onMoveToSource)="updateRemovePicture($event)"
                                        (onMoveAllToSource)="updateRemovePicture($event)"
                                        (onMoveAllToTarget)="updateAddPicture($event)">
                              <ng-template let-picture pTemplate="item">
                                <div>
                                  <img src="/picture/{{picture.id}}" style="display:inline-block;margin:2px 0 2px 2px"
                                       width="48">
                                  <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{ picture.name }}</div>
                                </div>
                              </ng-template>
                            </p-pickList>
                          </div>

                          <div class="p-col-12">
                            <p-messages severity="error" *ngIf="createCampaignError && !this.campaignPixOk">
                              <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.BannerError }}
                                </span>
                              </ng-template>
                            </p-messages>
                          </div>

                          <div class="p-col-12 p-sm-12 p-md-12">
                            <div class="p-grid">
                              <div class="p-col-12 p-sm-8 p-md-4 p-md-offset-2 p-sm-offset-1">
                                <p class="price_group_headline">
                                  {{ i18nService.localize().CampaignWizardComponent.AdGroup }}1
                                </p>
                                <p-card>
                                  <ng-template pTemplate="header">
                                    <img alt="png" src="/assets/images/jpeg_header.png"
                                         style="border-top-left-radius: 40px; border-top-right-radius: 40px;">
                                  </ng-template>
                                  <div class="p-grid">
                                    <div class="p-col-12">
                                      <ul style="list-style-type:none; padding-left: 1.7em;">
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          {{ i18nService.localize().CampaignWizardComponent.Memorability }}8/10
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          3 {{ i18nService.localize().CampaignWizardComponent.MinutesPerView }}
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          300 x 250 Pixel
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          {{ i18nService.localize().CampaignWizardComponent.Size }} = 10 MB
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="p-col-7" style="border-top: 1px solid gray;margin: 0 3.5em 0 3.5em">
                                      <p class="tkp_10">
                                        TKP 10€
                                      </p>
                                    </div>
                                  </div>
                                </p-card>
                              </div>
                              <div class="p-col-12 p-sm-8 p-md-4">
                                <p class="price_group_headline">
                                  {{ i18nService.localize().CampaignWizardComponent.AdGroup }}2
                                </p>
                                <p-card>
                                  <ng-template pTemplate="header">
                                    <img alt="gif" src="/assets/images/gif_header.png"
                                         style="border-top-left-radius: 40px; border-top-right-radius: 40px;">
                                  </ng-template>
                                  <div class="p-grid">
                                    <div class="p-col-12">
                                      <ul style="list-style-type:none; padding-left: 1.7em;">
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          {{ i18nService.localize().CampaignWizardComponent.Memorability }}10/10
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          3 {{ i18nService.localize().CampaignWizardComponent.MinutesPerView }}
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          300 x 250 Pixel
                                        </li>
                                        <li style="margin: 1em 0 1em 1em;">
                                          <img alt="check" src="/assets/images/green_check.png" style="width: 30px;">
                                          {{ i18nService.localize().CampaignWizardComponent.Size }} = 10 MB
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="p-col-7" style="border-top: 1px solid gray;margin: 0 3.5em 0 3.5em">
                                      <p class="tkp_20">
                                        TKP 20€
                                      </p>
                                    </div>
                                  </div>
                                </p-card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-accordionTab>

                    <!-- Klick Banner Bilder -->
                    <p-accordionTab *ngIf="isCampaignMix() || isClickCampaignOnly()"
                                    [(selected)]="showClickBannerPanel">
                      <ng-template pTemplate="header">
                        <div class="p-grid" style="width: 100%">
                          <div class="p-col">
                            {{ i18nService.localize().CampaignWizardComponent.YourClickAdsTab }}
                          </div>
                          <div class="p-col" style="text-align: end">
                            {{ i18nService.localize().CampaignWizardComponent.ChosenBanners }}
                            {{ getNumberOfClickBanners() }}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <div class="p-grid">
                          <div class="p-col-12">
                            <p>
                              {{ i18nService.localize().CampaignWizardComponent.LinkText }}
                            </p>
                            <input id="link" type="url" pInputText [(ngModel)]="affiliateLink" style="width: 100%"
                                   placeholder="https://example.com" (change)="checkURL()">
                            <p class="error" *ngIf="wrongURL">
                              {{ i18nService.localize().CampaignWizardComponent.LinkError }}
                            </p>
                          </div>
                          <div class="p-col-12">
                            <p-messages severity="error" *ngIf="createCampaignError && !this.affiliateLinkOk">
                              <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.LinkMissingError }}
                                </span>
                              </ng-template>
                            </p-messages>
                          </div>
                          <div class="p-col-12">
                            <p style="margin-top: 1em;">
                              {{ i18nService.localize().CampaignWizardComponent.ClickAdsText }}
                              <i class="far fa-question-circle"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.ClickAdsTooltip}}"></i>
                            </p>
                            <p class="error" *ngIf="!bannersOk">
                              {{ i18nService.localize().CampaignWizardComponent.ClickAdsError }}
                            </p>
                            <p class="error" *ngIf="imageLimitError">
                              {{ i18nService.localize().CampaignWizardComponent.ImageLimitError }}
                            </p>
                            <p-fileUpload name="banners[]" [multiple]=true accept="image/jpeg, image/png"
                                          [maxFileSize]=10000000 id="banner"
                                          [showUploadButton]="false" [files]="getCurrentBanners()"
                                          (onSelect)="setBanners($event)"
                                          [fileLimit]="3" (onRemove)="removeBanner($event)" (onClear)="clearBanners()"
                                          chooseLabel="{{i18nService.localize().CampaignWizardComponent.ChooseButton}}"
                                          cancelLabel="{{i18nService.localize().CampaignWizardComponent.DeleteButton}}">
                            </p-fileUpload>
                          </div>
                          <div class="p-col-12" *ngIf="this.globalService.agency">
                            <p style="margin-top: 1em;">
                              {{ i18nService.localize().CampaignWizardComponent.AgencyClickBannerText }}
                            </p>

                            <p-pickList [source]="availablePicturesForClick" [target]="usedPicturesForClick"
                                        [dragdrop]="true" [showSourceControls]="false"
                                        [showTargetControls]="false" [responsive]="true"
                                        *ngIf="this.globalService.agency"
                                        sourceHeader="{{i18nService.localize().CampaignWizardComponent.AvailableAgencyPictures}}"
                                        targetHeader="{{i18nService.localize().CampaignWizardComponent.SelectedAgencyPictures}}">
                              <ng-template let-picture pTemplate="item">
                                <div>
                                  <img src="/picture/{{picture.id}}" style="display:inline-block;margin:2px 0 2px 2px"
                                       width="48">
                                  <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{ picture.name }}</div>
                                </div>
                              </ng-template>
                            </p-pickList>
                          </div>

                          <div class="p-col-12">
                            <p-messages severity="error" *ngIf="createCampaignError && !this.affiliatePixOk">
                              <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.BannerError }}
                                </span>
                              </ng-template>
                            </p-messages>
                          </div>

                          <div class="p-col-12">
                            <p>
                              {{ i18nService.localize().CampaignWizardComponent.ChatBotText }}
                              <i class="far fa-question-circle"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.ChatBotTooltip}}"></i>
                            </p>
                            <p class="error" *ngIf="chatMessageContainsLinkError">
                              {{ i18nService.localize().CampaignWizardComponent.ChatBotError }}
                            </p>
                            <div class="p-fluid">
                              <textarea id="chatMessage" type="text" [(ngModel)]="chatMessage" [maxlength]="150"
                                        (ngModelChange)="updateChatMessageLetterCounter()" pInputTextarea
                                        placeholder="{{i18nService.localize().CampaignWizardComponent.ChatBotPlaceholder}}">
                              </textarea>
                              <p style="margin-top: 0; color: #adadad; font-size: small">
                                {{ chatLetterCount }} {{ i18nService.localize().CampaignWizardComponent.WordsLeft }}
                              </p>
                              <p class="error" *ngIf="chatMessageEmpty && !isDisplayCampaignOnly()">
                                {{ i18nService.localize().CampaignWizardComponent.ChatBotEmptyError }}
                              </p>
                            </div>
                          </div>
                          <div class="p-col-12" *ngIf="this.googleTagsEnabled">
                            <p>
                              {{ i18nService.localize().CampaignWizardComponent.TagManagerIdText }}
                              <i class="far fa-question-circle"
                                 pTooltip="{{i18nService.localize().CampaignWizardComponent.TagManagerIdTooltip}}"></i>
                            </p>
                            <p style="margin-top: 0; font-size: large;font-weight: bold;">
                              {{ googleTagId }}
                            </p>
                          </div>

                        </div>
                      </ng-template>
                    </p-accordionTab>
                  </p-accordion>
                </div>
                <div class="p-col-12 p-md-4">
                  <div class="p-grid info-box">
                    <div class="p-col-12">
                      <p
                        class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.InformationBoxHead }}</p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.InformationBoxText }}
                      </p>
                    </div>
                    <div class="p-col-12">
                      <p-image src="/assets/images/Vorschau_Kampagne_Hintergrund.png" alt="Vorschau" [preview]="true"
                               [imageStyle]="{width:'100%'}"></p-image>
                    </div>
                    <div class="p-col-12">
                      <p
                        class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.DisplayAdBoxHead }}</p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.DisplayAdBoxText }}
                      </p>
                    </div>
                    <div class="p-col-12">
                      <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.ClickAdBoxHead }}</p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.ClickAdBoxText }}
                      </p>
                    </div>
                    <div class="p-col-12">
                      <p class="info-box-header">{{ i18nService.localize().CampaignWizardComponent.ChatAdBoxHead }}</p>
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.ChatAdBoxText }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12" style="text-align: left;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()" iconPos="left"
                      icon="fas fa-chevron-left" label="{{i18nService.localize().CampaignWizardComponent.BackButton}}"
                      style="margin-right: .25em">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToNextStep()"
                      icon="fas fa-chevron-right" iconPos="right"
                      label="{{i18nService.localize().CampaignWizardComponent.NextButton}}"
                      style="margin-right: .25em">
              </button>
              <p-messages severity="error" *ngIf="createCampaignError">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PageThreeFillError }}
                    </span>
                </ng-template>
              </p-messages>
            </div>
          </div>
        </p-card>
      </div>

      <!-- Schritt 4: Budgetplanung -->
      <div class="p-col-12" *ngIf="this.step === 3">
        <h2 class="card-headline">{{ i18nService.localize().CampaignWizardComponent.BudgetHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em 3em 2em 3em;">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12 p-md-7">
                  <div class="p-fluid">
                    <div class="p-field p-grid">
                      <p
                        class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Budget }}
                        :</p>
                      <div class="p-col-12 p-md-10">
                        <p>
                          {{ i18nService.localize().CampaignWizardComponent.BudgetText }}
                          <i class="far fa-question-circle"
                             pTooltip="{{i18nService.localize().CampaignWizardComponent.BudgetTooltip}}"></i>
                        </p>

                        <div class="p-fluid">
                          <div class="p-field p-grid">
                            <div class="p-field-radiobutton p-col-12 p-mb-3 p-md-3 p-mb-md-0">
                              <p-radioButton name="budgetSelect" value="total" inputId="total"
                                             [(ngModel)]="budgetSelect" (onClick)="updateBudgetInputsTotal()">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label for="total" style="margin-left: 0.5em;">
                                {{ i18nService.localize().CampaignWizardComponent.TotalBudget }}:
                              </label>
                            </div>
                            <div class="p-col-12 p-md-9">
                              <p-inputNumber [(ngModel)]="totalBudget" mode="currency" currency="EUR" locale="de-DE"
                                             (ngModelChange)="updateBudgets()" id="totalBudget" [min]="0"
                                             [disabled]="disableTotalBudget">
                              </p-inputNumber>
                            </div>
                          </div>
                          <div class="p-field p-grid">
                            <div class="p-field-radiobutton p-col-12 p-mb-3 p-md-3 p-mb-md-0">
                              <p-radioButton name="budgetSelect" value="daily" inputId="daily"
                                             [(ngModel)]="budgetSelect" (onClick)="updateBudgetInputsDaily()">
                              </p-radioButton>
                              <!--suppress XmlInvalidId -->
                              <label for="daily" style="margin-left: 0.5em;">
                                {{ i18nService.localize().CampaignWizardComponent.DailyBudget }}:
                              </label>
                            </div>
                            <div class="p-col-12 p-md-9">
                              <p-inputNumber [(ngModel)]="dailyBudget" mode="currency" currency="EUR" locale="de-DE"
                                             (ngModelChange)="updateBudgets()" id="dailyBudget" [min]="0"
                                             [disabled]="disableDailyBudget">
                              </p-inputNumber>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="p-col-12">
                        <p-messages severity="error"
                                    *ngIf="createCampaignError && (this.totalBudget === 0 || this.dailyBudget === 0)">
                          <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.BudgetError }}
                                </span>
                          </ng-template>
                        </p-messages>
                      </div>

                      <!-- ToDo: Implementieren -->
                      <!--                      <p class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{i18nService.localize().CampaignWizardComponent.TimePlanner}}:</p>-->
                      <!--                      <div class="p-col-12 p-md-10">-->
                      <!--                        <p>-->
                      <!--                          {{i18nService.localize().CampaignWizardComponent.AllDay}}<i class="far fa-question-circle" pTooltip="{{i18nService.localize().CampaignWizardComponent.AllDayTooltip}}"></i>-->
                      <!--                        </p>-->
                      <!--                      </div>-->

                      <div class="p-col-12">&nbsp;</div>

                      <p class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                        {{ i18nService.localize().CampaignWizardComponent.StartEndDate }}:
                      </p>
                      <div class="p-col-12 p-md-10">
                        <div class="p-grid">
                          <div class="p-col-12 p-md-6">
                            <label for="startDate">{{ i18nService.localize().CampaignWizardComponent.StartDate }}: <i
                              class="far fa-question-circle"
                              pTooltip="{{i18nService.localize().CampaignWizardComponent.StartEndDateTooltip}}"></i></label>
                            <p-calendar [(ngModel)]="startDate" dateFormat="dd.mm.yy"
                                        [disabled]="!(this.dailyBudget>0 || this.totalBudget>0)"
                                        (onSelect)="updateDaysBetween()" (onInput)="updateDaysBetween()"
                                        [minDate]="minDate" id="startDate">
                            </p-calendar>
                          </div>
                          <div class="p-col-12 p-md-6">
                            <label for="endDate">{{ i18nService.localize().CampaignWizardComponent.EndDate }}:</label>
                            <p-calendar [(ngModel)]="endDate" dateFormat="dd.mm.yy"
                                        [disabled]="!(this.dailyBudget>0 || this.totalBudget>0) && this.startDate === undefined"
                                        (onSelect)="updateDaysBetween()" (onInput)="updateDaysBetween()"
                                        [minDate]="startDate" id="endDate">
                            </p-calendar>
                          </div>
                        </div>
                      </div>

                      <div class="p-col-12">
                        <p-messages severity="error"
                                    *ngIf="createCampaignError && (this.startDate === undefined || this.endDate === undefined)">
                          <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.StartDateError }}
                                </span>
                          </ng-template>
                        </p-messages>
                        <p-messages severity="error" *ngIf="tooFewDaysError">
                          <ng-template pTemplate>
                                <span class="custom-message">
                                  {{ i18nService.localize().CampaignWizardComponent.TooFewDaysError }}
                                </span>
                          </ng-template>
                        </p-messages>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="p-col-12 p-md-5">
                  <div class="p-fluid">
                    <div class="p-field p-grid">
                      <p class="p-col-12" style="font-weight: bold">
                        {{ i18nService.localize().CampaignWizardComponent.Overview }}:
                      </p>

                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">
                        {{ i18nService.localize().CampaignWizardComponent.TotalBudget }}:
                      </p>
                      <div class="p-col-12 p-md-9">
                        <p style="font-weight: bold">
                          {{ totalBudgetString }} € (Netto)
                        </p>
                        <p style="font-weight: bold">
                          {{ totalBudgetWithTaxString }} € (Brutto)
                        </p>
                      </div>
                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">
                        {{ i18nService.localize().CampaignWizardComponent.DailyBudget }}:
                      </p>
                      <div class="p-col-12 p-md-9">
                        <p style="font-weight: bold">
                          {{ i18nService.localize().CampaignWizardComponent.Round }} {{ dailyBudgetString }} €
                        </p>
                      </div>
                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0"
                         *ngIf="agencyService.agency && (agencyService.agency.is_balance_enabled && agencyService.agency.balance > 0 && !isBalanceDateError)">
                        Verfügbares Werbebudget:
                      </p>
                      <div class="p-col-12 p-md-9"
                           *ngIf="agencyService.agency && (agencyService.agency.is_balance_enabled && agencyService.agency.balance > 0 && !isBalanceDateError)">
                        <p style="font-weight: bold">
                          {{
                            agencyService.agency.balance.toLocaleString('de-DE', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                          }} €
                        </p>
                      </div>
                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0" *ngIf="isCampaignMix()||isDisplayCampaignOnly()">
                        {{ i18nService.localize().CampaignWizardComponent.DisplayPercent }}:
                      </p>
                      <div class="p-col-12 p-md-9" *ngIf="isCampaignMix()||isDisplayCampaignOnly()">
                        <p style="font-weight: bold">
                          {{ totalDisplayBudgetString }} € = {{ views }} Views
                        </p>
                      </div>
                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0" *ngIf="isCampaignMix()||isClickCampaignOnly()">
                        {{ i18nService.localize().CampaignWizardComponent.ClickPercent }}:
                      </p>
                      <div class="p-col-12 p-md-9" *ngIf="isCampaignMix()||isClickCampaignOnly()">
                        <p>
                          {{ totalClickBudgetString }} €
                          = {{ clicks }} {{ i18nService.localize().CampaignWizardComponent.Clicks }}
                        </p>
                      </div>
                      <p class="p-col-12 p-mb-3 p-md-3 p-mb-md-0">
                        {{ i18nService.localize().CampaignWizardComponent.Runtime }}:
                      </p>
                      <div class="p-col-12 p-md-9">
                        <p style="font-weight: bold">
                          {{ daysBetween }} {{ i18nService.localize().CampaignWizardComponent.Days }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12" style="text-align: left; margin-top: 2em;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()" iconPos="left"
                      icon="fas fa-chevron-left" label="{{i18nService.localize().CampaignWizardComponent.BackButton}}"
                      style="margin-right: .25em">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToNextStep()"
                      icon="fas fa-chevron-right" iconPos="right"
                      label="{{i18nService.localize().CampaignWizardComponent.NextButton}}"
                      style="margin-right: .25em">
              </button>
              <p-messages severity="error" *ngIf="createCampaignError">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PageFourFillError }}
                    </span>
                </ng-template>
              </p-messages>
            </div>
          </div>
        </p-card>
      </div>

      <!-- Schritt 5: Überprüfung -->
      <div class="p-col-12" *ngIf="this.step === 4">
        <h2 class="card-headline">{{ i18nService.localize().CampaignWizardComponent.ReviewHeader }}</h2>
        <p-card>
          <div class="p-grid" style="padding: 2em;">
            <div class="p-col-12">
              <p style="font-weight: bold; font-size: large">
                {{ i18nService.localize().CampaignWizardComponent.CampaignSettingsHead }}:
              </p>
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.CampaignName }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ campaignName }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.DisplayName }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ displayName }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.CampaignInformation }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ description }}
                  </div>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large">
                {{ i18nService.localize().CampaignWizardComponent.TargetHead }}:
              </p>
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Objective }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ getCampaignCombinationName() }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Branch }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <div class="p-grid">
                      <div [ngClass]="{'p-col-1': keyword.name.length <= 10, 'p-col-2': keyword.name.length > 10}"
                           *ngFor="let keyword of selectedKeywords">
                        <p style="word-wrap: break-word;">
                          {{ keyword.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Language }}
                    :</label>
                  <!-- Im Moment immer Deutsch -->
                  <div class="p-col-12 p-md-10">
                    {{ i18nService.localize().CampaignWizardComponent.German }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Focus }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ getFocusType() }}
                  </div>
                </div>
                <div class="p-field p-grid" *ngIf="activeStreamerAge">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.StreamerAge }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <span>{{ streamerAgeRangeValues[0] + ' - ' + streamerAgeRangeValues[1] }} {{ i18nService.localize().CampaignWizardComponent.Years }}</span>
                  </div>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large">
                {{ i18nService.localize().CampaignWizardComponent.StreamerTargetHead }}:
              </p>
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.TargetClients }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ getGenderTarget() }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Age }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <span>{{ ageRangeValues[0] + ' - ' + ageRangeValues[1] }} {{ i18nService.localize().CampaignWizardComponent.Years }}</span>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.StreamCategories }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <div class="p-grid">
                      <div class="p-col-2">
                        <p style="font-weight: bold;">
                          {{ i18nService.localize().CampaignWizardComponent.Advertise }}:
                        </p>
                      </div>
                      <div class="p-col-1" *ngFor="let keyword of selectedGameKeywordNames">
                        <p>
                          {{ keyword }}
                        </p>
                      </div>
                    </div>
                    <div class="p-grid">
                      <div class="p-col-2">
                        <p style="font-weight: bold;">
                          {{ i18nService.localize().CampaignWizardComponent.DontAdvertise }}:
                        </p>
                      </div>
                      <div class="p-col-1" *ngFor="let keyword of selectedNonGameKeywordNames">
                        <p>
                          {{ keyword }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large" *ngIf="isDisplayCampaignOnly() || isCampaignMix()">
                {{ i18nService.localize().CampaignWizardComponent.DisplayAdsHead }}:
              </p>
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <div class="p-col-12">
                    <div class="p-grid">
                      <div *ngFor="let file of uploadedPicturesBackup" class="p-col-2" style="margin-top: 1em;">
                        <img [src]="getPicURL(file)" width="250" alt="banner">
                        <!--                        <p style="margin-top: 0">{{file.name}}</p>-->
                      </div>
                      <!--                      <div *ngIf="this.globalService.agency">-->
                      <div *ngFor="let pic of usedPicturesForDisplay" class="p-col-2" style="margin-top: 1em;">
                        <img src="/picture/{{pic.id}}" width="250" alt="banner">
                        <!--                          <p style="margin-top: 0">{{pic.name}}</p>-->
                      </div>
                      <!--                      </div>-->
                    </div>
                  </div>

                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.TypeOfAd }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    <div *ngIf="!bannerContainsAnimatedPicture" style="margin-top: 1em;">
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.StaticAd }}{{ getTKP() }} €
                      </p>
                    </div>
                    <div *ngIf="bannerContainsAnimatedPicture" style="margin-top: 1em;">
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.DynamicAd }}{{ getTKP() }} €
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large" *ngIf="isClickCampaignOnly() || isCampaignMix()">
                {{ i18nService.localize().CampaignWizardComponent.ClickAdsHead }}:
              </p>
              <div class="p-fluid" *ngIf="!isDisplayCampaignOnly()">
                <div class="p-field p-grid">
                  <div class="p-col-12">
                    <div class="p-grid">
                      <div *ngFor="let file of uploadedBannersBackup" class="p-col-2" style="margin-top: 1em;">
                        <img [src]="getPicURL(file)" width="250" alt="banner">
                        <!--                        <p style="margin-top: 0">{{file.name}}</p>-->
                      </div>
                      <!--                      <div *ngIf="this.globalService.agency">-->
                      <div *ngFor="let pic of usedPicturesForClick" class="p-col-2" style="margin-top: 1em;">
                        <img src="/picture/{{pic.id}}" width="250" alt="banner">
                        <!--                          <p style="margin-top: 0">{{pic.name}}</p>-->
                      </div>
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Link:</label>
                  <div class="p-col-12 p-md-10">
                    {{ affiliateLink }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.TypeOfAd }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ i18nService.localize().CampaignWizardComponent.ClickLinkAd }}{{ getReadableCPC() }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Chatbot }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ chatMessage }}
                  </div>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large">
                Vorschau:
              </p>
              <div class="p-fluid">
                <div
                  style="background-image: url('/assets/images/Vorschau_Kampagne_Hintergrund.png'); width: 1000px; height: 650px; background-size: contain; background-repeat: no-repeat;"
                  *ngIf="isDisplayCampaignOnly()"
                >
                  <p-carousel *ngIf="uploadedPicturesBackup.length > 1; else onlyOneDisplayPicture" class="displayOnlyCarousel"
                              [value]="uploadedPicturesBackup" [circular]="true"
                              [autoplayInterval]="3000" [numVisible]="1" [numScroll]="1" [showIndicators]="false"
                              [showNavigators]="false">
                    <ng-template pTemplate="item" let-file>
                      <div class="p-col-12">
                        <img [src]="getPicURL(file)" width="100" alt="banner">
                      </div>
                    </ng-template>
                  </p-carousel>
                  <ng-template #onlyOneDisplayPicture>
                    <div *ngFor="let file of uploadedPicturesBackup" class="p-col-2 displayOnlyCarousel"
                         style="margin-top: 1em;">
                      <img [src]="getPicURL(file)" width="100" alt="banner">
                    </div>
                  </ng-template>
                </div>

                <div
                  style="background-image: url('/assets/images/Vorschau_Kampagne_Hintergrund.png'); width: 1000px; height: 650px; background-size: contain; background-repeat: no-repeat;"
                  *ngIf="!isDisplayCampaignOnly()"
                >
                  <p-carousel *ngIf="uploadedPicturesBackup.length > 1; else onlyOneDisplayPicture" class="displayOnlyCarouselMultiple" [value]="uploadedPicturesBackup" [circular]="true"
                              [autoplayInterval]="3000" [numVisible]="1" [numScroll]="1" [showIndicators]="false"
                              [showNavigators]="false">
                    <ng-template pTemplate="item" let-file>
                      <div class="p-col-12">
                        <img [src]="getPicURL(file)" width="100" alt="banner">
                      </div>
                    </ng-template>
                  </p-carousel>
                  <ng-template #onlyOneDisplayPicture>
                    <div *ngFor="let file of uploadedPicturesBackup" class="p-col-2 displayOnlyCarousel"
                         style="margin-top: 1em;">
                      <img [src]="getPicURL(file)" width="100" alt="banner">
                    </div>
                  </ng-template>

                  <p-carousel *ngIf="uploadedBannersBackup.length > 1; else onlyOneBanner" class="mixedCarouselMultiple" [value]="uploadedBannersBackup" [circular]="true"
                              [autoplayInterval]="3000" [numVisible]="1" [numScroll]="1" [showIndicators]="false"
                              [showNavigators]="false">
                    <ng-template pTemplate="item" let-file>
                      <div class="p-col-12">
                        <img [src]="getPicURL(file)" width="100" alt="banner">
                      </div>
                    </ng-template>
                  </p-carousel>
                  <ng-template #onlyOneBanner>
                    <div *ngFor="let file of uploadedBannersBackup" class="p-col-2 mixedCarousel"
                         style="margin-top: 1em;">
                      <img [src]="getPicURL(file)" width="100" alt="banner">
                    </div>
                  </ng-template>
                </div>
              </div>

              <p style="font-weight: bold; font-size: large">
                {{ i18nService.localize().CampaignWizardComponent.BudgetHead }}:
              </p>
              <div class="p-fluid">
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.DailyBudget }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ dailyBudgetString }} €
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.TotalBudget }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ totalBudgetString }} € (Netto)
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    {{ totalBudgetWithTaxString }} € (Brutto)
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    {{ displayClickPercent }}
                    % {{ i18nService.localize().CampaignWizardComponent.DisplayCampaign }} {{ 100 - displayClickPercent }}
                    % {{ i18nService.localize().CampaignWizardComponent.ClickCampaign }}
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label
                    class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ i18nService.localize().CampaignWizardComponent.Runtime }}
                    :</label>
                  <div class="p-col-12 p-md-10">
                    {{ daysBetween }} {{ i18nService.localize().CampaignWizardComponent.Days }}
                  </div>
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"></label>
                  <div class="p-col-12 p-md-10">
                    <div class="p-grid">
                      <div class="p-col-12 p-md-6">
                        <p>
                          {{ i18nService.localize().CampaignWizardComponent.StartDate }}:<br>
                          {{ getStartDateAsString() }}
                        </p>
                      </div>
                      <div class="p-col-12 p-md-6">
                        <p>
                          {{ i18nService.localize().CampaignWizardComponent.PossibleEndDate }}:<br>
                          {{ getEndDateAsString() }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-fluid">
                  <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                      {{ i18nService.localize().CampaignWizardComponent.DiscountCode }}:
                    </label>
                    <div class="p-col-6 p-md-6">
                      <p>
                        {{ i18nService.localize().CampaignWizardComponent.DiscountCodeText }}:
                      </p>
                      <div class="p-inputgroup">
                        <input type="text" pInputText placeholder="" [(ngModel)]="codeString"
                               [disabled]="!!discountCode">
                        <button type="button" pButton pRipple
                                label="{{i18nService.localize().CampaignWizardComponent.CheckButton}}"
                                (click)="checkDiscountCode()" *ngIf="!discountCode">
                        </button>
                        <button type="button" pButton pRipple
                                label="{{i18nService.localize().CampaignWizardComponent.DeleteButton}}"
                                (click)="removeDiscountCode()" class="p-button-danger" *ngIf="discountCode">
                        </button>
                      </div>
                      <div class="p-col-12 p-md-4" *ngIf="discountCode">
                        <p>
                          {{ discountCode.explanation }} {{ discountCode.percent }}%
                        </p>
                      </div>
                      <div class="p-col-12">
                        <p-messages severity="error" *ngIf="noDiscountError">
                          <ng-template pTemplate>
                            <span class="custom-message">
                              {{ i18nService.localize().CampaignWizardComponent.ExpiredDiscountCode }}
                            </span>
                          </ng-template>
                        </p-messages>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-fluid" *ngIf="this.globalService.agency">
                  <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                      Welche Rechnungsadresse soll verwendet werden:
                    </label>
                    <div class="p-col-12 p-md-10">
                      <p-radioButton name="chooseAddress" value="agency" [(ngModel)]="chooseAddress"
                                     label="Agentur Adresse" labelStyleClass="bold">
                      </p-radioButton>
                    </div>
                    <div class="p-col-12 p-md-10 p-md-offset-2">
                      <p-radioButton name="chooseAddress" value="advertiser" [(ngModel)]="chooseAddress"
                                     label="Advertiser Adresse" labelStyleClass="bold">
                      </p-radioButton>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="p-col-12" *ngIf="status != 'READY_TO_PAY'; else readyToPay">
              <div *ngIf="status == undefined">
                <h4>Kampagne bestätigen und zum Review einreichen</h4>
                <p>Nachdem du deine Kampagne bestätigt hast wird diese von unserem Team überprüft. <br>
                  Anschließend wird diese zum Bezahlprozess in deinem Dashboard freigegeben.</p>
              </div>
              <div *ngIf="status == 'IN_REVIEW_TO_PAY'">
                <h4>
                  Deine Kampagne wird zurzeit reviewed, sobald diese freigegeben wurde kannst du diese bezahlen.
                </h4>
              </div>
              <div *ngIf="status == 'REJECTED_TO_PAY'">
                <h4>
                  Deine Kampagne wurde abgelehnt, bitte überarbeite deine Kampagne und reiche diese erneut ein.
                </h4>
                <div class="p-col-12" *ngIf="messages.length > 0">
                  <p-messages [(value)]="messages" [enableService]="false"
                              [closable]="false"></p-messages>
                </div>
              </div>
            </div>

            <ng-template #readyToPay>
              <div class="p-col-4 border" *ngIf="isBalanceEnabled && remainingBaseBalance > 0 && !isBalanceDateError"
                   [ngClass]="{'border_selected': !paypalPay && isBalancePay}">
                <div style="margin-bottom: 2em;">
                  <p-radioButton name="payment" value="2" [(ngModel)]="paymentIndex"
                                 label="{{ i18nService.localize().CampaignWizardComponent.BalanceLabel }}"
                                 (click)="paypalPay = false; isBalancePay = true;"
                                 labelStyleClass="bold">
                  </p-radioButton>
                </div>
                <div *ngIf="isBalancePay">
                  <div *ngIf="(isBalanceEnough && !isBalancePaymentError); else errorBalance">
                    <p>
                      <b>{{ i18nService.localize().CampaignWizardComponent.ActualBaseBalance }}
                        :</b> {{
                        baseBalance.toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }} €
                    </p>
                    <p>
                      <b>{{ i18nService.localize().CampaignWizardComponent.TotalBudget }}
                        :</b> {{
                        totalBudget.toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }} €
                    </p>
                    <p>
                      <b>{{ i18nService.localize().CampaignWizardComponent.RemainingBaseBalance }}
                        :</b> {{
                        remainingBaseBalance.toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }} €
                    </p>
                    <p>
                      {{ i18nService.localize().CampaignWizardComponent.FindInBilling }}
                    </p>
                  </div>
                  <ng-template #errorBalance>
                    {{ i18nService.localize().CampaignWizardComponent.BalanceError }}
                  </ng-template>
                </div>
              </div>

              <div class="p-col-4 border" [ngClass]="{'border_selected': paypalPay && !isBalancePay}">
                <div style="margin-bottom: 2em;">
                  <p-radioButton name="payment" value="1" [(ngModel)]="paymentIndex"
                                 label="{{i18nService.localize().CampaignWizardComponent.DirectPayment}}"
                                 (click)="paypalPay = true; isBalancePay = false;" labelStyleClass="bold">
                  </p-radioButton>
                </div>
                <div *ngIf="totalBudgetWithTax >= 10; else minimumAmount">
                  <app-payment *ngIf="paypalPay" [productName]="campaignName" [price]="totalBudgetWithTax"
                               [returnUrl]="router.url" [campaignId]="wizardWIP!.id!" action="create"
                               [advertiserId]="advertiserId"></app-payment>
                </div>
                <ng-template #minimumAmount>
                  Der Betrag muss mindestens 10€ betragen.
                </ng-template>
              </div>

              <div class="p-col-4 border" [ngClass]="{'border_selected': !paypalPay && !isBalancePay}">
                <div style="margin-bottom: 2em;">
                  <p-radioButton name="payment" value="0" [(ngModel)]="paymentIndex"
                                 label="{{i18nService.localize().CampaignWizardComponent.BankTransfer}}"
                                 (click)="paypalPay = false; this.paymentError = false; isBalancePay = false;"
                                 labelStyleClass="bold">
                  </p-radioButton>
                </div>
                <div *ngIf="!paypalPay && !isBalancePay">
                  <p>
                    {{ i18nService.localize().CampaignWizardComponent.PleaseSend }}
                    <b>{{ totalBudgetWithTaxString }} €</b>
                    {{ i18nService.localize().CampaignWizardComponent.ToAccount }}<br><br>
                    <b>Streamboost GmbH</b><br>
                    <b>{{ i18nService.localize().CampaignWizardComponent.Institut }}:</b> Deutsche Bank<br>
                    <b>IBAN:</b> DE64 1001 0010 0948 7951 03<br>
                    <b>BIC:</b> PBNKDEFF<br>
                    <b>{{ i18nService.localize().CampaignWizardComponent.Purpose }}</b> {{ getBillNrsForBankTransfer() }}
                    <br><br>
                    {{ i18nService.localize().CampaignWizardComponent.In7Days }}<br>
                    {{ i18nService.localize().CampaignWizardComponent.FindInBilling }}
                  </p>
                </div>
              </div>
            </ng-template>

            <div class="p-col-12" *ngIf="paymentError">
              <p-messages severity="error">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PaymentError }}
                    </span>
                </ng-template>
              </p-messages>
            </div>

            <div class="p-col-12" *ngIf="paypalPaymentDone">
              <p-messages severity="success">
                <ng-template pTemplate>
                    <span class="custom-message">
                      {{ i18nService.localize().CampaignWizardComponent.PaymentDone }}
                    </span>
                </ng-template>
              </p-messages>
            </div>

            <div class="p-col-12" style="text-align: left; margin-top: 1em;">
              <button pButton pRipple type="button" class="p-button-rounded" (click)="goToPreviousStep()"
                      *ngIf="!working && status != 'READY_TO_PAY'" icon="fas fa-chevron-left"
                      label="{{i18nService.localize().CampaignWizardComponent.BackButton}}"
                      style="margin-right: .25em" iconPos="left">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded"
                      [loading]=working (click)="submitForPaymentReview()"
                      icon="fas fa-check" label="{{i18nService.localize().CampaignWizardComponent.SubmitToReview}}"
                      style="margin-right: .25em"
                      *ngIf="status != 'READY_TO_PAY'">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="create('')" [loading]=working
                      icon="fas fa-check" label="{{i18nService.localize().CampaignWizardComponent.PayButton}}"
                      style="margin-right: .25em"
                      *ngIf="paymentOk() && !isBalancePay">
              </button>
              <button pButton pRipple type="button" class="p-button-rounded" (click)="create('')"
                      icon="fas fa-check"
                      label="{{i18nService.localize().CampaignWizardComponent.PayWithBalanceButton}}"
                      style="margin-right: .25em"
                      *ngIf="paymentOk() && !isBalanceDateError && isBalancePay && isBalanceEnough && !isBalancePaymentError && status === 'READY_TO_PAY'">
              </button>
            </div>
          </div>
        </p-card>
      </div>

      <p-dialog [(visible)]="showRegister" [style]="{width: '70%', maxWidth: '770px'}" [resizable]="false"
                [draggable]="false" [modal]="true" [closeOnEscape]="false" [closable]="false" closeIcon="fas fa-times">
        <ng-template pTemplate="header">
          <div class="headline">{{ i18nService.localize().CampaignWizardComponent.AdditionalInfoHeader }}</div>
        </ng-template>

        <div class="p-grid" style="padding: 2em;">
          <div class="p-col-12">

            <p class="textWithNewlines">{{ i18nService.localize().CampaignWizardComponent.AdditionalInfoText }}</p>

            <div *ngIf="!registerSent">
              <p class="headline-in-step">
                {{ i18nService.localize().CampaignWizardComponent.CompanyHeader }}
              </p>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" *ngIf="!this.globalService.advertiser?.website">
                  <label for="website" style="font-weight: bold">Website:</label>
                  <input id="website" type="text" pInputText [(ngModel)]="website" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6" *ngIf="!this.globalService.advertiser?.taxNumber">
                  <label for="tax"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.TaxNumber }}
                    :</label>
                  <input id="tax" type="text" pInputText [(ngModel)]="taxNumber" class="field">
                </div>
              </div>

              <p class="headline-in-step"
                 *ngIf="!this.globalService.advertiser?.hasAddress() || this.globalService.advertiser?.country === undefined">
                {{ i18nService.localize().CampaignWizardComponent.AddressHeader }}
              </p>

              <div class="p-fluid p-formgrid p-grid"
                   *ngIf="!this.globalService.advertiser?.hasAddress() || this.globalService.advertiser?.country === undefined">
                <div class="p-field p-col-12 p-md-8" *ngIf="!this.globalService.advertiser?.street">
                  <label for="street"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.Street }}*:</label>
                  <input id="street" type="text" pInputText [(ngModel)]="street" class="field">
                </div>

                <div class="p-field p-col-12 p-md-4" *ngIf="!this.globalService.advertiser?.nr">
                  <label for="nr" style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.Nr }}
                    *:</label>
                  <input id="nr" type="text" pInputText [(ngModel)]="nr" class="field">
                </div>

                <div class="p-field p-col-12 p-md-4" *ngIf="!this.globalService.advertiser?.postalCode">
                  <label for="postalcode"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.PostalCode }}
                    *:</label>
                  <input type="text" maxlength="7" id="postalCode" pInputText [(ngModel)]="postalCode" class="field">
                </div>

                <div class="p-field p-col-12 p-md-8" *ngIf="!this.globalService.advertiser?.city">
                  <label for="city" style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.City }}
                    *:</label>
                  <input id="city" type="text" pInputText [(ngModel)]="city" class="field">
                </div>

                <div class="p-field p-col-12"
                     *ngIf="this.globalService.advertiser?.country === undefined || this.globalService.advertiser?.country === ''">
                  <label for="country"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.Country }}
                    *:</label>
                  <p-dropdown id="country" [options]="countriesDropDownList" [(ngModel)]="country"
                              optionLabel="item_text" [showClear]="true"
                              [style]="{'border-radius': '12px', 'width': '100%'}"
                              [panelStyle]="{'border-radius': '12px', 'width': '100%'}">
                  </p-dropdown>
                </div>
              </div>

              <p class="headline-in-step" *ngIf="!this.globalService.advertiser?.firstname">
                {{ i18nService.localize().CampaignWizardComponent.ContactHeader }}
              </p>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" *ngIf="!this.globalService.advertiser?.firstname">
                  <label for="firstname"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.Firstname }}
                    *:</label>
                  <input id="firstname" type="text" pInputText [(ngModel)]="firstname" class="field">
                </div>

                <div class="p-field p-col-12 p-md-6" *ngIf="!this.globalService.advertiser?.lastname">
                  <label for="lastname"
                         style="font-weight: bold">{{ i18nService.localize().CampaignWizardComponent.Lastname }}
                    *:</label>
                  <input id="lastname" type="text" pInputText [(ngModel)]="lastname" class="field">
                </div>

                <!--                <div class="p-field p-col-12 p-md-6" *ngIf="!this.globalService.advertiser?.telephone">-->
                <!--                  <label for="telephone" style="font-weight: bold">Telefon:</label>-->
                <!--                  <input id="telephone" type="number" pInputText [(ngModel)]="telephone" class="field">-->
                <!--                </div>-->
              </div>
            </div>

          </div>
          <div class="p-col-12" style="text-align: right;">
            <button pButton pRipple type="button" (click)="this.showRegister=false" *ngIf="!working&&!registerSent"
                    label="{{i18nService.localize().CampaignWizardComponent.CancelButton}}"
                    class="p-button-rounded" style="margin-right: .25em">
            </button>
            <button pButton pRipple type="button" (click)="register()" *ngIf="!working&&!registerSent"
                    label="{{i18nService.localize().CampaignWizardComponent.NextButton}}" class="p-button-rounded"
                    [disabled]="!checkAllRegisterValues()" style="margin-right: .25em">
            </button>
          </div>
        </div>
      </p-dialog>

    </div>
  </div>
</div>

<app-payment-confirmation *ngIf="showPaymentInfoPage" [success]="paymentSuccess"
                          [advertiserId]="advertiserId"></app-payment-confirmation>
